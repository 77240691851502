<div class="out">
  <div class="title">
    Client Onboarding
  </div>
  <div class="part">
    <k-spinner class="check spin" *ngIf="bPlanSpin"></k-spinner>

    <img *ngIf="!bPlanSpin && !bPlanDone" src="assets/images/kbar-tick.svg" class="check" alt="">
    <img *ngIf="!bPlanSpin && bPlanDone" src="assets/images/kbar-tick-done.svg" class="check" alt="">

    <div class="tx">
      keyzii plan
    </div>
    <progres-h class=" slider" [progress]="plan_progress">
    </progres-h>
  </div>
  <div class="part">
    <k-spinner class="check spin" *ngIf="bBillingSpin"></k-spinner>
    <img *ngIf="!bBillingSpin && !bBillDone" src="assets/images/kbar-tick.svg" class="check" alt="">
    <img *ngIf="!bBillingSpin && bBillDone" src="assets/images/kbar-tick-done.svg" class="check" alt="">

    <div class="tx">
      Billing details
    </div>
    <progres-h class=" slider" [progress]="billing_progress">
    </progres-h>
  </div>
  <div class="part last">
    <k-spinner class="check spin" *ngIf="bShowAccSpin"></k-spinner>
    <img *ngIf="!bShowAccSpin && !bAccCreated" src="assets/images/kbar-tick.svg" class="check" alt="">
    <img *ngIf="!bShowAccSpin && bAccCreated" src="assets/images/kbar-tick-done.svg" class="check" alt="">


    <div class="tx">
      Account Created
    </div>
  </div>

  <!-- <kpay-bar-part [active]=" state=='plan'" class=" part" label="Building Plan">
          </kpay-bar-part>
          <kpay-bar-part [active]="state=='acc'" class="part" label="Waiting for Account"></kpay-bar-part>
          <kpay-bar-part [active]="state=='process'" class="part" label="Procesing Payment"></kpay-bar-part>
          <kpay-bar-part [active]="state=='done'" class="part last" [label]="payment_lbl"></kpay-bar-part> -->
