<div class="out">
  <div class="sub_title black">
    Set your availability
  </div>
  <div class="">
    Edit your work hours, duration and time zone below. This will show on your company and personal keyzii booking page.
  </div>
  <div class="work">
    <div class="hours">
      Daily working hours
    </div>
    <div class="duration">
      Duration
    </div>
    <div class="zone">
      Time zone
    </div>
  </div>
  <div>
    <div class="work1">
      <div class="btm_l hours1">
        {{work_from}}
      </div>
      <div class="hours_">
        -
      </div>
      <div class="btm_l hours2">
        {{work_to}}
      </div>
      <div class="btm_l duration d_f">
        {{slot_minutes}} minutes
      </div>
      <div class="btm_l zone z_f">
        {{time_zone.text}}
      </div>

      <div class="btm_l edit"  kzLib (click)="keyziiSettings()">
        <div class="share_tx">
          Edit
        </div>
      </div>


    </div>
    <div class="sub_title">
      <span class="black">Company Booking Page</span>
    </div>
    <div class="boxes">
      <div class="box sel_box">
        <img class="logo" src="assets/images/keyzii_logo_primary1.svg" alt="">
        <div class="txt">
          Keyzii's scheduling tool is the only option for the company booking page.
          <div class="link_tx share_sel"  kzLib (click)="companyLink()">
            <img class="img" src="assets/images/link.svg" alt="">
            Copy link
          </div>

        </div>
      </div>

    </div>

    <div class="sub_title">
      <span class="black">Personal Booking Page</span> (Choose your preferred scheduling tool)
    </div>


    <div class="boxes btm">
      <div class="box" [ngClass]="{sel_box:selected=='keyzii'}">
        <img class="logo" src="assets/images/keyzii_logo_primary1.svg" alt="">
        <switch class="switch" (checkedCahnge)="select($event,'keyzii')" [checked]="selected=='keyzii'"></switch>
        <div class="txt">
          Select keyzii’s simple booking system and start booking meetings today. You can edit your work hours, duration and time zone above.
          <div class="link_tx" [ngClass]="{share_sel:selected=='keyzii'}"  kzLib (click)="keyziiLink()">
            <img class="img" *ngIf="selected=='keyzii'" src="assets/images/link.svg" alt="">
            <img class="img" *ngIf="selected!='keyzii'" src="assets/images/link_g.svg" alt="">
            Copy link
          </div>


        </div>
      </div>
      <div class="box" [ngClass]="{sel_box:selected!='keyzii'}">
        <img class="logo" src="assets/images/calendly_logo.svg" alt="">
        <div class="settings selected"  kzLib (click)="calendlySettings()">
          Edit
        </div>
        <switch class="switch" (checkedCahnge)="select($event, 'calendly')" [checked]="selected=='calendly'"></switch>
        <div class="txt">
          To seamlessly integrate your Calendly with keyzii, simply click ‘edit’ next to the toggle icon above and follow the 3 easy steps. </div>
        <div class="txt link_tx" [ngClass]="{share_sel:selected!='keyzii'}"  kzLib (click)="keyziiLink()">
          <img class="img" *ngIf="selected!='keyzii'" src="assets/images/link.svg" alt="">
          <img class="img" *ngIf="selected=='keyzii'" src="assets/images/link_g.svg" alt="">
          Copy link
        </div>
      </div>
    </div>
    <link-opts *ngIf="bShowLinkOpts" [only_cal]="true" class="links" [ngClass]="{cal_link:selected=='calendly'}"></link-opts>

    <cal-settings *ngIf="bShowSettings" class="abs_center up_a_bit" (close)="bShowSettings=false"></cal-settings>

  </div>
</div>
