import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ContactsState } from "./contacts.model";

export const selectContactsStateState = createFeatureSelector<ContactsState>('contacts');



//******************************************************************************
//
//******************************************************************************
export const gotContacts = createSelector(
  selectContactsStateState,
  state => state.contacts
)
