import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'canvas-tool-box',
  templateUrl: './tool-box.component.html',
  styleUrls: ['./tool-box.component.scss']
})
export class ToolBoxComponent {

  @Input() set options(opt: any) {
    this.line_color = opt.line_color
    this.line_width = opt.line_width
  }


  @Output() optionsChange: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  @ViewChild('out') out: ElementRef | undefined;
  parent: HTMLElement | undefined = undefined;

  style = "stroke:rgb(255,0,0);stroke-width:5"
  ngAfterViewInit() {
    if (this.out) {
      this.parent = this.out.nativeElement.parentElement
    }
    this.setColor({})
  }
  line_color: string = "#ff0b"
  line_width: number = 5
  setColor($event: any) {
    this.style = "stroke:" + this.line_color + ";stroke-width:" + this.line_width
    this.sendIt();
  }
  changeWidth($event: any) {
    this.style = "stroke:" + this.line_color + ";stroke-width:" + this.line_width
    this.sendIt();
  }
  sendIt() {
    this.optionsChange.emit({
      line_color: this.line_color,
      line_width: this.line_width
    })
  }
  cancel() {
    this.close.emit()
  }
}
