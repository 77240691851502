import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { selectToolOptions } from 'src/app/canvas/canvas.selectors';
import { WSSState } from 'src/app/wss/wss.model';
import { customerId, myId } from 'src/app/wss/wss.selectors';
import { NotifyError, NotifyUser, ShowCC } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { calendlyRep, customizeAccount, hostUrl, myCompanyTitle, myName, myPicture } from '../../pages.selectors';
import { CalendarState } from '../../scheduling/calendar.model';
import { changedWorkTime } from '../../scheduling/calendar.selectors';
import { CalendarService } from '../../scheduling/calendar.service';
import { Clipboard } from '@angular/cdk/clipboard'
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  @ViewChild('container') container: ElementRef | undefined
  calendy_frame: any
  selected: string = "keyzii"
  img_src: string = "assets/images/default_prof_pic.svg"
  rep_name: string = ""
  title: string = ""
  rep_link: string = ""
  cia_link: string = ""
  // calendly_cia: string = ""
  calendly_rep: string = ""
  cia_logo: string = "assets/images/keyzii_logo_primary1.svg"
  @Output() enableSave: EventEmitter<boolean> = new EventEmitter();

  @Input() set save_it(what: string) {
    if (what.startsWith("contact")) {

    }

  }
  // bShowCalendlySettings: boolean = false
  bShowSettings: boolean = false
  bShowLinkOpts: boolean = false

  work_time: any
  work_from: string = "09:00 AM";
  work_to: string = "05:00 PM";
  slot_minutes: number = 15;
  time_zone: any = {}
  subscriptions: any[] = []
  time_zones: any[] = [];

  host_url: string | undefined
  customer_id: string | undefined
  my_id: string | undefined

  constructor(
    public pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
    public calendar_service: CalendarService,
    public calendarStore: Store<CalendarState>,
    private wssState: Store<WSSState>,
    private clipboard: Clipboard,
  ) {
    console.log("calendar settings")
    this.subscriptions.push(this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      this.customer_id = customer_id
    }))
    this.subscriptions.push(this.wssState.select(myId).subscribe((id: string | undefined) => {
      this.my_id = id
    }))
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
    this.subscriptions.push(this.calendarStore.select(changedWorkTime).subscribe((work_time: any) => {
      if (work_time) {
        this.work_time = work_time
        this.work_from = work_time.from
        this.work_to = work_time.to
        if (work_time.slot_minutes) {
          this.slot_minutes = work_time.slot_minutes
        }
        this.setTZIKndex()
      }
    }))

    this.subscriptions.push(this.pagesState.select(customizeAccount).subscribe((customize: any) => {
      if (customize) {
        if (customize.logo) {
          this.cia_logo = customize.logo
        }
      }
    }))
    // this.pagesState.select(calendlyCia).subscribe((cia_link: string | undefined) => {
    //   if (cia_link) {
    //     this.cia_link = cia_link
    //     // this.setCalendly()
    //   }
    // })
    this.pagesState.select(calendlyRep).subscribe((calendly_rep: string | undefined) => {
      if (calendly_rep) {
        this.calendly_rep = calendly_rep
        this.selected = 'calendly'
      }
    })

    this.subscriptions.push(this.pagesState.select(myPicture).subscribe((picture: any) => {
      if (picture) {
        this.img_src = picture
      }
    }))
    this.subscriptions.push(this.pagesState.select(myName).subscribe((name: string) => {
      if (name) {
        this.rep_name = name
      }
    }))
    this.subscriptions.push(this.pagesState.select(myCompanyTitle).subscribe((title: string) => {
      if (title) {
        this.title = title
      }
    }))

    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      if (opt.name == "cal-opts") {
        this.bShowLinkOpts = true
      } else {
        this.bShowLinkOpts = false;
      }
    }))
    //Media state will work for the non-local ones
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.rep_link = "https://" + this.host_url + "?rep_id=" + this.my_id + "&call=true"
    this.cia_link = "https://" + this.host_url + "?customer_id=" + this.customer_id
  }

  select($event: any, what: string) {

    setTimeout(async () => {
      if (what == this.selected && $event == false) {
        if (this.selected == "calendly") {
          what = "keyzii"
        } else {
          what = "calendly"
        }
      }
      this.selected = what
      this.bShowSettings = false;
      // this.bShowCalendlySettings = false;
      this.bShowLinkOpts = false
      if (this.selected != "calendly") {
        this.calendy_frame = undefined
      }

      await this.calendar_service.selectCalendar(this.selected)

    })

  }

  keyziiSettings() {
    this.pagesState.dispatch(new ShowCC("calendar_set"))
  }
  calendlySettings() {
    this.pagesState.dispatch(new ShowCC("calendly_set"))
  }
  keyziiLink() {

    this.clipboard.copy(this.rep_link);
    if (this.selected == 'keyzii') {
      this.pagesState.dispatch(new NotifyUser("Rep link copied"))
    } else {
      this.pagesState.dispatch(new NotifyUser("Calendly link copied"))
    }
  }
  companyLink() {
    if (this.selected == 'keyzii') {
      this.clipboard.copy(this.cia_link);
      this.pagesState.dispatch(new NotifyUser("Company link copied"))
    }
  }
  // calendlyLink() {
  //   if (this.selected != 'keyzii') {
  //     this.clipboard.copy(this.rep_link);
  //     this.pagesState.dispatch(new NotifyUser("Calendly link copied"))
  //   }
  // }

  toggleOpts() {
    if (this.bShowLinkOpts) {
      this.canvasStore.dispatch(new SelectToolOpts(""))
    } else {
      this.canvasStore.dispatch(new SelectToolOpts("cal-opts"))
    }
  }
  async setTZIKndex() {
    if (this.time_zones.length == 0) {
      this.time_zones = await this.calendar_service.loadTZ()
    }
    if (this.time_zones && this.work_time) {
      for (let tz_index = 0; tz_index < this.time_zones.length; tz_index++) {
        let tz = this.time_zones[tz_index]
        if (tz.abbr == this.work_time.abbr) {
          if (tz.offset * 60 == this.work_time.offset) {
            this.time_zone = tz
            break;
          }
        }
      }
    }
  }
}
