/*
Copyright(c) 2023 Sergio A. Fernandez, Ph.D.


  call_waiting notification
  when we get queue,
  if we are NOT in a call
  We check the queue, if an entry is calling
    if the entry is for a SPECIFIC rep IGNORE it
    else notify

  when the call is answer turn the notificaio off


*/

import { ChatAction, ChatActionTypes } from "./chat.actions";
import { ChatState, initialChatState } from "./chat.model";

function binarySearch(msgs: any[], msg: any) {

  var startIndex = 0;
  let stopIndex = msgs.length - 1;
  if (stopIndex < 0 || !msg) {
    return stopIndex
  }
  let middle = Math.floor((stopIndex + startIndex) / 2);

  while (msgs[middle].time != msg.time && startIndex < stopIndex) {

    //adjust search area
    if (msg.time < msgs[middle].time) {
      stopIndex = middle - 1;
    } else if (msg.time > msgs[middle].time) {
      startIndex = middle + 1;
    }

    //recalculate middle
    middle = Math.floor((stopIndex + startIndex) / 2);
  }

  //make sure it's the right value
  return (msgs[middle].time != msg.time) ? -1 : middle;
}
// let streams_cache: any = {}
export function chatReducer(state = initialChatState,
  action: ChatAction): ChatState {
  switch (action.type) {

    case ChatActionTypes.CleanChat: {
      const chatState: ChatState = initialChatState;
      return chatState;
    }
    case ChatActionTypes.ChatId: {
      const chatState: ChatState = Object.assign({}, state);
      chatState.chat_id = action.chat_id
      return chatState;
    }
    case ChatActionTypes.GotChat: {
      const chatState: ChatState = Object.assign({}, state);
      chatState.chat = action.chat
      let chat_msgs = Object.assign([], action.chat.messages)
      chat_msgs.sort((a: any, b: any) => { return a.time - b.time })
      chatState.chat_msgs = chat_msgs
      return chatState;
    }
    case ChatActionTypes.OpenCloseChat: {
      const chatState: ChatState = Object.assign({}, state);
      chatState.open_chat = action.bOpen
      return chatState;
    }
    case ChatActionTypes.ChatMessages: {
      const chatState: ChatState = Object.assign({}, state);
      let chat_msgs = Object.assign([], action.msgs)
      chat_msgs.sort((a: any, b: any) => { return a.time - b.time })
      chatState.chat_msgs = chat_msgs
      return chatState;
    }
    case ChatActionTypes.NewMsg: {
      console.log("New Chat Msg")
      const chatState: ChatState = Object.assign({}, state);
      if (binarySearch(chatState.chat_msgs, action.msg.chat_msg) == -1) {
        let chat_msgs = Object.assign([], chatState.chat_msgs)
        chat_msgs.push(action.msg.chat_msg)
        chat_msgs.sort((a: any, b: any) => { return a.time - b.time })
        chatState.chat_msgs = chat_msgs
      }
      return chatState;
    }


    default: {
      return state;
    }
  }
}
