import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileModule } from './profile/profile.module';
import { InCallModule } from './in-call/in-call.module';
import { AssetsModule } from './assets/assets.module';
// import { CallManagementModule } from './call-management/call-management.module';
import { ContactsModule } from './contacts/contacts.module';
import { SchedulingModule } from './scheduling/scheduling.module';
import { StoreModule } from '@ngrx/store';
import * as pagesReducer from './pages.reducer';
import { ControlsModule } from '../controls/controls.module';
import { WSSModule } from '../wss/wss.module';
import { KVideoModule } from '../k-video/k-video.module';
import { CallManagementComponent } from './call-management/call-management/call-management.component';
import { QueueWndComponent } from './call-management/queue-wnd/queue-wnd.component';
import { CallNotificationComponent } from './call-management/call-notification/call-notification.component';
import { ChatModule } from '../chat/chat.module';
import { ActiveComponent } from './call-management/active/active.component';
import { HistoryComponent } from './call-management/history/history.component';
import { QueueComponent } from './call-management/queue/queue.component';
import { WndLineComponent } from './call-management/queue-wnd/wnd-line/wnd-line.component';
import { WndOptionsComponent } from './call-management/queue-wnd/wnd-options/wnd-options.component';
import { NotificationComponent } from './notification/notification.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { NoChromeComponent } from './no-chrome/no-chrome.component';
// import { AskCodeComponent } from './ask-code/ask-code.component';
import { FormsModule } from '@angular/forms';
import { ConfirmHangUpComponent } from './in-call/confirm-hang-up/confirm-hang-up.component';
import { FreeWndComponent } from './profile/billing/free-wnd/free-wnd.component';
import { LiveFlashComponent } from './call-management/live-flash/live-flash.component';
import { KzLibModule } from '../kz-lib/kz-lib.module';

@NgModule({
  declarations: [
    CallManagementComponent,
    LiveFlashComponent,
    QueueWndComponent,
    CallNotificationComponent,
    ActiveComponent,
    HistoryComponent,
    QueueComponent,
    QueueWndComponent,
    WndLineComponent,
    WndOptionsComponent,
    NotificationComponent,

    WelcomeComponent,
    NoChromeComponent,
    // AskCodeComponent,
    ConfirmHangUpComponent,
    FreeWndComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,

    ControlsModule,
    ProfileModule,
    InCallModule,
    AssetsModule,
    // CallManagementModule,
    ContactsModule,
    SchedulingModule,
    StoreModule.forFeature('kpages', pagesReducer.pagesReducer),
    WSSModule,
    KVideoModule,
    ChatModule,
    KzLibModule
  ], exports: [
    ProfileModule,
    InCallModule,
    CallManagementComponent,
    QueueWndComponent,
    CallNotificationComponent,
    NotificationComponent,

    WelcomeComponent,
    NoChromeComponent,
    // AskCodeComponent
    ConfirmHangUpComponent,
    FreeWndComponent,
    LiveFlashComponent
  ]
})
export class PagesModule { }
