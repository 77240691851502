import { Component, } from '@angular/core';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { hostUrl } from '../../pages.selectors';
import { AddAppointment } from '../calendar.actions';
import { changedSlots, hasCalToken, newAppointment } from '../calendar.selectors';
import { CalendarComponent } from '../calendar/calendar.component';
@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['../../profile/profile/profile.component.scss', '../calendar/calendar.component.html', './scheduling.component.scss']
})

export class SchedulingComponent extends CalendarComponent {
  tab: string = "calendar"
  btn_tx: string = "Create Event"
  slots: any[] | undefined;
  host_url: string | undefined
  auth_url: string | undefined

  bNeedsConnection: boolean = true;
  bShowSettings: boolean = false;
  ngOnInit() {
    console.log("scheduling")
    let tab = localStorage.getItem('tab')
    if (tab) {
      this.tab = tab;
    }
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
    this.subscriptions.push(this.calendarStore.select(hasCalToken).subscribe((bHasCalToken: boolean) => {
      this.bNeedsConnection = !bHasCalToken
    }))
    this.subscriptions.push(this.calendarStore.select(newAppointment).subscribe((slot: any) => {
      if (slot) {
        this.btn_tx = "Cancel Event";
      } else {
        this.btn_tx = "Create Event"
      }
    }))
    this.subscriptions.push(this.calendarStore.select(changedSlots).subscribe((slots: any[]) => {
      if (slots) {
        this.slots = []
        let now = new Date().getTime()
        for (let i = 0; i < slots.length; i++) {
          let slot = slots[i]
          if (slot.event) {
            if (slot.event.end > now) {
              this.slots.push(slot)
            }
          }
        }
      }
    }))
    this.calendar_service.getDayEvents(this.date)
  }

  createEvent() {
    if (this.btn_tx == "Cancel Event") {
      this.btn_tx = "Create Event"


      this.calendarStore.dispatch(new AddAppointment(undefined))
      this.canvasStore.dispatch(new SelectToolOpts(""))
    } else {
      this.calendarStore.dispatch(new AddAppointment({}))
      this.canvasStore.dispatch(new SelectToolOpts("event"))
      this.btn_tx = "Cancel Event"
    }

  }
  getLink(slot: any) {
    let _id = slot.event._id
    let link = "https://" + this.host_url + "?event_id=" + _id
    this.clipboard.copy(link);
  }
  async linkCal() {
    try {
      this.auth_url = await this.calendar_service.getGoogleCalendarAuthURL()
      window.location.href = this.auth_url
      console.log("auth_url " + this.auth_url)
    } catch (e) {
      console.error("error getting auth_url " + e)
    }
  }
  select(tab: string) {
    this.tab = tab
    localStorage.setItem('tab', tab)
  }
}
