/*
(C)2020 Sergio A. Fernandez, Ph.D.



*/


export interface kPayState {
  running: string | undefined //pay plan
  retry_running: string | undefined
  retry: number
  bShowScreen: boolean
  bShowMenu: boolean
  invoice: any,
  plan_from_rep: any,
  plan_from_caller: any,
  acc: any,
  cc: any,

  bill_info: any,
  captcha_token: string | undefined,
  response: any,
  create_acc_in_svr: any
  send_payment_to_svr: any
  local_cancel: boolean
  remote_cancel: boolean
  pay_bar_step: string
  plan_bar_step: string
  done: boolean,
  target_url: string | undefined
  error: string | undefined
  notify_done_pay: number
  error_no: number
}

export const initialkPayState: kPayState = {
  running: undefined,
  retry_running: undefined,
  retry: 0,
  bShowScreen: false,
  bShowMenu: false,
  invoice: undefined,
  plan_from_rep: undefined,
  plan_from_caller: undefined,
  acc: undefined,
  cc: undefined,
  bill_info: undefined,
  captcha_token: undefined,
  response: undefined,
  create_acc_in_svr: undefined,
  send_payment_to_svr: undefined,
  local_cancel: false,
  remote_cancel: false,
  pay_bar_step: "", //process done
  plan_bar_step: "", //plan acc process done
  done: false,
  target_url: undefined,
  error: undefined,
  notify_done_pay: 0,
  error_no: 0
};
