import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyComponent } from './property/property.component';
import { MlsSearchComponent } from './mls-search/mls-search.component';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

import * as fromChapalaMLS from './chapala-mls.reducer';
import { StoreModule } from '@ngrx/store';
import { SmallPropertyComponent } from './small-property/small-property.component';
import { PropertiesComponent } from './properties/properties.component';
import { ShowImgsComponent } from './show-imgs/show-imgs.component';
import { SelectorComponent } from './search/selector/selector.component';
import { StateComponent } from './search/selector/state.component';
import { CityComponent } from './search/selector/city.component';
import { LocationComponent } from './search/selector/location/location.component';
import { CategoriesComponent } from './search/selector/categories.component';
import { PropStatusComponent } from './search/selector/prop-status.component';
import { PriceComponent } from './search/selector/price/price.component';
import { MinPriceComponent } from './search/selector/price/min-price.component';
import { MaxPriceComponent } from './search/selector/price/max-price.component';
import { BedsBathsComponent } from './search/selector/beds-baths/beds-baths.component';
import { Sel1to5Component } from './search/selector/beds-baths/sel1to5/sel1to5.component';
import { MoreComponent } from './search/selector/more/more.component';
import { MLSOptionsComponent } from './search/selector/options.component';
import { ControlsModule } from '../controls/controls.module';

@NgModule({
  declarations: [
    PropertyComponent,
    MlsSearchComponent,
    SmallPropertyComponent,
    PropertiesComponent,
    ShowImgsComponent,
    SelectorComponent,
    StateComponent,
    CityComponent,
    LocationComponent,
    CategoriesComponent,
    PropStatusComponent,
    PriceComponent,
    MinPriceComponent,
    MaxPriceComponent,
    BedsBathsComponent,
    Sel1to5Component,
    MoreComponent,
    MLSOptionsComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    GoogleMapsModule,
    StoreModule.forFeature('cmls', fromChapalaMLS.chapalaMLSReducer),
    ControlsModule
  ],
  exports: [MlsSearchComponent, PropertyComponent]
})
export class ChapalaMlsModule { }
