import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { kPayState } from '../kpay.model';
import { gotInvoice, payBarStep, planBarStep, } from '../kpay.selectors';

@Component({
  selector: 'kpay-bar',
  templateUrl: './kpay-bar.component.html',
  styleUrls: ['./kpay-bar.component.scss']
})
export class KpayBarComponent {
  bPlanSpin: boolean = true
  bPlanDone: boolean = false
  bBillingSpin: boolean = false
  bBillDone: boolean = false

  bShowAccSpin: boolean = false
  bAccCreated: boolean = false

  plan_progress: number = 0
  billing_progress: number = 0

  payment_lbl: string = "0.00"
  subscriptions: any[] = []
  constructor(
    public kpayState: Store<kPayState>,
  ) {
    console.log("kpay-bar , plan")

  }
  ngAfterViewInit() {
    this.subscriptions.push(this.kpayState.select(planBarStep).subscribe((state: any) => {
      //undefined rep_plan acc caller_plan process done
      this.setUI(state)

    }))

    this.subscriptions.push(this.kpayState.select(gotInvoice).subscribe((invoice: any) => {
      if (invoice) {
        setTimeout(() => this.payment_lbl = "Payment for " + invoice.amount + " received")
      }
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  setUI(state: string | undefined) {
    console.log("Bar state " + state)
    setTimeout(() => {
      if (!state) {
        this.bPlanSpin = true
        this.bPlanDone = false
        this.plan_progress = 0

        this.bBillingSpin = false
        this.bBillDone = false
        this.billing_progress = 0

        this.bAccCreated = false


      } else {
        if (state == "rep_plan") {
          this.bPlanSpin = false
          this.bPlanDone = false
          this.plan_progress = 50

          this.bBillingSpin = false
          this.bBillDone = false
          this.billing_progress = 0

          this.bAccCreated = false
        } else if (state == "acc") {
          this.bPlanSpin = false
          this.bPlanDone = true
          this.plan_progress = 100

          this.bBillingSpin = true
          this.bBillDone = false
          this.billing_progress = 0
        }
        else if (state == "caller_plan") {
          this.bPlanSpin = false
          this.bPlanDone = true
          this.plan_progress = 100

          this.bBillingSpin = false
          this.billing_progress = 50
        } else if (state == "process") {
          this.bPlanSpin = false
          this.bPlanDone = true
          this.plan_progress = 100


          this.bBillingSpin = false
          this.bBillDone = true
          this.billing_progress = 100
          this.bShowAccSpin = true
        } else if (state == "done") {
          this.bPlanSpin = false
          this.bPlanDone = true
          this.plan_progress = 100


          this.bBillingSpin = false
          this.bBillDone = true
          this.billing_progress = 100

          this.bShowAccSpin = false
          this.bAccCreated = true
        }
      }
    })
  }
}
