import { createFeatureSelector, createSelector } from "@ngrx/store";
import { WSSState } from "./wss.model";

export const selectWSSStateState = createFeatureSelector<WSSState>('wss');



//******************************************************************************
//
//******************************************************************************
export const sessionId = createSelector(
  selectWSSStateState,
  state => {
    return state.session_id
  }
)
export const sessionExpired = createSelector(
  selectWSSStateState,
  state => {
    return state.session_expired
  }
)

export const myId = createSelector(
  selectWSSStateState,
  state => {
    return state.my_id
  }
)
export const customerId = createSelector(
  selectWSSStateState,
  state => {
    return state.customer_id
  }
)

export const busy = createSelector(
  selectWSSStateState,
  state => {
    return state.busy
  }
)
export const callId = createSelector(
  selectWSSStateState,
  state => {
    return state.call_id
  }
)
export const callerId = createSelector(
  selectWSSStateState,
  state => {
    return state.caller_id
  }
)
export const repId = createSelector(
  selectWSSStateState,
  state => {
    return state.rep_id
  }
)
export const managerId = createSelector(
  selectWSSStateState,
  state => {
    return state.manager_id
  }
)
// export const managerVideo = createSelector(
//   selectWSSStateState,
//   state => {
//     return state.manager_video
//   }
// )
// export const managerAudio = createSelector(
//   selectWSSStateState,
//   state => {
//     return state.manager_audio
//   }
// )

export const lastMsg = createSelector(
  selectWSSStateState,
  state => {
    return state.last_msg
  }
)
export const leftCall = createSelector(
  selectWSSStateState,
  state => {
    return state.left_call
  }
)
export const lastOnBoard = createSelector(
  selectWSSStateState,
  state => {
    return state.last_on_board
  }
)
export const lastkzApiMsg = createSelector(
  selectWSSStateState,
  state => {
    return state.last_kzApi_msg
  }
)
// export const callerVideo = createSelector(
//   selectWSSStateState,
//   state => {
//     return state.caller_video
//   }
// )
// export const callerAudio = createSelector(
//   selectWSSStateState,
//   state => {
//     return state.caller_audio
//   }
// )
