import { Component, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { PagesState } from '../pages.model';
import { apiParams, apiUrlParams, customizeAccount } from '../pages.selectors';
import { getAssetFromURL } from 'src/app/utils';
import { ShowAsset, FilterAssets, SelFilterOption, ShowFolder, ShowAPI } from 'src/app/pages/pages.actions';
import { WSSService } from 'src/app/wss/wss.service';
import { WSSState } from 'src/app/wss/wss.model';
import { lastMsg } from 'src/app/wss/wss.selectors';

@Component({
  selector: 'api-wnd',
  templateUrl: './api-wnd.component.html',
  styleUrls: ['./api-wnd.component.scss']
})
export class ApiWndComponent {
  api_iframe_url: any
  // show_in_wnd: boolean = false
  subscriptions: any[] = []

  company_icon: string = "assets/images/keyzii.svg"
  @ViewChild('iframe') iframe: ElementRef | undefined;
  constructor(
    private pagesState: Store<PagesState>,
    private sanitizer: DomSanitizer,
    public wss_service: WSSService,
    public wssState: Store<WSSState>,

  ) {
    console.log("api wnd")

    this.subscriptions.push(this.pagesState.select(apiUrlParams).subscribe((url_params: string | undefined) => {
      if (url_params) {

        this.api_iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(url_params);
      }
    }))

    this.subscriptions.push(this.pagesState.select(customizeAccount).subscribe((customize: any) => {
      if (customize) {
        if (customize.icon) {
          this.company_icon = customize.icon
        }
      }
    }))
    // this.subscriptions.push(this.pagesState.select(apiParams).subscribe((params: any) => {
    //   if (params.api_iframe_url) {
    //     setTimeout(() => {
    //       // this.show_in_wnd = params.show_in_wnd
    //       // setTimeout(() => {
    //       //   this.setAPI()
    //       // }, 200)
    //     });
    //   }
    // }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  // async   setAPI() {
  //   if (this.iframe && this.iframe.nativeElement && this.iframe.nativeElement.contentWindow) {
  //     let msg = {
  //       orig_href: this.orig_href
  //     }
  //     let smgs = JSON.stringify(msg)
  //     this.iframe.nativeElement.contentWindow.postMessage(smgs);
  //
  //
  //
  //     window.addEventListener("message", (event) => {
  //       // extract the data from the message event
  //       const {
  //         data
  //       } = event;
  //
  //       // display it in our textarea as formatted JSON
  //
  //       try {
  //         if (data && typeof data === 'string') {
  //           let asset = getAssetFromURL(data)
  //           if (asset) {
  //             this.pagesState.dispatch(new ShowAsset(asset))
  //             this.wss_service.sendMessageToOtherMembers({ show_asset: asset })
  //             this.pagesState.dispatch(new ShowAPI(false))
  //           }
  //         }
  //       } catch (e) {
  //         console.error(e)
  //       }
  //     });
  //   } else {
  //     console.error("problem loading iframe")
  //   }
  // }
  showHideAPI() {
    this.pagesState.dispatch(new ShowAPI(false))
  }
}
