import { Component, EventEmitter, Output } from '@angular/core';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { ShowCC } from 'src/app/pages/pages.actions';
import { CreditCardComponent } from 'src/app/pages/profile/billing/credit-card/credit-card.component';
import { checkPhone } from 'src/app/utils';

@Component({
  selector: 'kpay-info',
  templateUrl: './info.component.html',
  styleUrls: ['../../pages/profile/billing/credit-card/credit-card.component.scss', './info.component.scss']
})
export class KPayInfoComponent extends CreditCardComponent {
  phone: string = ""

  closeIt() {
    this.canvasStore.dispatch(new SelectToolOpts("kps1")) //To change the bottom keyzii page
  }
  changed() {
    let ph = checkPhone(this.phone)
    if (ph.length > 0) {
      this.phone = ph
    }
    if (

      this.first_name.length < 1
      || this.last_name.length < 1
      || this.cia_name.length < 1
      || this.bInvalidEmail
      // || ph.length != 12
    ) {
      this.bReady = false
    } else {
      this.phone = ph
      this.bReady = true
    }
  }
  sendReq() {
    if (!this.bReady) {
      return
    }
    let req = {
      first_name: this.first_name,
      last_name: this.last_name,
      cia_name: this.cia_name,
      email: this.email,
      phone: this.phone,
    }
    this.pay_service.sendRequest(req)
    this.closeIt()
  }
}
