import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'k-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {
  _progress: number = 100

  @Input() set value(v: number) {
    this._progress = v;
  }

  @Output() valueChange: EventEmitter<number> = new EventEmitter();
  @ViewChild('outComponent') outComponent: ElementRef | undefined;
  ngAfterViewInit() {
    if (this.outComponent) {
      this.outComponent.nativeElement.onmousemove = onMouseMove
    }
    let me = this
    function onMouseMove($event: any) {
      $event.stopPropagation()
      if ($event.buttons == 1) {
        //
        me._progress = Math.floor($event.offsetX / ($event.srcElement.clientWidth - 10) * 100)
        if (me._progress > 100) {
          me._progress = 100
        }
        me.valueChange.emit(me._progress)
      }
    }
  }

}
