
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Store } from '@ngrx/store';
import { WSSService } from 'src/app/wss/wss.service';
import { WSSState } from 'src/app/wss/wss.model';
import { lastMsg } from 'src/app/wss/wss.selectors';

//******************************************************************************
// Host
//******************************************************************************
//SKD Doc
// https://matterport.github.io/showcase-sdk/docs/sdk/reference/current/index.html
// include   <script src="https://static.matterport.com/showcase-sdk/latest.js"></script>
// in index.html

@Component({
  selector: 'matterport',
  templateUrl: './matterport.component.html',
  styleUrls: ['./matterport.component.scss']
})
export class MatterportComponent {
  //console at https://my.matterport.com/settings/account/devtools?organization=4HL7JPwJcee
  //active token 8165985f7e93e5a0, public keys h2pcf8ax0kwwmugtepwtbdaeb

  //The World tour JbaCzgR5oDg&amp=&ts=1%3Bbrand%3D0
  // https://my.matterport.com/show?m=j4RZx7ZGM6T&title=0&qs=1&hr=0&brand=0&help=0&play=1&dh=0&fp=0

  private iframe: HTMLIFrameElement | undefined;
  private sdk: any = null;
  private applicationKey: string = "h2pcf8ax0kwwmugtepwtbdaeb"
  public url: any;
  token: string | undefined;
  @Output() data = new EventEmitter<any>();
  // @Input() set goToId(pose: string | undefined) {
  //   if (pose) {
  //     this.moveToId(pose)
  //   }
  // }

  @Input() asset: any
  @Input() set id(vid: any) {
    if (vid) {
      let url = vid
      if (vid.token) {
        this.token = vid.token
        url = 'https://my.matterport.com/show/?m=' + vid.vid + "&auth=Bearer " + this.token + "&title=0&qs=1&hr=1&brand=0&help=0&play=1&dh=0&fp=0"// "auth=Bearer," + this.token
      } else {
        url = "https://my.matterport.com/show?m=" + vid + "&title=0&qs=1&hr=1&brand=0&help=0&play=1&dh=0&fp=0"

      }
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      console.log("Space URL " + url)
    }
  }

  interval: any
  subscriptions: any[] = []
  last_send_at: number = 0;
  last_rcv_at: number = 0;


  intersectionCache: any;
  poseCache: any;

  zoom: number = 10;
  constructor(
    public sanitizer: DomSanitizer,
    public wss_service: WSSService,
    private wssState: Store<WSSState>,
  ) {
    console.log("matterport")
    let vid = "j4RZx7ZGM6T"
    let url = "https://my.matterport.com/show?m=" + vid + "&title=0&qs=1&hr=0&brand=0&help=0&play=1&dh=0&fp=0"
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnDestroy() {
    clearInterval(this.interval)
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  async ngAfterViewInit() {
    // this.showMenuIcon()

    async function waitUntil(condition: () => boolean) {
      return new Promise<void>((resolve, reject) => {
        const intervalId = setInterval(() => {
          if (condition()) {
            clearInterval(intervalId);
            resolve();
          }
        }, 30);
      });
    };

    //Set up the sdk
    let me = this;
    this.iframe = <HTMLIFrameElement>document.getElementById("sdk-iframe");
    try {
      // if (this.token) {
      //   this.sdk = await (window as any).MP_SDK.connect(this.iframe, {
      //     auth: this.token,
      //   });
      // } else {
      this.sdk = await (window as any).MP_SDK.connect(this.iframe, this.applicationKey, '3.4');

      // this.modelData = await this.sdk.Model.getData()
      // // this.modelData.sweeps.forEach(sweep => {
      // // if (sweep.floor < 0) {
      // //   this.sweeps.push(sweep)
      // // }
      // // });
      // console.log('Model data:' + JSON.stringify(this.modelData));
      // this.data.emit(this.modelData)

      this.sdk.Pointer.intersection.subscribe(function(intersection: any) {
        me.intersectionCache = intersection;
        // console.log("#In 1" + JSON.stringify(intersection))
      });

      // }
      let appState: any = null;
      const appSub = this.sdk.App.state.subscribe((state: any) => {
        appState = state;
      });

      // this.sdk.Mattertag.data.subscribe({
      //   onAdded: function(index, item, collection) {
      //     console.log('#In Mattertag added to the collection', index, item, collection);
      //   },
      //   onRemoved: function(index, item, collection) {
      //     console.log('#In Mattertag removed from the collection', index, item, collection);
      //   },
      //   onUpdated: function(index, item, collection) {
      //     console.log('#In Mattertag updated in place in the collection', index, item, collection);
      //   }
      // });


      await waitUntil(() => {
        return appState && appState.phase === this.sdk.App.Phase.PLAYING;
      });
    } catch (e: any) {
      console.error("NO SDK " + e)
      return;
    }
    if (!this.sdk) {
      console.error("NO SDK1 ")
    }

    //Step
    let startTime: number = new Date().getTime()
    const millisecondsPerStep = 15;
    function getCurrentStep() {
      const currentTime = new Date().getTime();
      return Math.ceil((currentTime - startTime) / millisecondsPerStep);
    }

    //Send Events to the other side
    function sendEvent(ev_type: string, args: any) {
      let jmsg: any = { matterport: { type: ev_type, args: args, step: getCurrentStep() } }
      me.sendToClient(jmsg)
    }
    this.sdk.on(this.sdk.Sweep.Event.EXIT, (...args: any) => {
      let jmsg: any = { matterport: { type: this.sdk.Sweep.Event.EXIT, args: args, step: getCurrentStep() } }
      me.sendToClient(jmsg)
    })




    //    sendEvent(this.sdk.Sweep.Event.EXIT, args));
    this.sdk.on(this.sdk.Mode.Event.CHANGE_START, (...args: any) => sendEvent(this.sdk.Mode.Event.CHANGE_START, args));
    this.sdk.on(this.sdk.Mode.Event.CHANGE_END, (...args: any) => sendEvent(this.sdk.Mode.Event.CHANGE_END, args));

    //send zoom to the other side
    this.sdk.Camera.zoom.subscribe(function(zoom: any) {
      me.zoom = zoom;
      let jmsg: any = { matterport: { type: "zoom", zoom: zoom.level, step: getCurrentStep() } }
      me.sendToClient(jmsg)
      console.log('Current zoom is ', zoom.level);
    })


    //Get the current cameraPose
    this.sdk.Camera.pose.subscribe((pose: any) => {
      let jmsg: any = { matterport: { type: "pose", pose: pose, step: getCurrentStep() } }
      me.sendToClient(jmsg)
      me.poseCache = pose;
      me.pose = pose;
      // console.log('#In Current position is ', pose.position);
      // console.log('#In Rotation angle is ', pose.rotation);
      // console.log('#In Sweep UUID is ', pose.sweep);
      // console.log('#In View mode is ', pose.mode);

    });
    this.sdk.Mode.current.subscribe(function(mode: any) {
      // the view mode has changed
      console.log('#In Current view mode is is ', mode);
    });
    this.messagesDaemon() //start the daemon
    //****************************************************************************
    // receive messages
    //****************************************************************************

    this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg) {
        if (msg.hasOwnProperty("matterport")) {
          this.processMsg(msg)
        }
      }
    })
  }
  processMsg(kmsg: any) {
    if (kmsg) {
      let now = new Date().getTime()
      let dif = now - this.last_send_at
      if (dif > 1000) {
        this.last_rcv_at = now
        if (kmsg.matterport) {
          let jmsg = kmsg.matterport
          let mtype = jmsg.type
          if (mtype == this.sdk.Sweep.Event.EXIT) {
            console.log(" start Sweep")
            this.sdk.Sweep.moveTo(jmsg.args[1], {
              transition: this.sdk.Sweep.Transition.FLY,
            })
              .finally(() => {
                console.log(" Sweep done")

              });
          } else if (mtype == "pose") {
            let pmsg = jmsg.pose
            if (pmsg.rotation) {
              let rmsg = pmsg.rotation
              console.log(" start rotation")
              this.sdk.Camera.setRotation({
                x: rmsg.x,
                y: rmsg.y,
              })
                .finally(() => {
                  console.log(" rotation done")
                });
            }
          } else if (mtype == "zoom") {
            console.log(" zoom " + jmsg.zoom)
            this.sdk.Camera.zoomTo(jmsg.zoom)
          }
        }
      } else {
        console.error("Rejected " + dif + " " + JSON.stringify(kmsg))
      }
    }
  }
  //****************************************************************************
  //Send Message
  //****************************************************************************
  last_msg: number = 0;

  msg_to_send: any = {}// we keep them by type
  msg_keys: string[] = []
  messagesDaemon() {
    this.msg_keys = [
      this.sdk.Sweep.Event.EXIT,
      this.sdk.Mode.Event.CHANGE_START,
      this.sdk.Mode.Event.CHANGE_END,
      "zoom",
      "pose",
      "state",
    ]
    setInterval(() => {
      let now = new Date().getTime()
      this.msg_keys.forEach((key: string) => {
        if (this.msg_to_send[key]) {
          let jmsg = this.msg_to_send[key]
          if (now - jmsg.send_at > 100) { //half a second debounce
            if (now - this.last_rcv_at > 1000) {
              this.wss_service.sendMessageToOtherMembers(jmsg)
              delete this.msg_to_send[key]
              // console.log("--> " + JSON.stringify(jmsg))
              this.last_send_at = new Date().getTime()
            } else {
              // console.error("Did not send " + JSON.stringify(jmsg))
            }
          }
        }
      })
    }, 30)
  }


  sendToClient(jmsg: any) {
    if (jmsg.matterport) {
      let mtype = jmsg.matterport.type
      let index = this.msg_keys.indexOf(mtype)
      if (index < 0) {
        console.error("Invalid message type " + JSON.stringify(jmsg))
        return;
      }
      jmsg.send_at = this.last_send_at
      this.msg_to_send[mtype] = jmsg
    }
  }
  //****************************************************************************
  // Menu support in site june 23
  //****************************************************************************
  pose: any //used to pass the camera pano to the k-menu

}
