import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ControlsState } from '../controls.model';

@Component({
  selector: 'xxxphone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent {
  @Input() round: boolean = false
  @Input() background = '#F5423B';
  constructor(
    public controlsState: Store<ControlsState>,
  ) {
    // console.log("phone ")
  }



}
