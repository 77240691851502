import { Component, ElementRef, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { hostUrl } from '../../pages.selectors';
import { changedSlots, fromToEvents } from '../calendar.selectors';
import { formatDate } from '@angular/common';
import { Store } from '@ngrx/store';
import { CalendarState } from '../calendar.model';
import { CalendarService } from '../calendar.service';
import { PagesState } from '../../pages.model';
import { Clipboard } from '@angular/cdk/clipboard'
import { NotifyError, NotifyUser } from '../../pages.actions';
//opened by call icon in the heade
@Component({
  selector: 'appts-wnd',
  templateUrl: './appts-wnd.component.html',
  styleUrls: ['./appts-wnd.component.scss']
})
export class ApptsWndComponent {
  orig_events: any[] | undefined;
  events: any[] = [];
  host_url: string | undefined
  search: string = ""
  fdate: string = ""
  date: Date = new Date()
  bShowSelTime: boolean = false
  opened_index: number = -1
  @ViewChild('dt') dt: ElementRef | undefined | undefined
  subscriptions: any[] = []
  minDate: string = "";
  constructor(
    public calendarStore: Store<CalendarState>,
    public calendar_service: CalendarService,
    public clipboard: Clipboard,
    public pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
    @Inject(LOCALE_ID) public locale: string,

  ) {
  }
  ngOnInit() {
    console.log("appts-wnd")
    // this.getEvents()
    this.setFDate()

    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
    this.subscriptions.push(this.calendarStore.select(fromToEvents).subscribe((events: any[]) => {
      if (events) {
        this.orig_events = events;
        this.changedSearch()
      }
    }))
    this.calendar_service.getDayEvents(this.date)
    this.setMinDatel()
  }
  setMinDatel() {

    var dtToday = new Date();

    var month: number = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    let smonth = month.toString();
    if (month < 10)
      smonth = '0' + smonth;
    let sday = day.toString();
    if (day < 10)
      sday = '0' + sday

    this.minDate = year + '-' + smonth + '-' + sday;


  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  getLink(event: any) {
    let _id = event._id
    let link = "https://" + this.host_url + "?event_id=" + _id
    if (event.uri) {
      link = event.uri
    } else {
      let _id = event._id
      link = "https://" + this.host_url + "?event_id=" + _id
    }
    this.clipboard.copy(link);
    this.pagesState.dispatch(new NotifyUser("Link copied"))
  }
  getName(event: any) {
    if (event.name) {
      return event.name
    }
    if (event.email) {
      return event.email
    }
    return "N/A"
  }
  getDayLbl(event: any) {
    const today = new Date();
    let date = new Date(event.start)

    if (today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth()) {
      let t_md = today.getDate()
      let md = date.getDate()
      if (t_md === md) {
        return "Today";
      }
      t_md++
      if (t_md === md) {
        return "Tomorrow";
      }
    }
    let day = date.getDay()
    const wday = ["Sunday", "Monday", "Tuesday", "Thursday", "Friday", "Saturday"]
    return wday[day]
  }
  getDate(event: any) {
    return new Date(event.start)
  }
  getFromTo(event: any) {
    let d_from = new Date(event.start)
    let d_to = new Date(event.end)
    let rv = formatDate(d_from, 'hh:mm a', this.locale) + " - " + formatDate(d_to, 'hh:mm a', this.locale)
    return rv
  }

  changedDate($event: string) {
    let dt = $event.replace("-", "/");
    this.date = new Date(dt)
    // this.getEvents()
    this.setFDate()
  }
  setFDate() {
    this.fdate = formatDate(this.date, 'd MMM yyyy', this.locale)
    // this.fdate += " '" + formatDate(this.date, 'yy', this.locale)
    this.calendar_service.getDayEvents(this.date)

  }
  toggleDate() {
    if (this.dt) {
      this.dt.nativeElement.showPicker()
    }
  }
  changedSearch() { //filter by name
    console.log("filter by name")

    this.events = []
    if (this.orig_events) {
      let now = new Date().getTime()
      for (let i = 0; i < this.orig_events.length; i++) {
        let event = this.orig_events[i]
        if (this.search.length == 0) {
          this.events.push(event)
        } else {
          if (event.name) {

            if (event.name.indexOf(this.search) >= 0)
              this.events.push(event)
          }

        }
      }
    }
  }
  async getEvents() {
    try {
      let new_time = this.date.getTime()
      let date = new Date(new_time)
      date.setHours(0, 0, 0, 0);
      let from = date.getTime()
      // let day = date.getDay()
      // from -= day * 24 * 60 * 60 * 1000
      let to = (from + 7 * 24 * 60 * 60 * 1000) - 1
      let to_date = new Date(to)
      console.log("from" + new Date(from) + " to " + to_date)

      this.calendar_service.getFromToEvents(from, to)
    } catch (e) {
      console.error(e)
    }
  }
  openIndex(i: number) {
    if (this.opened_index == i) {
      this.opened_index = -1
    } else {
      this.opened_index = i
    }
  }
  async delEvent(event: any) {
    try {
      if (event._id) {
        await this.calendar_service.delEvent(event._id)
      } else if (event.google_id) {
        await this.calendar_service.delGoogleEvent(event.google_id)
      } else {
        this.pagesState.dispatch(new NotifyError("no event id"))
        return
      }
      this.getEvents()
      this.pagesState.dispatch(new NotifyUser("Appointment deleted"))
      this.opened_index = -1
    } catch (e) {
      console.error(e)
    }
  }
}
