import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetVideo } from 'src/app/k-video/k-video.actions';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { streams } from 'src/app/k-video/k-video.selectors';
import { DidStep, SelectToolOpts, SignerLic, SignerPic } from '../../canvas.actions';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { PagesState } from 'src/app/pages/pages.model';
import { showAsset } from 'src/app/pages/pages.selectors';
import { base64ToFile } from 'src/app/utils';
import { progress } from '../../canvas.selectors';
import { CanvasUploaderService } from '../../canvas-uploader.service';

@Component({
  selector: 'get-pic-or-upload',
  templateUrl: './get-pic-or-upload.component.html',
  styleUrls: ['./get-pic-or-upload.component.scss']
})
export class GetPicOrUploadComponent {
  img_src: string = ""
  img_type: string = "image/png"
  crop_prompt: string = ""
  @ViewChild('videoElement') videoCapture: ElementRef | undefined;

  @Input() target: string = "user";
  bHandlersReady: boolean = false
  @ViewChild('viewPort') viewPort: ElementRef | undefined;
  @ViewChild('topLeft') topLeft: ElementRef | undefined;
  @ViewChild('topRight') topRight: ElementRef | undefined;
  @ViewChild('bottomLeft') bottomLeft: ElementRef | undefined;
  @ViewChild('bottomRight') bottomRight: ElementRef | undefined;

  @Input() set image(img_src: string) {
    this.img_src = img_src

    if (this.img_src.length == 0) {
      this.hide_video = false;
    }
  }
  @Output() imageChange: EventEmitter<string> = new EventEmitter();
  prompt: string = "Take the picture"
  blob: Blob | undefined
  hide_video: boolean = false;
  done_color: string = "#DDD"

  left: number = -100;
  top: number = 0;
  bottom: number = 0;
  right: number = -100;
  bTakePic: boolean = false
  stream: MediaStream | undefined


  bShowSpinner: boolean = false;
  uploader: FileUploader | undefined
  contract_id: string | undefined
  progress: number = 0
  next_text: string = "Next"
  subscriptions: any[] = []
  constructor(
    public upload_service: CanvasUploaderService,
    private kVideoState: Store<KVideoState>,
    public canvasStore: Store<CanvasState>,
    private pagesState: Store<PagesState>,
  ) {
    console.log("get-pic-or-upload")
    this.setUpLoader()
    this.subscriptions.push(this.canvasStore.select(progress).subscribe((progress: any) => {
      this.progress = progress.progress
      if (this.progress == 100) {
        this.next_text = "Done"
      }
    }))

    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        if (asset.type == "contract") {
          this.contract_id = asset._id
        }
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.subscriptions.push(this.kVideoState.select(streams).subscribe((streams: any) => {
      let stream = streams["me"]
      if (stream) {
        this.stream = stream
      }
    }))
  }


  setMouseHandlers() {
    let me = this
    let target: any | undefined
    let mouse_x: number = 0;
    let mouse_y: number = 0

    let orig_left: number = 0;
    let orig_top: number = 0

    function onAnchorMouseDown($event: any) {
      $event.preventDefault()
      target = $event.currentTarget;
      if (me.viewPort) {
        me.viewPort.nativeElement.focus()
      }
      mouse_x = $event.clientX
      mouse_y = $event.clientY

      orig_left = target.offsetLeft
      orig_top = target.offsetTop
      console.log("#Anchor down " + target.dataset.id)

    }

    function onViewPortMouseMove($event: any) {
      if (target) {
        let delta_x = mouse_x - $event.clientX
        let delta_y = mouse_y - $event.clientY
        let left = orig_left - delta_x
        let top = orig_top - delta_y
        console.log("#Anchor move " + target.dataset.id)
        if (target.dataset.id == "bottomRight") {
          me.right = left
          me.bottom = top
        } else if (target.dataset.id == "bottomLeft") {
          me.left = left
          me.bottom = top
        } else if (target.dataset.id == "topLeft") {
          me.left = left
          me.top = top
        } else if (target.dataset.id == "topRight") {
          me.right = left
          me.top = top
        }
      }
    }

    function onViewPortMouseUp($event: any) {
      console.log("#Anchor up " + target.dataset.id)
      target = undefined
    }


    function setAnchorMouseHandler(el: ElementRef | undefined, label: string) {
      if (el) {
        el.nativeElement.dataset.id = label
        el.nativeElement.addEventListener('touchstart', onAnchorMouseDown, false);
        el.nativeElement.addEventListener('pointerdown', onAnchorMouseDown, false);

        el.nativeElement.addEventListener('touchstart', onAnchorMouseDown, false);
        el.nativeElement.addEventListener('pointerdown', onAnchorMouseDown, false);
      }
    }
    if (this.viewPort) {
      this.bHandlersReady = true
      this.bottom = this.viewPort.nativeElement.clientHeight - 10;
      this.right = this.viewPort.nativeElement.clientWidth - 10;

      this.viewPort.nativeElement.addEventListener('touchend', onViewPortMouseUp, false);
      this.viewPort.nativeElement.addEventListener('pointerup', onViewPortMouseUp, false);

      this.viewPort.nativeElement.addEventListener('pointermove', onViewPortMouseMove, false);
      this.viewPort.nativeElement.addEventListener('touchmove', onViewPortMouseMove, false);

      setAnchorMouseHandler(this.topLeft, 'topLeft')
      setAnchorMouseHandler(this.topRight, 'topRight')
      setAnchorMouseHandler(this.bottomLeft, 'bottomLeft')
      setAnchorMouseHandler(this.bottomRight, 'bottomRight')
    }
  }

  async takeThePic() {

    this.done_color = "#000"//"#40BE5C"
    if (this.prompt == "Take it again") {
      this.prompt = "Take the picture"
      this.hide_video = false;
      return
    }


    if (this.videoCapture) {
      var canvas = document.createElement('canvas');
      canvas.id = "CursorLayer";
      canvas.width = 320
      canvas.height = 240;
      canvas.style.position = "absolute";
      // var body = document.getElementsByTagName("body")[0];
      // body.appendChild(canvas);
      var context = canvas.getContext("2d");
      if (context && this.videoCapture) {
        context.imageSmoothingEnabled = false;



        context.drawImage(this.videoCapture.nativeElement,
          160, 140, 400, 300,  //6he video is 480x360, so 80+320+80, 60+240+60
          0, 0, 320, 240);

        this.img_src = canvas.toDataURL("image/png")
        this.imageChange.emit(this.img_src)
        this.hide_video = true;
        this.prompt = "Take it again"
        this.bTakePic = false;
      }
    }
  }

  async  done() {
    // this.cropImage()
    if (this.img_src) {
      console.log("take pic " + this.img_src.length)
      let imageBytes: ArrayBuffer = await base64ToFile(this.img_src)
      var byteArray = new Uint8Array(imageBytes);
      this.blob = new Blob([byteArray]);

      this.canvasStore.dispatch(new SignerLic(this.img_src, this.img_type))
      this.canvasStore.dispatch(new DidStep("driver_lic"))
    }
  }
  cancel() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }

  async takingPic() {
    function delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    this.bTakePic = true
    this.kVideoState.dispatch(new SetVideo(true))
    let bWaitingForStream: boolean = true
    do {
      await delay(100)
      if (this.videoCapture && this.stream) {
        let vt = this.stream.getVideoTracks()
        if (vt.length > 0) {
          this.videoCapture.nativeElement.srcObject = this.stream//caller for caller
          bWaitingForStream = false
        }
      }
    } while (bWaitingForStream)
  }
  fileToUpload: File | null = null;
  handleFileInput(_evt: any) {

    let files: FileList = _evt.target.files
    this.fileToUpload = files.item(0);

    console.log("Selected to upload")
    this.bShowSpinner = true;
    if (this.uploader) {
      this.uploader.uploadAll();
    }
  }
  canceledFileInput() {
    console.log("caceled upload")
    this.bShowSpinner = false;
  }
  dropped($event: any) {
    console.log("dropped file")
    console.log("Selected to upload")
    this.bShowSpinner = true;
    if (this.uploader) {
      this.uploader.uploadAll();
    }
  }
  // @HostListener('drop', ['$event'])
  // public onDrop(event: any): void {
  //   event.preventDefault();
  //   event.stopPropagation();
  // }
  setUpLoader() {

    this.uploader = this.upload_service.setUpUploader("image")
    this.uploader.onCompleteItem = async (item: FileItem, response: string, status: number, _header: any) => {
      if (status == 200) {
        // let picture = JSON.parse(rsponse)
        this.bShowSpinner = false
        this.img_src = 'data:image/jpeg;base64,' + response //picture.url
        this.img_type = item._file.type
        this.imageChange.emit(this.img_src)

        // "key": jres.key,
        // "url": jres.url,
        // "exp": jres.exp

      }
    }

  }

}
