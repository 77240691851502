<div class="out">
  <div class="row header">
    <div class="day def_c" *ngFor="let wd of week_days">
      {{wd}}
    </div>
  </div>
  <div class="row" *ngFor="let row of rows">
    <div class="day" *ngFor="let day of row" [ngClass]="{disable_c : !day.isSelectable, today: day.isSelected, can_sel:day.isSelectable }"  kzLib (click)="setDate(day)">
      {{day.day}}
    </div>
  </div>
</div>
