import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';

import { WSSState } from 'src/app/wss/wss.model';
import { PagesState } from 'src/app/pages/pages.model';
import { callerEmail, callerFirstName, gotAssets, iAmManager, isPhone, managerEmail, managerName, myEmail, myName } from 'src/app/pages/pages.selectors';
import { ChatState } from '../chat.model';
import { chatMsgs, gotChat, openChat } from '../chat.selectors';
import { callerId, managerId, repId } from 'src/app/wss/wss.selectors';
import { ChatService } from '../chat.service';
import { getAssetFromURL } from 'src/app/utils';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  messages: any[] = []
  text_prompt: string = "Write a reply..." //starst with Send a Message...
  in_text: string = ""
  @ViewChild('scroll_container') public myScrollContainer: ElementRef | undefined;

  isPhone: boolean = false;

  chat: any = {}
  caller_id: string = ""
  caller_name: string | undefined = ""
  caller_email: string | undefined
  rep_id: string | undefined
  rep_name: string | undefined
  rep_email: string | undefined
  manager_id: string | undefined
  manager_name: string | undefined
  manager_email: string | undefined
  bChatNeedsUpdate: boolean = true

  msg_from_id: string | undefined
  bManager: boolean = false

  isOpen: boolean = false;
  assets: any = {}
  chatStore: any;

  bShowChat: boolean = false
  subscriptions: any[] = []
  preview_asset: any
  preview_top: number = 10
  constructor(
    public chat_serivce: ChatService,
    public pagesState: Store<PagesState>,
    public wssState: Store<WSSState>,
    public chatState: Store<ChatState>,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("chat")

    this.subscriptions.push(this.chatState.select(openChat).subscribe((bOpen: boolean) => {
      this.bShowChat = bOpen
    }))

    this.subscriptions.push(this.pagesState.select(gotAssets).subscribe((assets: any[]) => {
      this.assets = assets
    }))

    this.subscriptions.push(this.pagesState.select(iAmManager).subscribe((manager: boolean) => {
      this.bManager = manager
      if (manager) {
        this.msg_from_id = this.manager_id
      } else {
        this.msg_from_id = this.rep_id
      }
    }))
    this.subscriptions.push(this.wssState.select(callerId).subscribe(async (id: string | undefined) => {
      if (id) {
        this.caller_id = id
      }
    }))
    this.subscriptions.push(this.wssState.select(repId).subscribe(async (id: string | undefined) => {
      this.rep_id = id
      if (!this.bManager) {
        this.msg_from_id = this.rep_id
      }
    }))
    this.subscriptions.push(this.wssState.select(managerId).subscribe(async (id: string | undefined) => {
      this.manager_id = id
      if (this.bManager) {
        this.msg_from_id = this.manager_id
      }
    }))


    this.subscriptions.push(this.chatState.select(chatMsgs).subscribe((msgs: any[]) => {
      setTimeout(() => {
        this.messages = msgs
        this.bChatNeedsUpdate = this.messages.length == 0//a new chat
        this.scrollToBottom();
      });
    }))
    this.subscriptions.push(this.pagesState.select(gotChat).subscribe((chat: any) => {
      this.chat = chat
      if (chat.caller_name) {
        this.caller_name = chat.caller_name
      }
      if (chat.caller_email) {
        this.caller_email = chat.caller_email
      }
      if (chat.rep_name) {
        this.rep_name = chat.rep_name
      }
      if (chat.rep_email) {
        this.rep_email = chat.rep_email
      }
      if (chat.manager_name) {
        this.manager_name = chat.manager_name
      }
      if (chat.manager_email) {
        this.manager_email = chat.manager_email
      }
    }))
    this.subscriptions.push(this.pagesState.select(myName).subscribe((name: string) => {
      if (name) {
        this.rep_name = name
        this.bChatNeedsUpdate = !this.chat.rep_name
      }
    }))
    this.subscriptions.push(this.pagesState.select(myEmail).subscribe((email: string) => {
      if (email) {
        this.rep_email = email
        this.bChatNeedsUpdate = !this.chat.rep_email
      }
    }))
    this.subscriptions.push(this.pagesState.select(callerFirstName).subscribe((name: string) => {
      if (name) {
        this.caller_name = name
        this.bChatNeedsUpdate = !this.chat.caller_name
      }
    }))
    this.subscriptions.push(this.pagesState.select(callerEmail).subscribe((email: string) => {
      if (email) {
        this.caller_email = email
        this.bChatNeedsUpdate = !this.chat.caller_email
      }
    }))
    this.subscriptions.push(this.pagesState.select(managerName).subscribe((name: string) => {
      if (name) {
        this.manager_name = name
        this.bChatNeedsUpdate = !this.chat.manager_name
      }
    }))
    this.subscriptions.push(this.pagesState.select(managerEmail).subscribe((email: string) => {
      if (email) {
        this.manager_email = email
        this.bChatNeedsUpdate = !this.chat.manager_email
      }
    }))
    this.subscriptions.push(this.pagesState.select(isPhone).subscribe((isPhone: boolean) => {
      setTimeout(() => this.isPhone = isPhone);
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.scrollToBottom();
  }
  ngOnInit(): void {
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        if (this.myScrollContainer) {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        }
      })

    } catch (err) { }
  }
  keyUp($event: any) {
    // console.log("key up "+$event.which)
    if ($event.which == 9 || $event.which == 13) {
      this.send()
    }
    this.preview_asset = undefined
  }
  send(val?: any | undefined) {


    if (this.in_text.length > 0) {
      let msg: any = {

        chat_msg: {
          chat_text: this.in_text,
          time: new Date().getTime(),
          id: this.msg_from_id
        }
      }

      if (this.bChatNeedsUpdate) {
        let members: any = {

        }
        if (this.caller_id) {
          members[this.caller_id] = {
            caller_name: this.caller_name,
            caller_email: this.caller_email,
            roll: "caller"
          }
        }
        if (this.rep_id) {
          members[this.rep_id] = {
            rep_name: this.rep_name,
            rep_email: this.rep_email,
            roll: "rep"
          }
        }
        if (this.manager_id) {
          members[this.manager_id] = {
            manager_name: this.manager_name,
            manager_email: this.manager_email,
            roll: "manager"
          }
        }
        msg.members = members

      }

      this.chat_serivce.sendMsg(msg)
      setTimeout(() => {
        this.in_text = ""
      })
    }
  }
  showAsset(asset: any) {
    this.preview_asset = asset

    this.preview_top = asset.top - 350
    console.log("show top " + asset.top + " preview " + this.preview_top)

    if (this.preview_top < 0) {
      this.preview_top = asset.top - 20
      console.log("show top <0 " + asset.top + " preview " + this.preview_top)

    }

  }
  closePreview() {
    this.preview_asset = undefined
  }
}
