import { SVG } from '@svgdotjs/svg.js';
import { AddToUndo, EditComment, GetSignature, SelectId, SetTool, ShowComment } from '../canvas.actions';
import { changeInitials, changeSignature, editComment, selSignerKey, signerEmail, signers } from '../canvas.selectors';
import { Draw } from './draw';

export class kComments extends Draw {
  comments_groups: any = {}
  anchors: any = {} //we only have the ancorh at the tail and is accesd by tge arrow id


  draw_comment: any | undefined
  draw_group: any | undefined  //used to create and move lines
  comment_id: string = ""
  move: string = "no"

  move_from_x: number = 0
  move_from_y: number = 0

  path = "M841.134446 1024a36.790489 36.790489 0 0 1-17.590681-4.46167L490.052242 837.487579h-110.627465C173.74912 837.487579 0 656.095108 0 441.45954V373.035081A376.681947 376.681947 0 0 1 379.424777 0.01024h411.424457A376.681947 376.681947 0 0 1 1170.274012 373.035081v68.278174a369.367735 369.367735 0 0 1-292.568503 363.955218V987.428937a36.571063 36.571063 0 0 1-36.571063 36.571063zM379.424777 72.823226A303.539822 303.539822 0 0 0 73.142126 373.035081v68.278174c0 172.139993 143.13914 323.141911 306.282651 323.141912h119.989657a36.900202 36.900202 0 0 1 17.590682 4.46167L804.563383 926.099265v-150.233927a36.571063 36.571063 0 0 1 30.28084-35.839641C994.184344 712.26826 1097.131886 595.058004 1097.131886 441.45954V373.035081a303.539822 303.539822 0 0 0-306.282652-300.358139h-411.424457z"
  initClass() {
    this.canvasStore.select(editComment).subscribe((action: any) => {
      if (action) {
        if (!action.show) {
          console.log("comment <--" + action.id + " " + action.content)
          let group = this.comments_groups[action.id]
          if (group) {
            group.node.dataset['comment'] = action.content
            this.canvas_service.sendCanvasMsg("comment", "content", action.id, group.svg())
          }
        }
      }
    })
  }

  //****************************************************************************
  // Parent events
  //****************************************************************************

  onMouseDown(evt: any) { //Cal
    //IT appears that when we click inside the anchor, the click happens after the canvas with the cooridnates of the anchor
    if (!this.draw_group && evt.offsetY > 10) {
      console.log("#cc on mouse down !draw_group " + evt.offsetX + ", " + evt.offsetY + " " + new Date().getTime())
      let comment_id = new Date().getTime().toString()
      let offsetX = this.parent_canvas.deScaleX(evt.offsetX)
      let offsetY = this.parent_canvas.deScaleY(evt.offsetY)

      this.newComment(comment_id, offsetX, offsetY)

      let group = this.comments_groups[comment_id]

      this.canvas_service.sendCanvasMsg("comment", "new", comment_id, group.svg())
      this.canvasStore.dispatch(new AddToUndo({
        undo: "new",
        k_type: "comment",
        id: comment_id
      }))
      this.canvasStore.dispatch(new SetTool("none"))



      this.canvasStore.dispatch(new EditComment(true, comment_id, "", offsetX + 10, offsetY + 20))
    } else {
      console.log("#cc on mouse down draw_group " + new Date().getTime())
    }

  }

  //****************************************************************************
  // Copt paste and move erase
  //****************************************************************************



  undo(msg: any) {
    let old_comment = this.comments_groups[msg.id]
    if (old_comment) {
      old_comment.remove()
      this.comments_groups[msg.id] = undefined
    }
    let anchor = this.anchors[msg.id]
    if (anchor) {
      this.canvas.removeChild(anchor)
      this.anchors[msg.id] = undefined
    }
    if (msg.data) {
      let svg = SVG(msg.data)
      let vb
      if (msg.canvas_width) {
        vb = {
          width: msg.canvas_width,
          height: msg.canvas_height
        }
      }
      let rv: any = this.commentFromSVG(svg, vb)
    }

  }


  commentFromSVG(comment: any, fromViewBox?: any) {
    let attr = comment.attr()
    let comment_id = attr["data-id"]
    let k_type = attr["data-k_type"]
    let x = attr["data-x"]
    let y = attr["data-y"]
    if (fromViewBox) {
      x = this.parent_canvas.scaleXToWidth(x, fromViewBox.width)
      y = this.parent_canvas.scaleYtoHeight(y, fromViewBox.height)
    }

    let content = attr["data-comment"]
    this.newComment(comment_id, x, y, content)

  }
  processMsg(msg: any) {
    this.undo(msg)
  }

  removeCommentFromMsg(msg: any) {
    let poly = this.comments_groups[msg.id]
    poly.remove()
    let anchor = this.anchors[msg.id]
    if (anchor) {
      this.canvas.removeChild(anchor)
    }
  }
  newCommentFromMsg(msg: any) {
    // this.comment_id = msg.id
    // this.draw_comment = this.draw_tool.path(this.path)
    //   .size(20, 20)
    // let group = this.draw_tool.group()
    // group.add(this.draw_comment)
    // group.move(msg.data.x, msg.data.y)
    // group.node.dataset['x'] = msg.data.x;
    // group.node.dataset['y'] = msg.data.y;
    // group.node.dataset['comment'] = msg.data.comment
    // this.setFunctions(msg.id, group, msg.data.x, msg.data.y)
  }
  setContentFromMsg(msg: any) {
    let path = this.comments_groups[msg.id]
    if (path) {
      path.node.dataset['comment'] = msg.data
    }

  }
  moveCommentFromMsg(msg: any) {
    if (msg.data) {
      this.comment_id = msg.id
      let group = this.comments_groups[msg.id]
      if (group) {
        group.move(msg.data.x, msg.data.y)
        group.node.dataset['x'] = msg.data.x;
        group.node.dataset['y'] = msg.data.y;
        let anchor = this.anchors[msg.id]
        anchor.style.left = msg.data.x + "px"
        anchor.style.top = msg.data.y + "px"
      }

      // this.poly_array = this.getArrayFromStr(msg.data.points)

      // this.draw_comment.attr({ points: this.poly_array })

    } else {
      console.error("no attr")
    }
  }





  //****************************************************************************
  // Parent events
  //****************************************************************************
  //called when the tool is ellipse



  //****************************************************************************
  // Utilities
  //****************************************************************************
  newComment(comment_id: string, x: number, y: number, content?: string) {
    console.log("new comment " + x + " " + y)
    let group = this.draw_tool.group()
    let draw_comment = this.draw_tool.path(this.path).size(20, 20)
    // draw_comment.fill('#00')
    group.add(draw_comment)
    group.move(x, y)
    group.node.dataset['x'] = x
    group.node.dataset['y'] = y;

    if (content) {
      group.node.dataset['comment'] = content
    } else {
      group.node.dataset['comment'] = ""
    }

    draw_comment.node.dataset['id'] = comment_id
    draw_comment.node.dataset['k_type'] = "comment"
    group.node.dataset['id'] = comment_id
    group.node.dataset['k_type'] = "comment"

    this.comments_groups[comment_id] = group

    this.setFunctions(comment_id, draw_comment, x, y)
    console.log("#cc  undefined 1 " + new Date().getTime())
  }
  setFunctions(comment_id: string, draw_comment: any, x: number, y: number) {
    console.log("#cc set up coment sets group 1 " + new Date().getTime())
    let anchor = this.anchors[comment_id]
    if (!anchor) {
      anchor = document.createElement("div");
      this.anchors[comment_id] = anchor
      this.canvas.appendChild(anchor)
    }
    anchor.style.width = "20px"//page.style.width
    anchor.style.height = "20px"//page.style.height
    anchor.style.position = "absolute"

    anchor.dataset['id'] = comment_id
    // anchor.style.background = "#00ffff40"

    anchor.onmousemove = onCommentMove
    anchor.onmouseenter = onCommentEnter
    anchor.onmouseleave = onCommentLeave
    anchor.onpointerdown = onCommentDown
    anchor.onpointerup = onCommentUp

    anchor.style.left = x + "px"
    anchor.style.top = y + "px"


    // this.canvasStore.dispatch(new SetTool("none"))
    //**************************************************************************
    // Poly Move
    //**************************************************************************
    //The position as it moves
    let x1: number = 0
    let y1: number = 0
    let box: any
    let me = this
    let mouse_down_at: number = 0


    function onCommentMove(this: any, $event: any) {

      if (me.sel_tool_name == 'erase') {
        console.log("coment cursor erase")
        this.style.cursor = 'url(assets/images/delete.svg) 0 14, pointer'
        return
      }
      this.style.cursor = "default"

    }
    function onCommentEnter(this: any, $event: any) {
      if (me.canvas) {
        if (me.move == "no" && me.sel_tool_name == 'none') {
          console.log("coment cursor grab")
          let id = this.dataset['id']
          let group = me.comments_groups[id]
          let comment = group.node.dataset['comment']
          if (comment) {
            if (comment.length > 0) {
              let cx = parseFloat(this.style.left)
              let cy = parseFloat(this.style.top)
              console.log("comment show -->" + id + " " + comment)
              me.canvasStore.dispatch(new ShowComment(true, comment, cx, cy))
            }
          }
        }
      }
    }
    function onCommentLeave($event: any) {
      if (me.canvas) {
        me.canvas.style.cursor = 'auto'
        console.log("coment cursor auto")
        setTimeout(() => {
          me.canvasStore.dispatch(new ShowComment(false))
        }, 1000)
      }
    }
    function onCommentDown(this: any, $event: any) {
      $event.stopPropagation()
      let id = this.dataset['id']
      if ($event.shiftKey) {
        let box = me.getBox(id)
        me.parent_canvas.processMultiSel(id, box, "comment")
        // me.canvasStore.dispatch(new SelectId(id, $event.shiftKey, "comment"))
        return
      }

      console.log("#cc anchor down" + new Date().getTime())

      if (me.sel_tool_name == 'erase' && me.canvas) {
        let group = me.comments_groups[id]
        // let attr = group.attr()
        me.canvasStore.dispatch(new AddToUndo({
          undo: "erase",
          k_type: "comment",
          id: id,
          data: group.svg()
        }))

        group.remove()
        let anchor = me.anchors[id]
        if (anchor) {
          me.canvas.removeChild(anchor)
          me.anchors[id] = undefined
        }

        me.canvas_service.sendCanvasMsg("comment", "erase", id)
        me.canvasStore.dispatch(new EditComment(false))
        return
      }
      function onAnchorMove(this: any, $event: any) {
        if ($event.buttons == 0) {
          return
        }
        let at_x = parseFloat(this.style.left)
        x1 = $event.offsetX + at_x

        let at_y = parseFloat(this.style.top)
        y1 = $event.offsetY + at_y

        this.style.left = x1 + "px"
        this.style.top = y1 + "px"
        me.draw_group.move(x1, y1)
      }

      if (me.sel_tool_name == 'none') {
        mouse_down_at = new Date().getTime()
        console.log("#CM down")



        setTimeout(() => {
          console.log("#CM to " + mouse_down_at + " " + $event.buttons)
          if (mouse_down_at) {
            this.style.cursor = 'grab'
            box = me.getBox(id) //used to caclulate distance for undo
            me.draw_group = me.comments_groups[id]
            if (!me.pointer_id) {
              me.pointer_id = $event.pointerId
              this.setPointerCapture(me.pointer_id)
              this.onpointermove = onAnchorMove

            }
            return
          } else {
            me.canvasStore.dispatch(new ShowComment(false))
            me.draw_group = me.comments_groups[id]
            let content = me.draw_group.node.dataset['comment']
            let x = parseFloat(me.draw_group.node.dataset['x']) + 20
            let y = parseFloat(me.draw_group.node.dataset['y']) + 20
            me.canvasStore.dispatch(new EditComment(true, id, content, x, y))
          }
        }, 500)
      }
    }
    function onCommentUp(this: any, $event: any) {
      console.log("#CM up")
      mouse_down_at = 0;
      this.style.cursor = 'auto'
      this.onpointermove = null;
      if (me.pointer_id) {
        this.releasePointerCapture(me.pointer_id)
        me.pointer_id = undefined
      }
      if (box) {
        let d = (box.x1 - x1) * (box.x1 - x1) +
          (box.y1 - y1) * (box.y1 - y1)
        if (d > 4) {
          let id = this.dataset['id']
          me.parent_canvas.addToUndo({
            undo: "move",
            data: box.svg,
            k_type: "comment",
            id: id,
          })
          me.draw_group.node.dataset['x'] = x1
          me.draw_group.node.dataset['y'] = y1

        }
        box = undefined
      }
    }
  }
  //****************************************************************************
  // getBox returns the frame and back to create the backdiv, points to create the points, svg for undo and data that will be passed back to moveObj
  //****************************************************************************


  getBox(selected_id: string) { //subclassed for mullti select
    let comment = this.comments_groups[selected_id]
    let attr = comment.attr()
    let x = attr['data-x']
    let y = attr['data-y']
    let svg = comment.svg()

    let rv =
    {
      x1: x,
      y1: y,
      x2: x + 20,
      y2: y + 20,
      back: false, //we use owr own back
      new_point: false, //not a polygon
      svg: svg //for the undo
    }
    return rv
  }
  moveDelta(selected_id: string, delta_x: number, delta_y: number) {//subclassed for mullti select
    let anchor = this.anchors[selected_id]
    let group = this.comments_groups[selected_id]
    let attr = group.attr()
    let x = attr['data-x'] + delta_x
    let y = attr['data-y'] + delta_y
    group.node.dataset['x'] = x
    group.node.dataset['y'] = y;
    group.move(x, y)
    anchor.style.left = x + "px"
    anchor.style.top = y + "px"


  }
  copy(selected_id: string) {
    let comment = this.comments_groups[selected_id]

    let svg = comment.svg()
    if (svg) {
      return svg
    }
    return undefined
  }
}
