import { createFeatureSelector, createSelector } from "@ngrx/store";
import { KVideoState } from "./k-video.model";

export const selectKVideoStateState = createFeatureSelector<KVideoState>('k-video');



//******************************************************************************
//
//******************************************************************************

export const checkVideo = createSelector(
  selectKVideoStateState,
  state => state.check_video
)
export const shareOn = createSelector(
  selectKVideoStateState,
  state => state.bShareOn
)
export const audioOn = createSelector(
  selectKVideoStateState,
  state => state.bAudioOn
)
export const videoOn = createSelector(
  selectKVideoStateState,
  state => state.bVideoOn
)
export const streams = createSelector(
  selectKVideoStateState,
  state => state.streams
)
export const showingRemoteShare = createSelector(
  selectKVideoStateState,
  state => state.showing_remote_share
)
export const mediaState = createSelector(
  selectKVideoStateState,
  state => state.media_state
)


export const videoDevId = createSelector(
  selectKVideoStateState,
  state => state.selected_vdev_id
)
export const audioDevId = createSelector(
  selectKVideoStateState,
  state => state.selected_adev_id
)
export const audioSpeakerId = createSelector(
  selectKVideoStateState,
  state => state.selected_a_out_dev_id
)
export const hasVideoCamera = createSelector(
  selectKVideoStateState,
  state => state.bHasVideoCamera
)
