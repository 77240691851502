import { Component, ElementRef, ViewChild } from '@angular/core';
import { PayService } from '../pay.service';

@Component({
  selector: 'payments',
  templateUrl: './payments.component.html',
  styleUrls: ['../../pages/contacts/leads/leads.component.scss', './payments.component.scss']
})
export class PaymentsComponent {
  @ViewChild('content') private content: ElementRef | undefined;

  search: string = ""
  count: number = 0
  in_page: number = 1
  page_size: number = 10
  num_pages: number = 10
  sel_index: number = 1;

  payments: any[] = []
  constructor(
    public pay_service: PayService) {

  }
  ngAfterViewInit() {
    this.getPayments()
  }
  changedSearch() { //filter by name
    this.getPayments()
  }
  selPage($event: any) {
    this.in_page = $event
    this.getPayments()
  }
  async getPayments() {
    try {
      let page_size = 10
      if (this.content) {
        let h = this.content.nativeElement.clientHeight
        if (h) {
          page_size = Math.floor(h / 60);
          setTimeout(() => {
            this.page_size = page_size
          }, 1)
        }
      }

      let rv = await this.pay_service.getPayments(this.in_page, page_size, this.sel_index, this.search)
      this.payments = rv.payments
      this.count = rv.count
      this.num_pages = Math.ceil(this.count / page_size)
    } catch (e) {
      console.error(e)
    }
  }
}
