import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'img-slide',
  templateUrl: './img-slide.component.html',
  styleUrls: ['./img-slide.component.scss']
})
export class ImgSlideComponent {
  _asset: any = { images: [] }
  @Input() set asset(a: any) {
    console.log("!! set asset " + a.asset_id)
    this._asset = a;
  }
  @Input() index: number = 0;
  @Output() indexChange: EventEmitter<number> = new EventEmitter();

  constructor(

  ) {
    console.log("img-asset.component")
  }


  ngOnInit() {

  }
  selected(img_index: number) {
    this.indexChange.emit(img_index)
  }
}
