/*
Tha actions that have to do with the chat UI
*/

import * as store from '@ngrx/store';

export enum ChatActionTypes {
  ChatId = '[chat]id',
  GotChat = '[chat]got',
  OpenCloseChat = '[chat]open/close',
  ChatMessages = '[chat]msgs',
  NewMsg = '[chat]new',
}
export class ChatId implements store.Action {
  readonly type = ChatActionTypes.ChatId;
  constructor(public chat_id: string) { }
}
export class GotChat implements store.Action {
  readonly type = ChatActionTypes.GotChat;
  constructor(public chat: any) { }
}

export class OpenCloseChat implements store.Action {
  readonly type = ChatActionTypes.OpenCloseChat;
  constructor(public bOpen: boolean) { }
}
export class ChatMessages implements store.Action {
  readonly type = ChatActionTypes.ChatMessages;
  constructor(public msgs: any[]) { }
}
export class NewMsg implements store.Action {
  readonly type = ChatActionTypes.NewMsg;
  constructor(public msg: any) { }
}

export type ChatAction =
  | OpenCloseChat
  | ChatMessages
  | NewMsg
  | ChatId
  | GotChat
  ;
