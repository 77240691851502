import { Component } from '@angular/core';
import { InCallComponent } from '../in-call/in-call.component';

@Component({
  selector: 'in-call-asset',
  templateUrl: './in-call-asset.component.html',
  styleUrls: ['./in-call-asset.component.scss']
})
export class InCallAssetComponent extends InCallComponent {



}
