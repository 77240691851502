import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { Router } from '@angular/router';
import { menus } from 'src/app/utils';
import { Store } from '@ngrx/store';
import { callId, myId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { SetSignerRoll } from 'src/app/canvas/canvas.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { accOverdue, activeCalls, customizeAccount, debug, myRoll, numActiveCalls, openMenu, showCC } from 'src/app/pages/pages.selectors';
import { Debuggin, NotifyError, ShowAsset } from 'src/app/pages/pages.actions';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: "263px",
      })),
      state('closed', style({
        width: "67px",
      })),
      transition('open => closed', [
        animate('.25s')
      ]),
      transition('closed => open', [
        animate('.25s')
      ]),
    ]),

    trigger('showHide', [
      state('hide', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('show => hide', [
        animate('0.25s')
      ]),
      transition('hide => show', [
        animate('0.25s')
      ]),
    ]),

    trigger('showHideSmall', [
      state('hide', style({
        left: "15px",
        opacity: 0,
      })),
      state('show', style({
        left: "55px",
        opacity: 1,
      })),
      transition('show => hide', [
        animate('0.25s')
      ]),
      transition('hide => show', [
        animate('0.25s')
      ]),
    ]),

  ],
})
export class LeftMenuComponent implements OnInit {
  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  isOpen: boolean = false;
  logo: any = {}
  icon: any = {}
  small_img_src = "assets/images/keyzii.svg"
  img_src = "assets/images/keyzii_logo_primary.svg"

  menus: any[] = []


  _sel_menu: number = 0;
  get sel_menu(): number {
    if (this.router.url == "/profile") {
      return -1
    }
    return this._sel_menu;
  }
  @Input() set sel_menu(value: number) {
    if (this.show_cc) {
      return
    }
    this._sel_menu = value;
    let menu = this.menus[this._sel_menu]
    if (!menu) {
      menu = this.menus[0]
    }
    let navTo: string = menu.nav
    if (!this.my_id) {
      if (this.router.url == "/signup") {
        return
      }
      this.router.navigate(["login"]);
      return
    }
    if (this.bAccOverdue) {
      this.pagesState.dispatch(new NotifyError("Account overdue"))
      return
    }
    if (navTo) {
      this.router.navigate([navTo]);
      localStorage.setItem("nav", this._sel_menu.toString())
      if (navTo == "/assets") {
        this.pagesState.dispatch(new ShowAsset({}));
        //The message is listend to by pages/in-call
        let msg: any = {
          show_asset: "none"
        }
        this.wss_service.sendMessageToMembersAndMe(msg)
      }

      if (navTo == "/assets" || navTo == "/call") {
        this.canvasStore.dispatch(new SetSignerRoll("agent"))
      } else if (navTo == "/manage") {
        this.canvasStore.dispatch(new SetSignerRoll("manager"))
      }
    }
  }
  my_id: string | undefined
  debug: string = "lines"
  subscriptions: any[] = []
  img_w: number = 0
  img_h: number = 0

  show_cc: string | undefined
  bAccOverdue: boolean = false
  bShowLiveCalls: boolean = false

  constructor(
    public router: Router,
    private wssState: Store<WSSService>,
    private canvasStore: Store<CanvasState>,
    private pagesState: Store<PagesState>,
    private wss_service: WSSService,

  ) {
    console.log("left menu")
    this.subscriptions.push(this.pagesState.select(numActiveCalls).subscribe((num: number) => {
      this.bShowLiveCalls = num > 0
    }))
    this.subscriptions.push(this.pagesState.select(accOverdue).subscribe((overdue: boolean) => {
      this.bAccOverdue = overdue
    }))
    this.subscriptions.push(this.pagesState.select(showCC).subscribe((show_cc: string | undefined) => {
      this.show_cc = show_cc
    }))
    this.subscriptions.push(this.pagesState.select(debug).subscribe((debug: string) => {
      this.debug = debug
    }))

    this.subscriptions.push(this.pagesState.select(openMenu).subscribe((open: boolean) => {
      if (open) {
        this.closedIt(false)
      }
    }))
    this.subscriptions.push(this.pagesState.select(myRoll).subscribe((my_roll: string) => {
      if (my_roll) {
        console.log(window.location)
        for (let i = 0; i < menus.length; i++) {
          let menu = Object.assign({}, menus[i])
          if (menu.roll) {
            if (menu.roll == my_roll) {
              this.menus.push(menu)
            }
          } else {
            this.menus.push(menu)
          }
        }
      }
    }))

    let sel = localStorage.getItem("nav")
    let v: number = 0
    if (sel) {
      v = parseInt(sel)
      this.sel_menu = v
    }

    this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      this.my_id = my_id
      this.sel_menu = v //Go to the store one or the first one
    })
    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (call_id) {
        this.sel_menu = 0
      }
    }))

    this.subscriptions.push(this.pagesState.select(customizeAccount).subscribe((customize: any) => {
      if (customize) {
        if (customize.logo) {
          this.logo = customize.logo
          this.img_src = this.logo
        }
        if (customize.icon) {
          this.icon = customize.icon
          this.small_img_src = this.icon
        }
      }
    }))

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngOnInit(): void {
  }
  onLogoeLoad($event: any) {
    console.log("loaded logo")
    let i_h = 50
    let i_w = $event.currentTarget.naturalWidth * i_h / $event.currentTarget.naturalHeight
    if (i_w > 150) {
      i_w = 150
      i_h = $event.currentTarget.naturalHeight * i_w / $event.currentTarget.naturalWidth
    } setTimeout(() => {
      this.img_w = i_w
      this.img_h = i_h
    })
  }
  closedIt($event: boolean) {
    setTimeout(() => this.isOpen = !$event)
    this.closed.emit(!this.isOpen)
  }
  toggleDebug() {
    // if (this.debug == 'lines') {
    //   this.debug = ''
    // } else {
    //   this.debug = 'lines'
    // }
    // this.pagesState.dispatch(new Debuggin(this.debug))
  }
  support() {
    window.open("https://www.keyzii.com/contact")
  }
}
