import { Action } from '@ngrx/store';



export enum CalendarActionTypes {
  // GotEvents = '[calendar] slots',
  ClendarUser = '[calendar] user',
  GotWorkTime = '[calendar]wt',
  RefresDayWeek = '[dw]refresh',
  DeleteEvent = '[event]del',
  GotWeekEvents = '[week]events',
  Showing24 = '[cal]24',
  AddAppointment = '[app]add',
  SaveTmp = '[event]tmp',
  GetFromToEvents = '[events]from to',
  SetSlots = '[slots]got',
  ChangedTZ = '[TZ]changed'
}
export class ChangedTZ implements Action {
  readonly type = CalendarActionTypes.ChangedTZ;
  constructor(public tz: any) { }
}

export class SetSlots implements Action {
  readonly type = CalendarActionTypes.SetSlots;
  constructor(public slots: any) { }
}
export class SaveTmp implements Action {
  readonly type = CalendarActionTypes.SaveTmp;
  constructor(public tmp: any) { }
}
export class AddAppointment implements Action {
  readonly type = CalendarActionTypes.AddAppointment;
  constructor(public slot: any) { }
}
export class Showing24 implements Action {
  readonly type = CalendarActionTypes.Showing24;
  constructor(public bShowing24: boolean) { }
}
export class DeleteEvent implements Action {
  readonly type = CalendarActionTypes.DeleteEvent;
  constructor(public event_id: string) { }
}
export class RefresDayWeek implements Action {
  readonly type = CalendarActionTypes.RefresDayWeek;
  constructor() { }
}
// export class GotEvents implements Action {
//   readonly type = CalendarActionTypes.GotEvents;
//   constructor(public from: number, public to: number, public offset: number, public events: any[]) { }
// }

export class GetFromToEvents implements Action {
  readonly type = CalendarActionTypes.GetFromToEvents;
  constructor(public from: number, public to: number, public offset: number, public events: any[]) { }
}
export class GotWeekEvents implements Action {
  readonly type = CalendarActionTypes.GotWeekEvents;
  constructor(public from: number, public to: number, public offset: number, public events: any[]) { }
}
export class ClendarUser implements Action {
  readonly type = CalendarActionTypes.ClendarUser;
  constructor(public user: any) { }
}

export class GotWorkTime implements Action {
  readonly type = CalendarActionTypes.GotWorkTime;
  constructor(public work_time: any) { }
}

export type CalendarActions =
  // GotEvents
  GetFromToEvents
  | GotWeekEvents
  | GotWorkTime
  | ClendarUser
  | RefresDayWeek
  | DeleteEvent
  | Showing24
  | AddAppointment
  | SaveTmp
  | SetSlots
  | ChangedTZ
  ;
