import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ToolService } from 'src/app/tools/tool.service';
import { WSSState } from 'src/app/wss/wss.model';
import { lastMsg } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';

@Component({
  selector: 'uploaded-video',
  templateUrl: './uploaded-video.component.html',
  styleUrls: ['./uploaded-video.component.scss']
})
export class UploadedVideoComponent {
  _asset: any
  @Input() set asset(a: any | undefined) {
    if (a) {
      this._asset = a;
      // this.sanitized_url = this.sanitizer.bypassSecurityTrustResourceUrl(this._asset.url);//+ "?autoplay=1")
      this.setSecURL(this._asset.key);//+ "?autoplay=1")

    }
  }
  async setSecURL(key: string) {
    try {
      let img_src = await this.tools_service.getKeyURL(key)
      let sanitized_url = this.sanitizer.bypassSecurityTrustResourceUrl(img_src);//+ "?autoplay=1")

      if (this.sanitized_url) {
        this.sanitized_url = sanitized_url
        if (this.uploaded)
          this.uploaded.nativeElement.load();
      } else {
        this.sanitized_url = sanitized_url
      }
    } catch (e) {
      console.error(e + " " + "upload video setSecURL")
    }
  }
  @ViewChild('outComponent') outComponent: ElementRef | undefined;
  @ViewChild('videoUploaded') uploaded: ElementRef | undefined;
  video_volume: number = 20;
  yt_debouncer: number = 0;
  sanitized_url: any
  subscriptions: any[] = []
  constructor(
    public sanitizer: DomSanitizer,
    public wss_service: WSSService,
    private wssState: Store<WSSState>,
    private tools_service: ToolService,
  ) {
    console.log("uploaded video")
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.setDims()
  }
  ngAfterViewInit() {
    let me = this;

    function StartPauseHandler(e: any): void {
      let time = new Date().getTime()
      let d = time - me.yt_debouncer
      if (d > 1000 && me.uploaded && me._asset) {
        console.log("video event " + e.type + " " + me.uploaded.nativeElement.currentTime)
        let msg: any = {
          "video_state": e.type,
          "asset_id": me._asset.asset_id,
          time: me.uploaded.nativeElement.currentTime
        }
        me.wss_service.sendMessageToOtherMembers(msg)
        // this.webrtc_service.videoState(this._asset.asset_id, e.type, this.uploaded.nativeElement.currentTime)
      }
    }
    /*
      Events
        abort
        canplay
        canplaythrough
        durationchange
        emptied
        encrypted
         ended
         error
         interruptbegin
         interruptend
         loadeddata l
         oadedmetadata
         loadstart
         mozaudioavailable
          pause
          play
          playing
          progress
          ratechange
          seeked
          seeking
           stalled
           suspend
           timeupdate
           volumechange
            waiting'
      */
    try {
      if (this.uploaded) {
        this.uploaded.nativeElement.volume = this.video_volume / 100;
        this.uploaded.nativeElement.addEventListener("pause", StartPauseHandler);
        this.uploaded.nativeElement.addEventListener("playing", StartPauseHandler);
        this.uploaded.nativeElement.addEventListener("seeked", StartPauseHandler);
        this.uploaded.nativeElement.addEventListener("loadedmetadata", function(e: any) {
          console.log("Video loaded");

          me.videoHeight = e.srcElement.videoHeight
          me.videoWidth = e.srcElement.videoWidth
          me.setDims()
        }, false);
      }

    } catch (e: any) {
      console.log("Error on capture stream " + e)
    }
    this.subscriptions.push(this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg) {
        if (msg.hasOwnProperty("video_state")) {

          if (this.uploaded) {

            this.yt_debouncer = new Date().getTime()
            this.uploaded.nativeElement.currentTime = msg.time
            if (msg.video_state == "pause") {
              this.uploaded.nativeElement.pause();
            } else if (msg.video_state == "playing") {
              this.uploaded.nativeElement.play();
            }
            //we use current time for all the messages
            // else if (msg.video_state == "seeked") {
            //   this.uploaded.nativeElement.currentTime=msg.time;
            // }
          }
        }
      }
    }))
  }
  videoWidth: number = 0
  videoHeight: number = 0
  video_w: string = "100%"
  video_h: string = "100%"
  video_l: string = "0px"
  video_t: string = "0px"
  setDims() {
    let video_w: number = 300
    let video_h: number = 300
    let video_left: number = 0
    let video_top: number = 0;
    if (this.outComponent) {
      let video_ne = this.outComponent.nativeElement
      // let parent_ne = video_ne.parentElement

      if (this.videoWidth > this.videoHeight) {
        video_w = video_ne.clientWidth
        video_h = this.videoHeight * video_w / this.videoWidth
        if (video_h > video_ne.clientHeight) {
          video_h = video_ne.clientHeight
          video_w = this.videoWidth * video_h / this.videoHeight
          video_top = 0
          video_left = (video_ne.clientWidth - video_w) / 2

        } else {
          video_top = (video_ne.clientHeight - video_h) / 2
          video_left = 0

        }
      } else if (this.videoWidth < this.videoHeight) {
        video_h = video_ne.clientHeight
        video_w = this.videoWidth * video_h / this.videoHeight
        if (video_w > video_ne.clientWidth) {
          video_w = video_ne.clientWidth
          video_h = this.videoHeight * video_w / this.videoWidth
          video_top = (video_ne.clientHeight - video_h) / 2
          video_left = 0
        } else {
          video_top = 0
          video_left = (video_ne.clientWidth - video_w) / 2
        }
      } else {
        return
      }
      setTimeout(() => {
        this.video_w = video_w + "px"
        this.video_h = video_h + "px"
        this.video_l = video_left + "px"
        this.video_t = video_top + "px"
        // console.log("set dims " + parent_ne.clientWidth + " " + this.video_w + " " + parent_ne.clientHeight + " " + this.video_h)
      })
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }

}
