import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { ChatComponent } from './chat/chat.component';
import * as chatReducer from './chat.reducer';
import { ControlsModule } from '../controls/controls.module';
import { ViewChatComponent } from './view-chat/view-chat.component';
import { AssetsModule } from '../pages/assets/assets.module';
import { FormsModule } from '@angular/forms';
import { LineComponent } from './line/line.component';
import { KzLibModule } from '../kz-lib/kz-lib.module';

@NgModule({
  declarations: [

    ChatComponent,
    ViewChatComponent,
    LineComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    StoreModule.forFeature('chats', chatReducer.chatReducer),
    ControlsModule,
    AssetsModule,
    KzLibModule,
  ], exports: [
    ChatComponent,
    ViewChatComponent

  ]
})
export class ChatModule { }
