import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'live-flash',
  templateUrl: './live-flash.component.html',
  styleUrls: ['./live-flash.component.scss'],
  animations: [
    trigger('fade', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0
      })),
      transition('show => hide', [
        animate('.5s')
      ]),
      transition('hide => show', [
        animate('.5s')
      ]),
    ])
  ]
})
export class LiveFlashComponent {
  timer: any
  fade: string = "fade"
  ngOnInit(): void {

    this.timer = setInterval(() => {
      if (this.fade == "show") {
        this.fade = "hide"
      } else {
        this.fade = "show"
      }
    }, 1000)
  }
  ngOnDestroy() {

    clearInterval(this.timer)
  }
}
