import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from '../../chapala-mls-service';
import { SetSearch } from '../../chapala-mls.actions';
import { ChapalaMLSState } from '../../chapala-mls.model';
import { gotMLSMsg, searchBy } from '../../chapala-mls.selectors';
import { SelectorComponent } from './selector.component';

@Component({
  selector: 'mls-prop-status',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class PropStatusComponent extends SelectorComponent {

  props_status: any[] = [
    { id: 1, name: "For Sale" },
    {
      id: 2, name: "Price Reduction"
    }, {
      id: 3, name: "For Rent"
    },
    {
      id: 4, name: "Contract Pending"
    }, {
      id: 5, name: "Under Contract"
    }]

  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);
    console.log("Status")


    this.setTitle("Status")

    this.setOptions(this.props_status, "name")
    this.search_by = "props_status"
    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.select) {
          if (msg.select.hasOwnProperty("Status")) {
            let index = msg.select["Status"]
            let prop_status_id = this.props_status[index].id
            this.chapalaMLState.dispatch(new SetSearch(this.search_by, prop_status_id))
          }
        }
      }
    }))

  }
  selOption(i: number) {
    super.selOption(i)
    // let prop_status_id = this.props_status[i].id
    // this.chapalaMLState.dispatch(new SetSearch("props_status", prop_status_id))
  }

}
