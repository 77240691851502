import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ChatState } from "./chat.model";

export const selectChatStateState = createFeatureSelector<ChatState>('chats');


export const openChat = createSelector(
  selectChatStateState,
  state => state.open_chat
)
export const chatMsgs = createSelector(
  selectChatStateState,
  state => state.chat_msgs
)
export const chatId = createSelector(
  selectChatStateState,
  state => state.chat_id
)
export const gotChat = createSelector(
  selectChatStateState,
  state => state.chat
)
