<div class="out" [ngClass]="{center:single}">
  <img *ngIf="single" [src]="url_photo" class="single">
  <div *ngIf="!single" class="imageHolder">
    <img [src]="url_photo">
  </div>


  <k-button *ngIf=" single" class="close" icon="close.svg" background="transparent" kzLib (click)="close()"></k-button>
  <k-paginator *ngIf="images.length>1 && single" class="paginator" [pages]="images.length" (indexChange)="selImage($event)"></k-paginator>

</div>
