<div class="out" [ngClass]="{out_sm:!bShowProgress}">
  <k-spinner class="spin" *ngIf="spining"></k-spinner>
  <!-- <div *ngIf="!spining" class="check" [ngClass]="{selected:_active}">
  </div> -->

  <img *ngIf="!spining && !_active && !error" src="assets/images/kbar-tick.svg" class="check" alt="">
  <img *ngIf="!spining && _active && !error" src="assets/images/kbar-tick-done.svg" class="check" alt="">
  <img *ngIf="error" src="assets/images/errorX.svg" class="check" alt="">

  <div class="tx" [ngClass]="{tx_sm:!bShowProgress}">
    {{label}}
  </div>
  <progres-h *ngIf="bShowProgress" class="slider" [progress]="_progress"></progres-h>

</div>
