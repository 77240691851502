import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddToChat, Download, SelectToolOpts } from '../../canvas.actions';

@Component({
  selector: 'sel-download',
  templateUrl: './sel-download.component.html',
  styleUrls: ['./sel-download.component.scss']
})
export class SelDownloadComponent {
  constructor(
    public canvasStore: Store<CanvasState>,

  ) {
    console.log("download")
  }
  add() {
    this.canvasStore.dispatch(new AddToChat())
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
  down() {
    this.canvasStore.dispatch(new Download())
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
}
