import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { Store } from '@ngrx/store';
import { SVG } from '@svgdotjs/svg.js';
import { Hash } from 'src/app/utils';
import { MoveAssets, SetFoldersForTab } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { filteredAssets, filteredFolders, gotAssets, inTab, myRoll } from '../../pages.selectors';

@Component({
  selector: 'assets-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersComponent {
  _selected_assets_ids: string[] = []
  assets: any = {}
  asset_keys: string[] = []

  root_folder: any = {}


  filtered_folders: any[] = []
  bAdmin: boolean = true
  path: any[] = []

  @Input() set selected_assets_ids(ids: string[]) {
    if (ids) {
      this._selected_assets_ids = ids
      this.num_sel_assets = this._selected_assets_ids.length
      console.log("num_sel_assets " + this.num_sel_assets + JSON.stringify(this._selected_assets_ids))
    }
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  num_sel_assets: number = 1
  sel_folder_index: number = -1
  stack: any[] = []
  move_color: string = "#e0e0e0"
  bMoveDisabled: boolean = true
  roll: string = ""
  subscriptions: any[] = []


  constructor(
    private pagesState: Store<PagesState>,
  ) {
    console.log("folders component")
    this.subscriptions.push(this.pagesState.select(myRoll).subscribe((my_roll: string) => {
      setTimeout(() => this.roll = my_roll);
    }))
    this.subscriptions.push(this.pagesState.select(gotAssets).subscribe((assets: any) => {
      function setSubFolders(asset: any): any[] {
        let folder = Object.assign({}, asset)
        let num_folders = 0
        let sub_folders: any[] = []
        if (folder.assets) {
          let keys = Object.keys(folder.assets)
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            let asset: any = folder.assets[key]
            if (asset.type == "folder") {
              num_folders++
              let sfolder = setSubFolders(asset)
              sub_folders.push(sfolder)
            }
          }
        }
        folder.num_folders = num_folders
        folder.sub_folders = sub_folders

        return folder
      }
      let agency_sub_folders: any[] = []
      let personal_sub_folders: any[] = []
      if (assets) {
        let keys = Object.keys(assets)
        if (keys.length > 0) {
          this.asset_keys = keys
          this.assets = assets
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            let asset: any = assets[key]
            if (asset.type == "folder") {
              let folder = setSubFolders(asset)
              if (asset.bAdmin) {
                agency_sub_folders.push(folder)
              } else {
                personal_sub_folders.push(folder)
              }
            }
          }

          let agency_folder: any = {
            name: "Company Assets Page",
            sub_folders: agency_sub_folders,
            num_folders: agency_sub_folders.length,
            asset_id: "company"
          }

          let personal_folder: any = {
            name: "Personal Assets Page",
            sub_folders: personal_sub_folders,
            num_folders: personal_sub_folders.length,
            asset_id: "agent"
          }


          let root_sub_folders = [agency_folder, personal_folder]
          this.root_folder = {
            name: "Folders",
            sub_folders: root_sub_folders,
            num_folders: root_sub_folders.length
          }


          // if (this.bAdmin) {
          //   this.stack = []
          //   this.path = [{ name: this.agency_folders.name }]
          //   this.filtered_folders = this.agency_folders.sub_folders
          // } else {
          //   this.path = [{ name: this.personal_folders.name }]
          //   this.filtered_folders = this.personal_folders.sub_folders
          // }
          this.path = [this.root_folder]
          this.filtered_folders = this.root_folder.sub_folders
        }
      }
    }))
    this.subscriptions.push(this.pagesState.select(inTab).subscribe((tab: string) => {
      this.bAdmin = tab == "company"
      this.path = [this.root_folder]
      this.filtered_folders = this.root_folder.sub_folders
      // if (this.bAdmin) {
      //   if (this.agency_folders.sub_folders) {
      //     this.filtered_folders = this.agency_folders.sub_folders
      //     this.path = [{ name: this.agency_folders.name }]
      //   } else {
      //     this.filtered_folders = []
      //   }
      // } else {
      //   if (this.personal_folders.sub_folders) {
      //     this.filtered_folders = this.personal_folders.sub_folders
      //     this.path = [{ name: this.personal_folders.name }]
      //   } else {
      //     this.filtered_folders = []
      //   }
      // }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }

  /*setFolders() {
    this.filtered_folders = []
    let keys = Object.keys(this.assets)
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      let asset = this.assets[key]
      if (asset.type == 'folder' && asset.bAdmin == this.bAdmin) {
        this.filtered_folders.push(asset)
      }
    }
  }*/
  selFolder(i: number) {
    if (this.sel_folder_index == i) {
      this.sel_folder_index = -1
      this.move_color = "#e0e0e0"
      this.bMoveDisabled = true
      return
    }
    this.sel_folder_index = i
    this.move_color = "#000"
    this.bMoveDisabled = false

  }
  nextFolder(i: number) {
    setTimeout(() => {
      this.path.push({ name: this.filtered_folders[i].name })
      this.path = Object.assign([], this.path)
      this.stack.push(this.filtered_folders)
      this.filtered_folders = this.filtered_folders[i].sub_folders
      this.sel_folder_index = -1
    })
  }
  back() {
    if (this.path.length > 1) {
      this.filtered_folders = this.stack.pop()

      this.path.pop()
      this.path = Object.assign([], this.path)
    }
  }
  selPath(i: number) {
    let filtered_folders
    while (i < this.stack.length) {
      filtered_folders = this.stack.pop()
    }
    while (i + 1 < this.path.length) {
      this.path.pop()
    }
    this.path = Object.assign([], this.path)

    this.filtered_folders = filtered_folders
    this.sel_folder_index = -1
  }
  async moveAsset() {


    let target_id
    // if (this.sel_folder_index == 100) {
    //   target_id = "company"
    // } else if (this.sel_folder_index == 101) {
    //   target_id = "agent"
    // } else {

    let folder = this.filtered_folders[this.sel_folder_index]
    target_id = folder.asset_id
    // }
    this.pagesState.dispatch(new MoveAssets(target_id, this._selected_assets_ids))
    this.close.emit()

  }
  cancelMe() {
    this.cancel.emit()
  }
}
