<div class="out brd" *ngIf="!bShowImages">
  <div class="left" #left>
    <show-imgs kzLib (click)="showImgs()" class="show-imgs point" [path]="path"></show-imgs>
    <div class="scroll" #images>
      <img *ngFor="let url of images_url;let i = index" [ngClass]="{imgl:i%2==0}" kzLib (click)="selImg(i)" class="imgs" [src]="url">
    </div>
  </div>
  <div class="right">
    <div class="hdr_content">
      <div class="ref-line">
        <span class="ref"> {{property.ref}} </span> - {{property.pro_name}}
      </div>
      <div class="text">
        <!-- <mat-icon  kzLib  kzLib (click)="showMap()"> location_on </mat-icon> {{property.address}} {{property.city_name}} {{property.state_name}} -->
      </div>
      <div class="text">
        {{property.region}}
      </div>
      <div class="text price">
        <strong> {{property.currency_code}} {{property.price | currency}}</strong>
      </div>
      <div class="text">
        {{property.category_name}}
      </div>
      <div class="text">
        {{property.bed_room}} Bedrooms | {{property.bath_room}} Badrooms
      </div>
      <div class="text">
        {{property.lot_size}} Lot m² | {{property.square_feet}} Construction m²
      </div>
    </div>
    <div class="hdr"> Details </div>
    <div class="scrollr">

      <div class="content" [innerHtml]="property.pro_full_desc">
      </div>
      <div class="content_box">
        <div *ngFor="let key of features_keys">
          <div class="f_hdr">{{key}}</div>
          <div *ngFor="let feature of features[key]">
            {{feature}}
          </div>
        </div>
      </div>
    </div>
    <k-button class="close" icon="close.svg" background="transparent" kzLib (click)="close()"></k-button>
  </div>
</div>
<div class="out" *ngIf="bShowImages">
  <show-imgs class="out" [path]="path" [single]="true"></show-imgs>
</div>
