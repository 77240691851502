import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KzLibDirective } from './kz-lib.directive';
import { KzInputDirective } from './kz-input.directive';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    KzLibDirective,
    KzInputDirective
  ],
  imports: [
    CommonModule,
    FormsModule // Add it here
  ], exports: [
    KzLibDirective,
    KzInputDirective
  ]
})
export class KzLibModule { }
