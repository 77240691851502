/*******************************************************************************
ONLY CHANGE THIS IN SITE, and copy it to CALL   May 13, 2023
*******************************************************************************/

//https://www.npmjs.com/package/

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { OpenCloseChat } from 'src/app/chat/chat.actions';
import { ShowAsset } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { iAmManager, isPhone, managerName, myCompany, myName, repName, myPicture, inPhone, showAsset, callerFirstName } from 'src/app/pages/pages.selectors';
import { WSSState } from 'src/app/wss/wss.model';
import { callId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { KVideoState } from '../k-video.model';
import { mediaState, streams } from '../k-video.selectors';


@Component({
  selector: 'k-video',
  templateUrl: './kvideo.component.html',
  styleUrls: ['./kvideo.component.scss']
})
export class KvideoComponent {
  @ViewChild('videoElement') video: ElementRef | undefined;
  @Input() muted: boolean = false;
  name: string = "name"
  initials: string = "c"
  _role: string = "me";
  img_src: string = "assets/images/default_prof_pic.svg"
  rep_img: string = ""
  cia: string = ""
  @Input() screen: string = ""
  @Input() no_prompts: boolean = false;

  @Input() set role(r: string) {
    if (this._role != r) {
      this._role = r
      this.name = r
      console.log("kvideo inPhone set role " + this._role)
      this.setName()

    }
  }

  @Input() set bMirrow(m: boolean) {
    setTimeout(() => this.muted = m);
  }
  _small: boolean = false
  @Input() set small(s: boolean) {
    this._small = s
    this.setName()
  }
  bShowMono: boolean = true;
  stream: MediaStream | undefined
  my_name: string | undefined
  caller_name: string | undefined
  rep_name: string | undefined
  manager_name: string | undefined
  media_state: any = {}
  call_id: string | undefined
  subscriptions: any[] = []
  bShowIsPhone: boolean = false
  isPhone: boolean = false
  audio_stream: MediaStream | undefined
  using_phone: string | undefined
  back_video_roll: string | undefined
  present_role: string = ""
  constructor(
    private kVideoState: Store<KVideoState>,
    public pagesState: Store<PagesState>,
    private wssState: Store<WSSState>,
    public canvasStore: Store<CanvasState>,
    private wss_service: WSSService,
  ) {
    console.log("kvideo inPhone")
    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        this.present_role = asset.roll
      }
    }))
    this.subscriptions.push(this.pagesState.select(myCompany).subscribe((cia: string | undefined) => {
      if (cia) { setTimeout(() => this.cia = cia) }
    }))
    this.subscriptions.push(this.pagesState.select(inPhone).subscribe((who: string | undefined) => {
      this.using_phone = who
      console.log("kvideo inPhone  " + this._role + "  who " + who)
      this.setName()
    }))
    this.subscriptions.push(this.pagesState.select(isPhone).subscribe((isPhone: boolean) => {
      this.isPhone = isPhone

    }))
    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
    }))
    this.subscriptions.push(this.pagesState.select(myName).subscribe((name: string | undefined) => {
      this.my_name = name
      this.setName()
    }))
    this.subscriptions.push(this.pagesState.select(myPicture).subscribe((picture: any) => {
      if (picture) {
        this.rep_img = picture
        this.setName()
      }
    }))
    this.subscriptions.push(this.pagesState.select(callerFirstName).subscribe((caller_name: string | undefined) => {
      this.caller_name = caller_name
      this.setName()
    }))
    this.subscriptions.push(this.pagesState.select(repName).subscribe((rep_name: string | undefined) => {
      this.rep_name = rep_name
      this.setName()
    }))
    this.subscriptions.push(this.pagesState.select(managerName).subscribe((manager_name: string | undefined) => {
      this.manager_name = manager_name
      this.setName()
    }))

    //Media state will work for the non-local ones
  }
  ngOnDestroy() {
    console.log("#AUDIO_" + this._role + ", " + this.screen + " destroying ")
    this.audio_stream = undefined
    this.stream = undefined
    if (this.video) {
      this.video.nativeElement.srcObject = undefined
    }
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }

  ngAfterViewInit() {
    // this.subscriptions.push(this.kVideoState.select(selectedAOutDevId).subscribe((sinkId: string | undefined) => {
    //   if (sinkId && this.video) {
    //     let me = this;
    //     this.video.nativeElement.setSinkId(sinkId)
    //       .then(function() {
    //         if (me.video) {
    //           console.log('Audio output device attached: ' + me.video.nativeElement.sinkId);
    //         }
    //       })
    //       .catch(function(error: any) {
    //         console.error(error)
    //       });
    //   }
    // }))
    this.subscriptions.push(this.kVideoState.select(mediaState).subscribe((media_state: any) => {
      let new_media_state = media_state[this._role]
      if (!new_media_state && this._role == "me") {
        new_media_state = media_state["rep"]
      }
      if (this.media_state && new_media_state) {
        console.log("#AUDIO_" + this._role + ", " + this.screen + " audio  " + this.media_state.audio + " -> " + new_media_state.audio + " video " + this.media_state.video + " -> " + new_media_state.video)
      }
      if (new_media_state) {
        this.media_state = new_media_state
        if (!this.media_state.video) {
          this.bShowMono = true;
        }
        if (!this.media_state.audio && !this.media_state.video) {
          this.stream = undefined
          this.audio_stream = undefined
          this.bShowMono = true;
          if (this.video) {
            this.video.nativeElement.srcObject = undefined
            console.log("#AUDIO_" + this._role + ", " + this.screen + this._role + "srcObject = undefined, !this.media_state.audio && !this.media_state.video")
          }
        }
      }
    }))

    this.subscriptions.push(this.kVideoState.select(streams).subscribe((streams: any) => {

      // if (streams && this._role == "caller") {
      //   let sk = Object.keys(streams)
      //   console.log("#VCS_" + this._role + " " + JSON.stringify(sk))
      // }

      this.stream = streams[this._role]
      // if (this.stream) {
      //   // console.log("#VCS_" + this._role + " stream.id " + this.stream.id)
      // } else {

      if (!this.stream && this._role == "rep") {
        this.stream = streams["me"] //to see it} when we are not in a call
      }
      // }
      if (this.stream && this.video) {
        if (!this.video.nativeElement.srcObject) {
          console.log("#AUDIO_" + this._role + ", " + this.screen + " new stream !this.video.nativeElement.srcObject")
          this.video.nativeElement.srcObject = this.stream
          setTimeout(() => { this.audio_stream = this.stream })
          this.checkMono()
          return
        }
        let vts = this.video.nativeElement.srcObject.getVideoTracks()
        if (vts == 0) {
          // console.log("#VCS_" + this._role + " we did not have vt")
          this.video.nativeElement.srcObject = this.stream
          setTimeout(() => { this.audio_stream = this.stream })
          this.checkMono()
          return
        }

        // console.log("#VCS_" + this._role + " change AT  " + this.stream.id)
        let old_at
        let new_at
        let old_ats = this.video.nativeElement.srcObject.getAudioTracks()
        if (old_ats.length > 0) {
          old_at = old_ats[0]
        }
        let new_ats = this.stream.getAudioTracks()
        if (new_ats.length > 0) {
          new_at = new_ats[0]
        }
        if (new_at && old_at) {
          if (new_at.id != old_at.id) {
            console.log("#AUDIO_" + this._role + ", " + this.screen + " replace track new_at.id != old_at.id  " + new_at + " != " + old_at.id)
            this.video.nativeElement.srcObject.removeTrack(old_at)
            this.video.nativeElement.srcObject.addTrack(new_at)
          }
        } else if (!new_at && old_at) {
          console.log("#AUDIO_" + this._role + ", " + this.screen + " remove track !new_at && old_at  ")
          this.video.nativeElement.srcObject.removeTrack(old_at)
        } if (new_at && !old_at) {
          this.video.nativeElement.srcObject.addTrack(new_at)
          console.log("#AUDIO_" + this._role + ", " + this.screen + " add track new_at.id != old_at.id  " + new_at + " != " + old_at.id)
        }

        let old_vt
        let new_vt
        let old_vts = this.video.nativeElement.srcObject.getVideoTracks()
        if (old_vts.length > 0) {
          old_vt = old_vts[0]
        }
        let new_vts = this.stream.getVideoTracks()
        if (new_vts.length > 0) {
          new_vt = new_vts[0]
        }
        if (new_vt && old_vt) {
          this.video.nativeElement.srcObject = this.stream //July 24
          // console.log("#VCS_" + this._role + " new_vt && old_vt  remove video track " + old_vt.id)
          // this.video.nativeElement.srcObject.removeTrack(old_vt)
          // this.resetAudioStream()
          // if (this.media_state.video) {
          //   this.video.nativeElement.srcObject.addTrack(new_vt)
          //   console.log("#VCS_" + this._role + " new_vt && old_vt && this.media_state.video add video track " + new_vt.id)
          // }
        } else if (!new_at && old_at || !this.media_state.video) {
          // console.log("#VCS_" + this._role + " this.media_state.video  remove audio track")
          this.video.nativeElement.srcObject.removeTrack(old_vt)
          this.resetAudioStream()
        } if (new_at && !old_at && this.media_state.video) {
          // console.log("#VCS_" + this._role + " new_vt && !old_vt && this.media_state.video add video track")
          this.video.nativeElement.srcObject.addTrack(new_vt)
        }
        this.checkMono()
      }
    }))

  }
  resetAudioStream() {
    if (this.audio_stream) {
      this.audio_stream = undefined
      setTimeout(() => {
        if (this.stream) {
          console.log("#AUDIO_" + this._role + ", " + this.screen + " reseted audio_stream")
        } else {
          console.log("#AUDIO_" + this._role + ", " + this.screen + " audio_stream undefined")
        }
        this.audio_stream = this.stream
      }, 100)
    }
  }

  checkMono() {
    setTimeout(() => {
      if (!this.media_state.video) {
        // console.log("#VCS_" + this._role + " !this.media_state.video  bShowMono true")
        this.bShowMono = true
      }

      if (this.video) {
        if (this.video.nativeElement.srcObject) {
          let vts = this.video.nativeElement.srcObject.getVideoTracks()
          if (vts.length > 0) {
            let vt = vts[0]
            // console.log("#VCS_" + this._role + " video track id " + vt.id)
            this.bShowMono = !vt.enabled
            // console.log("#VCS_" + this._role + " vt enabled " + vt.enabled + " bShowMono " + this.bShowMono)
            if (this.video && this.muted) {
              this.video.nativeElement.volume = 0
              console.log("#AUDIO_" + this._role + ", " + this.screen + " set volume to 0");
            }
            this.resetAudioStream()
            return
          }
        } else {
          // console.log("#VCS_" + this._role + " no srcObject " + " bShowMono  = true")
        }
      } else {
        // console.log("#VCS_" + this._role + " no vt " + " bShowMono  = true")

      }
      this.bShowMono = true;
    })
  }
  setName() {
    if (this.using_phone && this._role == this.using_phone && !this._small) {
      this.bShowIsPhone = true;
    } else {
      this.bShowIsPhone = false;
    }

    if (this._role == "caller") {
      if (this.caller_name) {
        this.name = this.caller_name
        let parts = this.name.split(' ')
        if (parts.length > 0) {
          this.initials = ""
          for (let i = 0; i < parts.length; i++) {
            this.initials += parts[i].substr(0, 1).toUpperCase()
          }
        }
        return
      }
    }
    if (this._role == "me") {
      if (this.my_name) {
        this.name = this.my_name
        this.img_src = this.rep_img
        return
      }
    }
    if (this._role == "rep") {
      if (this.rep_name) {
        this.name = this.rep_name
        this.img_src = this.rep_img
        return
      } else if (this.my_name) {
        this.name = this.my_name
        return
      }
    } if (this._role == "manager") {
      if (this.manager_name) {
        this.name = this.manager_name
        return
      }
    }
    this.name = this._role
    let parts = this.name.split(' ')
    if (parts.length > 0) {
      this.initials = ""
      for (let i = 0; i < parts.length; i++) {
        this.initials += parts[i].substr(0, 1).toUpperCase()
      }
    }
  }
  stopVCanvas() {
    this.pagesState.dispatch(new ShowAsset({}));
    //The message is listend to by pages/in-call
    let msg: any = {
      show_asset: "none"
    }
    this.wss_service.sendMessageToMembersAndMe(msg)
  }
  videoCanvas() {
    let wb_asset = { type: "video_canvas", asset_id: "video_canvas1", name: "Videoboard", roll: this._role }
    this.pagesState.dispatch(new ShowAsset(wb_asset))
    this.wss_service.sendMessageToOtherMembers({ show_asset: wb_asset })
    this.pagesState.dispatch(new OpenCloseChat(false))
  }
}
