<div class="out1">

  <div class="out" [style.background]="background" [ngClass]="{border:border}" (mouseenter)="enter()" [style.border-radius.px]="radius" [style.min-width.px]="_min_width" [style.height.px]="height">

    <div class="line"  kzLib (click)="showOptions()">
      {{title}}
    </div>
    <img class="expand" [ngClass]="{colapse:openFilter}" src="assets/images/expand.svg"  kzLib (click)="showOptions()">
  </div>

  <div *ngIf="openFilter" [style.min-width.px]="min_drop_width" class="opt_contianier" [ngClass]="{opt_contianier_btn:button, opt_ctr_right:right, ext:exterior}" (mouseleave)="leave()">
    <div #optComponent class="options" [style.min-width.px]="min_drop_width" [ngClass]="{opts_r:bShowRight,options_btn:button}">
      <div class="opt" [style.min-width.px]="min_opts_width" *ngFor="let opt of _options;let i = index"  kzLib (click)="selOption(i)">
        <img *ngIf="opt.img" [src]="opt.img" class="opt_img">
        <span class="tx" [ngClass]="{tximg:opt.img}">{{opt.desc}}</span>
        <div *ngIf="!opt.img" class="check" [ngClass]="{selected:opt.selected, right:_min_width}"></div>
      </div>
    </div>
    <div class="button" *ngIf="button && openFilter">
      <div class="button_tx">{{button}}</div>
    </div>

    <div class="del " *ngIf="show_del"  kzLib (click)="del()">
      <span class="deltx"> Delete User</span>
    </div>
  </div>
</div>
