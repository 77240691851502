<div class="out">

  <k-video [role]="left_role" screen="side-by-side" class="two_guest_p">
  </k-video>
  <k-video [role]="right_role" screen="side-by-side" class="two_guest_p second" [bMirrow]="true">
  </k-video>

  <div class="images" *ngIf="manager_id && left_role!='manager'">
    <div cdkDrag class="round">
      <k-video [role]="circle_role" screen="side-by-side" class="full">
      </k-video>
    </div>

  </div>


</div>
