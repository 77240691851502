import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { BillingComponent } from '../billing.component';

@Component({
  selector: 'cards',
  templateUrl: './cards.component.html',
  styleUrls: ['../billing.component.scss', './cards.component.scss']
})
export class CardsComponent extends BillingComponent {


}
