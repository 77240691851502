import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { WSSState } from '../wss/wss.model';
import { callId, lastMsg } from '../wss/wss.selectors';
import { ChatMessages, CleanChat, GotChat, NewMsg } from './chat.actions';
import { ChatState } from './chat.model';
import { SVG, extend as SVGextend, Element as SVGElement, G } from '@svgdotjs/svg.js'
import { DomSanitizer } from '@angular/platform-browser';
import { WSSService } from '../wss/wss.service';
import { chatId } from './chat.selectors';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  chat_id: string | undefined
  call_id: string | undefined
  constructor(
    private httpClient: HttpClient,
    public pagesState: Store<ChatState>,
    private wssState: Store<WSSState>,
    private chatState: Store<ChatState>,
    private sanitizer: DomSanitizer,
    public wss_serivce: WSSService,
  ) {
    console.log("chat service")



    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
      this.chatState.dispatch(new CleanChat())
    })

    this.chatState.select(chatId).subscribe(async (chat_id: string | undefined) => {
      if (chat_id) {
        try {
          this.chat_id = chat_id
          let chat: any = await this.httpClient.get<any>('/rchat/' + chat_id).toPromise();
          if (chat) {
            let new_chat = Object.assign({}, chat)
            let messages: any[] = []
            if (chat.messages) {
              for (let i = 0; i < chat.messages.length; i++) {
                let msg = this.setImgFromSVG(chat.messages[i])
                messages.push(msg)
              }
            }
            new_chat.messages = messages
            this.chatState.dispatch(new GotChat(new_chat))
          }
        } catch (e: any) {
          console.error("Error update user " + JSON.stringify(e))
        }
      }
    })

    this.wssState.select(lastMsg).subscribe((msg: any) => {
      if (msg) {
        if (msg.hasOwnProperty("chat_msg")) {
          let chat_msg = this.setImgFromSVG(msg.chat_msg)
          let new_msg = Object.assign({}, msg)
          new_msg.chat_msg = chat_msg
          this.chatState.dispatch(new NewMsg(new_msg))
        }
      }

    })


  }
  setImgFromSVG(msg: any) {
    if (msg) {
      if (msg.hasOwnProperty("chat_svg")) {
        let chat_msg = Object.assign({}, msg)
        let svg: string = chat_msg.chat_svg
        let bbox = msg.bbox
        console.log(svg)
        let ssvg = svg.replace('<svg ', '<svg viewBox="' + bbox.x + ' ' + bbox.y + ' ' + bbox.x2 + ' ' + bbox.y2 + '" ')
        let base64string = btoa(ssvg)
        let img = this.sanitizer.bypassSecurityTrustResourceUrl(
          `data:image/svg+xml;base64,${base64string}`
        );
        chat_msg.img = img
        return chat_msg
      }
    }
    return msg
  }

  view_call: any
  setViewCall(view_call: any) {
    this.view_call = view_call
  }
  async  viewCallChat() {
    return new Promise<any>(async (resolve, reject) => {
      if (this.view_call) {
        try {
          let rv = await this.getChat(this.view_call.chat_id)
          rv.call = this.view_call
          resolve(rv)
        } catch (e) {
          reject(e)
        }
      } else {
        reject("No view chat id set")
      }
    })
  }
  async getChat(chat_id: string) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        this.chat_id = chat_id
        let chat: any = await this.httpClient.get<any>('/rchat/' + chat_id).toPromise();
        if (chat) {
          let new_chat = Object.assign({}, chat)
          let messages: any[] = []
          if (chat.messages) {
            for (let i = 0; i < chat.messages.length; i++) {
              let msg = this.setImgFromSVG(chat.messages[i])
              messages.push(msg)
            }
          }
          new_chat.messages = messages
          resolve(new GotChat(new_chat))
        }
      } catch (e: any) {
        reject("Error update user " + JSON.stringify(e))
      }
    })
  }
  sendMsg(msg: any) {
    if (!this.chat_id) {
      this.chat_id = this.call_id
      msg.first_chat = true
    }
    msg.chat_id = this.chat_id
    this.wss_serivce.sendMessageToMembersAndMe(msg)
  }

}
