<div class="out" #out>
  <input kzInput class="fl" [(colorPicker)]="line_color" [cpAddColorButton]="true" (colorPickerChange)="setColor($event)" [style.background]="line_color" cpPosition="bottom" />

  <more-or-less class="ml" [(value)]="line_width" (valueChange)="changeWidth($event)"></more-or-less>
  <span class="bt font_text "> Line Width</span>

  <svg class="line" height="5" width="100">
    <line x1="0" y1="0" x2="100" y2="0" [style]="style" />
  </svg>
  <k-button class="cancel" icon="close.svg" background="transparent" kzLib (click)="cancel()"></k-button>

</div>
