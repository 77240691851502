import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import { OptionalContentConfig } from 'pdfjs-dist/types/src/display/optional_content_config';
import { ToolService } from 'src/app/tools/tool.service';

if (pdfjsLib !== undefined) {
  console.log("set worker...");
  pdfjsLib.GlobalWorkerOptions.workerSrc = "https://npmcdn.com/pdfjs-dist@2.16.105/build/pdf.worker.js";
}

pdfjsLib: pdfjsLib

@Component({
  selector: 'pdf-cover',
  templateUrl: './pdf-cover.component.html',
  styleUrls: ['./pdf-cover.component.scss']
})
export class PdfCoverComponent {
  @ViewChild('divContainer') divContainer: ElementRef | undefined;

  @ViewChild('canvas') the_canvas: ElementRef | undefined;


  _asset: any | undefined
  pdf_src: string | undefined
  @Input() set asset(a: any) {
    if (a) {
      this._asset = a
      if (this._asset.url) {
        if (this._asset.url.length > 2) {
          if (this._asset.url.length < 2048) {
            this.setURL()
          }
        }
      }
    }
  }
  async setURL() {
    try {
      this.pdf_src = await this.tools_service.getKeyURL(this._asset.key)
      this.loadPDF();
    } catch (e) {
      console.error(e + " " + "pdfCover ")
    }
  }
  pdflibDoc: any
  signers: any
  signer_keys: string[] = []
  //  https://keyzii-test.s3.us-west-1.amazonaws.com/6086ff61e3090488680297b9/1694355273297.pdf?AWSAccessKeyId=AKIA4UNR24CVC42GBRFX&Expires=1696015548&Signature=iEP7xL8S2r3JOTnOejikIPo6i8w%3D
  //  https://keyzii-test.s3.us-west-1.amazonaws.com/6086ff61e3090488680297b9/1694355273297.pdf?AWSAccessKeyId=AKIA4UNR24CVC42GBRFX&Expires=1694358873&Signature=cX9H85EonU1nUDv%2BBPN8U2kV5N4%3D
  constructor(
    private tools_service: ToolService,
  ) {
    console.log("pdf-cover")
  }



  ngAfterViewInit() {
    console.log("pdf-cover")
    if (!this._asset.contract_executed) {
      this.loadPDF();
    } else {
      setTimeout(() => {
        this.signers = this._asset.signers
        this.signer_keys = Object.keys(this.signers)
      })
    }
  }
  bLoading: boolean = false;
  async loadPDF() {
    let me = this
    if (this.bLoading) {
      return
    }
    if (this.pdf_src && this.the_canvas) {
      this.bLoading = true
      let existingPdfBytes
      try {
        existingPdfBytes = await fetch(this.pdf_src).then(res => res.arrayBuffer())
      } catch (e) {
        console.error(e)
      }
      if (existingPdfBytes && existingPdfBytes.byteLength > 500) {
        this.pdflibDoc = await pdfjsLib.getDocument(existingPdfBytes).promise;
      } else {
        this.pdflibDoc = undefined
      }

      if (this.pdflibDoc) {
        try {
          // loadingTask.promise.then(async (pdf: any) => {
          // console.log('PDF loaded');

          // Fetch the first page
          let page = await this.pdflibDoc.getPage(1)
          var scale = 2;
          var viewport = page.getViewport({ scale: scale });
          let canvas = this.the_canvas.nativeElement
          if (canvas) {
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function() {
              // console.log('Page rendered');
              me.bLoading = false;
            });
          }
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
}
