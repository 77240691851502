<div class="scrn" #scrn [ngClass]="{transparent:doing=='add_cc'}">
  <div class="abs_center out">
    <div class="title">
      Captcha verification
    </div>
    <div class="sub_title">
      Select the tickbox below
    </div>

    <div id="recaptcha-container">

    </div>
  </div>
</div>
