import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';



import { WSSState } from 'src/app/wss/wss.model';
import { WSSService } from 'src/app/wss/wss.service';
import { SVG, extend as SVGextend, Element as SVGElement, G } from '@svgdotjs/svg.js'
import { GetSignature, NewInitials, NewSignature } from 'src/app/canvas/canvas.actions';
import { changeSignature, getInitials, getSignature, signerSteps, textFont, textSize, textWeight } from '../../canvas.selectors';
import { PagesState } from 'src/app/pages/pages.model';
import { isPhone } from 'src/app/pages/pages.selectors';
import { SignPadComponent } from '../sign-pad/sign-pad.component';

@Component({
  selector: 'get-signature',
  templateUrl: './get-signature.component.html',
  styleUrls: ['./get-signature.component.scss']
})
export class GetSignatureComponent {
  // @Output() close: EventEmitter<any> = new EventEmitter();

  @ViewChild('signatureCanvas') signatureCanvas: SignPadComponent | undefined; //hand signature
  @ViewChild('signatureCanvas1') signatureCanvas1: ElementRef | undefined; //hand signature
  @ViewChild('inName') inName: ElementRef | undefined; //hand initials
  signing: string | undefined;
  name: string = ""
  initials: string = ""
  sel_font: number = 2
  // ctx_font: string | undefined;

  save_in_cookie: boolean = false //false for Caller
  // public font_names = [ //imported form assets in styles.css
  //   "Cookie",
  //   "Kalam",
  //   "Satisfy",
  // ]
  font_desc = "Font Style"
  font = "Satisfy"
  bTypeSignature: boolean = false
  draw_or_type: string = ""
  what: string = " Signature"
  bSigReady: boolean = false;
  sig_warning: string = ""


  done_back: string = "#d9d9d9" //#40BE5C
  done_color: string = "#151515"

  draw_tool: any
  group_id: string | undefined
  dt_opts = ["Type", "Draw"]



  intput_placeholder: string = "First and last name"
  clear_me: number = 0

  bNeedsSignature: boolean = false
  bNeedsInitials: boolean = false

  bShowSignatureOpt: boolean = false
  bShowFonts: boolean = false;
  weigth: string = "Regular"
  size: string = "14px"
  isPhone: boolean = false
  isLandscape: boolean = false
  sing_pad_height: number = 220
  num_strokes: number = 0
  svg: string = ""
  out_svg: any = {}
  out_rect: any
  text_initials: string | undefined

  subscriptions: any[] = []

  constructor(
    public canvasStore: Store<CanvasState>,
    public wssState: Store<WSSState>,
    public wss_service: WSSService,

    public pagesState: Store<PagesState>,
  ) {
    console.log("get-signature")
    this.subscriptions.push(this.pagesState.select(isPhone).subscribe((isPhone: boolean) => {
      this.isPhone = isPhone;
      if (this.isPhone) {
        if (!this.isLandscape) {
          this.sing_pad_height = 220
        }
      }
    }))
    // this.subscriptions.push(this.pagesState.select(isLandscape).subscribe((isLandscape: boolean) => {
    //   this.isLandscape = isLandscape;
    //   if (this.isPhone) {
    //     if (this.isLandscape) {
    //       this.sing_pad_height = 120
    //     }
    //   }
    // }))
    this.subscriptions.push(this.canvasStore.select(textFont).subscribe((text_font: string) => {
      if (this.font != text_font && this.bShowFonts) {
        this.font = text_font
        this.font_desc = text_font
        this.drawText(this.name)
      }
    }))
    this.subscriptions.push(this.canvasStore.select(textWeight).subscribe((text_weight: string) => {
      if (this.weigth != text_weight) {
        this.weigth = text_weight
        this.drawText(this.name)
      }
    }))
    this.subscriptions.push(this.canvasStore.select(textSize).subscribe((text_size: string) => {
      if (this.size != text_size) {
        this.size = text_size
        this.drawText(this.name)

      }
    }))

    if (localStorage.getItem("signature")) {
      this.save_in_cookie = true
    } else if (localStorage.getItem("initials")) {
      this.save_in_cookie = true
    }


    this.draw_tool = SVG()


  }

  ngOnDestroy(): void { //This will only fire when we are not sublcassed
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    if (this.inName) {
      this.inName.nativeElement.focus()
    }

    this.subscriptions.push(this.canvasStore.select(getSignature).subscribe((group_id: string | undefined) => {
      this.group_id = group_id
    }))

    this.subscriptions.push(this.canvasStore.select(changeSignature).subscribe((data: any | undefined) => {
      setTimeout(() => {
        this.draw_or_type = 'Draw'
        this.bTypeSignature = false

        if (data && data.svg && data.svg.length > 1) {
          if (data.svg.indexOf('/text') > 0) {
            this.draw_or_type = 'Type'
            this.bTypeSignature = true
          }
          this.svg = data.svg
          this.out_svg = this.svg

        }
      })
    }))
  }

  //****************************************************************************
  // Text signature
  //****************************************************************************
  changedName() {
    this.bShowFonts = false
    let parts = this.name.split(' ')
    if (parts.length > 0) {
      this.text_initials = parts[0].substring(0, 1)
    }
    if (parts.length > 1) {
      this.text_initials += " " + parts[1].substring(0, 1)
    }
    this.drawText(this.name)
  }

  //****************************************************************************
  // Hand signature
  //****************************************************************************
  clear() {
    this.drawText("");
    this.clear_me++
    setTimeout(() => {
      this.num_strokes = 0
      this.bSigReady = false
      this.name = ""
      this.done_back = "#acacac"

    })
  }


  //****************************************************************************
  // Text Signatures
  //****************************************************************************

  drawText(name: string) {
    // console.log("drawText")
    if (this.signatureCanvas) {
      // this.ctx_font = this.font_names[this.sel_font]
      this.draw_tool.clear()
      let text = this.draw_tool.text(name).font({
        family: this.font
        , size: this.size
        , anchor: 'start'
        , leading: '0em'
        , weigth: this.weigth
      }).fill("black")

      let bbox = text.bbox()
      if (this.signatureCanvas) {
        if (this.signatureCanvas.outComponent) {
          if (this.signatureCanvas.outComponent.nativeElement) {
            let w = this.signatureCanvas.outComponent.nativeElement.clientWidth
            let left = (w - bbox.width) / 2
            let h = this.signatureCanvas.outComponent.nativeElement.clientHeight
            let top = (h - bbox.height) / 2
            text.move(left, top)
          }
        }
      }
      text.node.dataset["font"] = this.font
      this.svg = this.draw_tool.svg()
      this.out_svg = { svg: this.svg }
      this.bSigReady = true
      this.done_back = "#151515"
      this.done_color = "#fff"
    }
  }



  changedSVG($event: any) {
    this.out_rect = $event.rect
    this.out_svg = $event
    console.log("changedSVG " + this.num_strokes + " >= 0 ")
    if (++this.num_strokes > 0) {
      this.done_back = "#151515"
      this.done_color = "#fff"
      this.bSigReady = true
    }
  }

  doneSigning() {
    if (this.group_id) {

      let rect: any
      if (this.out_rect) {
        rect = this.out_rect
      } else {
        rect = { //FROM .signature
          top: 60,
          left: 30,
          width: 490,
          height: 270
        }
      }

      this.canvasStore.dispatch(new NewSignature(
        this.group_id,
        this.out_svg.svg,
        rect,
        this.text_initials))
      this.clear_me++
      this.num_strokes = 0
      this.bSigReady = false
      this.done_back = "#acacac"

      console.log("Closed get signature 3 ")
      this.canvasStore.dispatch(new GetSignature(undefined))
      // this.close.emit()
    }
  }

  cancel() {
    this.canvasStore.dispatch(new GetSignature(undefined))
    // this.canvasStore.dispatch(new SelectTool(""))
  }
  selectedDT($event: string) {
    this.draw_or_type = $event
    if (this.draw_or_type == "Type") {
      this.sing_pad_height = 180
      this.bTypeSignature = true
      this.setTextFocus()
    } else {
      setTimeout(() => {
        this.sing_pad_height = 220
        this.bTypeSignature = false
        this.bShowSignatureOpt = false
      })
    }
  }
  toogleShowFonts() {
    this.bShowFonts = !this.bShowFonts
    this.setTextFocus()
  }
  setTextFocus() {
    let wd = 0;
    let interval = setInterval(() => {
      this.bShowSignatureOpt = false
      if (this.inName) {
        this.inName.nativeElement.focus()
        clearInterval(interval)
      } else if (++wd > 10) {
        clearInterval(interval)
      }
    }, 100)
  }

  getSel(options: any) {
    for (let i = 0; i < options.length; i++) {
      let opt = options[i]
      if (opt.selected) {
        return opt.desc
      }
    }
    options[0].desc
  }
  saveInCooke($event: any) {

  }
}
