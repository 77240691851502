import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'menu-open-close',
  templateUrl: './open-close.component.html',
  styleUrls: ['./open-close.component.scss']
})
export class OpenCloseComponent {
  bClosed: boolean = true;
  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    console.log("menu-open-close")
  }
  ngAfterViewInit() {
    // this.closed.emit(this.bClosed)
  }
  clicked() {
    this.bClosed = !this.bClosed
    this.closed.emit(this.bClosed)
  }
}
