import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { PagesState } from 'src/app/pages/pages.model';
import { callerFirstName, callerLastName } from 'src/app/pages/pages.selectors';
import { SetInvoice } from '../kpay.actions';
import { kPayState } from '../kpay.model';
import { PayService } from '../pay.service';

@Component({
  selector: 'kpay-wnd',
  templateUrl: './invoice-wnd.component.html',
  styleUrls: ['./invoice-wnd.component.scss']
})
export class InvoiceWndComponent {
  inv_title: string = ""
  inv_desc: string = ""
  inv_price: string = ""
  bReady: boolean = false
  caller_first_name: string = ""
  caller_last_name: string = ""
  subscriptions: any[] = []
  constructor(
    private pagesState: Store<PagesState>,
    public pay_service: PayService, //Make sure the service is ready
    public kpayState: Store<kPayState>,
  ) {
    console.log("kpay-wnd")
    // this.inv_title = ""
    // this.inv_desc = ""
    // this.inv_price = ""
    // this.caller_first_name = ""

    this.subscriptions.push(this.pagesState.select(callerFirstName).subscribe((name: string | undefined) => {
      if (name) {
        this.caller_first_name = name
      }
    }))
    this.subscriptions.push(this.pagesState.select(callerLastName).subscribe((name: string | undefined) => {
      if (name) {
        this.caller_last_name = name
      }
    }))
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  closeIt() {
    this.close.emit()
    this.pay_service.cancelInvoice()
  }
  sendInvoice() {
    if (this.bReady) {
      console.log("sendInvoice")
      this.kpayState.dispatch(new SetInvoice({
        title: this.inv_title,
        desc: this.inv_desc,
        amount: this.inv_price,
      }))
      this.close.emit()
    }
  }
  changed() {
    this.bReady = (this.inv_title.length > 0
      && this.inv_desc.length > 0
      && parseInt(this.inv_price) > 0
      && this.inv_title.length > 0)

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
}
