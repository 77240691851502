import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { Hash } from 'src/app/utils';
import { PagesState } from '../../pages.model';
import { myRoll } from '../../pages.selectors';
import { ContactsState } from '../contacts.model';
import { gotContacts } from '../contacts.selectors';
import { ContactsService } from '../contacts.service';

@Component({
  selector: 'rep-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent {
  // orig_contacts: any[] = []
  contacts: any[] = []
  // selected_contacts: DS = new DS()

  // edit_contact: number = -1
  search: string = ""

  edit_row: number = -1
  edit_what: string = ""
  edit_value: string = ""

  selected_rows: Hash = new Hash()
  subscriptions: any[] = []
  filter_types: string[] = ["Personal", "Company"]
  sel_index: number = 1;
  title: string = "Company"
  roll: string = 'admin'

  @ViewChild('content') private content: ElementRef | undefined;
  count: number = 0
  in_page: number = 1
  page_size: number = 0
  num_pages: number = 0

  constructor(
    public pagesState: Store<PagesState>,
    private contactsStore: Store<ContactsState>,
    private contacts_service: ContactsService,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("rep leads")

    this.subscriptions.push(this.contactsStore.select(gotContacts).subscribe((rv: any) => {

      this.contacts = rv.contacts;
      this.count = rv.count
      this.num_pages = Math.ceil(this.count / this.page_size)

    }))

    this.subscriptions.push(this.pagesState.select(myRoll).subscribe((my_roll: string) => {
      setTimeout(() => this.roll = my_roll);
    }))
  }
  ngAfterViewInit() {
    this.getContacts()
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngOnInit(): void {
  }
  // contactChanged(contact: any) {
  //   let users: any[] = Object.assign([], this.contacts)
  //   let user: any = Object.assign({}, users[this.edit_contact])
  //   user.name = contact.name
  //   user.email = contact.email
  //   user.phone = contact.phone
  //   users[this.edit_contact] = user
  //   this.contacts = users
  // }


  selRow(i: number) {
    if (!this.selected_rows.insert(i)) {
      this.selected_rows.remove(i)
    }
  }

  //****************************************************************************
  // Editing
  //****************************************************************************
  editField($event: any, i: number, what: string) {
    $event.stopPropagation()
    let contact = this.contacts[i]
    if (contact) {
      this.edit_row = i
      this.edit_what = what
      this.edit_value = contact[this.edit_what]
    }
  }

  doneEditing() {
    if (this.edit_row >= 0) {
      setTimeout(() => {
        let contacts: any[] = Object.assign([], this.contacts)
        let contact: any = Object.assign({}, contacts[this.edit_row])
        contact[this.edit_what] = this.edit_value
        contacts[this.edit_row] = contact
        this.edit_row = -1;
        this.contacts = contacts
        this.edit_value = ""
        this.contacts_service.updateContact(contact)
      })
    }
  }

  clickedEdit($event: any) {
    $event.stopPropagation()
  }
  changedSearch() { //filter by name
    console.log("filter by name")
    this.getContacts()


  }
  filterType($event: any) {
    for (let i = 0; i < $event.length; i++) {
      let entry = $event[i]
      if (entry.selected) {
        this.title = entry.desc
        this.sel_index = i
        break;
      }
    }

    this.getContacts()
  }
  getContacts() {
    try {
      let page_size = 10
      if (this.content) {
        let h = this.content.nativeElement.clientHeight
        if (h) {
          page_size = Math.floor(h / 60);
          setTimeout(() => {
            this.page_size = page_size
          }, 1)
        }
      }

      this.contacts_service.getContacts(this.in_page, page_size, this.sel_index, this.search)

    } catch (e) {
      console.error(e)
    }
  }
  downloadContacts() {
    let text = ""
    for (let i = 0; i < this.contacts.length; i++) {
      let contact = this.contacts[i]
      let line = contact.name + "," + contact.email + "," + contact.rep_name + "\n"
      text += line
    }
    const element = document.createElement('a');
    const fileType = 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', "contacts.csv");
    var event = new MouseEvent("click");
    element.dispatchEvent(event);
    document.removeChild(element)
  }
  selPage($event: any) {
    this.in_page = $event
    this.getContacts()
  }
  delLead(contact: any) {
    this.contacts_service.setDelId(contact._id)
    this.canvasStore.dispatch(new SelectToolOpts("del_lead"))
  }
}
