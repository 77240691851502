import * as store from '@ngrx/store';

export enum kPayActionTypes {
  ShowKMenu = '[kpay]menu',
  StartKPay = '[kpay]on',
  StartKPlan = '[kplan]on',
  StopKPay = '[kpay]off',
  SetInvoice = '[invoice]set',
  SetPlanFromRep = '[kplan]rep',
  SetPlanFromCaller = '[kplan]caller',

  SetAcc = '[kacc]set',
  InvOk = '[kacc]inv ok',
  GotCC = '[kacc]gotCC',
  SetCC = '[kcc]set',
  SetBillInfo = '[bill_info]set',
  GotCaptchaToken = '[captcha]got',
  SetResponse = '[response]osetpt',
  CancelLocalKPay = '[kpay]cancel loc',
  CancelRemoteKPay = '[kpay]cancel re',
  SetTargetURL = '[target]url',
  DoneKPay = '[kpay]done',
  RemError = '[kpay]remerr',
  ReTry = "[kplan]retry"
}
export class ReTry implements store.Action {
  readonly type = kPayActionTypes.ReTry;
  constructor() { }
}

export class ShowKMenu implements store.Action {
  readonly type = kPayActionTypes.ShowKMenu;
  constructor(public bShow: boolean) { }
}
export class StartKPay implements store.Action {
  readonly type = kPayActionTypes.StartKPay;
  constructor() { }
}
export class StartKPlan implements store.Action {
  readonly type = kPayActionTypes.StartKPlan;
  constructor() { }
}
export class StopKPay implements store.Action {
  readonly type = kPayActionTypes.StopKPay;
  constructor() { }
}
export class SetInvoice implements store.Action {
  readonly type = kPayActionTypes.SetInvoice;
  constructor(public invoice: any) { }
}
export class SetPlanFromRep implements store.Action {
  readonly type = kPayActionTypes.SetPlanFromRep;
  constructor(public plan: any) { }
}
export class SetPlanFromCaller implements store.Action {
  readonly type = kPayActionTypes.SetPlanFromCaller;
  constructor(public plan: any) { }
}

export class GotCC implements store.Action {
  readonly type = kPayActionTypes.GotCC;
  constructor() { }
}
export class InvOk implements store.Action {
  readonly type = kPayActionTypes.InvOk;
  constructor() { }
}
export class SetAcc implements store.Action {
  readonly type = kPayActionTypes.SetAcc;
  constructor(public acc: any) { }
}
export class SetCC implements store.Action {
  readonly type = kPayActionTypes.SetCC;
  constructor(public cc: any) { }
}
export class SetBillInfo implements store.Action {
  readonly type = kPayActionTypes.SetBillInfo;
  constructor(public bill_info: any) { }
}
export class GotCaptchaToken implements store.Action {
  readonly type = kPayActionTypes.GotCaptchaToken;
  constructor(public token: string) { }
}
export class SetResponse implements store.Action {
  readonly type = kPayActionTypes.SetResponse;
  constructor(public response: any) { }
}
export class CancelLocalKPay implements store.Action {
  readonly type = kPayActionTypes.CancelLocalKPay;
  constructor() { }
}

export class CancelRemoteKPay implements store.Action {
  readonly type = kPayActionTypes.CancelRemoteKPay;
  constructor() { }
}
export class DoneKPay implements store.Action {
  readonly type = kPayActionTypes.DoneKPay;
  constructor() { }
}
export class SetTargetURL implements store.Action {
  readonly type = kPayActionTypes.SetTargetURL;
  constructor(public url: string) { }
}
export class RemError implements store.Action {
  readonly type = kPayActionTypes.RemError;
  constructor(public error: string) { }
}

export type kPayAction =
  ShowKMenu
  | StartKPay
  | StartKPlan
  | StopKPay
  | SetInvoice
  | SetPlanFromRep
  | SetPlanFromCaller
  | SetAcc
  | InvOk
  | GotCC
  | SetCC
  | SetBillInfo
  | GotCaptchaToken
  | SetResponse
  | CancelLocalKPay
  | CancelRemoteKPay
  | DoneKPay
  | SetTargetURL
  | RemError
  | ReTry
  ;
