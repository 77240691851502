<div class="out">
  <div class="top_line">
    <input kzInput class="search" type="name" placeholder="Search calls" [(ngModel)]="search" (ngModelChange)="changedSearch()">
    <!-- <div class="total_d">
      <span class="total">Total:<span class="blue">{{count}}</span></span>
    </div> -->
  </div>
  <div class="gray_line"></div>

  <div class="line t_t">
    <div class="el">Date</div>
    <div class="el">Duration</div>
    <div class="el"> Caller Name</div>
    <div class="el">Caller Email</div>
    <div class="el">Agent Name</div>
    <div class="el">Actions</div>
  </div>

  <div class="h_content" #content>
    <div class="line" *ngFor="let call of calls, let i = index ">

      <div class="el">{{call.start|date}} {{call.start|date:'shortTime'}}</div>
      <div class="el">{{getDuration(call)}}</div>
      <div class="el"><span class="ellipsis">{{call.caller_name}}{{call.caller_first_name}} {{call.caller_last_name}} </span></div>
      <div class="el"><span class="ellipsis">{{call.caller_email}}</span></div>
      <div class="el"><span class="ellipsis"> {{call.rep_name}}</span> </div>
      <div class="el">
        <div class="imgs">
          <img *ngIf="call.chat_id" src="assets/images/transcribe.svg" class="im point"  kzLib (click)="showCallChat(call)">
          <img *ngIf="call.rec_num>0" src="assets/images/video_link.svg" class="im ir point"  kzLib (click)="play(call)">
          <img *ngIf="!call.chat_id" src="assets/images/transcribe_g.svg" class="im">
          <img *ngIf="!call.rec_num || call.rec_num==0" src="assets/images/video_link_g.svg" class="im ir">
        </div>
      </div>
    </div>

  </div>

  <k-filter [sel_index]="sel_index" [single]="true" [exterior]="true" [min_width]="110" class="filter" title="{{title}}" [str_options]="filter_types" (selected)="filterType($event)"></k-filter>
  <div class="pag_wrapper">

    <k-paginator class="paginator" [total]="count" *ngIf="num_pages>1" [pages]="num_pages" [page_size]="page_size" (indexChange)="selPage($event)"></k-paginator>
  </div>
</div>
