import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { HoursComponent } from '../../calendar/hours/hours.component';

@Component({
  selector: 'hours-all',
  templateUrl: './hours-all.component.html',
  styleUrls: ['../../calendar/hours/hours.component.scss']
})
export class HoursAllComponent extends HoursComponent {
  t_lbls: string[] = []
  @Input() start_index: number = -1
  @Input() set duration(d: number) {
    console.log("Set slots")
    let num_of_minutes = 24 * 60
    let mid_day = num_of_minutes / 2
    let am_pm: string = "AM"
    let hour = 0
    let min = 0;
    let slots = []
    let i = 0;
    for (let m = 0; m < num_of_minutes; m += d) {
      if (m == mid_day) {
        am_pm = "PM"
      }
      let lbl: string
      if (hour == 0) {
        lbl = "12:"
      } else {
        if (hour < 10) {
          lbl = "0" + hour + ":"
        } else {
          lbl = hour + ":"
        }
      }
      if (min < 10) {
        lbl += "0" + min + " " + am_pm
      } else {
        lbl += min + " " + am_pm
      }
      slots.push(lbl)
      min += d
      if (min >= 60) {
        min = 0
        hour++
        if (hour > 12) {
          hour = 1
        }
      }

      this.t_lbls = slots
      if (i++ == this.start_index) {
        this.checked[i] = true
      } else {
        this.checked[i] = false
      }
    }
  }
  @ViewChild('outComponentha') outComponent: ElementRef | undefined
  ngAfterViewInit() {
    console.log("hours")
    if (this.start_index >= 0 && this.outComponent) {
      let children = this.outComponent.nativeElement.children
      let target = children[this.start_index]
      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          // block: 'center',
          // inline: 'nearest'
          block: 'nearest', //ok nearest
          inline: 'end', //stat ok
        })

      }
    }
  }
  sel(i: number) {
    this.timesChange.emit({ from: i, to: i, lbl: this.t_lbls[i] })
  }
}
