<div class="out">

  <img *ngIf="!bEditName" kzLib src="assets/images/close.svg" class="close" kzLib (click)="close()" />

  <div *ngIf="!bEditName" class="font_text asset_name">
    <span *ngIf="bSharingScr">Sharing Screen</span> <span *ngIf="asset_name && bSharingScr">, </span> {{asset_name}}
  </div>
  <!-- <img *ngIf="!bEditName && !bShowAPI" src="assets/images/pen.svg" class="pen"  kzLib  kzLib (click)="bEditName=true" /> -->

  <!-- <input kzInput *ngIf="bEditName" class="in" type="text" placeholder="Name" name="" value="" [(ngModel)]="asset_name">
  <img *ngIf="bEditName" src="assets/images/save.svg" class="save"  kzLib  kzLib (click)="changeName()" /> -->

  <!-- <k-button *ngIf="bEditName" class="save_name" text="Save Name" background="#40BE5C"  kzLib  kzLib (click)="changeName()"></k-button> -->
</div>
