<!-- other on the left and gray -->
<div class="out " *ngIf="!mine">
  <div class="frame not_mine">
    <span *ngFor="let segment of segments" [ngClass]="{ul:segment.url}"  kzLib (click)="navigate(segment)">
      {{segment.tx}}
    </span>
  </div>
  <img *ngIf="has_asset" class="img" src="assets/images/show_asset.svg" alt=""  kzLib (click)="show_asset($event,has_asset)">

</div>
<!-- mine /on the right and blue -->
<div class="out " *ngIf="mine">
  <div class="filler"></div>
  <img *ngIf="has_asset" class="img" src="assets/images/show_asset.svg" alt=""  kzLib (click)="show_asset($event,has_asset)">
  <div class="frame">
    <span *ngFor="let segment of segments" [ngClass]="{ul:segment.url}"  kzLib (click)="navigate(segment)">
      {{segment.tx}}
    </span>
  </div>
</div>
