<div class="out">
  <div class="top_line">
    <input kzInput class="search" type="name" placeholder="Search payments" [(ngModel)]="search" (ngModelChange)="changedSearch()">
    <!-- <div class="total_d">
      <span class="total">Total:<span class="blue">{{count}}</span></span>
    </div> -->
  </div>
  <div class="gray_line"></div>

  <div class="line t_t">
    <div class="el">Date Sent</div>
    <div class="el">Invoice Title</div>
    <div class="el"> Caller</div>
    <div class="el">Agent</div>
    <div class="el">Invoiced</div>
    <div class="el">Status</div>
  </div>

  <div class="h_content" #content>
    <div *ngIf="payments.length==0" class="abs_center">
      No Payments
    </div>

    <div class="line" *ngFor="let payment of payments, let i = index ">
      <div class="el"> {{payment.payed_on|date:'MMMM dd yyyy H:mm'}}</div>
      <div class="el"> {{payment.title}} </div>
      <div class="el"> {{payment.first_name}} {{payment.last_name}}</div>
      <div class="el"> {{payment.rep_name}} {{payment.rep_last_name}}</div>
      <div class="el"> {{payment.invoice.amount|currency}} </div>
      <div class="el"> {{payment.conv_pay.ssl_result_message}} </div>


    </div>

  </div>

  <div class="pag_wrapper">

    <k-paginator class="paginator" [total]="count" *ngIf="num_pages>1" [pages]="num_pages" [page_size]="page_size" (indexChange)="selPage($event)"></k-paginator>
  </div>
</div>
