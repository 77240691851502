import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { WSSService } from 'src/app/wss/wss.service';
import { PagesState } from '../../pages.model';
import { myRoll, showAsset } from '../../pages.selectors';

import { WSSState } from 'src/app/wss/wss.model';
import { callId, lastMsg } from 'src/app/wss/wss.selectors';
import { ShowDrawTools } from 'src/app/canvas/canvas.actions';
import { CanvasService } from 'src/app/canvas/canvas.service';
import { selectedTool } from 'src/app/canvas/canvas.selectors';

declare var KuulaPlayerAPI: any;


@Component({
  selector: 'app-kuula',
  templateUrl: './kuula.component.html',
  styleUrls: ['./kuula.component.scss']
})
export class KuulaComponent {
  kuula_src: any
  subscriptions: any[] = []
  frame_id: number = 0
  last_hotspot: any | undefined
  last_hotspot_str: string = ""
  last_orientation: any | undefined
  // last_orientation_str: string = ""
  last_orientation_rcv_at: number = 0
  last_orientation_snd_at: number = 0

  // interval: any

  last_heading: number = 0
  received_headings: number[] = [1, 2, 3]
  reveived_index: number = 0
  // bAllowDrawing: boolean = true
  channel_owner: string = "caller"
  my_roll: string = "rep"
  last_message_sent_at: number = 0
  begin_of_gesture: number = 0
  call_id: string | undefined
  @ViewChild('frame') frame: ElementRef | undefined;
  out_w: number = 0
  out_h: number = 0
  rv_l: number = 0
  rv_r: number = 0
  bSentKuulaDim: boolean = false
  bPreview: boolean = false
  sel_tool_name: string = "none"
  @Input() set asset(a: any) {
    let no_spaces = a.url.replace(/\s/g, '');
    this.kuula_src = this.sanitizer.bypassSecurityTrustResourceUrl(no_spaces);
    this.bPreview = true
  }
  constructor(
    public sanitizer: DomSanitizer,
    private pagesState: Store<PagesState>,
    public wss_service: WSSService,
    private wssState: Store<WSSState>,
    public canvasStore: Store<CanvasState>,
    public canvas_service: CanvasService,
  ) {
    console.log("kuula")
    this.subscriptions.push(this.canvasStore.select(selectedTool).subscribe((tool_name: string) => {
      this.sel_tool_name = tool_name
    }))
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
    })
    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        if (asset.asset_id || asset._id) {
          if (asset.type == "kuula") {
            this.canvas_service.setAsset({ type: "video_canvas", asset_id: "video_canvas1", name: "Videoboard", roll: "share" })

            if (asset.url) {
              // this.kuula_src = asset.url
              let no_spaces = asset.url.replace(/\s/g, '');
              this.kuula_src = this.sanitizer.bypassSecurityTrustResourceUrl(no_spaces);
            }
          }
        }
      }
    }))
    this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg) {
        if (msg.hasOwnProperty("kuula")) {
          let now = new Date().getTime()
          // console.log("<-- " + now + " " + JSON.stringify(msg))
          this.processMsg(msg.kuula)
        } else if (msg.kuula_get_channel) {
          let now = new Date().getTime()
          let dif = now - this.begin_of_gesture
          if (dif > 300) {
            let omsg = {
              kuula_grant_channel: msg.kuula_get_channel
            }
            this.channel_owner = msg.kuula_get_channel
            this.wss_service.sendMessageToOtherMembers(omsg)
          } else {
            console.error("deny get channel to  " + msg.kuula_get_channel + " dif " + dif)
          }

        } else if (msg.kuula_grant_channel) {
          this.channel_owner = msg.kuula_grant_channel
        } else
          // if (msg.hasOwnProperty("draw_kuula")) {
          //   this.bAllowDrawing = msg.draw_kuula
          //   this.canvasStore.dispatch(new ShowDrawTools(this.bAllowDrawing))
          // } else
          if (msg.hasOwnProperty("kuula_dim")) {
            console.log("KUULA <-- " + JSON.stringify(msg))
            this.setViewPort(msg.kuula_dim)
            if (!this.bSentKuulaDim) {
              this.bSentKuulaDim = true
              this.sendReSize() //We want to make sure the caller is ready to receive our msg
            }
          }
      }
    })
  }

  ngAfterViewInit() {
    let me = this;
    KuulaPlayerAPI.addEventListener("frameloaded", function(e: any) {
      me.frame_id = e.frame
      console.log("KUULA " + JSON.stringify({ "frameloaded": e }))

      // me.sendMessageToOtherMembers({ kuula: me.last_msg })
      // console.log("Frame id:   " + e.frame + " frame_id " + me.frame_id);
      // console.log("Posts list: " + e.data.posts);
      // console.log("Tour info:  " + e.data.tour);
    });
    KuulaPlayerAPI.addEventListener("postloaded", function(e: any) {

      me.frame_id = e.frame
      console.log("KUULA " + JSON.stringify({ "postloaded": e }))
      let msg = { kuula: { "postloaded": e } }
      me.sendMessageToOtherMembers(msg)
      console.log("Frame id:   " + e.frame + " data " + JSON.stringify(e.data));
      // console.log("Posts list: " + e.data.posts);
      // console.log("Tour info:  " + e.data.tour);
    });
    KuulaPlayerAPI.addEventListener("hotspot", function(e: any) {
      me.last_hotspot = { "hotspot": e }
      let msg = { kuula: { "hotspot": e } }
      me.sendMessageToOtherMembers(msg)
      let now = new Date().getTime()
      console.log("--> " + now + " " + JSON.stringify(msg))

      // me.last_hotspot = { "hotspot": e }
      // console.log("KUULA " + JSON.stringify({ "hotspot": e }))
    });
    KuulaPlayerAPI.addEventListener("cardclosed", function(e: any) {

      // console.log("KUULA " + JSON.stringify({ "cardclosed": e }))
    });
    KuulaPlayerAPI.addEventListener("orientation", function(e: any) {
      if (me.my_roll == me.channel_owner) {

        let now = new Date().getTime()
        let n = e.data.heading
        if (n != 0) {
          // console.log(n)
          n = Math.floor(n * 1000000000)

          let d = Math.abs(n - me.last_heading)
          if (d > 1000) {
            for (let i = 0; i < me.received_headings.length; i++) {
              if (me.received_headings[i] == n) {
                console.log("Skip sending msg ")
                return
              }
            }
            me.last_orientation_snd_at = now
            me.last_heading = n
            let msg = { kuula: { "orientation": e } }
            me.sendMessageToOtherMembers(msg)
          }
        }
      }
    });


    this.onResize()
  }
  /*          ***********-*-*************
      viewBox            |  |out_w,out_h*
    ------------         |  |           *
    |    x     |         |  |           *
    ------------         |  |           *
              *          |  |           *
              ************-*-************
      -----
      |   |    -----***-----
      |   |    |    * *    |
      |   |    |    * *    |
      |   |    -----***-----
      -----
    viewBox
*/
  setViewPort(viewBox: any) {

    let view_box_scaled_width = viewBox.width * this.out_h / viewBox.height
    this.rv_l = (this.out_w - view_box_scaled_width) / 2
    this.rv_r = this.rv_l + view_box_scaled_width
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    if (this.frame) {
      if (this.out_w != this.frame.nativeElement.clientWidth
        || this.out_h != this.frame.nativeElement.clientHeight) {
        this.out_w = this.frame.nativeElement.clientWidth
        this.out_h = this.frame.nativeElement.clientHeight
        this.sendReSize()
      }
    }
  }
  sendReSize() {
    let msg = {
      kuula_dim: {
        width: this.out_w,
        height: this.out_h
      }
    }
    this.wss_service.sendMessageToOtherMembers(msg)
    console.log("KUULA -->" + JSON.stringify(msg))
  }
  sendMessageToOtherMembers(msg: any) {
    let now = new Date().getTime()
    let dif = now - this.last_message_sent_at
    if (dif > 300) {
      this.begin_of_gesture = now
      console.log(" GESTURE " + this.begin_of_gesture)

    }
    this.last_message_sent_at = now

    if (this.my_roll == this.channel_owner) {  // console.log("--> " + this.last_message_sent_at + " " + JSON.stringify(msg))
      // console.log("--> " + this.last_message_sent_at + " " + JSON.stringify(msg))
      this.wss_service.sendMessageToOtherMembers(msg)
    }
  }
  processMsg(msg: any) {
    if (this.my_roll == this.channel_owner) {  // console.log("--> " + this.last_message_sent_at + " " + JSON.stringify(msg))
      return
    }
    let now = new Date().getTime()
    let dif = now - this.last_orientation_snd_at
    if (dif > 200) {
      if (msg.postloaded && this.frame_id) {
        console.log("<-- " + now + " " + this.last_heading)
        KuulaPlayerAPI.load(this.frame_id, msg.postloaded.data.id);
      }
      if (msg.orientation && this.frame_id) {
        this.last_orientation_rcv_at = now
        // this.last_heading = Math.floor(msg.orientation.data.heading * 1000000000)
        console.log("<-- " + now + " " + this.last_heading)
        this.received_headings[this.reveived_index] = Math.floor(msg.orientation.data.heading * 1000000000);
        if (++this.reveived_index >= this.received_headings.length) {
          this.reveived_index = 0
        }
        KuulaPlayerAPI.setHeading(this.frame_id, msg.orientation.data.heading);
        KuulaPlayerAPI.setPitch(this.frame_id, msg.orientation.data.pitch);
        // KuulaPlayerAPI.setZoom(this.frame_id, msg.orientation.data.heading);
      } else if (msg.hotspot) {
        KuulaPlayerAPI.hotspotClick(msg.hotspot.frame, msg.hotspot.data.uid)
      }
    } else {
      console.log("skipped in msg")
    }
    //KuulaPlayerAPI.setParam(frameId, hotspotId, param, value);
    // KuulaPlayerAPI.setHeading(frame_id, 60.06516241084146);
    // KuulaPlayerAPI.setPitch(frame_id, -3.3765513138990073);
    // KuulaPlayerAPI.setZoom(frame_id, 0)

    //KuulaPlayerAPI.setAutoRotate(frameId, value); //if the user set it with an UI

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    // clearInterval(this.interval)
    //set the state of the bar to blue, so it can be turned yellow again
    this.canvasStore.dispatch(new ShowDrawTools(false))
  }
  // toogleCanvas($event: any) {
  //   this.bAllowDrawing = !this.bAllowDrawing
  //   this.canvasStore.dispatch(new ShowDrawTools(this.bAllowDrawing))
  //   let msg = {
  //     draw_kuula: this.bAllowDrawing
  //   }
  //   this.wss_service.sendMessageToOtherMembers(msg)
  // }
  getChannel() {
    if (this.call_id) {
      let msg = {
        kuula_get_channel: this.my_roll
      }
      this.wss_service.sendMessageToOtherMembers(msg)
    } else {
      this.channel_owner = this.my_roll
    }
  }

}
