<div class="scrn">
  <div class="abs_center out">
    <div class="title">Connect & Embed Calendly with Keyzii</div>
    <p>
      First copy this link below:
      <span class="gray"> (pre booking page, user inserts email to view booked meeting)</span>
    </p>
    <div class="link_tx"  kzLib (click)="copyLik()">
      <img class="img" src="assets/images/link.svg" alt="">
      {{cal_link}}
    </div>
    <p>
      Now, go to Calendly, click <span class="black">‘edit’</span> on your event type, go to event details, select <span class="black">'All options'</span> under location, choose <span class="black">'Custom,'</span> and paste the link.
    </p>
    <p>
      Finally, <span class="black">‘copy & paste’</span> your updated Calendly event link in the field below & click save.
    </p>
    <input kzInput class="input" uame="name" [(ngModel)]="rep_link" placeholder="Insert your Calendly link here">
    <div class="bline">
      <div class="send"  kzLib (click)="updateCalendly()">
        <div class="abs_center">Save</div>
      </div>
    </div>
    <img src="assets/images/close.svg" class="close"  kzLib (click)="closeIt()">
  </div>
</div>
