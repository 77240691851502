<div class="scrn">
  <div class="abs_center out">
    <div class="title">
      {{title}}
    </div>

    <img class="close" src="assets/images/close.svg" alt="close"  kzLib (click)="cancel()">
    <div class="filter">
      <k-filter [single]="true" [sel_index]="rol_index" [title]="rol_desc" [min_width]="220" [radius]="8" title="Agent" [options]="user_types" (selected)="userRole($event )"></k-filter>
    </div>
    <input kzInput class="in" type="name" placeholder="First Name" name="" value="" [(ngModel)]="name" (ngModelChange)="changedName($event )">
    <input kzInput class="in" type="name" placeholder="Last Name" name="" value="" [(ngModel)]="last_name" (ngModelChange)="changedName($event )">

    <input kzInput *ngIf="bEditEmail" class="in" [ngClass]="{invalid:bInvaildEmail}" type="email" placeholder="Email" name="" value="" [ngModel]="email" (ngModelChange)="changedEmail($event )">
    <span *ngIf="!bEditEmail" class="email">{{email}} </span>
    <div class="invite">
      <div class="abs_center invite_btn" [ngClass]="{ready:bReady}" color="#fff"  kzLib (click)="invite()">
        <div class="itx">
          {{btn_lbl}}
        </div>
      </div>
    </div>
  </div>
</div>
