import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'k-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  from_c: number = 1
  to_c: number = 0

  sel_page: number = 1;
  @Input() name: string = "page"
  @Input() set index(value: number) {
    this.sel_page = value;
  }
  @Output() indexChange: EventEmitter<number> = new EventEmitter();


  _pages: number = 0
  @Input() set pages(np: number) {
    this._pages = np
  }
  _page_size: number = 0
  @Input() set page_size(ps: number) {
    this._page_size = ps
    this.setPropmt()
  }
  bDoPages: boolean = true
  _total: number = 0
  @Input() set total(tt: number) {
    this._total = tt
    this.bDoPages = false
    this.setPropmt()
  }
  constructor() {
    console.log("paginator")
  }

  ngOnInit(): void {
  }

  prev() {
    if (this.sel_page > 1) {
      this.indexChange.emit(--this.sel_page)
      this.setPropmt()
    }
  }
  next(): void {
    if (this.sel_page < this._pages) {
      this.indexChange.emit(++this.sel_page)
      this.setPropmt()
    }
  }
  setPropmt() {
    this.from_c = Math.floor(this._page_size * (this.sel_page - 1))
    this.to_c = this.from_c + Math.floor(this._page_size)
    if (this._total && this.to_c > this._total) {
      this.to_c = this._total
    }
    if (this._total == 0) {
      this.from_c = 0
      this.to_c = 0
    } else {
      this.from_c++
    }
    // console.log("paginator t " + this._total + " ps " + this._page_size + " sel " + this.sel_page + " fr " + this.from_c + " to " + this.to_c)
  }
}
