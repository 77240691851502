import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DeskButtonsComponent } from '../desk-buttons/desk-buttons.component';


@Component({
  selector: 'phone-buttons',
  templateUrl: './phone-buttons.component.html',
  styleUrls: ['./phone-buttons.component.scss']
})
export class PhoneButtonsComponent extends DeskButtonsComponent {


}
