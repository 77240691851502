<div class="scrn" #scrn>
  <div class="abs_center out">
    <div *ngIf="cc_page=='billing'" class="page">
      <div class="left">
        <div class="bdy">
          <div class="title letf_t">
            Billing Information
          </div>
          <div class="stitle">Email Address *</div>
          <input kzInput class="in" [ngClass]="{invalid:bInvalidEmail}" [ngClass]="{red_frame:bInvalidEmail}" type="email" placeholder="Email address" name="" value="" [(ngModel)]="email" (ngModelChange)="changedEmail($event)">
          <div class="stitle">Company name *</div>
          <div class="cc_li">
            <input kzInput class="in" type="text" placeholder="Company name" name="" value="" [(ngModel)]="cia_name" (ngModelChange)="changed()">
          </div>


          <div class="stitle">Company billing address * (for invoice)</div>
          <input kzInput class="in" type="text" placeholder="Address" name="" value="" [(ngModel)]="cc_address" (ngModelChange)="changed()">
          <div class="cc_li">
            <input kzInput class="in zip" type="text" placeholder="Zip Code" name="" value="" [(ngModel)]="cc_zip" (ngModelChange)="changed()">
            <input kzInput class="in state" type="text" placeholder="State/Region" name="" value="" [(ngModel)]="cc_state" (ngModelChange)="changed()">
            <img src="assets/images/expand.svg" class="expand" [ngClass]="{close_ex:bShowStates}" kzLib (click)="bShowStates=!bShowStates" />
          </div>

          <div *ngIf="cards.length>0">
            <div class="stitle">Select payment option(s)</div>
            <!-- <div class="cc_box">
            <div class="check selected"></div>
            <img class="cc" [src]="getCCLogo(sel_card.ssl_card_number)">

            <div class="cctx">
              {{sel_card.ssl_card_short_description}} {{sel_card.ssl_card_number}}
            </div>
            <div class="ex_cc">
              {{formatExpDate(sel_card.ssl_exp_date)}}
            </div>
          </div> -->
            <cards></cards>
          </div>
          <div class="c_info" *ngIf="cards.length==0">
            <div class="stitle">Card information *</div>
            <div class="cc_li">
              <input kzInput class="in first" type="text" placeholder="Card holder name" name="" value="" [(ngModel)]="first_name" (ngModelChange)="changed()">
              <input kzInput class="in last" type="text" placeholder="Last Name" name="" value="" [(ngModel)]="last_name" (ngModelChange)="changed()">
            </div>
            <div class="cc_li">
              <input kzInput class="in" type="text" placeholder="CC Number" name="" value="" [(ngModel)]="cc_number" [ngClass]="{red_frame:!bCCNumOk}" (ngModelChange)="ccNchanged()">
              <img *ngIf="cc_logo" class="cc_lg" [src]="cc_logo" alt="">
            </div>
            <div class="cc_li">
              <div class="sb">
                <input kzInput class="in month" type="month" min="2024-07" max="2099-11" placeholder="MM/YYYY" name="" value="" [(ngModel)]="cc_exp" (ngModelChange)="changed()">
              </div>
              <div class="sb">
                <input kzInput class="in ccv" type="text" placeholder="CCV" name="" value="" [(ngModel)]="cc_cvv" (ngModelChange)="changed()">
              </div>
            </div>
          </div>

          <div class="btmtx">
            PaymentStars is an Elavon Payments Partner & Registered MSP/ISO of Elavon, Inc. Georgia.  PaymentStars is a registered Independent Sales Organization of Wells Fargo Bank, N.A., Concord, CA. Your payment details are sent directly to our
            secure payment gateway. keyzii never stores your card number or CVC number. Payments are charged on the same day you subscribe or update your membership. Upgrades incur prorated charges, and downgrades take effect immediately.
          </div>
          <img class="payment_stars" src="assets/images/payment_stars.svg" alt="">

        </div>
      </div>
      <div class="right">
        <div class="bdy">
          <div class="title" *ngIf="!code">
            Your Keyzii Plan
          </div>
          <div class="title" *ngIf="code">
            <span *ngIf="code.users==1"> RH personal</span>
            <span *ngIf="code.users==10"> RH Entrepreneur</span>
            <span *ngIf="code.users==25"> RH Agency</span>
          </div>

          <div *ngIf="bFreeTrial" class="free_plan">
            <div class="">
              Free trial period
            </div>
            <div class="right_tx ">
              Ends {{plan_ends |date:'shortDate'}}
            </div>
          </div>
          <div class="code_div">
            <div class="stitle">Have a unique code?</div>
            <input kzInput class="in code" type="text" placeholder="Enter here" name="" value="" [(ngModel)]="in_code" (ngModelChange)="changedCode()">
            <div class="send add_code" [ngClass]="{btn_code_ready:btn_code_ready}" kzLib (click)="sendCode()">
              <div class="abs_center btn btn_add" [ngClass]="{btn_code_tx_ready:btn_code_ready}">
                Add
              </div>
            </div>
            <div class="codeapp" *ngIf="code">
              Code Applied: {{code_desc}}
            </div>
          </div>

          <div class="l_b">
            <input kzInput class="num_users" type="text" [(ngModel)]="num_users" (ngModelChange)="changeUsers($event)" [ngClass]="{min_usrs:bInvalidUsers}"><span class="fs14"> Add users to your account</span>
          </div>
          <div class="stitle">
            What is included?
          </div>
          <div class="l_b fs12" *ngIf="!code">
            {{num_assets}} Assets - Unlimited Document Signing - Adaptive Call & Schedule Button - Scheduling - White Label - Integrations.
          </div>
          <div class="l_b fs12" *ngIf="code">
            {{code.users}} {{code_epx}}
            <span *ngIf="code.users==1">user</span>
            <span *ngIf="code.users>1">users</span>
            <span *ngIf="non_code_users>0">
              + {{non_code_users}} Paid
              <span *ngIf="non_code_users==1">user</span>
              <span *ngIf="non_code_users>1">users</span>
            </span>
            , {{num_assets}} Assets - Unlimited Document Signing - Adaptive Call & Schedule Button - Scheduling - White Label - Integrations.
          </div>
          <div class="g_line g_l_top"> </div>
          <div class="cc_li l_b fs14">
            Amount /User<div class="spacer"></div><span class="money">{{amount_user|currency}}</span>
          </div>
          <div class="g_line"> </div>
          <div class="cc_li l_b fs14">
            Total /{{period}}
            <div class="spacer"></div><span class="money">{{amount|currency}}</span>
          </div>
          <div class="g_line"> </div>
          <div class="cc_li l_b fs14">
            Billed Today<div class="spacer"></div><span class="money total">{{billed_today|currency}}</span>
          </div>
          <div class="cc_li l_b anualy fs14">
            Billed Annually?
            <switch class="switch" [checked]="bYearly" (checkedChange)="setAnualy($event)"></switch><span class="save">Save 10%</span>
          </div>
          <div class="send" [ngClass]="{btn_ready:bReady}" kzLib (click)="processIt()">
            <div class="abs_center btn" [ngClass]="{btn_tx_ready:bReady}">
              Subscribe
            </div>
          </div>
        </div>
      </div>
      <div class="cc_li lr_msg">
        Monthly amount will be billed on {{plan_ends |date:'shortDate'}} automatically
      </div>
      <img src="assets/images/close.svg" class="close" kzLib (click)="closeIt()">
    </div>
    <div class="page" *ngIf="cc_page=='process'">
      <div class="abs_center spin">
        <k-spinner class="abs_center sp_icon"></k-spinner>
        Processing
      </div>
    </div>
    <div class="page" *ngIf="cc_page == 'done'">
      <div class="abs_center done">
        <img class="done1" *ngIf="!cc_error" src="assets/images/done1.svg" alt="">
        <img class="done1" *ngIf="cc_error" src="assets/images/error1.svg" alt="">
        <div class="done1 title" *ngIf="!cc_error">
          {{done_prompt}}
        </div>
        <div class="done1 title" *ngIf="cc_error">
          Oops, something went wrong!
        </div>
        <div class="done1 " *ngIf="!cc_error">

        </div>
        <div class="done1" *ngIf="cc_error">
          {{cc_error|json}}
        </div>

        <div class="send abs_center done_btn btn_code_ready" kzLib (click)="doneError()">
          <div class="abs_center">
            Done
          </div>

        </div>
      </div>
    </div>
    <div class="page" *ngIf="cc_page=='cancel'">
      <div class="left">
        <div class="bdy">
          <div class="title">
            Cancel Plan
          </div>
          <div class="txt">
            “We believe that virtual interactions should feel like real life. So, we’ve built a secure & collaborative video calling platform that’s as easy and enjoyable as meeting a client for coffee.”
            — keyzii
          </div>
          <div class="txt stitle del" kzLib (click)="delete()">
            Delete Account
          </div>
        </div>
      </div>
      <div class="right">
        <div class="bdy">
          <div class="title">
            Free Plan
          </div>
          <div class="txt stitle del" kzLib (click)="delete()">
            Please Notice
          </div>
          <div class="">
            Details about free plan and downgrading from an agency or enterprise plan and what that means to users account </div>
          <div class="txt stitle">
            What is included?
          </div>
          <div class="">
            500 Assets - Unlimited Document Signing - Adaptive Call & Schedule Button - Scheduling - White Label - Integrations.
          </div>
          <div class="g_line"> </div>
          <div class="cc_li l_b">
            Amount /User<div class="spacer"></div><span class="money">{{amount_user|currency}}</span>
          </div>
          <div class="g_line"> </div>
          <div class="cc_li l_b">
            Total /{{period}}
            <div class="spacer"></div><span class="money">{{amount|currency}}</span>
          </div>
          <div class="cc_li l_b anualy">
            Billed Annually?
            <switch class="switch" [checked]="bYearly" (checkedChange)="setAnualy($event)"></switch><span class="save">Save 10%</span>
          </div>
          <div class="send" [ngClass]="{btn_ready:bReady}" kzLib (click)="processIt()">
            <div class="abs_center btn">
              Subscribe
            </div>
          </div>
          <div class="l_b">
            Free plan legal script?
          </div>

        </div>
      </div>
      <img src="assets/images/close.svg" class="close" kzLib (click)="closeIt()">

    </div>
    <div class="states" *ngIf="bShowStates">
      <div class="state_l" *ngFor="let state of us_states; let i = index" kzLib (click)="selState(i)">
        {{state.name}}
      </div>
    </div>
    <captcha *ngIf="bShowCaptcha" doing="add_cc" (token)="gotCapToken($event)" class="screen"></captcha>
  </div>
  <!-- <div class="democ">
    <a  kzLib (click)="goToLink('https://developer.elavon.com/products/converge/v1/test-cards')">Demo Cards Visa </a> 4000000000000002 Amex 370000000000002 MC 5121212121212124
  </div> -->

</div>
