<div class="out" #outComponent>
  <div class="top" #topDiv>
    <mls-location class="sel_f"></mls-location>
    <mls-categories class="sel_f"></mls-categories>
    <mls-price class="sel_f"></mls-price>
    <mls-prop-status class="sel_f"></mls-prop-status>
    <mls-beds-baths class="sel_f"></mls-beds-baths>
    <mls-more class="sel_f"></mls-more>
    <!-- <div class="sel_f kbtn"  kzLib  kzLib (click)="showAll()">Show All</div> -->

    <k-check class="sel_f check" [(checked)]="bShowMap" text="Show map" (checkedChange)="showMap($event)"></k-check>


    <div class="sel_f clear " kzLib (click)="clearAll()">Clear All</div>
  </div>

  <div class="bottom">

    <div id="map" class="map" [ngClass]="{width100:bShowSelLocation, hide:!bShowMap}">
    </div>
    <mls-properties *ngIf="!bShowSelLocation" class="props" [ngClass]="{full_scrn:!bShowMap}">

    </mls-properties>

  </div>
  <!-- <div class="url" tooltip="Click to copy to clipboard" [cdkCopyToClipboard]="search_url">{{search_url}}</div> -->
  <!-- <mat-icon *ngIf="bShowClose" class="close"  kzLib  kzLib (click)="close()">close</mat-icon> -->

  <!-- <sel-state class="opt"></sel-state> -->
  <k-button class="close" icon="close.svg" background="transparent" kzLib (click)="close()"></k-button>
</div>


<div *ngIf="sel_property" class="full_scrn">
  <mls-property></mls-property>

</div>
