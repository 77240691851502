import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts, SetInput, SetTool } from '../../canvas.actions';
import { SelSignerComponent } from '../sel-signer/sel-signer.component';
import { CanvasState } from 'src/app/canvas/canvas.model';

@Component({
  selector: 'sel-input',
  templateUrl: './sel-input.component.html',
  styleUrls: ['./sel-input.component.scss']
})

//SelSignerComponent extended to make sure there is a signer selected
export class SelInputComponent extends SelSignerComponent {
  box_color: string = "#3B5FE0"
  sname: string = ""

  constructor(
    public canvasStore: Store<CanvasState>,
    // private wss_service: WSSService
  ) {
    super(canvasStore);
    console.log("sel-input")
    this.selected_input = ""
  }

  setColors() {

    setTimeout(() => {
      if (this.signer_key) {
        let signer = this.signers[this.signer_key]
        this.box_color = signer.color
        this.sname = signer.name
        console.log("set " + signer.name + " " + this.box_color)
      }
    })


  }


  sign() {
    this.canvasStore.dispatch(new SelectToolOpts("i1"))
    this.canvasStore.dispatch(new SetInput("Sign"))
  }
  initial() {
    this.canvasStore.dispatch(new SelectToolOpts("i2"))
    this.canvasStore.dispatch(new SetInput("Initial"))
  }
  date() {
    this.canvasStore.dispatch(new SelectToolOpts("i3"))
    this.canvasStore.dispatch(new SetInput("Date"))
  }
  name() {
    this.canvasStore.dispatch(new SelectToolOpts("i4"))
    this.canvasStore.dispatch(new SetInput("Name"))
  }
  email() {
    this.canvasStore.dispatch(new SelectToolOpts("i5"))
    this.canvasStore.dispatch(new SetInput("Email"))
  }
  text() {
    this.canvasStore.dispatch(new SelectToolOpts("i6"))
    this.canvasStore.dispatch(new SetInput("Text"))
  }
}
