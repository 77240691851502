import { Component } from '@angular/core';

@Component({
  selector: 'app-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent {
  reload() {
    window.location.reload();
  }
}
