import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ContactsState } from './contacts.model';

import { PagesState } from '../pages.model';
import { WSSState } from 'src/app/wss/wss.model';
import { callId, lastMsg } from 'src/app/wss/wss.selectors';
import { CallerData, ContactInfo } from '../pages.actions';
import { GotContacts } from './contacts.actions';
import { validateEmail } from 'src/app/utils';
@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(
    private httpClient: HttpClient,
    private wssState: Store<WSSState>,
    private pagesState: Store<PagesState>,
    private contactsStore: Store<ContactsState>,
  ) {
    console.log("contacts service")
    /*
    {"ask_answer":"caller","email":"sergio@asci.us","name":"Sergio","customer_id":"60e4ade58dbe3f471800b033","caller_id":"648235ea3e5071e609fac4a5","call_id":"648238023e5071e609fac4a8"}
    */
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (!call_id) {
        this.pagesState.dispatch(new ContactInfo("no"))
      }
    })
    this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg) {
        if (msg.hasOwnProperty("ask_answer")) {
          if (validateEmail(msg.email)) {
            let contact: any = {
              email: msg.email,
              first_name: msg.first_name,
              last_name: msg.last_name,
              cia_name: msg.cia_name,
              caller_id: msg.caller_id
            }
            this.addContact(contact)
            this.pagesState.dispatch(new CallerData({
              caller_first_name: msg.first_name,
              caller_last_name: msg.last_name,
              caller_cia_name: msg.cia_name,
              caller_email: msg.email,
            }))
            this.pagesState.dispatch(new ContactInfo("ok"))
          } else {
            this.pagesState.dispatch(new ContactInfo("no"))
          }
        }
      }
    })
  }
  async addContact(contact: any) {
    try {
      let new_contact: any = await this.httpClient.post<any>('/contacts', contact).toPromise();
    } catch (e: any) {
      console.error("Error add contact " + JSON.stringify(e))
    }
  }
  async updateContact(contact: any) {
    try {
      let new_contact: any = await this.httpClient.put<any>('/contacts', contact).toPromise();
    } catch (e: any) {
      console.error("Error update contact " + JSON.stringify(e))
    }
  }
  last_get_contact_url: string | undefined
  async getContacts(in_page: number, page_size: number, sel_index: number, search: string) {
    try {
      let url: string = "/contacts?page=" + in_page + "&page_size=" + page_size
      if (sel_index == 1) {
        url += "&agency=1"
      }
      if (search) {
        const encoded = encodeURI(search);
        url += "&search=" + encoded
      }
      let rv: any = await this.httpClient.get<any[]>(url).toPromise();
      this.last_get_contact_url = url//in case we delete it
      this.contactsStore.dispatch(new GotContacts(rv))

    } catch (e: any) {
      console.error("Error get contacts " + JSON.stringify(e))
    }
  }
  del_id: string | undefined
  setDelId(del_id: any) {
    this.del_id = del_id
  }
  cancelDeleteContact() {
    delete this.del_id
  }
  async deleteContact() {
    if (this.del_id) {
      try {
        await this.httpClient.put<any[]>('/del_contacts', [this.del_id]).toPromise();

        if (this.last_get_contact_url) {
          let rv: any = await this.httpClient.get<any[]>(this.last_get_contact_url).toPromise();
          this.contactsStore.dispatch(new GotContacts(rv))
        }
      } catch (e: any) {
        console.error("Error deleting contacts " + JSON.stringify(e))
      }
    }
  }

}
