import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetsComponent } from './assets/assets.component';
import { FormsModule } from '@angular/forms';
import { ControlsModule } from 'src/app/controls/controls.module';
import { SelAssetComponent } from './sel-asset/sel-asset.component';
import { AssetComponent } from './asset/asset.component';
import { NewAssetComponent } from './new-asset/new-asset.component';
import { FileUploadModule } from 'ng2-file-upload';
import { EditAssetComponent } from './edit-asset/edit-asset.component';
import { MatterportComponent } from './matterport/matterport.component';
import { UploadedVideoComponent } from './uploaded-video/uploaded-video.component';
import { ImagesComponent } from './images/images.component';
import { ImgSlideComponent } from './images/img-slide/img-slide.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { YoutubeComponent } from './asset/youtube/youtube.component';
import { CanvasModule } from 'src/app/canvas/canvas.module';
import { ContractsComponent } from './contracts/contracts.component';
import { ContractLineComponent } from './contracts/contract-line/contract-line.component';
import { DatePipe } from '@angular/common'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NewTabAssetComponent } from './new-tab-asset/new-tab-asset.component';
import { KuulaComponent } from './kuula/kuula.component';
import { AssetOrFolderComponent } from './asset/asset-or-folder/asset-or-folder.component';
import { FoldersComponent } from './folders/folders.component';
import { PreviewComponent } from './preview/preview.component';
import { ApiWndComponent } from '../api-wnd/api-wnd.component';
import { DndDirective } from './dnd.directive';
import { ConfContDelComponent } from './contracts/conf-cont-del/conf-cont-del.component';
import { DelAssetsComponent } from './del-assets/del-assets.component';
import { KzLibModule } from 'src/app/kz-lib/kz-lib.module';

@NgModule({
  declarations: [
    AssetsComponent,
    SelAssetComponent,
    AssetComponent,
    NewAssetComponent,
    EditAssetComponent,
    MatterportComponent,
    UploadedVideoComponent,
    ImagesComponent,
    ImgSlideComponent,
    YoutubeComponent,
    ContractsComponent,
    ConfContDelComponent,
    ContractLineComponent,
    NewTabAssetComponent,
    KuulaComponent,
    AssetOrFolderComponent,
    FoldersComponent,
    PreviewComponent,
    ApiWndComponent,
    DndDirective,
    DelAssetsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ControlsModule,
    FileUploadModule,
    NgxYoutubePlayerModule.forRoot(),
    CanvasModule,
    DragDropModule,
    KzLibModule

  ], exports: [
    AssetsComponent,
    AssetComponent,
    SelAssetComponent,
    NewTabAssetComponent,
    AssetOrFolderComponent,
    YoutubeComponent,
    PreviewComponent,
    NewAssetComponent,
    ConfContDelComponent,
    DelAssetsComponent,
  ],
  providers: [DatePipe]
})
export class AssetsModule { }
