import { createFeatureSelector, createSelector } from "@ngrx/store";
import { kPayState } from "./kpay.model";

export const selectkPayStateState = createFeatureSelector<kPayState>('kPay');



//******************************************************************************
//
//******************************************************************************

export const showKMenu = createSelector(
  selectkPayStateState,
  state => state.bShowMenu
)
export const runKPay = createSelector(
  selectkPayStateState,
  state => state.running
)
export const retryKPay = createSelector(
  selectkPayStateState,
  state => state.retry_running
)
export const gotInvoice = createSelector(
  selectkPayStateState,
  state => state.invoice
)
export const planFromRep = createSelector(
  selectkPayStateState,
  state => state.plan_from_rep
)
export const planFromCaller = createSelector(
  selectkPayStateState,
  state => state.plan_from_caller
)
export const gotKAcc = createSelector(
  selectkPayStateState,
  state => state.acc
)
export const gotKCC = createSelector(
  selectkPayStateState,
  state => state.cc
)

export const gotKBillInfo = createSelector(
  selectkPayStateState,
  state => state.bill_info
)
export const gotKResponse = createSelector(
  selectkPayStateState,
  state => state.response
)
export const createAccInSvr = createSelector(
  selectkPayStateState,
  state => state.create_acc_in_svr
)
export const sendPaymentToSvr = createSelector(
  selectkPayStateState,
  state => state.send_payment_to_svr
)

export const cancelRemoteKPay = createSelector(
  selectkPayStateState,
  state => state.remote_cancel
)
export const cancelLocalKPay = createSelector(
  selectkPayStateState,
  state => state.local_cancel
)
export const showKScreen = createSelector(
  selectkPayStateState,
  state => state.bShowScreen
)
export const payBarStep = createSelector(
  selectkPayStateState,
  state => state.pay_bar_step
)
export const planBarStep = createSelector(
  selectkPayStateState,
  state => state.plan_bar_step
)
export const targetUrl = createSelector(
  selectkPayStateState,
  state => state.target_url
)
export const gotError = createSelector(
  selectkPayStateState,
  state => state.error
)
export const notifyDonePay = createSelector(
  selectkPayStateState,
  state => state.notify_done_pay
)
export const kPayError = createSelector(
  selectkPayStateState,
  state => state.error_no
)
