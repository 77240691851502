<div class="s_out" #outComponent>
  <video *ngIf="video_roll != 'kuula'" [muted]="true" #videoElement class="container" [style.height.px]="container_height" [style.top.px]="container_top" [style.width.px]="container_width" [style.left.px]="container_left" autoPlay autoplay
    playsinline>
  </video>

  <div class="container canvas" #divContainer [style.height.px]="canvas_height" [style.top.px]="canvas_top" [style.width.px]="canvas_width" [style.left.px]="canvas_left">
    <!-- MAKE SURE NOT TO PUT ANY THING INSIDE -->
  </div>

  <div *ngIf="!last_on_board && rv_l" class="rv_l" [style.width.px]="rv_l">
    <!-- <div class="not_v">
      Not viewable to caller
    </div>
    <div class="not_v not_v_tx">
      Your screen is wider than the callers
    </div> -->
  </div>
  <div *ngIf="!last_on_board && rv_r1" class="rv_l rv_r" [style.left.px]="rv_r1" [style.width]="rv_rw">
    <!-- <div class="not_v">
      Not viewable to caller
    </div>
    <div class="not_v not_v_tx">
      Your screen is wider than the callers
    </div> -->
  </div>
  <div *ngIf="last_on_board " class="wall" [style.left.px]="wall_l" [style.top.px]="wall_t" [style.width.px]="wall_w" [style.height.px]="wall_h">
    <!-- {{wall_l}} {{wall_t}} {{wall_w}} {{wall_h}} -->
    {{canvas_left}} {{canvas_top}} {{canvas_width}} {{canvas_height}}
  </div>

  <div *ngIf="last_on_board " class="wall_t" [style.left.px]="twall_l" [style.top.px]="twall_t" [style.width.px]="twall_w" [style.height.px]="wall_h">
    <!-- {{twall_l}} {{twall_t}} {{twall_w}} {{twall_h}} -->

  </div>
</div>
