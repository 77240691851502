import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccOverdue, ShowCC } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { gotInvoices, showCC } from 'src/app/pages/pages.selectors';

@Component({
  selector: 'free-end',
  templateUrl: './free-wnd.component.html',
  styleUrls: ['./free-wnd.component.scss']
})
export class FreeWndComponent {
  bShowClose: boolean = true;
  title: string = "Your free trial ends soon!"
  ends_in: string = "  Ends in 7 Days"
  btn_prompt: string = "Edit Plan & Add Card"
  constructor(
    public pagesState: Store<PagesState>,
    public router: Router,
  ) {
    console.log("free")
    this.pagesState.select(gotInvoices).subscribe((invoices: any[]) => {
      let dif_days: number = 7
      if (invoices && invoices.length > 0) {
        let last = invoices[0]

        if (last.amount == 0) {
          let dif_ms = new Date(last.next_date).getTime() - new Date().getTime()
          dif_days = Math.floor(dif_ms / (24 * 60 * 60 * 1000))

          if (dif_days < 0) {
            this.title = "Your free trial has expired!"
            this.ends_in = "Get started today"
            this.btn_prompt = "Join now"
          } else {
            this.ends_in = "Ends in " + dif_days + " Days"
          }
        } else {
          let dif_ms = new Date().getTime() - new Date(last.created_at).getTime()
          dif_days = Math.floor(dif_ms / (24 * 60 * 60 * 1000))

          this.title = "Your Invoce is overdue!"
          if (dif_days > 0) {
            this.bShowClose = false
            this.router.navigate(['profile']);
            this.pagesState.dispatch(new AccOverdue(true))
            // this.ends_in = "Overdue " + dif_days + " Days"
          }
          //  else {
          //   this.ends_in = "Overdue today"
          // }
          this.title = "Your free trial has expired!"
          this.ends_in = "Get started today"
          this.btn_prompt = "Join now"
        }
      }
    })
    this.pagesState.select(showCC).subscribe((show_cc: string | undefined) => {
      if (show_cc) {
        this.close.emit()
      }
    })
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  closeIt() {
    this.close.emit()
  }

  openCC() {
    this.pagesState.dispatch(new ShowCC("billing"))
  }
}
