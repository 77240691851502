import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetTab } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { activeCalls, numActiveCalls } from '../../pages.selectors';

@Component({
  selector: 'app-call-management',
  templateUrl: './call-management.component.html',
  styleUrls: ['../../profile/profile/profile.component.scss',
    './call-management.component.scss']
})
export class CallManagementComponent implements OnInit {
  bShowLiveCalls: boolean = false
  tab: string = "live"
  subscriptions: any[] = []

  constructor(
    private pagesState: Store<PagesState>,
  ) { }
  ngOnInit(): void {
    let tab = localStorage.getItem('cmtab')
    if (tab) {
      this.tab = tab;
    }
    this.pagesState.dispatch(new SetTab(this.tab))
    this.subscriptions.push(this.pagesState.select(numActiveCalls).subscribe((num: number) => {
      this.bShowLiveCalls = num > 0
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })

  }
  select(tab: string) {
    this.tab = tab
    localStorage.setItem('cmtab', tab)
    this.pagesState.dispatch(new SetTab(this.tab))

  }
}
