import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { PayService } from 'src/app/kpay/pay.service';
import { ShowCC } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';


@Component({
  selector: 'plan-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent {
  _what: string = "down"
  @Input() set what(w: string) {
    //
    if (w == "del") {
      this.title = "Delete"
      this.txt = "Deleting your account removes all your information and revokes all users permissions. You will not be ablve to log back into your keyzii account. "
      this.conf = 'To confirm this, type “DELETE”'
      this.btn = "Delete Account"
    }
    this._what = w
  }
  title: string = "Are you sure you want to downgrade?"
  txt: string = "Downgrading permanently deletes all content and users except the account holder. Deleted content and users cannot be recovered."
  conf: string = 'To confirm this, type “DOWNGRADE”'
  btn: string = "Downgrade"

  conf_tx: string = ""
  bReady: boolean = false;
  constructor(
    public pay_service: PayService,
    public pagesState: Store<PagesState>,
  ) {
    console.log("// WARNING: ")
  }
  confChange() {
    console.log("COnf " + this.conf_tx)
    if (this._what == "del") {
      this.bReady = (this.conf_tx == "DELETE")
    } else {
      this.bReady = (this.conf_tx == "DOWNGRADE")
    }
  }
  async doIt() {
    try {
      if (this._what == "del") {
        await this.pay_service.removeAccount()
      } else {
        await this.pay_service.setFree()
        this.pagesState.dispatch(new ShowCC(undefined))
      }
    } catch (e) {
      this.pagesState.dispatch(new ShowCC(undefined))
    }
  }
  cancel() {
    this.pagesState.dispatch(new ShowCC(undefined))
  }
}
