import * as store from '@ngrx/store';

export enum KVideoActionTypes {
  SetAudio = '[audio]on_off',
  SetVideo = '[video]on_off',
  SetShare = '[share]on_off',
  GotStream = '[stream]mine',
  RemoveStream = '[stream]off',
  AddTrack = '[track]add',

  RemoteTracksIds = '[remote tracks]id',
  CheckVideo = '[video]check',
  MediaState = '[media]state',

  SelectedSpeaker = '[speaker]id',
  SelectedMic = '[mic]id',
  SelectedCamera = '[camera]id',

  ClearMediaState = '[media]clear',
  HasVideoCamera = '[camera]exist'
}
export class HasVideoCamera implements store.Action {
  readonly type = KVideoActionTypes.HasVideoCamera;
  constructor(public bHasVideoCamera: boolean) { }
}
export class ClearMediaState implements store.Action {
  readonly type = KVideoActionTypes.ClearMediaState;
  constructor(public target: string) { }
}
export class SelectedSpeaker implements store.Action {
  readonly type = KVideoActionTypes.SelectedSpeaker;
  constructor(public id: string) { }
}
export class SelectedMic implements store.Action {
  readonly type = KVideoActionTypes.SelectedMic;
  constructor(public id: string) { }
}
export class SelectedCamera implements store.Action {
  readonly type = KVideoActionTypes.SelectedCamera;
  constructor(public id: string) { }
}
export class MediaState implements store.Action {
  readonly type = KVideoActionTypes.MediaState;
  constructor(public msg: any) { }
}
export class RemoveStream implements store.Action {
  readonly type = KVideoActionTypes.RemoveStream;
  constructor(public role: string) { }
}
export class CheckVideo implements store.Action {
  readonly type = KVideoActionTypes.CheckVideo;
  constructor(public role: string | undefined) { }
}
export class RemoteTracksIds implements store.Action {
  readonly type = KVideoActionTypes.RemoteTracksIds;
  constructor(public from: string, public tracks: any) { }
}
export class AddTrack implements store.Action {
  readonly type = KVideoActionTypes.AddTrack;
  constructor(public role: string, public track: MediaStreamTrack) { }
}
export class SetShare implements store.Action {
  readonly type = KVideoActionTypes.SetShare;
  constructor(public on: boolean) { }
}
export class SetVideo implements store.Action {
  readonly type = KVideoActionTypes.SetVideo;
  constructor(public on: boolean) { }
}
export class SetAudio implements store.Action {
  readonly type = KVideoActionTypes.SetAudio;
  constructor(public on: boolean) { }
}
export class GotStream implements store.Action {
  readonly type = KVideoActionTypes.GotStream;
  constructor(public role: string, public stream: MediaStream) { }
}


export type KVideoAction =
  SetAudio
  | SetVideo
  | GotStream
  | AddTrack
  | SetShare
  | RemoteTracksIds
  | CheckVideo
  | RemoveStream
  | MediaState
  | ClearMediaState

  | SelectedSpeaker
  | SelectedMic
  | SelectedCamera
  | HasVideoCamera
  ;
