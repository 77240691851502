import { Component, OnInit } from '@angular/core';
import { InCallComponent } from '../in-call/in-call.component';

@Component({
  selector: 'in-call-caller',
  templateUrl: './caller.component.html',
  styleUrls: ['./caller.component.scss']
})
export class CallerComponent extends InCallComponent {



}
