import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { NotifyError } from 'src/app/pages/pages.actions';
import { BillingComponent } from '../billing.component';

@Component({
  selector: 'new-cc',
  templateUrl: './new-cc.component.html',
  styleUrls: ['../credit-card/credit-card.component.scss', './new-cc.component.scss']
})

export class NewCcComponent extends BillingComponent {

  bCCNumOk: boolean = true;
  bShowCaptcha: boolean = false
  ngAfterViewInit() {
    console.log("New Card ")
    this.cc_number = ""
    this.cc_exp = ""
    this.cc_cvv = ""


  }

  addCard() {
    this.bShowCaptcha = true;
  }
  async gotCapToken(cap_token: string) {
    try {
      if (cap_token) {
        let rv: any = await this.pay_service.setCC(cap_token, {
          cc_exp: this.cc_exp,
          cc_number: this.cc_number,
          amount: this.amount,
          first_name: this.first_name,
          last_name: this.last_name,
          cc_address: this.cc_address,
          cc_zip: this.cc_zip,
          cc_state: this.cc_state,
        })
        this.cc_page = rv.page
        this.cc_error = rv.error
      }
    } catch (e: any) {
      this.cc_page = e.page
      this.cc_error = e.error
    }

    this.closeIt()
    this.ccChange()

  }


  ccChange() {
    this.cc_logo = this.getCCLogo(this.cc_number)
    this.bCCNumOk = this.validateCC()

    if (
      this.first_name.length < 1
      || this.last_name.length < 1
      || this.cc_number.length < 1
      || this.cc_cvv.length < 3
      || this.cc_exp.length < 6
    ) {
      this.bReady = false
      return;
    }
    this.bReady = this.bCCNumOk
  }
}
