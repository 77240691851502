import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from 'src/app/chapala-mls/chapala-mls-service';
import { ChapalaMLSState } from 'src/app/chapala-mls/chapala-mls.model';
import { gotMLSMsg, searchBy } from 'src/app/chapala-mls/chapala-mls.selectors';
import { SelectorComponent } from '../selector.component';

@Component({
  selector: 'mls-price',
  templateUrl: './price.component.html',
  styleUrls: ['../selector.component.scss', './price.component.scss']
})
export class PriceComponent extends SelectorComponent {
  bShowPriceOptions: boolean = false;
  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);
    console.log("Price")

    this.setTitle("Price")

    this.subscriptions.push(this.chapalaMLState.select(searchBy).subscribe((search_by: any) => {
      if (search_by) {

        setTimeout(() => {
          let title = "Price"

          if (search_by.min_price) {
            title = "Min " + this.formatCurrency(search_by.min_price)
          }
          if (search_by.max_price) {
            title = "Max " + this.formatCurrency(search_by.max_price)
          }
          if (search_by.min_price && search_by.max_price) {
            title = this.formatCurrency(search_by.min_price) + " - " + this.formatCurrency(search_by.max_price)
          }
          this.title = title
          // this.bShowOptions = false
        }, 1)

      }
    }))
    this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      if (msg) {
        if (msg.show) {
          if (msg.show.hasOwnProperty("Price")) {
            setTimeout(() => {
              this.bShowPriceOptions = msg.show.Price
            }, 1)
          } else {
            this.bShowPriceOptions = false
          }
        }
      }
    })

  }
  formatCurrency(val: string) {
    let newVal = val.replace(/\D/g, "");
    if (newVal.length === 0) {
      newVal = "";
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, "$1");
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})/, "$1,$2");
    } else if (newVal.length <= 5) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, "$1,$2");
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "$1,$2");
    } else if (newVal.length <= 7) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,4})/, "$1,$2,$3");
    } else if (newVal.length <= 8) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})/, "$1,$2,$3");
    } else if (newVal.length <= 9) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "$1,$2,$3");
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(
        /^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/,
        "$1,$2,$3,$4"
      );
    }
    return newVal;
  }
  showPriceOptions() {
    let msg: any = {
      show: {}
    }
    if (this.bShowPriceOptions) {
      msg.show["Price"] = false
    } else {
      msg.show["Price"] = true
    }
    this.chapala_mls_service.sendMsg(msg)
  }
}
