<div class="out">

  <img class="abs_center icon" src="assets/images/error1.svg" alt="" kzLib (click)="closeIt()">
  <div class="abs_center title">
    Oops, something went wrong!
  </div>
  <div class="abs_center title error">
    {{error}}
  </div>
  <!-- <div class="continue">
    <div class="abs_center">
      Continue
    </div>
  </div> -->
  <img src="assets/images/close.svg" class="close" (click)="closeIt()">

</div>
