import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCC } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { callerFirstName, callerLastName, onBoardURLs } from 'src/app/pages/pages.selectors';
import { CancelLocalKPay, SetPlanFromRep } from '../kpay.actions';
import { kPayState } from '../kpay.model';
import { cancelLocalKPay } from '../kpay.selectors';
import { PayService } from '../pay.service';

@Component({
  selector: 'kplan-wnd',
  templateUrl: './kplan-wnd.component.html',
  styleUrls: ['./kplan-wnd.component.scss']
})
export class KplanWndComponent {
  num_users: number = 5
  user_price: number = 200

  urls: string[] = []
  url: string = ""



  on_board = [
    { name: "None", type: "all", desc: "no onboarding" },
    {
      type: "iframe_pro",
      name: "Onboard Lite",
      desc: "Agent opens asset (iFrame), caller enables asset to be shared via allowing screen share. Agent can view and annotate over shared screen. "
    },
    {
      type: "on_board_pro",
      name: "Onboard Pro",
      desc: "Agent opens asset (iFrame), asset loads with keyzii library for caller to allow screen share. Agent can view, write, click and annotate. "

    },
    {
      type: "on_board_p2p",
      name: "Onboard P2P",
      desc: "Agent opens asset (iFrame), opens for both agent and caller with keyzii library, not requiring screen share. Agent & caller can view, write, click and annotate. "

    },
    {
      type: "on_board_wnd",
      name: "Onboard Pro Window",
      desc: "Agent opens asset (iFrame), asset loads with keyzii library in a new window for caller to allow screen share. Agent can view, write, click and annotate. "
    },
  ]
  on_board_index: number = 0;

  constructor(
    public pay_service: PayService, //Make sure the service is ready
    public kpayState: Store<kPayState>,
    public pagesState: Store<PagesState>,
  ) {
    console.log("kpay-wnd")
    this.kpayState.select(cancelLocalKPay).subscribe((bCancel: boolean) => {
      this.close.emit()
    })
    this.pagesState.select(onBoardURLs).subscribe((on_board_urls: any[]) => {
      this.urls = on_board_urls
    })
  }

  @Output() close: EventEmitter<any> = new EventEmitter();
  closeIt() {
    this.kpayState.dispatch(new CancelLocalKPay())
  }
  sendPlan() {
    let amount = this.num_users * this.user_price
    let plan: any = {
      num_users: this.num_users,
      min_users: this.num_users,
      user_price: this.user_price,
      amount: amount,
      urls: this.urls,
      type: this.on_board[this.on_board_index].type
    }


    this.kpayState.dispatch(new SetPlanFromRep(plan))
    this.close.emit()
  }


  nextOnBoardOpt() {
    if (++this.on_board_index >= this.on_board.length) {
      this.on_board_index = 0
    }
  }
  prevOnBoardOpt() {
    if (--this.on_board_index < 0) {
      this.on_board_index = this.on_board.length - 1
    }
  }

  delUrl(index: number) {
    let urls = Object.assign([], this.urls)
    urls.splice(index, 1)
    if (index > this.urls.length - 1) {
      index = this.urls.length - 1
    }
    this.urls = urls

  }
  addUrl() {
    if (this.url.length > 3) {
      let urls = Object.assign([], this.urls)
      urls.push(this.url)
      this.urls = urls
      this.url = ""
    }
  }
  changedPrice($event: any) {
    if ($event < 200) {
      setTimeout(() => { this.user_price = 200 })
    }
  }
}
