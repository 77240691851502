import { Component } from '@angular/core';
import { iAmManager } from 'src/app/pages/pages.selectors';
import { PdfCanvasComponent } from '../pdf-canvas/pdf-canvas.component';
/*
This class is here to be able to have canvas and pdf-canvas the same in
the caller and rep call

*/
@Component({
  selector: 'rep-pdf-canvas',
  templateUrl: '../pdf-canvas/pdf-canvas.component.html',
  styleUrls: ['../pdf-canvas/pdf-canvas.component.scss']
})
export class RepPdfCanvasComponent extends PdfCanvasComponent {

  ngAfterViewInit() {
    this.subscriptions.push(this.pagesState.select(iAmManager).subscribe((manager: boolean) => {
      if (manager) {
        this.role = "manager"
      } else {
        this.role = "agent" //we used agent in sing-tools.component
      }
      this.loadPDF();
      this.setSinger()
    }))
  }

}
