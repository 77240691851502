import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ChapalaMLSState } from "./chapala-mls.model";

export const selectChapalaMLSState = createFeatureSelector<ChapalaMLSState>('cmls');



//******************************************************************************
//
//******************************************************************************
export const gotStates = createSelector(
  selectChapalaMLSState,
  state => state.states
);

export const gotCities = createSelector(
  selectChapalaMLSState,
  state => state.cities
);

export const gotProperties = createSelector(
  selectChapalaMLSState,
  state => state.properties
);
export const selProperty = createSelector(
  selectChapalaMLSState,
  state => state.property
);

export const showImages = createSelector(
  selectChapalaMLSState,
  state => state.bShowImgs
);

export const searchBy = createSelector(
  selectChapalaMLSState,
  state => state.search_by
);
export const searchUrl = createSelector(
  selectChapalaMLSState,
  state => state.search_url

);
export const selImg = createSelector(
  selectChapalaMLSState,
  state => state.img_index
);
export const pageNo = createSelector(
  selectChapalaMLSState,
  state => state.page_no
);
export const setImgs = createSelector(
  selectChapalaMLSState,
  state => state.images
);


export const gotMLSMsg = createSelector(
  selectChapalaMLSState,
  state => state.msg
)

export const showMap = createSelector(
  selectChapalaMLSState,
  state => state.show_map
)
