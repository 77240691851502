/*
(C)2020 Sergio A. Fernandez, Ph.D.



*/


export interface ChapalaMLSState {
  states: any[],
  cities: any[]
  properties: any,
  property: any,
  bShowImgs: boolean,
  search_by: any;
  search_url: string;
  img_index: number;
  page_no: number,
  images: any[],

  msg: any,
  show_map: boolean
}

export const initialChapalaMLSState: ChapalaMLSState = {
  states: [],
  cities: [],
  properties: {},
  property: undefined,
  bShowImgs: false,
  search_by: {},
  search_url: "",
  img_index: 0,
  page_no: 1,
  images: [],

  msg: undefined,
  show_map: true
};
