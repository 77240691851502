<div #outContainer class="out">

  <div class="error">{{error}}</div>

  <div class="container" #divContainer [style.height.px]="container_height" [style.top.px]="container_top" [style.width.px]="container_width" [style.left.px]="container_left">
    <edit-comment cdkDrag *ngIf="bEditComment" class="comment" [style.left.px]="edit_comment_x" [style.top.px]="edit_comment_y"></edit-comment>
    <show-comment *ngIf="bShowComment" class="comment" [style.left.px]="show_comment_x" [style.top.px]="show_comment_y"></show-comment>
  </div>
  <!-- <white-board-header *ngIf="!bNoHeader && bShowHeader" class="header"></white-board-header> -->
  <!-- <div *ngIf="!bNoHeader" class="dots"  kzLib (click)="bShowHeader =! bShowHeader" [ngClass]="{top0:!bShowHeader}">
    <div class=" dot">
    </div>
    <div class="dot">
    </div>
    <div class="dot">
    </div>
  </div> -->
</div>
