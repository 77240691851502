import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from '../chapala-mls-service';
import { GotMLSMsg, SelImage, SelProperty, SetImages, ShowImages } from '../chapala-mls.actions';
import { ChapalaMLSState } from '../chapala-mls.model';
import { gotMLSMsg, selProperty, showImages } from '../chapala-mls.selectors';

@Component({
  selector: 'mls-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent {
  property: any
  path: string = "";
  // url_photo: any
  features: any = {}
  features_keys: string[] = []
  subscriptions: any[] = []
  images_url: any[] = []
  bShowImages: boolean = false

  @ViewChild('images') images: ElementRef | undefined;
  @ViewChild('left') leftComponent: ElementRef | undefined;

  constructor(
    public sanitizer: DomSanitizer,
    private chapalaMLState: Store<ChapalaMLSState>,
    private chapala_mls_service: ChapalaMLSService,
  ) {
    console.log("property");
  }

  // close_debounce:number=0
  // @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  //    if (event.keyCode === 27) {
  //      let now = new Date().getTime()
  //      console.log("@CD now "+now)
  //      let dif = now - this.close_debounce
  //      console.log("@CD dif "+dif)
  //      if(dif > 3000){
  //         console.log("@CD close() ")
  //         this.close();
  //      }
  //    }
  // }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngOnInit(): void {
    this.subscriptions.push(this.chapalaMLState.select(showImages).subscribe((bShow: boolean) => {
      this.bShowImages = bShow
      // this.close_debounce = new Date().getTime()
      // console.log("@CD close_debounce "+  this.close_debounce)
    }))

    this.subscriptions.push(this.chapalaMLState.select(selProperty).subscribe((p: any) => {
      this.property = p
      if (p) {
        this.path = p.url_photo
        let url = this.path + "/" + p.photos[0].image

        let features: any = {}

        if (p.features) {
          p.features.forEach((f: any) => {
            if (!features[f.field_label]) {
              features[f.field_label] = [f.field_option]
            } else {

              features[f.field_label].push(f.field_option)
            }
          });
        }
        this.features = features
        this.features_keys = Object.keys(this.features)
        if (p.photos) {
          this.images_url = []
          p.photos.forEach((image: any) => {
            let url = this.path + "/" + image.image
            this.images_url.push(this.sanitizer.bypassSecurityTrustResourceUrl(url));
          });
          setTimeout(() => {
            if (this.leftComponent && this.images) {
              let out_width = this.leftComponent.nativeElement.clientWidth
              let images = this.images.nativeElement.children
              let widths: any[] = []
              for (let i = 0; i < images.length; i++) {
                let img = images[i]
                widths.push({ i: i, w: img.clientWidth })
              };

              // this.makeLines(out_width, widths)
              this.chapalaMLState.dispatch(new SetImages(this.images_url))
              console.log("images " + out_width + " " + JSON.stringify(widths))
            }
          }, 100)
        }
      }
    }))

    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.hasOwnProperty("show_imgs")) {
          let v = msg.show_imgs
          this.chapalaMLState.dispatch(new ShowImages(v))
          return
        }
        if (msg.sel_prop) {
          if (msg.sel_prop == -1) {
            this.chapalaMLState.dispatch(new SelProperty(undefined))
          }
          return
        }
        if (msg.hasOwnProperty("sel_img")) {
          let i = msg.sel_img
          this.chapalaMLState.dispatch(new SelImage(i))
          return
        }
      }
    }))
  }
  showMap() {
  }
  showImgs() {
    let msg: any = {
      show_imgs: true
    }
    this.chapala_mls_service.sendMsg(msg)
  }
  close() {
    let msg: any = {
      sel_prop: -1
    }
    this.chapala_mls_service.sendMsg(msg)
  }
  selImg(i: number) {
    let msg: any = {
      sel_img: i
    }
    this.chapala_mls_service.sendMsg(msg)
  }
  makeLines(width: number, images: any) {
    let left_over = images.sort(function(a: any, b: any) {
      return b.w - a.w
    })
    console.log("sorted " + JSON.stringify(left_over))
    let lines = []
    let tot_difs = 0;
    do {
      let rv = makeLine(width, left_over, -1)
      // let skip = 1
      // while (rv.line.dif > 20 && skip < left_over.length && skip < 0) {
      //   let rv1 = makeLine(width, left_over, skip)
      //   if (rv.line.dif > rv1.line.dif) {
      //     rv = rv1
      //   }
      //   skip++
      // }

      left_over = rv.left_over
      lines.push(rv.line)
      tot_difs += rv.line.dif
    } while (left_over.length > 0)
    console.log("tot_difs " + tot_difs)
    console.log("lines " + JSON.stringify(lines))
    let images_url: string[] = []
    lines.forEach((line) => {
      line.images.forEach((img) => {
        images_url.push(this.images_url[img.i])
      })
    })
    this.images_url = images_url

    function makeLine(width: number, sorted_images: any[], skip: number) {
      let curr_line_images = []
      let left_over_images = []

      let curr_width = 0
      for (let index = 0; index < sorted_images.length; index++) {
        let img = sorted_images[index]
        let img_width = img.w
        if (skip > 0) {
          if (index < 1 || index > 1 && index > skip && curr_width + img_width <= width) {
            curr_line_images.push(img)
            curr_width += img_width
          } else {
            left_over_images.push(img)
          }
        } else {
          if (curr_width + img_width <= width) {
            curr_line_images.push(img)
            curr_width += img_width
          } else {
            left_over_images.push(img)
          }
        }
      }
      let line = {
        images: curr_line_images,
        width: curr_width,
        dif: (width - curr_width)
      }
      console.log("")
      console.log("")
      console.log("curr_line " + JSON.stringify(line))
      console.log("left_over_images " + JSON.stringify(left_over_images))
      return {
        line: line,
        left_over: left_over_images
      }
    }
  }
}
