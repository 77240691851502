import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from '../../chapala-mls-service';
import { SetSearch } from '../../chapala-mls.actions';
import { ChapalaMLSState } from '../../chapala-mls.model';
import { gotMLSMsg, gotStates } from '../../chapala-mls.selectors';
import { SelectorComponent } from './selector.component';

@Component({
  selector: 'sel-state',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class StateComponent extends SelectorComponent {

  states: any;

  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);
    console.log("state")

    try {
      this.chapala_mls_service.getStates()
    } catch (e) {
      console.error(e)
    }

    this.setTitle("State")

    this.subscriptions.push(this.chapalaMLState.select(gotStates).subscribe((states: any[]) => {
      this.states = states
      this.setOptions(states, "state_name")
    }))
    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.select) {
          if (msg.select.hasOwnProperty("State")) {
            let index = msg.select["State"]
            let state = this.states[index]
            this.chapalaMLState.dispatch(new SetSearch("state", state))
          }
          return
        }

      }
    }))
    // console.log("State constructor bShowOptions " + this.bShowOptions)
  }
  all() {
    let msg: any = {
      show_all: true
    }
    this.chapala_mls_service.sendMsg(msg)
  }
  selOption(i: number) {
    super.selOption(i)
  }
}
