import { AddToUndo, ClearSel } from '../canvas.actions';
import { Draw } from './draw';
import { SelectId } from '../canvas.actions';
import { SVG } from '@svgdotjs/svg.js';

export class kText extends Draw {
  moving_span: any
  span: any //editing
  bSelected: boolean = false

  drawing_rect: any | undefined  //used to create and move lines
  drawing_text: any | undefined
  rectangle_id: string = ""
  move: string = "no"
  poly_array: number[] = []
  text_field: any

  span_removed_at: number = 0;
  pasteSVG(svg: any, x: number, y: number) {
    let drawing_poly = this.textFromSVG(svg)

    if (drawing_poly) {
      let id = drawing_poly.node.dataset['id']
      if (this.findGroupById(id)) { //change the id
        id = new Date().getTime().toString()
        drawing_poly.node.dataset['id'] = id
        drawing_poly.node.dataset['k_type'] = "text"
      }
      this.moveDelta(id, 20, 20)
      let box: any = this.getBox(id)
      return { id: id, box: box, k_type: "text" }
    }
    return undefined
  }

  undo(msg: any) {
    let old = this.findGroupById(msg.id)
    if (old) {
      old.remove()
    }
    if (msg.data) {
      let new_obj = SVG(msg.data)
      if (msg.canvas_width) {
        let vb = {
          width: msg.canvas_width,
          height: msg.canvas_height
        }
        this.textFromSVG(new_obj, vb)
      } else {
        this.textFromSVG(new_obj)
      }
    }
  }

  processMsg(msg: any) {
    if (msg.action == "size") {
      this.processFontSize(msg.id, msg.data)
      return
    }
    this.undo(msg)

  }
  newTextFromMsg(msg: any) {
    let svg = SVG(msg.data)
    this.textFromSVG(svg)
  }
  moveTextFromMsg(msg: any) {
    let el = this.findGroupById(msg.id)
    if (el) {
      el.move(msg.data.left + 4, msg.data.top + 4)
    }
  }
  eraseTextFromMsg(msg: any) {
    let el = this.findGroupById(msg.id)
    if (el) {
      el.remove()
    }
  }
  setTextFromMsg(msg: any) {
    let el = this.findGroupById(msg.id)
    el.remove()
    let svg = SVG(msg.data)
    this.textFromSVG(svg)
  }
  textFromSVG(in_group: any, fromViewBox?: any) {
    let id = in_group.node.dataset['id']

    let w = in_group.node.dataset['w']
    let h = in_group.node.dataset['h']
    let left = in_group.node.dataset['left']
    let top = in_group.node.dataset['top']

    let text_font = in_group.node.dataset['text_font']
    let text_weight = in_group.node.dataset['text_weight']
    let text_size = in_group.node.dataset['text_size']
    let text_color = in_group.node.dataset['text_color']

    if (fromViewBox) {
      w = this.parent_canvas.scaleXToWidth(w, fromViewBox.width)
      left = this.parent_canvas.scaleXToWidth(left, fromViewBox.width)
      h = this.parent_canvas.scaleYtoHeight(h, fromViewBox.height)
      top = this.parent_canvas.scaleYtoHeight(top, fromViewBox.height)

    }

    let lines = in_group.children()
    let group = this.draw_tool.group()
    group.node.dataset['id'] = id
    group.node.dataset['k_type'] = 'text'

    group.node.dataset['text_font'] = text_font
    group.node.dataset['text_weight'] = text_weight
    group.node.dataset['text_size'] = text_size
    group.node.dataset['text_color'] = text_color

    group.node.dataset['w'] = w
    group.node.dataset['h'] = h
    group.node.dataset['left'] = left
    group.node.dataset['top'] = top


    if (lines.length > 0) {
      let line = lines[0]
      let text_attr = Object.assign({}, line.attr())
      text_attr.y = top
      text_attr.x = left
      text_attr.width = w
      text_attr.height = h
      let ts = parseFloat(text_size)
      let sts = this.parent_canvas.scaleXToWidth(ts, 612)
      let scaled_text_size = sts + "px"
      text_attr.style = "font-family:" + text_font + "; font-size:" + scaled_text_size + "; font-weight:" + text_weight + ";color:" + text_color
      let foreignObject = this.draw_tool.foreignObject(w, h)
      foreignObject.attr(text_attr)
      foreignObject.node.style.color = text_color

      let html = line.node.innerHTML
      foreignObject.add(SVG(html))

      group.add(foreignObject)
      // group.move(left, top)
    }
    return group
  }

  copy(selected_id: string) {
    if (this.span) {
      let span_id = this.span.dataset['id']
      if (span_id == selected_id) {
        let svg = this.getSpanSVG()
        return svg
      }
    } else { //its a multi copy, use the objects
      let group = this.findGroupById(selected_id)
      return group.svg()
    }

    return undefined
  }
  erase(id: string) {
    if (this.bSelected) {
      return
    }
    if (this.span) {
      let span_id = this.span.dataset['id']
      if (span_id == id) {
        this.parent_canvas.unselect(id)
        this.span.onblur = undefined
        this.span.onpointerdown = undefined
        this.span.onfocus = undefined
        this.canvas.removeChild(this.span)
        this.bSelected = false
        delete this.span
      }
    } else { //its a multi copy, use the objects
      let group = this.findGroupById(id)
      group.remove()
    }

    this.canvas_service.sendCanvasMsg("text", "erase", id)
  }

  //****************************************************************************
  // Parent Events
  //****************************************************************************
  onMouseDown($event: any) { //Cal
    let ts = parseFloat(this.text_size)    //The text szie is 18 for a pdf of 612
    let font_size = this.parent_canvas.scaleXToWidth(ts, 612) + "px"
    this.newText("new", "", $event.offsetX, $event.offsetY, 0, 0, this.text_font, font_size, this.text_weight, this.text_color)
  }
  select(id: string) {
    let now = new Date().getTime()
    let dif = now - this.span_removed_at
    if (dif > 300) {
      this.onTextDown(id)
    }
  }
  setFontSize(id: string, size: string) { //called from canvas
    this.text_size = size
    this.processFontSize(id, size)
    this.canvas_service.sendCanvasMsg("text", "size", id, size)

  }
  processFontSize(id: string, size: string) {

    let ts = parseFloat(size)    //The text szie is 18 for a pdf of 612
    let fs = this.parent_canvas.scaleXToWidth(ts, 612)
    let font_size = fs + "px"
    let group = this.findGroupById(id)

    group.node.dataset['text_size'] = size + "px"
    let lines = group.children()
    let h2 = 0
    let w2 = 0
    let draw = SVG()
    if (lines.length > 0) {
      let el = lines[0]
      el.node.style.fontSize = font_size;
      let tx = el.node.innerHTML
      let tx1 = tx.substring(5, tx.length - 5)
      let text = draw.text(tx1).font({ size: fs });
      let tbox = text.bbox()
      h2 += tbox.h
      if (tbox.w > w2) {
        w2 = tbox.w
      }
    }

    let bbox = group.bbox()
    let x2 = bbox.x + w2
    let y2 = bbox.y + h2
    this.moveObj(id, bbox.x, bbox.y, x2, y2) //onAnchorMove
    this.parent_canvas.removeAnchors()
    let keys = Object.keys(this.parent_canvas.multi_move_divs)
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      let back = this.parent_canvas.multi_move_divs[key]
      //  back.style.border = "1px solid red"
      back.style.height = h2 + "px"
      back.style.width = w2 + "px"
    }


  }

  newText(id: string, text: string, left: number, top: number, width: number, height: number, text_font: string, font_size: string, text_weight: string, text_color: string) { //Cal
    //This creates a text element in the div, no resizing needit until we fade
    if (!this.bSelected) {
      this.bSelected = true;
      console.log("#TXT new span for " + id)
      this.span = document.createElement('span')
      this.span.setAttribute('contenteditable', 'true')
      this.span.style.position = "absolute"
      this.span.style.left = left + "px"

      top -= 10; //To compoensate for the mouse icon, oct 17, 23

      this.span.style.top = top + "px"
      this.span.style.minHeight = "10px"

      this.span.style.padding = "4px"
      this.span.style.outline = "1px solid #FFBA55"

      this.span.style.minWidth = "50px"
      let mw = this.canvas.clientWidth - left - 20
      this.span.style.maxWidth = mw + "px"
      this.span.style.borderRadius = "4px"
      this.span.innerHTML = text
      this.canvasStore.dispatch(new ClearSel())

      this.span.onblur = onSpanBlur
      this.span.onpointerdown = onSpanDown
      this.span.onfocus = onSpanFocus


      this.span.dataset['id'] = id
      this.span.dataset['k_type'] = "text"

      this.span.style.fontFamily = text_font


      this.span.style.fontSize = font_size




      this.span.style.lineHeight = font_size
      this.span.style.fontWeight = text_weight
      this.span.style.color = text_color
      if (id != "new") {
        this.span.style.width = width + "px"
        // this.span.style.height = height + "px"

      }
      this.canvas.appendChild(this.span)
      let me = this



      function onSpanDown(this: any, $event: any) {
        $event.stopPropagation()
        if ($event.shiftKey) {
          // console.log("#TXT onSpanDown 2 " + new Date().getTime())

          me.onSpanBlur(this)
          let box = me.getBox(id)
          me.parent_canvas.processMultiSel(id, box, "text")
          return
        }
        me.mouse_down_at = 1
        me.canvas.onpointerup = onSpanUp
        setTimeout(() => {

          if (me.mouse_down_at) {
            if (me.sel_tool_name == "none") {
              me.x1 = parseFloat(this.style.left)
              me.y1 = parseFloat(this.style.top)

              me.startX = -1
              me.startY = -1
              me.pointer_id = $event.pointerId
              me.canvas.setPointerCapture(me.pointer_id)
              me.canvas.onpointermove = onMoveSpan

              me.moving_span = this
              me.moving_span.style.cursor = 'grab'
              console.log("#sp dw")
            }
          }
        }, 500)
      }
      function onMoveSpan($event: any) {
        $event.stopPropagation()
        if (me.moving_span) {

          if (me.startX < 0) {
            me.startX = $event.offsetX
            me.startY = $event.offsetY
            me.move_undo_params = {
              undo: "move",
              k_type: "text",
              id: me.moving_span.dataset['id'],
              data: { left: me.x1, top: me.y1 }
            }

            // console.log("#sp mv + undo move")
          }
          let delta_x = $event.offsetX - me.startX
          let delta_y = $event.offsetY - me.startY
          let x = me.x1 + delta_x
          let y = me.y1 + delta_y
          me.moving_span.style.left = x + "px"
          me.moving_span.style.top = y + "px"
        }
      }

      function onSpanUp(this: any, $event: any) {
        me.mouse_down_at = 0

        console.log("#sp up")
        if (me.moving_span) {
          let left = parseFloat(me.moving_span.style.left)
          let mw = me.canvas.clientWidth - left - 20
          me.moving_span.style.maxWidth = mw + "px"
          me.moving_span.style.cursor = "text"

          let id = me.moving_span.dataset['id']
          let top = parseFloat(me.moving_span.style.top)
          me.canvas_service.sendCanvasMsg("text", "move", id, { left: left, top: top })
        }
        me.moving_span = undefined
        me.canvas.onpointermove = undefined
        me.canvas.onpointerup = undefined
        if (me.pointer_id) {
          this.releasePointerCapture(me.pointer_id)
          me.pointer_id = undefined
        }
      }

      function onSpanFocus(this: any, $event: any) {
        // this.style.background="lightblue"
        me.span.style.outline = "1px solid #FFBA55"

      }
      function onSpanBlur(this: any, $event: any) {
        // console.log("#TXT onSpanBlur 1 " + new Date().getTime())

        me.onSpanBlur(this)
        return
      }

      // console.log("#tt new rectangle " + this.span.dataset['id'] + " " + this.span.style.left + " " + this.span.style.top)
      setTimeout(() => {
        this.span.focus()
        this.parent_canvas.selectObj(id, "text")
      }, 100)
    }

  }

  onSpanBlur(obj: any) { //obj is a div

    this.bSelected = false
    console.log("#TXT onSpanBlur " + new Date().getTime())
    // this.style.background="transparent"
    this.span.style.outline = "none"
    let left = parseFloat(obj.style.left) + 4 //from the pading
    let top = parseFloat(obj.style.top) + 2
    // let fs = parseFloat(obj.style.fontSize)
    var style = window.getComputedStyle(obj);
    let h = parseFloat(style.height) //Go up
    let w = parseFloat(style.width)

    left = this.parent_canvas.deScaleX(left)
    w = this.parent_canvas.deScaleX(w)

    top = this.parent_canvas.deScaleY(top) //We might have to descale from the bottom
    h = this.parent_canvas.deScaleY(h)


    // var lineHeight = style.getPropertyValue('line-height');

    let tx = obj.outerText
    let id = obj.dataset['id']
    let bIsNew: boolean = false //to auto select the new one
    if (id == "new") { //its a new one
      id = new Date().getTime()
      bIsNew = true
    }
    if (tx.length > 0) {

      // let lines = tx.split('\n')
      // let num_lines = lines.length
      let h1 = h + 8
      let group = this.draw_tool.group()
      group.node.dataset['left'] = left
      group.node.dataset['top'] = top //on blur

      group.node.dataset['h'] = h1
      group.node.dataset['w'] = w
      group.node.dataset['id'] = id
      group.node.dataset['k_type'] = 'text'

      group.node.dataset['text_font'] = this.text_font
      group.node.dataset['text_weight'] = this.text_weight
      group.node.dataset['text_size'] = this.text_size
      group.node.dataset['text_color'] = this.text_color

      let foreignObject = this.draw_tool.foreignObject(w, h1)

      foreignObject.node.dataset['id'] = id
      foreignObject.node.dataset['k_type'] = 'text'
      foreignObject.node.style.fontFamily = this.text_font
      foreignObject.node.style.fontWeight = this.text_weight

      let ts = parseFloat(this.text_size)    //The text szie is 18 for a pdf of 612
      let font_size = this.parent_canvas.scaleXToWidth(ts, 612) + "px"
      foreignObject.node.style.fontSize = font_size

      foreignObject.node.style.color = this.text_color

      foreignObject.add(SVG('<div>' + tx + '</div>'))
      group.add(foreignObject)
      group.move(left, top)


      //Redo this next part august 28 23
      if (obj.dataset['id'] == "new") {
        this.canvasStore.dispatch(new AddToUndo({
          undo: "new",
          k_type: "text",
          id: id,
        }))
        this.canvas_service.sendCanvasMsg("text", "new", id, group.svg())
      } else {
        this.canvas_service.sendCanvasMsg("box", "remove", "")
        this.canvas_service.sendCanvasMsg("text", "text", id, group.svg())
      }
    }

    if (this.span) {
      console.log("#TXT before removed span for " + id)
      this.span.onblur = undefined
      this.span.onpointerdown = undefined
      this.span.onfocus = undefined


      this.canvas.removeChild(this.span)
      console.log("#TXT removed span for " + id)
      this.span_removed_at = new Date().getTime()
      delete this.span
      if (bIsNew) {

        let box: any = this.getBox(id)
        this.parent_canvas.processMultiSel(id, box, "text")

      } else {
        this.parent_canvas.unselect(id)
      }
    }
  }

  getSpanSVG() {
    let left = parseFloat(this.span.style.left) + 4 //from the pading
    let top = parseFloat(this.span.style.top) + 4
    let fs = parseFloat(this.span.style.fontSize)
    var style = window.getComputedStyle(this.span);
    let h = style.height
    let w = style.width
    let tx = this.span.outerText
    let id = this.span.dataset['id']

    if (tx.length > 0) {
      let lines = tx.split('\n')
      let draw = SVG()
      let group = draw.group()
      group.node.dataset['left'] = left.toString()
      group.node.dataset['top'] = top.toString()

      group.node.dataset['h'] = h
      group.node.dataset['w'] = w
      group.node.dataset['id'] = id
      group.node.dataset['k_type'] = 'text'

      for (let i = 0; i < lines.length; i++) {
        let line = lines[i]
        let drawing_text = draw.text(line).font({
          family: this.span.style.fontFamily
          , size: this.span.style.fontSize
          , weigth: this.span.style.fontWeight
          , anchor: 'start'
          , leading: '0em'
        }).fill(this.span.style.color).move(left, top)
        top += fs
        drawing_text.node.dataset['id'] = id
        group.add(drawing_text)
      }
      return group.svg()
    }
    return undefined
  }


  onTextDown(id: string) {

    if (!this.span && (!this.sel_tool_name || this.sel_tool_name == 'none')) {
      // let span = $event.srcElement.instance.node.parentElement
      console.log("#tx on pointer down " + id)
      if (id == "x") {
        console.log("#tx on pointer down " + id)

      }
      let group = this.findGroupById(id)
      if (group) {
        if (group.node) {
          if (group.node.dataset) {
            this.text_size = group.node.dataset['text_size']
            this.text_font = group.node.dataset['text_font']
            this.text_color = group.node.dataset['text_color']
            this.text_weight = group.node.dataset['text_weight']
          }
        }


        let children = group.children()
        let attr
        let text = ""
        if (children.length > 0) {
          let line = children[0]
          if (!attr) {
            attr = line.attr()
          }
          let tx = line.node.innerHTML

          if (tx) {
            if (tx.length > 0) {
              // text += "<div>" + tx + "</div>"
              text += tx
            }
          } else {
            console.error("No tx ")
          }


          let font_family = line.node.style.fontFamily
          let font_weight = line.node.style.fontWeight
          let font_size = line.node.style.fontSize

          let box1 = group.bbox()
          let y = attr.y + 8
          let x = attr.x - 4
          let bbox = this.parent_canvas.scaleBox(box1)
          x = this.parent_canvas.scaleY(x)
          y = this.parent_canvas.scaleY(y)

          this.newText(id, text, x, y, bbox.width, bbox.height, font_family, font_size, font_weight, this.text_color)

          let svg = group.svg()
          this.canvasStore.dispatch(new AddToUndo({
            undo: "text",
            k_type: "text",
            id: id,
            data: svg
          }))

          group.remove()
        }
      }
    }
  }
  getBox(selected_id: string) { //subclassed for mullti select
    let group = this.findGroupById(selected_id)
    if (group) {
      let bbox = group.bbox()
      let rv =
      {
        x1: bbox.x,
        y1: bbox.y,
        x2: bbox.x2,
        y2: bbox.y2,
        back: true,
        new_point: false, //not a polygon
        svg: group.svg() //for the undo

      }
      return rv
    }
    return undefined
  }
  moveDelta(selected_id: string, delta_x: number, delta_y: number) {//subclassed for mullti select
    let group = this.findGroupById(selected_id)
    let bbox = group.bbox()

    let left = bbox.x + delta_x
    let top = bbox.y + delta_y
    group.move(left, top)
    group.node.dataset['left'] = left
    group.node.dataset['top'] = top

  }

  setColor(id: string, color: string) { //subclased
    console.log("set text color " + id + " " + color)
    let group = this.findGroupById(id)
    if (group) {
      let lines = group.children()
      for (let i = 0; i < lines.length; i++) {
        let line = lines[i]
        if (line.type == "foreignObject") {
          line.node.style.color = color
        } else if (line == "text") {
          line.fill(color)
        }
      }
    }
  }
  setFont(id: string, font: string) {
    let group = this.findGroupById(id)
    let lines = group.children()
    if (lines.length > 0) {
      let el = lines[0]
      el.node.style.fontFamily = font;
    }
  }
  setFontWeight(id: string, weight: string) {
    let group = this.findGroupById(id)
    let lines = group.children()
    if (lines.length > 0) {
      let el = lines[0]
      el.node.style.fontWeight = weight;
    }
  }

  moveObj(selected_id: string, x1: number, y1: number, x2: number, y2: number) {

    let group = this.findGroupById(selected_id)

    let children = group.children()
    if (children.length > 0) {
      let text = children[0]
      // let html = text.node.innerHTML
      // let bbox = group.bbox()
      if (x1 > x2) {
        let t = x1
        x1 = x2
        x2 = t
      }
      if (y1 > y2) {
        let t = y1
        y1 = y2
        y2 = t
      }

      let w = x2 - x1
      let h = y2 - y1
      let left = x1;
      let top = y1;
      // console.log("#el1 " + left + " " + top + " " + w + " " + h)

      left = this.parent_canvas.deScaleX(left)
      w = this.parent_canvas.deScaleX(w)

      top = this.parent_canvas.deScaleY(top) //We might have to descale from the bottom
      h = this.parent_canvas.deScaleY(h)
      text.size(w, h)

    }
  }
}
