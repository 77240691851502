<div class="out">
  <div class="header">
    <div class="h_p font_sub_title">
      <div class="ctr">
        <div class="ctr_tx">
          <span *ngIf="!converge_acc"> Account Set Up</span>
          <span *ngIf="converge_acc"> Payments</span>
          <div class="selected">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="body">
    <kpay-set-up *ngIf="!converge_acc" (open)="bShowForm=true"></kpay-set-up>
    <payments *ngIf="converge_acc"></payments>
  </div>
</div>
