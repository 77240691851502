import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { editComment, showComment } from '../../canvas.selectors';

@Component({
  selector: 'show-comment',
  templateUrl: './show-comment.component.html',
  styleUrls: ['./show-comment.component.scss']
})
export class ShowCommentComponent {
  content: string = ""
  subscriptions: any[] = []

  constructor(
    public canvasStore: Store<CanvasState>
  ) {
    this.subscriptions.push(this.canvasStore.select(showComment).subscribe((action: any) => {
      if (action) {
        this.content = action.content
        // console.log("Show comment <--" + JSON.stringify(action))
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }

}
