import { SVG } from '@svgdotjs/svg.js';
import { AddToUndo } from '../canvas.actions';
import { Draw } from './draw';

export class kRectangle extends Draw {
  rectangles: any = {}

  //****************************************************************************
  // Parent events
  //****************************************************************************
  //called when the tool is ellipse
  newObj(left: number, top: number, right: number, bottom: number) {
    left = this.parent_canvas.deScaleX(left)
    top = this.parent_canvas.deScaleY(top)

    right = this.parent_canvas.deScaleX(right)
    bottom = this.parent_canvas.deScaleY(bottom)


    let arr = [
      left, top,
      left, bottom,
      right, bottom,
      right, top] //right bottom 6,7
    let drawing_rect = this.draw_tool.polygon(arr).fill(this.fill_color).stroke({ width: 1 })

    let rectangle_id = new Date().getTime().toString()
    drawing_rect.node.dataset['id'] = rectangle_id
    drawing_rect.node.dataset['k_type'] = "rectangle"
    this.rectangles[rectangle_id] = drawing_rect

    let svg = drawing_rect.svg()
    this.canvas_service.sendCanvasMsg("rectangle", "new", rectangle_id, svg)
    // this.setupRectangle(drawing_rect)
    this.canvasStore.dispatch(new AddToUndo({
      undo: "new",
      k_type: "rectangle",
      id: rectangle_id
    }))
    let box: any = this.getBox(rectangle_id)
    return { id: rectangle_id, box: box, k_type: "rectangle" }
  }
  setMinSize(id: string, fromViewBox?: any) {
    let drawing_rect = this.rectangles[id]
    let attr = drawing_rect.attr()
    let points = this.getArrayFromStr(attr.points)
    let dx = points[0] - points[2]
    let dy = points[1] - points[3]
    let d = Math.sqrt(dx * dx + dy * dy)
    if (d < 20) {
      let dx = 100
      let dy = 100
      if (fromViewBox) {
        dx = this.parent_canvas.scaleXToWidth(dx, fromViewBox.width)
        dy = this.parent_canvas.scaleYtoHeight(dy, fromViewBox.height)
      }
      let left = points[0]
      let right = left + dx
      let top = points[1]
      let bottom = top + dy
      let arr = [
        left, top,
        left, bottom,
        right, bottom,
        right, top]
      drawing_rect.attr({ points: arr })
    }

  }

  onMouseDown($event: any) { //Cal
    return this.newObj($event.offsetX, $event.offsetY, $event.offsetX + 100, $event.offsetY + 100)
  }


  undo(msg: any) {
    let old = this.rectangles[msg.id]
    if (old) {
      old.remove()
      delete this.rectangles[msg.id]
    }
    if (msg.data) {
      let new_obj = SVG(msg.data)
      let drawing_rect
      if (msg.canvas_width) {
        let vb = {
          width: msg.canvas_width,
          height: msg.canvas_height
        }
        drawing_rect = this.rectangleFromSVG(new_obj, vb)
      } else {
        drawing_rect = this.rectangleFromSVG(new_obj)
      }

      let id = drawing_rect.node.dataset['id']
      this.rectangles[id] = drawing_rect
    }
  }


  pasteSVG(svg: any, x: number, y: number) {
    let drawing_rect = this.rectangleFromSVG(svg)
    if (drawing_rect) {
      let id = drawing_rect.node.dataset['id']

      if (this.rectangles[id]) { //change the id
        id = new Date().getTime().toString()
        drawing_rect.node.dataset['id'] = id
      }
      this.rectangles[id] = drawing_rect
      this.moveDelta(id, 20, 20)
      let box: any = this.getBox(id)
      return { id: id, box: box, k_type: "rectangle" }
    }
    return undefined
  }
  rectangleFromSVG(rectangle: any, fromViewBox?: any) {

    let attr = rectangle.attr()
    let drawing_rect
    if (fromViewBox) {
      let points = this.getArrayFromStr(attr.points)
      let np = this.parent_canvas.scalePointsToViewBox(points, fromViewBox)
      let nattr = Object.assign({}, attr)
      nattr.points = np
      drawing_rect = this.draw_tool.polygon(nattr)
    } else {
      drawing_rect = this.draw_tool.polygon(attr)
    }

    // let drawing_rect = this.draw_tool.polygon(attr)
    //    let drawing_rect = this.draw_tool.polygon(attr.points).fill(attr.fill).stroke({ width: attr['stroke-width'] })

    // this.setupRectangle(drawing_rect)
    return drawing_rect
  }


  //****************************************************************************
  // Utilities

  copy(selected_id: string) {
    let obj = this.rectangles[selected_id]
    if (obj) {
      return obj.svg()
    }
    return undefined
  }
  erase(id: string) {
    let rv
    let obj = this.rectangles[id]
    if (obj) {
      rv = {
        undo: "erase",
        k_type: "rectangle",
        id: id,
        data: obj.svg()
      }
    }
    obj.remove()
    this.canvas_service.sendCanvasMsg("rectangle", "erase", id)
    return rv
  }

  moveObj(selected_id: string, x1: number, y1: number, x2: number, y2: number) {
    let drawing_rect = this.rectangles[selected_id]
    console.log("#el1 " + x1 + " " + x2)


    if (x1 > x2) {
      let t = x1
      x1 = x2
      x2 = t
    }
    if (y1 > y2) {
      let t = y1
      y1 = y2
      y2 = t
    }

    let w = x2 - x1
    let h = y2 - y1
    let left = x1;
    let top = y1;
    // console.log("#el1 " + left + " " + top + " " + w + " " + h)

    left = this.parent_canvas.deScaleX(left)
    w = this.parent_canvas.deScaleX(w)

    top = this.parent_canvas.deScaleY(top) //We might have to descale from the bottom
    h = this.parent_canvas.deScaleY(h)
    console.log("#el2 " + left + " " + top + " " + w + " " + h)

    let points = [
      left, top, // left top
      left, top + h, // left bottom
      left + w, top + h, //right bottom
      left + w, top] //right top


    // let a=this.drawing_rect.attr()
    // let points = [
    //   x1, y1, // left top 0,1
    //   x1, y2, // left top 2,3
    //   x2, y2, //right top 4,5
    //   x2, y1] //right bottom 6,7


    drawing_rect.attr({ points: points })
    let svg = drawing_rect.svg()
    this.canvas_service.sendCanvasMsg("rectangle", "move", selected_id, svg)

  }
  getBox(selected_id: string) { //subclassed for mullti select
    if (this.rectangles) {
      let drawing_rect = this.rectangles[selected_id]
      if (drawing_rect) {

        let attr = drawing_rect.attr()//this.drawing_ellipse.attr({ cx: cx, cy: cy, rx: rx, ry: ry })
        let points = this.getArrayFromStr(attr.points)

        let rv =
        {
          x1: points[0],
          y1: points[1],
          x2: points[4],
          y2: points[5],
          back: true, //make back div
          new_point: false, //new_point for polygon
          svg: drawing_rect.svg() //for the undo
        }
        console.log("rect got box")
        return rv
      }
    }
    return undefined
  }
  moveDelta(selected_id: string, delta_x: number, delta_y: number) {//subclassed for mullti select
    if (this.rectangles) {
      let drawing_rect = this.rectangles[selected_id]
      if (drawing_rect) {
        this.movePolyPoints(drawing_rect, delta_x, delta_y)
      }
    }
  }
  setColor(id: string, color: string) { //subclased
    if (this.rectangles) {
      let drawing_rect = this.rectangles[id]
      if (drawing_rect) {
        drawing_rect.fill(color)
      }
    }
  }
}
