import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { gotAssets } from 'src/app/pages/pages.selectors';
import { ChatService } from '../chat.service';
import { ChatComponent } from '../chat/chat.component';

@Component({
  selector: 'view-chat',
  templateUrl: './view-chat.component.html',
  styleUrls: ['../chat/chat.component.scss', './view-chat.component.scss']
})
export class ViewChatComponent extends ChatComponent {
  // messages: any[] = []
  date: string = ""
  call: any = {}
  chat: any = {}
  // subscriptions: any[] = []

  async  getChat() {
    try {
      let rv: any = await this.chat_serivce.viewCallChat()
      this.chat = rv.chat
      let chat_msgs = this.chat.messages
      if (rv.call) {
        this.call = rv.call
        this.date = rv.call.start
        this.caller_name = rv.call.caller_name
        this.rep_name = rv.call.rep_name
      }
      chat_msgs.sort((a: any, b: any) => { return a.time - b.time })
      this.messages = chat_msgs

      this.scrollToBottom()
    } catch (e) {
      console.error(e)
    }
  }
  ngAfterViewInit() {
    this.getChat()

  }

  isCaller(msg: any) {
    return msg.id == this.call.caller_id
  }
  closeIt() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
}
