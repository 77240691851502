import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'asset-or-folder',
  templateUrl: './asset-or-folder.component.html',
  styleUrls: ['./asset-or-folder.component.scss']
})

export class AssetOrFolderComponent {
  @Output() addAsset: EventEmitter<void> = new EventEmitter();
  @Output() addFolder: EventEmitter<void> = new EventEmitter();

}
