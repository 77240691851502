import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'k-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss']
})
export class SelectBoxComponent {

  bShowOptions: boolean = false;
  bShowRight: boolean = false;

  _options: any[] = []
  sel_opt: number = -1
  @Input() set options(options: any[]) {
    this._options = options
  }


  @Input() border: boolean = true
  @Input() selected: number = 0
  @Output() selectedChanged: EventEmitter<number> = new EventEmitter();

  bMouseIn: boolean = false
  constructor() {
    console.log("select")
  }


  showOptions() {
    this.bShowOptions = !this.bShowOptions
  }
  leave() {
    this.bMouseIn = false
    setTimeout(() => {
      this.bShowOptions = this.bMouseIn
    }, 300)

  }
  enter() {
    this.bMouseIn = true
  }

  selOption(i: number) {

    this.sel_opt = i;
    this.selectedChanged.emit(this.sel_opt)

    // this.selected.emit(i)
  }
}
