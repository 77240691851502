import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getAssetFromURL } from 'src/app/utils';

@Component({
  selector: 'chat-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss']
})
export class LineComponent {
  @Input() mine: boolean = true
  segments: any[] = []
  has_asset: any
  @Input() set text(tx: string) {
    console.log("chat line " + tx)
    let parts = tx.split(" ")
    for (let i = 0; i < parts.length; i++) {
      let text = parts[i]
      let segment: any = { tx: text }
      if (text.startsWith("https:") || text.startsWith("data:image")) {
        this.has_asset = getAssetFromURL(text)
        segment.url = true;
      }
      this.segments.push(segment)
    }
  }
  @Output() asset: EventEmitter<any> = new EventEmitter();
  navigate(segment: any) {
    if (this.has_asset && this.mine) {
      this.asset.emit(this.has_asset)
      return
    }
    if (segment.url) {
      window.open(segment.tx)
    }
  }

  show_asset($event: any, asset: any) {
    console.log("clicked")
    if (asset && this.mine) {
      let show_asset = Object.assign({}, asset)
      show_asset.top = $event.clientY
      this.asset.emit(show_asset)
    }
  }
}
