import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { KeyziiService } from 'src/app/wss/keyzii.service';

@Component({
  selector: 'del-user',
  templateUrl: './del-user.component.html',
  styleUrls: ['./del-user.component.scss']
})

export class DelUserComponent {
  @Input() del_user: any
  constructor(
    private keyzii_service: KeyziiService,
    public canvasStore: Store<CanvasState>,
  ) {

  }
  deleteUser() {
    if (this.del_user) {
      this.keyzii_service.deleteUser(this.del_user)
      this.closeIt()

    }
  }
  closeIt() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
}
