import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from "@ngrx/store";
import { PagesState } from 'src/app/pages/pages.model';
import { customizeAccount } from 'src/app/pages/pages.selectors';
import { ControlsState } from '../controls.model';

@Component({
  selector: 'k-icon',
  templateUrl: './k-icons.component.html',
  styleUrls: ['./k-icons.component.scss']
})
export class KIconsComponent implements OnInit {
  @ViewChild('kbutton') kbutton!: ElementRef;

  _background: string = "#ececec00" //what gets displayed
  _user_back: string | undefined //from the user
  _set_back: string | undefined = "#ececec00"
  @Input() disabled: boolean = false
  @Input() set background(c: string) {
    this._set_back = c
    this._background = c
  }
  _internal: boolean = false
  @Input() set internal(value: string) {
    this._internal = (value == "true");
  }

  _user_color: string | undefined
  _set_color: string | undefined
  _color: string = "black"
  @Input() set color(c: string) {
    this._set_color = c
    this._color = this._set_color
  }

  _border: string = "unset"
  @Input() set border(s: string) {
    this._border = "1px solid " + s;
  }

  @Input() text: string | undefined
  _state: string = "on"


  @Input() min_width: string = "40"
  @Input() min_height: string = "40"

  src_icon: string | undefined //the one displayed
  src_icon_on: string | undefined
  @Input() set icon(s: string) {
    this.src_icon = "assets/images/" + s
    this.src_icon_on = "assets/images/" + s
  }

  @Input() fontSize: string = "16px"
  @Input() fontWeight: string = "400"

  bIconText: boolean = false;
  subscriptions: any[] = []

  constructor(
    private pagesState: Store<PagesState>,
    public sanitizer: DomSanitizer,
  ) {
    // console.log("k-button")
  }

  ngOnInit(): void {
    this.subscriptions.push(this.pagesState.select(customizeAccount).subscribe((customize: any) => {
      if (customize) {
        if (customize.btn_back) {
          this._user_back = customize.btn_back
        }
        if (customize.btn_color) {
          this._user_color = customize.btn_color
        }
      }
    }))
    // this.subscriptions.push(this.controlsState.select(getBtnBackColor).subscribe((color: string) => {
    //   this._user_back = color
    // }))
    // this.subscriptions.push(this.controlsState.select(getBtnTextColor).subscribe((color: string) => {
    //   this._user_color = color
    // }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.setStateColors()
  }
  setStateColors() {
    setTimeout(() => {
      if (this.src_icon_on && this.text) {
        this.bIconText = true
      }

      if (this.src_icon_on) {
        this.src_icon = this.src_icon_on
      }
      if (this._set_color) {
        this._color = this._set_color
      } else if (this._user_color) {
        this._color = this._user_color
      }
      if (this._set_back) {
        this._background = this._set_back
      } else if (this._user_back) {
        this._background = this._user_back
      }

    })
  }

  mouseEnter() {
    // this._background = "#eee"
  }
  mouseLeave() {
    this.setStateColors()
  }

}
