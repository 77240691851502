<div class="cont">

  <div class="title">
    Identification
  </div>
  <div *ngIf="!bTakePic" class="take_pic out">

    <div *ngIf="!bTakePic" class="options" ng2FileDrop [uploader]="uploader" (onFileDrop)="dropped($event)">
      <img class="upload_img" src="assets/images/upload_doc.svg" alt="Upload" kzLib (click)="file.click()">
      <div class="upload_txt" kzLib (click)="file.click()">
        Click to upload ID document
      </div>

      <div class="abs_center or">
        or
      </div>
      <img class="take_img" src="assets/images/take_pic.svg" alt="Upload" kzLib (click)="takingPic()">
      <div class="take_txt" kzLib (click)="takingPic()">
        Click to take a picture of ID document
      </div>
    </div>





    <input #file class="hidden" (change)="handleFileInput($event)" type="file" name="image" ng2FileSelect [uploader]="uploader" accept="image/x-png,image/gif,image/jpeg" />

  </div>
  <div class="take_pic" *ngIf="bTakePic" [ngClass]="{hide:hide_video}">
    <video [muted]="true" #videoElement class="mirrow" autoPlay autoplay playsinline>
    </video>
    <img *ngIf="hide_video" class="img" [src]="img_src">

    <div class="inst">
      {{prompt}}
    </div>
    <k-button class="camera" icon="camera_white.svg" background="#40BE5C" kzLib (click)="takeThePic()"></k-button>

  </div>
  <div class="take_pic uploaded_pic" *ngIf="!bTakePic && img_src">
    <img class="img" [src]="img_src">
  </div>

  <k-button [text]="next_text" class="next" [color]="text_color" [background]="done_color" kzLib (click)="done()"></k-button>
  <div *ngIf="img_src" class="rotate">
    <img src="assets/images/rotate.svg" kzLib (click)="rotate()">
  </div>
  <img *ngIf="!img_src" class="undo" src="assets/images/undo_gray.svg" alt="undo" kzLib (click)="undo()">
  <img *ngIf="img_src" class="undo" src="assets/images/undo.svg" alt="undo" kzLib (click)="undo()">
  <img class="cancel" src="assets/images/close.svg" kzLib (click)="cancel()">

  <img class="cancel" src="assets/images/close.svg" kzLib (click)="cancel()">
  <k-spinner class="abs_center" *ngIf="bShowSpinner"></k-spinner>

</div>
