import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';

@Component({
  selector: 'switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        left: "16px",
      })),
      state('closed', style({
        left: "0px",
      })),
      transition('open => closed', [
        animate('.25s')
      ]),
      transition('closed => open', [
        animate('.25s')
      ]),
    ]),]
})
export class SwitchComponent implements OnInit {
  bOn: boolean = false;
  @Input() set checked(c: boolean) {
    this.bOn = c;
    this.setBack()
  }
  @Input() back_on = "#40BE5C"
  @Input() back_off = "#d9d9d9"
  back_color: string = "#d9d9d9"
  border: string = "2px solid #d9d9d9"
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor() {
    // console.log("switch")
  }
  clicked() {
    this.bOn = !this.bOn
    this.setBack()
    this.checkedChange.emit(this.bOn)
  }
  ngOnInit(): void {
  }
  setBack() {
    if (this.bOn) {

      this.back_color = this.back_on
    } else {
      this.back_color = this.back_off
    }
    this.border = "2px solid " + this.back_color
  }

}
