import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as wssReducer from './wss.reducer';
import { StoreModule } from '@ngrx/store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('wss', wssReducer.wssReducer),

  ]
})
export class WSSModule { }
