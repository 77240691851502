import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts, SetPencil } from '../../canvas.actions';
import { pencilWidth, strokeColor } from '../../canvas.selectors';

@Component({
  selector: 'line-tools',
  templateUrl: './line-tools.component.html',
  styleUrls: ['./line-tools.component.scss']
})
export class LineToolsComponent {


  constructor(
    public canvasStore: Store<CanvasState>
  ) {
    console.log("line tools")
  }

}
