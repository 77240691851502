import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { WSSState } from 'src/app/wss/wss.model';
import { myId } from 'src/app/wss/wss.selectors';
import { NotifyUser, SetCalendlyRep, ShowCC } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { calendlyRep, hostUrl } from '../../pages.selectors';
import { CalendarService } from '../calendar.service';
import { Clipboard } from '@angular/cdk/clipboard'
@Component({
  selector: 'calendly-settings',
  templateUrl: './calendly-settings.component.html',
  styleUrls: ['./calendly-settings.component.scss']
})
export class CalendlySettingsComponent {
  rep_link: string = ""
  my_id: string | undefined
  host_url: string | undefined
  cal_link: string = "cal_link"
  subscriptions: any[] = []

  constructor(

    public calendar_service: CalendarService,
    public pagesState: Store<PagesState>,
    private wssState: Store<WSSState>,
    private clipboard: Clipboard,
  ) {
    console.log("calendly ")

    this.subscriptions.push(this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      this.my_id = my_id
    }))
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
    this.subscriptions.push(this.pagesState.select(calendlyRep).subscribe((rep_link: string | undefined) => {
      if (rep_link) {
        this.rep_link = rep_link
      }
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {

    let link = "https://" + this.host_url + "?rep_id=" + this.my_id + "&check_in=true"
    this.cal_link = link
  }
  copyLik() {
    this.clipboard.copy(this.cal_link);
    this.pagesState.dispatch(new NotifyUser("Link copied"))
  }
  closeIt() {
    this.pagesState.dispatch(new ShowCC(""))

  }
  async updateCalendly() {
    try {

      await this.calendar_service.setCalendly(this.rep_link)
      this.pagesState.dispatch(new SetCalendlyRep(this.rep_link))
      this.pagesState.dispatch(new ShowCC(""))

    } catch (e) {
      console.error(e)
    }
  }
}
