import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Busy } from 'src/app/wss/wss.actions';
import { WSSState } from 'src/app/wss/wss.model';
import { Router } from '@angular/router';
import { KeyziiService } from 'src/app/wss/keyzii.service';
import { busy } from 'src/app/wss/wss.selectors';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
@Component({
  selector: 'profile-opts',
  templateUrl: './profile-opts.component.html',
  styleUrls: ['./profile-opts.component.scss']
})
export class ProfileOptsComponent {
  subscriptions: any[] = []

  constructor(
    public router: Router,
    private wssState: Store<WSSState>,
    private keyzii_service: KeyziiService,
    public canvasStore: Store<CanvasState>,
  ) {
    this.wssState.select(busy).subscribe((busy: boolean) => {
      setTimeout(() => {
        this.bOnLine = !busy
        if (this.bOnLine) {
          this.status = "Online"
        } else {
          this.status = "Offline"
        }
      })
    })
    this.subscriptions.push(this.wssState.select(busy).subscribe((busy: boolean) => {
      setTimeout(() => this.bOnLine = !busy)
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  bOnLine: boolean = true;
  status: string = "Online"
  offLine($event: any) {

    this.wssState.dispatch(new Busy(!$event))
    //wss sends a busy message to the server


    if ($event == true) {
      this.status = "Online"
    } else {
      this.status = "Offline"
    }

  }
  settings() {
    this.router.navigate(['profile']);
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
  logout() {
    this.keyzii_service.logOut()
  }
  comunity() {
    window.open("https://www.skool.com/keyzii")

  }
}
