import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from 'src/app/chapala-mls/chapala-mls-service';
import { SetSearch } from 'src/app/chapala-mls/chapala-mls.actions';
import { ChapalaMLSState } from 'src/app/chapala-mls/chapala-mls.model';
import { gotMLSMsg } from 'src/app/chapala-mls/chapala-mls.selectors';
import { SelectorComponent } from '../selector.component';

@Component({
  selector: 'mls-min-price',
  templateUrl: '../selector.component.html',
  styleUrls: ['../selector.component.scss']
})
export class MinPriceComponent extends SelectorComponent {

  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);
    console.log("Minumun")

    this.setTitle("Minumun")

    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.select) {
          if (msg.select.hasOwnProperty("Minumun")) {
            let index = msg.select["Minumun"]
            let price = this.options[index].replace(/\D/g, '');
            this.chapalaMLState.dispatch(new SetSearch("min_price", price))
            // setTimeout(() => {
            //   this.bShowOptions = false
            // }, 100)
          }
        }
      }
    }))
    this.options = [
      "No Min",
      "$ 0",
      "$ 100,000",
      "$ 200,000",
      "$ 300,000",
      "$ 400,000",
      "$ 500,000",
      "$ 600,000",
      "$ 700,000",
      "$ 800,000",
      "$ 900,000",
    ]

  }
  selOption(i: number) {
    super.selOption(i)

  }

}
