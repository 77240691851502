<div *ngIf="!bIconText" class="out txt" [style.border]="_border" [style.min-width.px]="min_width" [style.min-height.px]="min_height" [style.color]="_color" [style.fontSize]="fontSize" [style.fontWeight]="fontWeight" (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()" [ngClass]="{disabled:disabled}">
  <div *ngIf="text && !src_icon" class="center" [ngClass]="{disabled:disabled}">{{text}}</div>
  <img *ngIf="src_icon && !text" class="abs_center" [ngClass]="{disabled:disabled}" [src]="src_icon" />
</div>

<div *ngIf="bIconText" class="out txt div_tx_icon" [style.border]="_border" [style.min-width.px]="min_width" [style.min-height.px]="min_height" [style.color]="_color" [style.fontSize]="fontSize" [style.fontWeight]="fontWeight" (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()" [ngClass]="{disabled:disabled}">
  <div class="content">
    <img #img class="left" [ngClass]="{disabled:disabled}" [src]="src_icon">
    <div class="icon_text" [ngClass]="{disabled:disabled}">{{text}}</div>
  </div>
</div>
