import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { RejectedCall } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { WSSState } from 'src/app/wss/wss.model';
import { myId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';

@Component({
  selector: 'queue-wnd-options',
  templateUrl: './wnd-options.component.html',
  styleUrls: ['./wnd-options.component.scss']
})
export class WndOptionsComponent {
  queue_entry: any = {}
  new_caller: string = "New Caller"
  bShowPersonal: boolean = false;
  my_id: string = "";
  prev_rep_name: string = ""
  prev_date: Date | undefined
  bShowHangUp: boolean = false;
  @Input() set call(c: any) {
    this.queue_entry = c
    if (this.queue_entry.prev_call) {
      this.new_caller = "Contact"
      this.prev_rep_name = this.queue_entry.prev_call.rep_name
      this.prev_date = new Date(this.queue_entry.prev_call.start)

    }
    if (this.queue_entry.rep_id) {
      if (this.queue_entry.rep_id == this.my_id) {
        this.bShowPersonal = true;
        this.bShowHangUp = true
      }
    }
  }
  subscriptions: any[] = []

  constructor(
    public pagesState: Store<PagesState>,
    public wss_service: WSSService,
    public wssState: Store<WSSState>,
    public canvasStore: Store<CanvasState>,
  ) {
    this.subscriptions.push(this.wssState.select(myId).subscribe((my_id: any) => {
      this.my_id = my_id
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  hangUp() {
    console.log("wnd opts")
    this.wss_service.sendMessage({
      forward: [this.queue_entry.caller_id],
      call_rejected: true
    })
    this.pagesState.dispatch(new RejectedCall(this.queue_entry))
    //
    // this.pagesState.dispatch(new ShowCallsWnd(false))
  }
  answer() {
    // let msg = { answer: this.queue_entry.caller_id }
    // if (this.queue_entry.caller_name) {
    //   this.pagesState.dispatch(new CallerName(this.queue_entry.caller_name))
    // }
    // if (this.queue_entry.caller_email) {
    //   this.pagesState.dispatch(new CallerEmail(this.queue_entry.caller_email))
    // }
    // this.wss_service.sendMessageToMembersAndMe(msg)
    // this.canvasStore.dispatch(new SelectToolOpts(""))
    this.wss_service.answer(this.queue_entry)
  }
}
