<div #out class="out" [ngClass]="{black:_asset.type!='pdf' && _asset.type!='contract'}">

  <images class="out" *ngIf="_asset.type=='image'" [asset]="_asset"></images>
  <matterport class="out" *ngIf="_asset.type=='matterport'" [asset]="_asset" [id]="_asset.url"></matterport>
  <uploaded-video class="out" *ngIf="_asset.type=='uploaded' || _asset.type=='video'" [asset]="_asset"></uploaded-video>

  <app-youtube class="out" *ngIf='_asset.type == "youtube"' [asset]="_asset">
  </app-youtube>

  <rep-pdf-canvas *ngIf="_asset.type=='pdf'||_asset.type=='contract'" [asset]="_asset" class="out">
  </rep-pdf-canvas>

  <white-board class="out" *ngIf="_asset.type=='white_board'"></white-board>
  <video-pen class="out" *ngIf="_asset.type=='video_canvas'" [roll]="_asset.roll"></video-pen>
  <app-kuula class="out" *ngIf="_asset.type=='kuula'" [asset]="_asset"> </app-kuula>

  <iframe class="out iframe" *ngIf="_asset.type=='iframe'" [src]="sanitized_url"> </iframe>
  <iframe [style.left.px]="p2p_left" [style.top.px]="p2p_top" [style.width.px]="p2p_width" [style.height.px]="p2p_height" class="out iframe" *ngIf="_asset.type=='on_board_p2p' && p2p_width" [src]="sanitized_url"> </iframe>


  <api-wnd *ngIf="bShowAPI" class="body"></api-wnd>

</div>
