import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { Clipboard } from '@angular/cdk/clipboard'
import {
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  getDate,
  getMonth,
  getYear,
  isToday,
  isSameDay,
  isSameMonth,
  isSameYear,
  isBefore,
  isAfter,
  getDay,
  subDays,
  setDay,
  format,
  addMonths,
  subMonths,
  setYear,
  addYears,
  subYears
} from 'date-fns';
import { PagesState } from '../../pages.model';
import { CalendarState } from '../calendar.model';
import { CalendarService } from '../calendar.service';

export interface Day {
  date: Date;
  day: number;
  month: number;
  year: number;
  inThisMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
  isSelectable: boolean;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  sel_cal = ["Month", "Week", "Day"]

  date: Date = new Date()
  next_lbl: string = ""

  sel_index: number = 0
  cols = [0, 1, 2, 3, 4, 5, 6, 7]
  week_days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


  rows: any[] = []
  language: string = navigator.language
  time_zone: number = 0
  hour_slots: any[] | undefined
  @Input() minimized: boolean = false
  subscriptions: any[] = []

  constructor(
    public calendarStore: Store<CalendarState>,
    public calendar_service: CalendarService,
    public clipboard: Clipboard,
    public pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
    @Inject(LOCALE_ID) public locale: string,

  ) {
    console.log("calendar")
    this.setSel(0)
    this.time_zone = (this.date.getTimezoneOffset() / 60);
    // this.calendar_service.getDayEvents(this.date)
    //This will fetch the wrong date events

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }

  setDate($event: Date) {
    this.date = $event;
    this.setSel(2)
  }
  setSel(i: number) {
    this.sel_index = i
    if (this.sel_index == 0) {
      this.next_lbl = this.months[getMonth(this.date)]
    } else if (this.sel_index == 1) {
      let new_time = this.date.getTime()
      let date = new Date(new_time)
      date.setHours(0, 0, 0, 0);
      let from = date.getTime()
      let day = date.getDay()
      from -= day * 24 * 60 * 60 * 1000
      console.log("from" + new Date(from))
      let to = (from + 7 * 24 * 60 * 60 * 1000) - 1
      let from_date = new Date(from)
      let to_date = new Date(to)
      this.next_lbl = this.months[getMonth(from_date)] + " " + getDate(from_date) + " - " + this.months[getMonth(to_date)] + " " + getDate(to_date)

    } else {
      this.next_lbl = this.months[getMonth(this.date)] + " " + getDate(this.date)

    }
  }
  prev() {
    if (this.sel_index == 0) { //its month
      this.date = subMonths(this.date, 1);
    } else { //its week
      let new_time = this.date.getTime()
      new_time -= 60 * 60 * 24 * 7 * 1000
      this.date = new Date(new_time)
    }
    this.setSel(this.sel_index)
  }
  next() {
    if (this.sel_index == 0) { //its month
      this.date = addMonths(this.date, 1);

    } else { //its week
      let new_time = this.date.getTime()
      new_time += 60 * 60 * 24 * 7 * 1000
      this.date = new Date(new_time)
    }
    this.setSel(this.sel_index)
  }
}
