import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { KeyziiService } from 'src/app/wss/keyzii.service';
import { PagesState } from '../../pages.model';
import { gotCode, gotPlans, gotReps, gotUsers, hostUrl, myRoll } from '../../pages.selectors';
// import { Clipboard } from '@angular/cdk/clipboard'
import { PagesService } from '../../pages.service';
import { Hash } from 'src/app/utils';
import { NotifyError, NotifyUser } from '../../pages.actions';
import { WSSState } from 'src/app/wss/wss.model';
import { customerId, lastMsg } from 'src/app/wss/wss.selectors';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {

  sel_user_types = [
    'Admin',
    // 'Manager',
    'Agent']

  search_type: any
  host_url: string | undefined
  search: string = ""
  all_users: any[] = []
  users: any[] = []
  reps: any[] = []

  bAddingUser: boolean = false
  edit_user_top: number = 0; //!=0 shows
  edit_user_left: number = 0
  del_user: any
  link_user_top: number = 0; //!=0 shows
  link_user_left: number = 0;
  link_user: any
  del_user_top: number = 0; //!=0 shows
  del_user_left: number = 0;
  show_rolls_index: number = -1
  sel_roll_type: string | undefined
  edit_user: any | undefined

  edit_row: number = -1
  edit_what: string = ""
  edit_value: string = ""

  selected_rows: Hash = new Hash()

  _filter_type: string[] = []
  _filter_name: string | undefined


  @Input() set filter_name(val: string | undefined) {
    if (val) {
      this._filter_name = val.toLowerCase()
    } else {
      this._filter_name = val
    }
    this.setAllUsers()
  }
  @Input() set filter_type(val: any) {
    this.filterType(val)
  }
  @Input() set add_user(n: number) {
    if (n) {
      this.addUser()
    }
  }
  subscriptions: any[] = []
  sel_option: number = 0
  link_options: any[] = [
    {
      img: "assets/images/copy.svg",
      text: "Personal Link"
    }, {
      img: "assets/images/embed.svg",
      text: "Embed Button"
    }]

  roll: string = "admin"
  customer_id: string | undefined

  selected_plan: any = {}
  code: any

  constructor(
    private pagesState: Store<PagesState>,
    private keyzii_service: KeyziiService,
    public canvasStore: Store<CanvasState>,
    private page_service: PagesService,
    private wssState: Store<WSSState>,
  ) {
    console.log("users")
    // for (let i = 0; i < 30; i++) {
    //   let user = {
    //     name: "User-" + i,
    //     email: "email-" + i + "@company.com",
    //     role: "Agent",
    //     link: "https://keyzi.com"
    //   }
    //   this.users.push(user)
    // }
    this.subscriptions.push(this.pagesState.select(gotPlans).subscribe((plans: any[]) => {
      if (plans.length > 0) {
        this.selected_plan = plans[0]
      }
    }))
    // }
    this.subscriptions.push(this.pagesState.select(gotCode).subscribe((code: any) => {
      if (code) {
        this.code = code

      }
    }))
    this.subscriptions.push(this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      this.customer_id = customer_id;
    }))
    this.subscriptions.push(this.pagesState.select(myRoll).subscribe((my_roll: string) => {
      setTimeout(() => this.roll = my_roll);
    }))
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
    this.subscriptions.push(this.pagesState.select(gotReps).subscribe((users: any[]) => {
      this.users = users
      this.setAllUsers()
    }))
    this.subscriptions.push(this.pagesState.select(gotUsers).subscribe((reps: any[]) => {
      this.reps = reps
      this.setAllUsers()
    }))
    this.subscriptions.push(this.wssState.select(lastMsg).subscribe((msg: any) => {
      if (msg) {
        if (msg.hasOwnProperty("new_user")) {
          // let id = msg.new_user
          this.keyzii_service.getUsersAndReps()

        }
      }
    }))
    this.keyzii_service.getUsersAndReps()

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  selRow(i: number) {
    if (!this.selected_rows.insert(i)) {
      this.selected_rows.remove(i)
    }
  }

  // deleteUsers() {
  //   let array = this.selected_rows.array
  //   for (let i = 0; i < array.length; i++) {
  //     let target = array[i]
  //     let user: any = this.all_users[target]
  //     this.keyzii_service.deleteUser(user)
  //   }
  // }

  filterType(val: any) {
    this._filter_type = []
    if (val) {
      for (let i = 0; i < val.length; i++) {
        let s = val[i]
        if (s.selected) {
          if (s.desc == "All") {
            this._filter_type = []
            break;
          } else if (s.desc == "Admin") {
            this._filter_type.push("admin")
          } else if (s.desc == "Manager") {
            this._filter_type.push("manager")
          } else {
            this._filter_type.push("rep")
          }
        }
      }
    }

    this.setAllUsers()
  }

  setAllUsers() {
    let all_users: any[] = []
    let me = this
    function addToArray(collection: any) {
      if (collection) {
        let keys = Object.keys(collection)
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i]
          let user = Object.assign({}, collection[key])
          if (!user._id) {
            user._id = key
            user.customer_id = me.customer_id
          }
          user.selected = false
          let bAddIt: boolean = true
          if (me._filter_name) {
            let name = user.name
            if (!name) {
              name = user.email
            }
            if (name.toLowerCase().indexOf(me._filter_name) < 0) {
              bAddIt = false;
            }
          }
          if (me._filter_type.length > 0) {
            if (me._filter_type.indexOf(user.roll) < 0) {
              bAddIt = false;
            }
          }
          if (bAddIt) {
            all_users.push(user)
          }
        }
      }
    }
    addToArray(this.users)
    addToArray(this.reps)
    all_users.sort(function(a, b) {
      let a_name = a.name
      let b_name = b.name
      if (a_name && b_name) {
        return a_name.toLowerCase().localeCompare(b_name.toLowerCase());
      }
      if (a_name) {
        return -1
      }
      if (b_name) {
        return 1
      }
      return 0
    })
    setTimeout(() => this.all_users = all_users)
  }
  selUser(i: number) {
    let users: any[] = Object.assign([], this.users)
    let user: any = Object.assign({}, users[i])
    user.selected = !user.selected
    users[i] = user
    this.users = users

  }
  ngOnInit(): void {
  }
  // showOptions($event: any, i: number) {
  //   $event.stopPropagation()
  //   this.show_rolls_index = i
  //   this.sel_roll_type = this.users[i].role
  // }
  copyLink(i: number) {
    let val: string | undefined
    if (i == 0) {
      val = this.linkToCB(this.link_user)
    } else {
      val = this.embedToCB(this.link_user)
    }
    if (val) {

      // var textArea = document.createElement("textarea");


      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.link_user_top = 0
    }
  }
  selOptType(i: number, sel_type: string) {

    this.sel_roll_type = sel_type
    let users: any[] = Object.assign([], this.users)
    let user: any = Object.assign({}, users[i])
    user.role = sel_type
    users[i] = user
    this.users = users

  }



  getSelTitle(user: any) {
    if (user.roll == "admin") {
      return "Admin";
    }
    if (user.roll == "manager") {
      return "Manager";
    }
    return "Agent"
  }

  links($event: any, user: any) {
    if (this.link_user_top || user.new_user) {
      this.link_user_top = 0
    } else {
      this.bAddingUser = false
      this.edit_user_top = 0
      this.del_user_top = 0
      this.link_user_top = $event.y - 215;
      this.link_user_left = $event.x - 220;
      this.link_user = user
    }
  }

  addUser() {
    //Check the number of users in the plan and only allow adding if is less
    let tot_users = 0
    if (this.selected_plan) {
      tot_users += parseInt(this.selected_plan.num_users)

      if (this.code) {
        tot_users += this.code.users
      }
      let num_users = 0
      if (this.users) {
        let uk = Object.keys(this.users)
        num_users += uk.length
      }
      if (this.reps) {
        let rk = Object.keys(this.reps)
        num_users += rk.length
      }
      if (num_users >= tot_users) {
        this.pagesState.dispatch(new NotifyError("Add users to your plan"))
        // return
      }
    }
    this.bAddingUser = !this.bAddingUser
    if (this.bAddingUser) {
      this.canvasStore.dispatch(new SelectToolOpts("add_one_user"))
      this.edit_user_top = 0
      this.link_user_top = 0
      this.del_user_top = 0
    }
  }
  edit($event: any, user: any) {
    if (this.edit_user_top) {
      this.edit_user_top = 0
    } else {
      this.edit_user = user
      this.bAddingUser = false
      this.del_user_top = 0
      this.link_user_top = 0
      this.edit_user_top = $event.y - 170;
      this.edit_user_left = $event.x - 250;
      this.canvasStore.dispatch(new SelectToolOpts("add_one_user", this.edit_user))

    }
  }

  delete($event: any, user: any) {

    if (this.del_user_top) {
      this.del_user_top = 0
    } else {
      if (user.roll == "admin") {
        let num_admins = 0
        if (this.all_users) {

          for (let j = 0; j < this.all_users.length; j++) {

            let user = this.all_users[j]
            if (user.roll == "admin") {
              num_admins++
            }
          }
        }
        if (num_admins < 2) {
          this.pagesState.dispatch(new NotifyError("You can not delete the last admin"))
          return
        }
      }
      this.edit_user_top = 0
      this.link_user_top = 0
      this.bAddingUser = false
      this.del_user_top = $event.y - 170;
      this.del_user_left = $event.x - 320;
      this.canvasStore.dispatch(new SelectToolOpts("del_user", user))


    }
  }
  // deleteUser() {
  //   this.keyzii_service.deleteUser(this.del_user)
  //   this.del_user_top = 0
  // }
  //****************************************************************************
  // Edit fields
  //****************************************************************************
  /*editField($event: any, i: number, what: string) {
    $event.stopPropagation()
    let user = this.all_users[i]
    if (user) {
      this.edit_row = i
      this.edit_what = what
      this.edit_value = user[this.edit_what]
    }
  }

  doneEditing() {
    this.show_rolls_index = -1
    if (this.edit_row >= 0) {
      setTimeout(() => {

        let users: any[] = Object.assign([], this.all_users)
        let user: any = Object.assign({}, users[this.edit_row])
        user[this.edit_what] = this.edit_value
        users[this.edit_row] = user
        this.edit_row = -1;
        this.all_users = users
        this.edit_value = ""
        this.page_service.updateUser(user)
      })
    }
  }

  clickedEdit($event: any) {
    $event.stopPropagation()
  }

*/



  linkToCB(user: any) {
    if (this.host_url) {
      let _id = user._id
      let name = "Rep"
      if (user.name && user.name.length > 0) {
        name = user.name
      }
      this.pagesState.dispatch(new NotifyUser(name + " link copied"))

      return "https://" + this.host_url + "?rep_id=" + _id
      // this.clipboard.copy(link);

    }

    return
  }
  embedToCB(user: any) {
    const for_frame = ' target="_blank"'
    const img_src = "https://" + this.host_url + "/rep_btn/" + user.email

    let btn_html = ' <style>' +
      '.kbtn{ position:relative; width:150px; height:100px; overflow:hidden; } ' +
      '  .box {' +
      '  transition: box-shadow .3s; width:150px;' +
      '}' +
      '.box:hover {' +
      'box-shadow: 0 0 11px rgba(33, 33, 33, .3);' +
      '  }' +
      '  </style>' +
      '  <div class="kbtn" >' +
      '<a href="' + this.host_url + '"' + for_frame + '><img src="' + img_src + '" class="box" ></a>' +
      '</div>'
    // this.clipboard.copy(btn_html);
    let name = "Rep"
    if (user.name && user.name.length > 0) {
      name = user.name
    }
    this.pagesState.dispatch(new NotifyUser(name + "Embed HTML copied"))

    return btn_html
  }

}
