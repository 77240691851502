<div class="out">
  <div *ngIf="!bTakePic" class="options" ng2FileDrop [uploader]="uploader" (onFileDrop)="dropped($event)">
    <div *ngIf="!bShowSpinner">
      <div class="upload_img">
        <img class="abs_center" src="assets/images/upload2.svg" alt="Upload" kzLib (click)="file.click();">
      </div>
      <div class=" upload_txt">
        Upload ID Document
      </div>

      <div class="line">

      </div>
      <div class="abs_center or">
        or
      </div>
      <div class="upload_img take_img">
        <img class="abs_center" src="assets/images/camera.svg" alt="Upload" kzLib (click)="takingPic()">
      </div>
      <div class="take_txt">
        Take a picture of ID Document
      </div>
    </div>
  </div>

  <div *ngIf="bTakePic" class="viewport" #viewPort>

    <video [muted]="true" class="mirrow" [ngClass]="{hide:hide_video}" #videoElement autoPlay autoplay playsinline>
    </video>
    <div class="anchor" [style.left.px]="left" [style.top.px]="top" #topLeft></div>
    <div class="anchor" [style.left.px]="right" [style.top.px]="top" #topRight></div>
    <div class="anchor" [style.left.px]="left" [style.top.px]="bottom" #bottomLeft></div>
    <div class="anchor" [style.left.px]="right" [style.top.px]="bottom" #bottomRight></div>

    <div class="line" [style.left.px]="left" [style.top.px]="top" [style.width.px]="right-left" [style.height.px]="1"></div>
    <div class="line" [style.left.px]="left" [style.top.px]="bottom+10" [style.width.px]="right-left" [style.height.px]="1"></div>

    <div class="line" [style.left.px]="left" [style.top.px]="top" [style.height.px]="bottom-top" [style.width.px]="1"></div>
    <div class="line" [style.left.px]="right+10" [style.top.px]="top" [style.height.px]="bottom-top" [style.width.px]="1"></div>


  </div>
  <img *ngIf="img_src" class="viewport" [src]="img_src">

  <!-- <div class="inst" *ngIf="bTakePic">
    {{prompt}}
  </div> -->
  <k-button *ngIf="bTakePic" class="camera" icon="camera_white.svg" background="#f2f2f2" kzLib (click)="takeThePic()"></k-button>
  <input #file class="hidden" (cancel)="canceledFileInput()" (change)="handleFileInput($event)" type="file" name="image" ng2FileSelect [uploader]="uploader" accept="image/x-png,image/gif,image/jpeg" />
  <k-spinner *ngIf="bShowSpinner" class="abs_center"></k-spinner>
</div>
