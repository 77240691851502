<div class="out">
  <!-- <div class="container" #divContainer></div> -->

  <canvas *ngIf="!signers" #canvas class="container" width="2040" height="2640"></canvas>
  <div *ngIf="signers" class="abs_center">
    <div *ngFor="let key of signer_keys" class="">
      {{signers[key].name}} {{signers[key].signed_at|date}}
    </div>
  </div>
</div>
