
import { CalendarState, initialCalendarState, } from './calendar.model'

import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectCalendarState = createFeatureSelector<CalendarState>("calendar");

export const changedHourSlots = createSelector(
  selectCalendarState,
  state => {
    console.log("hour_slots changed ")
    return state.hour_slots
  }
)

export const changedSlots = createSelector(
  selectCalendarState,
  state => {
    console.log("slots changed ")
    return state.slots
  }
)
export const weekSlots = createSelector(
  selectCalendarState,
  state => {
    console.log("week Slots changed")
    return state.week_slots

  }
)

export const changedWorkTime = createSelector(
  selectCalendarState,
  state => {
    console.log("work_time changed ")
    return state.work_time

  }
)
export const gotEvents = createSelector(
  selectCalendarState,
  state => {
    console.log("gotEvents changed ")
    return state.events

  }
)

export const refreshDayWeek = createSelector(
  selectCalendarState,
  state => {
    console.log("work_time changed ")
    return state.refresh_counter

  }
)

export const newAppointment = createSelector(
  selectCalendarState,
  state => state.new_appt

)

export const hasCalToken = createSelector(
  selectCalendarState,
  state => state.bHasCalToken
)

export const tmpEvent = createSelector(
  selectCalendarState,
  state => state.tmp
)
export const fromToEvents = createSelector(
  selectCalendarState,
  state => state.from_to_events
)
export const changedTZ = createSelector(
  selectCalendarState,
  state => state.changed_tz
)
