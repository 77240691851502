<div class="out" #outComponent [ngClass]="{  'dark-theme': theme=='dark'}">
  <div class="body">
    <app-chat class="app-chat"></app-chat>
    <div class="right" [style.left]="left" [style.width]="width">
      <k-video *ngIf="show=='kvideo'" role="me" class="content" [bMirrow]="true">
      </k-video>
      <in-call-asset class="content" *ngIf="show=='in_call_asset'"></in-call-asset>
      <in-call-caller class="content" *ngIf="show=='in_call_caller'"></in-call-caller>
      <side-by-side class="content" *ngIf="show=='side_by_side'"></side-by-side>

    </div>
  </div>
  <desk-buttons class="menu1" *ngIf="!isPhone"></desk-buttons>
  <phone-buttons class="menu1" *ngIf="isPhone"></phone-buttons>

  <tools class="tools"></tools>

</div>
