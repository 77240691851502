import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { WSSState } from 'src/app/wss/wss.model';
import { kPayState } from '../kpay.model';
import { gotKAcc, planFromCaller, planFromRep, runKPay } from '../kpay.selectors';

@Component({
  selector: 'kpay-screen',
  templateUrl: './kpay-screen.component.html',
  styleUrls: ['./kpay-screen.component.scss']
})
export class KpayScreenComponent {
  //For kplan acc, plan bill_pay updating plan_done gif
  //for kpay acc, invoice bill_pay,  updating pay_done

  state: string = "plan" //"plan" //"acc" "invoice" "plan" //"bill" //
  acc: any
  caller_plan: any
  rep_plan: any
  updating_msg: string = "Uploading Information"
  running: string | undefined
  subscriptions: any[] = []
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(
    public kpayState: Store<kPayState>,
    public wssState: Store<WSSState>
    // public recaptcha_service: RecaptchaService
  ) {
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.subscriptions.push(this.kpayState.select(runKPay).subscribe((running: string | undefined) => {
      this.running = running
      this.setStep()
    }))
    this.subscriptions.push(this.kpayState.select(planFromRep).subscribe((plan: any) => {
      this.rep_plan = plan
      this.setStep()
      //After the plan is done, we
    }))
    this.subscriptions.push(this.kpayState.select(planFromCaller).subscribe((plan: any) => {
      this.caller_plan = plan
      this.setStep()
    }))
    this.subscriptions.push(this.kpayState.select(gotKAcc).subscribe((acc: any) => {
      this.acc = acc
      this.setStep()
    }))
  }
  setStep() {
    setTimeout(() => {
      if (this.running == "error") {
        this.state = "error"
      } else if (this.running == "plan") {
        if (!this.rep_plan) {
          this.state = "plan"
        }
      } else {
        this.state = "pay"
      }
    })
  }
  closeErr() {
    this.state = ''
    this.close.emit()
  }
}
