import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetTab } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { convergeAcc } from 'src/app/pages/pages.selectors';

@Component({
  selector: 'kpay-main',
  templateUrl: './main.component.html',
  styleUrls: ['../../pages/profile/profile/profile.component.scss', './main.component.scss']
})
export class KPayMainComponent {
  bShowForm: boolean = false
  converge_acc: string | undefined
  subscriptions: any[] = []
  constructor(
    private pagesState: Store<PagesState>,
  ) {
    this.pagesState.dispatch(new SetTab("kpayset"))
    this.subscriptions.push(this.pagesState.select(convergeAcc).subscribe((converge_acc: string | undefined) => {
      this.converge_acc = converge_acc
    }))

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
}
