import { Store } from '@ngrx/store';
import { SVG } from '@svgdotjs/svg.js';
import { PagesState } from 'src/app/pages/pages.model';
import { WSSState } from 'src/app/wss/wss.model';
import { AddToUndo, SelectId } from '../canvas.actions';
import { CanvasService } from '../canvas.service';
import { CanvasComponent } from '../canvas/canvas.component';
import { Draw } from './draw';


export class kPolygon extends Draw {

  polygons: any = {}
  // bSelected: boolean = false;


  drawing_poly: any | undefined //for move all
  // polygon_id: string = ""
  poly_array: number[] = [] //used to move anchors

  anchors: any[] = []
  new_points: any[] = []
  move_index: number = -1
  anchor_left: number = 0
  anchor_top: number = 0

  line_width: number = 3

  // bIsLine: boolean = false //limits the number of points to 2


  //****************************************************************************
  // Parent events
  //****************************************************************************
  //called when the tool is polygon or polyfill
  newObj(left: number, top: number, right: number, bottom: number, sel_tool_name: string) {
    left = this.parent_canvas.deScaleX(left)
    top = this.parent_canvas.deScaleY(top)

    right = this.parent_canvas.deScaleX(right)
    bottom = this.parent_canvas.deScaleY(bottom)

    let poly_array = [left, bottom, right, bottom, (left + right) / 2, top]

    let drawing_poly
    if (sel_tool_name == 'polyfill') {
      drawing_poly = this.draw_tool.polygon(poly_array).fill(this.fill_color).stroke({ width: this.line_width, color: this.fill_color })
    } else if (sel_tool_name == 'polygon') {
      drawing_poly = this.draw_tool.polygon(poly_array).fill('transparent').stroke({ width: this.line_width, color: this.fill_color })
    } else if (sel_tool_name == 'line') {
      poly_array = [left, top, right, bottom]
      drawing_poly = this.draw_tool.polygon(poly_array).fill('transparent').stroke({ width: this.line_width, color: this.fill_color })
    }
    let polygon_id = new Date().getTime().toString()
    drawing_poly.node.dataset['id'] = polygon_id
    drawing_poly.node.dataset['k_type'] = sel_tool_name
    this.polygons[polygon_id] = drawing_poly

    this.doneWithPoly(drawing_poly)
    let svg = drawing_poly.svg()
    this.canvas_service.sendCanvasMsg("polygon", "new", polygon_id, svg)
    this.canvasStore.dispatch(new AddToUndo({
      undo: "new",
      k_type: "polygon",
      id: polygon_id
    }))
    let box = this.getBox(polygon_id)
    return { id: polygon_id, box: box, k_type: sel_tool_name }
  }

  setMinSize(id: string, fromViewBox?: any) {
    let drawing_poly = this.polygons[id]
    let bbox = drawing_poly.bbox()
    if (bbox.width < 10 && bbox.height < 10) {
      let k_type = drawing_poly.node.dataset['k_type']
      let dx = 40
      let dy = 20
      if (fromViewBox) {
        dx = this.parent_canvas.scaleXToWidth(dx, fromViewBox.width)
        dy = this.parent_canvas.scaleYtoHeight(dy, fromViewBox.height)
      }
      let left = bbox.x
      let top = bbox.y - dy
      let bottom = top + dy
      let right = left + dx
      let poly_array = [left, bottom, right, bottom, (left + right) / 2, top]

      if (k_type == "line") {
        let dx = 100
        let dy = 10
        if (fromViewBox) {
          dx = this.parent_canvas.scaleXToWidth(dx, fromViewBox.width)
          dy = this.parent_canvas.scaleYtoHeight(dy, fromViewBox.height)
        }
        poly_array = [left, bottom, left + dx, bottom + dy]
      }
      drawing_poly.attr({ points: poly_array })
    }
  }

  onMouseDown($event: any, sel_tool_name: string) {
    // if (sel_tool_name == 'line') {
    //   return this.newObj($event.offsetX, $event.offsetY - 20, $event.offsetX + 40, $event.offsetY, sel_tool_name)
    // } else {
    return this.newObj($event.offsetX, $event.offsetY - 20, $event.offsetX + 40, $event.offsetY, sel_tool_name)
    // }
  }

  pasteSVG(svg: any, x: number, y: number) {
    let drawing_poly = this.polyFromSVG(svg)
    if (drawing_poly) {
      let id = drawing_poly.node.dataset['id']
      let k_type = drawing_poly.node.dataset['k_type']
      if (this.polygons[id]) { //change the id
        id = new Date().getTime().toString()
        drawing_poly.node.dataset['id'] = id
      }
      this.polygons[id] = drawing_poly
      this.moveDelta(id, 20, 20)
      let box: any = this.getBox(id)
      return { id: id, box: box, k_type: k_type }
    }
    return undefined

  }

  undo(msg: any) {
    let old = this.polygons[msg.id]
    if (old) {
      old.remove()
      delete this.polygons[msg.id]
      if (this.anchors.length > 0) {
        if (this.anchors[0].dataset['id'] == msg.id) {
          this.removeAnchors()
        }
      }
    }
    if (msg.data) {
      let new_obj = SVG(msg.data)
      let drawing_poly
      if (msg.canvas_width) {
        let vb = {
          width: msg.canvas_width,
          height: msg.canvas_height
        }
        drawing_poly = this.polyFromSVG(new_obj, vb)
      } else {
        drawing_poly = this.polyFromSVG(new_obj)
      }
      let id = drawing_poly.node.dataset['id']
      this.polygons[id] = drawing_poly
    }
  }


  polyFromSVG(poly: any, fromViewBox?: any) {

    let attr = poly.attr()
    let drawing_poly

    if (fromViewBox) {
      let points = this.getArrayFromStr(attr.points)
      let np = this.parent_canvas.scalePointsToViewBox(points, fromViewBox)
      let nattr = Object.assign({}, attr)
      nattr.points = np
      drawing_poly = this.draw_tool.polygon(nattr)
    } else {
      console.log("drawing_rect 1")
      drawing_poly = this.draw_tool.polygon(attr)
    }

    this.doneWithPoly(drawing_poly)
    return drawing_poly
  }





  //****************************************************************************
  // Utils
  //****************************************************************************
  doneWithPoly(drawing_poly: any) {

    // drawing_poly.node.onmousemove = onPolyMove
    drawing_poly.node.onmouseleave = onPolyLeave
    drawing_poly.node.onpointerdown = onPolyDown
    drawing_poly.node.onpointerup = onPolyUp

    //this.createAnchors()

    // this.canvasStore.dispatch(new SetTool("none"))
    //**************************************************************************
    // plygon functions
    //**************************************************************************
    //The position as it moves
    let startX: number = 0
    let startY: number = 0

    let x1: number = 0
    let y1: number = 0
    let box: any
    let me = this

    function onMoveAll(this: any, $event: any) {
      $event.stopPropagation()
      if (me.drawing_poly) {
        let delta_x = startX - $event.offsetX
        let delta_y = startY - $event.offsetY
        //now take the original box and calculate the new one
        x1 = box.x1 + delta_x
        y1 = box.y1 + delta_y

        let new_points: number[] = []
        for (let i = 0; i < box.data.points.length; i++) {
          let p = box.data.points[i]
          if (i % 2 == 0) { //X
            p -= delta_x
          } else {
            p -= delta_y
          }
          new_points.push(p)
        }
        drawing_poly.attr({ points: new_points })
        me.removeAnchors()
        let id = me.drawing_poly.node.dataset['id']
        me.createAnchors(id)

        let xbox = drawing_poly.bbox()
        me.parent_canvas.setBox(xbox.x, xbox.y, xbox.x2, xbox.y2) //onMoveAll


      }
    }
    /*
    function onMoveUP(this: any, $event: any) {
      if (me.pointer_id) {
        me.canvas.releasePointerCapture(me.pointer_id)
        me.pointer_id = undefined

        console.log("#MV poly doneMoving back  up")
      }

      //find the distance
      if (me.drawing_poly) {
        let d = (box.x1 - x1) * (box.x1 - x1) +
          (box.y1 - y1) * (box.y1 - y1)
        if (d > 4) {
          let id = me.drawing_poly.node.dataset['id']
          let k_type = me.drawing_poly.node.dataset['k_type']

          me.parent_canvas.addToUndo({
            undo: "move",
            data: box.svg,
            k_type: k_type,
            id: id,
          })


          let new_box = me.getBox(id)

          me.parent_canvas.removeSingeSelBox()
          me.parent_canvas.processSingleSel(id, new_box, k_type)
          me.drawing_poly = undefined
        }
      }
    }*/
    // function onPolyMove(this: any, $event: any) {
    //   let id = this.dataset['id']
    //   if (me.selected_id != id) {
    //     $event.stopPropagation()
    //
    //     // if (!me.bSelected) {
    //
    //     let k_type = this.dataset['k_type']
    //     let box = me.getBox(id)
    //     if (box) {
    //       me.parent_canvas.onHover(id, box, k_type)
    //     }
    //   }
    // }
    function onPolyLeave(this: any, $event: any) {
      if (me.canvas) {
        me.canvas.style.cursor = 'auto'
      }
      // if (me.hover_div) {
      //   let id = this.dataset['id']
      //   if (id != me.hover_id) {
      //     console.log("remove hover_div " + me.hover_id)
      //     me.canvas.removeChild(me.hover_div)
      //     me.hover_div = undefined
      //   }
      // }
    }
    function onPolyUp(this: any, $event: any) {
      console.log("#poly  up ")
      this.onpointermove = null;
      if (me.pointer_id) {
        this.releasePointerCapture(me.pointer_id)
        me.pointer_id = undefined
      }
    }
    function onPolyDown(this: any, $event: any) {
      $event.stopPropagation()
      let id = this.dataset['id']
      me.drawing_poly = me.polygons[id]
      let k_type = this.dataset['k_type']
      if ($event.shiftKey) {
        let box = me.getBox(id)
        me.parent_canvas.processMultiSel(id, box, k_type)
        // me.canvasStore.dispatch(new SelectId(id, $event.shiftKey, "comment"))
        return
      }

      if (me.sel_tool_name == 'erase') {
        me.canvas_service.sendCanvasMsg("polygon", "erase", id)
        let drawing_poly = me.findGroupById(id)
        let svg = drawing_poly.svg()
        me.canvasStore.dispatch(new AddToUndo({
          undo: "erase",
          k_type: "polygon",
          id: id,
          data: svg
        }))

        let anchors = me.anchors[id]
        if (anchors) {
          for (let i = 0; i < anchors.length; i++) {
            let anchor = anchors[i]
            me.canvas.removeChild(anchor)
          }
        }
        let poly = me.findGroupById(id)
        poly.remove()
        return
      }
      /*
      if (me.bSelected) {
        box = me.getBox(id)
        startX = $event.offsetX
        startY = $event.offsetY
        me.pointer_id = $event.pointerId
        if (me.pointer_id) {
          try {
            me.canvas.setPointerCapture(me.pointer_id)
            me.canvas.onpointermove = onMoveAll
            me.canvas.onpointerup = onMoveUP
            console.log("#drm start all")
          } catch (e) {
            console.error(e)
          }
        }
        // me.prepareMove(id)
        // me.startMoveAll(this, $event)
        // me.move = "all"
        return
      }
      */
    }
  }


  createAnchors(selected_id: string) {
    this.anchors = []
    this.new_points = []
    let drawing_poly = this.polygons[selected_id]
    if (!drawing_poly) {
      return
    }
    // let k_type = drawing_poly.node.dataset['k_type']
    // if (k_type == "line") {
    //   return
    // }
    let spoints = drawing_poly.attr('points')
    let poly_array = this.getArrayFromStr(spoints)

    for (let i = 0; i < poly_array.length; i += 2) {
      let anchor = document.createElement("div");
      anchor.style.width = "10px"//page.style.width
      anchor.style.height = "10px"//page.style.height
      anchor.style.position = "absolute"
      anchor.dataset['index'] = (i / 2).toString()
      anchor.dataset['id'] = selected_id
      anchor.style.zIndex = "2"
      anchor.style.background = "#8F5CFFA0"

      anchor.onmouseenter = onAnchorEnter
      anchor.onmouseleave = onAnchorLeave
      anchor.onpointerdown = onAnchorDown
      // anchor.onpointerup = onAnchorUp

      this.anchors.push(anchor)
      this.canvas.appendChild(anchor)
    }

    poly_array = this.parent_canvas.scalePoints(poly_array)
    this.setAnchorsPos(poly_array) //create anchors
    //**************************************************************************
    // Poly Move
    //**************************************************************************
    let me = this
    function onAnchorEnter($event: any) {
      if (me.canvas && me.sel_tool_name == 'none') {    //was set on the canvas mouse down, beging
        me.canvas.style.cursor = 'move'
      }
    }
    function onAnchorLeave($event: any) {
      if (me.canvas) {
        me.canvas.style.cursor = 'auto'
      }
    }
    function onAnchorDown(this: any, $event: any) {
      $event.stopPropagation()
      me.prepareMove(this.dataset['id'], $event)
      let polygon_id = this.dataset['id']
      me.drawing_poly = me.polygons[polygon_id]
      let spoints = me.drawing_poly.attr('points')
      me.poly_array = me.getArrayFromStr(spoints)

      me.pointer_id = $event.pointerId
      me.canvas.setPointerCapture(me.pointer_id)
      console.log("#poly anchor down")
      me.canvas.onpointermove = onAnchorMove
      me.canvas.onmouseup = onAnchorUp
      me.moving_anchor = this
      me.anchor_left = parseFloat(me.moving_anchor.style.left)
      me.anchor_top = parseFloat(me.moving_anchor.style.top)

      me.move = "anchor"
      me.startX = -1

    }
    function onAnchorMove(this: any, $event: any) {
      $event.stopPropagation()
      if ($event.buttons == 0) {
        return
      }
      if (me.startX == -1) {
        me.startX = $event.offsetX
        me.startY = $event.offsetY
      }

      let delta_x = $event.offsetX - me.startX
      let delta_y = $event.offsetY - me.startY


      let x = delta_x + me.anchor_left


      let y = delta_y + me.anchor_top
      console.log("#poly anchor move1  x " + x + " y " + y + " " + $event.offsetX + " " + $event.offsetY)
      if ($event.offsetX < 0) {
        console.log("<0")
      }
      if (me.moving_anchor) {
        let sindex = me.moving_anchor.dataset['index']
        me.move_index = parseInt(sindex)

        let k_type = me.drawing_poly.node.dataset['k_type'] //xxx
        if (k_type == 'line') { //snap it
          let index = 0
          if (me.move_index == 0) {
            index = 1
          }
          let x1 = parseFloat(me.anchors[index].style.left) + 5
          let y1 = parseFloat(me.anchors[index].style.top) + 5
          console.log("snap line " + y + " " + y1)

          if (Math.abs(y - y1) < 5) {
            y = y1
          }
          if (Math.abs(x - x1) < 5) {
            x = x1
          }

        }

        if (me.move_index >= 0) {
          // console.log("move the last polygon index " + this.move_index)
          me.poly_array[me.move_index * 2] = me.parent_canvas.deScaleX(x)
          me.poly_array[me.move_index * 2 + 1] = me.parent_canvas.deScaleY(y)
          me.drawing_poly.attr({ points: me.poly_array })
        }
        me.moving_anchor.style.left = (x - 5) + "px"
        me.moving_anchor.style.top = (y - 5) + "px"

        // console.log("#poly anchor move <-- " + me.x1 + " " + me.y1 + " " + me.x2 + " " + me.y2)
        let box = me.getBoxFromArray(me.poly_array)
        me.parent_canvas.setBox(box.left, box.top, box.right, box.bottom) //onAnchorMove
        let svg = me.drawing_poly.svg()
        // let id = me.drawing_poly.node.dataset['id']
        me.canvas_service.sendCanvasMsg("polygon", "move", selected_id, svg)
        me.setNewPoints(me.poly_array)
      }
    }

    function onAnchorUp(this: any, $event: any) {
      console.log("#poly anchor up ")
      this.onpointermove = null;
      me.moving_anchor = null;
      if (me.pointer_id) {
        me.canvas.releasePointerCapture(me.pointer_id)
        me.pointer_id = undefined
      }
    }
  }
  createNewPoints(selected_id: string) {

    this.new_points = []
    let drawing_poly = this.polygons[selected_id]
    if (!drawing_poly) {
      return
    }
    // let k_type = drawing_poly.node.dataset['k_type']
    // if (k_type == "line") {
    //   return
    // }
    let spoints = drawing_poly.attr('points')
    let poly_array = this.getArrayFromStr(spoints)

    for (let i = 0; i < poly_array.length; i += 2) {

      let oImg = document.createElement("img");
      oImg.setAttribute('src', 'assets/images/anchor.svg');
      oImg.setAttribute('height', '10px');
      oImg.setAttribute('width', '10px');

      oImg.style.position = "absolute"
      // oImg.style.background = "#DAE1F7"
      oImg.dataset['index'] = (i / 2).toString()
      oImg.dataset['id'] = selected_id
      oImg.onpointerdown = newPointDown
      this.new_points.push(oImg)
      this.canvas.appendChild(oImg)

    }

    this.setNewPoints(poly_array)

    //**************************************************************************
    // Poly Move
    //**************************************************************************

    let me = this
    function newPointDown(this: any, $event: any) {
      me.addPolyPoint(this)
    }

  }
  removeAnchors() {
    let anchors = this.anchors
    if (anchors) {
      for (let i = 0; i < anchors.length; i++) {
        let anchor = anchors[i]
        this.canvas.removeChild(anchor)
      }
    }
    this.anchors = []

    let new_points = this.new_points
    if (new_points) {
      for (let i = 0; i < new_points.length; i++) {
        let new_point = new_points[i]
        this.canvas.removeChild(new_point)
      }
    }
    this.new_points = []
  }

  setAnchorsPos(poly_array: number[]) { //used after a move
    console.log("poly set anchors ")
    let anchors = this.anchors
    if (anchors.length != poly_array.length / 2) {
      // console.error("no ancles " + anchors.length + "!=" + poly_array.length / 2)
      return
    }
    for (let i = 0; i < poly_array.length; i += 2) {
      let anchor = anchors[i / 2]
      let x = poly_array[i]
      let y = poly_array[i + 1]
      anchor.style.left = (x - 5) + "px"
      anchor.style.top = (y - 5) + "px"
      console.log("#anchor anchor" + i / 2, " " + x + " " + y)
    }
  }
  setNewPoints(poly_array: number[]) {
    poly_array = this.parent_canvas.scalePoints(poly_array)
    let new_points = this.new_points
    for (let i = 0; i < new_points.length; i++) {
      let oImg = new_points[i]

      let x1 = poly_array[i * 2]
      let y1 = poly_array[i * 2 + 1]

      let i2 = i * 2 + 2
      if (i2 >= poly_array.length) {
        i2 = 0
      }
      let x2 = poly_array[i2]
      let y2 = poly_array[i2 + 1]

      let x = (x1 + x2) / 2
      let y = (y1 + y2) / 2

      oImg.style.left = (x - 5) + "px"
      oImg.style.top = (y - 5) + "px"
      console.log("#anchor new" + i / 2, " " + x + " " + y)
    }

  }
  // Moving and scaling
  //****************************************************************************
  // unselect() {
  //   console.log("#PL unselect")
  //   this.removeAnchors()
  //   this.bSelected = false
  // }

  select(selected_id: string) { //sublcassed
    this.removeAnchors()
    console.log("#PL select " + selected_id)
    // this.bSelected = true
    if (this.polygons) {
      this.selected_id = selected_id
      this.drawing_poly = this.polygons[selected_id]
      if (this.drawing_poly) {

        let k_type = this.drawing_poly.node.dataset['k_type']

        this.createAnchors(selected_id)
        if (k_type != "line") {
          this.createNewPoints(selected_id)
        }
        this.move_spoints = this.drawing_poly.attr('points')
        this.poly_array = this.getArrayFromStr(this.move_spoints)
        let box = this.getBoxFromArray(this.poly_array)
        // let bMakeNewPoint = true
        // if (this.drawing_poly.node.dataset['k_type'] == "line") {
        //   bMakeNewPoint = false
        // }
        // this.setBoxAnchors(selected_id,
        //   box.left, box.top, box.right, box.bottom, false, bMakeNewPoint)
        // console.log("#poly set box anchors" + this.x1 + " " + this.y1 + " " + this.x2 + " " + this.y2)
      } else {
        this.removeAnchors()
      }
    }
  }

  move_spoints: string = ""

  copy(selected_id: string) {
    let obj = this.polygons[selected_id]
    if (obj) {
      return obj.svg()
    }
    return undefined
  }
  erase(id: string) {
    let rv
    let obj = this.polygons[id]
    let k_type = obj.node.dataset['k_type']
    this.selected_id = ""
    if (obj) {
      rv = {
        undo: "erase",
        k_type: k_type,
        id: id,
        data: obj.svg()
      }
    }
    obj.remove()
    this.removeAnchors()
    delete this.polygons[id]
    this.canvas_service.sendCanvasMsg(k_type, "erase", id)
    return rv
  }

  moveObj(selected_id: string, x1: number, y1: number, x2: number, y2: number, data: any) {
    if (x1 > x2) {
      let t = x1
      x1 = x2
      x2 = t
    }
    if (y1 > y2) {
      let t = y1
      y1 = y2
      y2 = t
    }

    let w = x2 - x1
    let h = y2 - y1
    let left = x1;
    let top = y1;
    // console.log("#el1 " + left + " " + top + " " + w + " " + h)

    left = this.parent_canvas.deScaleX(left)
    w = this.parent_canvas.deScaleX(w)

    top = this.parent_canvas.deScaleY(top) //We might have to descale from the bottom
    h = this.parent_canvas.deScaleY(h)
    x1 = left
    x2 = left + w
    y1 = top
    y2 = top + h

    let orig_box = data.box
    let points = data.points
    let scale_x = (x2 - x1) / (orig_box.x2 - orig_box.x1)
    let scale_y = (y2 - y1) / (orig_box.y2 - orig_box.y1)

    let dx = orig_box.x1 - x1
    let dy = orig_box.y1 - y1
    // if (y1 > y2) {
    //   dy = orig_box.y1 - y2
    // }
    // if (x1 > x2) {
    //   dx = orig_box.x1 - x2
    // }
    let new_points: number[] = []
    for (let i = 0; i < points.length; i++) {
      let p = points[i]
      if (i % 2 == 0) { //X
        let px = p - orig_box.x1
        px *= scale_x
        p = px + orig_box.x1 - dx
      } else {
        let py = p - orig_box.y1
        py *= scale_y
        p = py + orig_box.y1 - dy
      }
      new_points.push(p)
    }


    console.log("#poly scale " + x1 + " " + y1 + " " + x2 + " " + y2)
    // let new_points = this.moveAndSize(this.move_spoints, x1, y1, x2, y2);
    let drawing_poly = this.polygons[selected_id]
    if (drawing_poly) {
      drawing_poly.attr({ points: new_points })
      this.setAnchorsPos(new_points)
      let svg = drawing_poly.svg()
      this.canvas_service.sendCanvasMsg("polygon", "move", selected_id, svg)
    }
  }

  addPolyPoint(obj: any) {

    let x = parseFloat(obj.style.left)
    let y = parseFloat(obj.style.top)

    let target = parseInt(obj.dataset['index'])
    let new_arr = []

    for (let i = 0; i < this.poly_array.length; i++) {

      if (target == (i / 2) - 1) {
        new_arr.push(x)
        new_arr.push(y)
      }
      new_arr.push(this.poly_array[i])

    }
    if ((target + 1) * 2 == this.poly_array.length) {
      new_arr.push(x)
      new_arr.push(y)
    }
    this.poly_array = new_arr
    //now insert a point betwen the 2 points
    let id = obj.dataset['id']
    let drawing_poly = this.polygons[id]
    drawing_poly.attr({ points: this.poly_array })
    this.removeAnchors()
    this.createAnchors(id)
    this.createNewPoints(id)

    let box = this.getBox(id)
    if (box) {
      this.parent_canvas.setBox(box.x1, box.y1, box.x2, box.y2)
    }
  }

  getBox(selected_id: string) { //subclassed for mullti select
    if (this.polygons) {
      let drawing_polygon = this.polygons[selected_id]
      if (drawing_polygon) {

        let attr = drawing_polygon.attr()//this.drawing_ellipse.attr({ cx: cx, cy: cy, rx: rx, ry: ry })
        let points = this.getArrayFromStr(attr.points)
        let k_type = drawing_polygon.node.dataset['k_type']
        let back: boolean = false;
        let new_point: boolean = true
        if (k_type == "line") {
          back = true;
          new_point = false
        }
        let bbox = drawing_polygon.bbox()
        let rv =
        {
          x1: bbox.x,
          y1: bbox.y,
          x2: bbox.x2,
          y2: bbox.y2,
          back: back, //make back div
          new_point: new_point, //new_point for polygon
          svg: drawing_polygon.svg(), //for the undo
          data: {
            box: {
              x1: bbox.x,
              y1: bbox.y,
              x2: bbox.x2,
              y2: bbox.y2
            }, points: points
          }
        }
        return rv
      }
    }
    return undefined
  }
  moveDelta(selected_id: string, delta_x: number, delta_y: number) {//subclassed for mullti select
    if (this.moving_anchor) {
      return
    }
    if (this.polygons) {
      let drawing_polygon = this.polygons[selected_id]
      if (drawing_polygon) {

        let poly_array = this.movePolyPoints(drawing_polygon, delta_x, delta_y)
        this.setAnchorsPos(poly_array)
        let bbox = drawing_polygon.bbox()
        this.parent_canvas.setBox(bbox.x, bbox.y, bbox.x2, bbox.y2)
      }
    }
  }

  setColor(id: string, color: string) { //subclased
    if (this.polygons) {
      let drawing_polygon = this.polygons[id]
      if (drawing_polygon) {

        drawing_polygon.fill(color).stroke({ width: this.line_width, color: this.stroke_color })
      }
    }
  }
  setPencilSize(id: string, size: number) {
    if (this.polygons) {
      let drawing_polygon = this.polygons[id]
      if (drawing_polygon) {

        drawing_polygon.stroke({ width: size })
      }
    }
  }

}
