import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { NotifyError } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { gotReps, gotUsers, myCompany } from 'src/app/pages/pages.selectors';
import { checkPhone, validateEmail } from 'src/app/utils';
import { KeyziiService } from 'src/app/wss/keyzii.service';

@Component({
  selector: 'add-user',
  templateUrl: './add-one-user.component.html',
  styleUrls: ['./add-one-user.component.scss']
})
export class AddOneUserComponent {
  // @Output() close: EventEmitter<void> = new EventEmitter();
  bEditEmail: boolean = true
  title: string = "Invite New User"
  btn_lbl: string = "Invite"
  _user: any
  bReady: boolean = false
  @Input() set user(u: any) {
    if (u && u.email) {
      this._user = u
      this.bEditEmail = false
      this.title = "Edit User"
      // this.send_back = "#bbb"
      this.btn_lbl = "Save"
      if (u.email) {
        this.email = u.email
      }
      if (u.name) {
        this.name = u.name
      }
      if (u.last_name) {
        this.last_name = u.last_name
      }
      if (u.phone) {
        this.phone = u.phone
      }
      if (u.roll) {
        if (u.roll == 'admin') {
          this.rol_index = 0
        } else if (u.roll == 'manager') {
          this.rol_index = 1
        }
      }
      //Now select the options for the filet
      this.user_types = Object.assign([], this.orig_user_types)
      let user_type = Object.assign({}, this.user_types[this.rol_index])
      user_type.selected = true
      this.user_types[this.rol_index] = user_type
      this.rol_desc = this.user_types[this.rol_index].desc
    }
    this.changedEmail(this.email)
  }

  orig_user_types = [
    { desc: 'Admin', val: "admin" },
    // { desc: 'Manager', val: "manager" },
    { desc: 'Agent', val: "rep" }
  ]
  rol_index: number = 1
  rol_desc: string = "Agent"
  user_types: any = {}
  bInvaildEmail: boolean = true
  // send_back: string = "#bbb"
  email: string = ""
  name: string = ""
  last_name: string = ""
  phone: string = ""
  company: string = ""
  users: any = {}
  subscriptions: any[] = []

  constructor(
    private keyzii_service: KeyziiService,
    private pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("add user")
    this.setSelUserType(1)

    this.subscriptions.push(this.pagesState.select(gotUsers).subscribe((users: any[]) => {
      this.users = users
    }))
    this.subscriptions.push(this.pagesState.select(myCompany).subscribe((company: string) => {
      if (company) {
        this.company = company
      }
    }))
    //  this.setUserRoll()
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  setSelUserType(sel: number) {
    this.user_types = Object.assign([], this.orig_user_types)
    let user_type = Object.assign({}, this.user_types[1])
    user_type.selected = true
    this.user_types[sel] = user_type
  }
  userRole($event: any[]) {
    for (let i = 0; i < $event.length; i++) {
      let opt = $event[i]
      if (opt.selected) {
        if (this.rol_index == 0 && i != 0) { //make sure you are not the last admin
          let num_admins = 0
          if (this.users) {
            let keys = Object.keys(this.users)
            for (let j = 0; j < keys.length; j++) {
              let key = keys[j]
              let user = this.users[key]
              if (user.roll == "admin") {
                num_admins++
              }
            }
          }
          if (num_admins < 2) {
            this.pagesState.dispatch(new NotifyError("You can not leave account without an admin"))
            this.rol_index = 0;
            return
          }
        }
        this.rol_index = i
      }
    }
    this.checkReady()
    // user.roll =
  }
  checkReady() {
    this.bReady = (this.name.length > 0 && this.last_name.length > 0)
    if (this.bReady && this.bEditEmail) {
      this.bReady = !this.bInvaildEmail
    }
  }
  changedName($event: any) {
    this.checkReady()
  }
  // changedPhone($event: any) {
  //   this.phone = checkPhone($event)
  //
  //   this.checkReady()
  // }
  changedEmail($event: any) {
    if ($event) {
      if (validateEmail($event)) {
        this.email = $event
        this.bInvaildEmail = false
      } else {
        this.bInvaildEmail = true
      }
      this.checkReady()
    }
  }
  updateUser() {
    if (this.bInvaildEmail == false) {
      let user = Object.assign({}, this._user)

      user.email = this.email
      user.name = this.name
      user.last_name = this.last_name

      user.phone = this.phone
      user.roll = this.orig_user_types[this.rol_index].val

      this.keyzii_service.editUser(user)
      // this.close.emit()
      this.canvasStore.dispatch(new SelectToolOpts(""))
    }
  }
  async invite() {
    if (!this.bReady) {
      return
    }
    if (!this.bEditEmail) {
      this.updateUser()
      return
    }

    let user = {
      email: this.email,
      name: this.name,
      last_name: this.last_name,
      company: this.company,
      phone: this.phone,
      roll: this.orig_user_types[this.rol_index].val
    }
    try {
      await this.keyzii_service.addUser(user)
    } catch (e: any) {
      // console.error(e)
      let err = "Error"
      if (e.error.error) {
        err = e.error.error
      } else if (e.error) {
        err = e.error
      }
      this.pagesState.dispatch(new NotifyError(err))
    }

    this.keyzii_service.getAccount()
    this.cancel()
  }
  cancel() {
    this.canvasStore.dispatch(new SelectToolOpts("close_add_user"))//  this.close.emit()
  }
}
