import { SVG } from '@svgdotjs/svg.js';
import { AddToUndo, SelectId } from '../canvas.actions';
import { Draw } from './draw';


export class kPencil extends Draw {

  scketches: any = {}
  bSelected: boolean = false;


  drawing_poly: any | undefined
  // polygon_id: string = ""
  poly_array: number[] = []


  move_index: number = -1

  bMoving: boolean = false
  pasteSVG(svg: any, x: number, y: number) {
    let drawing_poly = this.scketchFromSVG(svg)
    if (drawing_poly) {
      let id = drawing_poly.node.dataset['id']
      if (this.scketches[id]) { //change the id
        id = new Date().getTime().toString()
        drawing_poly.node.dataset['id'] = id
      }
      this.scketches[id] = drawing_poly

      this.moveDelta(id, 20, 20)
      let box: any = this.getBox(id)
      return { id: id, box: box, k_type: "pencil" }
    }
    return undefined
  }


  undo(msg: any) {
    let old = this.scketches[msg.id]
    if (old) {
      old.remove()
      delete this.scketches[msg.id]
    }
    if (msg.data) {
      let new_obj = SVG(msg.data)
      let vb
      if (msg.canvas_width) {
        vb = {
          width: msg.canvas_width,
          height: msg.canvas_height
        }
      }
      let drawing_poly = this.scketchFromSVG(new_obj, vb)
      let id = drawing_poly.node.dataset['id']
      this.scketches[id] = drawing_poly
    }

  }

  processMsg(msg: any) {
    this.undo(msg)
  }


  scketchFromSVG(poly: any, fromViewBox?: any) {
    let attr = poly.attr()
    let drawing_poly
    if (fromViewBox) {
      let points = this.getArrayFromStr(attr.points)
      let np = this.parent_canvas.scalePointsToViewBox(points, fromViewBox)
      let nattr = Object.assign({}, attr)
      nattr.points = np
      drawing_poly = this.draw_tool.polyline(nattr)
    } else {
      drawing_poly = this.draw_tool.polyline(attr)
    }
    let id = attr["data-id"]
    if (id) {
      this.scketches[id] = drawing_poly
    }

    return drawing_poly
  }

  //****************************************************************************
  // Parent events
  //****************************************************************************
  //called when the tool is polygon or polyfill
  onMouseDown($event: any, offsetX: number, offsetY: number) {
    if (!this.drawing_poly) {

      this.startX = offsetX;
      this.startY = offsetY
      let poly_array = [this.startX, this.startY, offsetX, offsetY]
      this.poly_array = this.parent_canvas.deScalePoints(poly_array)

      // console.log("scketch " + JSON.stringify(poly_array) + " " + JSON.stringify(this.poly_array))
      this.drawing_poly = this.draw_tool.polyline(this.poly_array).fill('none').stroke({ width: this.pencil_width, color: this.stroke_color, linecap: 'round' })
      this.pointer_id = $event.pointerId
      if (this.pointer_id != "remote") { //We dont do the capture because the other side is sending the move
        this.canvas.setPointerCapture(this.pointer_id)
      }
    }
  }


  onMouseMove($event: any) {
    if (this.drawing_poly) {
      if (this.bMoving) {
        let delta_x = $event.offsetX - this.startX
        let delta_y = $event.offsetY - this.startY
        let new_points: number[] = []
        for (let i = 0; i < this.poly_array.length; i++) {
          let point = this.poly_array[i]
          if (i % 2 == 0) {
            let x = this.parent_canvas.scaleX(point)
            let new_canvas_x = x + delta_x
            let new_x = this.parent_canvas.deScaleX(new_canvas_x)
            new_points.push(new_x)
            // console.log("scketch delta_x " + delta_x + " x " + x + " " + new_canvas_x + " " + new_x)
          } else {
            let y = this.parent_canvas.scaleY(point)
            let new_canvas_y = y + delta_y
            let new_y = this.parent_canvas.deScaleX(new_canvas_y)
            new_points.push(new_y)
            // console.log("scketch delta_y" + delta_y + " y " + y + " " + new_canvas_y + " " + new_y)
          }
        }
        this.drawing_poly.attr({ points: new_points })
        return
      }

      let dx = this.startX - $event.offsetX
      let dy = this.startY - $event.offsetY
      let d = Math.sqrt(dx * dx + dy * dy)
      if (d > 10) {
        let x = this.parent_canvas.deScaleX($event.offsetX)
        this.poly_array.push(x)
        let y = this.parent_canvas.deScaleY($event.offsetY)
        this.poly_array.push(y)
        // console.log("scketch offsetX " + $event.offsetX + " x " + x + " offsetY " + $event.offsetY + " y " + y)
        this.drawing_poly.attr({ points: this.poly_array })
      }
    }
  }

  onMouseUp($event: any) {

    if (this.drawing_poly) {

      let polygon_id = new Date().getTime().toString()
      console.log("poly new  " + polygon_id)
      this.drawing_poly.node.dataset['id'] = polygon_id
      this.drawing_poly.node.dataset['k_type'] = "pencil"


      this.scketches[polygon_id] = this.drawing_poly

      if (this.pointer_id == "remote") {
        setTimeout(() => {
          this.erase(polygon_id)
        }, 2000)
      } else {
        // this.doneWithPoly(this.drawing_poly)
        let svg = this.drawing_poly.svg()
        this.canvas_service.sendCanvasMsg("pencil", "new", polygon_id, svg)
        this.canvasStore.dispatch(new AddToUndo({
          undo: "new",
          k_type: "pencil",
          id: polygon_id,
        }))
        this.canvas.releasePointerCapture(this.pointer_id)
      }
      this.drawing_poly = undefined
    }

  }
  //****************************************************************************
  // Utils
  //****************************************************************************
  // doneWithPoly(drawing_poly: any) {
  //
  //
  //   drawing_poly.node.onmousemove = onPolyMove
  //   // this.drawing_poly.node.onmouseleave = onPolyLeave
  //   // this.drawing_poly.node.onpointerdown = onPolyDown
  //   // this.drawing_poly.node.onpointerup = onPolyUp
  //
  //
  //   // this.canvasStore.dispatch(new SetTool("none"))
  //   //**************************************************************************
  //   // plygon functions
  //   //**************************************************************************
  //   let me = this
  //   function onPolyMove(this: any, $event: any) {
  //
  //     $event.stopPropagation()
  //     let id = this.dataset['id']
  //     if (!me.bSelected) {
  //
  //       let k_type = this.dataset['k_type']
  //       let box = me.getBox(id)
  //       if (box) {
  //         me.parent_canvas.onHover(id, box, k_type)
  //       }
  //     }
  //
  //     // if (me.canvas) {    //was set on the canvas mouse down, beging
  //     //   if (me.sel_tool_name == 'erase') {
  //     //     me.canvas.style.cursor = 'url(assets/images/delete.svg) 0 14, pointer'
  //     //     return
  //     //   }
  //     //   if (me.sel_tool_name == 'none') {
  //     //     me.canvas.style.cursor = 'grab'
  //     //   }
  //     //   let scketch_id = this.dataset['id']
  //     //   let scketch = me.scketches[scketch_id]
  //     //   let spoints = scketch.attr('points')
  //     //   let array = me.getArrayFromStr(spoints)
  //     //   let box = me.getBoxFromArray(array)
  //     //   me.onHover(scketch_id, box, "pencil")
  //     // }
  //   }
  //   function xonPolyLeave($event: any) {
  //     if (me.canvas) {
  //       me.canvas.style.cursor = 'auto'
  //     }
  //   }
  //
  //   function xonPolyUp(this: any, $event: any) {
  //     console.log("#dr poly up ")
  //     this.onpointermove = null;
  //     if (me.pointer_id) {
  //       this.releasePointerCapture(me.pointer_id)
  //       me.pointer_id = undefined
  //     }
  //   }
  // }


  //****************************************************************************
  // Moving and scaling
  //****************************************************************************
  unselect() {
    console.log("#PL unselect")
    this.bSelected = false
  }
  select(selected_id: string) { //sublcassed
    this.bSelected = false
    // if (this.scketches) {
    //   this.drawing_poly = this.scketches[selected_id]
    //   if (this.drawing_poly) {
    //     this.move_spoints = this.drawing_poly.attr('points')
    //     let array = this.getArrayFromStr(this.move_spoints)
    //     let box = this.getBoxFromArray(array)
    //     this.setBoxAnchors(selected_id,
    //       box.left,
    //       box.top,
    //       box.right,
    //       box.bottom,
    //       true
    //     )
    //   }
    // }
  }
  move_spoints: string = ""
  cprepareMove(polygon_id: string, $event?: any) { //use this to get your moving strategy
    this.drawing_poly = this.scketches[polygon_id]
    if (this.drawing_poly) {
      this.move_spoints = this.drawing_poly.attr('points')
      this.poly_array = this.getArrayFromStr(this.move_spoints)
      this.move = "frame"
      let box = this.getBoxFromArray(this.poly_array)
      this.setBox(box.left, box.top, box.right, box.bottom)

    }

  }
  copy(selected_id: string) {
    let obj = this.scketches[selected_id]
    if (obj) {
      return obj.svg()
    }
    return undefined
  }
  erase(id: string) {
    let rv
    let obj = this.scketches[id]
    if (obj) {
      rv = {
        undo: "erase",
        k_type: "pencil",
        id: id,
        data: obj.svg()
      }
    }
    obj.remove()
    this.canvas_service.sendCanvasMsg("pencil", "erase", id)
    return rv
  }

  moveObj(selected_id: string, x1: number, y1: number, x2: number, y2: number, data: any) {
    if (x1 > x2) {
      let t = x1
      x1 = x2
      x2 = t
    }
    if (y1 > y2) {
      let t = y1
      y1 = y2
      y2 = t
    }

    let w = x2 - x1
    let h = y2 - y1
    let left = x1;
    let top = y1;
    // console.log("#el1 " + left + " " + top + " " + w + " " + h)

    left = this.parent_canvas.deScaleX(left)
    w = this.parent_canvas.deScaleX(w)

    top = this.parent_canvas.deScaleY(top) //We might have to descale from the bottom
    h = this.parent_canvas.deScaleY(h)
    x1 = left
    x2 = left + w
    y1 = top
    y2 = top + h
    let orig_box = data.box
    let points = data.points
    let scale_x = (x2 - x1) / (orig_box.x2 - orig_box.x1)
    let scale_y = (y2 - y1) / (orig_box.y2 - orig_box.y1)

    let dx = orig_box.x1 - x1
    let dy = orig_box.y1 - y1

    let new_points: number[] = []
    for (let i = 0; i < points.length; i++) {
      let p = points[i]
      if (i % 2 == 0) { //X
        let px = p - orig_box.x1
        px *= scale_x
        p = px + orig_box.x1 - dx
      } else {
        let py = p - orig_box.y1
        py *= scale_y
        p = py + orig_box.y1 - dy
      }
      new_points.push(p)
    }


    console.log("#poly scale " + x1 + " " + y1 + " " + x2 + " " + y2)
    // let new_points = this.moveAndSize(this.move_spoints, x1, y1, x2, y2);
    let drawing_poly = this.scketches[selected_id]
    if (drawing_poly) {
      drawing_poly.attr({ points: new_points })
      // let svg = drawing_poly.svg()
    }

  }
  getBox(polygon_id: string) { //subclassed for mullti select
    let drawing_poly = this.scketches[polygon_id]
    if (drawing_poly) {
      let move_spoints = drawing_poly.attr('points')
      if (move_spoints) {
        let poly_array = this.getArrayFromStr(move_spoints)
        let box = this.getBoxFromArray(poly_array)
        if (box.left == 10000) {
          console.log("err 1000")
        }
        return {
          x1: box.left,
          y1: box.top,
          x2: box.right,
          y2: box.bottom,
          back: true, //make back div
          new_point: false, //new_point for polygon
          svg: drawing_poly.svg(), //for the undo
          data: {
            box: {
              x1: box.left,
              y1: box.top,
              x2: box.right,
              y2: box.bottom,
            }, points: poly_array
          }
        }
      }
    }
    return undefined
  }
  moveDelta(polygon_id: string, delta_x: number, delta_y: number) {//subclassed for mullti select
    let drawing_poly = this.findGroupById(polygon_id)
    this.movePolyPoints(drawing_poly, delta_x, delta_y)
  }
  setColor(id: string, color: string) { //subclased
    let drawing_poly = this.findGroupById(id)
    if (drawing_poly) {
      drawing_poly.stroke({ color: color })
    }
  }
  setPencilSize(id: string, size: number) {
    let drawing_poly = this.findGroupById(id)
    if (drawing_poly) {
      // this.pencil_width = size
      drawing_poly.stroke({ width: this.pencil_width })
    }
  }
}
