import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { copyTo, signers } from '../../canvas.selectors';
import { CanvasService } from '../../canvas.service';
import { validateEmail } from 'src/app/utils';
import { SetCopyTo, SetSigners } from '../../canvas.actions';

@Component({
  selector: 'email-contract',
  templateUrl: './email-contract.component.html',
  styleUrls: ['./email-contract.component.scss']
})
export class EmailContractComponent {

  copy_lbl: string = "Send Copy to:"
  signers: any
  signers_keys: string[] = []
  invaild_emails: any = {}


  copy_to: any[] = []
  max_height: number = 300;

  send_back_color: string = "#d9d9d9"
  subscriptions: any[] = []
  @Output() close: EventEmitter<any> = new EventEmitter();
  constructor(
    public canvasStore: Store<CanvasState>,
    // public wssState: Store<WSSState>,
    // public wss_service: WSSService,
    public canvas_service: CanvasService,
  ) {
    console.log("email wnd")
    this.subscriptions.push(this.canvasStore.select(copyTo).subscribe((copy_to: any[]) => {
      this.copy_to = copy_to
    }))
    this.subscriptions.push(this.canvasStore.select(signers).subscribe((signers: any) => {
      this.signers = signers
      this.signers_keys = []
      let signers_keys = Object.keys(this.signers)
      let num_emails = 0
      for (let i = 0; i < signers_keys.length; i++) {
        let key = signers_keys[i]
        let signer = this.signers[key]
        if (signer.email) {
          num_emails++
        }
        this.signers_keys.push(key)
      }
      if (this.signers_keys.length == num_emails) {
        this.send_back_color = "#151515"

      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) { //aspect ratio is 1280, 720
    setTimeout(() => { this.max_height = window.innerHeight - 350 })
  }
  ngAfterViewInit() {
    setTimeout(() => { this.max_height = window.innerHeight - 350 })
  }
  changedEmail($event: any, key: string) {
    if ($event) {
      if (validateEmail($event)) {
        this.invaild_emails[key] = false
        this.changeSigner(key, "email", $event)
      } else {
        this.invaild_emails[key] = true
      }
    }
  }
  changedName($event: any, key: string) {
    if ($event) {
      this.changeSigner(key, "name", $event)
    }
  }
  changedTitle($event: any, key: string) {
    if ($event) {
      this.changeSigner(key, "title", $event)
    }
  }
  changeSigner(signer_key: string, key: string, value: any) {
    let signers: any = Object.assign({}, this.signers)
    let signer: any = Object.assign({}, signers[signer_key])
    signer[key] = value
    signers[signer_key] = signer
    this.signers = signers
    this.canvasStore.dispatch(new SetSigners(this.signers))
  }
  async emailContract() {
    try {
      this.canvas_service.sendPDFEmails()
      this.close.emit()
    } catch (e) {
      console.error(e)
    }
  }

  cancel() {
    this.close.emit()
  }
  newContact() {
    let copy_to = Object.assign([], this.copy_to)
    copy_to.push({ email: "", name: "" })
    this.copy_to = copy_to
  }
  changedCopyEmail($event: any, i: number) {
    if ($event) {
      if (validateEmail($event)) {
        this.invaild_emails[i] = false
      } else {
        this.invaild_emails[i] = true
      }
    }

  }
  onDoneWithEmail($event: any, i: number) {
    let copy_to: any[] = Object.assign([], this.copy_to)
    let contact: any = Object.assign({}, copy_to[i])
    contact.email = $event.srcElement.value;
    copy_to[i] = contact
    this.copy_to = copy_to
    this.canvasStore.dispatch(new SetCopyTo(this.copy_to))

  }
  onDoneWithName($event: any, i: number) {
    let copy_to: any[] = Object.assign([], this.copy_to)
    let contact: any = Object.assign({}, copy_to[i])
    contact.name = $event.srcElement.value;
    copy_to[i] = contact
    this.copy_to = copy_to
    this.canvasStore.dispatch(new SetCopyTo(this.copy_to))

  }
  noName(key: string) {
    let signer = this.signers[key]
    if (signer) {
      if (signer.name) {
        return signer.name.length > 0
      }
    }
    return false;
  }
  noEmail(key: string) {
    let signer = this.signers[key]
    if (signer) {
      if (signer.email) {
        return signer.email.length > 0
      }
    }
    return false;
  }
}
