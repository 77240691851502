import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { PagesState } from '../pages/pages.model';
import { hostUrl, showAsset } from '../pages/pages.selectors';
import { WSSState } from '../wss/wss.model';
import { customerId, sessionId } from '../wss/wss.selectors';
@Injectable({
  providedIn: 'root'
})
export class CanvasUploaderService {

  host_url: string | undefined;
  customer_id: string | undefined;
  session_id: string | undefined;


  public onProgres: Function | undefined
  public onFileUploaded: Function | undefined
  asset: any
  constructor(
    private pagesState: Store<PagesState>,
    private wssState: Store<WSSState>,

  ) {
    console.log("uploader serivice")
    this.pagesState.select(hostUrl).subscribe((host_url: string | undefined) => {
      this.host_url = host_url
    })


    this.wssState.select(sessionId).subscribe((session_id: string | undefined) => {
      this.session_id = session_id
    })
    this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      this.customer_id = customer_id
    })
  }

  getUploaderBody(type: string) {
    console.log("uploaded service")
    let protocol: string = "https://"
    let host = localStorage.getItem('host')
    if (!host && this.host_url) {
      host = this.host_url
    }
    if (host) {
      if (host.startsWith("local")) {
        protocol = location.protocol + "//"
      }
    }

    let url = protocol + host + "/api/return_img/"
    let token = 'Bearer ' + this.session_id
    return {
      url: url,
      itemAlias: type,
      authToken: token
    };
  }
  /*
  authToken: "Bearer 1341a3cd-b08a-420f-9d2c-bfe0c672713f"
  itemAlias: "image"
  url: "http://local.callvendi.com/api/image/user"

  */
  setUpUploader(aset_type: string): FileUploader {

    let body = this.getUploaderBody(aset_type);
    let uploader: FileUploader = new FileUploader(body)
    // uploader.onProgressItem = (progress: any) => {
    //   if (this.onProgres) {
    //     this.onProgres(progress)
    //   }
    // };


    return uploader;
  }

}
