<div class="out">
  <k-button *ngIf="!bShowChat" class="chat" icon="chat.svg"  kzLib (click)="openChat()"></k-button>
  <k-button *ngIf="bShowChat" class="chat" icon="arrow_down_white.svg"  kzLib (click)="closeChat()"></k-button>
  <status *ngIf="bShowChatNotification" class="chat-noti"></status>

  <div class="btns">
    <k-button *ngIf="bAudioOn" class="btn" icon="mic.svg"  kzLib (click)="setAudio(false)"></k-button>
    <k-button *ngIf="!bAudioOn" background="#F5423B" class="btn" icon="mic.svg"  kzLib (click)="setAudio(true)"></k-button>

    <k-button class="btn" icon="profile_white.svg"  kzLib (click)="askEmail()"></k-button>

    <k-button *ngIf="bVideoOn" class="btn" icon="camera_white.svg"  kzLib (click)="setVideo(false)"></k-button>
    <k-button *ngIf="!bVideoOn" class="btn" icon="camera_white.svg" background="#F5423B"  kzLib (click)="setVideo(true)"></k-button>



    <k-button class="btn" icon="settings.svg" cmd="settings"></k-button>

    <status class="have_caller_info" [ok]="contactInfo"></status>
  </div>

</div>