import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';
import { showAsset } from 'src/app/pages/pages.selectors';
import { b64toBlob } from 'src/app/utils';
import { DidStep, GoToStep, SelectToolOpts } from '../../canvas.actions';
import { bioResults, bioResultsError, progress, selSignerKey, signerEmail, signerLic, signerName, signerPic, signersWithSteps } from '../../canvas.selectors';
import { CanvasService } from '../../canvas.service';


@Component({
  selector: 'bio-results',
  templateUrl: './bio-results.component.html',
  styleUrls: ['./bio-results.component.scss']
})
export class BioResultsComponent {
  pic: string | undefined
  lic: string | undefined
  contract_id: string | undefined
  signer_name: string | undefined
  signer_email: string | undefined

  signers: any | undefined
  results: any
  progress: number = 50
  next_text: string = "Next"
  bShowSpinner: boolean = false
  bError: boolean = false

  subscriptions: any[] = []
  signer_key: string | undefined

  constructor(
    public canvas_service: CanvasService,
    public canvasStore: Store<CanvasState>,
    private pagesState: Store<PagesState>,
  ) {
    console.log("Biometric")
    this.subscriptions.push(this.canvasStore.select(selSignerKey).subscribe((signer_key: string | undefined) => {
      this.signer_key = signer_key
    }))

    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        if (asset.type == "contract") {
          this.contract_id = asset._id
          if (!this.contract_id) { //in live call
            this.contract_id = new Date().getTime().toString()
          }
        }
      }
      // setTimeout(() => {
      //   if (!this.bShowSpinner) {
      //     this.canvasStore.dispatch(new DidStep(""))
      //   }
      // }, 230)
    }))
    this.subscriptions.push(this.canvasStore.select(signerPic).subscribe(async (pic: string | undefined) => {
      this.pic = pic
      this.recognize()
    }))
    this.subscriptions.push(this.canvasStore.select(signerLic).subscribe(async (lic: any | undefined) => {
      if (lic) {
        console.log("Set lic " + lic.length + " " + new Date())
        this.lic = lic.src
        this.recognize()
      }
      else {
        console.log("Unset lic " + new Date())
        this.lic = undefined
      }
    }))
    this.subscriptions.push(this.canvasStore.select(signersWithSteps).subscribe(async (signers: any) => {
      this.signers = signers
      this.recognize()
    }))
    /*
    Errors are proces by canvas service and canvas reducer
    */
    this.subscriptions.push(this.canvasStore.select(bioResultsError).subscribe(async (error: any) => {
      if (error) {
        this.bError = true
        delete this.pic
        delete this.lic
        this.bShowSpinner = false;
      }
    }))

    this.subscriptions.push(this.canvasStore.select(bioResults).subscribe(async (results: any) => {
      if (results) {
        setTimeout(() => {
          this.bError = false
          this.results = results
          this.bShowSpinner = false;
        })
      }
    }))

    this.subscriptions.push(this.canvasStore.select(progress).subscribe((progress: any) => {
      this.progress = progress.progress
      // if (this.progress == 100) {
      //   this.next_text = "Done"
      // }
    }))
    this.subscriptions.push(this.canvasStore.select(signerName).subscribe((name: string | undefined) => {
      this.signer_name = name
    }))
    this.subscriptions.push(this.canvasStore.select(signerEmail).subscribe((email: string | undefined) => {
      this.signer_email = email
    }))

    // setTimeout(() => {
    //   let rv = { "similar": 0.1, "compared_at": "2023-06-29T00:30:36.352Z", "lic_text": "California USA DRIVER LICENSE DL F1237185 CLASS с EXP 04/16/2024 END NONE LN FERNANDEZ OROZCO FN SERGIO ANTONIO 700 WEST E ST 2101 SAN DIEGO, CA 92101 DOB 04/16/1959 RSTR CORR LENS 04161959 SONCH EYES BLK SEX: M HAIR BLK Age WGT 175 lb HGT 5'-09\" OPEN ISS 12/27/2018 DD 12/27/201850639/AAFD/24", "lic_scores": { "names": [{ "name": "Buyer", "score": 28.57142857142857 }], "email": 100, "name": 14.285714285714285, "lic_text": "California USA DRIVER LICENSE DL F1237185 CLASS с EXP 04/16/2024 END NONE LN FERNANDEZ OROZCO FN SERGIO ANTONIO 700 WEST E ST 2101 SAN DIEGO, CA 92101 DOB 04/16/1959 RSTR CORR LENS 04161959 SONCH EYES BLK SEX: M HAIR BLK Age WGT 175 lb HGT 5'-09\" OPEN ISS 12/27/2018 DD 12/27/201850639/AAFD/24" }, "url": { "exp": 1688002176353, "url": "https://keyzii-test.s3.us-west-1.amazonaws.com/649ccf9c6468c4788c34866f/rep_lic.png?AWSAccessKeyId=AKIA4UNR24CVC42GBRFX&Expires=1688002236&Signature=a1B6HbL2TAyErFVk%2F6OWT3mYO5g%3D" } }
    //   this.canvasStore.dispatch(new BioResults(rv))
    // }, 2000)

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  bWaitingForCompare: boolean = false
  async recognize() {
    if (this.results) {
      return
    }


    if (this.pic && this.lic && this.contract_id && this.signers) {

      if (this.bWaitingForCompare) {

        return
      }
      this.bWaitingForCompare = true;
      this.bShowSpinner = true;
      try {
        let blob = b64toBlob(this.pic)
        await this.canvas_service.saveFileForRecognition(this.contract_id, "face", blob)

        blob = b64toBlob(this.lic)
        await this.canvas_service.saveFileForRecognition(this.contract_id, "lic", blob)
        this.bWaitingForCompare = false;
      } catch (e) {
        this.bWaitingForCompare = false;
        console.error(e)
        // this.bError = true;
        // // this.error = "Error comparing documents please retake the pictures"
        // this.bShowSpinner = false;
      }
    }
  }
  cancel() {
    this.canvasStore.dispatch(new DidStep(""))
  }
  done() {
    this.canvasStore.dispatch(new DidStep("image_rec"))
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
  retry() {
    this.bError = false;
    if (this.signer_key && this.signers) {
      let signer = this.signers[this.signer_key]
      if (signer) {
        let steps = signer.steps
        if (steps) {
          let step = steps["take_pic"]
          if (step) {
            this.canvasStore.dispatch(new GoToStep(step))
          }
        }
      }
    }
  }

}
