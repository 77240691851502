import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';


import { CopyLinksComponent } from './copy-links/copy-links.component';
import { SelColorComponent } from './sel-color/sel-color.component';
import { KInputComponent } from './input/input.component';
import { FormsModule } from '@angular/forms';

import * as fromControls from './controls.reducer';
import { SearchComponent } from './search/search.component';
import { FilterComponent } from './filter/filter.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { SelectComponent } from './select/select.component';
import { PhoneComponent } from './phone/phone.component';
import { RecordingComponent } from './recording/recording.component';
import { StatusComponent } from './status/status.component';
import { MemoComponent } from './memo/memo.component';
import { KButtonComponent } from './k-button/k-button.component';
import { SwitchComponent } from './switch/switch.component';
import { CircleComponent } from './circle/circle.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PhoneHangUpComponent } from './phone-hang-up/phone-hang-up.component';
import { PhoneAnswerComponent } from './phone-answer/phone-answer.component';
import { KspinnerComponent } from './kspinner/kspinner.component';
import { CheckComponent } from './check/check.component';
import { MoreOrLessComponent } from './more-or-less/more-or-less.component';
import { ColorPickerModule } from 'ngx-color-picker';
// import { FeedBackComponent } from './feed-back/feed-back.component';
import { ProgresHComponent } from './progres-h/progres-h.component';
import { SelectBoxComponent } from './select-box/select-box.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { SelectIconComponent } from './select-icon/select-icon.component';
import { CallsWaitingComponent } from './calls-waiting/calls-waiting.component';
import { MonoComponent } from './mono/mono.component';
import { CopyLinkOptComponent } from './copy-link-opt/copy-link-opt.component';
import { KIconsComponent } from './k-icons/k-icons.component';
import { CalendarIconComponent } from './calendar/calendar.component';
import { SliderComponent } from './slider/slider.component';
import { SaveAsComponent } from './save-as/save-as.component';
import { CloseAndRenameComponent } from './close-and-rename/close-and-rename.component';
import { PathTitleComponent } from './path-title/path-title.component';
import { KzLibModule } from '../kz-lib/kz-lib.module';
@NgModule({
  declarations: [
    CopyLinksComponent,
    SwitchComponent,
    CircleComponent,
    SelColorComponent,
    KInputComponent,
    SearchComponent,
    FilterComponent,
    PaginatorComponent,
    SelectComponent,
    SelectBoxComponent,
    PhoneComponent,
    RecordingComponent,
    StatusComponent,
    MemoComponent,
    KButtonComponent,
    PhoneAnswerComponent,
    PhoneHangUpComponent,
    KspinnerComponent,
    CheckComponent,
    MoreOrLessComponent,
    // FeedBackComponent,
    ProgresHComponent,
    ScheduleComponent,
    SelectIconComponent,
    CallsWaitingComponent,
    MonoComponent,
    CopyLinkOptComponent,
    KIconsComponent,
    CalendarIconComponent,
    SliderComponent,
    SaveAsComponent,
    CloseAndRenameComponent,
    PathTitleComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ClipboardModule,
    StoreModule.forFeature('kcontrols', fromControls.controlsReducer),
    ColorPickerModule,
    KzLibModule,
  ],
  exports: [
    CopyLinksComponent,
    KButtonComponent,

    SwitchComponent,
    CircleComponent,
    KInputComponent,
    SelColorComponent,
    SearchComponent,
    FilterComponent,
    PaginatorComponent,
    SelectComponent,
    SelectBoxComponent,
    RecordingComponent,
    StatusComponent,
    MemoComponent,
    KButtonComponent,
    PhoneAnswerComponent,
    PhoneHangUpComponent,
    KspinnerComponent,
    CheckComponent,
    MoreOrLessComponent,
    // FeedBackComponent,
    ProgresHComponent,
    ScheduleComponent,
    SelectIconComponent,
    CallsWaitingComponent,
    MonoComponent,
    CopyLinkOptComponent,
    KIconsComponent,
    CalendarIconComponent,
    SliderComponent,
    SaveAsComponent,
    CloseAndRenameComponent,
    PathTitleComponent,

  ]
})
export class ControlsModule { }
