// src/app/auth-config.ts
import { Configuration, LogLevel } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: '67617f55-770c-437f-8b5c-bcba36ad1727',
    authority: 'https://login.microsoftonline.com/daf8b055-56a1-406d-9dc5-a7f849d99f0c',
    redirectUri: 'https://21.venditio.us/api/ms_login'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE(), // set to true for IE 11
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
};

function isIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const trident = ua.indexOf('Trident/') > -1;
  return msie || trident;
}
