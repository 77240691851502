import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { StandardFonts } from 'pdf-lib';
import { SetFont } from '../../canvas.actions';
import { fieldTextFont, fieldTextSize, fieldTextWeight, textFont, textSize, textWeight } from '../../canvas.selectors';

@Component({
  selector: 'text-tool',
  templateUrl: './text-tools.component.html',
  styleUrls: ['./text-tools.component.scss']
})
export class TextToolsComponent {
  selecting = "font"
  weight: string = "Regular"

  weight_options: string[] = ["Regular", "Bold"]
  size: number = 12
  font = ""
  font_options: string[] = []
  sel_index: number = -1
  options: string[] = []
  subscriptions: any[] = []
  @Input() field_text: boolean = false
  constructor(
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("text-tool")


  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    this.processChange()

  }
  setFontIndex() {
    if (this.font_options.length > 0) {
      for (let i = 0; i < this.font_options.length; i++) {
        if (this.font_options[i] == this.font) {
          this.sel_index = i;
          break;
        }
      }
    }
  }
  ngAfterViewInit() {
    if (this.field_text) {
      this.canvasStore.select(fieldTextWeight).subscribe((text_weight: string) => {
        this.weight = text_weight
      })

      this.canvasStore.select(fieldTextSize).subscribe((text_size: string) => {
        setTimeout(() => { this.size = parseInt(text_size) })
      })

      this.canvasStore.select(fieldTextFont).subscribe((text_font: string) => {
        setTimeout(() => {
          this.font = text_font
          this.setFontIndex()
        })
      })
    } else {
      this.subscriptions.push(this.canvasStore.select(textFont).subscribe((text_font: string) => {
        setTimeout(() => {
          this.font = text_font
          this.setFontIndex()
        })
      }))
      this.subscriptions.push(this.canvasStore.select(textWeight).subscribe((text_weight: string) => {
        this.weight = text_weight

      }))
      this.subscriptions.push(this.canvasStore.select(textSize).subscribe((text_size: string) => {
        setTimeout(() => { this.size = parseInt(text_size) })
      }))
    }
    this.font_options = []

    this.font_options.push("Cookie")
    this.font_options.push("Kalam")
    this.font_options.push("Satisfy")
    this.font_options.push("OpenSans")
    this.font_options.push("OpenSansBold")
    let fonts: any = StandardFonts
    console.log("text-tool")
    let keys = Object.keys(fonts)

    keys.forEach(key => {
      this.font_options.push(fonts[key])
    })


    this.setFontIndex()
    this.setOptions()
  }

  getSel(options: any) {
    for (let i = 0; i < options.length; i++) {
      let opt = options[i]
      if (opt.selected) {
        return opt.desc
      }
    }
    options[0].desc
  }

  setOptions() {
    setTimeout(() => {
      if (this.selecting == 'font') {
        this.options = this.font_options
      } else if (this.selecting == 'weight') {
        this.options = this.weight_options
      }
    })
  }
  selectedWeight($event: any) {
    setTimeout(() => {
      this.weight = this.getSel($event)
      this.processChange()
    })
  }
  selectedSize($event: any) {
    setTimeout(() => {
      setTimeout(() => { this.size = this.getSel($event) })
      this.processChange()
    })
  }
  selectedFont(sel_index: number) {
    setTimeout(() => {
      this.sel_index = sel_index
      // this.font = this.getSel($event)
      if (this.selecting == 'font') {
        this.font = this.font_options[this.sel_index]
      } else if (this.selecting == 'weight') {
        this.weight = this.weight_options[this.sel_index]
      }
      this.processChange()
    })
  }
  toogleWeight($event: any) {
    $event.stopPropagation()
    if (this.selecting == 'weight') {
      this.selecting = 'font'
    } else {
      this.selecting = 'weight'
    }
  }
  changedSize($event: any) {
    this.processChange()
  }
  processChange() {
    if (this.size > 0) {
      this.canvasStore.dispatch(new SetFont(this.font, this.weight, this.size + "px", this.field_text))
    }
  }
}
