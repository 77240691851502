import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { audioOn, videoOn } from 'src/app/k-video/k-video.selectors';
import { fomatTime } from 'src/app/utils';
import { KeyziiService } from 'src/app/wss/keyzii.service';
import { WSSState } from 'src/app/wss/wss.model';
import { myId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { IAmManager } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { activeCalls, myName } from '../../pages.selectors';

@Component({
  selector: 'call-active',
  templateUrl: './active.component.html',
  styleUrls: ['../../contacts/leads/leads.component.scss', './active.component.scss']
})
export class ActiveComponent {
  calls: any[] = []
  interval: any
  bVideoOn: boolean = false
  bAudioOn: boolean = false
  my_name: string | undefined
  rep_id: string | undefined
  subscriptions: any[] = []
  search: string = ""

  sel_index: number = 1;
  count: number = 0
  in_page: number = 1
  page_size: number = 10
  num_pages: number = 10
  @ViewChild('content') private content: ElementRef | undefined;

  constructor(
    private pagesState: Store<PagesState>,
    private keyzii_service: KeyziiService,
    private wss_service: WSSService,
    private kVideoState: Store<KVideoState>,
    private wssState: Store<WSSState>,
  ) {
    console.log("active calls")

    this.subscriptions.push(this.wssState.select(myId).subscribe(async (id: string | undefined) => {
      this.rep_id = id
    }))
    this.subscriptions.push(this.kVideoState.select(videoOn).subscribe((on: boolean) => {
      this.bVideoOn = on
    }))
    this.subscriptions.push(this.kVideoState.select(audioOn).subscribe((on: boolean) => {
      this.bAudioOn = on
    }))
    this.subscriptions.push(this.pagesState.select(activeCalls).subscribe((calls: any[]) => {
      this.calls = calls

    }))
    this.subscriptions.push(this.pagesState.select(myName).subscribe((name: string) => {
      this.my_name = name
    }))
    this.getCalls()


    this.interval = setInterval(() => this.setDuration(), 1000)
    this.setDuration()
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    clearInterval(this.interval)
  }
  async getCalls() {
    try {
      let page_size = 10
      if (this.content) {
        let h = this.content.nativeElement.clientHeight
        if (h) {
          page_size = Math.floor(h / 60);
          setTimeout(() => {
            this.page_size = page_size
          }, 1)
        }
      }

      let rv = await this.keyzii_service.getActiveCalls(this.in_page, page_size, this.sel_index, this.search)
      this.calls = rv.calls
      this.count = rv.count
      this.num_pages = Math.ceil(this.count / page_size)
    } catch (e) {
      console.error(e)
    }
  }
  setDuration() {
    let now = new Date().getTime()
    let calls: any[] = Object.assign([], this.calls)
    for (let i = 0; i < calls.length; i++) {
      let call: any = Object.assign({}, calls[i])
      call.duration = fomatTime(now - call.started_at)
      calls[i] = call
    }
    this.calls = calls
  }
  monitorCall(call: any) {
    let msg = {
      monitor: call.call_id,
      manager_video: this.bVideoOn,
      manager_audio: this.bAudioOn,
      manager_name: this.my_name,
      spy: true
    }
    this.pagesState.dispatch(new IAmManager(true))

    this.wss_service.sendMessageToMembersAndMe(msg)
  }
  joinCall(call: any) {
    let msg = {
      monitor: call.call_id,
      manager_video: this.bVideoOn,
      manager_audio: this.bAudioOn,
      manager_name: this.my_name,
      spy: false
    }
    this.pagesState.dispatch(new IAmManager(true))

    this.wss_service.sendMessageToMembersAndMe(msg)
  }
  changedSearch() { //filter by name
    console.log("filter by name")
  }
  selPage($event: any) {
    this.in_page = $event
    this.getCalls()
  }
}
