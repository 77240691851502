<div class="out">


  <div class="title">
    Account Details
  </div>
  <div class="line ">
    <span class="users"> Minumun number of users</span>
    <input kzInput class="in" type="text" name="" value="" [(ngModel)]="num_users">
  </div>
  <div class="line ul">
    Amount/User
    <div class="filler"></div>
    <input kzInput class="in" type="text" name="" value="" [(ngModel)]="user_price" (ngModelChange)="changedPrice($event)">
  </div>
  <div class="on_board">
    <div class="ont">
      On Board type
    </div>

    <div class="line ob_name">
      <img class="prev" src="assets/images/next.svg" alt="" kzLib (click)="nextOnBoardOpt()">
      <div class="ont ont1"> {{on_board[on_board_index].name}}</div>
      <img class="next" src="assets/images/next.svg" alt="" kzLib (click)="prevOnBoardOpt()">
      <!-- <div class="next"  kzLib (click)="nextOnBoardOpt()">
        Next
      </div>
      <div class="prev"  kzLib (click)="nextOnBoardOpt()">
        Next
      </div> -->

    </div>
    <div class="ont ond">
      {{on_board[on_board_index].desc}}
    </div>
    <div *ngIf="on_board_index>0" class="ont1">
      Whitelist URL
    </div>
    <input *ngIf="on_board_index>0" class="in url" type="text" placeholder="Insert URL" name="" value="" [(ngModel)]="url">

    <div *ngIf="on_board_index>0" class="send add" (click)="addUrl()">
      <div class="abs_center">
        Add URL
      </div>
    </div>
    <div *ngIf="on_board_index>0" class="ont1">
      Whitelist URL's:
    </div>
    <div *ngIf="on_board_index>0" class="urlb">
      <div class="line lurl2" *ngFor="let url of urls; let i = index">
        {{url}} <img class="del" src="assets/images/delete.svg" alt="" kzLib (click)="delUrl(i)">
      </div>
    </div>
  </div>

  <!-- <div class="line lurl" *ngIf="on_board_index>0">

    </div>
  </div> -->


  <div class="line">

  </div>
  <!-- <div class="line sw_li">
    iFrame Pro<div class="filler"></div>
    <switch class="switch" [(checked)]="bIFrame" (checkedChange)="setIframe($event)"></switch>
  </div>
  <div class="line sw_li1">
    Onboarding Pro<div class="filler"></div>
    <switch class="switch" [(checked)]="bOnBoard" (checkedChange)="setOnBoard($event)"></switch>
  </div> -->
  <div class="send" kzLib (click)="sendPlan()">
    <div class="abs_center btn">
      Done
    </div>
  </div>
  <img src="assets/images/close.svg" class="close" kzLib (click)="closeIt()">

</div>
