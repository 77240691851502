import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { WSSState } from '../wss/wss.model';
import { callId, customerId, lastMsg, myId } from '../wss/wss.selectors';
import { PagesState } from '../pages/pages.model';
import { apiParams, callerEmail, gotAssets, saveAssets } from '../pages/pages.selectors';
import { AddAsset, ApiUrlPArams, NotifyUser, SetAssets, SetAssetsAndContracts, ShowAsset, ShowFolder } from '../pages/pages.actions';

@Injectable({
  providedIn: 'root'
})
export class ToolService {
  customer_id: string | undefined
  my_id: string | undefined
  assets: any = {}
  assets_and_contracts: any = {}
  s3_dic: any
  remove_keys: string[] = []
  contracts: any[] = []
  //asset and signers are used to save the pdfasset or to send the emails

  caller_email: string | undefined
  call_id: string | undefined

  api_url: string | undefined
  orig_href: string | undefined
  constructor(
    private httpClient: HttpClient,
    private wssState: Store<WSSState>,
    private pagesState: Store<PagesState>,

  ) {
    console.log("tool service")

    this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      this.customer_id = customer_id

    })
    this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      this.my_id = my_id
    })
    this.pagesState.select(saveAssets).subscribe((assets: any) => {
      if (this.customer_id && assets) {
        this.saveAssets(assets)
      }
    })
    this.pagesState.select(callerEmail).subscribe((caller_email: string) => {
      this.caller_email = caller_email
      this.getCallerContracts()
    })
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
      this.getCallerContracts()
    })
    this.pagesState.select(gotAssets).subscribe((assets: any[]) => {
      this.assets = assets
      this.mergeContracts()
    })
    this.wssState.select(lastMsg).subscribe((msg: any) => {
      if (msg) {
        //sent by assets/asset to close with undefined
        //and by tools/tools
        if (msg.hasOwnProperty("show_asset")) {
          if (msg.show_asset == "none") {
            this.pagesState.dispatch(new ShowAsset(undefined));
          } else {
            this.pagesState.dispatch(new ShowAsset(msg.show_asset));
          }
        }
      }
    })

    this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg) {
        if (msg.orig_href) {
          this.orig_href = msg.orig_href
          this.setAPIParams()
        }
      }
    })
    this.pagesState.select(apiParams).subscribe((params: any) => {
      if (params && params.api_iframe_url) {
        setTimeout(() => {
          this.api_url = params.api_iframe_url
          this.setAPIParams()
        });
      }
    })

  }
  setAPIParams() {
    if (this.api_url) {
      let url = this.api_url
      if (url.indexOf('?') > 0) {
        url += "&"
      } else {
        url += "?"
      }
      url += "kzapi=" + this.call_id
      //Get the parameters from this.orig_href  and add them to you call
      if (this.orig_href) {
        let bAddedCustomerId = false
        var query = this.orig_href.split("?");
        if (query.length > 1) {
          let params = query[1]
          params.split("&").forEach(function(part) {
            var name_val = part.split("=");
            let name = name_val[0]
            if (name == 'customer_id') {
              bAddedCustomerId = true
            }
            let value = decodeURIComponent(name_val[1]);
            url += "&" + name + "=" + value
          })
        }
        if (!bAddedCustomerId) {
          url += "&customer_id=" + this.customer_id
        }

      }

      this.pagesState.dispatch(new ApiUrlPArams(url))
    }
  }
  async getAssets() {
    // if (this.assets.length > 0) {
    //   return
    // }
    try {
      let body = {
        customer_id: this.customer_id,
        player_id: this.my_id
      }
      let rv: any = await this.httpClient.put('/get_assets', body).toPromise();
      this.assets = rv.assets;
      let keys = Object.keys(this.assets)
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let asset = this.assets[key]
        this.addS3(asset)
      }
      this.pagesState.dispatch(new SetAssets(this.assets))

      this.mergeContracts()
      this.s3_dic = rv.s3
    } catch (e: any) {
      console.error("Error add_asset show " + e)
    }
  }
  async getKeyURL(key: string) {
    return new Promise<string>(async (resolve, reject) => {
      let entry = this.s3_dic[key]
      if (entry) {
        if (entry.url) {
          let now = new Date()
          if (now.getTime() < entry.url.exp) {
            resolve(entry.url.url)
            return
          }
          console.log("S3 renew, key " + key + "expired at " + new Date(entry.url.exp) + " now " + now)
          try {
            let body = {
              key: key,
            }
            let rv: any = await this.httpClient.put('/update_URL', body).toPromise();
            let new_entry = Object.assign({}, entry)
            new_entry.url = rv
            let new_dic = Object.assign({}, this.s3_dic)
            new_dic[key] = new_entry
            this.s3_dic = new_dic
            resolve(new_entry.url.url)
          } catch (e: any) {
            console.error("Error add_asset show " + e)
          }

        }
      }
      // console.error("No URL for " + key)
      reject("key not found")
    })
  }
  addS3(asset: any) {
    if (asset.assets) {
      let keys = Object.keys(asset.assets)
      for (let i = 0; i < keys.length; i++) {
        let k = keys[i]
        let tasset = asset.assets[k]
        this.addS3(tasset)
      }
    }

    let me = this
    function addId(key: string, url: string, exp: number) {
      let s3_dic = Object.assign({}, me.s3_dic)
      let entry = Object.assign({}, s3_dic[key])
      if (!entry.keys) {
        entry.keys = [asset.asset_id]
      } else {
        if (entry.keys.indexOf(asset.asset_id) < 0) {
          let keys = Object.assign([], entry.keys)
          keys.push(asset.asset_id)
          entry.keys = keys
        }
      }
      if (url) {
        entry.url = { url: url, exp: exp }
      }
      s3_dic[key] = entry;
      me.s3_dic = s3_dic
    }
    if (asset.key) {
      addId(asset.key, asset.url, asset.exp)
    }
    if (asset.images) {
      for (let i = 0; i < asset.images.length; i++) {
        let image = asset.images[i]
        addId(image.key, image.url, image.exp)
      }
    }

  }

  removeS3(asset: any) { //side efect remove_keys

    if (asset.assets) {
      let keys = Object.keys(asset.assets)
      for (let i = 0; i < keys.length; i++) {
        let k = keys[i]
        let tasset = asset.assets[k]
        this.removeS3(tasset)
      }
    }
    let me = this
    function removeId(key: string, asset_id: string) {
      if (!me.s3_dic[key]) {
        return
      }
      let s3_dic = Object.assign({}, me.s3_dic)
      let entry = Object.assign({}, s3_dic[key])
      let keys: string[] = []

      for (let i = 0; i < entry.keys.length; i++) {
        let id = entry.keys[i]
        if (id != asset_id) {
          keys.push(id)
        }
      }
      entry.keys = keys
      if (keys.length == 0) { //delete image from s3
        me.remove_keys.push(key)
      } else {
        s3_dic[key] = entry
      }
      me.s3_dic = s3_dic
    }
    if (asset.key) {
      removeId(asset.key, asset.asset_id)
    }
    if (asset.images) {
      for (let i = 0; i < asset.images.length; i++) {
        let image = asset.images[i]
        removeId(image.key, asset.asset_id)
      }
    }
  }




  async getCallerContracts() {
    if (this.caller_email && this.call_id) {
      try {
        let contracts: any = await this.httpClient.get('/get_contracts/' + this.caller_email).toPromise();
        if (contracts) {
          this.contracts = contracts
          //Add the urls of the contracts
          this.mergeContracts()
        }
      } catch (e: any) {
        console.error("Error get caller contracts " + e)
      }
    }
  }
  mergeContracts() {
    //We also need to remove the contracts from previous calls


    if (this.contracts) {

      // let assets = Object.assign({}, this.assets) //The original assets
      //remove the old contracts

      if (this.contracts.length > 0) {
        let assets = Object.assign({}, this.assets)
        for (let i = 0; i < this.contracts.length; i++) {
          let contract = this.contracts[i]
          assets[contract._id] = contract
          this.addS3(contract)
        }
        this.assets_and_contracts = assets;
        this.pagesState.dispatch(new SetAssetsAndContracts(this.assets_and_contracts))
        return
      }

    }
    this.pagesState.dispatch(new SetAssetsAndContracts(this.assets))



  }
  //*****************************************************************************
  //
  //*****************************************************************************
  async changeAsset(_asset: any) {
    return new Promise(async (resolve, reject) => {
      // console.log("Change asset " + JSON.stringify(_asset))
      try {
        let asset = Object.assign({}, _asset)

        if (!asset.asset_id) {
          asset.asset_id = new Date().getTime().toString()
        }
        this.addS3(asset)
        asset.customer_id = this.customer_id
        asset.player_id = this.my_id
        this.pagesState.dispatch(new AddAsset(asset))
        resolve(asset)
      } catch (e: any) {
        console.error("Error changeAsset show " + e)
        reject(e)
      }
    })
  }

  replaceAsset(assets: any, targte_asset: any, lbl?: string): any {
    if (!lbl) {
      lbl = "0"
    }

    let keys = Object.keys(assets)
    console.log("in " + lbl + " target " + JSON.stringify(targte_asset) + " " + JSON.stringify(keys))
    let old_target = assets[targte_asset.asset_id]
    if (old_target) {
      console.log("old_target " + JSON.stringify(old_target))
      let new_assets = Object.assign({}, assets)
      new_assets[targte_asset.asset_id] = targte_asset
      let nkeys = Object.keys(new_assets)
      console.log("ret " + lbl + JSON.stringify(nkeys))
      return new_assets
    }
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      let asset = assets[key]
      if (!old_target && asset.type == "folder" && asset.assets) {
        let folder_assets = this.replaceAsset(asset.assets, targte_asset, lbl + "_1")
        if (folder_assets) {
          let new_folder = Object.assign({}, asset)
          new_folder.assets = folder_assets
          let new_assets = this.replaceAsset(assets, new_folder, lbl + "_2")
          let nkeys = Object.keys(new_assets)
          console.log("ret " + lbl + JSON.stringify(nkeys))
          return new_assets
        }
      }
    }
    console.log("ret " + lbl + " undefined")
    return undefined
  }

  removeAssetsFromFolder(folder: any, asset_ids: string[], bAdmin: boolean) {
    let folder_assets = Object.assign({}, folder.assets)
    let removed_assets: any[] = []
    let assets
    for (let i = 0; i < asset_ids.length; i++) {
      let id = asset_ids[i]
      let asset = Object.assign({}, folder_assets[id])
      removed_assets.push(asset)
      delete folder_assets[id]
      let new_folder = Object.assign({}, folder)
      new_folder.assets = folder_assets
      new_folder.num_assets = new_folder.assets.length
      //Now find the folder in the assets

      let rv = this.replaceAsset(this.assets, new_folder)
      if (rv) {
        assets = rv
      }
    }
    if (assets) {
      this.assets = assets
      this.pagesState.dispatch(new SetAssets(this.assets))
      this.pagesState.dispatch(new NotifyUser("Asset Moved"))
    }
    return removed_assets
  }
  moveBetweenRepAndCia(asset_ids: string[], bAdmin: boolean) {
    let assets = Object.assign({}, this.assets)
    for (let i = 0; i < asset_ids.length; i++) {
      let id = asset_ids[i]
      let asset = Object.assign({}, this.assets[id])
      asset.bAdmin = bAdmin;
      assets[asset.asset_id] = asset
    }
    this.assets = assets
    this.pagesState.dispatch(new SetAssets(this.assets))
    this.pagesState.dispatch(new NotifyUser("Asset Moved"))
  }
  addAssetsTo(removed_assets: any[], bAdmin: boolean) {
    let assets = Object.assign({}, this.assets)
    for (let i = 0; i < removed_assets.length; i++) {
      let asset = Object.assign({}, removed_assets[i])
      asset.bAdmin = bAdmin
      assets[asset.asset_id] = asset
    }
    this.assets = assets
  }


  async sendAssets(bAdmin: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        let keys = Object.keys(this.assets)
        let assets: any = {}
        for (let i = -0; i < keys.length; i++) {
          let key = keys[i]
          let t_asset = this.assets[key]
          if (t_asset.bAdmin == bAdmin) {
            assets[key] = t_asset
          }
        }
        let body: any = {}
        if (bAdmin) {
          body.client_assets = assets
        } else {
          body.user_assets = assets
        }
        let rv = await this.httpClient.put('/set_assets', body).toPromise();
        resolve(rv)
      } catch (e) {
        reject(e)
      }
    })
  }
  async deleteAssets(folder: any, assets: any[], cut: boolean) {
    try {
      let parent_assets = this.assets
      if (folder) {
        parent_assets = folder.assets
      }


      let t_parent_assets = Object.assign({}, parent_assets)

      let bAdmin: boolean = false
      //If the previous cut left some dangilng S3 images remove them
      let rv = await this.removePendingKeys()
      this.remove_keys = []
      let sarr
      if (cut) {
        sarr = JSON.stringify(assets)
      }

      for (let a = 0; a < assets.length; a++) {
        let asset = assets[a]
        bAdmin = asset.bAdmin
        //remove ojects form the parent asset and move them to the folder
        this.removeS3(asset)
        delete t_parent_assets[asset.asset_id]
      }
      if (folder) {
        let t_folder = Object.assign({}, folder)
        t_folder.assets = t_parent_assets
        t_folder.num_assets = Object.keys(t_folder.assets).length
        // this.changeAsset(parent)
        this.assets = this.replaceAssetInAssets(this.assets, t_folder, t_folder.parents)
      } else {
        this.assets = t_parent_assets
      }
      if (sarr) {
        try {
          await navigator.clipboard.writeText(sarr); //This will ALLWAYS fail on debug because the document is not focus
          console.log(" --> clipboard " + sarr)
        } catch (e) {
          console.error(e)
          cut = false; //make sure to remove the dangling stuf
        }
      }

      if (this.remove_keys.length > 0) {
        if (!cut) {
          let rv = await this.removePendingKeys()
          let s3_dic = Object.assign({}, this.s3_dic)
          for (let i = 0; i < this.remove_keys.length; i++) {
            let key = this.remove_keys[i]
            delete s3_dic[key]
          }
          this.s3_dic = s3_dic
        }
      }

      await this.sendAssets(bAdmin)
      this.pagesState.dispatch(new SetAssets(this.assets))
    } catch (e: any) {
      console.error("Error changeAsset show " + e)
    }
  }

  async pasteAsset(folder: any) {
    try {
      let sarr = await navigator.clipboard.readText()
      if (sarr) {
        this.remove_keys = [] //we dont need to delete them beacuse we are pasting them
        let bAdmin: boolean = false
        let assets
        try {
          assets = JSON.parse(sarr)
        } catch (e) {
          console.error("errir parsing " + sarr)
        }
        if (!assets) {
          return;
        }
        let parents = []
        let parent_assets = this.assets
        if (folder) {
          parent_assets = folder.assets
          parents = Object.assign([], folder.parents)
          parents.push(folder.asset_id)
        }
        let t_parent_assets = Object.assign({}, parent_assets)
        for (let i = 0; i < assets.length; i++) {
          let asset: any = Object.assign({}, assets[i])
          if (folder) {
            asset.parents = parents
            asset.bAdmin = folder.bAdmin
          }
          bAdmin = asset.bAdmin
          t_parent_assets[asset.asset_id] = asset
        }
        if (folder) {
          let t_folder = Object.assign({}, folder)
          t_folder.assets = t_parent_assets
          t_folder.num_assets = Object.keys(t_folder.assets).length
          // this.changeAsset(parent)
          this.assets = this.replaceAssetInAssets(this.assets, t_folder, t_folder.parents)
        } else {
          this.assets = t_parent_assets
        }
        await this.sendAssets(bAdmin)
        this.pagesState.dispatch(new SetAssets(this.assets))
      }
    } catch (e) {
      console.error(e)
    }
  }

  async removePendingKeys() {
    return new Promise<void>(async (resolve, reject) => {
      if (this.remove_keys) {
        if (this.remove_keys.length > 0) {
          try {
            let rv = await this.removeKeys(this.remove_keys)
            resolve(rv)

          } catch (e) {
            reject(e)
          }
          return;
        }
      }
      resolve()
    })
  }

  async removeKeys(remove_keys: string[]) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv = await this.httpClient.put('/s3_remove', { keys: remove_keys }).toPromise();
        resolve()
      } catch (e) {
        reject(e)
        console.error(e)
      }
    })
  }

  replaceAssetInAssets(assets: any, asset: any, in_parents: string[]) {
    let parents = Object.assign([], in_parents)
    let id = parents.shift() //Get the first objectg

    if (!id) {
      let t_assets = Object.assign({}, assets)
      t_assets[asset.asset_id] = asset;
      return t_assets;
    }

    let parent = Object.assign({}, assets[id])
    parent.assets = this.replaceAssetInAssets(parent.assets, asset, parents)
    parent.num_assets = Object.keys(parent.assets).length
    let t_assets = Object.assign({}, assets)
    t_assets[parent.asset_id] = parent;
    return t_assets;
  }





  async copyAssets(assets: any, to_clipboard: boolean) {
    if (to_clipboard) {
      try {
        let sarr = JSON.stringify(assets)
        await navigator.clipboard.writeText(sarr); //This will ALLWAYS fail on debug because the document is not focus
        console.log(" --> clipboard " + sarr)
      } catch (e) {
        console.error(e)
      }
      return
    }
    //remove the asset id
    let bAdmin: boolean = false
    let prev_assets = Object.assign({}, this.assets)
    try {
      for (let i = 0; i < assets.length; i++) {
        let asset = Object.assign({}, assets[i])
        //change the name and call changeAsset
        let name = "Copy " + asset.name
        asset.name = name
        asset.asset_id = new Date().getTime().toString()
        if (asset.key) { //Make a copy of the S3 object and use the new URL
          let new_key = asset.player_id + "/" + asset.asset_id
          let body = {
            old_key: asset.key,
            new_key: new_key
          }
          let rv: any = await this.httpClient.post('/copy_key', body).toPromise();
          asset.url = rv.url
          asset.key = new_key
        }
        this.addS3(asset)
        asset.customer_id = this.customer_id
        asset.player_id = this.my_id
        prev_assets = this.replaceAssetInAssets(prev_assets, asset, asset.parents)
        bAdmin = asset.bAdmin
      }
      this.assets = prev_assets
      await this.sendAssets(bAdmin)
      this.pagesState.dispatch(new SetAssets(this.assets))
    } catch (e: any) {
      console.error("Error changeAsset show " + e)
    }
  }

  async saveAssets(assets: any) {
    try {
      let client_assets: any = {}
      let user_assets: any = {}
      let client_num = 0;
      let user_num = 0;

      let keys = Object.keys(assets)
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let asset = assets[key]
        if (asset.bAdmin) {
          client_num++
          client_assets[key] = asset
        } else {
          user_num++
          user_assets[key] = asset
        }
      }
      let body: any = {}
      if (user_num) {
        body.user_assets = user_assets
      }
      if (client_num) {
        body.client_assets = client_assets
      }
      let rv = await this.httpClient.put('/set_assets', body).toPromise();
    } catch (e: any) {
      console.error("Error changeAsset show " + e)
    }
  }
  async getYouTubeTitle(youtube_id: string) {
    return new Promise<string>(async (resolve, reject) => {
      try {

        let data: any = await this.httpClient.get('/youtube_title/' + youtube_id).toPromise();
        resolve(data.title)
      } catch (e: any) {
        console.error("Error getYouTubeTitle show " + e)
        reject()
      }
    })
  }
}
