<div class=" font_sub_title out">
  <div class="offline">
    {{status}}
  </div>
  <switch class="switch" back_off="red" [checked]="bOnLine" (checkedChange)="offLine($event)"></switch>
  <div class="settings" kzLib (click)="settings()">
    Settings
  </div>
  <div class="community" kzLib (click)="comunity()">
    Community
  </div>
  <div class="logout" kzLib (click)="logout()">
    Log Out
  </div>
  <img class="slogout" src="assets/images/logout.svg" kzLib (click)="logout()">

</div>
