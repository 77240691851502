import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts, TextColor } from '../../canvas.actions';

@Component({
  selector: 'text-color',
  templateUrl: './text-color.component.html',
  styleUrls: ['./text-color.component.scss']
})
export class TextColorComponent {

  text_colors: string[] = [
    "#3B5FE0",
    "#A13BE0",
    "#FFCE38",
    "#3DE049",
    "#E03BCF",
    "#E06D3B",
    "#653BE0",
    "#E03B8D",
    "#E03B3D"
  ]
  text_lbls: string[] = [
    "1. Vibrant Blue",
    "2. Vibrant Purple",
    "3. Vibrant Yellow",
    "4. Vibrant Green",
    "5. Vibrant Magenta",
    "6. Vibrant Orange",
    "7. Vibrant Indigo",
    "8. Vibrant Pink",
    "9. Vibrant Red"
  ]
  constructor(
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("text color")
  }
  selColor(i: number) {
    this.canvasStore.dispatch(new TextColor(this.text_colors[i]))
    this.canvasStore.dispatch(new SelectToolOpts("tc1"))
  }
}
