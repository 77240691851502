<div class="out">
  <div class="top_space"> </div>

  <div class="calls" *ngFor="let call of calls, let i = index ">
    <wnd-line class="line" [call]="call" [show_index]="show_index" [index]="i" (show_opts)="showOpts($event)">
    </wnd-line>

    <queue-wnd-options [call]="call" *ngIf="show_index == i" class="wop"></queue-wnd-options>

  </div>
  <call-notification class="abs_center" *ngIf="call_waiting"></call-notification>

  <div *ngIf="calls.length==0" class="abs_center">
    No Calls
  </div>
</div>
