import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { PdfSecOpt, SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { pdfSecOpts } from 'src/app/canvas/canvas.selectors';
import { CanvasService } from '../../canvas.service';




@Component({
  selector: 'pdf-opts',
  templateUrl: './pdf-opts.component.html',
  styleUrls: ['./pdf-opts.component.scss']
})
export class PdfOptsComponent {
  take_pic: boolean = false;
  driver_lic: boolean = false;
  image_rec: boolean = false;
  bc_once: boolean = false
  bc_all: boolean = false

  @Input() set options(data: any) {
    this.take_pic = data.take_pic
    this.driver_lic = data.driver_lic
    this.image_rec = data.image_rec
  }

  @Output() close: EventEmitter<any> = new EventEmitter();
  subscriptions: any[] = []
  constructor(
    public canvasStore: Store<CanvasState>,
    public canvas_service: CanvasService,
  ) {
    console.log("pdf-opts")
    this.subscriptions.push(this.canvasStore.select(pdfSecOpts).subscribe((options: any) => {

      if (options) {
        this.take_pic = options.take_pic
        this.driver_lic = options.driver_lic
        this.image_rec = options.image_rec
        if ((options.blockchain == "all")) {
          this.bc_all = true
          this.bc_once = true
        } else {
          this.bc_once = (options.blockchain == "once")
        }
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  // afterEverySession() {
  //   if (this.bc_all) {
  //     this.bc_once = true
  //   }
  //   this.saveIt()
  // }

  dirverLic() {
    this.driver_lic = !this.driver_lic
    if (this.driver_lic) {
      this.take_pic = true
    }
    this.saveIt()
  }
  takePic() {
    this.take_pic = !this.take_pic;
    this.saveIt()
  }
  blockChain() {
    this.bc_once = !this.bc_once
    this.saveIt()
  }
  saveIt() {
    let blockchain: string = "none";
    if (this.bc_all) {
      blockchain = "all"
    } else if (this.bc_once) {
      blockchain = "once"
    }
    let sec_opts = {
      take_pic: this.take_pic,
      driver_lic: this.driver_lic,
      image_rec: this.image_rec,
      blockchain: blockchain
    }
    this.canvasStore.dispatch(new PdfSecOpt(sec_opts))
    this.canvas_service.sendCanvasMsg("sec_opts", "sec_opts", "", sec_opts)

    // this.canvasStore.dispatch(new SelectToolOpts(""))
  }
}
