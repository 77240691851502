<!-- <div class="out" *ngIf="converge_acc=='keyzii'"> -->
<div class="out">
  <div class="body">
    <div class="left">
      <div class="title m10">
        Payment History
      </div>
      <div class="plan">
        <k-search class="search" type="name" placeholder="Search Invoice by amount or date (mm/dd/yyyy)" [(myModel)]="search" (myModelChange)="filterName($event)"></k-search>


        <div class="tx titles">

          <div class="t_date">
            Date
          </div>
          <div class="t_plan">
            Plan
          </div>
          <div class="t_amount">
            Amount
          </div>
          <div class="t_invoice">
            Invoice
          </div>

        </div>
        <div *ngIf="invoices" class="container">
          <div class="line" *ngFor="let invoice of invoices" [ngClass]="{overdue:invoice.overdue}">
            <!-- Year {{year|json}}{{year.year}} {{year.months|json}} -->
            <div class="">
              <!-- Month {{month}} -->
            </div>
            <!-- invoices{{invoices[year.year][month]|json}} -->
            <div class="t_date">
              {{invoice.created_at|date: 'MMM dd yyyy'}}
            </div>
            <div class="t_plan">
              {{ invoice.concept}}
            </div>
            <div class="t_amount">
              {{ invoice.amount|currency}}
            </div>
            <div class="t_invoice ul" kzLib (click)="download(invoice)">
              Download
            </div>

          </div>

        </div>
      </div>
      <div class="title m10" kzLib>
        Payment Method
        <div class="add_cc" kzLib (click)="addCard()">
          Add new card
        </div>
      </div>
      <div class="m10 manage fs14">
        Manage your credit cards and payment options.
      </div>
      <cards-del></cards-del>

    </div>




    <div class="right">
      <div *ngIf="bFreeTrial" class="free_plan">
        <div class="">
          Free trial period
        </div>
        <div class="right_tx ">
          Ends {{plan_ends |date:'shortDate'}}
        </div>
      </div>
      <div class="plan plan_bx">
        <div class="tx yp">
          {{selected_plan.text}}
        </div>


        <div class="tx pl_e" *ngIf="selected_plan.amount!=0">
          <div class="">
            Next billing date:
          </div>
          <div class="pl_er ">
            {{plan_ends |date:'shortDate'}}
          </div>
        </div>
        <div class="tx mt20" *ngIf="!code">
          <span class="users"> {{num_users}} </span>
          <span class="user" *ngIf="non_code_users==1">user</span>
          <span class="user" *ngIf="non_code_users>1">users</span>
        </div>
        <div class="tx mt20" *ngIf="code">
          {{code.users}} {{code_epx}}
          <span *ngIf="code.users==1">user</span>
          <span *ngIf="code.users>1">users</span>
          <span *ngIf="non_code_users>0">
            + {{non_code_users}} Paid
            <span *ngIf="non_code_users==1">user</span>
            <span *ngIf="non_code_users>1">users</span>
          </span>
        </div>
        <div class="tx ft" *ngIf="selected_plan.amount==0 && !code">
          Free Trial
        </div>
        <div class="tx ft" *ngIf="selected_plan.amount==0 && code">
          Lifetime
        </div>
        <div class="tx" *ngIf="selected_plan.amount>0">
          <span class="amount ft"> {{amount|currency}} </span> {{period}}
        </div>
        <div class="wi">
          What is included?
        </div>
        <ul class="fs12">
          <li>
            {{num_assets}} Assets (Image, Video, Virtual Tours & PDFS)
          </li>
          <li>Send or E-Sign Live with Biometric ID Verification (Unlimited)</li>
          <li>Adaptive Call Now & Scheduling Button</li>
          <li>Scheduling Tool / Calendly Integration</li>
          <li>Custom Branding & White Labeling</li>
          <li>Advance API & Embedding </li>
        </ul>
        <div class="edit_plan" kzLib (click)="openCC()" [ngClass]="{edit_plan_ready:cc_page!='new'}">
          <div class="abs_center btn">
            Edit Plan
          </div>
        </div>
      </div>
      <div class="ctr_tx downgrade" kzLib (click)="downgrade()">
        Downgrade Plan
      </div>
    </div>
  </div>

</div>
<!-- <div class="out" *ngIf="converge_acc!='keyzii'">
  <div class="comming">
    <div class="abs_center comming_lbl">
      <img src="assets/images/lock.svg" alt="">
      Coming soon ...
    </div>
  </div>
</div> -->
