import { Directive, ElementRef, Input, } from '@angular/core';


@Directive({
  selector: '[kzLib]'
})
export class KzLibDirective {

  @Input() kzLib: string = "kz";

  constructor(private el: ElementRef,
  ) {
    console.log("kzLib directive")
  }
  // @HostListener('focus', ['$event.target'])
  // onFocus(target: EventTarget) {
  //   console.log('Input focused!', target);
  //   // Add additional logic here
  // }
  ngAfterViewInit(): void {

    this.el.nativeElement.dataset['kzbtn'] = this.kzLib
    this.el.nativeElement.onKZ = function(event: any) {
      console.log("on kzLib " + event)
    }


  }
}
