import { Component } from '@angular/core';
import { ShowToolAddAsset } from '../../pages.actions';
import { NewAssetComponent } from '../new-asset/new-asset.component';

@Component({
  selector: 'new-tab-asset',
  templateUrl: './new-tab-asset.component.html',
  styleUrls: ['../new-asset/new-asset.component.scss', '../new-asset/new-asset.component.scss', './new-tab-asset.component.scss']
})
export class NewTabAssetComponent extends NewAssetComponent {
  changedLink($event: any) {
    console.log("check link")
    this.bEnableSave = (this.link.length > 2)
  }
  selType(i: number) {
    super.selType(i)
    setTimeout(() => {
      this.bAdmin = false; //Add the asser to the rep
      this.bEnableSave = (this.link.length > 2)
    }, 10)
  }
  back() {
    this.pagesState.dispatch(new ShowToolAddAsset(false))

  }
}
