/*
Copyright(c) 2022 Sergio A. Fernandez, Ph.D.
*/

import { ContactsAction, ContactsActionTypes } from "./contacts.actions";
import { ContactsState, initialContactsState } from "./contacts.model";


// let streams_cache: any = {}
export function contactsReducer(state = initialContactsState,
  action: ContactsAction): ContactsState {
  switch (action.type) {

    case ContactsActionTypes.GotContacts: {
      console.log("got contacts")
      const contactsState: ContactsState = Object.assign({}, state);
      contactsState.contacts = action.rv
      return contactsState;
    }

    default:
      return state;

  }
}
