import { Component } from '@angular/core';
import { PhoneComponent } from '../phone/phone.component';

@Component({
  selector: 'phone-hang-up',
  templateUrl: './phone-hang-up.component.html',
  styleUrls: ['../phone/phone.component.scss']
})
export class PhoneHangUpComponent extends PhoneComponent {
}
