<div class="out" #outComponent>
  <div  kzLib (click)="showOptions()" class="title">{{title}}
    <img class="exp" [ngClass]="{colapse:bShowOptions}" src="assets/images/expand.svg">
  </div>
  <div *ngIf="bShowOptions" #optComponent class="options" [ngClass]="{opts_r:bShowRight}" [style.border]="border">
    <div class="opt" [style.border-bottom]="border"  kzLib (click)="all()">
      All
    </div>
    <div class="opt" [style.border-bottom]="border" *ngFor="let opt of options;let i = index"  kzLib (click)="selOption(i)">
      {{opt}}
    </div>
  </div>
</div>
