<div class="out">
  <div class="top_line">
    <div class="tt">
      Secure Payment Processing with PaymentStars (US Companies ONLY)
    </div>
  </div>
  <div class="gray_line"></div>

  <div class="t_b">
    <img class="abs_center img" src="assets/images/payment_stars.svg" alt="">
    <div class="tx tx_top">
      PaymentStars is a comprehensive payment processing service that offers tailored solutions for businesses of all sizes. They provide a wide range of services, including credit/debit card processing, ACH/eCheck processing, mobile payments, and
      online payment portals. Their platform is designed to make payment processing easy, secure, and efficient, with 24/7 customer support and competitive pricing. PaymentStars is dedicated to helping businesses streamline their payment processes
      and save on fees, all while offering excellent customer service.

    </div>
    <div class="tx">
      For more details, visit their <span class="link"  kzLib (click)="openPS()">website</span>.
    </div>
  </div>

  <div class="start">
    {{doing_t}}
  </div>
  <div class="tx tx_2">
    {{doing_b}}
  </div>
  <div class="tx_2 btn"  kzLib (click)="openForm()">
    <div class="abs_center">
      Open Form >
    </div>
  </div>
  <div class="tx tx_2 it">
    Keyzii only collects your name and email during this onboarding process and does not store, view, or access any other information. Any details you provide in the PaymentStars form, which you will receive after your request is processed, are
    solely between you and PaymentStars. Keyzii does not see or store any information submitted through PaymentStars.
  </div>

</div>
