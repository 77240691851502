import { SVG } from '@svgdotjs/svg.js';
import { AddToUndo, SelectId } from '../canvas.actions';
import { Draw } from './draw';


export class kArrow extends Draw {
  arrows: any = {} //will have the group with the head and line

  tip_anchor: any
  tail_anchor: any

  arrow_tail_x: number = 0
  arrow_tail_y: number = 0
  arrow_tip_x: number = 0
  arrow_tip_y: number = 0

  drawing_arrow_tip: any
  drawing_arrow_line: any

  //****************************************************************************
  // Parent events
  //****************************************************************************
  newObj(left: number, top: number, right: number, bottom: number) {

    left = this.parent_canvas.deScaleX(left)
    top = this.parent_canvas.deScaleY(top)

    right = this.parent_canvas.deScaleX(right)
    bottom = this.parent_canvas.deScaleY(bottom)

    let points = this.makeArrow(left, top, right, bottom)
    let drawing_arrow_line = this.draw_tool.polygon(points.line).fill('#00ff00a0').stroke({ width: this.pencil_width, color: this.fill_color })
    let drawing_arrow_tip = this.draw_tool.polygon(points.tip).fill(this.fill_color)//.stroke({ width: 1, color: "red" })
    let arrow_id = new Date().getTime().toString()

    let group = this.draw_tool.group()
    group.node.dataset['k_type'] = "arrow"
    group.node.dataset['id'] = arrow_id
    drawing_arrow_tip.node.dataset['id'] = arrow_id
    drawing_arrow_line.node.dataset['id'] = arrow_id
    drawing_arrow_tip.node.dataset['k_type'] = "arrow"
    drawing_arrow_line.node.dataset['k_type'] = "arrow"
    group.add(drawing_arrow_tip)
    group.add(drawing_arrow_line)
    this.arrows[arrow_id] = group
    // this.setFunctions(drawing_arrow_tip, drawing_arrow_line)
    this.canvasStore.dispatch(new AddToUndo({
      undo: "new",
      k_type: "arrow",
      id: arrow_id
    }))
    this.canvas_service.sendCanvasMsg("arrow", "new", arrow_id, group.svg())
    let box = this.getBox(arrow_id)
    return { id: arrow_id, box: box, k_type: "arrow" }
  }
  setMinSize(id: string, fromViewBox?: any) {
    let el = this.findGroupById(id)
    if (el) {
      let childern: any[] = el.children()
      if (childern.length > 1) {
        let drawing_arrow_tip = childern[0]
        let drawing_arrow_line = childern[1]
        let tip_bbox = drawing_arrow_tip.bbox()
        let line_bbox = drawing_arrow_line.bbox()
        let dx = tip_bbox.cx - line_bbox.cx
        let dy = tip_bbox.cy - line_bbox.cy
        let d = Math.sqrt(dx * dx + dy * dy)
        if (d < 20) {

          let dx = 100
          let dy = 10
          if (fromViewBox) {
            dx = this.parent_canvas.scaleXToWidth(dx, fromViewBox.width)
            dy = this.parent_canvas.scaleYtoHeight(dy, fromViewBox.height)
          }

          let tip_points = this.movePolyPoints(drawing_arrow_tip, dx, dy)
          let line_points = this.movePolyPoints(drawing_arrow_line, 0, 0)
          let points = this.makeArrow(line_points[0], line_points[1], tip_points[2], tip_points[3])
          drawing_arrow_tip.attr({ points: points.tip })
          drawing_arrow_line.attr({ points: points.line })


          this.setAnchorsPos(id)
        }
      }
    }
  }
  onMouseDown(evt: any) { //creates the object
    let offsetX = this.parent_canvas.deScaleX(evt.offsetX)
    let offsetY = this.parent_canvas.deScaleY(evt.offsetY)

    return this.newObj(offsetX, offsetY, offsetX + 30, offsetY - 50)
  }

  //****************************************************************************
  // Copt paste and move erase
  //****************************************************************************
  processMsg(msg: any) {
    this.undo(msg)
  }
  undo(msg: any) {
    let old_arrow = this.arrows[msg.id]
    if (old_arrow) {
      if (this.tip_anchor) {
        if (this.tip_anchor.dataset['id'] == msg.id) {
          this.removeAnchors()
        }
      }
      old_arrow.remove()
      delete this.arrows[msg.id]
    }
    if (msg.data) {
      let svg_arrow = SVG(msg.data)
      let vb
      if (msg.canvas_width) {
        vb = {
          width: msg.canvas_width,
          height: msg.canvas_height
        }
      }
      let rv: any = this.fromSVG(svg_arrow, vb)
      if (rv) {
        this.arrows[msg.id] = rv.arrow
        this.setMinSize(rv.id)
        this.createAnchors(msg.id)
        return rv
      }
    }
  }
  pasteSVG(svg: any, x: number, y: number) {
    let rv: any = this.fromSVG(svg)
    if (rv) {
      let id = rv.id
      if (this.arrows[id]) { //change the id
        id = new Date().getTime().toString()
        rv.arrow.node.dataset['id'] = id
        rv.line.node.dataset['id'] = id
        rv.tip.node.dataset['id'] = id
        rv.arrow.node.dataset['k_type'] = "arrow"
        rv.line.node.dataset['k_type'] = "arrow"
        rv.tip.node.dataset['k_type'] = "arrow"
      }
      this.arrows[id] = rv.arrow
      //
      // rv.arrow.move(box.x1 + 20, box.y1 + 20)
      this.moveDelta(id, 20, 20)
      let box: any = this.getBox(id)
      return { id: id, box: box, k_type: "arrow" }
    }
    return undefined
  }
  fromSVG(svg_arrow: any, fromViewBox?: any) {
    let gattr = svg_arrow.attr()
    let childern: any[] = svg_arrow.children()
    if (childern.length > 1) {
      let id = svg_arrow.node.dataset['id']
      let k_type = svg_arrow.node.dataset['k_type']

      let attr = childern[0].attr()
      let points = this.getArrayFromStr(attr.points)
      if (fromViewBox) {
        let np = this.parent_canvas.scalePointsToViewBox(points, fromViewBox)
        points = np
      }
      let drawing_arrow_tip = this.draw_tool.polygon(points).fill(attr.fill)//.stroke({ width: 1, color: "red" })

      attr = childern[1].attr()
      points = this.getArrayFromStr(attr.points)
      if (fromViewBox) {
        let np = this.parent_canvas.scalePointsToViewBox(points, fromViewBox)
        points = np
      }
      let drawing_arrow_line = this.draw_tool.polygon(points).fill(attr.fill).stroke({ width: attr['stroke-width'], color: attr['stroke'] })
      drawing_arrow_line.attr('points', points)


      let arrow = this.draw_tool.group()
      arrow.node.dataset['id'] = id
      arrow.node.dataset['k_type'] = k_type
      drawing_arrow_line.node.dataset['id'] = id
      drawing_arrow_line.node.dataset['k_type'] = k_type
      drawing_arrow_tip.node.dataset['id'] = id
      drawing_arrow_tip.node.dataset['k_type'] = k_type
      arrow.add(drawing_arrow_tip)
      arrow.add(drawing_arrow_line)
      // this.setFunctions(drawing_arrow_tip, drawing_arrow_line)
      return { id: id, arrow: arrow, line: drawing_arrow_line, tip: drawing_arrow_tip }
    }
    return undefined
  }
  copy(selected_id: string) {
    let arrow = this.arrows[selected_id]
    if (arrow) {
      return arrow.svg()
    }
    return undefined
  }
  erase(id: string) {
    let rv
    this.removeAnchors()
    let arrow = this.arrows[id]
    if (arrow) {
      rv = {
        undo: "erase",
        k_type: "arrow",
        id: id,
        data: arrow.svg()
      }
      arrow.remove()
      this.canvas_service.sendCanvasMsg("arrow", "erase", id)

    }
    return rv
  }


  //****************************************************************************
  // getBox returns the frame and back to create the backdiv, points to create the points, svg for undo and data that will be passed back to moveObj
  //****************************************************************************

  getBox(id: string) {
    if (this.arrows) {
      let arrow = this.arrows[id]
      if (arrow) {
        let childern: any[] = arrow.children()
        if (childern.length > 1) {
          let drawing_arrow_tip = childern[0]
          let drawing_arrow_line = childern[1]
          let spoints = drawing_arrow_tip.attr('points')
          let arrow_tip_points = this.getArrayFromStr(spoints)
          spoints = drawing_arrow_line.attr('points')
          let arrow_line_points = this.getArrayFromStr(spoints)

          let line_lng = "w"
          let line_lat = "s"
          let x1 = arrow_line_points[2]
          let y2 = arrow_line_points[3]

          let tip_lng = "e"
          let tip_lat = "n"
          let x2 = arrow_tip_points[0]
          let y1 = arrow_tip_points[1]

          if (x1 > x2) {
            line_lng = "e"
            tip_lng = "w"
            let t = x1
            x1 = x2
            x2 = t
          }
          if (y1 > y2) {
            line_lat = "n"
            tip_lat = "s"
            let t = y1
            y1 = y2
            y2 = t
          }

          let rv =
          {
            x1: x1 - 5,
            y1: y1 - 5,
            x2: x2 + 5,
            y2: y2 + 5,
            data: {
              tip: tip_lat + tip_lng,
              line: line_lat + line_lng
            },
            back: true, //make back div
            new_point: false, //new_point for polygon
            svg: arrow.svg() //for the undo
          }
          return rv
        }
      }
    }
    return undefined
  }
  //****************************************************************************
  // Moves and scales
  //****************************************************************************

  moveObj(id: string, x1: number, y1: number, x2: number, y2: number, data: any) {
    console.log("#sm arrow move obj " + x1 + " " + y1 + " " + x2 + " " + y2)
    if (x1 > x2) {
      let t = x1
      x1 = x2
      x2 = t
    }
    if (y1 > y2) {
      let t = y1
      y1 = y2
      y2 = t
    }

    let w = x2 - x1
    let h = y2 - y1
    let left = x1;
    let top = y1;
    // console.log("#el1 " + left + " " + top + " " + w + " " + h)

    left = this.parent_canvas.deScaleX(left)
    w = this.parent_canvas.deScaleX(w)

    top = this.parent_canvas.deScaleY(top) //We might have to descale from the bottom
    h = this.parent_canvas.deScaleY(h)
    x1 = left
    x2 = left + w
    y1 = top
    y2 = top + h

    function getAnchorPos(name: string) {
      if (name == "ne") {
        return { x: x2, y: y1 }
      } else if (name == "se") {
        return { x: x2, y: y2 }
      } else if (name == "nw") {
        return { x: x1, y: y1 }
      } else {//sw
        return { x: x1, y: y2 }
      }
    }

    let group = this.findGroupById(id)
    if (group) {
      let childern: any[] = group.children()
      if (childern.length > 1) {
        let drawing_arrow_tip = childern[0]
        let drawing_arrow_line = childern[1]
        let tip_points = getAnchorPos(data.tip)
        let line_points = getAnchorPos(data.line)
        let points = this.makeArrow(line_points.x, line_points.y, tip_points.x, tip_points.y)

        drawing_arrow_tip.attr({ points: points.tip })
        drawing_arrow_line.attr({ points: points.line })
        this.canvas_service.sendCanvasMsg("arrow", "move", id, group.svg())
      }
    }
  }
  //****************************************************************************
  // Moves in increments used to move the hole object
  //****************************************************************************
  moveDelta(selected_id: string, delta_x: number, delta_y: number) {
    let el = this.findGroupById(selected_id)
    if (el) {
      let childern: any[] = el.children()
      if (childern.length > 1) {
        let drawing_arrow_tip = childern[0]
        let drawing_arrow_line = childern[1]
        this.movePolyPoints(drawing_arrow_tip, delta_x, delta_y)
        this.movePolyPoints(drawing_arrow_line, delta_x, delta_y)
        this.setAnchorsPos(selected_id)
        let bbox = el.bbox()
        this.parent_canvas.setBox(bbox.x, bbox.y, bbox.x2, bbox.y2)
      }
    }
  }

  //****************************************************************************
  // Utils
  //****************************************************************************
  // setFunctions(drawing_arrow_tip: any, drawing_arrow_line: any) {
  //**********************************************************************
  // move functions
  //**********************************************************************
  // drawing_arrow_line.node.onmousemove = onArrowEnter
  // drawing_arrow_tip.node.onmousemove = onArrowEnter
  //
  // let me = this
  // function onArrowEnter(this: any, $event: any) {
  //   let arrow_id = this.dataset['id']
  //   let tip_anchor_id = ""
  //   if (me.tip_anchor) {
  //     tip_anchor_id = me.tip_anchor.dataset['id']
  //   }
  //
  //   if (arrow_id != tip_anchor_id) {
  //     let box = me.getBox(arrow_id)
  //     if (box) {
  //       me.parent_canvas.onHover(arrow_id, box, "arrow")
  //     }
  //   }
  // }
  // }
  makeArrow(tailX: number, tailY: number, tipX: number, tipY: number) {

    function toRadians(degrees: number) {
      var pi = Math.PI;
      return degrees * (pi / 180);
    }

    let angle = -25
    let head_length = 20; //can be adjusted
    let dx = tipX - tailX;
    let dy = tipY - tailY;

    let line_len = Math.sqrt(dx * dx + dy * dy)
    if (line_len > head_length) {

      // console.log("line_len " + line_len + ", " + tailX + ", " + tailY + ", " + tipX + ", " + tipY)

      let theta = Math.atan2(dy, dx);

      let rad = toRadians(angle) //35 angle, can be adjusted
      let ax1 = tipX - head_length * Math.cos(theta + rad);
      let ay1 = tipY - head_length * Math.sin(theta + rad);

      let phi2 = toRadians(angle * -1);//-35 angle, can be adjusted
      let ax2 = tipX - head_length * Math.cos(theta + phi2);
      let ay2 = tipY - head_length * Math.sin(theta + phi2);

      //https://math.stackexchange.com/questions/656500/given-a-point-slope-and-a-distance-along-that-slope-easily-find-a-second-p
      let line_length = line_len - head_length
      let m = dy / dx
      let r = Math.sqrt(1 + m * m)
      let ldx = line_length / r
      let ldy = line_length * m / r
      let lx
      let ly
      if (tipX > tailX) {
        lx = tailX + ldx
        ly = tailY + ldy

      } else {
        lx = tailX - ldx
        ly = tailY - ldy
      }

      return { tip: [tipX, tipY, ax1, ay1, ax2, ay2], line: [lx, ly, tailX, tailY] }
      //   this.arrow_tip_points = [tipX, tipY, ax1, ay1, ax2, ay2]
      // this.arrow_line_points = [lx, ly, tailX, tailY]
    } else {
      return { tip: [tipX, tipY, tipX, tipY, tipX, tipY], line: [tipX, tipY, tailX, tailY] }
      // this.arrow_tip_points = [tipX, tipY, tipX, tipY, tipX, tipY]
      // this.arrow_line_points = [tipX, tipY, tailX, tailY]
    }
  }

  //****************************************************************************
  //****************************************************************************
  preSelect(_selected_id: string, k_type: string) {

    this.removeAnchors()
  }

  select(selected_id: string) { //sublcassed

    console.log("#AR select " + selected_id)
    // this.bSelected = true

    this.createAnchors(selected_id)
  }
  createAnchors(id: string) {
    console.log("sel arrow " + id)
    if (this.arrows[id]) {
      let group = this.arrows[id]
      if (!this.tip_anchor) {
        this.tip_anchor = document.createElement("div");
        this.tip_anchor.style.width = "10px"//page.style.width
        this.tip_anchor.style.height = "10px"//page.style.height
        this.tip_anchor.style.position = "absolute"
        this.tip_anchor.style.background = "#8F5CFFA0"
        this.tip_anchor.style.cursor = 'move'
        this.canvas.appendChild(this.tip_anchor)

        this.tip_anchor.onpointerdown = onAnchorDown
        this.tip_anchor.onpointerup = onAnchorUp
        this.tip_anchor.dataset['loc'] = "tip"

        this.tail_anchor = document.createElement("div");
        this.tail_anchor.style.width = "10px"//page.style.width
        this.tail_anchor.style.height = "10px"//page.style.height
        this.tail_anchor.style.position = "absolute"
        this.tail_anchor.style.background = "#8F5CFFA0"
        this.tail_anchor.style.cursor = 'move'
        this.canvas.appendChild(this.tail_anchor)
        this.tail_anchor.onpointerdown = onAnchorDown
        this.tail_anchor.onpointerup = onAnchorUp
        this.tail_anchor.dataset['loc'] = "tail"
        //**********************************************************************
        // Anchor functions
        //**********************************************************************

        let me = this

        let undo_svg: string | undefined
        function onAnchorDown(this: any, $event: any) {
          $event.stopPropagation()

          // if (me.sel_tool_name == 'none') {
          let id = this.dataset['id']
          me.perpareToStartMoving(id, $event)
          me.pointer_id = $event.pointerId
          me.canvas.setPointerCapture(me.pointer_id)
          console.log("#poly anchor down")
          undo_svg = me.copy(id)
          me.canvas.onpointermove = onAnchorMove
          me.canvas.onpointerup = onAnchorUp
          me.moving_anchor = this
        }

        function onAnchorMove(evt: any) {
          evt.stopPropagation()
          if (evt.buttons == 0) {
            return
          }
          let offsetX = me.parent_canvas.deScaleX(evt.offsetX)
          let offsetY = me.parent_canvas.deScaleY(evt.offsetY)

          if (me.moving_anchor) {
            let loc = me.moving_anchor.dataset['loc']
            let points: any
            if (loc == "tip") { //when we are makeing a new one or moving tip
              points = me.makeArrow(me.arrow_tail_x, me.arrow_tail_y, offsetX, offsetY)
              me.tip_anchor.style.left = me.parent_canvas.scaleX(points.tip[0] - 5) + "px"
              me.tip_anchor.style.top = me.parent_canvas.scaleY(points.tip[1] - 5) + "px"
            } else if (loc == "tail") {
              points = me.makeArrow(offsetX, offsetY, me.arrow_tip_x, me.arrow_tip_y)
              me.tail_anchor.style.left = me.parent_canvas.scaleX(points.line[2] - 5) + "px"
              me.tail_anchor.style.top = me.parent_canvas.scaleY(points.line[3] - 5) + "px"
            }

            //
            me.drawing_arrow_tip.attr({ points: points.tip })
            me.drawing_arrow_line.attr({ points: points.line })


            me.tail_anchor.style.left = me.parent_canvas.scaleX(points.line[2] - 5) + "px"
            me.tail_anchor.style.top = me.parent_canvas.scaleY(points.line[3] - 5) + "px"

            let bbox = group.bbox()
            me.parent_canvas.setBox(bbox.x, bbox.y, bbox.x2, bbox.y2)
            me.canvas_service.sendCanvasMsg("arrow", "move", id, me.copy(id))

          }

        }

        function onAnchorUp(this: any, $event: any) {
          console.log("#arrow anchor up ")
          if (me.moving_anchor) {
            let id = me.moving_anchor.dataset['id']
            me.parent_canvas.addToUndo({
              undo: "move",
              data: undo_svg,
              k_type: "arrow",
              id: id,
            })
            me.canvas_service.sendCanvasMsg("arrow", "move", id, me.copy(id))


            me.canvas.onpointermove = null;
            me.moving_anchor = null;
            if (me.pointer_id) {
              me.canvas.releasePointerCapture(me.pointer_id)
              me.pointer_id = undefined
            }
          }
        }
      }
      this.tail_anchor.dataset['id'] = id
      this.tip_anchor.dataset['id'] = id
      this.setAnchorsPos(id)
    } else {
      this.removeAnchors()
    }
  }
  setAnchorsPos(id: string) {
    if (this.tail_anchor) {
      let arrow = this.arrows[id]
      if (arrow) {
        let childern: any[] = arrow.children()
        if (childern.length > 1) {
          let drawing_arrow_tip = childern[0]
          let drawing_arrow_line = childern[1]
          let spoints = drawing_arrow_tip.attr('points')
          let arrow_tip_points = this.getArrayFromStr(spoints)
          spoints = drawing_arrow_line.attr('points')
          let arrow_line_points = this.getArrayFromStr(spoints)

          this.tail_anchor.style.left = this.parent_canvas.scaleX(arrow_line_points[2] - 5) + "px"
          this.tail_anchor.style.top = this.parent_canvas.scaleY(arrow_line_points[3] - 5) + "px"
          this.tip_anchor.style.left = this.parent_canvas.scaleX(arrow_tip_points[0] - 5) + "px"
          this.tip_anchor.style.top = this.parent_canvas.scaleY(arrow_tip_points[1] - 5) + "px"
        }
      }
    }
  }

  removeAnchors() {
    if (this.tip_anchor) {
      this.canvas.removeChild(this.tip_anchor)
      this.tip_anchor = undefined
    }
    if (this.tail_anchor) {
      this.canvas.removeChild(this.tail_anchor)
      this.tail_anchor = undefined
    }

  }
  perpareToStartMoving(arrow_id: string, $event?: any) {
    let arrow = this.arrows[arrow_id]
    if (arrow) {
      let childern: any[] = arrow.children()
      if (childern.length > 1) {
        this.drawing_arrow_tip = childern[0]
        this.drawing_arrow_line = childern[1]
        let spoints = this.drawing_arrow_tip.attr('points')
        let arrow_tip_points = this.getArrayFromStr(spoints)
        spoints = this.drawing_arrow_line.attr('points')
        let arrow_line_points = this.getArrayFromStr(spoints)
        this.startX = $event.offsetX
        this.startY = $event.offsetY
        this.arrow_tail_x = arrow_line_points[2] //THE INDICES ARE SET UP in makeArrow
        this.arrow_tail_y = arrow_line_points[3]
        this.arrow_tip_x = arrow_tip_points[0]
        this.arrow_tip_y = arrow_tip_points[1]
      }
    }

  }
  setColor(id: string, color: string) { //subclased
    let arrow = this.arrows[id]
    if (arrow) {
      let childern: any[] = arrow.children()
      if (childern.length > 1) {
        this.drawing_arrow_tip = childern[0]
        this.drawing_arrow_line = childern[1]
        this.drawing_arrow_tip.fill(color)
        this.drawing_arrow_line.fill(color).stroke({ width: this.pencil_width, color: color })
      }
    }
  }
  setPencilSize(id: string, size: number) {
    let arrow = this.arrows[id]
    if (arrow) {
      let childern: any[] = arrow.children()
      if (childern.length > 1) {
        let drawing_arrow_tip = childern[0]
        let drawing_arrow_line = childern[1]
        drawing_arrow_line.stroke({ width: size })
      }
    }
  }

}
