import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from '../../chapala-mls-service';
import { SetSearch } from '../../chapala-mls.actions';
import { ChapalaMLSState } from '../../chapala-mls.model';
import { gotCities, gotMLSMsg } from '../../chapala-mls.selectors';
import { SelectorComponent } from './selector.component';

@Component({
  selector: 'sel-city',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']

})
export class CityComponent extends SelectorComponent {
  cities: any;

  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);
    let me = this
    try {
      this.chapala_mls_service.getStates()
    } catch (e) {
      console.error(e)
    }

    this.setTitle("City")

    this.subscriptions.push(this.chapalaMLState.select(gotCities).subscribe((cities: any[]) => {
      this.cities = cities
      this.setOptions(cities, "city")
    }))

    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.select) {
          if (msg.select.hasOwnProperty("City")) {
            let index = msg.select["City"]
            this.chapalaMLState.dispatch(new SetSearch("city", this.cities[index]))
          }
        }
      }
    }))
  }

  selOption(i: number) {
    super.selOption(i)
    // this.chapalaMLState.dispatch(new SetSearch("city", this.cities[i]))
  }
  all() {
    super.all()
    let msg: any = {
      show_all: true
    }
    this.chapala_mls_service.sendMsg(msg)
  }
}
