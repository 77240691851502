import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { validateEmail, colors } from 'src/app/utils';
import { CanvasState } from 'src/app/canvas/canvas.model';
import { SelectToolOpts, SelSigner, SetSigners } from 'src/app/canvas/canvas.actions';
import { selectedInput, selectToolOptions, selSignerKey, signers } from 'src/app/canvas/canvas.selectors';
import { WSSService } from 'src/app/wss/wss.service';
@Component({
  selector: 'sel-signer',
  templateUrl: './sel-signer.component.html',
  styleUrls: ['./sel-signer.component.scss']
})
export class SelSignerComponent {
  editing_index: number = -1;
  editing: string = ""

  signers: any
  signer_key: string | undefined
  signers_keys: string[] = []
  invialid_email_index: number = -1;
  bShowSelAll: boolean = true
  // Vibrant Blue: #3B5FE0 Vibrant Orange: #E06D3B Vibrant Yellow: #E0D53B Vibrant Green: #3DE049 Vibrant Teal: #3BE0C4 Vibrant Cyan: #3BDCE0 Vibrant Purple: #A13BE0 Vibrant Magenta: #E03BCF Vibrant Pink: #E03B8D Vibrant Indigo: #653BE0 Vibrant Red: #E03B3D

  no_email: boolean = false;
  subscriptions: any[] = []
  selected_input: string = ""

  constructor(
    public canvasStore: Store<CanvasState>,
    // private wss_service: WSSService
  ) {
    console.log("sel-signer")
    this.canvasStore.select(selectedInput).subscribe((selected_input: string) => {
      this.selected_input = selected_input
    })
    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      if (opt.name == 'sel-signer1') {
        this.no_email = true
      }
    }))
    this.subscriptions.push(this.canvasStore.select(signers).subscribe((signers: any) => {
      this.signers = signers
      this.signers_keys = Object.keys(this.signers)
      if (this.signers_keys.length == 0) {
        this.newSigner()
      }
      this.selByKey()
    }))
    this.subscriptions.push(this.canvasStore.select(selSignerKey).subscribe((signer_key: string | undefined) => {
      this.signer_key = signer_key
      this.selByKey()
      this.setColors()
    }))
  }
  setColors() { }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  selByKey() {
    let signers: any = Object.assign({}, this.signers)
    if (this.signers && this.signer_key) {
      for (let i = 0; i < this.signers_keys.length; i++) {
        let key = this.signers_keys[i]
        let signer: any = Object.assign({}, signers[key])
        signer.selected = (this.signer_key == key)
        signers[key] = signer
      }
      this.signers = signers
    }
  }
  selRow(index: number) {
    this.signer_key = this.signers_keys[index]
    if (this.signer_key) {
      this.canvasStore.dispatch(new SelSigner(this.signer_key))
    }
  }
  selRowAndClose(index: number) {
    this.signer_key = this.signers_keys[index]
    if (!this.canClose()) {
      return
    }
    if (this.signer_key) {
      this.canvasStore.dispatch(new SelSigner(this.signer_key))
      this.canvasStore.dispatch(new SelectToolOpts(""))
    }
  }

  changeTitle($event: any, index: number) {
    if ($event) {
      this.changeSigner(index, "title", $event)
      if ($event.length > 0) {
        this.setEditing(index, 'name')
      }
    }
  }
  changeName($event: any, index: number) {
    if ($event) {
      this.changeSigner(index, "name", $event)
      if ($event.length > 0) {
        this.setEditing(index, 'email')
      }
    }
  }
  changedEmail($event: any, index: number) {
    if ($event) {
      //First make sure the email is not in other emails
      for (let i = 0; i < this.signers_keys.length; i++) {
        if (i != index) {
          let key = this.signers_keys[i]
          let signer: any = Object.assign({}, this.signers[key])
          if (signer.email == $event) {
            this.invialid_email_index = index
            return
          }
        }
      }


      if (validateEmail($event)) {
        this.no_email = false;
        this.invialid_email_index = -1
        this.changeSigner(index, "email", $event)
        if ($event.length > 0 && ++index < this.signers_keys.length) {
          this.setEditing(index, 'title')
        }
        this.SetSigners()

      } else {
        this.invialid_email_index = index
      }
    }
  }

  changeSigner(index: number, key: string, value: any) {
    let signers: any = Object.assign({}, this.signers)
    let signer_key = this.signers_keys[index]
    let signer: any = Object.assign({}, signers[signer_key])
    signer[key] = value
    signers[signer_key] = signer
    this.signers = signers
    this.SetSigners()
  }

  edit(i: number, editing: string) {
    let key = this.signers_keys[i]
    if (key) {
      this.canvasStore.dispatch(new SelSigner(key))
    }
    this.setEditing(i, editing)
  }

  setEditing(i: number, editing: string) {
    this.editing_index = i
    this.editing = editing
  }
  newSigner() {
    if (this.signers_keys.length > 0) {
      let last_signer_key = this.signers_keys[this.signers_keys.length - 1]
      let last_signer = this.signers[last_signer_key]
      if (last_signer.title.length == 0 && last_signer.name.length == 0 && last_signer.email.length == 0) {
        return
      }
    }
    let signers = Object.assign([], this.signers)
    let new_key = new Date().getTime().toString()
    let last_color = this.signers_keys.length
    signers[new_key] = { color: colors[last_color], name: "", title: "", email: "" }
    this.signers = signers
    let signer_keys = Object.assign([], this.signers_keys)
    signer_keys.push(new_key)
    this.signers_keys = signer_keys

    this.SetSigners()
  }

  toogleAllSel() {
    let signer_keys = Object.assign([], this.signers_keys)
    let signers: any = Object.assign({}, this.signers)
    for (let i = 0; i < signer_keys.length; i++) {
      let key = signer_keys[i]
      let signer: any = Object.assign({}, signers[key])
      signer.selected = this.bShowSelAll
      signers[key] = signer
    }
    this.signers = signers
    this.bShowSelAll = !this.bShowSelAll
  }

  delete() { //Todo: When a signer is deleted we need to delete all ths svg nodes for that signer
    let signers: any = {}
    let signers_keys: string[] = []
    for (let i = 0; i < this.signers_keys.length; i++) {
      let key = this.signers_keys[i]
      let signer: any = Object.assign({}, this.signers[key])
      if (!signer.selected) {
        signers[key] = signer
        signers_keys.push(key)
      }
    }
    this.signers = signers
    this.signers_keys = signers_keys
    this.SetSigners()

  }
  cancel() {
    //Before we leave, the signers need to have an id, of email
    // if (!this.canClose()) {
    //   return
    // }
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
  canClose() {
    if (this.invialid_email_index >= 0) {
      return false
    }
    if (this.signer_key) {
      let signer = this.signers[this.signer_key]
      if (!signer) {
        return false;
      }
      if (!validateEmail(signer.email)) {
        this.no_email = true
        return false;
      }
    } else {
      return false;
    }
    return true;
  }
  SetSigners() {
    this.canvasStore.dispatch(new SetSigners(this.signers))
  }
}
