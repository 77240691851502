import { Component, Input } from '@angular/core';

@Component({
  selector: 'k-spinner',
  templateUrl: './kspinner.component.html',
  styleUrls: ['./kspinner.component.scss']
})
export class KspinnerComponent {
  //  @ViewChild('img') img: ElementRef | undefined;
  @Input() spinner = "assets/images/kspinner.svg"
  constructor() { }

  ngAfterViewInit(): void {
  }

}
