/*
is the pen over video in both sides, it can be over a video feed
over an shared screen or over youtube video

We need to keep the aspect ratio to be the same as the caller

If the caller is showing the on_boarding target in an iframe and sharing its window (not an extrnal window)
we need to adjust the frame so we show only the iframe part, this is done by setOnBoardDims
*/

import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatState } from 'src/app/chat/chat.model';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { checkVideo, streams } from 'src/app/k-video/k-video.selectors';
import { PagesState } from 'src/app/pages/pages.model';
import { ToolService } from 'src/app/tools/tool.service';
import { WSSState } from 'src/app/wss/wss.model';
import { WSSService } from 'src/app/wss/wss.service';
import { SVG, extend as SVGextend, Element as SVGElement, G } from '@svgdotjs/svg.js'


import { CanvasService } from '../canvas.service';
import { MetaPageService } from '../meta-page.service';
import { fitImgInTarget } from 'src/app/utils';
import { CanvasComponent } from '../canvas/canvas.component';
import { lastOnBoard } from 'src/app/wss/wss.selectors';
// import { kPencil } from '../draw/pencil';
@Component({
  selector: 'video-pen',
  templateUrl: './video-pen.component.html',
  styleUrls: ['./video-pen.component.scss']
})
export class VideoPenComponent extends CanvasComponent {
  video_roll: string = ""
  bMirrow: boolean = false

  @ViewChild('outComponent') outComponent: ElementRef | undefined
  @ViewChild('divContainer') container: ElementRef | undefined
  @ViewChild('videoElement') video: ElementRef | undefined;
  container_height: number = 500;
  container_width: number = 500;
  container_top: number = 0;
  container_left: number = 0;
  canvas_height: number = 500;
  canvas_width: number = 500;
  canvas_top: number = 0;
  canvas_left: number = 0;
  bSmall: boolean = false
  streams: any
  share_stream: MediaStream | undefined
  bCanvasReady: boolean = false
  @Input() set roll(r: string) {
    if (r) {
      this.video_roll = r
      this.bMirrow = (this.video_roll == 'me' || this.video_roll == "rep")

    } else {
      this.video_roll = ""
    }
    this.setVideoStream()
  }
  @Input() rv_l: number = 0

  @Input() set rv_r(r: number) {
    this.rv_r1 = r
    this.rv_rw = "calc(100% - " + r + "px)"
  }
  rv_r1: number = 0;
  rv_rw: string = "0"
  last_on_board: any

  wall_l: number = 0
  wall_t: number = 0
  wall_w: number = 0
  wall_h: number = 0

  twall_l: number = 0
  twall_t: number = 0
  twall_w: number = 0
  twall_h: number = 0


  // local_draw_pencil!: kPencil
  // remote_draw_pencil!: kPencil


  //
  // polygon_id: string = ""
  local_drawing_poly: any | undefined
  local_poly_array: number[] = []

  remote_drawing_poly: any | undefined
  remote_poly_array: number[] = []

  bSendToRemote: boolean = true
  bDrawLocal: boolean = true

  scketches: any[] = []
  last_scketch_at: number = 0
  fadeInterval: any

  constructor(
    public canvasStore: Store<CanvasState>,
    public wssState: Store<WSSState>,
    public wss_service: WSSService,
    public canvas_service: CanvasService,
    public pagesState: Store<PagesState>,
    public chatState: Store<ChatState>,
    public tools_service: ToolService,
    public meta_server: MetaPageService,
    private kVideoState: Store<KVideoState>,

  ) {
    super(canvasStore, wssState, wss_service, canvas_service, pagesState, chatState, tools_service, meta_server);
    console.log("video-canvas")
  }
  ngAfterViewInit() {

    let me = this
    if (this.video) {

      this.video.nativeElement.addEventListener('loadeddata', function($event: any) {
        console.log("Loaded videoHeight " + $event.srcElement.videoWidth + " " + $event.srcElement.videoHeight);
        if (!me.last_on_board) {
          me.setCanvas()
        }
      })
    }


    this.subscriptions.push(this.kVideoState.select(checkVideo).subscribe((check: any | undefined) => {
      if (check) {
        if (check.role == "local_share" || check.role == "remote_share" && this.share_stream) {
          delete this.share_stream
        }
      }
    }))
    this.subscriptions.push(this.kVideoState.select(streams).subscribe((streams: any) => {
      // console.log("k-video  stream for " + JSON.stringify(Object.keys(streams)))
      let share_stream = streams["local_share"]
      if (!share_stream) {
        share_stream = streams["remote_share"]
      }

      if (share_stream && !this.share_stream) {
        let vts = share_stream.getVideoTracks()
        if (vts.length > 0) {
          this.share_stream = share_stream
          let interval = setInterval(() => {
            if (this.video) {
              let newStream = new MediaStream()
              newStream.addTrack(vts[0])
              this.video.nativeElement.srcObject = newStream
              this.video.nativeElement.volume = 0
              clearInterval(interval)
            }
          }, 100)
        }
      } else if (this.share_stream) {
        if (this.share_stream && !share_stream) {
          this.share_stream = share_stream //This will remove the share element
        }
      } else {
        this.streams = streams
        this.setVideoStream()
      }

    }))


    const fade_in = 5000
    let fade_in_delta = 255 / fade_in
    this.fadeInterval = setInterval(() => {
      let msecs = (new Date().getTime() - this.last_scketch_at)
      if (msecs > fade_in) {
        for (let i = 0; i < this.scketches.length; i++) {
          let scketch = this.scketches[i]
          scketch.node.remove()

        }
        this.scketches = []
      } else {


        let intencity = 255 - Math.floor(fade_in_delta * msecs)
        // console.log("intencity " + intencity)

        // let ncolor = "#8F5CFF" + intencity.toString(16)
        for (let i = 0; i < this.scketches.length; i++) {
          let scketch = this.scketches[i]
          let ncolor = scketch.node.getAttribute('stroke').substring(0, 7) + intencity.toString(16);
          scketch.node.setAttribute('stroke', ncolor);
        }
      }
    }, 100)

  }
  ngOnDestroy(): void {
    super.ngOnDestroy()
    if (this.fadeInterval) {
      clearInterval(this.fadeInterval)
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(_event?: any) {

    this.setCanvas()

  }

  setVideoStream() {
    if (this.video) {
      if (this.video_roll && this.streams && this.streams[this.video_roll]) {
        this.video.nativeElement.srcObject = this.streams[this.video_roll]
      } else {
        this.video.nativeElement.srcObject = undefined
      }
    }
  }
  setCanvas() {
    setTimeout(() => {
      let ns = this.setVSize()

      this.container_height = ns.height
      this.container_width = ns.width
      this.container_top = ns.top
      this.container_left = ns.left

      this.canvas_height = ns.height
      this.canvas_width = ns.width
      this.canvas_top = ns.top
      this.canvas_left = ns.left



      if (!this.bCanvasReady && this.container) {
        this.bCanvasReady = true
        this.addCanvasToDiv()//rue, this.container.nativeElement, this.page_svg, ns.width, ns.height)
      } else {
        this.my_canvas.style.width = this.canvas_width + "px"
        this.my_canvas.style.height = this.canvas_height + "px"
        // this.my_canvas.style.background = "#ff000030"
        let childern = this.my_canvas.children
        if (childern) {
          let svg_node = childern[0]
          if (svg_node) { // scale is true&& bScale) {
            this.viewBoxWidth = this.canvas_width
            this.viewBoxHeight = this.canvas_height
            this.canvas_service.setSVGDim(this.viewBoxWidth, this.viewBoxHeight)
            let svg_node = childern[0]
            this.canvas_service.setSVGDim(this.viewBoxWidth, this.viewBoxHeight)
            if (svg_node) { // scale is true&& bScale) {
              svg_node.setAttribute("viewBox", "0 0 " + this.viewBoxWidth + " " + this.viewBoxHeight);
            }
          }
        }
      }
    })
  }

  setVSize() { //keps a .75 aspect ratio

    let width = 0
    let top = 0;
    let left = 0
    let height = 0
    let out_w = 0
    let out_h = 0
    if (this.outComponent && this.container) {
      // if (this.video) {
      this.bSmall = this.outComponent.nativeElement.clientHeight < 200
      out_w = this.outComponent.nativeElement.clientWidth
      out_h = this.outComponent.nativeElement.clientHeight
      height = out_h
      let img_w = 640
      let img_h = 480
      if (this.video) {
        img_w = this.video.nativeElement.videoWidth
        img_h = this.video.nativeElement.videoHeight
      }
      if (this.video_roll == "share" && this.video) {
        img_w = this.video.nativeElement.videoWidth
        img_h = this.video.nativeElement.videoHeight
      }
      let rv = fitImgInTarget(img_w, img_h, out_w, out_h)

      return rv;
      // }
    }
    return {
      height: height,
      width: width,
      top: top,
      left: left
    }
  }
  scalePointsToViewBox(poly_array: number[], viewBox: any) { //used after a move\
    if (this.bNoScale) {
      return poly_array;
    }
    let new_points: number[] = []
    console.log("poly set anchors ")
    for (let i = 0; i < poly_array.length; i += 2) {
      let x1: number = poly_array[i]
      if (this.video_roll == "kuula") {
        /*          ***********-*-*************
            viewBox            |  | my_canvas *
          ------------         |  |           *
          |    x     |         |  |           *
          ------------         |  |           *
                    *          |  |           *
                    ************-*-************
        */
        let view_box_scaled_width = viewBox.width * this.my_canvas.clientHeight / viewBox.height
        x1 = x1 * view_box_scaled_width / viewBox.width
        let offset_x = (view_box_scaled_width - this.my_canvas.clientWidth) / 2
        x1 -= offset_x

        /*  -----
            |   |    -----***-----
            |   |    |    * *    |
            |   |    |    * *    |
            |   |    -----***-----
            -----
          viewBox
        */

      } else {
        x1 = this.scaleXToWidth(poly_array[i], viewBox.width)
      }

      new_points.push(x1)
      new_points.push(this.scaleYtoHeight(poly_array[i + 1], viewBox.height))
    }
    return new_points
  }
  setSize(aspect_ratio: number) { //keps a .75 aspect ratio
    let width = 0
    let top = 0;
    let left = 0
    let height = 0
    let out_w = 0
    let out_h = 0
    if (this.divContainer && this.outComponent) {
      out_w = this.outComponent.nativeElement.clientWidth - 8
      out_h = this.outComponent.nativeElement.clientHeight - 8 //border 4px
      height = out_h
      width = out_h / aspect_ratio
      if (width > out_w) {
        width = out_w
        height = width * aspect_ratio
      }
      top = (out_h - height) / 2
      left = (out_w - width) / 2
    }
    return {
      height: height,
      width: width,
      top: top,
      left: left
    }
  }

  setAspectRatio() {
    // if (!this.last_on_board) {
    //   let ns = this.setOnBoardDims()
    // }
  }
  //****************************************************************************
  //From canvas.component.ts
  //****************************************************************************
  addCanvasToDiv() {
    if (!this.container) {
      return
    }

    const canvas = document.createElement("div");

    this.my_canvas = canvas
    canvas.style.position = "absolute"
    canvas.style.overflow = "hidden"
    // canvas.style.background = "#ff000030"
    canvas.style.left = "0px"



    this.canvas_height

    canvas.style.width = this.canvas_width + "px"
    canvas.style.height = this.canvas_height + "px"



    // canvas.style.background = "#ffaa0020"
    this.container.nativeElement.appendChild(canvas);
    this.draw_tool = SVG().addTo(canvas)
    let childern = canvas.children
    if (childern) {
      let svg_node = childern[0]
      if (svg_node) { // scale is true&& bScale) {
        this.viewBoxWidth = canvas.clientWidth
        this.viewBoxHeight = canvas.clientHeight
        this.canvas_service.setSVGDim(this.viewBoxWidth, this.viewBoxHeight)

        svg_node.setAttribute("viewBox", "0 0 " + this.viewBoxWidth + " " + this.viewBoxHeight);
      }
    }
    this.draw_tool.node.dataset['canvas'] = "main"
    // this.local_draw_pencil = new kPencil(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)
    // this.remote_draw_pencil = new kPencil(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)

    let el: HTMLElement = canvas


    let svg = this.draw_tool.node
    if (svg) {        //This is to keep the aspect ratio in an image
      svg.style.width = "100%"
      svg.style.height = "100%"
    }

    if (el.parentNode) {
      el.addEventListener('pointerdown', onMouseDown, false);
      el.addEventListener('touchstart', onMouseDown, false);
      el.addEventListener('pointermove', onMouseMove, false);
      el.addEventListener('touchmove', onMouseMove, false);
      el.addEventListener('pointerup', onMouseUp, false);
      el.addEventListener('touchend', onMouseUp, false);
      el.addEventListener('click', onClicked);
    }

    // this.showGrid()
    let me = this
    return this.draw_tool
    //**************************************************************************
    // Mouse Down
    //**************************************************************************
    function onClicked(this: any, $event: any) {
      if (me.bSendToRemote && me.video) {
        me.canvas_service.sendCanvasMsg("mouse", "click", me.mouse_lbl, {
          x: $event.offsetX,
          y: $event.offsetY,
          client_width: me.video.nativeElement.clientWidth,
          client_height: me.video.nativeElement.clientHeight
        })
      }
      // else {
      //     console.log("local mouse down")
      //   me.local_draw_pencil.onMouseDown($event, $event.offsetX, $event.offsetY)
      // }
    }
    //**************************************************************************
    // Mouse Down
    //**************************************************************************
    function onMouseDown(this: any, $event: any) {
      if (me.bSendToRemote && me.video) {
        me.canvas_service.sendCanvasMsg("mouse", "down", me.mouse_lbl, {
          x: $event.offsetX,
          y: $event.offsetY,
          client_width: me.video.nativeElement.clientWidth,
          client_height: me.video.nativeElement.clientHeight
        })
      }
      if (me.bDrawLocal) {
        console.log("local mouse down")
        if (!me.local_drawing_poly) {
          me.local_poly_array = [$event.offsetX, $event.offsetY]
          me.local_drawing_poly = me.draw_tool.polyline(me.local_poly_array).fill('none').stroke({ width: 5, color: '#8F5CFF', linecap: 'round' })
        }
      }
    }

    //**************************************************************************
    // Mouse Move
    //**************************************************************************
    function onMouseMove(this: any, $event: any) {
      // $event.currentTarget.style.cursor = "none"
      if (me.bDrawLocal && me.local_drawing_poly) {
        me.local_poly_array.push($event.offsetX, $event.offsetY)
        me.local_drawing_poly.attr({ points: me.local_poly_array })
      }
      // let x =
      // if (me.last_on_board) {
      //   x = $event.offsetX * this.clientWidth / me.container_width
      // }
      if (me.video) {
        me.canvas_service.sendCanvasMsg("mouse", "move", me.mouse_lbl, {
          x: $event.offsetX,
          y: $event.offsetY,
          client_width: me.video.nativeElement.clientWidth,
          client_height: me.video.nativeElement.clientHeight
        })
      }

    }
    //**************************************************************************
    // Mouse Up
    //**************************************************************************
    function onMouseUp(this: any, $event: any) {
      if (me.bSendToRemote && me.video) {
        me.canvas_service.sendCanvasMsg("mouse", "up", this.mouse_lbl, {
          x: $event.offsetX,
          y: $event.offsetY,
          client_width: me.video.nativeElement.clientWidth,
          client_height: me.video.nativeElement.clientHeight
        })
      }
      if (me.bDrawLocal && me.local_drawing_poly) {
        console.log("local mouse up")
        me.scketches.push(me.local_drawing_poly)
        me.last_scketch_at = new Date().getTime()
        me.local_drawing_poly = undefined
        // me.local_draw_pencil.onMouseUp($event)
      }
    }
  }

  bRemoteIsDrawing: boolean = false
  processIncomingMouseMsg(msg: any) {
    if (this.video && msg) {
      let x
      let y
      if (msg.data) {
        x = msg.data.x * this.video.nativeElement.clientWidth / msg.data.client_width
        y = msg.data.y * this.video.nativeElement.clientHeight / msg.data.client_height
      } else {
        x = msg.x
        y = msg.y
      }
      if (msg.action == "down") {
        this.bRemoteIsDrawing = true
        if (!this.remote_drawing_poly) {
          this.remote_poly_array = [x, y]
          this.remote_drawing_poly = this.draw_tool.polyline(this.remote_poly_array).fill('none').stroke({ width: 5, color: '#FFBA55', linecap: 'round' })
        }
        console.log("draw remote down " + x + ", " + y)
      }
      if (msg.action == "move") {
        if (this.bRemoteIsDrawing) {
          if (this.remote_drawing_poly) {
            this.remote_poly_array.push(x, y)
            this.remote_drawing_poly.attr({ points: this.remote_poly_array })
          }

          console.log("draw remote move " + x + ", " + y)

        }
        if (msg.canvas == "mouse" && !msg.caller_id) { //we dont need to show our own email or name
          if (!this.other_mouses[msg.id]) {
            this.makeMouseBox(msg)
          }
          this.other_mouses[msg.id].style.left = (x - 3) + "px"
          this.other_mouses[msg.id].style.top = (y - 10) + "px"
        }
      }

      if (msg.action == "up") {
        this.bRemoteIsDrawing = false
        // this.remote_draw_pencil.onMouseUp(msg.data)
        if (this.remote_drawing_poly) {
          console.log("local mouse up")
          this.scketches.push(this.remote_drawing_poly)
          this.last_scketch_at = new Date().getTime()
          this.remote_drawing_poly = undefined
        }

        console.log("draw remote up " + x + ", " + y)
        setTimeout(() => {
          this.undo()
        }, 5000)

      }
    }
  }


}
