<div class="out">

  <img class="img" [src]="url_photo">
  <div class="content">
    <div class="price">
      <strong> {{_property.currency_code}}{{_property.price | currency}}</strong>
    </div>

    <div class="small_text">
      {{_property.address}} {{_property.city_name}} {{_property.state_name}}
    </div>
    <!-- <div class="small_text">
      {{_property.region}}
    </div> -->
    <div class="small_text">
      {{_property.bed_room}} <img class="icon" src="assets/images/bed.svg"> {{_property.bath_room| number: '1.0-0'}} <img class="icon" src="assets/images/bath.svg">{{_property.lot_size}} Lot m², {{_property.square_feet}} Constr. m²
    </div>

  </div>
</div>
