import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { WSSState } from '../wss/wss.model';
import { customerId, sessionId } from '../wss/wss.selectors';
import { PagesState } from './pages.model';
import { hostUrl } from './pages.selectors';
@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  host_url: string | undefined;
  customer_id: string | undefined;
  session_id: string | undefined;


  public onProgres: Function | undefined
  public onFileUploaded: Function | undefined

  constructor(
    private pagesState: Store<PagesState>,
    private wssState: Store<WSSState>,

  ) {
    this.pagesState.select(hostUrl).subscribe((host_url: string | undefined) => {
      this.host_url = host_url
      //If there was a host_url in
      let host = localStorage.getItem('host')
      if (host) {
        this.host_url = host
      }
    })

    this.wssState.select(sessionId).subscribe((session_id: string | undefined) => {
      this.session_id = session_id
    })
    this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      this.customer_id = customer_id
    })
  }

  getUploaderBody(type: string, bRepAsset: boolean) {
    console.log("uploaded service")
    let protocol: string = "https://"
    let host = localStorage.getItem('host')
    if (host) {
      if (host.startsWith("local")) {
        protocol = location.protocol + "//"
      }
    }

    let url = protocol + this.host_url + "/api/" + type
    if (bRepAsset) {
      url += "/user"
    } else {
      url += "/" + this.customer_id
    }
    let token = 'Bearer ' + this.session_id
    let alias: string = type
    if (alias == "contract") {
      alias = "pdf"
    }
    return {
      url: url,
      itemAlias: alias,
      authToken: token
    };
  }
  /*
  authToken: "Bearer 1341a3cd-b08a-420f-9d2c-bfe0c672713f"
  itemAlias: "image"
  url: "http://local.callvendi.com/api/image/user"

  */
  setUpUploader(aset_type: string, bRepAsset: boolean): FileUploader {

    let body = this.getUploaderBody(aset_type, bRepAsset);
    let uploader: FileUploader = new FileUploader(body)
    // uploader.onProgressItem = (progress: any) => {
    //   if (this.onProgres) {
    //     this.onProgres(progress)
    //   }
    // };


    return uploader;
  }

}
