import * as store from '@ngrx/store';

export enum ControlsActionTypes {
  SetBtnBackColor = '[btn_back]color',
  SetBtnTextColor = '[btn_text]color',
  FeedBack = '[feedback]',
  LinkOption = '[link]opt'
}
export class LinkOption implements store.Action {
  readonly type = ControlsActionTypes.LinkOption;
  constructor(public num: number) { }
}
// export class FeedBack implements store.Action {
//   readonly type = ControlsActionTypes.FeedBack;
//   constructor(public feed_back: string, public msg_type: string) { }
// }

export class SetBtnBackColor implements store.Action {
  readonly type = ControlsActionTypes.SetBtnBackColor;
  constructor(public color: string) { }
}
export class SetBtnTextColor implements store.Action {
  readonly type = ControlsActionTypes.SetBtnTextColor;
  constructor(public color: string) { }
}

export type ControlsAction =
  | SetBtnBackColor
  | SetBtnTextColor
  // | FeedBack
  | LinkOption
  ;
