/*
(C)2020 Sergio A. Fernandez, Ph.D.



*/


export interface PagesState {

  is_phone: boolean,
  in_page: string,
  queue: any[],
  call_waiting: any | undefined
  call_rejected: any | undefined
  //user data
  my_picture: any,
  my_email: string,
  my_name: string,
  my_last_name: string,
  my_company: string,
  my_company_title: string,
  my_sms: string,
  my_company_name: string,
  my_roll: string,


  caller_first_name: string,
  caller_last_name: string,
  caller_cia_name: string,
  caller_email: string,
  caller_is_phone: boolean
  rep_name: string,
  rep_email: string,
  manager_name: string,
  manager_email: string,
  //User Info received at login

  work_time: any//{offset: null, zone: null}
  accepted_eula_on: string | undefined //"2022-06-29T16:37:32.598Z"
  host_url: string | undefined
  rep_url: string | undefined
  cia_qr_img: any | undefined
  rep_qr_img: any | undefined

  assets: any,
  selected_assets: string[],
  assets_and_contracts: any,
  active_calls: any[],
  users: any,
  reps: any,

  customize_account: any | undefined,
  show_asset: any | undefined
  show_folder: any | undefined
  i_am_manager: boolean,
  contracts: any | undefined
  rep_link: string | undefined

  calls: any[],
  rec_status: string,
  debug: string

  header_path: any[]
  notify_snd: boolean,
  notify_usr: any | undefined
  contact_info: string
  open_menu: boolean
  has_google_calendar: boolean

  has_api: boolean
  on_board: any
  on_board_urls: any[]
  on_boarding: boolean
  api_params: any
  show_api: boolean

  filter_options: any[] | undefined
  filtered_assets: any[]
  filtered_folders: any[]
  unselect_all: number
  tab: string;
  search: string;
  save_assets: any;
  welcome: string | undefined
  in_phone: string | undefined
  close_progress: number
  api_url_params: string | undefined
  play_rec: any
  code_from_url: string | undefined
  // ask_code_from: string | undefined
  calendly_rep: string | undefined

  acc_created_at: Date | undefined
  show_cc: string | undefined
  invoices: any[]
  converge: any
  ask_hangup: boolean
  bill_info: any
  plans: any[],
  code: any,
  converge_acc: string | undefined
  merchant_id: string | undefined
  show_tool_add_asset: boolean
  acc_overdue: boolean
  num_active_calls: number
  site_enabled: boolean
  site_ready: boolean
}

export const initialPagesState: PagesState = {
  is_phone: false,
  queue: [],
  call_waiting: undefined,
  call_rejected: undefined,
  in_page: "/assets",

  //user data
  my_picture: undefined,
  my_email: "",
  my_name: "",
  my_last_name: "",
  my_company: "",
  my_company_title: "",
  my_sms: "",
  my_company_name: "",
  my_roll: "rep",

  caller_email: "",
  caller_first_name: "",
  caller_last_name: "",
  caller_cia_name: "",
  caller_is_phone: false,
  rep_name: "",
  rep_email: "",
  manager_name: "",
  manager_email: "",


  work_time: {},//{offset: null, zone: null}
  accepted_eula_on: undefined,
  host_url: undefined,
  rep_url: undefined,
  cia_qr_img: undefined,
  rep_qr_img: undefined,
  assets: {},
  selected_assets: [],

  assets_and_contracts: {},
  active_calls: [],

  users: {},
  reps: {},

  customize_account: {
    avaliable_tx: "Call Now",
    border_radius: "20",
    btn_back: "#3B5FE0",
    btn_color: "white",
    offline_tx: "Book a Call",
    theme: "light"
  },
  show_asset: undefined,
  show_folder: undefined,
  i_am_manager: false,
  contracts: undefined,
  rep_link: undefined,

  calls: [],
  rec_status: "ready", //ready starting, recording, stopping
  debug: "",
  header_path: [],
  notify_snd: true,
  notify_usr: undefined,
  contact_info: "no",
  open_menu: false,
  has_google_calendar: false,
  has_api: false,
  on_board: undefined,
  on_board_urls: [],
  on_boarding: false,
  api_params: {},
  show_api: false,

  filter_options: undefined,
  filtered_assets: [],
  filtered_folders: [],
  unselect_all: 0,
  tab: "", //"agent"
  search: "",
  save_assets: {},
  welcome: undefined,
  in_phone: undefined,
  close_progress: 0,
  api_url_params: undefined,
  play_rec: undefined,
  code_from_url: undefined,
  // ask_code_from: undefined,
  calendly_rep: undefined,
  acc_created_at: undefined,
  show_cc: undefined,
  invoices: [],
  converge: undefined,
  ask_hangup: false,
  bill_info: undefined,
  plans: [],
  code: undefined,
  converge_acc: undefined,
  merchant_id: undefined,
  show_tool_add_asset: false,
  acc_overdue: false,
  num_active_calls: 0,
  site_enabled: false,
  site_ready: false
};
