import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from 'src/app/chapala-mls/chapala-mls-service';
import { SetSearch } from 'src/app/chapala-mls/chapala-mls.actions';
import { ChapalaMLSState } from 'src/app/chapala-mls/chapala-mls.model';
import { gotCities, gotMLSMsg, gotStates, searchBy, selProperty } from 'src/app/chapala-mls/chapala-mls.selectors';
import { SelectorComponent } from '../selector.component';

@Component({
  selector: 'mls-location',
  templateUrl: './location.component.html',
  styleUrls: ['../selector.component.scss', './location.component.scss']
})
export class LocationComponent extends SelectorComponent {
  bLocShowOptions: boolean = false
  search: string = ""
  states: any[] = [];
  sel_state: number = -1
  cities: any[] = [];
  sel_city: number = -1
  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);
    console.log("location")

    this.setTitle("Location")
    this.subscriptions.push(this.chapalaMLState.select(gotStates).subscribe((states: any[]) => {
      this.states = states
    }))

    this.subscriptions.push(this.chapalaMLState.select(gotCities).subscribe((cities: any[]) => {
      this.cities = cities
      this.findStateAndCity()
    }))
    this.chapalaMLState.select(selProperty).subscribe((msg: any) => {
      if (msg) {
        let msg: any = {
          show: {}
        }
        msg.show["Location"] = false
        this.chapala_mls_service.sendMsg(msg)
      }
    })

    this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      if (msg) {
        if (msg.show) {

          if (msg.show.hasOwnProperty("Location")) {
            this.bLocShowOptions = msg.show.Location
          } else {
            if (!msg.show.hasOwnProperty("State") && !msg.show.hasOwnProperty("City"))
              this.bLocShowOptions = false;
          }
          return;

          if (msg.show_all) {
            this.bLocShowOptions = false
            this.title = "Location"
            return
          }

          if (msg.select.hasOwnProperty("State")) {
            this.sel_state = msg.select["State"]
            this.setSearch()

          }
          if (msg.select.hasOwnProperty("City")) {
            this.sel_city = msg.select["City"]
            this.setSearch()
          }
        }
      }
    })

    this.chapalaMLState.select(searchBy).subscribe((search_by: any) => {
      if (search_by) {
        if (search_by.state) {
          this.title = search_by.state.state_name
        }
        if (search_by.city) {
          setTimeout(() => {
            this.title = search_by.city.city + ", " + this.title
            this.bLocShowOptions = false
          }, 100)
        }
      }
    })
  }
  showOptions() {
    let msg: any = {
      show: {}
    }
    if (this.bLocShowOptions) {
      msg.show["Location"] = false
    } else {
      msg.show["Location"] = true
    }
    this.chapala_mls_service.sendMsg(msg)
  }
  searchNow() {
    //find the state or the city using the typed string, and search by that

    console.log("Search by " + this.search)
    this.sel_state = -1
    this.sel_city = -1
    this.findStateAndCity()

  }
  findStateAndCity() {
    let search = this.search.toLowerCase()
    if (this.sel_state == -1) {

      for (let i = 0; i < this.states.length; i++) {
        let state: any = this.states[i]
        let name = state.state_name.toLowerCase()
        let rv = search.indexOf(name)
        if (rv >= 0) {
          this.sel_state = i;
          let msg: any = {
            select: { State: i }
          }
          this.chapala_mls_service.sendMsg(msg)
          this.search = state.state_name + " "
          console.log("Found " + name + "getting citites")
          return;
        }
      }
    } else if (this.sel_city == -1) {
      for (let i = 0; i < this.cities.length; i++) {
        let city: any = this.cities[i]
        let name = city.city.toLowerCase()
        let rv = search.indexOf(name)
        if (rv >= 0) {
          this.sel_state = i;
          let msg: any = {
            select: { City: i }
          }
          this.chapala_mls_service.sendMsg(msg)
          console.log("Found " + name + "getting properties")
          this.chapalaMLState.dispatch(new SetSearch("city", city))
          return;
        }
      }
    }
    if (this.sel_state != -1) {
      this.chapalaMLState.dispatch(new SetSearch("state", this.states[this.sel_state]))
    }
  }
  setSearch() {
    if (this.sel_city >= 0) {
      let city = this.cities[this.sel_city]
      this.search = city.city
    } else {
      this.search = ""
    }


    if (this.sel_state >= 0) {
      if (this.sel_city >= 0) {
        this.search += ", "
      }
      let state = this.states[this.sel_state]
      this.search += state.state_name
    }

  }
}
