import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard'
import { Store } from '@ngrx/store';
import { hostUrl } from 'src/app/pages/pages.selectors';
import { WSSState } from 'src/app/wss/wss.model';
import { customerId, myId } from 'src/app/wss/wss.selectors';
import { PagesState } from 'src/app/pages/pages.model';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { selectToolOptions } from 'src/app/canvas/canvas.selectors';
import { ControlsState } from '../controls.model';
import { linkOption } from '../controls.selectors';
import { LinkOption } from '../controls.actions';
import { NotifyUser } from 'src/app/pages/pages.actions';
;
@Component({
  selector: 'copy-links',
  templateUrl: './copy-links.component.html',
  styleUrls: ['./copy-links.component.scss']
})
export class CopyLinksComponent {

  bShowOptions: boolean = false;

  sel_option: number = 0
  options: any[] = [
    {
      text: "Personal Link"
    }, {
      text: "Company Link"
    }]
  company_link: string | undefined
  personal_link: string | undefined
  host_url: string | undefined
  customer_id: string | undefined
  my_id: string | undefined
  subscriptions: any[] = []
  @Input() only_cal: boolean = false
  constructor(
    private clipboard: Clipboard,
    private wssState: Store<WSSState>,
    private pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
    public controlsStore: Store<ControlsState>
  ) {
    console.log("copied links ")
    this.subscriptions.push(this.controlsStore.select(linkOption).subscribe((opt: number) => {
      this.sel_option = opt
    }))
    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      this.bShowOptions = (opt.name == 'link-opts')
    }))
    this.subscriptions.push(this.wssState.select(myId).subscribe((id: string | undefined) => {
      this.my_id = id
    }))
    this.subscriptions.push(this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      this.customer_id = customer_id
    }))
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
  }

  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }

  copyOption() {
    if (this.host_url && this.customer_id && this.my_id) {
      let link
      let protocol = "https://"
      if (this.host_url.indexOf("localhost") >= 0) {
        protocol = "http://"
      }
      if (this.sel_option == 0) {
        link = protocol + this.host_url + "?rep_id=" + this.my_id
        this.pagesState.dispatch(new NotifyUser("Personal link copied"))
      } else {
        link = protocol + this.host_url + "?customer_id=" + this.customer_id;
        this.pagesState.dispatch(new NotifyUser("Company link copied"))
      }
      if (this.only_cal) {
        link += "&call=true"
      }
      this.clipboard.copy(link);
      console.log("copied link " + link)

    }
  }

  selOption(i: number) {
    setTimeout(() => {
      this.sel_option = i;
      this.controlsStore.dispatch(new LinkOption(i))
      this.canvasStore.dispatch(new SelectToolOpts("")) //close the link
      this.copyOption()
    })
  }
  copy($event: any) {
    $event.stopPropagation()
    this.copyOption()
  }

  toogleOpts() {
    if (this.bShowOptions) {
      this.canvasStore.dispatch(new SelectToolOpts(""))
    } else {
      this.canvasStore.dispatch(new SelectToolOpts("link-opts"))
    }
  }
}
