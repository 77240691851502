<div class="scrn">
  <div class="abs_center out">

    <div class="title">
      {{title}} </div>
    <div class="tx">
      {{txt}} <strong>This is cannot be undone.</strong>
    </div>
    <div class="stx">
      {{conf}}
    </div>
    <div class="li">

      <input kzInput class="in" type="text" name="" value="" [(ngModel)]="conf_tx" (ngModelChange)="confChange()">

      <div class="btn" [ngClass]="{ready:bReady}"  kzLib (click)="doIt()">
        <div class="abs_center" [ngClass]="{del_b:_what=='del'}">
          {{btn}}
        </div>
      </div>
    </div>
    <img class="close" src="assets/images/close.svg" alt="close"  kzLib (click)="cancel()">
  </div>
</div>
