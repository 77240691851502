// src/app/auth.service.ts
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountInfo, AuthenticationResult, PublicClientApplication, SilentRequest } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class MSAuthService {
  constructor(
    private httpClient: HttpClient,
  ) {
    console.log("ms auth service")

  }


  base64URLEncode(bytes: Uint8Array) {
    let str = bytes.toString()
    const base64String = btoa(str);


    return base64String
  }


  async generateCodeChallenge(codeVerifier: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hash = await crypto.subtle.digest('SHA-256', data);
    const arr1 = new Uint8Array(hash);
    return this.base64URLEncode(arr1);
  }


  //https://learn.microsoft.com/en-us/entra/identity-platform/v2-oauth2-on-behalf-of-flow
  async getMSURL() {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.get('/ms_url').toPromise();
        resolve(rv.url)
      } catch (e: any) {
        reject(e.error);
      }
    })
  }

  logout() {

  }

  getToken() {

    return
  }
}
