import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';
import { RecaptchaService } from '../recaptcha.service';

@Component({
  selector: 'captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent {
  bIsTransparent: boolean = false;
  key: string | undefined
  @Input() doing: string = "na"

  @Output() token: EventEmitter<string> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();

  constructor(
    public pagesState: Store<PagesState>,
    public captcha_service: RecaptchaService

  ) {
    console.log("captcha")
  }
  ngAfterViewInit() {
    this.chackCaptcha()
  }
  async chackCaptcha() {
    try {
      let token = await this.captcha_service.checkCaptcha(this.doing)
      if (token) {
        this.token.emit(token)
      }
    } catch (e) {
      this.error.emit(e)
    }
  }
}
