<div class="scrn">
  <div class="abs_center out">
    <img *ngIf="bShowClose" src="assets/images/close.svg" class="close"  kzLib (click)="closeIt()">
    <div class="text stitle">
      {{title}}

    </div>
    <div class="text title">
      {{ends_in}}

    </div>
    <div class="text bold">
      Don't miss out on your keyzii Premium experience
    </div>
    <div class="text ">
      To keep enjoying all the benefits, please update your plan and add your card details now. It's quick and easy, and it ensures you won't lose access when your trial ends. Upgrade today and stay connected with keyzii!
    </div>
    <div class="text update"  kzLib (click)="openCC()">
      <div class="abs_center btn">
        {{btn_prompt}}
      </div>
    </div>
    <div class="text small">
      Monthly or Yearly | Cancel anytime
    </div>
  </div>
</div>
