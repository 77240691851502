<div class="out">


  <div class="title">
    keyzii<span class="dark">pay</span>
  </div>
  <div class="stitle">Invoice title </div>
  <input kzInput class="in" type="text" placeholder="Title" name="" value="" [(ngModel)]="inv_title" (ngModelChange)="changed()">
  <div class="stitle">Description</div>
  <textarea class="in desc" rows="4" type="text" placeholder="Invoice description " name="" value="" [(ngModel)]="inv_desc" (ngModelChange)="changed()">
</textarea>
  <div class="stitle">Price </div>
  <div class="cc_li">
    <input kzInput class="in" type="number" placeholder="$" name="" value="" [(ngModel)]="inv_price" (ngModelChange)="changed()">
  </div>

  <div class="send" [ngClass]="{btn_ready:bReady}"  kzLib (click)="sendInvoice()">
    <div class="abs_center btn">
      Send <span *ngIf="caller_first_name">to </span> <span class="dark">{{caller_first_name}} {{caller_last_name}}</span>
    </div>
  </div>

  <img src="assets/images/close.svg" class="close"  kzLib (click)="closeIt()">

</div>
