/*
Copyright(c) 2022 Sergio A. Fernandez, Ph.D.
*/

import { kPayAction, kPayActionTypes } from "./kpay.actions";
import { kPayState, initialkPayState } from "./kpay.model";



// let streams_cache: any = {}
export function kPayReducer(state = initialkPayState,
  action: kPayAction): kPayState {
  switch (action.type) {

    case kPayActionTypes.ShowKMenu: {
      const kPayState: kPayState = Object.assign({}, initialkPayState);
      kPayState.bShowMenu = action.bShow
      return kPayState;
    }
    case kPayActionTypes.StartKPay: {
      const kPayState: kPayState = Object.assign({}, initialkPayState);
      kPayState.running = "pay"
      kPayState.bShowScreen = true
      return kPayState;
    }
    case kPayActionTypes.StartKPlan: {
      const kPayState: kPayState = Object.assign({}, initialkPayState);
      kPayState.running = "plan"
      kPayState.bShowScreen = true
      return kPayState;
    }
    case kPayActionTypes.ReTry: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.error = undefined
      kPayState.retry++
      kPayState.error_no = 0
      kPayState.bShowScreen = false
      return kPayState;
    }
    case kPayActionTypes.RemError: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.error = action.error
      kPayState.error_no++
      kPayState.retry_running = kPayState.running
      kPayState.running = "error"
      kPayState.bShowScreen = true
      return kPayState;
    }
    case kPayActionTypes.SetPlanFromCaller: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.plan_from_caller = action.plan
      kPayState.plan_bar_step = "caller_plan"
      return kPayState;
    }
    case kPayActionTypes.SetInvoice: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.invoice = action.invoice
      kPayState.bShowScreen = false
      kPayState.pay_bar_step = "invoice"
      return kPayState;
    }
    case kPayActionTypes.SetPlanFromRep: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.plan_from_rep = action.plan
      kPayState.plan_bar_step = "rep_plan"
      kPayState.bShowScreen = false
      return kPayState;
    }
    case kPayActionTypes.StopKPay: {
      const kPayState: kPayState = Object.assign({}, initialkPayState);
      kPayState.running = undefined
      return kPayState;
    }
    case kPayActionTypes.CancelLocalKPay: {
      const kPayState: kPayState = Object.assign({}, initialkPayState);
      kPayState.local_cancel = true
      return kPayState;
    }
    case kPayActionTypes.CancelRemoteKPay: {
      const kPayState: kPayState = Object.assign({}, initialkPayState);
      kPayState.remote_cancel = true
      if (state.send_payment_to_svr) {
        kPayState.notify_done_pay++
      }
      return kPayState;
    }

    case kPayActionTypes.CancelRemoteKPay: {
      const kPayState: kPayState = Object.assign({}, initialkPayState);
      kPayState.remote_cancel = true
      return kPayState;
    }
    case kPayActionTypes.SetPlanFromCaller: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.plan_from_caller = action.plan
      return kPayState;
    }

    case kPayActionTypes.SetAcc: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.acc = action.acc
      kPayState.pay_bar_step = "acc"
      kPayState.plan_bar_step = "acc"
      return kPayState;
    }
    case kPayActionTypes.InvOk: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.pay_bar_step = "inv_ok"
      return kPayState;
    }
    case kPayActionTypes.GotCC: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.pay_bar_step = "got_cc"
      return kPayState;
    }

    case kPayActionTypes.SetCC: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.cc = action.cc
      return kPayState;
    }

    case kPayActionTypes.SetBillInfo: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.bill_info = action.bill_info
      kPayState.plan_bar_step = "process"
      return kPayState;
    }
    case kPayActionTypes.GotCaptchaToken: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.captcha_token = action.token
      return kPayState;
    }
    case kPayActionTypes.SetResponse: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.response = action.response
      let running = kPayState.running

      if (kPayState.running == "error") {
        running = kPayState.retry_running
      }
      if (running == "pay") {

        kPayState.send_payment_to_svr = {
          invoice: kPayState.invoice,
          acc: kPayState.acc,
          bill_info: kPayState.bill_info,
          response: kPayState.response
        }
      } else {
        kPayState.create_acc_in_svr = {
          plan: kPayState.plan_from_caller,
          acc: kPayState.acc,

          bill_info: kPayState.bill_info,
          response: kPayState.response
        }
      }
      return kPayState;
    }

    case kPayActionTypes.DoneKPay: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.done = true
      return kPayState;
    }

    case kPayActionTypes.SetTargetURL: {
      const kPayState: kPayState = Object.assign({}, state);
      kPayState.target_url = action.url
      kPayState.pay_bar_step = "done"
      kPayState.plan_bar_step = "done"

      return kPayState;
    }
    default: {
      return state;
    }
  }
}
