import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetVideo } from 'src/app/k-video/k-video.actions';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { streams } from 'src/app/k-video/k-video.selectors';
import { PagesState } from 'src/app/pages/pages.model';
import { showAsset } from 'src/app/pages/pages.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { DidStep, SelectToolOpts, SignerPic } from '../../canvas.actions';
import { progress, selSignerKey } from '../../canvas.selectors';

@Component({
  selector: 'get-user-pic',
  templateUrl: './get-user-pic.component.html',
  styleUrls: ['./get-user-pic.component.scss']
})
export class GetUserPicComponent {
  img_src: string = ""
  @ViewChild('videoElement') videoCapture: ElementRef | undefined;
  prompt: string = "Click camera to take picture of yourself"
  blob: Blob | undefined
  hide_video: boolean = false;
  done_color: string = "#DDD"

  signer_key: string | undefined;
  subscriptions: any[] = []
  title: string = "Live photo"

  constructor(
    private kVideoState: Store<KVideoState>,
    public canvasStore: Store<CanvasState>,
    public wss_service: WSSService,
    private pagesState: Store<PagesState>,

  ) {
    console.log("take user pic")
    this.kVideoState.dispatch(new SetVideo(true))

  }
  ngAfterViewInit() {

    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        if (asset.type == "contract") {
          if (asset.sec_opts) {
            if (asset.sec_opts.driver_lic) {
              setTimeout(() => {
                this.title = "Facial recognition"
              })
            }
          }
        }
      }
    }))
    this.subscriptions.push(this.canvasStore.select(selSignerKey).subscribe((signer_key: string | undefined) => {
      this.signer_key = signer_key
    }))
    this.subscriptions.push(this.kVideoState.select(streams).subscribe((streams: any) => {
      if (this.videoCapture) {
        let stream = streams["me"] //its me for the rep
        if (stream) {
          let vt = stream.getVideoTracks()
          if (vt.length > 0) {
            this.videoCapture.nativeElement.srcObject = stream//caller for caller
          }
        }
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  async takeThePic() {
    this.done_color = "#000"//"#40BE5C"
    if (this.prompt == "Take it again") {
      this.prompt = "Take the picture"
      this.hide_video = false;
      return
    }

    if (this.videoCapture) {
      var canvas = document.createElement('canvas');
      canvas.id = "CursorLayer";
      canvas.width = 480
      canvas.height = 360

      canvas.style.position = "absolute";
      // var body = document.getElementsByTagName("body")[0];
      // body.appendChild(canvas);
      var context = canvas.getContext("2d");
      if (context && this.videoCapture) {
        context.imageSmoothingEnabled = false;

        context.drawImage(this.videoCapture.nativeElement,
          80, 60, 480, 360,  // sx, sy, sWidth, sHeight,  640x480
          0, 0, 480, 360); // dx, dy, dWidth, dHeight

        this.img_src = canvas.toDataURL("image/png")
        console.log("take pic " + this.img_src.length)
        this.hide_video = true;
        this.prompt = "Take it again"
      }
    }
  }
  cancel() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
    this.canvasStore.dispatch(new DidStep(""))
  }
  done() {
    if (this.img_src) {
      this.canvasStore.dispatch(new SignerPic(this.img_src))
      this.canvasStore.dispatch(new DidStep("take_pic")) //will be process by progress and if need it, it will close the windo
      // this.canvasStore.dispatch(new SelectToolOpts(""))
      let jmsg: any = {
        caller_pic: this.img_src,
        signer_key: this.signer_key
      }
      this.wss_service.sendMessageToOtherMembers(jmsg)
    }
  }


}
