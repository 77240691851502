import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts, SetCanvasOpts } from 'src/app/canvas/canvas.actions';
import { SelectedCamera, SelectedMic, SelectedSpeaker } from '../k-video.actions';
import { KVideoState } from '../k-video.model';
import { audioDevId, audioSpeakerId, videoDevId } from '../k-video.selectors';

@Component({
  selector: 'app-sel-media',
  templateUrl: './sel-media.component.html',
  styleUrls: ['./sel-media.component.scss']
})
export class SelMediaComponent implements OnInit {
  video_devs: any[] = []
  audio_devs: any[] = []
  audio_out_devs: any[] = []

  selected_vdev_id: string | undefined;
  selected_adev_id: string | undefined;
  selected_a_out_dev_id: string | undefined;

  iconcolor: string = "#152666"
  subscriptions: any[] = []
  caller_draw: boolean = false;
  constructor(
    private kVideoState: Store<KVideoState>,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("sel media")
    this.setDevices()
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  async setDevices() {
    let me = this;
    let adev = localStorage.getItem('adev')
    if (adev) {
      this.selected_adev_id = adev
    }
    let vdev = localStorage.getItem('vdev')
    if (vdev) {
      this.selected_vdev_id = vdev
    }
    let a_out_dev = localStorage.getItem('a_out_dev')
    if (a_out_dev) {
      this.selected_a_out_dev_id = a_out_dev
    }

    let devices = await navigator.mediaDevices.enumerateDevices()
    devices.forEach(function(device) {
      if (device.kind == "videoinput") {
        if (!me.selected_vdev_id) {
          if (device.label.indexOf("default")) {
            me.selected_vdev_id = device.deviceId
          }
        }
        me.video_devs.push(device)
      }
      if (device.kind == "audioinput") {
        if (!me.selected_adev_id) {
          if (device.label.indexOf("default")) {
            me.selected_adev_id = device.deviceId
          }
        }
        me.audio_devs.push(device)
      }
      if (device.kind == "audiooutput") {
        if (!me.selected_a_out_dev_id) {
          if (device.label.indexOf("default")) {
            me.selected_a_out_dev_id = device.deviceId
          }
        }
        me.audio_out_devs.push(device)
      }
    })
  }

  ngOnInit(): void {
    console.log("SelMediaComponent")
    this.subscriptions.push(this.kVideoState.select(audioDevId).subscribe((id: string | undefined) => {
      if (id) {
        console.log("audioDevId " + id)
        this.selected_adev_id = id
      }
    }))
    this.subscriptions.push(this.kVideoState.select(videoDevId).subscribe((id: string | undefined) => {
      if (id) {
        console.log("videoDevId " + id)
        this.selected_vdev_id = id
      }
    }))
    this.subscriptions.push(this.kVideoState.select(audioSpeakerId).subscribe((id: string | undefined) => {
      console.log("videoDevId " + id)
      this.selected_a_out_dev_id = id
    }))
  }
  changedVideo(dev: any) {
    console.log("sel " + dev.deviceId)
    this.selected_vdev_id = dev.deviceId
    this.kVideoState.dispatch(new SelectedCamera(dev.deviceId))
  }
  changedAudio(dev: any) {
    console.log("sel " + dev.deviceId)
    this.selected_adev_id = dev.deviceId
    this.kVideoState.dispatch(new SelectedMic(dev.deviceId))
  }
  changedOutAudio(dev: any) {
    console.log("sel " + dev.deviceId)
    this.selected_a_out_dev_id = dev.deviceId

    if (this.selected_a_out_dev_id) {
      localStorage.setItem('a_out_dev', this.selected_a_out_dev_id)
      this.kVideoState.dispatch(new SelectedSpeaker(this.selected_a_out_dev_id))
    }
  }
  close() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
  enableCallrDraw() {
    this.caller_draw = !this.caller_draw
    let opt = {
      bDraw: this.caller_draw,
    }
    this.canvasStore.dispatch(new SetCanvasOpts(opt))
  }
}
