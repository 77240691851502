import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import {
  PDFDocument,
  rgb,
  PDFFont,
  PDFPage,
  StandardFonts,
  PDFImage,
  asPDFName,
  PDFHexString
} from 'pdf-lib'
import { CanvasService } from './canvas.service';
import { Store } from '@ngrx/store';
import { bioResults, callerLic, callerPic, callerResults, signerLic, signerPic } from './canvas.selectors';
import { PagesState } from '../pages/pages.model';
import { callerEmail, callerFirstName, callerLastName } from '../pages/pages.selectors';


@Injectable({
  providedIn: 'root'
})
export class MetaPageService {
  helveticaFont: PDFFont | undefined
  helveticaBold: PDFFont | undefined
  public pdfDoc: PDFDocument | undefined
  page: PDFPage | undefined//the page we are writing
  height: number = 0
  width: number = 0

  top: number = 0
  rows: number = 0
  meta: any

  my_pic: string | undefined
  my_id: any | undefined
  results: any// = { "similar": 99.9612045288086, "compared_at": "2023-06-29T00:30:36.352Z", "lic_text": "California USA DRIVER LICENSE DL F1237185 CLASS с EXP 04/16/2024 END NONE LN FERNANDEZ OROZCO FN SERGIO ANTONIO 700 WEST E ST 2101 SAN DIEGO, CA 92101 DOB 04/16/1959 RSTR CORR LENS 04161959 SONCH EYES BLK SEX: M HAIR BLK Age WGT 175 lb HGT 5'-09\" OPEN ISS 12/27/2018 DD 12/27/201850639/AAFD/24", "lic_scores": { "names": [{ "name": "Buyer", "score": 28.57142857142857 }], "email": 100, "name": 14.285714285714285, "lic_text": "California USA DRIVER LICENSE DL F1237185 CLASS с EXP 04/16/2024 END NONE LN FERNANDEZ OROZCO FN SERGIO ANTONIO 700 WEST E ST 2101 SAN DIEGO, CA 92101 DOB 04/16/1959 RSTR CORR LENS 04161959 SONCH EYES BLK SEX: M HAIR BLK Age WGT 175 lb HGT 5'-09\" OPEN ISS 12/27/2018 DD 12/27/201850639/AAFD/24" }, "url": { "exp": 1688002176353, "url": "https://keyzii-test.s3.us-west-1.amazonaws.com/649ccf9c6468c4788c34866f/rep_lic.png?AWSAccessKeyId=AKIA4UNR24CVC42GBRFX&Expires=1688002236&Signature=a1B6HbL2TAyErFVk%2F6OWT3mYO5g%3D" } }

  caller_pic: string | undefined
  caller_id: string | undefined
  caller_first_name: string | undefined
  caller_last_name: string | undefined
  caller_email: string | undefined
  caller_results: any

  constructor(
    public canvas_service: CanvasService,
    public canvasStore: Store<CanvasState>,
    public pagesState: Store<PagesState>,
  ) {
    console.log("meta page")
    this.pagesState.select(callerEmail).subscribe((email: string | undefined) => {
      this.caller_email = email
    })
    this.pagesState.select(callerFirstName).subscribe((name: string | undefined) => {
      this.caller_first_name = name
    })
    this.pagesState.select(callerLastName).subscribe((name: string | undefined) => {
      this.caller_last_name = name
    })
    this.canvasStore.select(signerPic).subscribe((pic: string | undefined) => {
      this.my_pic = pic
    })
    this.canvasStore.select(signerLic).subscribe((lic: any | undefined) => {
      this.my_id = lic
    })
    this.canvasStore.select(callerPic).subscribe((pic: string | undefined) => {
      this.caller_pic = pic
    })
    this.canvasStore.select(callerLic).subscribe((lic: string | undefined) => {
      this.caller_id = lic
    })
    this.canvasStore.select(callerResults).subscribe((results: any) => {
      this.caller_results = results
    })


    this.canvasStore.select(bioResults).subscribe(async (results: any) => {
      if (results) {
        this.results = results
      }
    })
  }
  async setupDoc(inPdfDoc: PDFDocument) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const pdfData: Uint8Array = await inPdfDoc.save()
        this.pdfDoc = await PDFDocument.load(pdfData)

        let rv = false;

        if (this.pdfDoc.catalog) {
          let smeta = this.pdfDoc.catalog.get(asPDFName("metadata")) as any
          if (smeta) {
            let sfields = smeta.decodeText()
            console.log("%% Loaded k_pages ")
            this.meta = JSON.parse(sfields)
          }
        }
        //*************************************
        // Initialize state
        //*************************************
        this.page = undefined
        if (this.meta) {
          if (this.meta.top > 175) {
            if (this.meta.signature_page_num) {
              let index = this.meta.signature_page_num - 1
              this.page = this.pdfDoc.getPage(index)
            }
          }
          if (this.meta.top) {
            this.top = this.meta.top
          }
        }
        if (!this.page) {
          this.page = this.pdfDoc.addPage()
          rv = true
        }
        console.log("meta page")
        let p0 = this.pdfDoc.getPage(0)
        let page0_h = p0.getHeight()
        let page0_w = p0.getWidth()
        this.page.setHeight(page0_h)
        this.page.setWidth(page0_w)
        this.height = this.page.getHeight()
        this.width = this.page.getWidth()

        this.helveticaFont = await this.pdfDoc.embedFont(StandardFonts.Helvetica)
        this.helveticaBold = await this.pdfDoc.embedFont(StandardFonts.HelveticaBold)
        resolve(rv)
      } catch (e) {
        reject(e)
      }
    })
  }
  async addHeader(title: string) {
    return new Promise<number>(async (resolve, reject) => {
      try {

        if (this.pdfDoc && this.page) {
          let y = this.height - 54
          this.page.drawText("Metadata Document ", {
            x: 40,
            y: y,
            size: 20,         // font: helveticaBold,
            color: rgb(0.0, 0.0, 0.0),
            lineHeight: 20,
            opacity: 1,
            font: this.helveticaBold
          },
          )

          let pngUrl = "assets/pdf/keyzii_sign1.png"
          let pngImageBytes: ArrayBuffer = await fetch(pngUrl).then(res => res.arrayBuffer())
          let pngImage = await this.pdfDoc.embedPng(pngImageBytes)
          this.page.drawImage(pngImage, {
            x: this.width - 140, //
            y: y - 5,
            width: 100,
            height: 23
          })
          y -= 62  //was 42 in figma
          if (!title) {
            title = "Contract"
          }
          this.page.drawText(title, {
            x: 40,
            y: y,
            size: 19,
            color: rgb(0.0, 0.0, 0.0),
            lineHeight: 19,
            opacity: 1,
            font: this.helveticaFont
          },
          )

          y -= 78 //53 *62/42


          this.top = y

          resolve(y)
        }

      } catch (e) {
        console.error("errorl loading png from server " + e)
        reject(e)
      }
    })
  }
  async addBlocChain(title: string) {
    return new Promise<void>(async (resolve, reject) => {

      if (this.pdfDoc && this.page) {
        try {
          let pngImageBytes = await this.canvas_service.getContractQRCode()
          let pngImage = await this.pdfDoc.embedPng(pngImageBytes)
          this.page.drawImage(pngImage, {
            x: this.width - 96,
            y: this.height - 170,
            width: 60,
            height: 60
          })
        } catch (e) {
          console.error(e)
          return;
        }

        this.page.drawText(title, {
          x: this.width - 140,
          y: this.height - 90,
          size: 10,
          color: rgb(0.0, 0.0, 0.0),
          lineHeight: 10,
          opacity: 1,
          font: this.helveticaFont
        })

        this.page.drawText("[SCAN ME]", {
          x: this.width - 93,
          y: this.height - 105,
          size: 10,
          color: rgb(0.0, 0.0, 0.0),
          lineHeight: 10,
          opacity: 1,
          font: this.helveticaBold
        })

      }
      resolve()
    })
  }

  addContact(email: string, first_name: string | undefined, last_name: string | undefined) {
    return new Promise<PDFDocument>(async (resolve, reject) => {
      try {
        if (this.caller_email) {
          await this.addContactLine(this.caller_email, this.caller_first_name, this.caller_last_name, this.caller_pic, this.caller_id, this.caller_results)
        }
        let rv = this.addContactLine(email, first_name, last_name, this.my_pic, this.my_id, this.results)
        resolve(rv)
      } catch (e) {
        console.error(e)
      }
    })
  }
  addContactLine(email: string, first_name: string | undefined, last_name: string | undefined, my_pic: string | undefined, my_id: any | undefined, results: any | undefined) {
    return new Promise<PDFDocument>(async (resolve, reject) => {
      if (this.pdfDoc && this.page) {
        const line_height = 25
        let y = this.top
        console.log("add contact line at y = " + y)
        //Name
        try {
          let pngUrl = "assets/pdf/name_l.png"
          let pngImageBytes = await fetch(pngUrl).then(res => res.arrayBuffer())
          let pngImage = await this.pdfDoc.embedPng(pngImageBytes)
          this.page.drawImage(pngImage, {
            x: 40,
            y: y,
            width: 16,
            height: 17.6
          })
        } catch (e) {
          console.error(e)
        }

        this.page.drawText("Name:", {
          x: 70,
          y: y + 4,
          size: 10,
          color: rgb(0.0, 0.0, 0.0),
          lineHeight: 14,
          opacity: 1,
          font: this.helveticaBold
        })
        if (!first_name) {
          first_name = email
        }
        if (!first_name) {
          first_name = "signer"
        }
        this.page.drawText(first_name + " " + last_name, {
          x: 103,
          y: y + 4,
          size: 10,
          color: rgb(0.0, 0.0, 0.0),
          lineHeight: 14,
          opacity: 1,
          font: this.helveticaFont
        })
        y -= line_height //1

        //email
        try {
          let pngUrl = "assets/pdf/email_l.png"
          let pngImageBytes = await fetch(pngUrl).then(res => res.arrayBuffer())
          let pngImage = await this.pdfDoc.embedPng(pngImageBytes)
          this.page.drawImage(pngImage, {
            x: 40,
            y: y,
            width: 16,
            height: 17.6
          })
        } catch (e) {
          console.error(e)
        }

        this.page.drawText("Email:", {
          x: 70,
          y: y + 4,
          size: 10,
          color: rgb(0.0, 0.0, 0.0),
          lineHeight: 13,
          opacity: 1,
          font: this.helveticaBold
        })
        if (!email) {
          email = "signer@keyzii.com"
        }
        this.page.drawText(email, {
          x: 103,
          y: y + 4,
          size: 10,
          color: rgb(0.0, 0.0, 0.0),
          lineHeight: 13,
          opacity: 1,
          font: this.helveticaFont
        })
        y -= line_height //2

        //date time
        try {
          let pngUrl = "assets/pdf/calendar_l.png"
          let pngImageBytes = await fetch(pngUrl).then(res => res.arrayBuffer())
          let pngImage = await this.pdfDoc.embedPng(pngImageBytes)
          this.page.drawImage(pngImage, {
            x: 40,
            y: y,
            width: 16,
            height: 17.6
          })
        } catch (e) {
          console.error(e)
        }

        this.page.drawText("Date & Time:", {
          x: 70,
          y: y + 4,
          size: 10,
          color: rgb(0.0, 0.0, 0.0),
          lineHeight: 13,
          opacity: 1,
          font: this.helveticaBold
        })
        let sd = formatDate(new Date(), 'hh:mm a, dd MMM yy', navigator.language);
        this.page.drawText(sd, {
          x: 133,
          y: y + 4,
          size: 10,
          color: rgb(0.0, 0.0, 0.0),
          lineHeight: 13,
          opacity: 1,
          font: this.helveticaFont
        })
        y -= line_height //3

        //biometric
        if (results) {
          try {
            let pngUrl = "assets/pdf/verify_l.png"
            let pngImageBytes = await fetch(pngUrl).then(res => res.arrayBuffer())
            let pngImage = await this.pdfDoc.embedPng(pngImageBytes)
            this.page.drawImage(pngImage, {
              x: 40,
              y: y,
              width: 16,
              height: 17.6
            })
          } catch (e) {
            console.error(e)
          }

          this.page.drawText("Biometric results:", {
            x: 70,
            y: y + 4,
            size: 10,
            color: rgb(0.0, 0.0, 0.0),
            lineHeight: 13,
            opacity: 1,
            font: this.helveticaBold
          })
          y -= 18
          let similar = (Math.round(results.similar * 100) / 100).toFixed(2);
          this.page.drawText("Facial Recognition: " + similar + "%", {
            x: 70,
            y: y + 4,
            size: 10,
            color: rgb(0.0, 0.0, 0.0),
            lineHeight: 13,
            opacity: 1,
            font: this.helveticaFont
          })

          if (results.lic_scores) {
            if (results.lic_scores.name) {
              y -= 18
              let sim_name = (Math.round(results.lic_scores.name * 100) / 100).toFixed(2);
              this.page.drawText("Name Recognition: " + sim_name + "%", {
                x: 70,
                y: y + 4,
                size: 10,
                color: rgb(0.0, 0.0, 0.0),
                lineHeight: 13,
                opacity: 1,
                font: this.helveticaFont
              })
            }
          }
          y -= line_height //4
        }
        let top_img = this.top - 80
        if (!results) {
          top_img = this.top - 65
        }
        if (my_pic) {
          try {
            let pngImage = await this.pdfDoc.embedPng(my_pic)
            this.page.drawImage(pngImage, {
              x: this.width / 2,
              y: top_img,
              width: 120,
              height: 80

            })
          } catch (e) {
            console.error(e)
            reject(e)
          }
        }

        if (my_id) {
          try {
            let image
            if (my_id.type == "image/png") {
              image = await this.pdfDoc.embedPng(my_id.src)
            } else if (my_id.type == "image/jpeg") {
              image = await this.pdfDoc.embedJpg(my_id.src)
            }
            if (image) {
              this.page.drawImage(image, {
                x: this.width / 2 + 130,
                y: top_img,
                width: 120,
                height: 80
              })
            }
          } catch (e) {
            console.error(e)
            reject(e)
          }
        }
        y -= line_height
        //if we had bio result subract a line
        if (results) {
          y += 18
        }
        this.page.drawLine({
          start: { x: 40, y: y },
          end: { x: this.width - 40, y: y },
          thickness: 1,
          color: rgb(0.75, 0.75, 0.75),
          opacity: 0.75,
        })
        y -= line_height
        y -= line_height
        this.rows++;

        let signature_page_num = this.pdfDoc.getPageCount()
        let first_signature_page = signature_page_num
        if (this.meta) {
          if (this.meta.first_signature_page) {
            first_signature_page = this.meta.first_signature_page
          }
        }

        let new_meta = {
          top: y,
          first_signature_page: first_signature_page,
          signature_page_num: signature_page_num,
          rows: this.rows
        }
        let smeta = JSON.stringify(new_meta)
        this.pdfDoc.catalog.set(asPDFName("metadata"), PDFHexString.fromText(smeta))

        this.top = y
        console.log("done contact line at y = " + y)
        resolve(this.pdfDoc)
      }
    })



  }
}
