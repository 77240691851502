import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'more-or-less',
  templateUrl: './more-or-less.component.html',
  styleUrls: ['./more-or-less.component.scss']
})
export class MoreOrLessComponent {
  @Input() value: number = 5
  @Output() valueChange: EventEmitter<number> = new EventEmitter();
  @Input() max: number = 25

  prev() {
    if (this.value > 0) {
      --this.value
      this.valueChange.emit(this.value)
    }
  }
  next() {
    if (this.value <= this.max) {
      this.value++
      this.valueChange.emit(this.value)
    }
  }
}
