import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSState } from 'src/app/chapala-mls/chapala-mls.model';
import { ChapalaMLSService } from '../../chapala-mls-service';
import { SelectorComponent } from './selector.component';

@Component({
  selector: 'mls-options',
  templateUrl: './options.component.html',
  styleUrls: ['./selector.component.scss']

})
export class MLSOptionsComponent extends SelectorComponent {
  @Output() changed: EventEmitter<string> = new EventEmitter();
  @Input() names: string[] = []
  @Input() values: string[] = []
  @Input() set name(n: string) {
    this.setTitle(n)
  }
  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);
    console.log("options")
  }
  ngAfterViewInit() {
    if (this.names) {
      setTimeout(() => {
        this.options = this.names
        this.title = this.names[0]
      }, 1)
    }
  }
  selOption(i: number) {
    super.selOption(i)
    let val
    if (this.values) {
      val = this.values[i]
    } else {
      val = this.names[i]
    }
    this.changed.emit(val);
  }

}
