
/*
This code should be keep in sync between call  and site
*/
import { DatePipe } from '@angular/common';

const day = 24 * 60 * 60 * 1000
//8 columns, the first has the times, the rest only the events
export function makeWeekSlots(t_from: number, t_to: number, events: any, work_time: any) {
  console.log("MWS")
  //We are going to do day by day
  let work_days = [false, true, true, true, true, true, false]
  if (work_time.work_days) {
    work_days = work_time.work_days
  }
  let slot_minutes = 15
  if (work_time.slot_minutes) {
    slot_minutes = parseInt(work_time.slot_minutes)
  }
  let begining_of_day = t_from;
  let end_of_day = begining_of_day + day

  let week_slots: any[] = [] //the date+ 7 days
  let col = 0;
  for (let i = 0; i < work_days.length; i++) {
    let hours = avaliableHours(begining_of_day, end_of_day - 1, [work_time])
    week_slots[i] = makeSlots(events, hours, slot_minutes, 1)
    begining_of_day = end_of_day
    end_of_day = begining_of_day + day
  }

  return week_slots
}


export function dayTimeToMSecs(stime: string, begining_of_day: number, offset: number) {
  let parts = stime.split(' ')
  let hhmm = parts[0].split(':')
  let hours = parseInt(hhmm[0])
  let mm = parseInt(hhmm[1])
  if (parts[1] == "PM") {
    hours += 12;
  }
  let d_ms = (hours * 60 * 60 + mm * 60) * 1000
  // let date = new Date(begining_of_day)
  // console.log("begining_of_day" + date)

  begining_of_day += d_ms
  // date = new Date(begining_of_day)

  // let of = offset // date.getTimezoneOffset()
  // let off_ms = of * 60 * 1000;
  let rv = begining_of_day //- off_ms + offset * 60 * 1000


  console.log("#TI " + stime + " " + new Date(rv).toUTCString())

  return rv;
}

export function avaliableHours(t_from: number, t_to: number, work_times: any) {

  console.log("<- hours from " + new Date(t_from) + " " + new Date(t_to))
  let rv_to = 0//1711623600000
  let rv_from = 9711623600000

  let o_from = t_from
  let o_to = t_to


  //Now lets find out who is available to answer calls today
  function parseHour(stime: string) {
    let parts = stime.split(' ')
    let hhmm = parts[0].split(':')
    let hours = parseInt(hhmm[0])
    let mm = "00"
    if (hhmm.length > 1) {
      let min = parseInt(hhmm[1])
      if (min < 10) {
        mm = "0" + min
      } else {
        mm = min.toString()
      }

    }

    if (parts[1] == "PM") {
      hours += 12;
    }
    let s_hours
    if (hours < 10) {
      s_hours = "0" + hours
    } else {
      s_hours = hours.toString()
    }

    return {
      hours: s_hours,
      min: mm
    }
  }

  function getRepBeginAndEnd(t_from: number, work_time: any) {
    let date = new Date(t_from)
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let smonth
    if (month < 10) {
      smonth = "0" + month
    } else {
      smonth = month.toString()
    }
    let day = date.getDate()
    let sday
    if (day < 10) {
      sday = "0" + day
    } else {
      sday = day.toString()
    }

    let sfrom = work_time.from
    if (!sfrom) {
      sfrom = "9:00 AM"
    }
    let time = parseHour(sfrom)
    let offset_h = Math.floor(Math.abs(work_time.offset / 60))
    let s_h
    if (offset_h < 10) {
      s_h = "0" + offset_h
    } else {
      s_h = offset_h.toString()
    }
    if (work_time.offset > 0) {
      s_h = "+" + s_h
    } else {
      s_h = "-" + s_h
    }


    let offset_min = Math.abs(work_time.offset) - offset_h * 60
    let s_m
    if (offset_min < 10) {
      s_m = "0" + offset_min
    } else {
      s_m = offset_min.toString()
    }
    //new Date("2018-12-30T20:00:00-05:00");
    let sdate = year + "-" + smonth + "-" + sday + "T" + time.hours + ":" + time.min + ":00" + s_h + ":" + s_m
    let s_d = new Date(sdate)
    console.log(work_time.name + " starts work at " + sdate + " " + s_d)
    let s_ms = s_d.getTime()

    let etime = work_time.to
    if (!etime) {
      etime = "5:00 PM"
    }
    time = parseHour(etime)
    let edate = year + "-" + smonth + "-" + sday + "T" + time.hours + ":" + time.min + ":00" + s_h + ":" + s_m
    let e_d = new Date(edate)
    console.log(work_time.name + " ends work at " + edate + " " + e_d)
    let e_ms = e_d.getTime()
    if (t_to > e_ms) {
      t_to = e_ms
    }
    return {
      from: s_ms,
      to: e_ms
    }
  }

  for (let i = 0; i < work_times.length; i++) {
    let work_time = work_times[i]
    if (work_time) {
      if (work_time.offset) {
        let rep_wt = getRepBeginAndEnd(t_from, work_time)
        if (rep_wt.to <= o_from) { //try with tomorrow
          console.log("end work time before requested time")
          rep_wt = getRepBeginAndEnd(t_from + 24 * 60 * 60 * 1000, work_time)
        }
        if (rep_wt.from < rv_from) {
          rv_from = rep_wt.from
        }
        if (rep_wt.to > rv_to) {
          rv_to = rep_wt.to
        }
      }
    }
  }
  console.log("--> hours from " + new Date(rv_from) + " " + new Date(rv_to))

  return {
    from: rv_from,
    to: rv_to
  }
}
/*
export function avaliableHours(t_from: number, t_to: number, work_times: any) {

  // console.log("<- hours from " + new Date(t_from) + " " + new Date(t_to))
  let rv_to = t_from //// ISODate("2023-10-04T07:00:00Z")
  let rv_from = t_to //// ISODate("2023-10-05T07:00:00Z")


  //to test it in hours-v.component.ts :'GMT+1'

  for (let i = 0; i < work_times.length; i++) {
    let work_time = work_times[i]
    if (work_time) {
      if (work_time.offset) {
        let date = new Date(t_from)
        console.log("#TI t_from " + date.toUTCString())
        date.setUTCHours(0, 0, 0, 0);
        console.log("#TI begining of the day t_from  UTC " + date.toUTCString())
        let time_ms = date.getTime() - work_time.offset * 60 * 1000
        t_from = time_ms
        date = new Date(t_from)
        console.log("#TI t_from work time " + date.toUTCString())
        date = new Date(t_to)
        date.setUTCHours(0, 0, 0, 0);
        time_ms = date.getTime() - work_time.offset * 60 * 1000
        if (work_time.offset >= 12 * 60) {
          t_from += 24 * 60 * 60 * 1000
          time_ms += 24 * 60 * 60 * 1000
        }
        t_to = time_ms
      }
    }


    let available_from = dayTimeToMSecs(work_time.from, t_from, work_time.offset)

    if (rv_from > available_from) {
      rv_from = available_from
    }
    let available_to = dayTimeToMSecs(work_time.to, t_from, work_time.offset)
    if (available_to > rv_to) {
      rv_to = available_to
    }
  }
  // console.log("hours from -->" + new Date(rv_from) + " " + new Date(rv_to))

  return {
    from: rv_from,
    to: rv_to
  }
}
//the first colum has the time, the next 7 are monday through sunday
*/

export function makeSlots(events: any[], hours: any, slot_minutes: number, num_reps: number) {
  let slot_ms = slot_minutes * 60 * 1000
  let pipe: DatePipe = new DatePipe('en-US');

  let slots: any[] = []
  let slot_index = 0;
  let event_index = 0;

  function isSlotInEvent(slot_t: number, event: any) {
    function timeInRange(t: number) {
      let rv = (t >= event.start && t < event.end)
      if (rv) {
        console.log(new Date(t).toString() + " > " + new Date(event.start).toString() + " < " + new Date(event.end).toString() + " ")
      }
      return rv
    }

    if (timeInRange(slot_t)) {
      return true;
    }
    if (timeInRange(slot_t + slot_ms - 1)) {
      return true;
    }
    return false
  }

  let event = events[event_index]
  // if (event) {
  // console.log("*CU event " + event_index + " " + new Date(event.start))
  // }
  let now_t = new Date().getTime()
  let prev_slot_minutes = slot_minutes
  let slot_event
  for (let slot_t = hours.from; slot_t < hours.to; slot_t += prev_slot_minutes * 60 * 1000) {
    //Move the busy slot forward to the current one
    let status = "f"
    slot_event = undefined

    while (event && event.end <= slot_t) {
      event_index++
      event = events[event_index]
      // if (event) {
      //   console.log("*CU event " + event_index + " " + new Date(event.start))
      // }
    }

    if (event) {
      if (isSlotInEvent(slot_t, event)) {
        slot_event = event
        if (event.duration > slot_minutes) {
          prev_slot_minutes = event.duration
        }
        if (num_reps == 1) {
          status = "b"
        } else {
          let next_event_idndex = event_index
          let slot_end = slot_t + slot_ms - 1
          let events_in_slot = 1
          while (++next_event_idndex < events.length) {//Check with the events until there is no event
            let event = events[next_event_idndex]
            if (event.start > slot_end) {   //or the event starts after the end of the slot
              break;
            }
            if (isSlotInEvent(slot_t, event)) {
              status = "b"
            }
          }
        }
      }
    }
    if (slot_t < now_t) {
      status = "d"
    }

    let slot: any = {
      status: status,
      //  stime: pipe.transform(slot_t, 'hh:mm'),
      time: slot_t,
      index: slot_index++,
      height: 80 / 60 * slot_minutes, //80 per hour
    }
    if (slot_event) {
      slot.event = slot_event
      if (slot_event.duration > slot_minutes) {
        let duraton = Math.ceil((slot_event.duration + slot_minutes - 1) / slot_minutes) * slot_minutes
        slot.height = 80 / 60 * duraton
        slot.slot_minutes = duraton
      } else {
        slot.slot_minutes = slot_minutes
      }
    } else {
      prev_slot_minutes = slot_minutes
    }

    slots.push(slot)

  }
  //So far we have a slot for every 15 minutes, we have to re-pack them into hourly slots with all the slots indisde
  // let hour_slots = makeHourSlots(hours)
  // console.log("Make slot " + JSON.stringify(hour_slots))
  return { slots: slots }//, hour_slots: hour_slots };
}

export function makeHourSlots(hours: any) {
  let hour_slots: any = []
  for (let slot_t = hours.from; slot_t < hours.to; slot_t += 60 * 60 * 1000) {
    hour_slots.push({
      time: slot_t,
    })
  }
  return hour_slots;
}

export function getDayHours(in_date: Date) {
  let new_time = in_date.getTime()
  let date = new Date(new_time)
  date.setHours(0, 0, 0, 0);
  let from = date.getTime()
  date.setHours(23, 59, 59, 0);
  let to = date.getTime()
  return { from: from, to: to }
}
