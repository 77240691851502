<div class="scrn">
  <div class="abs_center out" [ngClass]="{shadow:contract}">
    <div class="margin">
      Are you sure you want to leave the call?
    </div>

    <div class="abs_center btns_t">
      <k-button class="btn1" text="Yes" background="#fff" color="#000"  kzLib (click)="hangUp()"></k-button>
      <k-button text="No" background="#000" color="#fff"  kzLib (click)="cancel()"></k-button>
    </div>

    <!-- <k-check class="abs_center cookies" [(checked)]="bCookiesSaved" text="Save cookies" (checkedChange)="cookiesChange($event)"></k-check> -->
  </div>
</div>
