import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'k-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @ViewChild('input') my_input!: ElementRef;

  display_placeholder: string = ""
  _placeholder: string = ""
  border: string = "1px solid #d9d9d9"
  @Input() set placeholder(p: string) {
    this._placeholder = p;
    setTimeout(() => this.display_placeholder = this._placeholder)
  }
  @Input() type: string = ""
  _model: string = "";

  @Output() invalidChange: EventEmitter<boolean> = new EventEmitter();

  @Input() set myModel(value: string) {
    this._model = value;
  }
  @Output() myModelChange: EventEmitter<string> = new EventEmitter();

  modelChanged($event: any) {

    this.myModelChange.emit(this._model)

  }
  onFocus() {
    this.display_placeholder = ""
    this.border = "1px solid #3B5FE0"
  }
  onBlur() {
    console.log("blur")
    if (this._model.length == 0) {
      this.display_placeholder = this._placeholder
    } else {
      this.display_placeholder = ""
    }
    this.border = "1px solid #d9d9d9"
  }

}
