<div class="out">
  <img class="top_logo" src="assets/images/keyzii_logo_white1.svg">

  <div *ngIf="!bForgat" class="body" [ngClass]="{bphone:isPhone}">
    <div class="left" [ngClass]="{phone:isPhone}">
      <div class="left_body">

        <div class="center font_sub_title ft">{{title}}</div>
        <!-- <div class="new_to fst"  kzLib (click)="gotoSignUp()"> <span class="new">New to Keyzii? </span>Create account </div> -->

        <div *ngIf="!kzCode" class="ms_wraper" kzLib (click)="msLogin()">
          <img src="assets/images/ms.png" class="google_logo ms_logo" alt="">
          <div class="google_tx">Sign in with Microsoft</div>
        </div>
        <div *ngIf="!kzCode" class="google_wraper" kzLib (click)="logInWithGoogle()">
          <img src="assets/images/google_lg.svg" class="google_logo" alt="">
          <div class="google_tx"> Sign in with Google</div>
        </div>
        <!-- <div class="google_wraper" *ngIf="bShowGoogleBtn">
          <asl-google-signin-button width="300" shape="circle" class="google" type="standard" size="large"> </asl-google-signin-button>
        </div> -->
        <div *ngIf="!kzCode" class="line_wrapper">
          or
        </div>

        <input #username autocomplete="username" kzInput class="in" [ngClass]="{invalid:bInvalidEmail}" type="email" placeholder="Email" [(ngModel)]="email" (ngModelChange)="changedEmail($event)">
        <input #password_f autocomplete="current-password" kzInput class="in" type="password" placeholder="Password" [(ngModel)]="password" (keyup.enter)="login()">
        <input kzInput *ngIf="reset_token" class="in" type="password" placeholder="Password confirmation" [(ngModel)]="password_conf" (blur)="changedConfirm($event)">



        <div class="error">{{error_text }}</div>

        <k-button *ngIf="!reset_token" class="login" min_width="190" [background]="btn_back" [color]="btn_color" min_width="296" min_height="50" text="Log in" kzLib (click)="login()"></k-button>
        <k-button *ngIf="reset_token" class="login" color="white" min_width="190" background="#000" min_width="296" min_height="50" text="Reset password" kzLib (click)="login()"></k-button>


        <div *ngIf="!reset_token" class="info hc" kzLib (click)="forgot()">Forgot password</div>
        <!-- <div class="info">{{info_text}}</div> -->



      </div>
    </div>
    <!-- <div *ngIf="!isPhone" class="right">
      <img class="abs_center back" src="assets/images/keyzii_icon_white.svg">
      <img class="abs_center more" src="assets/images/more_than.svg">
      <div class="abs_center instanly">
        Instantly connect, share, colaborate and sign, all on one platform.
      </div>
    </div> -->
  </div>
  <div *ngIf="bForgat" class="body p3" [ngClass]="{bphone:isPhone}">


    <div *ngIf="info_text" class="ma_tp3 font_title ft"> Password reset link sent!

    </div>
    <div class="ma_tp3 p3_st">
      {{info_text}}
    </div>
    <div *ngIf="info_text" class="abs_center resend">
      <div class="abs_center rst" kzLib (click)="forgot()">
        Resend Email
      </div>
    </div>
    <div class="abs_center sc" kzLib (click)="support()">
      Contact support
    </div>

    <k-spinner class="abs_center" *ngIf="!info_text"></k-spinner>
  </div>

</div>
