import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditComment } from '../../canvas.actions';
import { editComment } from '../../canvas.selectors';

@Component({
  selector: 'edit-comment',
  templateUrl: './edit-comment.component.html',
  styleUrls: ['./edit-comment.component.scss']
})
export class EditCommentComponent {

  @ViewChild('textA') textA: ElementRef | undefined; //hand signature

  content: string = "hola"
  id: string = ""
  subscriptions: any[] = []
  constructor(
    public canvasStore: Store<CanvasState>
  ) {
    this.subscriptions.push(this.canvasStore.select(editComment).subscribe((action: any) => {
      if (action) {
        this.content = action.content
        this.id = action.id
        console.log("Edit comment <--" + this.id + " " + this.content)

      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    if (this.textA) {
      this.textA.nativeElement.focus()
    }
  }
  cancel() {
    console.log("Edit comment -->" + this.id + " " + this.content)
    this.canvasStore.dispatch(new EditComment(false, this.id, this.content))
  }
}
