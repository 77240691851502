<div #outElement class="out" [ngClass]="{no_r:isPhone}">
  <video [ngClass]=" {'mirrow':muted, hide:bShowMono}" [muted]="muted" #videoElement class="video" autoPlay autoplay playsinline>
  </video>
  <div *ngIf="present_role == _role" class="presenting">
    <div class="abs_center presenting_tx">
      Presenting
    </div>
  </div>
  <div *ngIf="bShowMono && _role=='caller'" class="no_video">
    <div class="abs_center">
      <div class="round blue" [ngClass]="{small:_small}">
        <data class="abs_center">{{initials}}</data>
      </div>
    </div>
  </div>
  <img *ngIf="bShowMono && _role!='caller' && no_prompts" class="abs_center img" src="assets/images/camera_off.svg">

  <div *ngIf="bShowMono && _role!='caller' && !no_prompts" class="no_video">
    <div class="abs_center">
      <div class="round" [ngClass]="{small:_small}">
        <img *ngIf="img_src" [src]="img_src" [ngClass]="{small:_small}">
      </div>
      <div *ngIf="!call_id" class="tx_line call_type ">Audio Call</div>
      <div *ngIf="!call_id" class="tx_line">{{name}}</div>
      <div *ngIf="!call_id" class="tx_line">{{cia}}</div>
    </div>
  </div>

  <div *ngIf="!no_prompts" class="name my_name" [ngClass]="{nm_sm:_small, black:bShowMono}">{{name}}</div>

  <sound-mono *ngIf="!no_prompts" class="snd_circle" [audio_stream]="audio_stream" [role]="_role" [ngClass]="{snd_sm:_small}"></sound-mono>
  <div *ngIf="bShowIsPhone" class="on_mobile">
    <div class="abs_center mtx">
      on mobile
    </div>

  </div>
  <k-button *ngIf="present_role == _role" class="video_canvas" icon="close_white.svg" background="#f00"  kzLib (click)="stopVCanvas()"></k-button>
  <k-button *ngIf="present_role != _role" class="video_canvas" icon="video_canvas.svg" background="#ffffffc0"  kzLib (click)="videoCanvas()"></k-button>
</div>
