import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetInitials, GetSignature, GoToStep, NewInitials, NewSignature, Progress, SelectToolOpts, SetTool } from 'src/app/canvas/canvas.actions';
import { changeInitials, changeSignature, contractSigned, lastSigner, pdfSecOpts, readyToBlockChain, signers, signerSteps, signersWithSteps } from 'src/app/canvas/canvas.selectors';
import { CanvasService } from 'src/app/canvas/canvas.service';
import { CloseProgress, NotifyUser, ShowAsset } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { myEmail } from 'src/app/pages/pages.selectors';
import { WSSState } from 'src/app/wss/wss.model';
import { callId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';

@Component({
  selector: 'contract-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})

export class ProgressComponent {
  progress: number = 0;
  lower_msg: string = ""
  step_keys: string[] = []
  steps: any = {}
  curr_step: any = {}
  step_no: number = 1;
  num_steps: number = 5
  in_steps: any = {}
  missing: number = 0

  bShowDone: boolean = true;
  bSent: boolean = false;
  done_color: string = "#ddd"
  last_signer: boolean = false;
  // missing_emails: string[] = []
  ready_to_block_chain: boolean = false
  call_id: string | undefined
  // prompt: string = "Complete all steps below."
  bClose: boolean = false

  signers: any = {}
  signer_keys: string[] = []

  bBlockChain: boolean = false

  bContratSigned: boolean = false
  my_email: string | undefined
  my_key: string | undefined
  signature: any | undefined
  initials: any | undefined
  subscriptions: any[] = []

  constructor(
    public canvasStore: Store<CanvasState>,
    private canvas_service: CanvasService,
    public wssState: Store<WSSState>,
    public pagesState: Store<PagesState>,
    private wss_service: WSSService,

  ) {
    this.subscriptions.push(this.canvasStore.select(changeSignature).subscribe((data: any | undefined) => {
      if (data && data.svg && data.svg.length > 1) {
        this.signature = data
      }
    }))
    this.subscriptions.push(this.canvasStore.select(changeInitials).subscribe((data: any | undefined) => {
      if (data && data.svg && data.svg.length > 1) {
        this.initials = data
      }
    }))
    this.subscriptions.push(this.canvasStore.select(contractSigned).subscribe((signed: boolean) => {
      this.bContratSigned = signed
      setTimeout(() => {
        if (this.bContratSigned) {
          this.done_color = "#40BE5C"
        } else {
          this.done_color = "#ddd"
        }
      })
    }))

    this.subscriptions.push(this.canvasStore.select(pdfSecOpts).subscribe((sec_opts: any) => {
      if (sec_opts) {
        setTimeout(() => {
          this.bBlockChain = false
          if (sec_opts.blockchain) {
            if (sec_opts.blockchain != "none") {
              this.bBlockChain = true
            }
          }
        })
      }
    }))
    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (call_id) {
        this.call_id = call_id
      }
    }))

    this.subscriptions.push(this.canvasStore.select(readyToBlockChain).subscribe((ready: boolean) => {
      this.ready_to_block_chain = ready
      if (this.ready_to_block_chain) {
        if (this.my_key) {
          this.select(this.my_key, 'blockchain')
        }
      }
    }))

    this.subscriptions.push(this.canvasStore.select(lastSigner).subscribe((last_signer: boolean) => {
      this.last_signer = last_signer
    }))
    // this.canvasStore.select(missingEmailsFor).subscribe((missing_emails: string[]) => {
    //   this.missing_emails = missing_emails
    // })

    this.subscriptions.push(this.pagesState.select(myEmail).subscribe((email: string | undefined) => {
      if (email) {
        this.my_email = email
        this.setMyKey()
      }
    }))

    this.subscriptions.push(this.canvasStore.select(signersWithSteps).subscribe((signers: any[]) => {
      console.log("signers: " + JSON.stringify(signers))
      setTimeout(() => {
        this.signers = signers
        this.signer_keys = Object.keys(this.signers)
        //Find out you key
        this.setMyKey()
        console.log("signers w/s: " + JSON.stringify(this.signers))
      })
    }))
    this.subscriptions.push(this.canvasStore.select(signers).subscribe((new_signers: any) => {
      //If threre is a new signer and it is not part of the signers add it
      let signer_keys = Object.keys(new_signers)
      let signers: any = {}
      for (let i = 0; i < signer_keys.length; i++) {
        let key = signer_keys[i]
        if (!this.signers[key]) {
          signers[key] = new_signers[key]

        } else { //check the email and name to update them
          let signer = Object.assign({}, this.signers[key])
          signer.name = new_signers[key].name
          signer.email = new_signers[key].email
          signer.title = new_signers[key].title
          signers[key] = signer
        }
      }
      setTimeout(() => {
        this.signers = signers
        this.signer_keys = signer_keys
        console.log("signers update: " + JSON.stringify(this.signers))

      })

    }))

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  setMyKey() {
    if (this.my_email && this.signers) {
      for (let i = 0; i < this.signer_keys.length; i++) {
        let key = this.signer_keys[i]
        let signer = this.signers[key]
        if (signer.email) {
          if (signer.email == this.my_email) {
            this.my_key = key;
            return
          }
        }
      }
    }
  }

  select(skey: string, key: string) {
    if (this.my_key == skey) {
      let step: any = this.signers[skey].steps[key]
      if (step.k_type == "sign" || step.k_type == "initial") {
        this.canvasStore.dispatch(new SetTool("signing")) //Canvas select sigining tool
        if (step.k_type == "sign") {
          if (!this.signature) {
            this.canvasStore.dispatch(new GetSignature(key))
          } else {
            let svg = this.signature.svg
            let rect = this.signature.rect
            let text_initials = this.signature.text_initials
            this.canvasStore.dispatch(new NewSignature(step.id, svg, rect, text_initials))
            return;
          }
        } else {
          if (!this.initials) {
            this.canvasStore.dispatch(new GetInitials(key))
          } else {
            let svg = this.initials.svg
            let rect = this.initials.rect
            this.canvasStore.dispatch(new NewInitials(step.id, svg, rect))
            return;
          }
        }
      }
      if (key == "image_rec") {
        if (!this.signers[skey].steps["take_pic"].done) {
          this.canvasStore.dispatch(new GoToStep(this.signers[skey].steps["take_pic"]))
          return;
        }
        if (!this.signers[skey].steps["driver_lic"].done) {
          this.canvasStore.dispatch(new GoToStep(this.signers[skey].steps["driver_lic"]))
          return
        }
      }
      this.canvasStore.dispatch(new GoToStep(step)) //picked up by canvas-pdf
    }
  }
  done() {
    if (this.bContratSigned) {
      this.canvas_service.saveSignedPDF()
      this.bShowDone = false
      this.bSent = true;
      this.pagesState.dispatch(new NotifyUser("Document completed. Final copy sent to your email."))
      this.pagesState.dispatch(new CloseProgress())
    }
  }
  openSel() {
    this.canvasStore.dispatch(new SelectToolOpts("sel-signer"))
  }
  toogleSigner(skey: string) {
    let signers = Object.assign({}, this.signers)
    let signer = Object.assign({}, signers[skey])
    if (signer.hidden) {
      signer.hidden = false
    } else {
      signer.hidden = true
    }
    signers[skey] = signer
    this.signers = signers

  }

}
