import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { eachDayOfInterval, endOfMonth, getDate, getDay, getMonth, getYear, isAfter, isSameDay, isSameMonth, isSameYear, isToday, startOfMonth, subDays } from 'date-fns';
import { CalendarState } from '../../calendar.model';
import { CalendarService } from '../../calendar.service';

import { CalendarComponent, Day } from '../calendar.component';


@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.scss']
})
export class MonthComponent extends CalendarComponent {
  _date: Date = new Date()
  // @Input() set date(d: Date) {
  //   this._date = d;
  //   this.initDays()
  // }
  @Output() dateChange: EventEmitter<Date> = new EventEmitter();

  innerValue: Date = new Date();

  days: Day[] = [];
  ngOnInit() {
    // this.initDays()
    console.log("month")
    this.initDays()
  }

  private initDays(): void {
    const date = this._date || new Date();
    const [start, end] = [startOfMonth(date), endOfMonth(date)];

    this.days = eachDayOfInterval({ start, end }).map((d: Date) => this.generateDay(d));

    const tmp = getDay(start) //- (this.options.firstCalendarDay as number);
    const prevDays = tmp < 0 ? 7 /* - (this.options.firstCalendarDay as number) */ : tmp;
    for (let i = 1; i <= prevDays; i++) {
      const d = subDays(start, i);
      this.days.unshift(this.generateDay(d, false));
    }
    this.rows = []
    let row = []
    for (let i = 0; i < this.days.length; i++) {
      row.push(this.days[i])
      if (row.length == 7) {
        this.rows.push(row)
        row = []
      }
    }
    if (row.length > 0) {
      this.rows.push(row)
    }
    // console.log(JSON.stringify(this.rows))
  }
  private generateDay(date: Date, inThisMonth: boolean = true): Day {
    let is_after = isAfter(date, this.innerValue) || isSameDay(date, this.innerValue)
    return {
      date,
      day: getDate(date),
      month: getMonth(date),
      year: getYear(date),
      inThisMonth,
      isToday: isToday(date),
      isSelected:
        isSameDay(date, this.innerValue) && isSameMonth(date, this.innerValue) && isSameYear(date, this.innerValue),
      isSelectable: is_after
    };
  }
  setDate(day: any): void {
    this.dateChange.emit(day.date);
  }

}
