import { Component, OnInit } from '@angular/core';
import { PhoneComponent } from '../phone/phone.component';

@Component({
  selector: 'phone-answer',
  templateUrl: './phone-answer.component.html',
  styleUrls: ['../phone/phone.component.scss']
})
export class PhoneAnswerComponent extends PhoneComponent {



}
