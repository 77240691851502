import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { RemoveAssets, ShowCC } from '../../pages.actions';
import { PagesState } from '../../pages.model';

@Component({
  selector: 'del-assets',
  templateUrl: './del-assets.component.html',
  styleUrls: ['./del-assets.component.scss']
})
export class DelAssetsComponent {
  constructor(
    private pagesState: Store<PagesState>,
  ) {

  }
  async delAssets() {

    // let array = this.selected_assets.array
    // let assets_ids: string[] = []
    // for (let i = 0; i < array.length; i++) {
    //   let target = array[i]
    //   let key = this.asset_keys[target]
    //   if (this.assets[key]) {
    //     assets_ids.push(key)
    //   }
    // }
    // this.selected_assets = new Hash()
    // this.num_of_movable_assets = 0
    try {
      this.pagesState.dispatch(new RemoveAssets())
      this.pagesState.dispatch(new ShowCC(""))
      // await this.tool_service.deleteAssets(this.folder, assets, cut) //We will need a new stream for the new room

    } catch (e: any) {
      //displayed in the tool_service
    }

  }
  close() {
    this.pagesState.dispatch(new ShowCC(""))

  }
}
