import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { WSSState } from 'src/app/wss/wss.model';
import { callerId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { AskHangUp } from '../../pages.actions';

import { PagesState } from '../../pages.model';

@Component({
  selector: 'confirm-hang-up',
  templateUrl: './confirm-hang-up.component.html',
  styleUrls: ['./confirm-hang-up.component.scss']
})
export class ConfirmHangUpComponent implements OnInit {
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() contract: boolean = false
  bCookiesSaved: boolean = false
  caller_id: string | undefined
  // cookie_text: string = "Save cookies"
  constructor(
    private pagesState: Store<PagesState>,
    private wss_service: WSSService,
    public wssState: Store<WSSState>,
  ) {
    this.wssState.select(callerId).subscribe((caller_id: string | undefined) => {
      if (caller_id) {
        this.caller_id = caller_id
      }
    })
  }

  ngOnInit(): void {
    let caller_id = localStorage.getItem("caller_id")
    if (caller_id) {
      this.bCookiesSaved = true;
    }
  }
  cancel() {
    this.pagesState.dispatch(new AskHangUp(false))
  }
  hangUp() {
    this.wss_service.hangUp()
    this.pagesState.dispatch(new AskHangUp(false))
    // this.pagesState.dispatch(new InPage('after'))
    // this.close.emit()
  }
  cookiesChange($event: boolean) {
    setTimeout(() => {
      this.bCookiesSaved = $event
      if (!this.bCookiesSaved) {
        localStorage.clear();
      } else {
        if (this.caller_id) {
          localStorage.setItem("caller_id", this.caller_id)
        }
      }
      setTimeout(() => {
        this.bCookiesSaved = false
      }, 500)
    })

  }
}
