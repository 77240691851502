import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SVG } from '@svgdotjs/svg.js';

@Component({
  selector: 'path-title',
  templateUrl: './path-title.component.html',
  styleUrls: ['./path-title.component.scss']
})
export class PathTitleComponent {
  @Output() selected: EventEmitter<number> = new EventEmitter();

  path_lbl: string = ""
  _path: any[] = []
  path_title_left: number = 0;
  title_parts: string[] = []
  @Input() set path(p: any[]) {
    // let p1: any[] = []
    // for (let i = 0; i < 12; i++) {
    //   let lbl = "F"
    //   for (let j = 0; j < i; j++) {
    //     lbl += j.toString()
    //   }
    //   p1.push({ name: "F_" + lbl + i })
    // }
    console.log("Path " + JSON.stringify(p))
    this._path = p
    // if (p.length > 1) {
    this.setTitle()
    // } else {
    // this.title_parts = p
    // }
  }
  @ViewChild('path') path_div: ElementRef | undefined;
  constructor(
  ) {
    console.log("path component")
  }
  ngAfterViewInit() {
    this.setTitle()
  }
  setTitle() {
    if (this.path_div) {
      let client_width = this.path_div.nativeElement.clientWidth
      let path_title_left = -1
      let path_lbl = ""
      let compres_up_to = 0
      let delete_up_to = -1
      let title_parts: string[] = []
      do {
        title_parts = []
        path_lbl = ""
        let next = ""
        for (let i = 0; i < this._path.length; i++) {
          let part = this._path[i].name
          if (i >= delete_up_to) {
            if (i < compres_up_to) {
              part = part.substr(0, 3)
            }
            let lbl = next + part
            path_lbl += lbl
            next = " > "
            title_parts.push(lbl)
          } else {
            title_parts.push("")
          }
        }
        let draw = SVG()
        let drawing_text = draw.text(path_lbl).font({
          family: "Open Sans"
          , size: 16
          , weigth: 700
        }).fill("#000")
        let bbox = drawing_text.bbox()

        let pat_title_width = bbox.width * 1.02
        let pat_width = client_width
        if (pat_title_width > pat_width) {
          path_title_left = pat_width - pat_title_width
        } else {
          path_title_left = 0
        }
        if (compres_up_to >= this._path.length - 1) {
          delete_up_to++
        } else {
          compres_up_to++
        }
      } while (
        path_title_left < 0 && delete_up_to < this._path.length
      )
      if (delete_up_to > 0) {
        path_lbl = "..." + path_lbl.substring(3, path_lbl.length)
        if (delete_up_to > 0) {
          title_parts[delete_up_to - 1] = "..."
        }
      }
      setTimeout(() => {
        this.path_title_left = path_title_left
        this.title_parts = title_parts
      })
    }
  }
  select(i: number) {
    this.selected.emit(i)
  }
}
