<div class="out">
  <div class="container">
    <div class="content">
      <div class="spt">
        <span class="bld">Embed keyzii in your website</span>
      </div>
      <div class="kzfr">
        keyzii frame
      </div>
      <div class="scrpt">
        keyzii API script
      </div>


      <div class="line flex">
        <div class="code">
          {{iframe_example}}
        </div>
        <img class="copy" src="assets/images/copy.svg"  kzLib (click)="copyFrame($event)">
      </div>

      <div class="spt2">
        <span class="bld ">Advance asset sharing</span>
      </div>
      <div class="line section"> <span class="bld">1. Follow the steps in the API documentation.</span> <a href="https://www.keyzii.com/apidocs" target="_blank" rel="noopener noreferrer">Click here </a>
      </div>
      <div class="line flex">
        <div class="code">
          {{script_url}}
        </div>
        <img class="copy" src="assets/images/copy.svg"  kzLib (click)="copy($event)">

      </div>

      <div class="line section"><span class="bld">2. Enter your URL: </span>In the input field below, paste the URL of the webpage with assets you wish to share.
        <input kzInput class="in" type="text" placeholder="Insert url here" name="" value="" [(ngModel)]="api_iframe_url" (ngModelChange)="changeURL($event)">

      </div>
      <div class="line section"><span class="bld">3. API display options: </span>
      </div>

      <div class="line flex">
        Show API in separate browser window <switch class="switch" [checked]="show_in=='browser'"  kzLib (click)="show_in='browser'"></switch>
      </div>
      <div class="line flex">
        Show API in keyzii window
        <switch class="switch" [checked]="show_in=='wnd'"  kzLib (click)="show_in='wnd'"></switch> <span class="sm">(make sure the site can run in an iFrame)
        </span>
      </div>
      <div class="line flex">
        Show API in keyzii side bar
        <switch class="switch" [checked]="show_in=='tools'"  kzLib (click)="show_in='tools'"></switch><span class="sm">(make sure the site can run in an iFrame) </span>
      </div>
      <div class="line indent section">
        <span class="bld ">4. Go Live:</span> Once on a call, go to the asset side bar, click on the icon <img class="icon" src="assets/images/api_icon.svg" alt=""> to present your API window to search the asset and share from your entered URL
        above.
      </div>

      <div class="line bld support">Support and Resources</div>
      <div class="line mw740">
        For detailed instructions and tips on how to optimize your live presentations, visit skool.com/keyzii or reach out to our customer support.
      </div>
      <div class="end">

      </div>
    </div>
  </div>
</div>
