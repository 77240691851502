<div class="out">
  <div class="cal_opts">

    <div class="next_back" *ngIf="!minimized">
      {{next_lbl}}
      <img *ngIf="sel_index<2" class="expand_i" src="assets/images/expand_l.svg"  kzLib (click)="prev()">
      <img *ngIf="sel_index<2" class="expand_i expand_b" src="assets/images/expand_l.svg"  kzLib (click)="next()">
    </div>
    <div class="opts">
      <div class="cal_opt" *ngFor="let sel of sel_cal; let i = index" [ngClass]="{sel:i==sel_index}"  kzLib (click)="setSel(i)">{{sel}}</div>
    </div>
  </div>
  <div *ngIf="minimized" class="min_div"></div>

  <app-month class="component" (dateChange)="setDate($event)" *ngIf="sel_index==0">
  </app-month>
  <app-week class="component" *ngIf="sel_index==1">
  </app-week>
  <app-day class="component" *ngIf="sel_index==2">
  </app-day>
</div>