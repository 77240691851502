import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';
import { GetInitials, GetSignature, IsInitials, SelectToolOpts, SetTool } from '../../canvas.actions';
import { changeInitials, changeSignature, isInitial, selectedTool, selSignerKey, signers, signerSteps } from '../../canvas.selectors';
import { SVG } from '@svgdotjs/svg.js';
import { CanvasService } from '../../canvas.service';
import { myEmail } from 'src/app/pages/pages.selectors';
@Component({
  selector: 'sign-tools',
  templateUrl: './sign-tools.component.html',
  styleUrls: ['./sign-tools.component.scss']
})
export class SignToolsComponent {

  signer_title: string = ""
  signer_color: string = ""
  target: any
  // sel_tool: string = "sign"


  signers: any = {}
  signer: any = {}
  signer_key: string = "";
  bShowSign: boolean = false;

  sign_create_or_edit: string = "Create"
  init_create_or_edit: string = "Create"
  bSign: boolean = false //selection sign or initials
  bInit: boolean = false
  signature_text = "Signature"
  initials_text = "Initials"
  @ViewChild('signComponent') signComponent: ElementRef | undefined
  @ViewChild('initComponent') initComponent: ElementRef | undefined

  bIAmSigner: boolean = false
  sing_data: any
  init_data: any
  my_email: string = ""
  // tool_name:string=""

  signature_width: number = 100
  subscriptions: any[] = []

  constructor(
    public canvasStore: Store<CanvasState>,
    public pagesState: Store<PagesState>,
    public canvas_service: CanvasService,
  ) {
    console.log("sign tools")

    this.sing_data = localStorage.getItem("signature")
    this.init_data = localStorage.getItem("initials")


    this.subscriptions.push(this.canvasStore.select(isInitial).subscribe((bInit: boolean) => {
      this.bInit = bInit
      this.bSign = !this.bInit
    }))

    this.subscriptions.push(this.canvasStore.select(signers).subscribe((signers: any) => {
      this.signers = signers
      this.setUI()

    }))
    this.subscriptions.push(this.pagesState.select(myEmail).subscribe((email: string) => {
      if (email) {
        this.my_email = email
      }
    }))
    this.subscriptions.push(this.canvasStore.select(selSignerKey).subscribe((signer_key: string | undefined) => {
      if (signer_key) {
        this.signer_key = signer_key
        let signer_name = this.signers[this.signer_key].name
        this.signature_text = signer_name.charAt(0).toUpperCase() + signer_name.slice(1) + " Signature"
        this.initials_text = signer_name.charAt(0).toUpperCase() + signer_name.slice(1) + " Initials"
        this.setUI()
      }
    }))

    this.subscriptions.push(this.canvasStore.select(signerSteps).subscribe((steps: any[]) => {
      this.bShowSign = steps.length > 0 //we have to sign something
      // this.setUI()
    }))

    this.subscriptions.push(this.canvasStore.select(changeSignature).subscribe((data: any | undefined) => {
      if (data) {
        this.sign_create_or_edit = "Edit"
        this.sing_data = data
        this.setSVGInDiv(this.sing_data, this.signComponent)
      }
    }))
    this.subscriptions.push(this.canvasStore.select(changeInitials).subscribe((data: any | undefined) => {
      if (data) {
        this.init_create_or_edit = "Edit"
        this.init_data = data
        this.setSVGInDiv(this.init_data, this.initComponent)
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.setSVGInDiv(this.sing_data, this.signComponent)
    this.setSVGInDiv(this.init_data, this.initComponent)
  }
  setSVGInDiv(data: any, canvas: ElementRef | undefined) {

    function getChildren(svg: any, rv: any[]): any[] {
      let children = svg.children()

      for (let i = 0; i < children.length; i++) {
        let el = children[i]
        if (el.type == "polyline") {
          rv.push(el)
        } else if (el.type == "text") {
          rv.push(el)
        } else if (el.type == "svg" || el.type == "g") {
          rv = getChildren(el, rv)
        }
      }
      return rv
    }
    if (data && canvas) {


      let svg: any = SVG(data.svg)
      let new_children = getChildren(svg, [])


      let draw_tool = SVG().addTo(canvas.nativeElement)
      let group = draw_tool.group()

      let x1 = 0// canvas.nativeElement.offsetLeft
      let y1 = 0//canvas.nativeElement.offsetTop
      let x2 = x1 + canvas.nativeElement.clientWidth
      let y2 = y1 + canvas.nativeElement.clientHeight



      let new_width = (x2 - x1)
      let new_height = (y2 - y1)
      let l = 1000
      let t = 1000
      let b = 0
      let r = 0
      for (let i = 0; i < new_children.length; i++) {
        let el = new_children[i]
        if (el.type == "polyline") {
          var bbox = el.bbox();
          if (bbox.x < l) {
            l = bbox.x
          }
          if (bbox.y < t) {
            t = bbox.y
          }

          if (bbox.x2 > r) {
            r = bbox.x2
          }
          if (bbox.y2 > b) {
            b = bbox.y2
          }
        }
      }
      let orig_height = b - t
      let orig_width = r - l
      let scale_x = new_width / orig_width
      let scale_y = new_height / orig_height
      let scale

      if (scale_x < scale_y) {
        scale = scale_x
      } else {
        scale = scale_y
      }

      let scaled_height = orig_height * scale
      let scaled_width = orig_width * scale
      let me = this

      function drawText(el: any) {
        var bbox = el.bbox();

        let attr = el.attr()
        let new_el = draw_tool.text(attr)
        let text = el.text()
        new_el.text(text)
        let tbox = new_el.bbox()
        if (tbox.w + 20 > me.signature_width) {
          me.signature_width = tbox.w + 20;
        } if (me.signature_width > x2) {
          x2 = me.signature_width
        }


        var t_w = tbox.width;
        var t_h = bbox.height;
        let r_w = x2 - x1
        let r_h = y2 - y1
        let x = x1 + (r_w - t_w) / 2
        let cy = (r_h - t_h) / 2
        let y = y1 + cy + t_h - 5
        new_el.attr({ x: x, y: y })
        group.add(new_el)
        console.log("Center it")

      }
      for (let i = 0; i < new_children.length; i++) {
        let el = new_children[i]
        if (el.type == "text") {
          drawText(el)
        }
      }
      for (let i = 0; i < new_children.length; i++) {
        let el = new_children[i]
        if (el.type == "polyline") {
          console.log("signature  w1 " + orig_width + " w2 " + scaled_width)
          console.log("signature  h1 " + orig_height + " h2 " + scaled_height)

          let dy = (new_height - scaled_height) / 2
          let dx = (new_width - scaled_width) / 2
          console.log("signature  dx " + dx + " dy " + dy)

          let new_points: number[] = []
          let spoints = el.attr('points')
          let points = this.getArrayFromStr(spoints)

          for (let i = 0; i < points.length; i++) {
            let p = points[i]
            if (i % 2 == 0) { //X
              let px = p - l
              px *= scale
              p = px + dx
            } else {
              let py = p - t
              py *= scale
              p = py + dy
            }
            new_points.push(p)
          }
          let new_el = draw_tool.polyline(new_points).fill('transparent').stroke({ width: 1, color: 'black' })
          console.log("draw_tool.polyline( " + JSON.stringify(new_points))
          group.add(new_el)
        }
      }
    }

  }

  getArrayFromStr(spoints: string) {
    let poly_array: number[] = []
    // console.log("poly  set rect " + spoints)
    if (spoints) {
      let points = spoints.split(' ')

      for (let i = 0; i < points.length; i++) {
        let point = points[i]
        let parts = point.split(',')
        for (let j = 0; j < parts.length; j++) {
          let val = parseFloat(parts[j])
          poly_array.push(val)
        }
      }
      // console.log("poly  set rec  " + JSON.stringify(poly_array))
    } else {
      console.error("no spoints")
    }
    return poly_array

  }
  setUI() {
    this.signer = this.signers[this.signer_key]
    if (this.signer) {
      this.bIAmSigner = (this.signer.email == this.my_email)
      this.signer_title = this.signer.title
      this.signer_color = this.signer.color
      if (this.signer_key == "rep") {
        this.bShowSign = true
      }
    }
  }

  signature() {
    this.canvasStore.dispatch(new IsInitials(false))
    this.canvasStore.dispatch(new SetTool("drop_signature"))
    this.canvasStore.dispatch(new SelectToolOpts("none2"))
  }
  initials() {
    this.canvasStore.dispatch(new IsInitials(true))
    this.canvasStore.dispatch(new SetTool("drop_initials"))
    this.canvasStore.dispatch(new SelectToolOpts("none3"))
  }


  editSignature() {
    // let id = new Date().getTime() * -1
    this.canvasStore.dispatch(new GetSignature("signature"))
    this.canvasStore.dispatch(new SelectToolOpts("none4"))
  }
  editInitials() {
    this.canvasStore.dispatch(new GetInitials("initials"))
    this.canvasStore.dispatch(new SelectToolOpts("none5"))
  }

}
