/*
Copyright(c) 2022 Sergio A. Fernandez, Ph.D.
*/

import { ChapalaMLSAction, ChapalaMLSActionTypes } from "./chapala-mls.actions";
import { ChapalaMLSState, initialChapalaMLSState } from "./chapala-mls.model";

function setSearchURL(state: ChapalaMLSState) {
  let pn = state.page_no
  let search_url = "&page=" + pn
  if (state.search_by.city) {
    search_url += "&cities[]=" + state.search_by.city.city_id
  }
  if (state.search_by.category) {
    search_url += "&category_ids[]=" + state.search_by.category
  }
  if (state.search_by.baths) {
    search_url += "&nbaths=" + state.search_by.baths
  }
  if (state.search_by.beds) {
    search_url += "&nbeds=" + state.search_by.beds
  }
  if (state.search_by.casitas) {
    search_url += "&casita=" + state.search_by.casitas
  }
  if (state.search_by.pool) {
    search_url += "&pool=" + state.search_by.pool
  }
  if (state.search_by.floors) {
    search_url += "&nfloors=" + state.search_by.floors
  }
  if (state.search_by.furnished) {
    search_url += "&pro_types=" + state.search_by.furnished
  }
  if (state.search_by.gated) {
    search_url += "&gated_comm=" + state.search_by.gated
  }
  if (state.search_by.max_price) {
    search_url += "&max_price=" + state.search_by.max_price
  }
  if (state.search_by.min_price) {
    search_url += "&min_price=" + state.search_by.min_price
  }
  if (state.search_by.parking) {
    search_url += "&parking_street=" + state.search_by.parking
  }
  if (state.search_by.props_status) {
    search_url += "&pro_types=" + state.search_by.props_status
  }
  if (state.search_by.view) {
    search_url += "&with_view=" + state.search_by.view
  }
  if (state.search_by.yard) {
    search_url += "&with_yard=" + state.search_by.yard
  }
  state.search_url = search_url
}

// let streams_cache: any = {}
export function chapalaMLSReducer(state = initialChapalaMLSState,
  action: ChapalaMLSAction): ChapalaMLSState {
  switch (action.type) {
    case ChapalaMLSActionTypes.GotMLSMsg: {
      console.log("MLS msg " + JSON.stringify(action.msg))
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.msg = action.msg
      return mlsState;
    }


    case ChapalaMLSActionTypes.ShowMap: {
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.show_map = action.show
      return mlsState;
    }
    case ChapalaMLSActionTypes.CleanMLS: {
      return initialChapalaMLSState;
    }

    case ChapalaMLSActionTypes.SetImages: {
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.images = action.imgs
      return mlsState;
    }
    case ChapalaMLSActionTypes.SelImage: {
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.img_index = action.index
      return mlsState;
    }
    case ChapalaMLSActionTypes.GSetMLSPage: {
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.page_no = action.page_no
      setSearchURL(mlsState)
      return mlsState;
    }
    case ChapalaMLSActionTypes.ClearSearch: {
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.search_by = {}
      setSearchURL(mlsState)
      return mlsState;
    }

    case ChapalaMLSActionTypes.SetSearch: {
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      const search_by: any = Object.assign({}, state.search_by);
      search_by[action.key] = action.value
      mlsState.search_by = search_by
      setSearchURL(mlsState)
      return mlsState;
    }

    case ChapalaMLSActionTypes.ShowImages: {
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.bShowImgs = action.bShowImgs
      return mlsState;
    }
    case ChapalaMLSActionTypes.SelProperty: {
      // console.log("GotProperties")
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.property = action.property
      return mlsState;
    }

    case ChapalaMLSActionTypes.GotProperties: {
      // console.log("GotProperties")
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.properties = action.properties
      return mlsState;
    }
    case ChapalaMLSActionTypes.GotStates: {
      // console.log("GotStates")
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.states = action.states
      return mlsState;
    }

    case ChapalaMLSActionTypes.GotCities: {
      console.log("GotCities x")
      const mlsState: ChapalaMLSState = Object.assign({}, state);
      mlsState.cities = action.cities
      return mlsState;
    }


    default: {
      return state;
    }
  }
}
