import { Action } from '@ngrx/store';

export enum ChapalaMLSActionTypes {
  //Tools
  GotStates = '[states]got',
  GotCities = '[cities]got',
  GotProperties = '[properties]got',
  SelProperty = '[property]sel',
  ShowImages = '[imgs]show',
  SetSearch = '[search]set',
  ClearSearch = '[search]clear',
  SelImage = '[imgs]sel',
  GSetMLSPage = '[MSL]get',
  SetImages = '[imgs]set',

  GotMLSMsg = '[msg]gotmls',
  CleanMLS = '[mls]clean',
  ShowMap = '[mlsmap]show'
}
export class ShowMap implements Action {
  readonly type = ChapalaMLSActionTypes.ShowMap;
  constructor(public show: boolean) { }
}
export class GotMLSMsg implements Action {
  readonly type = ChapalaMLSActionTypes.GotMLSMsg;
  constructor(public msg: any) { }
}
export class CleanMLS implements Action {
  readonly type = ChapalaMLSActionTypes.CleanMLS;
  constructor() { }
}

export class SetImages implements Action {
  readonly type = ChapalaMLSActionTypes.SetImages;
  constructor(public imgs: any[]) { }
}
export class SelImage implements Action {
  readonly type = ChapalaMLSActionTypes.SelImage;
  constructor(public index: number) { }
}

export class ClearSearch implements Action {
  readonly type = ChapalaMLSActionTypes.ClearSearch;
  constructor() { }
}
export class SetSearch implements Action {
  readonly type = ChapalaMLSActionTypes.SetSearch;
  constructor(public key: string, public value: any) { }
}
export class ShowImages implements Action {
  readonly type = ChapalaMLSActionTypes.ShowImages;
  constructor(public bShowImgs: boolean) { }
}
export class SelProperty implements Action {
  readonly type = ChapalaMLSActionTypes.SelProperty;
  constructor(public property: any) { }
}
export class GotStates implements Action {
  readonly type = ChapalaMLSActionTypes.GotStates;
  constructor(public states: any[]) { }
}
export class GotCities implements Action {
  readonly type = ChapalaMLSActionTypes.GotCities;
  constructor(public cities: any[]) { }
}
export class GotProperties implements Action {
  readonly type = ChapalaMLSActionTypes.GotProperties;
  constructor(public properties: any[]) { }
}

export class GSetMLSPage implements Action {
  readonly type = ChapalaMLSActionTypes.GSetMLSPage;
  constructor(public page_no: number) { }
}

export type ChapalaMLSAction =
  GotStates
  | GotCities
  | GotProperties
  | SelProperty
  | ShowImages
  | SetSearch
  | ClearSearch
  | SelImage
  | GSetMLSPage
  | SetImages

  | GotMLSMsg
  | CleanMLS
  | ShowMap
  ;
