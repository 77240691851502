import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { CancelLocalKPay } from '../kpay.actions';
import { kPayState } from '../kpay.model';
import { gotError } from '../kpay.selectors';

@Component({
  selector: 'kplan-error',
  templateUrl: './kplan-error.component.html',
  styleUrls: ['../kplan-wnd/kplan-wnd.component.scss', './kplan-error.component.scss']
})
export class KplanErrorComponent {
  @Input() error: string = ""
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(
    public kpayState: Store<kPayState>,
  ) {
    console.log("kplan-error")
    this.kpayState.select(gotError).subscribe((error: string | undefined) => {
      if (error) {
        this.error = error
      }
    })

  }
  closeIt() {
    this.close.emit()
  }
}
