import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts, SetColors } from '../../canvas.actions';
import { fillColor, pencilWidth, strokeColor, textColor, textFont, textSize, textWeight } from '../../canvas.selectors';

@Component({
  selector: 'colors-tool',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent {

  parent: HTMLElement | undefined = undefined;

  style = "stroke:rgb(255,0,0);stroke-width:5"
  color: string = "rgba(155,155,120,0.43)"//"#ffff00"
  presets: string[] = ["rgba(255,255,0,0.54)", "rgba(255,165,0,0.55)", "rgba(54,255,0,0.56)"]


  @ViewChild('out') out: ElementRef | undefined | undefined;

  subscriptions: any[] = []
  constructor(
    public canvasStore: Store<CanvasState>
  ) {
    console.log("colors tools")


    this.subscriptions.push(this.canvasStore.select(fillColor).subscribe((fill_color: string) => {
      this.color = fill_color
      // this.setThePallet()
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.out) {
        let children = this.out.nativeElement.children
        if (children.length > 1) {
          let pick = children[1]
          pick.children[0].style.border = "none"
        }
      }
    })
    // this.setColor({})
  }
  cancel() {
    this.canvasStore.dispatch(new SelectToolOpts("none1"))
  }

  setColor($event: any) {
    this.color = $event
    this.canvasStore.dispatch(new SetColors(this.color, this.color, this.color))
  }

}
