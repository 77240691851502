import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from 'src/app/chapala-mls/chapala-mls-service';
import { GotMLSMsg, SetSearch } from 'src/app/chapala-mls/chapala-mls.actions';
import { ChapalaMLSState } from 'src/app/chapala-mls/chapala-mls.model';
import { gotMLSMsg, searchBy } from 'src/app/chapala-mls/chapala-mls.selectors';
import { SelectorComponent } from '../selector.component';

@Component({
  selector: 'mls-beds-baths',
  templateUrl: './beds-baths.component.html',
  styleUrls: ['../selector.component.scss', './beds-baths.component.scss']
})
export class BedsBathsComponent extends SelectorComponent {
  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);
    console.log("Beds & Baths")
    this.setTitle("Beds & Baths")

    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      if (msg) {
        if (msg.beds) {
          this.chapalaMLState.dispatch(new SetSearch("beds", msg.beds))
        }
        if (msg.baths) {
          this.chapalaMLState.dispatch(new SetSearch("baths", msg.baths))
        }
      }
    }))
    this.subscriptions.push(this.chapalaMLState.select(searchBy).subscribe((search_by: any) => {
      if (search_by) {

        setTimeout(() => {
          let title = "Beds & Baths"
          if (search_by.beds && search_by.baths) {
            title = search_by.beds + " Beds, " + search_by.baths + " Baths"
          } else if (search_by.beds) {
            title = search_by.beds + " Beds"
          } else if (search_by.baths) {
            title = search_by.baths + " Baths"
          }
          this.title = title
        }, 1)

      }
    }))

  }
  setBeds(val: string) {
    let msg = { beds: val }
    this.chapala_mls_service.sendMsg(msg)
  }
  setBaths(val: string) {
    let msg = { baths: val }
    this.chapala_mls_service.sendMsg(msg)
  }

}
