/*
(C)2023  Venditio Sergio A. Fernandez, Ph.D.

*/

export interface WSSState {
  session_id: string | undefined
  session_expired: number
  my_id: string | undefined
  customer_id: string | undefined
  busy: boolean,

  call_id: string | undefined,

  rep_id: string | undefined,

  caller_id: string | undefined,
  // caller_video: boolean,
  // caller_audio: boolean,

  manager_id: string | undefined,
  // manager_video: boolean,
  // manager_audio: boolean,

  last_msg: any,
  last_on_board: any
  last_kzApi_msg: any
  left_call: string | undefined
}

export const initialWSSState: WSSState = {
  session_id: undefined,
  session_expired: 0,
  my_id: undefined,
  customer_id: undefined,
  busy: false,

  call_id: undefined,
  rep_id: undefined,

  caller_id: undefined,
  // caller_video: false,
  // caller_audio: false,

  manager_id: undefined,
  // manager_video: false,
  // manager_audio: false,

  last_msg: undefined,
  last_on_board: undefined,
  last_kzApi_msg: undefined,
  left_call: undefined,
};
