import { Component, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { KeyziiService } from 'src/app/wss/keyzii.service';
import { PagesState } from '../pages.model';
import { myEmail, myLastName, myName, showWelcome } from '../pages.selectors';

@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  welcome: string | undefined
  bDontShow: boolean = false
  name: string = ""
  last_name: string = ""
  my_email: string = ""
  url: any | undefined
  @Output() close: EventEmitter<any> = new EventEmitter();


  constructor(
    public pagesState: Store<PagesState>,
    private sanitizer: DomSanitizer,
    private keyzii_service: KeyziiService,
  ) {
    console.log("welcome")
    this.pagesState.select(showWelcome).subscribe((who: string | undefined) => {
      this.welcome = who
    })
    this.pagesState.select(myEmail).subscribe((email: string | undefined) => {
      if (email) {
        this.my_email = email

      }
    })
    this.pagesState.select(myName).subscribe((name: string | undefined) => {
      if (name) {
        this.name = name
      }
    })
    this.pagesState.select(myLastName).subscribe((last_name: string | undefined) => {
      if (last_name) {
        this.last_name = last_name
      }
    })
  }
  ngAfterViewInit() {
    let url = "https://7z5p11pz1rf.typeform.com/keyzii-admin-ob"
    url += "?who=" + this.welcome

    if (this.my_email) {
      url += "&email=" + this.my_email
    }
    if (this.name) {
      url += "&name=" + this.name
    }
    if (this.last_name) {
      url += "&last=" + this.last_name
    }
    this.url = url
    setTimeout(() => {

      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    })
  }
  checkIt($event: boolean) {
    this.bDontShow = $event
  }
  cancel() {
    if (this.bDontShow) {
      this.keyzii_service.setUserData({ welcome: "no" })
    } this.close.emit()

  }
}
