import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ControlsState } from 'src/app/controls/controls.model';
import { KeyziiService } from 'src/app/wss/keyzii.service';
import { PagesState } from '../../pages.model';
import { customizeAccount } from '../../pages.selectors';
import { PagesService } from '../../pages.service'
import { NotifyUser, SetCustomizaton } from '../../pages.actions';
import { SVG } from '@svgdotjs/svg.js';
import { CanvasUploaderService } from 'src/app/canvas/canvas-uploader.service';
import { loResImg } from 'src/app/utils';

@Component({
  selector: 'app-customization',
  templateUrl: './customization.component.html',
  styleUrls: ['./customization.component.scss']
})
export class CustomizationComponent {
  @ViewChild('file') file_field: ElementRef | undefined


  @Output() enableSave: EventEmitter<boolean> = new EventEmitter();
  @Input() set save_it(what: string) {
    if (what.startsWith("custom")) {
      this.saveIt()
    }
  }

  bDarkMode: boolean = false;

  company_logo: string | undefined
  company_favicon: string | undefined
  company_icon: string | undefined

  btn_radius: number = 24
  sbtn_radius: string = "24"
  border_radius: string = ""
  border_radius1: string = ""
  avaliable: string = ""
  offline: string = ""
  avaliable_tx: string = "Call Now"
  offline_tx: string = "Book a Call"
  btn_back: string = "#3B5FE0"
  btn_color: string = "black"

  bShowSpinner: boolean = false;
  uploader: FileUploader | undefined
  uploading: string = "logo"

  avaliable_img: string = ""
  off_line_img: string = ""
  subscriptions: any[] = []

  av: string = "both"

  available_pos: number = 100
  off_line_pos: number = 100
  fname: any
  tab_name: string = ""
  constructor(
    public upload_service: CanvasUploaderService,
    public controlsState: Store<ControlsState>,
    private keyzii_service: KeyziiService,
    private pagesState: Store<PagesState>,
    private page_service: PagesService

  ) {
    console.log("Custom")
    // this.setBorder()

    this.subscriptions.push(this.pagesState.select(customizeAccount).subscribe((customize: any) => {
      if (customize) {
        if (customize.theme) {
          this.bDarkMode = customize.theme == "dark"
        }
        if (customize.logo) {
          // this.logo = customize.logo
          this.company_logo = customize.logo
        }
        if (customize.icon) {
          // this.icon = customize.icon
          this.company_icon = customize.icon
        }
        if (customize.fav_icon) {
          this.company_favicon = customize.fav_icon
        }
        if (customize.btn_back) {
          this.btn_back = customize.btn_back
        }
        if (customize.btn_color) {
          this.btn_color = customize.btn_color
          console.log("btn_color <-- " + this.btn_color)
        }
        if (customize.border_radius) {
          this.border_radius = customize.border_radius
          let br = parseInt(this.border_radius)
          this.btn_radius = br;
          this.border_radius1 = br.toString()
        }
        if (customize.avaliable_tx) {
          this.avaliable_tx = customize.avaliable_tx
          this.avaliable = this.avaliable_tx
        }
        if (customize.offline_tx) {
          this.offline_tx = customize.offline_tx
          this.offline = customize.offline_tx
        }
        if (customize.tab_name) {
          this.tab_name = customize.tab_name
        }
        this.makeButtons()
      }
    }))

    this.uploader = this.upload_service.setUpUploader("image")
    let me = this
    this.uploader.onCompleteItem = async (item: FileItem, response: string, status: number, _header: any) => {


      if (status == 200) {
        try {
          let target_w = 90
          let target_h = 90
          if (this.uploading == 'logo') {
            target_w = 200
          }

          let type = item._file.type
          let img_src = 'data:' + type + ';base64,' + response //picture.url
          let low_res
          if (type == "image/svg+xml") {
            low_res = img_src
          } else {
            low_res = await loResImg(img_src, target_w, target_h, false) // crop
          }

          if (me.uploading == 'logo') {
            me.company_logo = low_res
          } else if (me.uploading == 'fav') {
            me.company_favicon = low_res
          } else if (me.uploading == 'icon') {
            me.company_icon = low_res
          } else {
            console.error("uploading " + this.uploading)
          }
          this.enableSave.emit(true)
        } catch (e) {
          console.error(e)
        }
        // this.changed()
        // let jres: any = JSON.parse(rsponse)

        // let data = this.setData()
        // this.pagesState.dispatch(new SetCustomizaton(data))

      }
    }
  }

  ngAfterViewInit() {
    this.makeButtons()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngOnInit(): void {
  }

  setBorder($event: any) {
    this.border_radius = $event
    let data = this.setData()
    this.pagesState.dispatch(new SetCustomizaton(data))
    this.makeButtons()
    this.changed()
  }
  changedAvailable(val: string) {
    setTimeout(() => {
      this.avaliable_tx = val
      this.makeButtons()
      this.changed()
    })
  }
  changedOffLine(val: string) {
    setTimeout(() => {
      this.offline_tx = val
      this.makeButtons()
      this.changed()
    })
  }
  changedBorwserTabName(val: string) {
    setTimeout(() => {
      this.tab_name = val
      this.changed()
    })
  }
  selectedUploadImage(evt: any) {
    this.bShowSpinner = true;
    if (this.uploader) {
      this.uploader.uploadAll();
    }
    evt.srcElement.value = ""
  }
  setData() {

    let data: any = {
      avaliable_tx: this.avaliable_tx,
      offline_tx: this.offline_tx

    }
    if (this.bDarkMode) {
      data.theme = "dark"
    } else {
      data.theme = "light"
    }
    if (this.company_logo) {
      data.logo = this.company_logo
    }
    if (this.tab_name) {
      data.tab_name = this.tab_name
    }
    if (this.company_icon) {
      data.icon = this.company_icon
    }
    if (this.company_favicon) {
      data.fav_icon = this.company_favicon
    }

    if (this.btn_back) {
      data.btn_back = this.btn_back
    }
    if (this.btn_color) {
      data.btn_color = this.btn_color
    }
    if (this.border_radius) {
      data.border_radius = this.border_radius
    }
    if (this.avaliable_img) {
      data.avaliable_img = this.avaliable_img
    }
    if (this.off_line_img) {
      data.off_line_img = this.off_line_img
    }
    //add the customized buttons
    if (this.available) {
      data.available = this.available_svg
    }

    if (this.off_line) {
      data.off_line = this.off_line_svg
    }
    return data
  }

  saveIt() {

    let data = this.setData()
    this.pagesState.dispatch(new SetCustomizaton(data))
    this.keyzii_service.setCompanyData(data)
    this.pagesState.dispatch(new NotifyUser("Saved"))
    this.enableSave.emit(false)
  }
  setBackColor($event: any) {
    this.btn_back = $event
    let data = this.setData()
    this.pagesState.dispatch(new SetCustomizaton(data))
    this.makeButtons()
    this.changed()
  }
  setTextColor($event: any) {
    this.btn_color = $event
    let data = this.setData()
    this.pagesState.dispatch(new SetCustomizaton(data))
    this.makeButtons()
    this.changed()
  }
  changeTheme($event: any) {
    this.bDarkMode = $event;
    this.changed()
  }
  @ViewChild('available') available: ElementRef | undefined
  @ViewChild('off_line') off_line: ElementRef | undefined
  available_svg: string = ""
  off_line_svg: string = ""
  makeButtons() {
    setTimeout(() => {
      if (this.available) {
        this.available_svg = this.page_service.makeAvailableHTML(this.avaliable_tx)
        this.available.nativeElement.innerHTML = this.available_svg
        let svg = SVG(this.available.nativeElement.innerHTML)
        let elements = svg.children()
        let box = elements[0]
        let bbox = box.bbox()
        this.available_pos = bbox.width + 190

      }
      if (this.off_line) {
        this.off_line_svg = this.page_service.makeOffLineHTML(this.offline_tx)
        this.off_line.nativeElement.innerHTML = this.off_line_svg
        let svg = SVG(this.off_line.nativeElement.innerHTML)
        let elements = svg.children()
        let box = elements[0]
        let bbox = box.bbox()
        this.off_line_pos = bbox.width + 190
      }
    })
  }
  deleteLogo() {
    this.company_logo = undefined
    let data = this.setData()
    this.enableSave.emit(true)
  }
  changed() {
    this.enableSave.emit(true)
  }
  deleteIcon() {
    this.company_icon = undefined
    let data = this.setData()
    this.enableSave.emit(true)
  }
  deleteFav() {
    this.company_favicon = undefined
    let data = this.setData()
    this.enableSave.emit(true)
  }
}
