import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetAudio, SetVideo } from 'src/app/k-video/k-video.actions';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { audioOn, videoOn } from 'src/app/k-video/k-video.selectors';
import { fomatTime } from 'src/app/utils';
import { WSSState } from 'src/app/wss/wss.model';
import { myId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { CallWaiting, RejectedCall } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { callWaiting, notifySnd } from '../../pages.selectors';

@Component({
  selector: 'call-notification',
  templateUrl: './call-notification.component.html',
  styleUrls: ['./call-notification.component.scss']
})
export class CallNotificationComponent {
  waiting: string = "";
  caller_name: string = "Caller";
  caller_email: string = "";
  prev_rep_name: string = ""
  agent: string = "";
  my_id: string = "";
  interval: any;
  queue_entry: any = {}
  bAudioOn: boolean = false;
  bVideoOn: boolean = false;
  bShowHangUp: boolean = false;
  prev_date: Date | undefined
  bShowPersonal: boolean = false;
  bShowOpts: boolean = false;
  new_caller: string = "New Caller"
  audio: any
  bNotify: boolean = false
  count: number = 0;
  subscriptions: any[] = []

  constructor(
    public pagesState: Store<PagesState>,
    public wssState: Store<WSSState>,
    public wss_service: WSSService,
    public kVideoState: Store<KVideoState>,
  ) {
    console.log("call-notification")
    this.audio = new Audio();
    try {
      this.audio.src = "assets/ring.mp3";
      this.audio.load();
      if (this.bNotify) {
        this.audio.play()
      }
    } catch (e) {
      console.error(e)
    }

    if (!this.interval) {
      this.interval = setInterval(() => {
        this.setDuration()
        if (--this.count == 0) {
          this.count = 3;
          if (this.bNotify && this.audio) {
            this.audio.play()
          }
        }
      }, 1000)
    }
    this.subscriptions.push(this.pagesState.select(notifySnd).subscribe((bNotify: boolean) => {
      setTimeout(() => {
        this.bNotify = bNotify
        if (this.audio) {
          if (this.bNotify) {
            this.count = 3;
            this.audio.play()
          } else {
            this.audio.pause()
          }
        }
      });
    }))
    this.subscriptions.push(this.kVideoState.select(audioOn).subscribe((on: boolean) => {
      this.bAudioOn = on
    }))
    this.subscriptions.push(this.kVideoState.select(videoOn).subscribe((on: boolean) => {
      this.bVideoOn = on
    }))

    this.subscriptions.push(this.wssState.select(myId).subscribe((my_id: any) => {
      this.my_id = my_id
    }))

    this.subscriptions.push(this.pagesState.select(callWaiting).subscribe((queue_entry: any) => this.setQueuEntry(queue_entry)))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    if (this.audio) {
      this.audio.pause()
    }
    clearInterval(this.interval)
    delete this.audio
  }


  setQueuEntry(queue_entry: any) {
    if (queue_entry) {
      this.queue_entry = queue_entry
    } else {
      this.queue_entry = {}
    }
    if (queue_entry) {
      if (queue_entry.caller_email) {
        if (queue_entry.caller_email.length > 0) {
          this.caller_email = queue_entry.caller_email
        }
      }

      if (queue_entry.caller_first_name) {
        if (queue_entry.caller_first_name.length > 0) {
          this.caller_name = queue_entry.caller_first_name
        }
      }
      this.agent = "Any agent"
      if (queue_entry.agent) {
        if (queue_entry.agent.length > 0) {
          this.agent = queue_entry.agent
        }
      }
      if (queue_entry.rep_id) {
        if (queue_entry.rep_id == this.my_id) {
          this.agent = "You"
          this.bShowHangUp = true
          this.bShowPersonal = true;
        }
      }

      if (queue_entry.prev_call) {
        this.new_caller = "Contact"
        // this.bShowPersonal = true;
        this.prev_rep_name = queue_entry.prev_call.rep_name
        this.prev_date = new Date(queue_entry.prev_call.start)
        if (this.caller_name == "n/a") {
          if (queue_entry.prev_call.caller_name) {
            this.caller_name = queue_entry.caller_name
          }
        }
        if (this.caller_name == "n/a") {
          if (queue_entry.prev_call.caller_first_name) {
            this.caller_name = queue_entry.caller_first_name
          }
        }
      }
      this.setDuration()
    } else {
      this.caller_name = ""
      this.agent = ""
    }
  }
  setDuration() {
    if (this.queue_entry) {
      let now = new Date().getTime()
      if (this.queue_entry.start_calling_at) {
        this.waiting = fomatTime(now - this.queue_entry.start_calling_at)
      } else {
        this.waiting = "error"
      }
    }
  }

  answer() {
    this.wss_service.answer(this.queue_entry)
  }
  setAudio($event: boolean) {
    // console.log("Set audio " + $event)
    this.kVideoState.dispatch(new SetAudio($event))
  }
  setVideo($event: boolean) {
    // console.log("Set video " + $event)
    this.kVideoState.dispatch(new SetVideo($event))
  }
  public hangUp() { //we need to send a message to the caller, so it goes into the ups and schedule
    this.wss_service.sendMessage({
      forward: [this.queue_entry.caller_id],
      call_rejected: true
    })
    this.pagesState.dispatch(new RejectedCall(this.queue_entry))
  }
  expand() {
    this.bShowOpts = !this.bShowOpts
  }
  close() {
    this.pagesState.dispatch(new CallWaiting(""))
  }
}
