import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from '../../chapala-mls-service';
import { SetSearch } from '../../chapala-mls.actions';
import { ChapalaMLSState } from '../../chapala-mls.model';
import { gotMLSMsg } from '../../chapala-mls.selectors';
import { SelectorComponent } from './selector.component';

@Component({
  selector: 'mls-categories',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class CategoriesComponent extends SelectorComponent {
  categories: any[] = [
    { id: 13, name: "Land and Lots" },
    {
      id: 14, name: "Commercial"
    }, {
      id: 12, name: "Residential"
    }];
  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);

    this.setTitle("Type")


    this.setOptions(this.categories, "name")
    this.search_by = "category"

    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.select) {
          if (msg.select.hasOwnProperty("Type")) {
            let index = msg.select["Type"]
            let categories_id = this.categories[index].id
            this.chapalaMLState.dispatch(new SetSearch(this.search_by, categories_id))
          }
        }
      }
    }))

  }


}
