import { Directive, ElementRef, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
/*

WARNING://
  IF the NgModel fails to load is because the input field is of type="file"


*/
@Directive({
  selector: '[kzInput]'
})
export class KzInputDirective {

  @Input() kzLib: string = "kz";

  constructor(
    private ngModel: NgModel,
    private el: ElementRef,
  ) {
    console.log("kzLib directive")
  }
  // @HostListener('focus', ['$event.target'])
  // onFocus(target: EventTarget) {
  //   console.log('Input focused!', target);
  //   // Add additional logic here
  // }
  ngAfterViewInit(): void {

    this.el.nativeElement.dataset['kzbtn'] = this.kzLib
    let me = this
    this.el.nativeElement.onKZ = function(event: any) {
      let value = me.el.nativeElement.value
      // console.log("on kz1 value " + value + " ngModel " + me.ngModel.value)
      me.ngModel.control.setValue(value);
      // console.log("on kz2 value " + value + " ngModel " + me.ngModel.value)
    }


  }
}
