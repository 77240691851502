<div class="out">
  <div class="user heading">
    <span class="uname">Name</span>
    <span class="uphone"> Phone</span>
    <span class="uname"> Email</span>
    <span class="actions">
      Set Credentials
    </span>
  </div>
  <div class="users">
    <div class="user" *ngFor="let user of all_users, let i = index ">
      <span *ngIf="user.name" class="uname" [ngClass]="{pending:user.new_user}"> {{user.name}}</span>
      <span *ngIf="!user.name" class="uname pending"> No Name</span>
      <span *ngIf="user.phone" class="uphone" [ngClass]="{pending:user.new_user}"> {{user.phone}}</span>
      <span *ngIf="!user.phone" class="uphone pending"> No phone</span>
      <span class="uname" [ngClass]="{pending:user.new_user}"> {{user.email}}</span>


      <div class="actions">
        <div class="img"  kzLib (click)="setCreds($event, user)">
          <img class="abs_center" src="assets/images/edit_user.svg" alt="">

        </div>
        <span class="red" *ngIf="!user.bHasConverge"> Pending</span>
        <span *ngIf="user.bHasConverge"> Ready</span>
      </div>
      <!-- <k-filter *ngIf="!user.new_user" [right]="true" [height]="35" [radius]="6" class="select" title="Call Links" [options]="call_link_opts" (selected)="getLink($event, i)"></k-filter>
      <span *ngIf="user.new_user" class="select pending">Status Pending</span> -->

    </div>
    <div class="cb_top" *ngIf="edit_user_top" [style.top.px]="edit_user_top" [style.left.px]="edit_user_left">
      <div class="cred_box">
        <div>API User id</div>
        <input kzInput class="in" type="text" placeholder="User id" name="" value="" [(ngModel)]="api_user_id" (ngModelChange)="checkIt()">
        <div>User PIN </div>
        <input kzInput class="in pin" type="text" placeholder="User PIN" name="" value="" [(ngModel)]="user_pin" (ngModelChange)="checkIt()">

      </div>
      <div class="saveIt" [ngClass]="{ready:bReady}"  kzLib (click)="saveIt()">
        <div class="abs_center">
          Save
        </div>
      </div>
      <img src="assets/images/close.svg" class="close"  kzLib (click)="closeIt()">
    </div>

  </div>

</div>
