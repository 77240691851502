/*
(C)2020 Sergio A. Fernandez, Ph.D.
*/


export interface CanvasState {
  saved_svgs: any, //One for every asset during the call
  signer_name: string | undefined
  signer_email: string | undefined
  signer_roll: string
  // signer_key: string | undefined
  // missing_emails_for: string[]
  contract_executed: boolean
  contract_signed: boolean
  ready_to_block_chain: boolean
  select_tool_opt: any
  fill_color: string,
  stroke_color: string,
  text_color: string,
  pencil_width: number,

  text_font: string,
  text_weight: string,
  text_size: string,

  field_text_font: string,
  field_text_weight: string,
  field_text_size: string,

  shape_options: any[]
  sel_shape: number

  tools: any
  selected_tool: string //WE USE THE NAME, because we might need to selected in the canvas on mouse down
  selected_input: string
  save_svg: any
  save_svg_counter: number //to make sure we get it every timer

  //PDF Data
  sec_opts: any
  pdf_svg: any
  pdf_svg_blob: any

  bIsInitial: boolean, //used to reset the select initial or signature
  sel_signer_key: string | undefined

  signers: any //SetSigners is dispatched by asset-header,when it gets the asset or when it modifieds the signers
  signers_with_steps: any,
  //the signers are persisted by the tool_service when the user hits save or send email
  signers_emails: any
  signers_steps: any

  last_signer: boolean
  copy_to: any[]
  changed_svg: number
  signer_steps: any
  did_step_id: string
  progress: any
  go_to_step: any
  signer_pic: string | undefined
  signer_lic: any | undefined

  caller_pic: string | undefined
  caller_lic: any | undefined
  caller_results: any
  bio_results: any | undefined
  bio_results_error: any | undefined

  last_signed_contract: any


  get_signature: string | undefined
  get_initials: string | undefined
  signature_data: any | undefined
  initials_data: any | undefined
  undo_seq: number
  edit_comment: any
  show_comment: any

  add_to_undo: any
  undo_stack_size: number,
  selected_id: any
  download: number,
  add_to_chat: number,
  canvas_opts: any,
  // num_sig_and_initials: number
  resized_canvas: number,
  show_draw_tools: boolean
  kz_code: string | undefined
}

export const initialCanvasState: CanvasState = {
  saved_svgs: {},
  signer_name: undefined,
  signer_email: undefined,
  signer_roll: "",
  // signer_key: undefined,
  // missing_emails_for: [],
  contract_executed: false,
  contract_signed: false, //ture when the user signed the contract
  ready_to_block_chain: false,
  select_tool_opt: {},
  fill_color: "rgba(0,75,255,0.53)",
  stroke_color: "black",
  text_color: "rgba(0,0,0,0.53)",
  pencil_width: 10,

  text_font: "OpenSans",
  text_weight: "Regular",
  text_size: "18px",

  field_text_font: "OpenSans",
  field_text_weight: "Regular",
  field_text_size: "18px",

  shape_options: [
    { desc: "Rectangle", svg: "rectangle.svg", tool: "rectangle" },
    { desc: "Eclipse", svg: "eclipse.svg", tool: "eclipse" },
    { desc: "Poly fill", svg: "polygon.svg", tool: "polyfill" },
    { desc: "Poly line ", svg: "polygon.svg", tool: "polygon" },
    { desc: "Line", svg: "line.svg", tool: "line" },
    { desc: "Arrow", svg: "arrow.svg", tool: "arrow" },
  ],
  sel_shape: -1,

  tools: {
    /*
    auto:true, when the user click in the tool the tool is picked upp for one time
    for example to sign, initial or add text
    */
    Sign: { cursor: "drop_sign.svg", x: 0, y: 12 },
    Initial: { cursor: "initials.svg", x: 0, y: 12 },
    Text: { cursor: "text.svg", x: 0, y: 12 },
    Date: { cursor: "calendar.svg", x: 0, y: 14 },
    Email: { cursor: "email.svg", x: 0, y: 15 },
    Name: { cursor: "name.svg", x: 2, y: 16 },
    erase: { cursor: "eraser.svg", x: 0, y: 14 },
    move: { cursor: "cursor.svg" },
    pencil: { cursor: "pencil.svg", x: 0, y: 14 },

    sign: { cursor: "sign.svg", auto: true },
    initial: { cursor: "sign.svg", auto: true },
    text: { cursor: "text.svg", auto: true },
    comment: { cursor: "comment.svg", auto: true },

    rectangle: { cursor: "rectangle.svg" },
    eclipse: { cursor: "eclipse.svg" },
    polygon: { cursor: "polygon.svg" },
    line: { cursor: "line.svg" },
    arrow: { cursor: "arrow.svg" },
  },

  selected_tool: "none",
  selected_input: "",
  save_svg: {},
  save_svg_counter: 0,




  //PDF Data
  sec_opts: {},
  pdf_svg: undefined,
  pdf_svg_blob: undefined,

  bIsInitial: false,
  sel_signer_key: undefined,
  // sel_signer_key: "buyer_k",
  signers_with_steps: {},
  signers: {
    // "seller_k": { title: "Seller", name: "seller", email: "sergio@asci.us", color: "#8F5CFF" },
    // "buyer_k": { title: "Buyer", name: "buyer", email: "sergio@venditio.us", color: "#FFBA55" },
    // "manager": { title: "Manager", name: "", email: "", color: "#DAE1F7" },
    // "buyer_k1": { title: "buyer_k1", name: "buyer1", email: "sergio@venditiso.us", color: "#FFBA55" },
    // "seller_k1": { title: "Seller1", name: "seller1", email: "sergio@asci.sus", color: "#8F5CFF" },
    // "buyer_k2": { title: "buyer_k1", name: "buyer1", email: "sergio@venditiso.us", color: "#FFBA55" },
    // "seller_k2": { title: "Seller1", name: "seller1", email: "sergio@asci.sus", color: "#8F5CFF" },
    // "buyer_k3": { title: "buyer_k1", name: "buyer1", email: "sergio@venditiso.us", color: "#FFBA55" },
    // "seller_k3": { title: "Seller1", name: "seller1", email: "sergio@asci.sus", color: "#8F5CFF" },
    //
    // "buyer_k11": { title: "buyer_k1", name: "buyer1", email: "sergio@venditiso.us", color: "#FFBA55" },
    // "seller_k11": { title: "Seller1", name: "seller1", email: "sergio@asci.sus", color: "#8F5CFF" },
    // "buyer_k12": { title: "buyer_k1", name: "buyer1", email: "sergio@venditiso.us", color: "#FFBA55" },
    // "seller_1k2": { title: "Seller1", name: "seller1", email: "sergio@asci.sus", color: "#8F5CFF" },
    // "buyer_k13": { title: "buyer_k1", name: "buyer1", email: "sergio@venditiso.us", color: "#FFBA55" },
    // "seller_1k3": { title: "Seller1", name: "seller1", email: "sergio@asci.sus", color: "#8F5CFF" },
    //
    // "buyer_2111": { title: "buyer_k1", name: "buyer1", email: "sergio@venditiso.us", color: "#FFBA55" },
    // "seller_k211": { title: "Seller1", name: "seller1", email: "sergio@asci.sus", color: "#8F5CFF" },
    // "buyer_k212": { title: "buyer_k1", name: "buyer1", email: "sergio@venditiso.us", color: "#FFBA55" },
    // "seller_21k2": { title: "Seller1", name: "seller1", email: "sergio@asci.sus", color: "#8F5CFF" },
    // "buyer_k213": { title: "buyer_k1", name: "buyer1", email: "sergio@venditiso.us", color: "#FFBA55" },
    // "seller_21k3": { title: "Seller1", name: "seller1", email: "sergio@asci.sus", color: "#8F5CFF" },
    // "manager": { title: "Manager", name: "", email: "", color: "#DAE1F7" }

  },
  signers_emails: {},
  signers_steps: {},
  copy_to: [],
  last_signer: false,
  changed_svg: 0,
  signer_steps: {},
  did_step_id: "",
  progress: {},
  go_to_step: {},
  signer_pic: undefined,
  signer_lic: undefined,
  caller_pic: undefined,
  caller_lic: undefined,
  caller_results: undefined,
  bio_results: undefined,
  bio_results_error: undefined,
  last_signed_contract: undefined,

  get_signature: undefined,
  get_initials: undefined,
  signature_data: undefined,
  initials_data: undefined,
  undo_seq: 0,
  edit_comment: undefined,
  show_comment: undefined,
  add_to_undo: undefined,
  undo_stack_size: 0,
  selected_id: undefined,
  download: 0,
  add_to_chat: 0,
  canvas_opts: { bDownload: true, bDraw: true },
  resized_canvas: 0,
  show_draw_tools: false,
  kz_code: undefined,
  // num_sig_and_initials: 0
};
