<div class="rec"  kzLib (click)="togggleRec()">
  <img class="rec_b" *ngIf="rec_status=='ready' || rec_status=='starting'" src="assets/images/rec.svg" alt="">
  <div class="round" *ngIf="rec_status=='recording'">
    <div class="abs_center rbox"></div>
  </div>
  <div class="rtxt upl" *ngIf="rec_status=='stopping'">
    Uploading ...
  </div>
  <!-- <div *ngIf="rec_status=='ready'" class="rtxt">
    Record
  </div> -->
  <!-- <img class="round" *ngIf="rec_status=='ready'" src="assets/images/stop_rec.svg" alt=""> -->
  <div *ngIf="rec_status=='recording'" class="rtxt">{{duration}} </div>

</div>
