import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { kPayState } from '../kpay.model';
import { gotInvoice, kPayError, payBarStep, retryKPay, runKPay, showKScreen } from '../kpay.selectors';

@Component({
  selector: 'kpay-invoice-bar',
  templateUrl: './kpay-invoice-bar.component.html',
  styleUrls: ['../kpay-bar/kpay-bar.component.scss', './kpay-invoice-bar.component.scss']
})
export class KpayInvoiceBarComponent {
  state: string = "invoice" //"acc" "invoice" "plan" //"bill" //
  amount: string = ""
  payment_lbl: string = "0.00"
  subscriptions: any[] = []

  bSpinInvoice: boolean = false
  inv_progress: number = 0
  bInvCheck: boolean = false

  bBillingSpin: boolean = false
  billing_progress: number = 0
  bBillingCheck: boolean = false

  bPaymentSpin: boolean = false
  payment_progress: number = 0
  bPaymentCheck: boolean = false
  bPaymentError: boolean = false
  constructor(
    public kpayState: Store<kPayState>,
  ) {
    console.log("kpay-bar")

  }
  ngAfterViewInit() {

    this.subscriptions.push(this.kpayState.select(retryKPay).subscribe((retry: any) => {
      if (retry) {
        console.log("retry ")
        this.setUI("inv_ok")
      }
    }))
    this.subscriptions.push(this.kpayState.select(payBarStep).subscribe((state: any) => {
      setTimeout(() => {
        this.setUI(state)
      })
    }))

    this.subscriptions.push(this.kpayState.select(gotInvoice).subscribe((invoice: any) => {
      if (invoice) {
        this.amount = invoice.amount
      }
    }))
    this.subscriptions.push(this.kpayState.select(kPayError).subscribe((num: number) => {
      if (num) {
        this.bPaymentError = true
      } else {
        this.bPaymentError = false
      }
    }))
    this.kpayState.select(showKScreen).subscribe((bShowKScreen: boolean): void => {
      if (!bShowKScreen) {
        this.bPaymentError = false
      }
    })
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  setUI(state: string) {
    console.log("state " + state)
    if (state == "invoice") {
      this.state = state

      this.bSpinInvoice = true
      this.inv_progress = 50
      this.bInvCheck = false

      this.bBillingSpin = false
      this.billing_progress = 0
      this.bBillingCheck = false

      this.bPaymentSpin = false
      this.payment_progress = 0
      this.bPaymentCheck = false

    } else if (state == "inv_ok") {
      this.state = state

      this.bSpinInvoice = false
      this.inv_progress = 100
      this.bInvCheck = true

      this.bBillingSpin = true
      this.billing_progress = 0
      this.bBillingCheck = false

      this.bPaymentSpin = false
      this.payment_progress = 0
      this.bPaymentCheck = false

    } else if (state == "got_cc") {
      this.state = state

      this.bSpinInvoice = false
      this.inv_progress = 100
      this.bInvCheck = true

      this.bBillingSpin = false
      this.billing_progress = 70
      this.bBillingCheck = true

      this.bPaymentSpin = true
      this.payment_progress = 0
      this.bPaymentCheck = false


    }



    // if (this.state == "done") {
    //   this.payment_lbl = "Payment for " + this.amount + " received"
    // }

  }
}
