<div class="s_out" #outComponent *ngIf="bRemoteIsSharing">
  <video [muted]="true" #videoElement class="container" [style.height.px]="container_height" [style.top.px]="container_top" [style.width.px]="container_width" [style.left.px]="container_left" autoPlay autoplay playsinline>
  </video>

  <div class="container canvas" #divContainer [style.height.px]="canvas_height" [style.top.px]="canvas_top" [style.width.px]="canvas_width" [style.left.px]="canvas_left">
    <!-- MAKE SURE NOT TO PUT ANY THING INSIDE -->
  </div>

  <div *ngIf="bShowWall " class="wall" [style.left.px]="wall_l" [style.top.px]="wall_t" [style.width.px]="wall_w" [style.height.px]="wall_h">
    <!-- {{wall_l}} {{wall_t}} {{wall_w}} {{wall_h}} -->
    <!-- {{canvas_left}} {{canvas_top}} {{canvas_width}} {{canvas_height}} -->
  </div>

  <div *ngIf="bShowWall " class="wall_t" [style.left.px]="twall_l" [style.top.px]="twall_t" [style.width.px]="twall_w" [style.height.px]="wall_h">
    <!-- {{twall_l}} {{twall_t}} {{twall_w}} {{twall_h}} -->

  </div>
  <!-- <div class="test" [style.left.px]="viewport_left" [style.top.px]="viewport_top" [style.width.px]="viewport_width" [style.height.px]="viewport_height">

  </div> -->
</div>

<div class="s_out" #outComponent *ngIf="!bRemoteIsSharing">
  <iframe [src]="sanitazed_url" #iframeElement class="container">
  </iframe>
  <div class="container canvas" [ngClass]="{drawing:sel_tool_name=='none'}" #divContainer>
  </div>

</div>
