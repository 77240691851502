import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mls-sel1to5',
  templateUrl: './sel1to5.component.html',
  styleUrls: ['./sel1to5.component.scss']
})
export class Sel1to5Component {
  border: string | undefined;
  btncolor: string | undefined;
  values: string[] = []
  @Input() set max_val(n: number) {
    this.setVals(5)
  }
  @Output() changed: EventEmitter<string> = new EventEmitter();
  constructor(
  ) {
    // this.accountStore.select(btnColor).subscribe((color: string) => {
    //   this.btncolor = color;
    //   this.border = "1px solid " + color;
    // })
    this.setVals(5)
  }
  setVals(max_val: number) {
    this.values = []
    for (let i = 1; i <= max_val; i++) {
      this.values.push(i.toString())
    }
  }
  clicked(val: string) {
    this.changed.emit(val);
  }
}
