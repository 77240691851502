import { Component } from '@angular/core';
import { ShowCC } from 'src/app/pages/pages.actions';
import { CreditCardComponent } from '../credit-card/credit-card.component';

@Component({
  selector: 'downgrade',
  templateUrl: './downgrade.component.html',
  styleUrls: ['../billing.component.scss', '../credit-card/credit-card.component.scss', './downgrade.component.scss']
})
export class DowngradeComponent extends CreditCardComponent {
  downgradeIt() {
    this.pagesState.dispatch(new ShowCC("w_down"))

  }
  deleteIt() {
    this.pagesState.dispatch(new ShowCC("w_del"))

  }
  cancel() {
    this.pagesState.dispatch(new ShowCC(undefined))
  }
}
