<div class="out">
  <div class="hdr" *ngIf="signers_keys.length>0">
    Please review all signers details below.
  </div>
  <div class="signers" [style.max-height.px]="max_height">
    <div class="signer" *ngFor="let key of signers_keys;let i = index" [ngClass]="{mb15: i+1 < signers_keys.length}">
      <span class="s_title">
        <!-- {{signers[key].title}} -->
        <input kzInput class="input ih30 s_title" [style.color]="signers[key].color" [ngClass]="{in_border:signers[key].title.length==0}" type="text" placeholder="Title" name="" value="" [ngModel]="signers[key].title" (ngModelChange)="changedTitle($event ,key )">
      </span>
      <span class="s_name">
        <!-- {{signers[key].name}} -->
        <input kzInput class="input ih30 s_name" [ngClass]="{in_border:noName(key)}" type="text" placeholder="Name" name="" value="" [ngModel]="signers[key].name" (ngModelChange)="changedName($event ,key )">
      </span>
      <span class="s_email">
        <input kzInput class="input ih30" [ngClass]="{in_border:noEmail(key)}" type="email" placeholder="Email" name="" value="" [ngModel]="signers[key].email" (ngModelChange)="changedEmail($event ,key )" [ngClass]="{invalid:invaild_emails[key]}">
      </span>

    </div>

  </div>
  <!-- <div class="hdr">
    {{copy_lbl}}
  </div> -->
  <!-- <div class="btm ul"  kzLib (click)="newContact()">Add contact</div>
  <div class="signers contacts">
    <div class="signer" *ngFor="let copy of copy_to; let i = index">
      <input kzInput class="input ih30" [ngClass]="{in_border:copy.name.length==0}" type="text" placeholder="Name" name="" value="" [(ngModel)]="copy.name" (blur)="onDoneWithName($event,i )">
      <input kzInput class="input ih30" [ngClass]="{in_border:copy.email.length==0}" type="email" placeholder="Email" name="" value="" [(ngModel)]="copy.email" (ngModelChange)="changedCopyEmail($event ,i )" [ngClass]="{invalid:invaild_emails[i]}" (blur)="onDoneWithEmail($event,i )">
    </div>
  </div> -->

  <k-button class="email_cont" [background]="send_back_color" color="white" text="Send" kzLib (click)="emailContract()"></k-button>
  <k-button class="cancel" icon="close.svg" background="transparent" kzLib (click)="cancel()"></k-button>


</div>
