import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SVG, extend as SVGextend, Element as SVGElement, G } from '@svgdotjs/svg.js'


@Component({
  selector: 'sign-pad',
  templateUrl: './sign-pad.component.html',
  styleUrls: ['./sign-pad.component.scss']
})
export class SignPadComponent {
  @ViewChild('outComponent') outComponent: ElementRef | undefined;
  _svg: string = "";
  @Input() enabled: boolean = true
  @Input() set clear(s: number) {
    if (this.draw_tool) {
      this.draw_tool.clear()
    }
  }
  @Input() set svg(svg: string) {
    if (this.draw_tool) {
      this.draw_tool.clear()
      this.draw_tool.svg(svg)
    } else {
      this._svg = svg //Draw it after initializing the tool
    }

  }
  @Output() svgChange: EventEmitter<any> = new EventEmitter();

  constructor() {
    console.log("sign pad")
  }
  draw_tool: any

  drawing_poly: any | undefined
  polygon_id: string = ""
  poly_array: number[] = []
  startX: number = 0
  startY: number = 0

  move_index: number = -1
  pointer_id: any //to capture the mouse

  ngAfterViewInit() {
    // console.log("sign-pad")
    if (this.outComponent) {
      let canvas = this.outComponent.nativeElement

      this.draw_tool = SVG().addTo(canvas)
      let svg = this.draw_tool.node

      if (svg) {        //This is to keep the aspect ratio in an image
        svg.style.width = "100%"
        svg.style.height = "100%"
      }
      canvas.addEventListener('pointerdown', onMouseDown, false);
      canvas.addEventListener('touchstart', onMouseDown, false);
      canvas.addEventListener('pointermove', onMouseMove, false);
      canvas.addEventListener('touchmove', onMouseMove, false);
      canvas.addEventListener('pointerup', onMouseUp, false);
      canvas.addEventListener('touchend', onMouseUp, false);
      if (this._svg) {
        this.draw_tool.svg(this._svg)
      }
    }
    let me = this
    function onMouseDown($event: any) {
      $event.preventDefault()
      if (!me.enabled) {
        return
      }
      let x = $event.offsetX
      let y = $event.offsetY
      if (!x) {
        x = $event.layerX
        y = $event.layerY
      }
      if (!me.drawing_poly) {
        me.pointer_id = $event.pointerId
        if (me.outComponent) {
          me.outComponent.nativeElement.setPointerCapture(me.pointer_id)
        }

        console.log("Start scketch")
        me.startX = x;
        me.startY = y
        me.poly_array = [me.startX, me.startY, x, y]
        me.drawing_poly = me.draw_tool.polyline(me.poly_array).fill('none').stroke({ width: 3, color: 'black' })
      }
    }
    function onMouseMove($event: any) {
      $event.preventDefault()
      let x = $event.offsetX
      let y = $event.offsetY
      if (!x) {
        x = $event.layerX
        y = $event.layerY
      }
      if (me.drawing_poly) {
        let dx = me.startX - x
        let dy = me.startY - y
        console.log("draw scketch " + dx + " " + dy)

        let d = Math.sqrt(dx * dx + dy * dy)
        if (d > 10) {
          me.poly_array.push(x)
          me.poly_array.push(y)
          me.drawing_poly.attr({ points: me.poly_array })
        }
      }
    }
    function onMouseUp(_$event: any) {
      // console.log("donw scketch " )
      if (me.pointer_id && me.outComponent) {
        me.outComponent.nativeElement.releasePointerCapture(me.pointer_id)
        me.pointer_id = undefined
      }
      if (me.drawing_poly) {
        me.doneWithPoly()
      }
    }
  }
  doneWithPoly() {
    this.polygon_id = this.drawing_poly.node.dataset['id']
    if (!this.polygon_id) {
      this.polygon_id = new Date().getTime().toString()
      // console.log("poly new  " + this.polygon_id)
      this.drawing_poly.node.dataset['id'] = this.polygon_id
      this.drawing_poly.node.dataset['k_type'] = "pencil"
    }
    // else {
    // console.log("poly svg  " + this.polygon_id)
    // }

    this.drawing_poly = undefined
    let svg = this.draw_tool.svg(true)
    this.svgChange.emit({ svg: svg })
  }

}
