import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { CalendarState } from '../../calendar.model';
import { changedHourSlots, changedSlots } from '../../calendar.selectors';
import { CalendarService } from '../../calendar.service';
import { CalendarComponent } from '../calendar.component';
import { formatDate } from '@angular/common';
import { AddAppointment } from '../../calendar.actions';

@Component({
  selector: 'app-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss']
})
export class DayComponent extends CalendarComponent {
  slots: any[] | undefined;
  inhour_slots: any[] | undefined
  _date: Date = new Date()
  // @Input() set date(d: Date) {
  //   this._date = d;
  // }

  @Output() timeChange: EventEmitter<any> = new EventEmitter();

  ngOnInit() {

    console.log("day")
    this.subscriptions.push(this.calendarStore.select(changedSlots).subscribe((slots: any[]) => {
      if (slots) {
        this.slots = slots
      }
    }))
    this.subscriptions.push(this.calendarStore.select(changedHourSlots).subscribe((hour_slots: any[]) => {
      this.inhour_slots = hour_slots
      this.setHours()
    }))
  }

  setHours() {
    if (this.inhour_slots && this.slots) {
      this.hour_slots = []
      let slots_per_hour = this.slots.length / this.inhour_slots.length;
      let slots_index = 0
      for (let i = 0; i < this.inhour_slots.length; i++) {
        let hs = Object.assign({}, this.inhour_slots[i])
        hs.stime = formatDate(hs.time, "shortTime", navigator.language, "-" + this.time_zone)
        console.log("#TI " + new Date(hs.time).toUTCString() + " -> " + hs.stime)
        console.log(new Date(hs.time).toLocaleString())
        hs.slots = []
        for (let s = 0; s < slots_per_hour; s++) {
          hs.slots.push(this.slots[slots_index++])
        }
        this.hour_slots.push(hs)
      }
    }
  }
  addAppointment(slot: any) {
    this.timeChange.emit(slot)
    this.calendarStore.dispatch(new AddAppointment(slot))
  }
}
