import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts, SelShape } from '../../canvas.actions';
import { selShape, shapeOptions } from '../../canvas.selectors';

@Component({
  selector: 'sel-shape-opts',
  templateUrl: './sel-shape.component.html',
  styleUrls: ['./sel-shape.component.scss']
})
export class SelShapeComponent {
  sel_shape: number = 0
  shape_options: any[] = []
  subscriptions: any[] = []

  constructor(
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("sel-shape")
    this.subscriptions.push(this.canvasStore.select(shapeOptions).subscribe((shape_options: any) => {
      this.shape_options = []
      for (let i = 0; i < shape_options.length; i++) {
        let opt = Object.assign({}, shape_options[i])
        opt.img = "assets/images/" + opt.svg
        this.shape_options.push(opt)
      }
    }))
    this.subscriptions.push(this.canvasStore.select(selShape).subscribe((sel_shape: number) => {
      this.sel_shape = sel_shape
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  getOpt(options: any) {
    for (let i = 0; i < options.length; i++) {
      let opt = options[i]
      if (opt.selected) {
        return opt
      }
    }
    options
  }
  selectedShape($event: number) {
    this.sel_shape = $event
    this.canvasStore.dispatch(new SelShape(this.sel_shape))
  }
}
