/*
Copyright(c) 2022 Sergio A. Fernandez, Ph.D.
*/

import { ControlsAction, ControlsActionTypes } from "./controls.actions";
import { ControlsState, initialControlsState } from "./controls.model";


// let streams_cache: any = {}
export function controlsReducer(state = initialControlsState,
  action: ControlsAction): ControlsState {
  switch (action.type) {
    case ControlsActionTypes.LinkOption: {
      const ControlsState: ControlsState = Object.assign({}, state);
      ControlsState.link_option = action.num
      return ControlsState;
    }
    // case ControlsActionTypes.FeedBack: {
    //   const ControlsState: ControlsState = Object.assign({}, state);
    //   ControlsState.feed_back = { text: action.feed_back, msg_type: action.msg_type }
    //   return ControlsState;
    // }
    case ControlsActionTypes.SetBtnBackColor: {
      const ControlsState: ControlsState = Object.assign({}, state);
      ControlsState.btn_back_color = action.color
      return ControlsState;
    }

    case ControlsActionTypes.SetBtnTextColor: {
      const ControlsState: ControlsState = Object.assign({}, state);
      ControlsState.btn_text_color = action.color
      return ControlsState;
    }
    default: {
      return state;
    }
  }
}
