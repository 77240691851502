<div class="out">

  <div class="content">

    <iframe class="abs_center frame" [src]="url">
    </iframe>
  </div>
  <div class="btm">
    <k-button class="cancel_b" icon="close.svg" background="#ffffffa0"  kzLib (click)="cancel()"></k-button>

    <div class="abs_center again">
      <k-check class="check" [checked]="bDontShow" (checkedChange)="checkIt($event)"></k-check>
      <div class="again_tx">
        Do not show again
      </div>
    </div>
  </div>
</div>
