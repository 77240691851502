<div class="out">

  <div class="line">
    Live Picture
    <div class="check" [ngClass]="{selected:take_pic}"  kzLib (click)="takePic()"></div>
  </div>
  <div class="line">
    ID Verification
    <div class="check" [ngClass]="{selected:driver_lic}"  kzLib (click)="dirverLic()"></div>
  </div>



  <!-- <div class="line">
    Blockchain Verification
    <div class="check" [ngClass]="{selected:bc_once}"  kzLib (click)="blockChain()"></div>
  </div> -->

</div>
