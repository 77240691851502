import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mls-small-property',
  templateUrl: './small-property.component.html',
  styleUrls: ['./small-property.component.scss']
})
export class SmallPropertyComponent {
  _property: any = {}
  @Input() set property(p: any) {
    this._property = p
    let url = p.url_photo + "/" + p.photos[0].image
    this.url_photo = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // console.log("url " + url)
  };
  url_photo: any
  subscriptions: any[] = []
  constructor(
    public sanitizer: DomSanitizer,
  ) {
    console.log("mls-search");
  }
}
