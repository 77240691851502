import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as kVideoReducer from './k-video.reducer';
import { StoreModule } from '@ngrx/store';
import { KvideoComponent } from './kvideo/kvideo.component';

import { ControlsModule } from '../controls/controls.module';
import { SelMediaComponent } from './sel-media/sel-media.component';


@NgModule({
  declarations: [
    KvideoComponent,
    SelMediaComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature('k-video', kVideoReducer.kVideoReducer),
    ControlsModule
  ], exports: [
    KvideoComponent,
    SelMediaComponent
  ],
})
export class KVideoModule { }
