<div class="out"  kzLib (click)="check()">



  <div class="check" [ngClass]="{selected:bChecked}"></div>
  <div class="text">
    {{text}}
  </div>

</div>
