import { Component, EventEmitter, Output } from '@angular/core';
import { NotifyError, NotifyUser, ShowCC } from 'src/app/pages/pages.actions';
import { BillingComponent } from '../billing.component';
import { CreditCardComponent } from '../credit-card/credit-card.component';

@Component({
  selector: 'bill-info',
  templateUrl: './bill-info.component.html',
  styleUrls: ['../credit-card/credit-card.component.scss', './bill-info.component.scss']
})
export class BillInfoComponent extends CreditCardComponent {
  closeIt() {
    this.pagesState.dispatch(new ShowCC(""))
  }
  changed() {
    if (
      this.bInvalidEmail
      || this.cia_name.length < 1
      || this.cc_address.length < 1

      || this.cc_zip.length < 1
      || this.cc_state.length < 1
    ) {
      this.bReady = false
    } else {
      this.bReady = true
    }
  }

}
