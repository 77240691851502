import { Component, EventEmitter, Input, Output, } from '@angular/core';


@Component({
  selector: 'k-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {

  @Input() openFilter: boolean = false
  @Output() openFilterChange: EventEmitter<boolean> = new EventEmitter();

  @Input() exterior: boolean = false

  bShowRight: boolean = false;
  @Input() right: boolean = false
  @Input() height: number = 38
  @Input() button: string | undefined
  _options: any[] = []
  @Input() set str_options(options: string[]) {
    this._options = []
    options.forEach((opt: string) => {
      this._options.push({ desc: opt })
    })
  }
  _sel_index: number = -1
  @Input() set sel_index(index: number) {
    this._sel_index = index;
  }
  @Input() radius: number = 22;
  @Input() show_del: boolean = false;
  @Input() set options(options: any[]) {
    this._options = options
    if (options) {
      for (let j = 0; j < options.length; j++) {
        let opt = options[j]
        if (opt.selected) {
          if (opt.desc == "All") {
            this.title = "Filter Assets"
          } else {
            this.title = opt.desc
          }
          this._sel_index = j
          if (opt.img) {
            this.src_img = opt.img
            return
          }
        }
      }
      if (options.length > 0) {
        this.src_img = options[0].img
      }
    }
  }
  @Input() set min_width(n: number) {
    this._min_width = n
    this.min_drop_width = n + 7
  }
  _min_width: number = 0;

  @Input() min_opts_width: number = 120
  min_drop_width: number = 0;
  @Input() border: boolean = true
  @Input() single: boolean = false
  @Input() title: string = "title"
  @Input() state: string = "open"
  // private resizeObserver: ResizeObserver | undefined;
  // private readonly observedElement = document.body;


  @Output() selected: EventEmitter<any[]> = new EventEmitter();
  @Output() delete: EventEmitter<any[]> = new EventEmitter();

  bMouseIn: boolean = false
  @Input() background: string = "#fff"
  constructor() {
    console.log("filter")
  }

  src_img: string | undefined
  showOptions() {
    if (this.single) {
      let options: any[] = Object.assign([], this._options)
      for (let i = 0; i < options.length; i++) {
        let option: any = Object.assign({}, options[i])
        option.selected = (i == this._sel_index)
        options[i] = option
      }
      this._options = options
    }
    this.openFilter = !this.openFilter
    this.openFilterChange.emit(this.openFilter)
  }
  leave() {
    this.bMouseIn = false
    setTimeout(() => {
      this.openFilter = !this.openFilter
      this.openFilterChange.emit(this.openFilter)
    }, 300)
  }
  enter() {
    console.log("Enter")
    this.bMouseIn = true
  }

  selOption(i: number) {
    console.log("sel opt " + i)
    let options: any[] = Object.assign([], this._options);
    let num_options = options.length
    let option: any = Object.assign({}, options[i])

    if (i == 0 && option.desc == "All") {
      option.selected = true
      options[0] = option
      for (let j = 1; j < num_options; j++) {
        option = Object.assign({}, options[j])
        option.selected = !options[0].selected
        options[j] = option
      }
      this._options = options
      this.title = "Filter Assets"

    } else {

      option.selected = !option.selected;
      this.title = option.desc
      options[i] = option
      this.src_img = option.img
      if (options[0].desc == "All") {
        let num_sel = 0
        for (let j = 1; j < num_options; j++) {
          if (options[j].selected) {
            num_sel++
          }
        }
        if (num_sel == 0) {
          options[0].selected = true
          this.title = "Filter Assets"

        } else if (num_sel > 0) {
          option = Object.assign({}, options[0])
          option.selected = false
          options[0] = option
        }
        if (num_sel > 1) {
          for (let j = 1; j < num_options; j++) {
            if (options[j].selected) {
              this.title = options[j].desc + "..."
              break
            }
          }
        }
      }
    }
    if (this.single && option.selected) {
      for (let j = 0; j < num_options; j++) {
        if (j != i) {
          option = Object.assign({}, options[j])
          option.selected = false
          options[j] = option
        }
      }
      // }
    }

    this._options = options


    this.selected.emit(this._options)
  }
  del() {
    this.delete.emit()
  }
}
