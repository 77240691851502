import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { selectToolOptions } from 'src/app/canvas/canvas.selectors';
import { AddAppointment } from 'src/app/pages/scheduling/calendar.actions';
import { CalendarState } from 'src/app/pages/scheduling/calendar.model';

@Component({
  selector: 'schedule-call',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent {
  bShowOptions: boolean = false;
  subscriptions: any[] = []

  constructor(
    public calendarStore: Store<CalendarState>,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("shedule")
    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      this.bShowOptions = (opt.name == 'event')
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  schedule($event: any) {
    if (!this.bShowOptions) {//btn_tx == "New") {
      var viewportOffset = $event.srcElement.getBoundingClientRect();

      this.calendarStore.dispatch(new AddAppointment({}))
      this.canvasStore.dispatch(new SelectToolOpts("event", { x: viewportOffset.left }))
    } else {
      this.calendarStore.dispatch(new AddAppointment(undefined))
      this.canvasStore.dispatch(new SelectToolOpts(""))
    }

  }
}
