import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddTrack, RemoteTracksIds } from '../k-video/k-video.actions';
import { KVideoState } from '../k-video/k-video.model';
import { mediaState, streams } from '../k-video/k-video.selectors';
import { WSSState } from '../wss/wss.model';
import { callerId, callId, lastMsg, managerId, repId, myId, leftCall } from '../wss/wss.selectors';
import { WSSService } from '../wss/wss.service';


//IF THIS IS COMENTED, the stream_id and msg_from should ge caller, also comment
//IF NOT stream_id should be me and msg_from rep
import { Router, Event, NavigationEnd } from '@angular/router';

/*
  state="waiting" --> send_offer --> got_answer --> connected
                  -->got_offer --> connected

  when there is a change new video or somthing like that
      --> send_local_description, the recipient will set the remote desc and answer with its onw
      peer conections are deleted  whe there is a ung up

*/
@Injectable({
  providedIn: 'root'
})
export class WRTCService {
  stream_id = "me" //identifies the stream, me for the rep, caller for the caller
  msg_from: string = "rep" //dentifies the peer_connection that we send messages to
  //the caller sends messages rep or manager
  //the rep sends messages with the caller or manager
  //the manager sends messages with the caller or rep
  //the roll changes when we are in the manager window

  ice_servers = {
    iceServers: [
      {
        urls: "turn:coturn.venditio.us:443?transport=tcp",
        username: "venditio",
        credential: "A24367"
      },
      // { urls: "stun:stun1.venditio.us:3478" },
      // { urls: "stun:21.venditio.us:3478" },
      { urls: "stun:stun.l.google.com:19302" },
      { urls: "stun:stun2.l.google.com:19305" }
    ]
  };
  peerConnection: any = {};

  my_id: string | undefined
  call_id: string | undefined
  caller_id: string | undefined
  rep_id: string | undefined
  manager_id: string | undefined

  streams: any = {}

  caller_will_initiate: number = 0
  manager_will_initiate: number = 0

  // bCallerWillInitiate: boolean = true //We set it to true if the caller has the video on
  /**                                 and to false once we receive the offer */
  // bManagerWillInitiate: boolean = true

  //Nov 19 ,2023
  got_offer_on: number = 0
  sent_offer_on: number = 0

  media_state: any = {}
  constructor(
    private wssState: Store<WSSState>,
    private kVideoState: Store<KVideoState>,
    private wss_service: WSSService,
    private router: Router     //COMENT THIS FOR THE CALLER
  ) {
    console.log("wrtc constructor")
    //COMENT THIS FOR THE CALLER
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // console.log('wrtc route nav end detected ' + event.url + " call_id " + this.call_id);
        if (!this.call_id) {
          if (event.url == "/manage") {
            this.msg_from = "manager"
          }
          if (event.url == "/call") {
            this.msg_from = "rep"
          }
        }
      }
    })


    this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      this.my_id = my_id
      if (this.my_id) {
        if (this.msg_from == "caller") {
          console.error("if you have my_id, you are a rep or a manager, not a caller")
        }
        if (this.stream_id != "me") {
          console.error("stream_id me, not the caller")
        }
      }
    })
    //End of COMENT THIS FOR THE CALLER

    //This message is sent in response to call_id or in every change by k-video.service

    //**************************************************************************
    //Common code
    //**************************************************************************
    //THIS IS THE ONLY WAY WE MIGHT INITIATE A CONNECTION
    this.kVideoState.select(streams).subscribe((streams: any) => {
      this.streams = streams
      //Sep 2023
      // console.log("wrtc k-video  stream for " + JSON.stringify(Object.keys(streams)))
      // if ((streams[this.stream_id] || streams['local_share']) && this.call_id)
      console.log("wrtc23 call_fn streams changed, checkConnectionTracks");
      this.checkConnectionTracks(false) //streams
    })

    this.wssState.select(leftCall).subscribe((who: string | undefined) => {
      if (who) {
        let peer_connection: any = this.peerConnection[who]
        if (peer_connection) {
          peer_connection.close()
          delete this.peerConnection[who]
          this.newConnection(who)
        }
      }
    })
    this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg) {
        if (msg.hasOwnProperty("webrtc")) {
          if (msg.hasOwnProperty("get_token")) {
            this.processGetToken(msg)
          } else if (msg.hasOwnProperty("grant_token")) {
            this.processGrantToken(msg)
          } else if (msg.hasOwnProperty("release_token")) {
            this.processReleaseToken(msg)
          } else {
            this.processMessage(msg)
          }
        }
      }
    })


    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (this.call_id && !call_id) {
        this.call_id = call_id
        if (this.peerConnection["caller"]) {
          this.peerConnection["caller"].close()
          delete this.peerConnection["caller"]
          this.newConnection("caller")
        }
        if (this.peerConnection["rep"]) {
          this.peerConnection["rep"].close()
          delete this.peerConnection["rep"]
          this.newConnection("rep")
        }
        if (this.peerConnection["manager"]) {
          this.peerConnection["manager"].close()
          delete this.peerConnection["manager"]
          this.newConnection("manager")
        }
        this.media_state = {} //reset the state
        // this.bCallerWillInitiate = false
        // this.bManagerWillInitiate = false
      }
      if (!this.call_id && call_id) {
        console.log("wrtc started call" + new Date())
        this.call_id = call_id
        console.log("wrtc23  call_fn, call started calling checkConnectionTracks");
        this.checkConnectionTracks(true)
      }
    })

    this.wssState.select(callerId).subscribe((caller_id: string | undefined) => {
      this.caller_id = caller_id
      if (caller_id) {
        console.log("wrtc23 call_fn, caller_id exist when you are in a call and you are not the caller " + this.caller_id)
        if (this.msg_from != "caller") { //if I am not the caller
          this.newConnection("caller")
          this.checkConnectionTracks(true)
        }
      }
    })

    this.wssState.select(repId).subscribe((rep_id: string | undefined) => {
      if (rep_id) {
        if (this.msg_from != "rep") { //If I am not the rep
          this.newConnection("rep")

        }
        if (!this.rep_id) {
          if (this.msg_from == "caller") {
            if (this.streams["caller"]) {
              this.rep_id = rep_id
              // this.checkConnectionTracks()
            }
          }
          // console.log("wrtc set caller_id")
        }


        //TEST POINT, verify that the rep id exist when you are the rep and are in a call
        this.rep_id = rep_id
        console.log("wrtc23 call_fn test rep id exist when you are the rep  or caller and are in a call " + this.rep_id)
        this.checkConnectionTracks(true)

        if (this.rep_id == this.my_id) {
          if (this.msg_from == "caller" || this.stream_id != "me") {
            console.error("messages should be from rep and the stream_id should be me")
          }
          if (this.msg_from != "rep") {
            console.error("if are the rep")
          }
        }
      }
    })
    this.wssState.select(managerId).subscribe((manager_id: string | undefined) => {
      if (manager_id) {
        this.manager_id = manager_id
        if (this.msg_from != "manager") {
          console.log("wrtc23 call_fn, test manager_id shold exist when we are in a call with manager" + this.manager_id)
          this.newConnection("manager")
          this.checkConnectionTracks(true)
        }
      }
    })
  }
  //**************************************************************************
  // setPeerConnection creates the peer connection
  //**************************************************************************
  newConnection(target: string): void {

    if (!this.peerConnection[target]) {

      this.peerConnection[target] = new RTCPeerConnection(this.ice_servers);
      // console.log("wrtc new peerConnection for " + target + " connectionState " + this.peerConnection[target].connectionState)

      this.peerConnection[target].onicecandidate = (e: RTCPeerConnectionIceEvent) => {
        if (e.candidate) {
          // console.log("wrtc -->send ice candidate to " + target)
          this.wss_service.sendMessageToOtherMembers({
            webrtc: target,
            from: this.msg_from,
            candidate: e.candidate,
          });
        }
      };

      this.peerConnection[target].ontrack = (e: RTCTrackEvent) => {
        const track = e.track;
        console.log('wrtc  gt <--' + target + ' got ' + track.kind + " enabled = " + track.enabled + " " + track.id);
        this.kVideoState.dispatch(new AddTrack(target, track))
      }
    }
    this.resetTracksForConnection(target)
  }

  //****************************************************************************
  // checkConnectionTracks is ony called when streams change, or when we start a call
  //****************************************************************************
  local_audio_track_id: string = ""
  local_video_track_id: string = ""
  local_shared_track_id: string = ""
  current_negotiation: any;
  granted_token: Date | undefined //When we give a token we save it
  waiting_for_other_party_to_reply: number = 0

  async checkConnectionTracks(bStartingCall: boolean) {
    console.log("wrtc23 call_fn checkConnectionTracks (" + bStartingCall + ") " + new Date())

    console.log("wrtc23 busy chek busy *********************")
    if (this.waiting_for_other_party_to_reply) {
      console.log("wrtc23 busy waiting for other party to retry " + this.waiting_for_other_party_to_reply);
      let now = new Date().getTime()
      let dif = now - this.waiting_for_other_party_to_reply
      if (dif > 0) {
        console.log("wrtc23 busy waiting for other party to retry < now, now = " + dif);
        return;
      }
    }
    if (this.current_negotiation) {
      console.log("wrtc23 busy test busy width current_negotiation " + JSON.stringify(this.current_negotiation))
      let now = new Date().getTime()
      let dif = now - this.current_negotiation.token.getTime()
      if (dif < 10000) {
        console.log("wrtc23 busy test busy dif " + dif)
        return
      } else {
        console.log("wrtc23 busy current_negotiation timed out dif " + dif)
      }
    }
    if (this.granted_token) {
      let dtd = new Date(this.granted_token)
      let gtt = dtd.getTime()
      let now = new Date().getTime()
      let dif = now - gtt
      if (dif < 150000) {
        console.log("wrtc23 busy test busy width granted_token " + this.granted_token)
        return
      } else {
        this.granted_token = undefined
        console.log("wrtc23 busy granted_token expired");
      }
    }


    console.log("wrtc23 call chek in call *********************")
    if (!this.call_id) { //If we are not in a call we dont care
      console.log("wrtc23 call not in a call")
      return
    }

    let num_participants = 0
    if (this.caller_id) { //If we are not in a call we dont care
      num_participants++
    }
    if (this.rep_id) { //If we are not in a call we dont care
      num_participants++
    }
    if (this.manager_id) { //If we are not in a call we dont care
      num_participants++
    }
    if (num_participants < 2) {
      console.log("wrtc23 call num_participants<2 caller_id " + this.caller_id + " rep_id " + this.rep_id + " manager_id " + this.manager_id)
      return
    }

    console.log("wrtc23 streams chek streams *********************")

    //We only care about a change in the local tream the one with this.stream_id
    if (!this.streams) {
      console.log("wrtc23 streams, no streams")
      return
    }

    let bTryToStartOffer: boolean = false

    //We start with the remote share because we want to get the track id to identify it in the other side

    let local_share_stream = this.streams["local_share"]
    if (local_share_stream && local_share_stream.active) {
      console.log("wrtc23 streams local_share active")
      let vt = local_share_stream.getVideoTracks()
      let track = vt[0]
      if (track.enabled) {
        let local_shared_track_id = track.id
        if (local_shared_track_id != this.local_shared_track_id) {
          console.log("wrtc23 streams try to start offer local_shared " + local_shared_track_id + " != " + local_shared_track_id.local_video_track_id)
          this.local_shared_track_id = local_shared_track_id
          bTryToStartOffer = true;
        }
      } else {
        console.log("wrtc23 streams local_share track disabled")
        this.local_shared_track_id = ""
      }
    } else {
      this.local_shared_track_id = ""
      console.log("wrtc23 streams local_share inactive")
    }

    let local_stream = this.streams[this.stream_id] // me or caller
    if (local_stream && local_stream.active) {
      console.log("wrtc23 streams local_stream active")
    } else {
      console.log("wrtc23 streams local_stream inactive")
      local_stream = undefined
      this.local_audio_track_id = ""
      this.local_video_track_id = ""
    }

    if (local_stream) {
      if (local_stream.active) {
        if (bStartingCall) {
          let tracks = local_stream.getTracks()
          for (let i = 0; i < tracks.length; i++) {
            let track = tracks[i]
            if (track.enabled) {
              bTryToStartOffer = true;
              console.log("wrtc23 streams bStartingCall local_streams > 0")
            }
          }
        }

        let at = local_stream.getAudioTracks()
        if (at.length > 0) {
          let track = at[0]
          if (track.enabled) {
            let local_audio_track_id = track.id
            if (local_audio_track_id != this.local_audio_track_id) {
              console.log("wrtc23 streams try to start offer audio " + local_audio_track_id + " != " + this.local_audio_track_id)
              this.local_audio_track_id = local_audio_track_id
              bTryToStartOffer = true;
            }
          } else {
            console.log("wrtc23 streams audio track not enabled")
            this.local_audio_track_id = ""
          }
        } else {
          console.log("wrtc23 streams no audio track")
          this.local_audio_track_id = ""
        }


        let vt = local_stream.getVideoTracks()
        if (vt.length > 0) {
          let track = vt[0]
          if (track.enabled) {
            let local_video_track_id = track.id
            if (local_video_track_id != this.local_video_track_id) {
              console.log("wrtc23 streams try to start offer video " + local_video_track_id + " != " + this.local_video_track_id)
              this.local_video_track_id = local_video_track_id
              bTryToStartOffer = true;
            }
          } else {
            console.log("wrtc23 streams video track not enabled")
            this.local_video_track_id = ""
          }
        } else {
          console.log("wrtc23 streams no video track")
          this.local_video_track_id = ""
        }
      }
    }
    // if (this.local_audio_track_id == "") {
    //   bTryToStartOffer = false
    //   console.log("#CASU test with no offer to the caller")
    // }
    //TEST POINT
    if (!bTryToStartOffer) {
      console.log("wrtc23 streams test no changes in tracks")
      return;
    } else {
      console.log("wrtc23 busy ready to try to get channel ********************************** start token negotiation  " + new Date())
    }


    let token_requests: string[] = []
    if (this.msg_from == "rep") {
      if (this.manager_id) { //we expect a response from the manager
        token_requests.push("manager")
      }
      if (this.caller_id) {
        token_requests.push("caller")
      }
    } else if (this.msg_from == "caller") {
      if (this.manager_id) { //we expect a response from the manager
        token_requests.push("manager")
      }
      if (this.rep_id) {
        token_requests.push("rep")
      }
    } else if (this.msg_from == "manager") {
      if (this.rep_id) { //we expect a response from the manager
        token_requests.push("rep")
      }
      if (this.caller_id) {
        token_requests.push("caller")
      }
    }
    if (token_requests.length == 0) {
      console.error("wrtc23 token_requests.length == 0")
    }
    let asked_at = new Date()
    let me = this
    let negotiation_timer = setTimeout(() => {
      if (this.current_negotiation) {
        console.error("wrtc23 timer Negotiation timed out " + new Date() + " " + JSON.stringify(this.current_negotiation))
        me.back_up_time_out = undefined
        let bStartingCall = false
        if (me.current_negotiation) {
          bStartingCall = me.current_negotiation.bStartingCall
        }
        this.current_negotiation = undefined
        console.log("wrtc23 timer retry checkConnectionTracks " + bStartingCall);
        me.checkConnectionTracks(bStartingCall)
      }
    }, 20000)

    let msg_to = token_requests.pop() //Only send it for the first one
    this.current_negotiation = {
      token: asked_at,
      token_requests: token_requests,
      token_reponses: {},
      bStartingCall: bStartingCall,
      msg_to: msg_to,
      negotiation_timer: negotiation_timer
    }

    //TEST POINT
    console.log("wrtc23 busy expect response from: " + JSON.stringify(this.current_negotiation))
    //the next one will be done in doneWithNegotiation
    if (msg_to) {
      let msg: any = {
        webrtc: msg_to, //message to
        from: this.msg_from,
        "get_token": this.current_negotiation.token,
        tracks: { //needed by the other side to id share and video
          shared: this.local_shared_track_id,
          video: this.local_video_track_id,
          audio: this.local_audio_track_id
        }
      }
      console.log("wrtc23 msg --> : " + JSON.stringify(msg) + " " + new Date())
      this.wss_service.sendMessageToOtherMembers(msg);
    }
  }

  //Now we actually will set the streams in the connections
  /*Race condition protocol
    --> get_token
    <-- token when we get all the token from all the other members of the call, we can start
    this will reset the number of audio and video tracks
    if we get a get_token before we get all the tokens we back up for a random time and try aganin
  */
  back_up_time_out: any
  processGetToken(msg: any) {
    let me = this
    console.log("wrtc23 msg ****************** ")
    console.log("wrtc23 msg <--  " + JSON.stringify(msg) + " " + new Date())
    if (this.current_negotiation && this.waiting_for_other_party_to_reply == 0) {

      if (!this.back_up_time_out) {
        let wait_time = 100
        let wait_for_other = 0;
        if (this.msg_from == "caller" || this.msg_from == "manager" && msg.from == "caller") {
          wait_time = 5000
          wait_for_other = new Date().getTime() + 4000
        }

        this.back_up_time_out = setTimeout(() => {
          me.back_up_time_out = undefined
          let bStartingCall = false
          if (me.current_negotiation) {
            bStartingCall = me.current_negotiation.bStartingCall
          }
          this.current_negotiation = undefined
          console.log("wrtc23 busy retry checkConnectionTracks " + bStartingCall);
          me.checkConnectionTracks(bStartingCall)
        }, wait_time) //Make sure we are not waiting before the retry
        console.log("wrtc23  busy try again in  " + wait_time + " ms");
        this.waiting_for_other_party_to_reply = wait_for_other
        console.log("wrtc23 busy" + this.msg_from + " yelds to " + msg.from + " until waiting_for_other_party_to_reply " + this.waiting_for_other_party_to_reply);

      } else {
        console.log("wrtc23 busy back_up_time_out on " + this.back_up_time_out);
      }
      return;
    }
    this.granted_token = msg.get_token
    this.waiting_for_other_party_to_reply = 0 //we dont start until get_token is released
    console.log("wrtc23 busy granted_token =" + this.granted_token)
    console.log("wrtc23 busy this.waiting_for_other_party_to_reply  = 0 ")

    let resp_msg: any = {
      webrtc: msg.from,
      from: this.msg_from,
      asked_at: msg.asked_at,
      "grant_token": new Date(),
    }

    this.kVideoState.dispatch(new RemoteTracksIds(msg.from, msg.tracks))
    this.wss_service.sendMessageToOtherMembers(resp_msg);
    console.log("wrtc23 msg --> " + JSON.stringify(resp_msg) + " " + new Date());
    //We should not be sending any request until the other partie finishes

  }
  processGrantToken(msg: any) {
    console.log("wrtc23 msg <-- from " + JSON.stringify(msg) + " " + new Date());
    let current_negotiation = Object.assign({}, this.current_negotiation)
    let token_reponses = Object.assign({}, current_negotiation.token_reponses)
    token_reponses[msg.from] = msg.grant_token
    current_negotiation.token_reponses = token_reponses
    this.current_negotiation = current_negotiation
    if (this.current_negotiation.token_requests) {
      for (let i = 0; i < this.current_negotiation.token_requests.length; i++) {
        let key = this.current_negotiation.token_requests[i]
        if (!token_reponses[key]) {
          console.log("wrtc23 not ready waiting for " + key)
          return
        }
      }
    }

    this.startOffer(msg.from)
  }
  doneWithNegotiation() {
    console.log("wrtc23 msg done with WRTC negotiation")

    if (this.current_negotiation) {
      if (this.current_negotiation.token_requests) {
        let msg_to = this.current_negotiation.msg_to
        let msg: any = {
          webrtc: msg_to, //message to
          from: this.msg_from,
          "release_token": this.current_negotiation.token
        }
        this.wss_service.sendMessageToOtherMembers(msg);
        console.log("wrtc23 msg -->  " + JSON.stringify(msg) + " " + new Date());

      }
      console.log("wrtc23 busy done with WRTC negotiation for " + this.current_negotiation.msg_to)
    }

    this.waiting_for_other_party_to_reply = 0
    console.log("wrtc23 busy waiting_for_other_party_to_reply = 0");

    let process_request = []
    if (this.current_negotiation) {
      process_request = Object.assign([], this.current_negotiation.process_request)
    }
    if (process_request) {
      let msg_to = process_request.pop()
      if (msg_to) {
        let current_negotiation = Object.assign({}, this.current_negotiation)
        current_negotiation.process_request = process_request
        this.current_negotiation = current_negotiation

        let msg: any = {
          webrtc: msg_to, //message to
          from: this.msg_from,
          "get_token": this.current_negotiation.token,
          tracks: { //needed by the other side to id share and video
            shared: this.local_shared_track_id,
            video: this.local_video_track_id,
            audio: this.local_audio_track_id
          }
        }
        console.log("wrtc23 msg --> : " + JSON.stringify(msg) + " " + new Date())
        this.wss_service.sendMessageToOtherMembers(msg);
        return
      }
    }
    if (this.current_negotiation) {
      if (this.current_negotiation.negotiation_timer) {
        console.log("wrtc23 timer, clear timer")
        clearTimeout(this.current_negotiation.negotiation_timer)
      }
    }
    delete this.current_negotiation
    console.log("wrtc23 busy delete this.current_negotiation");
    console.log("wrtc23 call_fn try again just in case the streams have changed");
    this.checkConnectionTracks(false)
  }
  processReleaseToken(msg: any) {
    console.log("wrtc23 msg <--  " + JSON.stringify(msg) + " " + new Date());
    if (this.granted_token != msg.release_token) {
      console.error("wrtc32 busy this.granted_token != msg.release_token, " + this.granted_token + " != " + msg.release_token)
    }
    this.granted_token = undefined
    console.log("wrtc23 busy this.granted_token = undefined ")
  }


  startOffer(key: string) {
    console.log("wrtc23 msg ********** startOffer")
    let out_tracks = this.getLocalConnectionTracks()
    if (out_tracks.length > 0) {
      this.resetTracksForConnection(key)
      this.makeOfferForConnection(key)
    }
  }

  async makeOfferForConnection(conection_id: string) {
    let now = new Date().getTime()
    let dif = now - this.got_offer_on
    if (dif < 1000) { //Wait at least a second before making an offer
      return
    }

    if (now - this.caller_will_initiate < 2000) {
      console.error("We are trying to make an offer while waiting for the caller to make us an offer")
      return
    }
    if (now - this.manager_will_initiate < 2000) {
      console.error("We are trying to make an offer while waiting for the manager to make us an offer")
      return
    }

    this.sent_offer_on = now //cleard when we get an answer


    let peer_connection: any = this.peerConnection[conection_id]
    if (!peer_connection) {
      console.error("Peer conection does not exist for" + conection_id)
      return;
    }
    if ((peer_connection.connectionState == "connected"
      || peer_connection.connectionState == "new")
      && peer_connection.signalingState == "stable") {
      this.resetTracksForConnection(conection_id)
      try {
        console.log("wrtc --> send offer from " + this.msg_from + " to " + conection_id)
        let offer = await peer_connection.createOffer()
        if (offer) {
          await peer_connection.setLocalDescription(offer);
          this.wss_service.sendMessageToOtherMembers({
            webrtc: conection_id, //message to
            from: this.msg_from,
            offer: peer_connection.localDescription,
          });
        }
      } catch (e: any) {
        console.error("changed video fail to set create offer " + e)
      }
    } else {
      console.info("tried to renegotiatl call that while in state " + peer_connection.connectionState + " signalingState " + peer_connection.signalingState)
    }
  }
  //****************************************************************************
  // resetTracksForConnection is called when streams change, or when we get an offer
  //****************************************************************************
  resetTracksForConnection(peer_key: string) {
    let live_tracks: MediaStreamTrack[] = []
    let local_stream = this.streams[this.stream_id]
    if (local_stream && local_stream.active) {
      live_tracks = local_stream.getTracks() //1
    }
    if (!live_tracks) {
      console.error("wrtc rest  " + peer_key + " no live_tracks ")
      return
    }
    let peer_connection: any = this.peerConnection[peer_key]

    let trac_ids: string[] = []
    for (let i = 0; i < live_tracks.length; i++) {
      let track: MediaStreamTrack = live_tracks[i]
      trac_ids.push(track.id)
    }
    let share_tracks: MediaStreamTrack[] | undefined
    if (this.streams["local_share"] && this.streams["local_share"].active) {
      share_tracks = this.streams["local_share"].getTracks() //2
    }
    if (share_tracks) {
      for (let i = 0; i < share_tracks.length; i++) {
        let track: MediaStreamTrack = share_tracks[i]
        trac_ids.push(track.id)
      }
    }

    let sender_trac_ids: string[] = []
    //remove the tracks that are in the connection
    let senders = peer_connection.getSenders()
    for (let i = 0; i < senders.length; i++) {
      let sender: RTCRtpSender = senders[i]

      if (sender.track) {
        if (trac_ids.indexOf(sender.track.id) < 0) {
          console.log("wrtc removed " + sender.track.kind + " from " + peer_key + " " + sender.track.id)
          peer_connection.removeTrack(sender)
        } else {
          sender_trac_ids.push(sender.track.id)
        }
      }
    }
    //add the tracks that are in the local stream  but not in the connection
    //When we add the track here it might fail if the connection is inactive
    for (let i = 0; i < live_tracks.length; i++) {
      let track: MediaStreamTrack = live_tracks[i]

      if (sender_trac_ids.indexOf(track.id) < 0) {
        peer_connection.addTrack(track)
        console.log("wrtc added " + track.kind + " to " + peer_key + " " + track.id + " " + new Date())
      }
    }

    if (share_tracks) {
      for (let i = 0; i < share_tracks.length; i++) {
        let track: MediaStreamTrack = share_tracks[i]

        if (sender_trac_ids.indexOf(track.id) < 0) {
          peer_connection.addTrack(track)
        }
      }
    }
  }

  sout_tracks: string = "" //compare the previosus version
  getLocalConnectionTracks(): any {
    let out_tracks: any[] = []

    let local_stream = this.streams[this.stream_id] // me or caller
    if (local_stream && local_stream.active) {
      let live_tracks = local_stream.getTracks() //3
      for (let i = 0; i < live_tracks.length; i++) {
        let track: MediaStreamTrack = live_tracks[i]
        out_tracks.push({ id: track.id, kind: track.kind, role: this.msg_from }) //the track is from
        // live_track_ids[track.id] = true
      }
    }//Sep 2023, was after the share tracks

    if (this.streams["local_share"] && this.streams["local_share"].active) {
      let svt = this.streams["local_share"].getTracks()//4
      if (svt.length > 0) {
        let track = svt[0]
        out_tracks.push({ id: track.id, kind: track.kind, role: this.msg_from }) //the track is from
        if (svt.length > 1) {
          console.error("wrtc23 error we should have at most one shared track")
        }
      }
    }

    // if (share_tracks) {
    //   for (let i = 0; i < share_tracks.length; i++) {
    //     let track: MediaStreamTrack = share_tracks[i]
    //     out_tracks.push({ id: track.id, kind: "remote_share", role: this.msg_from })
    //   }
    // }
    return out_tracks
  }

  async processMessage(jmsg: any) {

    if (this.msg_from == "caller") { //if I am the caller
      if (jmsg.webrtc != "caller") {  //.webrtc is the message destination
        console.error("wrtc Wrong target " + JSON.stringify(jmsg))
      }

      if (jmsg.from == "manager" && !this.manager_id) {
        console.error("wrtc manager send a message and is not in the call" + JSON.stringify(jmsg))
      }
      if (jmsg.from == "rep" && !this.rep_id) {
        console.error("wrtc rep send a message and is not in the call" + JSON.stringify(jmsg))
      }

      if (jmsg.from != "manager" && jmsg.from != "rep") {
        console.error("wrtc Wrong source " + JSON.stringify(jmsg))
      }

      if (jmsg.candidate) {
        // console.log("wrtc add ice candidate to " + jmsg.from)
        this.peerConnection[jmsg.from].addIceCandidate(jmsg.candidate)
        return
      }
    } else { //I am the rep or the manager
      if (this.msg_from != "caller" && jmsg.from != "remote_share") {
        if (this.msg_from == "rep" && jmsg.from == "rep") {
          console.error("Msessge from me " + JSON.stringify(jmsg))
          return;
        }
      }
      //To
      if (this.msg_from != jmsg.webrtc) {
        console.error("Msessge NOT for me " + JSON.stringify(jmsg))
        return;
      }

      if (jmsg.candidate) {
        // console.log("wrtc add ice candidate to " + jmsg.from)
        this.peerConnection[jmsg.from].addIceCandidate(jmsg.candidate)
        return
      }
    }
    //**************************************************************************
    // offer, calls reset tacks for connetion to create the connection if it
    //   does not exist, and check to make sure the local and share stream are
    //   part of the connection
    //**************************************************************************
    if (jmsg.offer) {

      let out_tracks = this.getLocalConnectionTracks()
      let sout_tracks = JSON.stringify(out_tracks)
      let peer_connection: any = this.peerConnection[jmsg.from]
      peer_connection.debounce = new Date().getTime()
      if (peer_connection.sout_tracks != sout_tracks) {
        this.resetTracksForConnection(jmsg.from)
      }

      if (this.peerConnection[jmsg.from].connectionState != "new"
        && this.peerConnection[jmsg.from].connectionState != "connected") {
        console.error("wrtc msg got offer connectionState != new for" + jmsg.from + " connectionState " + this.peerConnection[jmsg.from].connectionState)
        // return;
      }
      console.log("wrtc <-- offer " + new Date() + " from " + jmsg.from + " to " + jmsg.webrtc)
      this.peerConnection[jmsg.from].setRemoteDescription(new RTCSessionDescription(jmsg.offer));
      // console.log("wrtc msg <-- got offer for " + jmsg.from + " connectionState " + this.peerConnection[jmsg.from].connectionState + " " + JSON.stringify(jmsg.out_tracks))

      try {
        //Make sure that the tracks are included in the response
        const answer = await this.peerConnection[jmsg.from].createAnswer();
        await this.peerConnection[jmsg.from].setLocalDescription(answer);
        this.wss_service.sendMessageToOtherMembers({
          webrtc: jmsg.from, //respond to the one that sent you the message
          from: this.msg_from, //caller, rep or manager
          answer: answer,
          out_tracks: out_tracks
        });
        // console.log("wrtc msg --> answer from " + jmsg.from + " to " + jmsg.webrtc + " connectionState " + this.peerConnection[jmsg.from].connectionState + " out_tracks " + JSON.stringify(out_tracks))
        if (jmsg.from == "caller") {
          this.caller_will_initiate = 0
        } else if (jmsg.from == "manager") {
          this.manager_will_initiate = 0
        }


      } catch (e: any) {
        console.error("wrtc answer error" + jmsg.from)
        return;
      }
    }


    if (jmsg.answer) {
      if (this.peerConnection[jmsg.from].connectionState != "new"
        && this.peerConnection[jmsg.from].connectionState != "connecting"
        && this.peerConnection[jmsg.from].connectionState != "connected") { //Renegotiation
        console.error("wrtc state != new or conencting for" + jmsg.from + " connectionState " + this.peerConnection[jmsg.from].connectionState)
        // return;
      }
      try {
        //Sep 2023, if we call this we loose the remote video, sep 6, and if we dont we never get it
        console.log("wrtc <-- answer from " + jmsg.from + " to " + jmsg.webrtc + JSON.stringify(jmsg.out_tracks))

        const remoteDesc = new RTCSessionDescription(jmsg.answer);
        await this.peerConnection[jmsg.from].setRemoteDescription(remoteDesc);
        // console.log("wrtc answer, added remote description to " + jmsg.from + " connectionState " + this.peerConnection[jmsg.from].connectionState)
        this.sent_offer_on = 0 //we are ready to receive an offer

      } catch (e: any) {
        console.error("wrtc setting remote description for  " + jmsg.from + " " + e)
        return
      }
      this.doneWithNegotiation()
    }
  }
}
