<div class="out">
  <div class="el">
    <div class="l">{{caller_name}}</div>
    <div class="r">{{waiting}}</div>
  </div>
  <div class="tx tx1">
    {{caller_email}}
  </div>
  <div class="filler">
  </div>

  <k-video [no_prompts]="true" role="me" class="video" [bMirrow]="true">
  </k-video>


  <k-button min_width="40" min_height="40" *ngIf="bAudioOn" background="#ECECEC" class="btn1" icon="mic.svg"  kzLib (click)="setAudio(false)"></k-button>
  <k-button min_width="40" min_height="40" *ngIf="!bAudioOn" background="#ECECEC" class="btn1" icon="mic_off.svg"  kzLib (click)="setAudio(true)"></k-button>


  <k-button min_width="40" min_height="40" *ngIf="bVideoOn" class="btn1 btn2" background="#ECECEC" icon="camera_on.svg"  kzLib (click)="setVideo(false)"></k-button>
  <k-button min_width="40" min_height="40" *ngIf="!bVideoOn" class="btn1 btn2" background="#ECECEC" icon="camera_off.svg"  kzLib (click)="setVideo(true)"></k-button>
  <phone-answer class="btn1 btn3" [round]="true" [ngClass]="{phone_l:bShowHangUp}"  kzLib (click)="answer()"></phone-answer>
  <div class="exp exp1"  kzLib (click)="expand()">
    Options
  </div>
  <img class="exp" [ngClass]="{colapse:bShowOpts}" src="assets/images/expand.svg"  kzLib (click)="expand()">

  <div *ngIf="bShowOpts" class="options">
    <div class="line top_line">
      <div class="opt_tx">
        <div class="bold">
          {{new_caller}}
        </div>
        <div *ngIf="bShowPersonal" class="personal">
          Personal
        </div>
      </div>
    </div>
    <div class="line" *ngIf="queue_entry.prev_call">
      <div class="opt_tx">
        <div class="bold f12">
          Previous call
        </div>
        <div class="f12">
          {{prev_rep_name}}
        </div>
      </div>
      <div class="date"> {{prev_date|date:'shortDate'}}</div>
    </div>
    <phone-hang-up *ngIf="bShowHangUp" class="ph_r1" [round]="true"  kzLib (click)="hangUp()"></phone-hang-up>
    <phone-hang-up background="#FFCE38" class="ph_r1" [ngClass]="{ph_r2:bShowHangUp}" [round]="true"  kzLib (click)="close()"></phone-hang-up>

  </div>
</div>
