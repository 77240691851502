import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowAsset } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { showAsset } from 'src/app/pages/pages.selectors';
import { ToolService } from 'src/app/tools/tool.service';
import { WSSService } from 'src/app/wss/wss.service';

@Component({
  selector: 'save-as',
  templateUrl: './save-as.component.html',
  styleUrls: ['./save-as.component.scss']
})
export class SaveAsComponent {
  show_asset: any | undefined
  subscriptions: any[] = []

  constructor(
    private pagesState: Store<PagesState>,
    private tool_service: ToolService,
    public wss_service: WSSService,
  ) {

    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        if (asset.asset_id || asset._id) {
          if (asset.type != "folder") {
            this.show_asset = asset;
          }
          return;
        }
      }
      delete this.show_asset
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  async savePDFAs() {
    try {
      await this.tool_service.copyAssets([this.show_asset], false) //We will need a new stream for the new room
      this.pagesState.dispatch(new ShowAsset({}));
      //The message is listend to by pages/in-call
      let msg: any = {
        show_asset: "none"
      }
      this.wss_service.sendMessageToMembersAndMe(msg)

    } catch (e: any) {
      //displayed in the tool_service
    }
  }
}
