import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard'
import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';
import { RecordService } from '../record.service';
import { NotifyUser } from 'src/app/pages/pages.actions';
import { WSSState } from 'src/app/wss/wss.model';
import { callId } from 'src/app/wss/wss.selectors';
import { hostUrl } from 'src/app/pages/pages.selectors';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
@Component({
  selector: 'rec-ready',
  templateUrl: './ready.component.html',
  styleUrls: ['./ready.component.scss']
})
export class ReadyComponent {
  call_id: string | undefined
  host_url: string | undefined
  subscriptions: any[] = []
  is_chrome: boolean = false
  constructor(
    private pagesState: Store<PagesState>,
    private rec_service: RecordService,
    private clipboard: Clipboard,
    private wssState: Store<WSSState>,
    public canvasStore: Store<CanvasState>,

  ) {
    console.log("rec-ready")
    const agent = window.navigator.userAgent.toLowerCase();
    this.is_chrome =
      agent.indexOf('edg') > -1 //? 'Chromium-based Edge'
      || agent.indexOf('opr') > -1// ? 'Opera'
      || agent.indexOf('chrome') > -1// ? 'Chrome'

    this.call_id = rec_service.call_id
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  async copyLink() {
    try {
      let url

      if (this.call_id && this.host_url) {
        let protocol: string = "https://"
        if (this.host_url.startsWith("local")) {
          protocol = location.protocol + "//"
        }
        url = protocol + this.host_url + "?rec_id=" + this.call_id

        this.clipboard.copy(url);
        if (this.is_chrome) {
          this.pagesState.dispatch(new NotifyUser("Recorder link copied"))
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  async downLoadWebm() {
    try {
      let url = await this.rec_service.getLink("webm")
      this.downloadIt(url, "webm")
    } catch (e) {
      console.error(e)
    }
  }
  async downLoadMp4() {
    try {
      this.pagesState.dispatch(new NotifyUser("Encoding mp4, it might take a few minutes"))
      let url = await this.rec_service.getLink("mp4")
      this.downloadIt(url, "mp4")
    } catch (e) {
      console.error(e)
    }
  }
  downloadIt(url: string, ext: string) {
    var link = document.createElement("a");
    let date = new Date()
    let iso = date.toISOString()
    let name = iso.split('T')[0] + "." + ext
    link.download = name;

    link.href = url;
    // link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  }
  cancel() {
    this.canvasStore.dispatch(new SelectToolOpts("r1"))
  }
}
