import { CalendarState, initialCalendarState, } from './calendar.model'
import { CalendarActions, CalendarActionTypes } from './calendar.actions';
// import { testCalendarUtils } from './calendars.util.test';
import { avaliableHours, makeHourSlots, makeSlots, makeWeekSlots } from './calendar.utils';

// function setSlots(calendarState: CalendarState) {
//   if (calendarState.from_t && calendarState.events && calendarState.work_time) {
//     let slot_minutes = 15
//     if (calendarState.work_time) {
//       if (calendarState.work_time.slot_minutes) {
//         slot_minutes = parseInt(calendarState.work_time.slot_minutes)
//       }
//     }
//     let rv: any = makeSlots(calendarState.events, calendarState.hours, slot_minutes, 1) //1 is one rep
//     calendarState.slots = rv.slots
//   }
// }
// function setWeekSlots(calendarState: CalendarState) {
//   if (!calendarState.from_t) {
//     let date = new Date()
//     calendarState.from_t = date.getTime()
//     date.setHours(24, 0, 0, 0);
//     calendarState.to_t = date.getTime()
//     checkHourSlots(calendarState)
//   }
//
//   if (calendarState.from_week_t && calendarState.events && calendarState.work_time) {
//     let slot_minutes = 15
//     if (calendarState.work_time) {
//       if (calendarState.work_time.slot_minutes) {
//         slot_minutes = parseInt(calendarState.work_time.slot_minutes)
//       }
//     }
//     calendarState.week_slots = makeWeekSlots(calendarState.from_week_t, calendarState.to_week_t, calendarState.week_events, calendarState.work_time)
//   }
// }

function checkHourSlots(calendarState: any) {
  if (calendarState.hour_slots.length == 0) {
    if (calendarState.from_t && calendarState.to_t && calendarState.work_time) {
      calendarState.hours = avaliableHours(calendarState.from_t, calendarState.to_t, [calendarState.work_time])
      calendarState.hour_slots = makeHourSlots(calendarState.hours)
    }
  }

}

//let streams_cache: any = {}
export function calendarReducer(state = initialCalendarState,
  action: CalendarActions): CalendarState {
  switch (action.type) {
    case CalendarActionTypes.ChangedTZ: {
      const calendarState: CalendarState = Object.assign({}, state);
      calendarState.changed_tz = action.tz
      return calendarState;
    }
    case CalendarActionTypes.SetSlots: {
      const calendarState: CalendarState = Object.assign({}, state);
      calendarState.slots = action.slots
      return calendarState;
    }
    case CalendarActionTypes.SaveTmp: {
      const calendarState: CalendarState = Object.assign({}, state);
      calendarState.tmp = action.tmp
      return calendarState;
    }
    case CalendarActionTypes.AddAppointment: {
      const calendarState: CalendarState = Object.assign({}, state);
      calendarState.new_appt = action.slot
      return calendarState;
    }
    case CalendarActionTypes.RefresDayWeek: {
      console.log("RefresDayWeek")
      const calendarState: CalendarState = Object.assign({}, state);
      calendarState.refresh_counter++
      return calendarState;
    }

    case CalendarActionTypes.GotWorkTime: {
      console.log("GotWorkTime")
      const calendarState: CalendarState = Object.assign({}, state);
      calendarState.work_time = action.work_time

      if (action.work_time) {
        if (action.work_time.from) {
          calendarState.work_time = action.work_time
          calendarState.hours = avaliableHours(calendarState.from_t, calendarState.to_t, [calendarState.work_time])
          // setSlots(calendarState)
        }
      }
      return calendarState;
    }
    case CalendarActionTypes.ClendarUser: {
      console.log("ClendarUser")
      const calendarState: CalendarState = Object.assign({}, state);
      if (action.user) {
        if (action.user.work_time) {
          if (action.user.work_time.from) {
            calendarState.work_time = action.user.work_time
            // checkHourSlots(calendarState);
            // setSlots(calendarState)
          }
        }
        calendarState.bHasCalToken = action.user.bHasCalToken
      }

      return calendarState;
    }

    case CalendarActionTypes.GetFromToEvents: {
      // testCalendarUtils()
      const calendarState: CalendarState = Object.assign({}, state);
      calendarState.from_to_events = action.events
      return calendarState;
    }

    case CalendarActionTypes.GotWeekEvents: {
      // testCalendarUtils()
      const calendarState: CalendarState = Object.assign({}, state);
      calendarState.week_events = action.events
      calendarState.from_week_t = action.from
      calendarState.to_week_t = action.to
      calendarState.offset = action.offset
      console.log("GotWeekEvents " + new Date(calendarState.from_week_t).toString() + " " + new Date(calendarState.to_week_t).toString())

      // setWeekSlots(calendarState)
      return calendarState;
    }
    case CalendarActionTypes.Showing24: {
      const calendarState: CalendarState = Object.assign({}, state);
      calendarState.bShowing24 = action.bShowing24
      if (calendarState.bShowing24) {
        calendarState.hours = {
          from: calendarState.from_t,
          to: calendarState.to_t
        }
      } else {
        calendarState.hours = avaliableHours(calendarState.from_t, calendarState.to_t, [calendarState.work_time])
      }
      calendarState.hour_slots = makeHourSlots(calendarState.hours)


      return calendarState;
    }
    // case CalendarActionTypes.GotEvents: {
    //   // testCalendarUtils()
    //   const calendarState: CalendarState = Object.assign({}, state);
    //   calendarState.events = action.events
    //
    //   let bDidChange = (calendarState.from_t != action.from || calendarState.to_t != action.to || calendarState.offset != action.offset)
    //   //action from_t and to_t is the begining of the day where the data is been requested from
    //   //We need to change it to GTM 0 plus the minutes
    //   calendarState.from_t = action.from
    //   calendarState.to_t = action.to
    //   calendarState.offset = action.offset
    //
    //   console.log("GotEvents " + new Date(calendarState.from_t).toString() + " " + new Date(calendarState.to_t).toString())
    //
    //   if (bDidChange) {
    //     if (calendarState.bShowing24) {
    //       calendarState.hours = {
    //         from: calendarState.from_t,
    //         to: calendarState.to_t
    //       }
    //     }
    //     // else {
    //     //   // calendarState.hours = avaliableHours(calendarState.from_t, calendarState.to_t, [calendarState.work_time])
    //     //   console.log("#TI from " + new Date(calendarState.from_t).toUTCString() + " to " + new Date(calendarState.to_t).toUTCString() + " h from " + new Date(calendarState.hours.from).toUTCString() + " h to " + new Date(calendarState.hours.to).toUTCString())
    //     // }
    //     // calendarState.hour_slots = makeHourSlots(calendarState.hours)
    //   }
    //
    //   // setSlots(calendarState)
    //   return calendarState;
    // }
    case CalendarActionTypes.DeleteEvent: {
      console.log("del events")
      const calendarState: CalendarState = Object.assign({}, state);
      let events: any = []
      for (let i = 0; i < calendarState.events.length; i++) {
        let event = calendarState.events[i]
        if (event._id != action.event_id) {
          events.push(event)
        }
      }
      calendarState.events = events
      // setSlots(calendarState)
      return calendarState;
    }
    default:
      return state;
  }
}
