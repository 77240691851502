import { Component, Input } from '@angular/core';

@Component({
  selector: 'kpay-bar-part',
  templateUrl: './kpay-bar-part.component.html',
  styleUrls: ['./kpay-bar-part.component.scss']
})
export class KpayBarPartComponent {
  _progress: number = 0
  bShowProgress: boolean = false
  @Input() set progress(p: number) {
    this.bShowProgress = true
    if (p) {
      this._progress = p
    }
  }
  @Input() spining: boolean = false
  @Input() label: string = ""
  _active: boolean = false
  @Input() set active(a: boolean) {
    this._active = a
    if (a) {
      this.progress = 100
    }
  }

  @Input() error: boolean = false

}
