/*
Copyright(c) 2022 Sergio A. Fernandez, Ph.D.
*/

import { KVideoAction, KVideoActionTypes } from "./k-video.actions";
import { KVideoState, initialkVideoState } from "./k-video.model";

function dumpStreams(streams: any, label: string) {
  console.log("#CASU")
  let keys = Object.keys(streams)
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    let stream = streams[key]
    let ats = stream.getAudioTracks()
    let vts = stream.getVideoTracks()
    console.log("#CASU " + key + " dump streams from " + label + " audio tracks " + ats.length + " video tracks " + vts.length)
  }
  console.log("#CASU")
}
function addTrack(state: any, action: any) {
  const kVideoState: KVideoState = Object.assign({}, state);
  console.log("add track " + action.role + " " + action.track.kind + " " + action.track.id)
  let target_track = action.role
  if (action.track.kind == "video") {
    let tracks_for = kVideoState.remote_tracks[action.role]
    if (tracks_for) {
      //if there is no video and the shared is >0
      if (tracks_for.shared.length > 0) {
        if (tracks_for.video.length == 0) {
          kVideoState.showing_remote_share = true
          target_track = "remote_share"
        } else { //There is video
          if (tracks_for.video == action.track.id) {
            console.log("it's video")
          } else { //There is video and there is a share track
            kVideoState.showing_remote_share = true
            target_track = "remote_share"
          }
        }
      }
    } else {
      console.error("no tracks for " + action.role)
    }
  }


  if (kVideoState.streams[target_track]) {
    if (kVideoState.streams[target_track].active == false) {
      let streams = Object.assign({}, kVideoState.streams)
      delete streams[target_track]
      kVideoState.streams = streams
      // dumpStreams(streams, "1")
    }
  }
  if (!kVideoState.streams[target_track]) {
    let streams = Object.assign({}, kVideoState.streams)
    streams[target_track] = new MediaStream()
    streams[target_track].addTrack(action.track)
    kVideoState.streams = streams
    // dumpStreams(streams, "New MediaStream " + target_track)
  } else {
    let bTrackExist: boolean = false;
    if (kVideoState.streams[target_track].active) {
      let existing_tacks = kVideoState.streams[target_track].getTracks()
      for (let i = 0; i < existing_tacks.length; i++) {
        let track = existing_tacks[i]
        if (action.track.id == track.id) { //Sep 2023, we need to add the track when there is a share screen
          bTrackExist = true;
        }
      }
    }
    if (!bTrackExist) {
      let streams = Object.assign({}, kVideoState.streams)
      let stream: MediaStream = streams[target_track]
      let tracks = stream.getTracks()
      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i]
        if (track.kind == "audio" && action.track.kind == "audio") {
          if (track.id != action.track.id) {
            console.log("#CASU " + target_track + " Remove audio track " + stream.id)
            stream.removeTrack(track)
          }
        } else if (track.kind == "video" && action.track.kind == "video") {
          if (target_track != "remote_share") {
            if (track.id != action.track.id) {
              console.log("#CASU " + target_track + " Remove video track " + stream.id)
              stream.removeTrack(track)
            }
          }
        }
      }
      console.log("#CASU " + target_track + " add track " + action.track.kind + " " + stream.id + " " + new Date().getTime())
      stream.addTrack(action.track)
      streams[target_track] = stream
      kVideoState.streams = streams
      // dumpStreams(streams, "Remove Track")
    }
  }

  return kVideoState;
}

export function kVideoReducer(state = initialkVideoState,
  action: KVideoAction): KVideoState {
  switch (action.type) {

    case KVideoActionTypes.HasVideoCamera: {
      const kVideoState: KVideoState = Object.assign({}, state);
      kVideoState.bHasVideoCamera = action.bHasVideoCamera
      return kVideoState;
    }

    case KVideoActionTypes.ClearMediaState: {
      //xxx
      const kOldVideoState: KVideoState = Object.assign({}, state);
      let keys = Object.keys(kOldVideoState.streams)
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let stream = kOldVideoState.streams[key]
        let tracks = stream.getTracks()
        for (let j = 0; j < tracks.length; j++) {
          let track = tracks[j]
          track.stop()
        }
      }
      const kVideoState: KVideoState = Object.assign({}, initialkVideoState);
      kVideoState.bAudioOn = kOldVideoState.bAudioOn
      kVideoState.bVideoOn = kOldVideoState.bVideoOn
      kVideoState.bHasVideoCamera = kOldVideoState.bHasVideoCamera
      kVideoState.showing_remote_share = false
      kVideoState.remote_tracks = {}
      let streams: any = {}
      streams['me'] = kOldVideoState.streams['me']
      if (kOldVideoState.streams['rep']) {
        if (kOldVideoState.streams['rep'].id == kOldVideoState.streams['me'].id) {
          streams['rep'] = kOldVideoState.streams['rep']
        }
        kVideoState.streams = streams
        // dumpStreams(streams, "4")
      }
      kVideoState.selected_a_out_dev_id = kOldVideoState.selected_a_out_dev_id
      kVideoState.selected_adev_id = kOldVideoState.selected_adev_id
      kVideoState.selected_vdev_id = kOldVideoState.selected_vdev_id

      kVideoState.media_state = {
        "rep": {
          audio: kVideoState.bAudioOn,
          media_state: "rep",
          video: kVideoState.bVideoOn
        }
      }
      // let media_state = Object.assign({}, kVideoState.media_state);
      // delete media_state[action.target]
      // kVideoState.media_state = media_state
      return kVideoState;
    }

    case KVideoActionTypes.SelectedSpeaker: {
      const kVideoState: KVideoState = Object.assign({}, state);
      kVideoState.selected_a_out_dev_id = action.id
      return kVideoState;
    }
    case KVideoActionTypes.SelectedMic: {
      const kVideoState: KVideoState = Object.assign({}, state);
      kVideoState.selected_adev_id = action.id
      return kVideoState;
    }
    case KVideoActionTypes.SelectedCamera: {
      const kVideoState: KVideoState = Object.assign({}, state);
      kVideoState.selected_vdev_id = action.id
      return kVideoState;
    }


    case KVideoActionTypes.MediaState: {
      const kVideoState: KVideoState = Object.assign({}, state);
      let media_state = Object.assign({}, kVideoState.media_state);
      media_state[action.msg.media_state] = action.msg
      kVideoState.media_state = media_state
      return kVideoState;
    }
    case KVideoActionTypes.CheckVideo: {
      const kVideoState: KVideoState = Object.assign({}, state);
      kVideoState.check_video_seq++
      kVideoState.check_video = { role: action.role, seq: kVideoState.check_video_seq }
      if (action.role == "remote_share") {
        kVideoState.showing_remote_share = false
        let streams = Object.assign({}, kVideoState.streams)
        delete streams[action.role]
        kVideoState.streams = streams
        // dumpStreams(streams, "5")
      }
      return kVideoState;
    }
    case KVideoActionTypes.SetShare: {
      const kVideoState: KVideoState = Object.assign({}, state);
      kVideoState.bShareOn = action.on
      return kVideoState;
    }
    case KVideoActionTypes.SetAudio: {
      const kVideoState: KVideoState = Object.assign({}, state);
      kVideoState.bAudioOn = action.on
      return kVideoState;
    }
    case KVideoActionTypes.SetVideo: {
      const kVideoState: KVideoState = Object.assign({}, state);
      kVideoState.bVideoOn = action.on
      return kVideoState;
    }
    case KVideoActionTypes.GotStream: {
      const kVideoState: KVideoState = Object.assign({}, state);
      let streams = Object.assign({}, kVideoState.streams)

      if (action.role == "me") {
        if (streams[action.role] && streams["rep"]) {
          if (streams[action.role].id == streams["rep"].id)
            streams["rep"] = action.stream
        }
      }
      streams[action.role] = action.stream
      kVideoState.streams = streams
      // dumpStreams(streams, "GotStream")
      console.log("share GotStream:" + action.role)
      // console.log("kvr added stream for " + action.role + " " + JSON.stringify(Object.keys(kVideoState.streams)))
      return kVideoState;
    }
    case KVideoActionTypes.RemoveStream: {
      const kVideoState: KVideoState = Object.assign({}, state);
      if (kVideoState.streams[action.role]) {
        let streams = Object.assign({}, kVideoState.streams)
        delete streams[action.role]
        console.log("share RemoveStream:" + action.role)
        kVideoState.streams = streams
        // dumpStreams(streams, "RemoveStream ")
      }
      // console.log("kvr removed stream for " + action.role + " " + JSON.stringify(Object.keys(kVideoState.streams)))
      return kVideoState;
    }


    case KVideoActionTypes.RemoteTracksIds: {
      const kVideoState: KVideoState = Object.assign({}, state);
      let remote_tracks = Object.assign({}, kVideoState.remote_tracks)
      remote_tracks[action.from] = action.tracks
      kVideoState.remote_tracks = remote_tracks
      return kVideoState;
    }

    //{ id: track.id, kind: track.kind, origin: this.from }
    case KVideoActionTypes.AddTrack: {
      return addTrack(state, action)
    }
    default: {
      return state;
    }
  }

}
