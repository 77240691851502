/*
(C)2020 Sergio A. Fernandez, Ph.D.



*/


export interface ChatState {
  chat: any
  open_chat: boolean
  chat_msgs: any[]
  chat_id: string | undefined
}

export const initialChatState: ChatState = {
  chat: {},
  open_chat: false,
  chat_msgs: [],
  chat_id: undefined
};
