/*
(C)2020 Sergio A. Fernandez, Ph.D.



*/


export interface ControlsState {

  btn_back_color: string,
  btn_text_color: string,
  // feed_back: any,
  link_option: number
}

export const initialControlsState: ControlsState = {
  btn_back_color: "#FFCE38",
  btn_text_color: "black",
  // feed_back: {},
  link_option: 0
};
