import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from 'src/app/chapala-mls/chapala-mls-service';
import { SetSearch } from 'src/app/chapala-mls/chapala-mls.actions';
import { ChapalaMLSState } from 'src/app/chapala-mls/chapala-mls.model';
import { gotMLSMsg } from 'src/app/chapala-mls/chapala-mls.selectors';
import { SelectorComponent } from '../selector.component';

@Component({
  selector: 'mls-max-price',
  templateUrl: '../selector.component.html',
  styleUrls: ['../selector.component.scss']

})
export class MaxPriceComponent extends SelectorComponent {
  values = [
    undefined,
    "500000",
    "600000",
    "700000",
    "800000",
    "900000",
    "1000000",
    "1250000",
    "1500000",
    "1750000",
    undefined,
  ]
  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);
    console.log("categories")


    this.setTitle("Maximun")

    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.select) {
          if (msg.select.hasOwnProperty("Maximun")) {
            let index = msg.select["Maximun"]
            let price = this.values[index];
            this.chapalaMLState.dispatch(new SetSearch("max_price", price))
          }
        }
      }
    }))
    this.options = [
      "No Max",
      "$ 500,000",
      "$ 600,000",
      "$ 700,000",
      "$ 800,000",
      "$ 900,000",
      "$ 1M",
      "$ 1.25M",
      "$ 1.5M",
      "$ 1.75M",
      "$ Any Price",
    ]


  }
  selOption(i: number) {
    super.selOption(i)
  }
}
