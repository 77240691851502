import { Component, Input, } from '@angular/core';
import { Store } from '@ngrx/store';
import { CalendarState } from '../../calendar.model';
import { changedHourSlots, weekSlots } from '../../calendar.selectors';
import { CalendarComponent } from '../calendar.component';
import { formatDate } from '@angular/common';
import { CalendarService } from '../../calendar.service';
import { isSameDay } from 'date-fns';
import { AddAppointment } from '../../calendar.actions';
@Component({
  selector: 'app-week',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.scss']
})
export class WeekComponent extends CalendarComponent {

  hours: any[] | undefined
  week_hours: any | undefined
  week_slots: any[] | undefined
  from_date: Date | undefined
  to_date: Date | undefined
  border = "1px solid #FFCE38";
  _date: Date = new Date()
  // @Input() set date(d: Date) {
  //
  //   if (!isSameDay(this._date, d)) {
  //     this._date = d;
  //     this.setFromTo()
  //   }
  // }

  ngOnInit() {
    console.log("week")
    this.setFromTo()
  }
  ngAfterViewInit() {
    this.subscriptions.push(this.calendarStore.select(changedHourSlots).subscribe((hour_slots: any[]) => {
      if (hour_slots) {
        this.hour_slots = hour_slots
        this.setSlotsInHours()

      }
    }))
    this.subscriptions.push(this.calendarStore.select(weekSlots).subscribe((week_slots: any) => {
      this.week_slots = week_slots
      this.setSlotsInHours()
    }))

  }
  setFromTo() {
    let new_time = this._date.getTime()
    let date = new Date(new_time)
    date.setHours(0, 0, 0, 0);
    let from = date.getTime()
    let day = date.getDay()
    from -= day * 24 * 60 * 60 * 1000
    console.log("from" + new Date(from))
    let to = (from + 7 * 24 * 60 * 60 * 1000) - 1
    this.from_date = new Date(from)
    this.to_date = new Date(to)
    this.calendar_service.getWeekEvents(from, to)
  }
  setSlotsInHours() {
    if (!this.hour_slots || !this.week_slots) {
      return;
    }
    let week_hours = []

    for (let d = 0; d < this.week_slots.length; d++) {
      let day_hours = []
      let day_slots: any[] = this.week_slots[d].slots
      let from = day_slots[0].time
      for (let h = 0; h < this.hour_slots.length; h++) {
        let to = from + 60 * 60 * 1000
        let slots_in_hour: any[] = []
        let l = day_slots.length
        day_slots.forEach((s) => {

          if (s.time >= from && s.time < to) {
            let dif = s.time - from
            let top = dif / (15 * 60 * 1000) * 25
            let new_s = Object.assign({}, s)
            new_s.top = top;
            new_s.height = s.height / 20 * 25
            slots_in_hour.push(new_s)
          }
        })
        day_hours.push(slots_in_hour)
        from = to;
      }
      week_hours.push(day_hours)
    }
    this.week_hours = week_hours
    // if (week_hours.length) {
    //   this.week_hours = week_hours
    //   let fs = this.week_hours[0][0][0]
    //   let ts = this.week_hours[this.week_hours.length - 1][0][0]
    //   this.from_date = new Date(fs.time)
    //   this.to_date = new Date(ts.time)
    // }
    setTimeout(() => this.hours = this.hour_slots)
  }

  getHourSlot(row: number, col: number) {
    if (this.week_hours) {
      let d = col - 1
      if (this.week_hours.length > d) {
        let day_hours = this.week_hours[d]
        if (day_hours.length > col) {
          if (row == 5 && col == 6) {
            // console.log("the one " + JSON.stringify(day_hours[col]))
          }
          return day_hours[row]
        }
      }
    }
  }

  getContent(row: number, col: number) {
    if (col == 0) {
      if (this.hour_slots) {
        if (this.hour_slots.length > row) {
          let date = new Date(this.hour_slots[row].time)
          try {
            return formatDate(date, 'hh a', this.language, "-" + this.time_zone);
          } catch (e: any) {
            this.language = 'en-US'
            return formatDate(date, 'hh a', this.language, "-" + this.time_zone);
          }

        }
      }
    }
    return row + "," + col
  }
  addAppointment(slot: any) {
    this.calendarStore.dispatch(new AddAppointment(slot))
  }
}
