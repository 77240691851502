<div class="out" [@OpenClose]="state">
  <div class="container">
    <div class="round" *ngIf="show_in=='tools' && api_iframe_url" [ngClass]="{hide:!bShowAPI}">
      <iframe #iframe class="iframe" [src]="api_iframe_url" safe></iframe>
    </div>
    <div class="round" *ngIf="!bShowAPI">
      <div class="line">

        <input kzInput class="search" type="name" placeholder="Search assets or input url " [(ngModel)]="asset_name" (ngModelChange)="changedSearch()">

      </div>
      <div class="line">
        <k-filter [exterior]="true" background="white" [min_width]="120" [min_opts_width]="163" [options]="options" class="asset_type" title="Filter Asset" (selected)="selected($event)"></k-filter>
        <div class="add_new"  kzLib (click)="bAskAorF=!bAskAorF">
          Add New <img class="exp exp_t" [ngClass]="{colapse:bAskAorF}" src="assets/images/expand.svg">
        </div>

        <div class="space">

        </div>
      </div>
      <div class="bdy" *ngIf="!bAddingAsset" [ngClass]="{bdy_w_back:folder}">
        <sel-asset class="asset" [asset]="assets[key]" *ngFor="let key of asset_keys, let i = index" [index]="i" (selectedCahnged)="selectAsset(i)"></sel-asset>
        <div *ngIf="asset_keys.length==0 && asset_name.length>0" class="abs_center">
          No Assets Found
        </div>
      </div>
      <div class=" btn_line" *ngIf="!bAddingAsset">
        <div class="fill">
        </div>


        <k-button *ngIf="folder" class="folder_back" background="transparent" icon="folder_back.svg"  kzLib (click)="backFromFolder()"></k-button>
        <div *ngIf="folder" class="folder_btx"  kzLib (click)="backFromFolder()">
          Back
        </div>

      </div>
    </div>
    <div class="expand" cdkDrag>
      <div class="exp_bdy"  kzLib (click)="toggleOpen()">
        <img *ngIf="this.state == 'closed'" class="expand_i" src="assets/images/expand_l.svg">
        <img *ngIf="this.state != 'closed'" class="expand_i expand_b" src="assets/images/expand_l.svg">
      </div>
    </div>
    <div *ngIf="bAddingAsset" class="top_line1"> </div>
    <new-tab-asset class="new_asset" *ngIf="bAddingAsset" (close)="bAddingAsset = false"></new-tab-asset>

  </div>
  <asset-or-folder *ngIf="bAskAorF" (addAsset)="addAsset()" (addFolder)="addFolder()" class="a_or_f"></asset-or-folder>
  <img class="api_open_close" [ngClass]="{api_open:!bShowAPI}" *ngIf="api_iframe_url && state == 'open'" [src]="company_icon"  kzLib (click)="showHideAPI()">
  <asset-preview *ngIf="preview_asset" [asset]="preview_asset" (close)="closePreview()" class="preview"></asset-preview>

</div>
