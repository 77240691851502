import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from "@ngrx/store";
import { PagesState } from 'src/app/pages/pages.model';
import { customizeAccount } from 'src/app/pages/pages.selectors';

@Component({
  selector: 'sel-color',
  templateUrl: './sel-color.component.html',
  styleUrls: ['./sel-color.component.scss']
})
export class SelColorComponent implements OnInit {
  @Input() name: string = ""
  subscriptions: any = [];

  constructor(
    private pagesState: Store<PagesState>,

  ) {
    this.subscriptions.push(this.pagesState.select(customizeAccount).subscribe((customize: any) => {
      if (customize) {
        if (customize.btn_back && this.name == "buton_back") {
          this.color = customize.btn_back
        }
        if (customize.btn_color && this.name == "buton_text") {
          this.color = customize.btn_color
        }
      }
    }))
  }
  color: string = "#8F5DFF"
  @Input() set value(c: string) {
    this.color = c
    console.log("sel-color " + this.color)
  }
  @Output() valueChange: EventEmitter<string> = new EventEmitter()
  ngOnInit(): void {
    // if (this.name == "buton_back") {
    //   this.color = "#FFCE38"
    // } else if (this.name == "buton_text") {
    //   this.color = "black"
    // }

  }
  modelChanged($event: any) {
    this.valueChange.emit(this.color)
  }
}
