import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  _background: string = "#F5423B";
  @Input() set ok(val: string) {
    if (val == "ok") {
      this._background = "#40BE5C"
    } else if (val == "ask") {
      this._background = "#FFCE38"
    } else if (val == "no") {
      this._background = "#F5423B";
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
