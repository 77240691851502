import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'k-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent {
  @Input() text: string = "";
  bChecked: boolean = false
  @Input() set checked(v: boolean) {
    this.bChecked = v
  }
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter();

  check() {
    this.bChecked = !this.bChecked
    this.checkedChange.emit(this.bChecked)
  }
}
