import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulingComponent } from './scheduling/scheduling.component';
import { ControlsModule } from 'src/app/controls/controls.module';
import { FormsModule } from '@angular/forms';
import { CalendarComponent } from './calendar/calendar.component';
import { MonthComponent } from './calendar/month/month.component';
import { WeekComponent } from './calendar/week/week.component';
import { DayComponent } from './calendar/day/day.component';
import { StoreModule } from '@ngrx/store';
import * as fromCalendar from './calendar.reducer';
import { CreateEventComponent } from './create-event/create-event.component';
import { CalSettingsComponent } from './cal-settings/cal-settings.component';
import { HoursComponent } from './calendar/hours/hours.component';
import { HoursAllComponent } from './cal-settings/hours-all/hours-all.component';
import { ApptsWndComponent } from './appts-wnd/appts-wnd.component';
import { ChangeTzComponent } from './change-tz/change-tz.component';
import { CalendlySettingsComponent } from './calendly-settings/calendly-settings.component';
import { KzLibModule } from 'src/app/kz-lib/kz-lib.module';


@NgModule({
  declarations: [
    SchedulingComponent,
    CalendarComponent,
    MonthComponent,
    WeekComponent,
    DayComponent,
    CreateEventComponent,
    CalSettingsComponent,
    HoursComponent,
    HoursAllComponent,
    ApptsWndComponent,
    ChangeTzComponent,
    CalendlySettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ControlsModule,
    KzLibModule,
    StoreModule.forFeature('calendar', fromCalendar.calendarReducer),

  ], exports: [
    SchedulingComponent,
    CreateEventComponent,
    ApptsWndComponent,
    CalSettingsComponent,
    ChangeTzComponent,
    CalendlySettingsComponent
  ]
})
export class SchedulingModule { }
