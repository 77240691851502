import { Component, EventEmitter, Input, Output } from '@angular/core';
import { changedSlots } from '../../calendar.selectors';
import { CalendarComponent } from '../calendar.component';

@Component({
  selector: 'hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.scss']
})
export class HoursComponent extends CalendarComponent {
  slots: any[] | undefined;
  checked: boolean[] = []
  first: number = -1
  from_to: any

  // @Input() value: number = 5
  @Output() close: EventEmitter<void> = new EventEmitter();


  @Input() set times(t: any) {
    this.from_to = t;
    this.checkFromTo(this.from_to.from, this.from_to.to)
  }
  @Output() timesChange: EventEmitter<any> = new EventEmitter();
  ngOnInit() {

    console.log("hour")
    this.subscriptions.push(this.calendarStore.select(changedSlots).subscribe((slots: any[]) => {
      if (slots) {
        this.slots = slots
        this.checkFromTo(this.from_to.from, this.from_to.to)
      }
    }))
  }
  getDate(time: number) {
    return new Date(time)
  }
  uncheckAll() {
    if (this.slots) {
      for (let i = 0; i < this.slots.length; i++) {
        this.checked[i] = false
      }
    }
  }
  check($event: any, i: number) {
    if (this.slots) {
      let slot = this.slots[i]
      if (slot.status != 'f') {
        return
      }
    }
    if ($event.shiftKey == false || this.first == -1) {
      this.checkFromTo(i, i)
      this.first = i
      this.timesChange.emit({ from: i, to: i })
    } else {
      let last = i
      if (last < this.first) {
        let t = last
        last = this.first
        this.first = t
      }
      this.checkFromTo(this.first, last)
      this.timesChange.emit({ from: this.first, to: last })
    }
    // if ($event.shiftKey) {
    //   this.close.emit()
    // }
  }
  checkFromTo(from: number, to: number) {
    if (from == to) {
      if (from >= 0) {
        this.uncheckAll()
        if (this.slots) {
          let slot = this.slots[from]
          if (slot) {
            if (slot.status == 'f') {
              this.checked[from] = true
              this.first = from
            }
          }
        }
        return
      }
    }
    if (from >= 0) {
      if (this.slots) {
        for (let i = to; i >= from; i--) {
          let slot = this.slots[i]
          if (slot) {
            if (slot.status != 'd') {
              this.checked[i] = true
            } else {
              break;
            }
          } else {
            console.error("No slot in " + i)
          }
        }
      }
    }
  }
}
