import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCC } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { GotWorkTime } from '../calendar.actions';
import { CalendarState } from '../calendar.model';
import { changedWorkTime, hasCalToken } from '../calendar.selectors';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'cal-settings',
  templateUrl: './cal-settings.component.html',
  styleUrls: ['./cal-settings.component.scss']
})
export class CalSettingsComponent {
  time_zones: any[] = [];
  orig_time_zones: any[] = [];
  work_time: any
  time_zone: any = {}
  tz_index: number = 0
  time_zones_d: string[] = []
  bShowTimeZones: boolean = false
  search: string = ""

  bNeedsConnection: boolean = true;
  auth_url: string | undefined


  slot_minutes: number = 15;
  bShowTimeSlot: boolean = false
  time_slots: string[] = ["15 minutes", "30 minutes", "45 minutes"]
  ts_index = 0;

  work_from: string = "09:00 AM";
  work_to: string = "05:00 PM";
  time_from: number = 0;
  time_to: number = 0
  from_to: any = { from: -1, to: -1 }
  bShowSelTime: boolean = true
  hours_for: string = ""
  strart_from_index = 9 * 4 - 1
  strart_to_index = 17 * 4 - 1
  subscriptions: any[] = []
  bChanged: boolean = false
  constructor(
    public calendar_service: CalendarService,
    public calendarStore: Store<CalendarState>,
    public pagesState: Store<PagesState>,
  ) {
    console.log("calendar settings")
    this.subscriptions.push(this.calendarStore.select(hasCalToken).subscribe((bHasCalToken: boolean) => {
      this.bNeedsConnection = !bHasCalToken
    }))
    this.subscriptions.push(this.calendarStore.select(changedWorkTime).subscribe((work_time: any) => {
      if (work_time) {
        this.work_time = work_time
        this.work_from = work_time.from
        this.work_to = work_time.to
        if (work_time.slot_minutes) {
          this.slot_minutes = work_time.slot_minutes
          this.ts_index = Math.floor(this.slot_minutes / 5)
        }
        this.setTZIKndex()
      }
    }))

    this.loadTZ()
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  async loadTZ() {
    if (this.orig_time_zones.length == 0) {
      try {
        this.orig_time_zones = await this.calendar_service.loadTZ()
        if (this.orig_time_zones) {
          this.time_zones = Object.assign([], this.orig_time_zones)
          this.setTZIKndex()
        }
      } catch (e) {
        console.error(e)
      }
    }
  }


  setTZIKndex() {
    if (this.time_zones && this.work_time) {
      for (this.tz_index = 0; this.tz_index < this.time_zones.length; this.tz_index++) {
        let tz = this.time_zones[this.tz_index]
        if (tz.abbr == this.work_time.abbr) {
          if (tz.offset * 60 == this.work_time.offset) {
            this.time_zone = tz
            break;
          }
        }
      }
    }
  }

  selTimeZone(i: number, tz: any) {
    this.tz_index = i;
    // let tz = this.time_zones[this.tz_index]
    this.time_zone = tz
    this.bShowTimeZones = false;
    this.bChanged = true
  }
  async linkCal() {
    try {
      this.auth_url = await this.calendar_service.getGoogleCalendarAuthURL()
      window.location.href = this.auth_url
      console.log("auth_url " + this.auth_url)
    } catch (e) {
      console.error("error getting auth_url " + e)
    }
  }
  toogleTZ() {
    this.bShowTimeZones = !this.bShowTimeZones
    this.bShowTimeSlot = false;
    if (this.bShowTimeZones) {
      this.time_zones = Object.assign([], this.orig_time_zones)

    }
  }
  toogleTS() {
    this.bShowTimeSlot = !this.bShowTimeSlot
    this.bShowTimeZones = false
  }
  durationChanged() {
    let i = Math.floor(this.slot_minutes / 15)
    this.slot_minutes = i * 15
    this.strart_from_index = 9 * 60 / this.slot_minutes - 1
    this.strart_to_index = 17 * 60 / this.slot_minutes - 1
    this.bChanged = true
  }
  /*
    selTimeSlot(i: number) {
      this.slot_minutes = (i + 1) * 15
      this.ts_index = i
      this.bShowTimeSlot = false;

    }
    */
  async updateTime() {
    let tz: any = this.time_zone//this.orig_time_zones[this.tz_index]

    let jtime = {
      from: this.work_from,
      to: this.work_to,
      zone: tz.text,
      offset: tz.offset * 60,
      slot_minutes: this.slot_minutes,
      abbr: tz.abbr
    }
    try {
      await this.calendar_service.setWorkTime(jtime)
      this.pagesState.dispatch(new ShowCC(""))
    } catch (e) {
      console.error(e)
    }
  }
  changedSearch($event: string) {
    this.time_zones = []
    let search = $event.toLowerCase()
    for (let i = 0; i < this.orig_time_zones.length; i++) {
      let tz = this.orig_time_zones[i]
      let ltx = tz.text.toLowerCase()
      let index = ltx.indexOf(search)
      if (index >= 0) {
        this.time_zones.push(tz)
      }
    }


  }
  setSlotMinutes(min: number) {
    this.slot_minutes = min
    this.bChanged = true
  }
  timesChange($event: any) {
    this.bChanged = true
    if (this.hours_for == 'from') {
      this.work_from = $event.lbl
      this.strart_from_index = $event.from - 1
    } else {
      this.work_to = $event.lbl
      this.strart_to_index = $event.from - 1
    }
    this.hours_for = ""
  }
  toogleHours($event: any, what: string) {
    $event.stopPropagation()
    if (this.hours_for == what) {
      this.hours_for = ""
    } else {
      this.hours_for = what
    }
  }
  outside() {
    this.hours_for = ""
  }
  closeIt() {
    this.pagesState.dispatch(new ShowCC(""))

  }
}
