import * as store from '@ngrx/store';
/*
The action that have to do with communications
*/
export enum ContactsActionTypes {

  GotContacts = '[contacts]got ',

}
export class GotContacts implements store.Action {
  readonly type = ContactsActionTypes.GotContacts;
  constructor(public rv: any) { }
}

export type ContactsAction =
  GotContacts


  ;
