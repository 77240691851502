import { Component } from '@angular/core';
import { GetInitials, NewInitials } from '../../canvas.actions';
import { changeInitials, changeSignature, getInitials } from '../../canvas.selectors';
import { GetSignatureComponent } from '../get-signature/get-signature.component';

@Component({
  selector: 'get-initials',
  templateUrl: './get-initials.component.html',
  styleUrls: ['../get-signature/get-signature.component.scss']
})
export class GetInitialsComponent extends GetSignatureComponent {
  ngAfterViewInit() {
    this.intput_placeholder = "Enter Initials"
    if (this.inName) {
      this.inName.nativeElement.focus()
    }
    this.subscriptions.push(this.canvasStore.select(changeSignature).subscribe((data: any | undefined) => {

      setTimeout(() => {
        this.draw_or_type = 'Draw'
        this.bTypeSignature = false

        if (data && data.svg && data.svg.length > 1) {
          if (data.text_initials) {
            this.name = data.text_initials
            this.draw_or_type = 'Type'
            this.bTypeSignature = true
            setTimeout(() => {
              this.changedName()
            }, 100)
          }
        }
      })

    }))
    this.subscriptions.push(this.canvasStore.select(getInitials).subscribe((group_id: string | undefined) => {
      this.group_id = group_id
    }))

    this.subscriptions.push(this.canvasStore.select(changeInitials).subscribe((data: any | undefined) => {
      if (data && data.svg && data.svg.length > 1) {
        setTimeout(() => {
          this.svg = data.svg
          this.out_svg = this.svg
        })
      }
    }))

  }
  changedSVG($event: any) {
    this.out_svg = $event
    console.log("changedSVG " + this.num_strokes + " >= 1")
    if (++this.num_strokes > 0) {
      this.done_back = "#151515"
      this.done_color = "#fff"
      this.bSigReady = true
    }
  }
  doneInitials() {
    if (this.group_id) {
      let rect: any = { //FROM .signature
        top: 60,
        left: 30,
        width: 490,
        height: 270
      }

      if (this.out_svg.width) {
        rect = { //FROM .signature
          top: this.out_svg.top,
          left: this.out_svg.left,
          width: this.out_svg.width,
          height: this.out_svg.height
        }
      }
      this.canvasStore.dispatch(new NewInitials(
        this.group_id,
        this.out_svg.svg,
        rect))
      console.log("Closed get initials 1 ")

      this.canvasStore.dispatch(new GetInitials(undefined))
      // this.close.emit()
    }
  }
  changedName() {
    this.bShowFonts = false
    let parts = this.name.split(' ')
    if (parts.length > 0) {
      this.text_initials = parts[0].substring(0, 1)
    }
    if (parts.length > 1) {
      this.text_initials += " " + parts[1].substring(0, 1)
    }
    if (this.text_initials) {
      this.drawText(this.text_initials)
    }
  }
  cancel() {
    this.canvasStore.dispatch(new GetInitials(undefined))
    // this.canvasStore.dispatch(new SelectTool(""))
  }
}
