<div class="out">
  <div class="frame">
    <div class="all">
    </div>
    <div class="progress" [style.width.%]="_progress">
    </div>
    <div class="ball" [style.left.%]="_progress"></div>
    <div class="scr" #outComponent>
    </div>
  </div>
  <div class="text">
    {{_progress}} %
  </div>
</div>
