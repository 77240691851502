<div class="scrn">
  <div class="abs_center out">
    <div class=" title font_title top">Settings</div>
    <div class="body">

      <!-- <div>
      <div class="line font_sub_title"  kzLib (click)="enableCallrDraw()">
        <div class="check" [ngClass]="{selected:caller_draw}"></div>
        Enable draw tool for caller
      </div>
    </div> -->
      <div *ngIf="video_devs.length>1">
        <div class="title font_title">Select Camera</div>
        <div class="line font_sub_title " *ngFor="let dev of video_devs" kzLib (click)="changedVideo(dev)">
          <div class="check" [ngClass]="{selected:selected_vdev_id==dev.deviceId}"></div>
          {{dev.label}}
        </div>
      </div>
      <div *ngIf="audio_devs.length>1">
        <div class="title font_title">Select Microphone</div>
        <div class="line font_sub_title " *ngFor="let dev of audio_devs" kzLib (click)="changedAudio(dev)">
          <div class="check" [ngClass]="{selected:selected_adev_id==dev.deviceId}"></div>
          {{dev.label}}
        </div>
      </div>
      <div *ngIf="audio_out_devs.length>1">
        <div class="title font_title">Select Speaker</div>
        <div class="line font_sub_title " *ngFor="let dev of audio_out_devs" kzLib (click)="changedOutAudio(dev)">
          <div class="check" [ngClass]="{selected:selected_a_out_dev_id==dev.deviceId}"></div>
          {{dev.label}}
        </div>
      </div>
    </div>

    <k-button class="close" icon="close.svg" background="transparent" kzLib (click)="close()"></k-button>
  </div>
</div>
