import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { CalendarState } from '../calendar.model';
import { changedTZ, changedWorkTime } from '../calendar.selectors';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'change-tz',
  templateUrl: './change-tz.component.html',
  styleUrls: ['./change-tz.component.scss']
})
export class ChangeTzComponent {
  subscriptions: any[] = []
  time_zone: any = {}
  work_time: any
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private calendarStore: Store<CalendarState>,
    private calendar_service: CalendarService,
  ) {
    console.log('change-tz')
    this.subscriptions.push(this.calendarStore.select(changedTZ).subscribe((tz: any) => {
      this.time_zone = tz
    }))
    this.subscriptions.push(this.calendarStore.select(changedWorkTime).subscribe((work_time: any) => {
      if (work_time) {
        this.work_time = work_time
      }
    }))
  }
  async change() {
    if (this.work_time) {
      let work_time = Object.assign({}, this.work_time)
      work_time.offset = this.time_zone.offset * 60;
      work_time.zone = this.time_zone.text
      work_time.abbr = this.time_zone.abbr
      try {
        await this.calendar_service.setWorkTime(work_time)
        this.close.emit()
      } catch (e) {
        console.error(e)
      }
    }
  }
  cancel() {
    localStorage.setItem('last_no_ask', this.time_zone.text)
    this.close.emit()
  }
}
