/*
Tha actions that have to do with the pages UI
*/

import * as store from '@ngrx/store';

export enum PagesActionTypes {
  //Tools
  IsPhone = '[phone]yes_no',
  GotLoginData = '[login]data',
  GotQueue = '[queue]',
  CallWaiting = '[call]waiting',
  RejectedCall = '[call]rejected',
  CleanOnHangUp = '[call]clean',
  CallerData = '[caller]data',
  // CallerEmail = '[caller]email',
  SetAssets = '[assets]set',
  SetAssetsAndContracts = '[assets & contracts]set',
  ActiveCalls = '[calls]acrive',
  GotUsers = '[user] ',
  GotAccount = '[account] got',

  ShowAsset = '[asset]show',
  ShowFolder = '[filder]show',
  IAmManager = '[manager]yes or no', //on when we join or manage, off when call_id is undefined
  GotContracts = '[contracts]got',
  SetManagerName = '[manager]name',
  SetManagerEmail = '[manager]email',

  SetRepName = '[rep]name',
  SetRepEmail = '[rep]email',
  StartRecording = '[recording]start',
  Recording = '[recording]on',
  StopRecording = '[recording]stop',
  RecordingReady = '[recording]ready',

  Debuggin = "[pages]debug",

  SetHeaderTitle = '[header]title',
  NotifySnd = '[notify]snd', //This is the ring or silence on call
  NotifyUser = '[notify]usr',
  NotifyError = '[notify]error',
  NotifyWait = '[notify]wait',
  ShowError = '[notify]shpw',
  ContactInfo = '[contact]info',
  RemoveUserProfPic = '[prof pic]del',
  SetCustomizaton = '[account] customization',
  CallerIsPhone = '[caller]is_phone',
  UserRoll = '[user]roll',
  OpenMenu = '[menu]open',
  GotMyPicture = '[pic]mine',
  SetAPIParams = '[api]params',
  ShowAPI = '[api]show',
  SelFilterOption = '[filter]opts',
  FilterAssets = '[assets]filter',
  UnselectAllAssets = '[asssets]unselect',
  AddAsset = '[asset]add',

  RemoveAssets = '[assets]remove',
  SetSelAssets = '[assets]setsel',
  MoveAssets = '[assets]move',
  UpdateAsset = '[asset]update',
  SetTab = '[tab]sel',
  SearchBy = '[search]by',
  SetFoldersForTab = '[tab folders]sel',
  ShowWelcome = '[welcome]show',
  InPhone = '[in_phone]',
  CloseProgress = '[progress]close',
  ApiUrlPArams = '[api_utr]params',
  CodeFromURL = '[promo]urlencoded',
  // AskCodeFrom = '[promo]ask',
  PlayRec = '[play]rec',
  SetCalendlyRep = '[calendly]set',
  ShowCC = '[cc]show',
  GotInvoices = '[invoices]got',
  AskHangUp = '[ask]hangup',
  GotConverge = '[converge]getAccount',
  GotBillInfo = '[bill_info]got',
  GotPlans = '[plans]got',
  GotBillingInfo = '[bill_info]got',
  GotCode = '[code]got',
  ShowToolAddAsset = '[asset]showadd',
  AccOverdue = '[acc]overdue',
  ShowActiveCalls = '[calls]active',
  OnBoarding = '[on_board]yn'

}
export class OnBoarding implements store.Action {
  readonly type = PagesActionTypes.OnBoarding;
  constructor(public on_boarding: boolean) { }
}
export class ShowActiveCalls implements store.Action {
  readonly type = PagesActionTypes.ShowActiveCalls;
  constructor(public num_calls: number) { }
}
export class AccOverdue implements store.Action {
  readonly type = PagesActionTypes.AccOverdue;
  constructor(public overdue: boolean) { }
}
export class ShowToolAddAsset implements store.Action {
  readonly type = PagesActionTypes.ShowToolAddAsset;
  constructor(public show: boolean) { }
}
export class GotBillingInfo implements store.Action {
  readonly type = PagesActionTypes.GotBillingInfo;
  constructor(public bill_info: any[]) { }
}
export class GotCode implements store.Action {
  readonly type = PagesActionTypes.GotCode;
  constructor(public code: any[]) { }
}
export class GotPlans implements store.Action {
  readonly type = PagesActionTypes.GotPlans;
  constructor(public plans: any[]) { }
}
export class GotBillInfo implements store.Action {
  readonly type = PagesActionTypes.GotBillInfo;
  constructor(public bill_info: any) { }
}
export class GotConverge implements store.Action {
  readonly type = PagesActionTypes.GotConverge;
  constructor(public converge: any) { }
}
export class AskHangUp implements store.Action {
  readonly type = PagesActionTypes.AskHangUp;
  constructor(public ask: boolean) { }
}
export class GotInvoices implements store.Action {
  readonly type = PagesActionTypes.GotInvoices;
  constructor(public invoices: any) { }
}
export class ShowCC implements store.Action {
  readonly type = PagesActionTypes.ShowCC;
  constructor(public show: string | undefined) { }
}
export class SetCalendlyRep implements store.Action {
  readonly type = PagesActionTypes.SetCalendlyRep;
  constructor(public calendly: string) { }
}
export class PlayRec implements store.Action {
  readonly type = PagesActionTypes.PlayRec;
  constructor(public play_rec: any) { }
}
export class CodeFromURL implements store.Action {
  readonly type = PagesActionTypes.CodeFromURL;
  constructor(public code_from_url: string) { }
}
// export class AskCodeFrom implements store.Action {
//   readonly type = PagesActionTypes.AskCodeFrom;
//   constructor(public ask_code_from?: string) { }
// }
export class ApiUrlPArams implements store.Action {
  readonly type = PagesActionTypes.ApiUrlPArams;
  constructor(public api_url_params: string) { }
}
export class CloseProgress implements store.Action {
  readonly type = PagesActionTypes.CloseProgress;
  constructor() { }
}
export class InPhone implements store.Action {
  readonly type = PagesActionTypes.InPhone;
  constructor(public who: string) { }
}
export class ShowWelcome implements store.Action {
  readonly type = PagesActionTypes.ShowWelcome;
  constructor(public who: string) { }
}
export class UpdateAsset implements store.Action {
  readonly type = PagesActionTypes.UpdateAsset;
  constructor(public asset: any) { }
}
export class SetFoldersForTab implements store.Action {
  readonly type = PagesActionTypes.SetFoldersForTab;
  constructor(public bAdmin: boolean) { }
}
export class SetTab implements store.Action {
  readonly type = PagesActionTypes.SetTab;
  constructor(public tab: string) { }
}
export class SearchBy implements store.Action {
  readonly type = PagesActionTypes.SearchBy;
  constructor(public search: string) { }
}
export class AddAsset implements store.Action {
  readonly type = PagesActionTypes.AddAsset;
  constructor(public asset: any) { }
}
export class SetSelAssets implements store.Action {
  readonly type = PagesActionTypes.SetSelAssets;
  constructor(public asset_ids: string[]) { }
}
export class RemoveAssets implements store.Action {
  readonly type = PagesActionTypes.RemoveAssets;
  constructor() { }
}
export class MoveAssets implements store.Action {
  readonly type = PagesActionTypes.MoveAssets;
  constructor(public target_id: string, public asset_ids: string[]) { }
}
export class UnselectAllAssets implements store.Action {
  readonly type = PagesActionTypes.UnselectAllAssets;
  constructor() { }
}
export class FilterAssets implements store.Action {
  readonly type = PagesActionTypes.FilterAssets;
  constructor(public tab: string, public search: string) { }
}
export class SelFilterOption implements store.Action {
  readonly type = PagesActionTypes.SelFilterOption;
  constructor(public filter_options: any) { }
}

export class ShowAPI implements store.Action {
  readonly type = PagesActionTypes.ShowAPI;
  constructor(public show: boolean) { }
}
export class SetAPIParams implements store.Action {
  readonly type = PagesActionTypes.SetAPIParams;
  constructor(public api_params: any) { }
}
export class GotMyPicture implements store.Action {
  readonly type = PagesActionTypes.GotMyPicture;
  constructor(public picture: string) { }
}
export class OpenMenu implements store.Action {
  readonly type = PagesActionTypes.OpenMenu;
  constructor(public open: boolean) { }
}
export class UserRoll implements store.Action {
  readonly type = PagesActionTypes.UserRoll;
  constructor(public roll: string) { }
}
export class CallerIsPhone implements store.Action {
  readonly type = PagesActionTypes.CallerIsPhone;
  constructor(public is_phone: boolean) { }
}
export class SetCustomizaton implements store.Action {
  readonly type = PagesActionTypes.SetCustomizaton;
  constructor(public customize: any) { }
}
export class RemoveUserProfPic implements store.Action {
  readonly type = PagesActionTypes.RemoveUserProfPic;
  constructor() { }
}
export class ContactInfo implements store.Action {
  readonly type = PagesActionTypes.ContactInfo;
  constructor(public state: string) { }
}
export class NotifyWait implements store.Action {
  readonly type = PagesActionTypes.NotifyWait;
  constructor(public text: string | undefined) { }
}

export class ShowError implements store.Action {
  readonly type = PagesActionTypes.ShowError;
  constructor(public text: string | undefined) { }
}
export class NotifyUser implements store.Action {
  readonly type = PagesActionTypes.NotifyUser;
  constructor(public text: string | undefined) { }
}
export class NotifyError implements store.Action {
  readonly type = PagesActionTypes.NotifyError;
  constructor(public text: string | undefined) { }
}
export class NotifySnd implements store.Action {
  readonly type = PagesActionTypes.NotifySnd;
  constructor(public on: boolean) { }
}

export class SetHeaderTitle implements store.Action {
  readonly type = PagesActionTypes.SetHeaderTitle;
  constructor(public path: any[]) { }
}

export class Debuggin implements store.Action {
  readonly type = PagesActionTypes.Debuggin;
  constructor(public debug: string) { }
}
export class StartRecording implements store.Action {
  readonly type = PagesActionTypes.StartRecording;
  constructor() { }
}
export class Recording implements store.Action {
  readonly type = PagesActionTypes.Recording;
  constructor() { }
}
export class StopRecording implements store.Action {
  readonly type = PagesActionTypes.StopRecording;
  constructor() { }
}
export class RecordingReady implements store.Action {
  readonly type = PagesActionTypes.RecordingReady;
  constructor() { }
}
export class SetManagerName implements store.Action {
  readonly type = PagesActionTypes.SetManagerName;
  constructor(public name: string) { }
}
export class SetManagerEmail implements store.Action {
  readonly type = PagesActionTypes.SetManagerEmail;
  constructor(public email: string) { }
}
export class SetRepName implements store.Action {
  readonly type = PagesActionTypes.SetRepName;
  constructor(public name: string) { }
}
export class SetRepEmail implements store.Action {
  readonly type = PagesActionTypes.SetRepEmail;
  constructor(public email: string) { }
}
export class GotContracts implements store.Action {
  readonly type = PagesActionTypes.GotContracts;
  constructor(public rv: any) { }
}

export class IAmManager implements store.Action {
  readonly type = PagesActionTypes.IAmManager;
  constructor(public manager: boolean) { }
}

export class ShowAsset implements store.Action {
  readonly type = PagesActionTypes.ShowAsset;
  constructor(public asset: any) { }
}
export class ShowFolder implements store.Action {
  readonly type = PagesActionTypes.ShowFolder;
  constructor(public folder_id?: string | undefined) { }
}

export class GotAccount implements store.Action {
  readonly type = PagesActionTypes.GotAccount;
  constructor(public account: any) { }
}
export class GotUsers implements store.Action {
  readonly type = PagesActionTypes.GotUsers;
  constructor(public account: any) { }
}
export class ActiveCalls implements store.Action {
  readonly type = PagesActionTypes.ActiveCalls;
  constructor(public calls: any[]) { }
}

export class SetAssets implements store.Action {
  readonly type = PagesActionTypes.SetAssets;
  constructor(public assets: any[]) { }
}
export class SetAssetsAndContracts implements store.Action {
  readonly type = PagesActionTypes.SetAssetsAndContracts;
  constructor(public assets: any[]) { }
}
export class CleanOnHangUp implements store.Action {
  readonly type = PagesActionTypes.CleanOnHangUp;
  constructor() { }
}
export class IsPhone implements store.Action {
  readonly type = PagesActionTypes.IsPhone;
  constructor(public yes: boolean) { }
}
export class GotLoginData implements store.Action {
  readonly type = PagesActionTypes.GotLoginData;
  constructor(public data: any) { }
}

export class CallWaiting implements store.Action {
  readonly type = PagesActionTypes.CallWaiting;
  constructor(public call_waiting: any) { }
}
export class RejectedCall implements store.Action {
  readonly type = PagesActionTypes.RejectedCall;
  constructor(public call_rejected: any) { }
}
export class GotQueue implements store.Action {
  readonly type = PagesActionTypes.GotQueue;
  constructor(public queue: any[]) { }
}
export class CallerData implements store.Action {
  readonly type = PagesActionTypes.CallerData;
  constructor(public data: any) { }
}
// export class CallerEmail implements store.Action {
//   readonly type = PagesActionTypes.CallerEmail;
//   constructor(public email: string) { }
// }


export type PagesAction =
  IsPhone
  | GotLoginData
  | CallWaiting
  | RejectedCall
  | GotQueue
  | CleanOnHangUp
  | CallerData
  // | CallerEmail
  | SetAssets
  | SetAssetsAndContracts

  | ActiveCalls
  | GotUsers
  | GotAccount
  | ShowAsset
  | ShowFolder
  | IAmManager
  | GotContracts
  | SetManagerName
  | SetManagerEmail
  | StartRecording
  | Recording
  | StopRecording
  | RecordingReady
  | SetRepName
  | SetRepEmail
  | Debuggin

  | SetHeaderTitle
  | NotifySnd
  | NotifyUser
  | NotifyError
  | NotifyWait
  | ShowError
  | ContactInfo
  | RemoveUserProfPic
  | SetCustomizaton
  | CallerIsPhone
  | UserRoll
  | OpenMenu
  | GotMyPicture
  | SetAPIParams
  | ShowAPI
  | SelFilterOption
  | FilterAssets
  | UnselectAllAssets
  | MoveAssets
  | RemoveAssets
  | SetSelAssets
  | AddAsset
  | SetTab
  | SearchBy
  | SetFoldersForTab
  | UpdateAsset
  | ShowWelcome
  | InPhone
  | CloseProgress
  | ApiUrlPArams
  | PlayRec
  | CodeFromURL
  // | AskCodeFrom
  | SetCalendlyRep
  | ShowCC
  | GotInvoices
  | AskHangUp
  | GotConverge
  | GotBillInfo
  | GotPlans
  | GotBillingInfo
  | GotCode
  | ShowToolAddAsset
  | AccOverdue
  | ShowActiveCalls
  | OnBoarding

  ;
