import { Component, HostListener, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from '../chapala-mls-service';
import { GotMLSMsg, ShowImages } from '../chapala-mls.actions';
import { ChapalaMLSState } from '../chapala-mls.model';
import { gotMLSMsg, selImg, selProperty, setImgs } from '../chapala-mls.selectors';

@Component({
  selector: 'show-imgs',
  templateUrl: './show-imgs.component.html',
  styleUrls: ['./show-imgs.component.scss']
})
export class ShowImgsComponent {
  images: any[] = []
  sel_img: number = 0;
  url_photo: any

  @Input() path: string = ""
  @Input() single: boolean = false

  subscriptions: any[] = []
  constructor(
    public sanitizer: DomSanitizer,
    private chapalaMLState: Store<ChapalaMLSState>,
    private chapala_mls_service: ChapalaMLSService,
  ) {
    console.log("show-imgs")
  }
  // @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  //    if (event.keyCode === 27) {
  //        this.close();
  //    }
  // }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngOnInit(): void {
    this.subscriptions.push(this.chapalaMLState.select(setImgs).subscribe((images: any) => {
      if (images) {
        this.images = images;
        this.setImg()
      }
    }))
    this.subscriptions.push(this.chapalaMLState.select(selImg).subscribe((sel_img: number) => {
      this.sel_img = sel_img
      this.setImg()
    }))
    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.hasOwnProperty("sel_img")) {
          this.sel_img = msg.sel_img
          this.setImg()
          return
        }
      }
    }))
  }
  setImg() {
    if (this.images && this.path) {
      if (this.images[this.sel_img]) {
        this.url_photo = this.images[this.sel_img]
      }
    }
  }
  // next($event: any) {
  //   $event.stopPropagation()
  //   if (++this.sel_img >= this.images
  //     .length) {
  //     this.sel_img = 0;
  //   }
  //   let msg: any = {
  //     sel_img: this.sel_img
  //   }
  //   this.chapala_mls_service.sendMsg(msg)
  // }
  // previous($event: any) {
  //   $event.stopPropagation()
  //   if (this.sel_img > 0) {
  //     this.sel_img--;
  //     let msg: any = {
  //       sel_img: this.sel_img
  //     }
  //     this.chapala_mls_service.sendMsg(msg)
  //
  //   }
  // }
  selImage(sel_img: number) {

    let msg: any = {
      sel_img: this.sel_img
    }
    this.chapala_mls_service.sendMsg(msg)
  }
  close() {
    let msg: any = {
      show_imgs: false
    }
    this.chapala_mls_service.sendMsg(msg)
  }
}
