<div class="out">
  <div class="block">
    <div class="round">
      <!-- <mat-icon class="tr_mono" [ngClass]="{ 'dark_mono': theme=='dark'}">person_outline</mat-icon> -->
      <img *ngIf="!img_src" src="assets/images/no_prof_pic.svg" class="tr_mono" [ngClass]="{ 'dark_mono': theme=='dark'}" kzLib (click)="file.click()">
      <!-- <img #profile_image *ngIf="img_src" [src]="img_src" class="tr_img" [ngClass]="{ 'dark_mono': theme=='dark'}" [width]="profile_width" [height]="profile_height" [style.top.px]="profile_top" [style.left.px]="profile_left"> -->
      <img #profile_image *ngIf="img_src" [src]="img_src" class="tr_img" [ngClass]="{ 'dark_mono': theme=='dark'}">
      <k-spinner *ngIf="bShowSpinner" class="abs_center"></k-spinner>
    </div>

    <div *ngIf="img_src" class="del_img" kzLib (click)="bShowDelUserWarning=true">
      <img class="abs_center" src="assets/images/white_tcan.svg" alt="Upload">
    </div>
    <div class="call_link">
      <div class="ptitle" kzLib (click)="copyRepLink()">
        Unique Call Link
        <!-- <img src="assets/images/question.svg"> -->
      </div>
      <div class="cpylnk" kzLib (click)="copyRepLink()">
        <span class="fa"> {{host_url}}/</span><input kzInput class="in_link" [ngClass]="{invalid:bBadlink}" type="link" placeholder="your_name (no spaces)" [(ngModel)]="rep_link" (ngModelChange)="changeLink($event)">
        <img class="copy" src="assets/images/copy_w_gray_circle.svg">
      </div>
      <input #file class="hidden" (change)="selectedUploadImage($event)" type="file" name="image" ng2FileSelect [uploader]="uploader" accept="image/x-png,image/gif,image/jpeg" />

    </div>




  </div>
  <div class="block block2">
    <div class="input_c">
      <div>First Name</div>
      <input kzInput class="in" type="text" placeholder="Name" name="" value="" [(ngModel)]="rep_name" (ngModelChange)="changed()">
      <div>Email Address </div>
      <input kzInput class="in" [ngClass]="{invalid:bInvalidEmail}" type="email" placeholder="Email" name="" value="" [(ngModel)]="email" (ngModelChange)="changedEmail($event)">

      <!-- <k-input class="fback" width="280px" [(invalid)]="bInvalidEmail" type="email" email placeholder="Email" [(myModel)]="email" (myModelChange)="changedEmail($event)"></k-input> -->
      <div>Company Name</div>
      <div class="in no_ed" *ngIf="roll!='admin'">{{company}}</div>
      <input kzInput *ngIf="roll=='admin'" class="in" type="text" placeholder="Name" name="" value="" [(ngModel)]="company" (ngModelChange)="changed()">
      <!-- <k-input class="fback" width="280px" type="name" placeholder="Name" [(myModel)]="company"></k-input> -->
      <!-- <k-button class="log_out" min_width="100" text="Log out"  kzLib (click)="logOut()"></k-button> -->

    </div>

    <div class="input_c">
      <div>Last Name</div>
      <input kzInput class="in" type="text" placeholder="Name" name="" value="" [(ngModel)]="last_name" (ngModelChange)="changed()">

      <!-- <k-input class="fback" width="280px" type="name" placeholder="Name" [(myModel)]="last_name"></k-input> -->
      <div>Phone Number</div>
      <input kzInput class="in" type="tel" placeholder="Number" name="" value="" [(ngModel)]="phone" (ngModelChange)="changedPhone($event )">
      <!-- <k-input class="fback" width="280px" type="name" placeholder="Company" [(myModel)]="phone"></k-input> -->
      <div>Company Title</div>
      <input kzInput class="in" type="text" placeholder="Title" name="" value="" [(ngModel)]="rep_title" (ngModelChange)="changed()">
      <!-- <k-input class="fback" width="280px" type="name" placeholder="T" [(myModel)]="rep_title"></k-input> -->
    </div>



  </div>



  <div class="block">
    <div class="input_c">
      <div>Signature</div>

      <img *ngIf="signature_data" class="clickable" src="assets/images/trash_w_g_c.svg" kzLib (click)="delSignature()">
      <!-- <img *ngIf="signature_data" class="clickable pen"  kzLib (click)="getSignature()" src="assets/images/pen_w_g_c.svg"> -->
      <div #signContainer class="signature">
        <img *ngIf="!signature_data" src="assets/images/add_sig.svg" class="abs_center hand" kzLib (click)="getSignature()">
        <div *ngIf="!signature_data" class="abs_center txt_l">
          Create Signature
        </div>
      </div>
    </div>
    <div class="input_c">
      <div>Initials</div>
      <img *ngIf="initials_data" class="clickable" src="assets/images/trash_w_g_c.svg" kzLib (click)="delInitials()">
      <!-- <img *ngIf="initials_data" class="clickable pen"  kzLib (click)="getinitials()" src="assets/images/pen_w_g_c.svg"> -->
      <div #initialsContainer class="signature">
        <img *ngIf="!initials_data" src="assets/images/add_ini.svg" class="abs_center hand" kzLib (click)="getinitials()">
        <div *ngIf="!initials_data" class="abs_center txt_l">
          Create Initials
        </div>
      </div>
    </div>

  </div>
  <div class="right_box">

    <!-- <div *ngIf="bNeedsConnection" class="connect"  kzLib (click)="linkCal()">
      <img src="assets/images/google_lg.svg" alt=""> Connect account
    </div>
    <div *ngIf="!bNeedsConnection" class="connect"  kzLib (click)="removeCal()">
      <img src="assets/images/google_lg.svg" alt=""> Disconnect account
    </div> -->
    <!-- <img *ngIf="bNeedsConnection" class="connect" src="assets/images/google_connect_cal.svg"  kzLib (click)="linkCal()"> -->

    <!-- <div class="settings"  kzLib (click)="bShowSettings=!bShowSettings">
      Calendar settings
      <img class="expand" src="assets/images/exp10.svg" [ngClass]="{flip:bShowSettings}">
    </div> -->
    <div class="id_c">
      <div class="id">Identification Document</div>
      <get-pic-or-upload [image]="lic_src" (imageChange)="gotLic($event)"></get-pic-or-upload>
      <img *ngIf="lic_src!=''" class="del_img del_id" kzLib (click)="delId()" src="assets/images/del_id.svg" alt="Upload">
      <img *ngIf="lic_src" class="rotate" src="assets/images/rotate_id.svg" kzLib (click)="rotate()">

    </div>
    <calendly-settings *ngIf="bShowCalendlySettings" class="cal_settings" (close)="bShowCalendlySettings=false"></calendly-settings>
    <!-- <cal-settings *ngIf="bShowSettings" class="cal_settings" (close)="bShowSettings=false"></cal-settings> -->
    <!-- <k-button class="save_btn" min_width="100" text="Save"  kzLib (click)="save()"></k-button> -->
  </div>
  <div *ngIf="bShowDelUserWarning" class="abs_center sure_box">
    <div class="margin">
      Are you sure you want to delete this picture?
    </div>
    <div class="abs_center btnsyn">
      <k-button class="btn1" text="Yes" background="#fff" color="#000" kzLib (click)="removeProfile()"></k-button>
      <k-button text="No" background="#000" color="#fff" kzLib (click)="bShowDelUserWarning=false"></k-button>
    </div>
  </div>
</div>
