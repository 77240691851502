<div class="out">
  <div class="state">
    keyziipay
  </div>
  <kpay-bar-part [active]="bInvCheck" [progress]="inv_progress" [spining]="bSpinInvoice" class="part1" label="Sent Invoice"></kpay-bar-part>
  <kpay-bar-part [active]="bBillingCheck" [progress]="billing_progress" [spining]="bBillingSpin" class="part1" label="Billing details"></kpay-bar-part>
  <kpay-bar-part *ngIf="!bPaymentError" [active]="bPaymentCheck" [spining]="bPaymentSpin" class="part2" label="Payment status"></kpay-bar-part>

  <kpay-bar-part *ngIf="bPaymentError" [error]="true" [active]="bPaymentCheck" [spining]="bPaymentSpin" class="part2" label="Payment error"></kpay-bar-part>
</div>
