import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Store } from '@ngrx/store';
import { ChangedTZ, GetFromToEvents, GotWeekEvents, GotWorkTime, SetSlots } from './calendar.actions'
import { CalendarState } from './calendar.model'

import { changedWorkTime } from './calendar.selectors';
import { PagesState } from '../pages.model';
import { showWelcome, workTime } from '../pages.selectors';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  seq: number = 1;
  prev_time: number = 0
  prev_time_to: number = 0

  offset: number = -1;
  localTZ: any = {}
  constructor(
    private httpClient: HttpClient,
    private calendarStore: Store<CalendarState>,
    private pagesState: Store<PagesState>,

  ) {
    console.log("CalendarService")
    let offset = new Date().getTimezoneOffset()
    // if (offset > 0) {
    offset *= -1////The sign is reverse because javascript returns whole minutes that are positive West of UTC.
    // }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    console.log(timeZone);
    let time = ""
    if (offset >= 0) {
      time = "+"
    } else {
      time = "-"
    }
    let hh = Math.abs(offset) / 60
    let mm = Math.abs(offset) - hh * 60
    if (hh < 10) {
      time += "0" + hh + ":"
    } else {
      time += hh + ":"
    }
    if (mm < 10) {
      time += "0" + mm
    } else {
      time += mm
    }
    let utc = "(UTC" + time + ")" + " "
    if (Math.abs(offset) > 60) {
      offset /= 60
    }
    let local: any = {
      value: timeZone,
      offset: offset, //KEEP OFFSET IN MINUTES
      text: utc + " " + timeZone
    }
    var matches = timeZone.match(/\b(\w)/g);
    if (matches) {
      var abbreviations = matches.join('');
      local.abbr = abbreviations
    }
    this.localTZ = local
    this.pagesState.select(workTime).subscribe((work_time: any) => {
      if (work_time) {
        if (work_time.hasOwnProperty("offset")) {
          if (work_time.offset == null) {
            console.log("Calendar service reset work time ")
            this.setDefaultWorkTime()
          }
        }
      }
    })
    this.calendarStore.select(changedWorkTime).subscribe((work_time: any) => {
      if (work_time) {
        if (work_time.offset) {
          // if (this.offset == 0) {
          if (this.localTZ.offset * 60 != work_time.offset) {
            this.calendarStore.dispatch(new ChangedTZ(this.localTZ))
          }
          // }

          if (this.offset != work_time.offset) {
            this.offset = work_time.offset
            this.prev_time = 0



          }
        }
      }
    })

    this.pagesState.select(showWelcome).subscribe((who: string | undefined) => {
      if (who) {
        if (who != "no" && this.offset >= 0) {
          setTimeout(() => { this.setDefaultWorkTime() }, 100)
        }
      }
    })
    // this.authState.select(gotAuth).subscribe((user: any) => {
    //   if (user) {
    //     if (user.work_time) {
    //       if (user.work_time.offset) {
    //         this.offset = user.work_time.offset
    //       }
    //     }
    //   }
    // })
  }
  //Jan27, 2021
  ////slots:[{"start":1611770400000,"end":1611771300000,"title":"no reps available"}],"dirty":{},"reps":["5fed1d522701bda9b4d6c08e"]
  async  setDefaultWorkTime() {
    let jtime = {
      from: "09:00 AM",
      to: "05:00 PM",
      zone: this.localTZ.text,
      slot_minutes: 30,
      abbr: this.localTZ.abbr,
      offset: this.localTZ.offset * 60 //The original one was in hours
    }
    try {
      await this.setWorkTime(jtime)
    } catch (e) {
      console.error(e)
    }
  }
  async getWeekEvents(from: number, to: number) {
    if (from == this.prev_time) {
      return;
    }

    console.log("to " + new Date(to))
    console.log("getWeekEvemts  " + new Date(from) + " " + new Date(to) + " " + this.seq)
    this.seq++;
    let url = '/cal_events?&from=' + from + "&to=" + to
    if (this.offset) {
      url += "&offset=" + this.offset
    }
    try {
      let events: any = await this.httpClient.get(url).toPromise();
      this.calendarStore.dispatch(new GotWeekEvents(from, to, this.offset, events))
      this.prev_time = from
    } catch (e: any) {
    }
  }

  async getFromToEvents(from: number, to: number) {
    if (from == this.prev_time && to == this.prev_time_to) {
      return;
    }

    console.log("to " + new Date(to))
    console.log("getFromToEvents  " + new Date(from) + " " + new Date(to) + " " + this.seq)
    this.seq++;
    let url = '/cal_events?&from=' + from + "&to=" + to
    if (this.offset) {
      url += "&offset=" + this.offset
    }
    try {
      let events: any = await this.httpClient.get(url).toPromise();
      this.calendarStore.dispatch(new GetFromToEvents(from, to, this.offset, events))
      this.prev_time = from
      this.prev_time_to = to
    } catch (e: any) {
    }
  }
  async getDayEvents(in_date: Date) {
    //Wed Oct 04 2023 05:01:19 GMT-0700 (Pacific Daylight Time)
    //in date is the local time
    let new_time = in_date.getTime()
    let date = new Date(new_time)

    let offset = date.getTimezoneOffset()
    console.log("offset " + offset)

    if (offset < -720) {
      let now = date.getTime()
      let prev_day = now - 24 * 60 * 60 * 1000
      date = new Date(prev_day)
    }
    date.setHours(0, 0, 0, 0); //Begining of the local day in GTM 2023-10-04T07:00:00Z
    //now subract the offset

    let from = date.getTime()
    if (from == this.prev_time) {
      return;
    }
    this.prev_time = from
    date.setHours(24, 0, 0, 0);
    let to = date.getTime() // ISODate("2023-10-05T07:00:00Z")
    console.log("getDayEvemts  " + new Date(from) + " " + new Date(to) + " " + this.seq)
    this.seq++;
    let url = '/cal_events?&from=' + from + "&to=" + to
    if (this.offset) {
      url += "&offset=" + this.offset
    }
    try {
      let events: any = await this.httpClient.get(url).toPromise();
      this.calendarStore.dispatch(new GetFromToEvents(from, to, this.offset, events))

      console.log("getDayEvemts prev_time " + this.prev_time)
    } catch (e: any) {
      console.error(e)
    }
  }

  async getDaySlots(in_date: Date) { //The slots of the day for the browser local time
    let date = new Date(in_date.getTime())
    // let offset = date.getTimezoneOffset()
    // console.log("offset " + offset)

    // if (offset < -720) {
    //   let now = date.getTime()
    //   let prev_day = now - 24 * 60 * 60 * 1000
    //   date = new Date(prev_day)
    // }
    // date.setHours(0, 0, 0, 0);
    let from = date.getTime()
    if (from == this.prev_time) {
      return;
    }
    this.prev_time = from
    // date.setHours(24, 0, 0, 0);
    try {
      let slots = await this.httpClient.get('/rep_slots?from=' + from).toPromise();
      this.calendarStore.dispatch(new SetSlots(slots))
      return
    } catch (e) {
      console.error(e)
    }

  }

  async delEvent(event_id: string) {
    return new Promise<void>(async (resolve, reject) => {

      try {
        let rv: any = await this.httpClient.delete('/event/' + event_id).toPromise();
        this.prev_time = 0
        resolve()
      } catch (e: any) {
        reject(e)
      }
    })
  }
  async delGoogleEvent(event_id: string) {
    return new Promise<void>(async (resolve, reject) => {

      try {
        let rv: any = await this.httpClient.delete('/gevent/' + event_id).toPromise();
        this.prev_time = 0
        resolve()
      } catch (e: any) {
        reject(e)
      }
    })
  }
  async showing24(bShowing24: boolean) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.put('/showing_24', { bShowing24: bShowing24 }).toPromise();
        resolve()
      } catch (e: any) {
        reject(e)
      }
    })
  }

  async addEvent(event: any) {
    return new Promise<void>(async (resolve, reject) => {
      event.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (this.offset) {
        event.offset = this.offset
      }
      try {
        let rv: any = await this.httpClient.post('/add_event', event).toPromise();
        this.prev_time = 0;//Make sure we update it
        // this.getDayEvents(new Date())
        resolve()
      } catch (e: any) {
        reject(e)
      }
    })
  }

  async getGoogleLoginURL() {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.get('/google_login').toPromise();
        resolve(rv.authUrl)
      } catch (e: any) {
        reject("Error getting venditio" + e)
      }
    })
  }
  async getGoogleCalendarAuthURL() {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.get('/google_calendar_auth_url').toPromise();
        resolve(rv.authUrl)
      } catch (e: any) {
        reject("Error getting venditio" + e)
      }
    })
  }

  async setGoogleCalendarAuthCode(code: any) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.post('/google_calendar_code', code).toPromise();
        resolve(rv.authUrl)
      } catch (e: any) {
        reject("Error getting venditio" + e)
      }
    })
  }

  async delGoogleCalendarAuthCode() {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.delete('/google_calendar_code').toPromise();
        resolve(rv.authUrl)
      } catch (e: any) {
        reject("Error getting venditio" + e)
      }
    })
  }

  async setWorkTime(time: any) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.post('/work_time', time).toPromise();
        this.calendarStore.dispatch(new GotWorkTime(time))
        //We need to get the events with the new work times
        this.offset = time.offset
        resolve(rv.authUrl)
      } catch (e: any) {
        reject("Error getting venditio" + e)
      }
    })
  }
  async loadTZ() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let url = '/assets/time_zones.json'
        let rv: any = await this.httpClient.get(url, {}).toPromise();

        rv.unshift(this.localTZ)
        resolve(rv);
      } catch (e) {
        reject(e)
      }
    })
  }
  async setCalendly(rep: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.post('/calendly', {
          rep: rep
        }).toPromise();
        resolve()
      } catch (e: any) {
        reject("Error getting venditio" + e)
      }
    })
  }
  selectCalendar(wich: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.post('/setlect_cal', {
          cal: wich
        }).toPromise();
        resolve()
      } catch (e: any) {
        reject("Error getting venditio" + e)
      }
    })
  }


}
