// https://dev.to/codeclown/styling-a-native-date-input-into-a-custom-no-library-datepicker-2in



import { Component, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { validateEmail } from 'src/app/utils';
import { CalendarState } from '../calendar.model';
import { changedSlots, changedWorkTime, tmpEvent } from '../calendar.selectors';
import {
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  getDate,
  getMonth,
  getYear,
  isToday,
  isSameDay,
  isSameMonth,
  isSameYear,
  isBefore,
  isAfter,
  getDay,
  subDays,
  setDay,
  format,
  addMonths,
  subMonths,
  setYear,
  addYears,
  subYears
} from 'date-fns';
import { CalendarService } from '../calendar.service';
import { AddAppointment, SaveTmp } from '../calendar.actions';
import { formatDate } from '@angular/common';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';

@Component({
  selector: 'create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent {
  title: string = ""
  first_name: string = ""
  last_name: string = ""
  email: string = ""
  message: string = ""
  bInvalidEmail: boolean = true
  from_options: any[] = []
  slots: any | undefined;
  _sel_slot: any | undefined;

  date: Date = new Date()
  sel_date: Date | undefined
  fdate: string = ""
  bShowSelTime: boolean = false

  _event: any | undefined;

  day_lbl: string = "Day"
  time_lbl: string = "Time"
  bDisabled: boolean = true

  slot_minutes: number = 15
  app_time: number = 15



  @Input() set event(ev: any) {
    this._event = ev;
    if (this._event) {
      if (this._event.date) {
        this.date = this._event.date
      }
    }
    this.setUI()
  }
  @Output() eventChange: EventEmitter<string> = new EventEmitter()

  from_to: any = { from: -1, to: -1 }

  @ViewChild('title_field') title_field: ElementRef | undefined | undefined
  @ViewChild('dt') dt: ElementRef | undefined | undefined
  subscriptions: any[] = []
  minDate: string = "";

  bSentIt: boolean = false
  bError: boolean = false
  page: number = 1

  constructor(
    private calendarStore: Store<CalendarState>,
    private calendar_service: CalendarService,
    public canvasStore: Store<CanvasState>,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    console.log("create event")
    this.setFDate()

    this.subscriptions.push(this.calendarStore.select(changedSlots).subscribe((slots: any) => {
      if (slots) {
        this.slots = slots
        if (this.slots.length > 0) {
          this.date = new Date(this.slots[0].time)
          this.setUI()
          this.setFDate()
        }
      }
    }))

    this.subscriptions.push(this.calendarStore.select(changedWorkTime).subscribe((work_time: any) => {
      if (work_time) {
        if (work_time.slot_minutes) {
          this.slot_minutes = parseInt(work_time.slot_minutes)
          this.app_time = this.slot_minutes
        }
      }
    }))
    this.subscriptions.push(this.calendarStore.select(tmpEvent).subscribe((tmp: any) => {
      if (tmp.slot_minutes) { //set to zero on save
        this.date = tmp.date
        this.first_name = tmp.first_name
        this.last_name = tmp.last_name
        this.slot_minutes = tmp.slot_minutes
        this.app_time = tmp.app_time
        this.title = tmp.title
        this.message = tmp.message
        let from = -1;
        let to = -1;
        if (this.date) {
          let t_from = this.date.getTime()
          for (let i = 0; i < this.slots.length; i++) {
            let slot = this.slots[i]
            if (slot.time >= t_from) {
              from = i;
              to = from + (this.app_time / tmp.slot_minutes) - 1
              break;
            }
          }
        }

        this.from_to = { from: from, to: to }
      }
    }))
    this.setMinDatel
  }
  ngAfterViewInit() {
    // let offset = this.date.getTimezoneOffset()
    // console.log("offset " + offset)
    setTimeout(() => { this.changedDate() }, 1000)
  }
  setMinDatel() {

    var dtToday = new Date();

    var month: number = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    let smonth = month.toString();
    if (month < 10)
      smonth = '0' + smonth;
    let sday = day.toString();
    if (day < 10)
      sday = '0' + sday

    this.minDate = year + '-' + smonth + '-' + sday;


  }
  // cancel() {
  //   this.eventChange.emit()
  // }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })

    let msg = {
      date: this.sel_date,
      slot_minutes: this.slot_minutes,
      app_time: this.app_time,
      first_name: this.first_name,
      last_name: this.last_name,
      title: this.title,
      message: this.message,
      email: this.email,
    }
    this.calendarStore.dispatch(new SaveTmp(msg))
  }

  async send() {
    if (!this.bDisabled && this.date) {
      // let event_id: string|;//Only set to edit a meeting
      let start_date = this.date
      let start = start_date.getTime()
      let end = start + this.slot_minutes * 60 * 1000
      let stime = start_date.toLocaleTimeString('en-us', { hour: "2-digit", minute: "2-digit", timeZoneName: 'short' })

      // let stime = formatDate(start_date, "shortTime", navigator.language, "-" + this.time_zone)
      let fdate = start_date.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
        + " " + stime
      let initials
      if (this.first_name && this.first_name.length > 0) {
        initials = this.first_name.substring(0, 1).toUpperCase()
      }
      if (this.last_name && this.last_name.length > 0) {
        initials = this.last_name.substring(0, 1).toUpperCase()
      }


      let new_event: any = {
        // event_id: this.event_id,
        email: this.email,
        caller_offset: new Date().getTimezoneOffset() * -1,
        start: start,
        allDay: false,
        first_name: this.first_name,
        last_name: this.last_name,
        title: this.title,
        notes: this.message,
        duration: this.slot_minutes,
        end: end,
        initials: initials,
        fdate: fdate
      }

      try {
        this.page = 2;
        await this.calendar_service.addEvent(new_event)
        this.page = 3;
        this.slot_minutes = 0 //we use this for the restore
        this.eventChange.emit(new_event)
        this.bSentIt = true;
      } catch (e: any) {
        this.bError = true
        console.error(e.error.esrror)
      }
    }
  }
  async changedEmail($event: any) {
    this.bInvalidEmail = !validateEmail(this.email)
    this.setUI()
  }

  toogleTime() {
    setTimeout(() => {
      if (!this.slots || this.slots.length == 0) {
        if (!this.date) {
          this.date = new Date()
        }
        this.calendar_service.getDaySlots(this.date)
      }
      this.bShowSelTime = !this.bShowSelTime

      // this.bShowSelDay = !this.bShowSelTime
    })
    this.setUI()
  }
  setDate(date: Date) {
    this.date = date
    this.calendar_service.getDayEvents(this.date)
    // setTimeout(() => { this.bShowSelDay = false })
    this.setUI()

  }
  setTime(slot: any) {
    console.log("set time")
    if (slot.status == 'f') {
      setTimeout(() => {
        this.bShowSelTime = false
        if (!this.bShowSelTime && this.title_field) {
          this.title_field.nativeElement.focus()
        }
      })
      this.date = new Date(slot.time)
      this.setUI()

    }

  }

  setUI() {
    let date: Date | undefined = this.date

    setTimeout(() => {
      if (this.sel_date) {
        this.bDisabled = this.bInvalidEmail
      } else {
        this.bDisabled = true;
        date = new Date()
        this.day_lbl = getYear(date) + "/" + (getMonth(date) + 1) + "/" + getDate(date)
      }
    })
  }
  changedDate($event?: string) {
    let date
    if ($event) {
      let dt = $event.replace("-", "/");
      dt = dt.replace("-", "/");
      date = new Date(dt)
    } else {
      date = new Date()
      // date.setHours(0, 0, 0, 0)
    }
    this.date = date
    this.calendar_service.getDaySlots(this.date)
    this.setFDate()
  }
  setFDate() {
    this.fdate = formatDate(this.date, 'd MMM yyyy', this.locale)
    // this.fdate += " '" + formatDate(this.date, 'yy', this.locale)

  }
  timesChange($event: any) {
    setTimeout(() => {
      console.log("times changed ")
      let slot = this.slots[$event.from]
      this.date = new Date(slot.time)
      this.sel_date = this.date
      this.time_lbl = this.date.toLocaleTimeString('en-us', { hour: "2-digit", minute: "2-digit" })

      this.app_time = (1 + $event.to - $event.from) * this.slot_minutes
      console.log(this.date + " " + this.app_time)
      this.setUI()
    })
  }
  toggleDate() {
    if (this.dt) {
      this.dt.nativeElement.showPicker()
    }
  }
  done() {
    this.calendarStore.dispatch(new AddAppointment(undefined))
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
  reTry() {
    this.bError = false;
    this.bSentIt = false;
    this.page = 1;
  }
}
