<div class="out">
  <div class="row header">
    <div class="day_w def_c">Time</div>
    <div class="day_w def_c" *ngFor="let wd of week_days">
      {{wd}}
    </div>
  </div>
  <div class="h_row" *ngFor="let hour of hours; let r = index">


    <div class="day-unit" *ngFor="let col of cols; let c = index">
      <div *ngIf="c == 0" class="day">
        <span class="abs_center ">{{getContent(r,c)}}</span>
      </div>

      <div *ngIf="c != 0" class="day">
        <div class="slot" [ngClass]="{'disabled': slot.status != 'f'}" [style.border-bottom]="border" [style.top.%]="slot.top" [style.height.%]="slot.height" *ngFor="let slot of getHourSlot(r,c)"  kzLib (click)="addAppointment(slot)">
          <div class="slot1" *ngIf="slot.event">{{slot.event.title}}<span *ngIf="slot.event.name">, </span>{{slot.event.name}} </div>
        </div>
      </div>
    </div>
  </div>
</div>
