<div class="out"  kzLib (click)="doneEditing()">
  <div class="top_line">
    <input kzInput class="search" type="name" placeholder="Search leads" [(ngModel)]="search" (ngModelChange)="changedSearch()">
    <k-button class="del" icon="download.svg" background="#F2F2F2"  kzLib (click)="downloadContacts()"></k-button>
  </div>
  <div class="gray_line"></div>

  <div class="line t_t">
    <div class="el"> Date </div>
    <div class="el"> <span class="ellipsis">Name </span></div>
    <div class="el"> Company</div>
    <div class="el"> Email</div>
    <div class="el"><span class="ellipsis"> Agent </span></div>
    <div class="el">Actions</div>
  </div>
  <div class="h_content" #content>
    <div class="line" *ngFor="let contact of contacts, let i = index ">
      <div class="el"> {{contact.updated_at|date}} </div>
      <div class="el">
        <span class="ellipsis">
          {{contact.name}}{{contact.first_name}} {{contact.last_name}}
        </span>
      </div>
      <div class="el"> {{contact.cia_name}} </div>
      <div class="el"> {{contact.email}} </div>
      <div class="el">
        <span class="ellipsis">{{contact.rep_name}} </span>
      </div>
      <div class="el">
        <img src="assets/images/delete.svg" class="img" alt=""  kzLib (click)="delLead(contact)">
      </div>
    </div>

  </div>
  <k-filter [single]="true" [exterior]="true" [min_width]="110" class="filter" title="{{title}}" [str_options]="filter_types" [sel_index]="sel_index " (selected)="filterType($event)"></k-filter>
  <div class="pag_wrapper">
    <k-paginator class="paginator" [total]="count" [pages]="num_pages" [page_size]="page_size" (indexChange)="selPage($event)"></k-paginator>
  </div>
</div>
