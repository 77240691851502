<div class="out ot" *ngIf="bDoPages">

  <div class="txt">
    {{name}} {{sel_page}} of {{_pages}}
  </div>

  <div class="arrows">
    <img class="prev" src="assets/images/expand_l.svg"  kzLib (click)="prev()" [ngClass]="{disabled:sel_page==1}">
    <img class="next" src="assets/images/expand_l.svg"  kzLib (click)="next()" [ngClass]="{disabled:sel_page==_pages}">
  </div>
</div>
<div class="out ot" *ngIf="!bDoPages">
  <div class="txt">
    {{from_c}} - {{to_c}} of <strong>{{_total}}</strong>
  </div>
  <div class="arrows">
    <img class="prev" src="assets/images/expand_l.svg"  kzLib (click)="prev()" [ngClass]="{disabled:sel_page==1}">
    <span class="sel"> </span>
    <img class="next" src="assets/images/expand_l.svg"  kzLib (click)="next()" [ngClass]="{disabled:sel_page==_pages}">

  </div>

</div>
