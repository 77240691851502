import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PagesService } from 'src/app/pages/pages.service';

@Component({
  selector: 'contract-line',
  templateUrl: './contract-line.component.html',
  styleUrls: ['./contract-line.component.scss']
})
export class ContractLineComponent {
  signer_keys: string[] = []
  _contract: any = {}
  _selected: boolean = false;
  @Input() set contract(c: any) {
    this._contract = c;
    this.signer_keys = Object.keys(c.signers)
  }
  @Output() selected = new EventEmitter<boolean>();
  constructor(
    public page_service: PagesService
  ) {
    console.log("contract")
  }
  async   download(id: string) {
    try {
      let key = this._contract.signers[id].chain.key
      let url = await this.page_service.getContractURL(key)
      window.location.assign(url.url);
    } catch (e) {
      console.error(e)
    }
  }
  select($event: any) {
    $event.stopPropagation();
    this._selected = !this._selected
    this.selected.emit(this._selected)
  }
  copyRef($event: any) {
    $event.stopPropagation();

  }
}
