import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts, SetCanvasOpts } from '../../canvas.actions';
import { canvasOpts } from '../../canvas.selectors';
import { CanvasService } from '../../canvas.service';

@Component({
  selector: 'canvas-opts',
  templateUrl: './canvas-opts.component.html',
  styleUrls: ['./canvas-opts.component.scss']
})
export class CanvasOptsComponent {

  bDraw: boolean = false;
  bDownload: boolean = false;
  bContract: boolean = false;

  draw($event: any) {
    this.bDraw = !this.bDraw
    this.sendOpts()
  }
  download($event: any) {
    this.bDownload = !this.bDownload
    this.sendOpts()
  }
  contract($event: any) {
    this.bContract = !this.bContract
    this.bDownload = false;
    this.sendOpts()
  }
  sendOpts() {
    let opt = {
      bDraw: this.bDraw,
      bDownload: this.bDownload,
      bContract: this.bContract
    }
    this.canvasStore.dispatch(new SetCanvasOpts(opt))
    //sent by the canvas.component when it opens or when they change
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
  subscriptions: any[] = []
  constructor(
    public canvasStore: Store<CanvasState>,
    public canvas_service: CanvasService,
  ) {
    console.log("canvasOpts")
    this.subscriptions.push(this.canvasStore.select(canvasOpts).subscribe((opts: any) => {
      this.bDraw = opts.bDraw
      this.bDownload = opts.bDownload
      this.bContract = opts.bContract
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
}
