import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss']
})
export class CircleComponent implements OnInit {
  @Input() text: string = ""
  // _backcolor: string = "#DAE1F7"
  // @Input() set backcolor(bc: string) {
  //   this._backcolor = bc
  // }
  _diameter: number = 25;
  @Input() set diameter(r: number) {
    this._diameter = r
  }
  constructor() { }

  ngOnInit(): void {
  }

}
