import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ControlsState } from "./controls.model";

export const selectControlsStateState = createFeatureSelector<ControlsState>('kcontrols');



//******************************************************************************
//
//******************************************************************************



// export const getBtnBackColor = createSelector(
//   selectControlsStateState,
//   state => state.btn_back_color
// )
// export const getBtnTextColor = createSelector(
//   selectControlsStateState,
//   state => state.btn_text_color
// )
// export const feedBack = createSelector(
//   selectControlsStateState,
//   state => state.feed_back
// )
export const linkOption = createSelector(
  selectControlsStateState,
  state => state.link_option
)
