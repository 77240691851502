<div class="out">
  <k-video role="caller" class="full">
  </k-video>

  <div class="images">
    <div cdkDrag class="round" *ngIf="rep_id">
      <k-video role="rep" class="full" [bMirrow]="my_id==rep_id">
      </k-video>
    </div>
    <div cdkDrag class="round" *ngIf="manager_id">
      <k-video role="manager" class="full" [bMirrow]="my_id==manager_id">
      </k-video>
    </div>

  </div>
</div>