import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts, SetTool } from '../canvas/canvas.actions';
import { KVideoState } from '../k-video/k-video.model';
import { streams } from '../k-video/k-video.selectors';
import { NotifyError, NotifyUser, StartRecording, Recording, RecordingReady, } from '../pages/pages.actions';
import { PagesState } from '../pages/pages.model';
import { recStatus } from '../pages/pages.selectors';
;
import { PagesService } from '../pages/pages.service';
import { WSSState } from '../wss/wss.model';
import { callId, myId, repId } from '../wss/wss.selectors';
import { WSSService } from '../wss/wss.service';

import { S3Service } from './s3.service';
@Injectable({
  providedIn: 'root'
})
export class RecordService {
  mediaRecorder: MediaRecorder | undefined

  streams: any //

  screen_stream: any
  audio_stream: any
  audio_and_screen_stream: any;

  rec_status: string = "";
  num_part: number = 0

  call_id: string | undefined;

  partNumber: number = 1
  rep_id: string | undefined
  // bReady: boolean = true
  constructor(
    public s3_service: S3Service,
    public wssState: Store<WSSState>,
    private kVideoState: Store<KVideoState>,
    public wss_service: WSSService,
    private pagesState: Store<PagesState>,
    private page_service: PagesService,
    private httpClient: HttpClient,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("MP# record service")
    this.pagesState.select(recStatus).subscribe((rec_status: string) => {
      this.rec_status = rec_status
      if (this.rec_status == "starting") {
        this.start()
      } else if (this.rec_status == "stopping") {
        this.stop()
      }
    })
    this.wssState.select(repId).subscribe(async (id: string | undefined) => {
      if (id) { this.rep_id = id }
    })
    this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      if (my_id) { this.rep_id = my_id }
    })
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (this.call_id && !call_id) {
        console.log("MP# hangup")
        this.stop()
      }
      this.call_id = call_id
    })
    this.kVideoState.select(streams).subscribe((streams: any) => {
      this.streams = streams;
      let keys = Object.keys(this.streams)
      console.log("MP# Stream " + JSON.stringify(keys))
      keys.forEach((k) => {
        let stream = this.streams[k]
        console.log("MP#          " + k + " " + stream.id)
      })
    })

  }

  showPlayback() {
    return false;//this.recordedChunks.length > 0
  }
  /*
  getPrompt() {

    if (!this.call_id) {
      return "Not in call"
    }
    if (this.rec_status == "recording") {
      return "Stop Recording"
    } else if (this.rec_status == "stopping") {
      return "Preparing Recording"
    }
    return ""
  }*/

  async start() {

    try {

      this.num_part = 0
      await this.s3_service.createS3Client()
      if (!this.call_id) {
        //rec_stand_alone

        try {
          let rv: any = await this.httpClient.get('/rec_stand_alone').toPromise();
          this.call_id = rv.call._id
          console.log("got rec standalon id " + JSON.stringify(rv) + " " + this.call_id)
        } catch (e: any) {
          return "Error createing stand alone call " + e
        }
      }

      if (!this.call_id) {
        return "Not in call"
      }
      await this.shareScren()
      this.pagesState.dispatch(new Recording())
    } catch (e) {
      this.pagesState.dispatch(new RecordingReady())
      console.error(e)
    }

    if (this.mediaRecorder) {
      this.mediaRecorder.start(60000); //Blobs will have 60 seconds
      console.log("MP# (1) start " + new Date().getTime())


      // this.toolsStore.dispatch(new Recording(true))
      this.wss_service.sendMessageToOtherMembers({ recording: true })

      return "ok"
    } else {
      return "no media recorder"
    }
  }

  async stop() {
    // this.pagesState.dispatch(new NotifyUser("Stopping recorder"))
    //Tell the world that you are stopping

    // console.log("MP# requestData ")
    //  this.mediaRecorder.requestData()
    try {

      console.log("MP# (3) stop ")
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      } else {
        console.error("MP# !this.mediaRecorder")
      }

    } catch (e) {
      console.error(e)
    }

  }
  /*
    async toggleRec() {
      this.bRecording = !this.bRecording
      if (this.bRecording) {
        this.start()
      } else {
        this.stop()
      }
      // return this.getPrompt()
    }*/

  async shareScren() {
    return new Promise<void>(async (resolve, reject) => {
      let me = this

      async function handleDataAvailable(event: any) {
        console.log("MP# (4) data-available " + event.data.size + " " + new Date().getTime());
        if (event.data.size > 0) {
          let blob: Blob = event.data
          try {
            console.log("MP# (5) calling uploadMultipart ");
            await me.s3_service.uploadMultipart(blob)
            console.log("MP# (6) first part " + me.num_part + " " + new Date().getTime())
            me.num_part++
            if (me.rec_status == "stopping") {

              console.log("MP# (7) calling finish uploadMultipart ");
              me.finishS3()
              console.log("MP# (8) stop tracks")
              me.screen_stream.getTracks().forEach((track: any) => track.stop())
              me.wss_service.sendMessageToOtherMembers({ recording: false })

            }
          }
          catch (e) {
            // me.toastr.error("error uploading multipart " + e)
            console.error("MP# abort uploadMultipart " + e);
            await me.s3_service.abortMultiPart()
          }
        } else {
          console.log("MP# event.data.size <= 0 ");
        }
      }

      function handleStopMedia(event: any) {
        console.log("MP# Media Stoped, do nothing")
      }

      try {
        const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();

        // for (const constraint of Object.keys(supportedConstraints)) {
        //   console.log(constraint)
        // }


        const mediaDevices = navigator.mediaDevices as any; //Fix to Property 'getDisplayMedia' does not exist on type 'MediaDevices
        this.screen_stream = await mediaDevices.getDisplayMedia({ preferCurrentTab: true });
        this.screen_stream.addEventListener('inactive', (e: any) => {
          console.log("MP# Stream media inactive, does not do anything");
        })


        let video_track: any
        let audio_tracks: any[] = []
        if (this.screen_stream.active) {
          let share_tracks = this.screen_stream.getTracks()
          for (const track of share_tracks) {
            if (track.kind == "video") {
              // console.log("MP# *** track for connection track.id " + track.id + " " + track.kind + " " + track.label)
              video_track = track
            }
          }
        }

        if (this.streams) {
          let keys = Object.keys(this.streams)
          console.log("MP# rec  " + JSON.stringify(keys))
          for (let key of keys) {
            let stream = this.streams[key]
            if (stream.active) {
              console.log("MP#  rec        " + key + " " + stream.id)
              for (const track of stream.getTracks()) {
                if (track.kind == "audio") {
                  console.log("MP# *** audio for " + key + " track id " + track.id + " " + track.kind + " " + track.label)
                  audio_tracks.push(track)
                }
              };
            }
          }
        }
        //We need to merge the audio streams before recording
        //https://stackoverflow.com/questions/55165335/how-do-you-combine-many-audio-tracks-into-one-for-mediarecorder-api
        let audioContext: any = new AudioContext();
        const destination = audioContext.createMediaStreamDestination();
        let audioStreams: MediaStream[] = []
        let sources: any[] = []
        for (let track of audio_tracks) {
          let mediaStream = new MediaStream()
          audioStreams.push(mediaStream)
          mediaStream.addTrack(track)
          const source = audioContext.createMediaStreamSource(mediaStream)
          sources.push(source)
          source.connect(destination);
          console.log("MP#   added audiot track " + track.id)
        }

        this.audio_and_screen_stream = new MediaStream();
        this.audio_and_screen_stream.addTrack(video_track);
        this.audio_and_screen_stream.addTrack(destination.stream.getAudioTracks()[0]);

        // this.audio_and_screen_stream = new MediaStream(tracks)

        const options = { mimeType: "video/webm" };
        this.mediaRecorder = new MediaRecorder(this.audio_and_screen_stream, options);
        this.mediaRecorder.ondataavailable = handleDataAvailable;
        this.mediaRecorder.onstop = handleStopMedia;
        if (!this.rep_id) {
          reject("no rep_id")
          return
        }
        if (!this.call_id) {
          reject("no call_id")
          return
        }
        this.s3_service.startMultipart(this.rep_id, this.call_id)
        resolve()
      } catch (e) {
        reject()
        //    this.toastr.error("Error: please enable screen recording to share it")
        console.error("MP# Error " + JSON.stringify(e))
      }
    })
  }
  finishS3() {

    setTimeout(async () => { //We need to upload the last block before completing multipart
      try {
        console.log("MP# finishS3")
        let rec_num = await this.s3_service.completeMultiPart()
        if (this.call_id) {
          this.page_service.addRecording(this.call_id, rec_num, true)

          // this.pagesState.dispatch(new NotifyUser("Recording sent to email"))
          //Tell the world that you are done

          //TODO: add the call length
        } else {
          console.error("Not in call")
        }
        this.pagesState.dispatch(new RecordingReady())
        this.canvasStore.dispatch(new SelectToolOpts("rec_ready"))

      } catch (e) {
        await this.s3_service.abortMultiPart()
        console.error(e)
      }

    }, 500)
  }
  playback(video: ElementRef) {
    // const blob = new Blob(this.recordedChunks)//, { 'type' : settings.recorderOptions.mimeType});
    // video.nativeElement.src = window.URL.createObjectURL(blob);
  }
  async getLink(type: string) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let key = this.s3_service.key
        // key = "6692df35c53600249985507f/1.webm"
        if (key) {
          let rv: any = await this.httpClient.put<any>('/rec_link', { key: key, type: type }).toPromise();
          resolve(rv.url)
        } else {
          reject("no key")
        }
      } catch (e: any) {
        console.error("Error update user " + JSON.stringify(e))
        reject(e)
      }
    })
  }
}
