<div class="s_out" #outComponent>
  <video *ngIf="video_roll != 'kuula'" [muted]="true" #videoElement class="container" [style.height.px]="container_height" [style.top.px]="container_top" [style.width.px]="container_width" [style.left.px]="container_left" autoPlay autoplay
    playsinline>
  </video>

  <div class="container canvas" #divContainer [style.height.px]="canvas_height" [style.top.px]="canvas_top" [style.width.px]="canvas_width" [style.left.px]="canvas_left">
    <!-- MAKE SURE NOT TO PUT ANY THING INSIDE -->
  </div>

</div>
