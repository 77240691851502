import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { YoutubePlayerComponent } from 'ngx-youtube-player';
import { ChatState } from 'src/app/chat/chat.model';
import { openChat } from 'src/app/chat/chat.selectors';
import { WSSState } from 'src/app/wss/wss.model';
import { lastMsg } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent {
  video_id: string = ""
  video_url: string = ""
  _asset: any
  @Input() pauseOnStart: boolean = false;
  @Input() set asset(a: any) {
    this._asset = a
    this.video_id = this._asset.data
  }
  options = {}
  player: YT.Player | undefined
  bPlayerReady: boolean = false
  bDidSeek: boolean = false
  yt_debouncer: number = 0;
  width: number = 0
  height: number = 0
  // suspended_for_resize_at: number = -1
  @ViewChild('out') out: ElementRef | undefined

  @ViewChild('ytplayer') ytplayer: any | undefined
  subscriptions: any[] = []
  bSmall: boolean = false
  constructor(
    // private changeDetectorRef: ChangeDetectorRef,
    public wss_service: WSSService,
    private wssState: Store<WSSState>,
    public chatStore: Store<ChatState>
  ) {
    this.subscriptions.push(this.chatStore.select(openChat).subscribe((bOpen: boolean) => {
      this.onResize()
    }))
    this.subscriptions.push(this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg) {
        if (msg.hasOwnProperty("YT_state")) {
          if (this._asset.asset_id == msg.asset_id) {
            if (msg.YT_state == "RESTART") {
              if (this.player) {
                this.player.seekTo(0, true)
                this.player.pauseVideo()
              }
            } else if (msg.YT_state == "PAUSED" || msg.YT_state == "ENDED") {
              if (this.player) {
                this.player.pauseVideo()
              }
            } else if (msg.YT_state == "PLAYING") {
              if (this.player) {
                this.player.playVideo()
              }
              if (msg.t) {
                let t = new Date().getTime()
                let d = t - this.yt_debouncer
                if (d > 1000) {
                  if (this.player) {
                    this.player.seekTo(msg.t, true)
                  }
                }
              }
            }
          }
        }
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.onResize()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) { //aspect ratio is 1280, 720
    console.log("youtube w ")
    //Get the location of the youtube video
    // if (this.player) {
    //   this.suspended_for_resize_at = this.player.getCurrentTime()
    // }
    this.height = 0; //TO Force youtube to resize
    setTimeout(() => {
      if (this.out) {
        this.height = this.out.nativeElement.clientHeight
        this.width = this.out.nativeElement.clientWidth
        this.bSmall = this.out.nativeElement.clientHeight < 200

        // this.changeDetectorRef.detectChanges()
        console.log("changed size")
        // if (this.ytplayer) {
        //   if (this.ytplayer.elementRef) {
        //     if (this.ytplayer.elementRef.nativeElement) {
        //       this.ytplayer.elementRef.nativeElement.offsetWidth = this.width
        //       this.ytplayer.elementRef.nativeElement.offsetHeight = this.height
        //     }
        //   }
        // }
      }
      // if (this.player) {
      //   this.player.playVideo()
      // }
    })
  }

  bGotBuffering = false;
  setupPlayer(player: any) { //called by the UI
    console.log("video player 1")
    this.player = player;
    this.bPlayerReady = true;
    console.log('player instance', player);

    // let parts=this._asset.key.split('/')
    // this.video_id = parts[parts.length-1]
    setTimeout(() => { //It appears that we need to wait for the asset to be render before showing the player or the video is not center properly the first time
      if (this._asset.data) {
        this.video_id = this._asset.data
        console.log("&&& loadVideoById " + this.video_id)
        this.bGotBuffering = false;
        if (this.video_id && this.player) {
          this.player.loadVideoById(this.video_id);
        }
      } else if (this._asset.video_url) {
        this.video_url = this._asset.video_url
        console.log("&&& loadVideoURL " + this.video_url)
        this.bGotBuffering = false;
        if (this.video_id && this.player) {
          this.player.loadVideoByUrl(this.video_url);
        }
      }
    }, 10);
  }

  updatePlayerState(event: any) {

    console.log('player state', event.data);
    switch (event.data) {
      case -1:
        console.log('player is minus one');
        if (this.bGotBuffering) {
          console.log('got buffering');
          if (this.player) {
            this.player.playVideo()
          }
        }
        break;
      case YT.PlayerState.ENDED://0
        console.log("&&& ENDED")
        this.wss_service.sendMessageToOtherMembers({
          "YT_state": "ENDED",
          "asset_id": this._asset.asset_id
        })

        break;
      case YT.PlayerState.PLAYING://1
        if (this.player) {
          // if (this.suspended_for_resize_at > 0) {
          //   this.player.seekTo(this.suspended_for_resize_at, true)
          //   this.suspended_for_resize_at = -1
          // }
          if (this.pauseOnStart) {
            this.player.pauseVideo()
            this.pauseOnStart = false;
          }
          let t = this.player.getCurrentTime()
          console.log("&&& PLAYING " + t)
          this.yt_debouncer = new Date().getTime()
          this.wss_service.sendMessageToOtherMembers({
            "YT_state": "PLAYING",
            "asset_id": this._asset.asset_id,
            t: t
          })
          // this.webrtc_service.YTState(this._asset.asset_id, "PLAYING", t)

          // if (this.play_video == "no") {
          //   this.player.stopVideo()
          // } else {
          if (!this.bDidSeek) {
            this.bDidSeek = true;
            let start_at: number = this._asset.data.start_at;
            if (start_at > 0) {
              console.log("&&& SEEK " + start_at)
              this.player.seekTo(start_at, true)
            }
            if (this._asset.data.volume) {
              this.player.setVolume(this._asset.data.volume)
            }
          }
        }

        break;
      case YT.PlayerState.PAUSED://2
        console.log("&&& PAUSED")
        this.wss_service.sendMessageToOtherMembers({
          "YT_state": "PAUSED",
          "asset_id": this._asset.asset_id,
        })
        // this.webrtc_service.YTState(this._asset.asset_id, "PAUSED")

        break;
      case YT.PlayerState.BUFFERING://3
        console.log("&&& BUFFERING")
        this.bGotBuffering = true;

        //  this.player.stopVideo()
        break;
      case YT.PlayerState.CUED://5
        console.log("&&& CUED")
        break;
      // case YT.PlayerState.SEEK:
      // console.log("&&& SEEK")
      // break;

    }
  }

}
