import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { CanvasService } from 'src/app/canvas/canvas.service';
import { PagesService } from 'src/app/pages/pages.service';

@Component({
  selector: 'conf-cont-del',
  templateUrl: './conf-cont-del.component.html',
  styleUrls: ['./conf-cont-del.component.scss']
})
export class ConfContDelComponent {
  @Input() set params(p: any) {
    this.contract_id = p.id
    this.page_size = p.page_size
    this.page = p.page
    this.search = p.search
  }
  contract_id: string | undefined
  page_size: number = 0 //for the next search
  page: number = 0;
  search: string = ""
  constructor(
    private canvas_service: CanvasService,
    private page_service: PagesService,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("del contract")
  }
  async deletContract() {
    try {
      if (this.contract_id) {
        await this.canvas_service.deleteContract(this.contract_id)
        await this.page_service.getContracts(this.page, this.page_size, this.search);
        this.closeIt()
      }
    } catch (e) {
      console.error(e)
    }
    // }

  }
  closeIt() {
    this.canvasStore.dispatch(new SelectToolOpts(""));
  }
}
