import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { WSSState } from 'src/app/wss/wss.model';
import { customerId, myId } from 'src/app/wss/wss.selectors';
import { PagesState } from '../../pages.model';
import { ciaQR, customizeAccount, hostUrl, repQR, siteEnabled } from '../../pages.selectors';
import { Clipboard } from '@angular/cdk/clipboard'
import { PagesService } from '../../pages.service'
import { NotifyUser } from '../../pages.actions';
import { getQueryParameter } from '../../../utils';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent {
  customer_id: string | undefined
  my_id: string | undefined
  host_url: string | undefined
  cia_qr_code: any;
  rep_qr_code: any;

  @ViewChild('company') company: ElementRef | undefined
  @ViewChild('personal') personal: ElementRef | undefined
  @ViewChild('embed') embed: ElementRef | undefined
  @ViewChild('embed_per') embed_per: ElementRef | undefined

  customize: any
  subscriptions: any[] = []

  constructor(
    private pagesState: Store<PagesState>,
    private wssState: Store<WSSState>,
    private clipboard: Clipboard,
    private page_service: PagesService,
  ) {
    console.log("links")
    this.subscriptions.push(this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      this.my_id = my_id
    }))

    this.subscriptions.push(this.pagesState.select(customizeAccount).subscribe((customize: any) => {
      this.customize = customize
      this.makeButtons()
    }))
    this.subscriptions.push(this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      this.customer_id = customer_id
    }))
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
    this.subscriptions.push(this.pagesState.select(ciaQR).subscribe((cia_qr_code: any) => {
      if (cia_qr_code) {
        setTimeout(() => {
          this.cia_qr_code = cia_qr_code.url;
        }, 1);
      }
    }))
    this.subscriptions.push(this.pagesState.select(repQR).subscribe((rep_qr_code: any) => {
      if (rep_qr_code) {
        setTimeout(() => {
          this.rep_qr_code = rep_qr_code.url;
        }, 1);
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.makeButtons()
  }
  makeButtons() {
    if (this.customize) {
      if (this.company) {
        this.company.nativeElement.innerHTML = this.page_service.makeCopyHTML("Company Link")
      }

      if (this.personal) {
        this.personal.nativeElement.innerHTML = this.page_service.makeCopyHTML("Personal Link")
      }

      if (this.embed) {

        this.embed.nativeElement.innerHTML = this.page_service.makeCopyHTML("Embed Button")

      }
      if (this.embed_per) {
        this.embed_per.nativeElement.innerHTML = this.page_service.makeCopyHTML("Embed Button")
      }
    }
  }
  customerLink() {
    let link = "https://" + this.host_url + "?customer_id=" + this.customer_id
    this.clipboard.copy(link);
    this.pagesState.dispatch(new NotifyUser("Company link copied"))
  }
  personalLink() {
    let link = "https://" + this.host_url + "?rep_id=" + this.my_id
    this.clipboard.copy(link);
    this.pagesState.dispatch(new NotifyUser("Personal link copied"))
  }
  embedCia() {
    let protocol = "https://"
    if (this.host_url && this.host_url.indexOf("local") >= 0) {
      protocol = "http://"
    }
    const host_url = protocol + this.host_url + "?customer_id=" + this.customer_id
    const img_src = protocol + this.host_url + "/call_btn/" + this.customer_id
    if (this.embed) {
      let btn_html = '<a href="' + host_url + '"><img src="' + img_src + '"></a>'
      this.clipboard.copy(btn_html);
      this.pagesState.dispatch(new NotifyUser("Company embed HTML copied"))

    }

  }
  embedPersonal() {
    let protocol = "https://"
    if (this.host_url && this.host_url.indexOf("local") >= 0) {
      protocol = "http://"
    }
    const host_url = protocol + this.host_url + "?rep_id=" + this.my_id
    const img_src = protocol + this.host_url + "/rep_btn/" + this.my_id
    if (this.embed) {

      let btn_html = '<a href="' + host_url + '"><img src="' + img_src + '"></a>'
      this.clipboard.copy(btn_html);
      this.pagesState.dispatch(new NotifyUser("Company embed HTML copied"))
    }
  }



}
