import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolsModule } from 'src/app/tools/tools.module';

import { ControlsModule } from 'src/app/controls/controls.module';
import { InCallComponent } from './in-call/in-call.component';

import { PhoneButtonsComponent } from './phone-buttons/phone-buttons.component';
import { DeskButtonsComponent } from './desk-buttons/desk-buttons.component';
import { KVideoModule } from 'src/app/k-video/k-video.module';
import { CallerComponent } from './caller/caller.component';
import { SideBySideComponent } from './side-by-side/side-by-side.component';
import { AssetsModule } from '../assets/assets.module';
import { InCallAssetComponent } from './in-call-asset/in-call-asset.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CanvasModule } from 'src/app/canvas/canvas.module';
import { ChatModule } from 'src/app/chat/chat.module';
import { KzLibModule } from 'src/app/kz-lib/kz-lib.module';


@NgModule({
  declarations: [
    InCallComponent,
    DeskButtonsComponent,
    PhoneButtonsComponent,
    CallerComponent,
    InCallAssetComponent,
    SideBySideComponent,
    // InCallDeskComponent,
    // InCallPhoneComponent
  ],
  imports: [
    CommonModule,
    ToolsModule,
    ControlsModule,
    KVideoModule,
    AssetsModule,
    DragDropModule,
    CanvasModule,
    ChatModule,
    KzLibModule
  ],
  exports: [
    InCallComponent
  ]
})
export class InCallModule { }
