import { Component, OnInit } from '@angular/core';
import { InCallComponent } from '../in-call/in-call.component';

@Component({
  selector: 'side-by-side',
  templateUrl: './side-by-side.component.html',
  styleUrls: ['./side-by-side.component.scss']
})
export class SideBySideComponent extends InCallComponent {



}
