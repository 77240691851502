import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetPencil, SetTool } from 'src/app/canvas/canvas.actions';
import { pencilWidth } from 'src/app/canvas/canvas.selectors';


@Component({
  selector: 'line-width',
  templateUrl: './line-width.component.html',
  styleUrls: ['./line-width.component.scss']
})
export class LineWidthComponent {
  _progress: number = 100
  top: number = 16
  width: number = 0


  @ViewChild('outComponent') outComponent: ElementRef | undefined;
  subscriptions: any[] = []

  constructor(
    public canvasStore: Store<CanvasState>
  ) {
    console.log("line width")

    this.subscriptions.push(this.canvasStore.select(pencilWidth).subscribe((pencil_width: number) => {
      this.width = pencil_width
      this._progress = this.width / 30 * 100 - 5;
      this.top = 21 - this.width / 2
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })

    this.canvasStore.dispatch(new SetTool("pencil"))
  }


  ngAfterViewInit() {
    if (this.outComponent) {
      this.outComponent.nativeElement.onmousemove = onMouseMove
    }
    let me = this
    function onMouseMove($event: any) {
      $event.stopPropagation()
      if ($event.buttons == 1) {
        me.setW($event)
      }
    }
  }
  setW($event: any) {
    let progress = Math.floor($event.offsetX / ($event.srcElement.clientWidth - 10) * 100)
    if (progress > 100) {
      progress = 100
    }


    let width = Math.floor(progress * .3)
    if (width < 1) { width = 1 }
    let top = 21 - width / 2

    setTimeout(() => {
      this._progress = progress
      this.width = width
      this.top = top
      this.canvasStore.dispatch(new SetPencil(this.width))
    })
  }


}
