import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'k-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {

  bShowOptions: boolean = false;
  bShowRight: boolean = false;

  _options: any[] = []
  sel_opt: number = -1
  @Input() set options(options: string[]) {
    this._options = []
    for (let i = 0; i < options.length; i++) {
      this._options.push({ name: options[i] })
    }
    //  this._options = _options
  }
  @Input() set options_icon(options: any[]) {
    this._options = options
  }

  @Input() multiple: boolean = false;
  @Input() title: string = "title"

  @Output() selected: EventEmitter<number> = new EventEmitter();

  bMouseIn: boolean = false
  constructor() {
    console.log("select")
  }


  showOptions() {
    this.bShowOptions = !this.bShowOptions
  }
  leave() {
    this.bMouseIn = false
    setTimeout(() => {
      this.bShowOptions = this.bMouseIn
    }, 300)

  }
  enter() {
    this.bMouseIn = true
  }

  selOption(i: number) {

    this.sel_opt = i;
    this.selected.emit(this.sel_opt)
    if (!this.multiple) {
      this.bShowOptions = false
    }
    // this.selected.emit(i)
  }
}
