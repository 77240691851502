export interface CalendarState {
  events: any[];
  from_t: number;
  to_t: number;
  offset: number;
  work_time: any;
  slots: any[];
  from_to_events: any[];
  week_events: any[];
  from_week_t: number;
  to_week_t: number
  week_slots: any[];
  hours: any;
  hour_slots: any[]
  refresh_counter: number
  bShowing24: boolean
  new_appt: any
  bHasCalToken: boolean,
  tmp: any,
  changed_tz: any
}

export const initialCalendarState: CalendarState = {
  events: [],
  from_t: 0,
  to_t: 0,
  offset: 0,
  slots: [],

  week_events: [],
  from_to_events: [],
  week_slots: [],
  from_week_t: 0,
  to_week_t: 0,

  hours: undefined,
  hour_slots: [],
  work_time: undefined,
  refresh_counter: 0,
  bShowing24: false,
  new_appt: undefined,
  bHasCalToken: false,
  tmp: {},
  changed_tz: undefined
}
