import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { KZCode } from 'src/app/canvas/canvas.actions';
import { ToolService } from 'src/app/tools/tool.service';
import { WSSState } from 'src/app/wss/wss.model';
import { callId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { NotifyError, NotifyUser, OnBoarding, ShowAsset, ShowFolder, ShowToolAddAsset, UpdateAsset } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { inPhone, showAsset, unselectAllAssets } from '../../pages.selectors';

@Component({
  selector: 'sel-asset',
  templateUrl: './sel-asset.component.html',
  styleUrls: ['./sel-asset.component.scss']
})
export class SelAssetComponent {
  img_src: any | undefined
  sanitized_url: any
  checked: boolean = false

  @Input() index: number = -2;
  @Input() selected: boolean = false
  @Output() selectedCahnged: EventEmitter<boolean> = new EventEmitter();

  // @Input() checked: boolean = false
  // @Output() checkedCahnged: EventEmitter<boolean> = new EventEmitter();

  /*
  asset_id: "63c1a81f0717de516ddc6f1e"
  bAdmin: false
  desc: "Picture 007.jpg"
  images: (5) [{…}, {…}, {…}, {…}, {…}]
  type: "image"
  */
  _asset: any
  name: string = "Name"
  using_phone: string | undefined
  @Input() set asset(a: any) {
    delete this.img_src
    // delete this.sanitized_url
    this._asset = a;
    if (this._asset) {
      this.name = this._asset.type
      if (this._asset.name) {
        this.name = this._asset.name
      }
      if ((this._asset.type == "iframe" ||
        this._asset.type == "on_board_p2p") && this._asset.url) {
        this.sanitized_url = this.sanitizer.bypassSecurityTrustResourceUrl(this._asset.url);
      } else if (this._asset.type == "image") {
        if (this._asset.images) {
          let image = this._asset.images[0]
          this.setImgSrc(image.key)
        } else if (this._asset.url) {
          this.img_src = this.sanitizer.bypassSecurityTrustResourceUrl(this._asset.url);
        } else {
          console.error("image asset has no images")
        }
      } else if (this._asset.type == "youtube") {
        this.img_src = "https://img.youtube.com/vi/" + this._asset.data + "/3.jpg"
      }
      else if (this._asset.type == "matterport") {

        let url = "https://my.matterport.com/show?m=" + this._asset.url + "&qs=1&ts=-1&dh=0&gt=0&hr=0&brand=0&help=0&play=0&dh=0&fp=0&mls=0"
        this.sanitized_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.name = this._asset.name
      } if (this._asset.type == "kuula") {
        let url = this._asset.url
        this.sanitized_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.name = this._asset.name
      } else if (this._asset.type == "uploaded") {
        this.setSecURL(this._asset.key)
      } else if (this._asset.type == "iframe_pro" || this._asset.type == "on_board_pro" || this._asset.type == "on_board_wnd") {
        let url = new URL(this._asset.url);
        url.searchParams.set('kzCode', 'none');
        // let url = this._asset.url + "?kzCode='none'"
        this.sanitized_url = this.sanitizer.bypassSecurityTrustResourceUrl(url.href);
      }
      // console.log("sel-asset " + this.checked + " " + this.name)
    }
  };
  async setImgSrc(key: string) {
    try {
      this.img_src = await this.tools_service.getKeyURL(key)
    } catch (e) {
      console.error(e + " " + "sel asset setImgSrc")
    }
  }
  async setSecURL(key: string) {
    try {
      let img_src = await this.tools_service.getKeyURL(key)
      this.sanitized_url = this.sanitizer.bypassSecurityTrustResourceUrl(img_src);//+ "?autoplay=1")
    } catch (e) {
      console.error(e + " " + "sel asset setSecURL")
    }
  }
  call_id: string | undefined
  subscriptions: any[] = []
  // has_on_board: boolean = false
  constructor(
    public sanitizer: DomSanitizer,
    public pagesState: Store<PagesState>,
    public wss_service: WSSService,
    public wssState: Store<WSSState>,
    public tools_service: ToolService,
    public router: Router,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("sel-asset const")
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  initSubClass() { }
  ngOnInit() {
    // this.subscriptions.push(this.pagesState.select(hasOnBoard).subscribe((has_on_board: boolean) => {
    //   console.log("sel asset has_on_board " + has_on_board)
    //
    //   this.has_on_board = has_on_board
    // }))


    this.subscriptions.push(this.pagesState.select(inPhone).subscribe((in_phone: string | undefined) => {
      this.using_phone = in_phone
    }))
    this.subscriptions.push(this.pagesState.select(unselectAllAssets).subscribe((num: number) => {
      if (num) {
        this.checked = false
        this.selected = false
      }
    }))
    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      let checked: boolean = false
      if (asset && this._asset) {
        if (asset.asset_id) {
          checked = this._asset.asset_id == asset.asset_id
        } else {
          checked = this._asset.asset_id == asset._id
        }
      }
      this.checked = checked
    }))
    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (!this.call_id && call_id) {
        this.checked = false
      }
      this.call_id = call_id
    }))
    this.initSubClass()
  }
  async showAsset($event: any) {
    this.checked = $event
    if ($event) {



      if (!this.call_id &&
        (this._asset.type == 'iframe_pro'
          || this._asset.type == 'on_board_pro'
          || this._asset.type == 'on_board_wnd')
      ) {
        this.pagesState.dispatch(new NotifyError("This asset type (Onboard Lite/Pro) can only be opened inside a call."))
        setTimeout(() => this.checked = false)
        return
      }

      if (this.using_phone == "caller" && (
        this._asset.type == 'iframe_pro'
        || this._asset.type == 'on_board_pro'
        || this._asset.type == 'on_board_wnd'
        || this._asset.type == 'on_board_p2p')
      ) {
        this.pagesState.dispatch(new NotifyError("Onboard Lite/Pro is only available when the caller is using a desktop."))
        setTimeout(() => this.checked = false)
        return
      }


      if (this._asset.type == "folder") {
        // this.pagesState.dispatch(new ShowFolder({}))
        this.pagesState.dispatch(new ShowFolder(this._asset.asset_id))
      } else {
        // if (this._asset.type == "iframe_pro"
        //   || this._asset.type == "on_board_pro"
        //   || this._asset.type == 'on_board_p2p'
        //   || this._asset.type == "on_board_wnd") { //The onboarded frames
        //   // if (!this.has_on_board) {
        //     this.pagesState.dispatch(new NotifyUser("Talk to you keyzii rep to enable on boarding"))
        //     setTimeout(() => {
        //       this.checked = false
        //     }, 10)
        //     return
        //   }
        // } else {
        this.pagesState.dispatch(new ShowAsset(this._asset)) //This will close the tools, but they are not closed because we are not showintg it, I will close it when the screen is shared
        // }
      }

      let a = Object.assign({}, this._asset)
      if (a.images) {
        let imgs = Object.assign([], a.images)
        for (let i = 0; i < imgs.length; i++) {
          let img = Object.assign({}, imgs[i])
          try {
            img.url = await this.tools_service.getKeyURL(img.key)
            imgs[i] = img
            a.images = imgs
          } catch (e) {
            console.error(e + " " + "sel asset showAsset")
          }
        }
      }
      if (a.key) {
        try {
          a.url = await this.tools_service.getKeyURL(a.key)
        } catch (e) {
          console.error(e + " " + "sel asset getKeyURL1")
        }

      }
      if (this._asset.key) {
        try {
          a.url = await this.tools_service.getKeyURL(this._asset.key)
        } catch (e) {
          console.error(e + " " + "sel asset getKeyURL2")
        }
      }
      if (a.type == "on_board_pro"
        || a.type == "on_board_p2p"
        || a.type == "on_board_wnd"
        || a.type == "iframe_pro") {
        try {
          let url = await this.wss_service.setkzCode(a.url)
          url += "&on_board=" + a.type
          a.url = url
          if (a.type == "on_board_wnd") {
            let msg = {
              on_board: {
                url: url,
                type: a.type
              }
            }
            this.wss_service.sendMessageToOtherMembers(msg)
            this.pagesState.dispatch(new OnBoarding(true))
          }
          if (this._asset.type == 'on_board_p2p') {
            url += "&master=true"
            let s_asset = Object.assign({}, this._asset)
            s_asset.url = url
            this.pagesState.dispatch(new ShowAsset(s_asset)) //This will close the tools, but they are not closed because we are not showintg it, I will close it when the screen is shared
          }
          this.pagesState.dispatch(new OnBoarding(true))
        } catch (e) {
          console.error(e)
        }

        // let url = a.url + "?kzCode=" + code
      }
      if (a.type != "on_board_wnd") {
        this.wss_service.sendMessageToOtherMembers({ show_asset: a })
      }

      if (this.using_phone == "caller") {
        if (this._asset.type == "uploaded"
          || this._asset.type == "youtube") {
          this.pagesState.dispatch(new NotifyUser("Caller needs to play video first"))
        }
      }

    } else {
      this.pagesState.dispatch(new ShowFolder())
      this.pagesState.dispatch(new ShowAsset({}))
      // this.wss_service.sendMessageToOtherMembers({ show_asset: {} })
      let msg: any = {
        show_asset: "none"
      }
      this.wss_service.sendMessageToOtherMembers(msg)
    }
  }
  is_dobule: boolean = false;

  doubleClick($event: any) {
    this.is_dobule = true
    this.showAsset(!this.checked)
  }

  select() {
    this.selected = !this.selected
    this.selectedCahnged.emit(this.selected)
  }

  async saveName() {
    try {
      if (this._asset.name != this.name) {
        let asset = Object.assign({}, this._asset)
        asset.name = this.name
        await this.tools_service.changeAsset(asset)
        this._asset = asset;
        this.pagesState.dispatch(new UpdateAsset(asset))
      }
    }
    catch (e: any) {
      console.error(e)
    }
  }
  onKeyPress($event: any) {
    $event.stopPropagation()
    if ($event.keyCode == 13) {
      $event.currentTarget.blur()
    }
  }
}
