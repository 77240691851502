import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from '../chapala-mls-service';
import { GotMLSMsg, GSetMLSPage, SelProperty } from '../chapala-mls.actions';
import { ChapalaMLSState } from '../chapala-mls.model';
import { gotMLSMsg, gotProperties, pageNo } from '../chapala-mls.selectors';

@Component({
  selector: 'mls-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent {
  subscriptions: any[] = []
  num_props: number = 0
  page_size: number = 20
  properties: any;
  page_no: number = 1;

  @ViewChild('propout') outComponent: ElementRef | undefined;
  content_width: number = 0
  margin: number = 0;
  constructor(
    private chapalaMLState: Store<ChapalaMLSState>,
    private chapala_mls_service: ChapalaMLSService,

    // private currency_directive: CurrencyMaskDirective,
  ) {
    console.log("mls-properties");
    this.chapala_mls_service.getStates()
  }
  ngAfterViewInit() {
    this.setSize()

  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) { //aspect ratio is 1280, 720
    setTimeout(() => {
      this.setSize()
    }, 100)
  }

  setSize() {
    setTimeout(() => {
      if (this.outComponent) {
        let width = this.outComponent.nativeElement.clientWidth
        let cols = Math.floor(width / 240)
        this.content_width = cols * 240
        this.margin = (width - this.content_width) / 2
        console.log("w " + this.content_width + " Margin " + this.margin)
      }
    })
  }
  ngOnDestroy() {

    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngOnInit(): void {
    this.subscriptions.push(this.chapalaMLState.select(gotProperties).subscribe((properties: any) => {
      if (properties) {
        if (properties.pagination) {
          this.num_props = properties.pagination.total
          this.page_size = properties.pagination.limit
        }
      }
      this.properties = properties.properties
    }))
    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.hasOwnProperty("sel_prop")) {

          let index = msg.sel_prop
          let property = this.properties[index]
          this.chapalaMLState.dispatch(new SelProperty(property))
          return
        }
        if (msg.hasOwnProperty("page")) {
          let page_no = msg.page
          this.chapalaMLState.dispatch(new GSetMLSPage(page_no))
        }
      }
    }))

  }
  search_url(search_url: any) {
    throw new Error('Method not implemented.');
  }
  selProperty(index: number) {
    let msg: any = {
      sel_prop: index
    }
    this.chapala_mls_service.sendMsg(msg)
  }
  page($event: any) {
    console.log("sel page " + $event.pageIndex)
    let msg: any = {
      page: $event.pageIndex + 1
    }
    this.chapala_mls_service.sendMsg(msg)
  }
}
