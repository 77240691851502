<div class="out">
  <div class="header">
    <div class="h_p font_sub_title"  kzLib (click)="select('live')">
      <div class="ctr">
        <div class="ctr_tx">
          Live Calls
          <div *ngIf="tab=='live'" class="selected">
          </div>
        </div>
      </div>
    </div>
    <div class="h_p font_sub_title"  kzLib (click)="select('leads')">
      <div class="ctr">
        <div class="ctr_tx">
          Leads
          <div *ngIf="tab=='leads'" class="selected">
          </div>
        </div>
      </div>
    </div>
    <div class="h_p font_sub_title"  kzLib (click)="select('history')">
      <div class="ctr">
        <div class="ctr_tx">
          History
          <div *ngIf="tab=='history'" class="selected">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="body">

    <call-active *ngIf="tab=='live'"></call-active>
    <rep-leads *ngIf="tab=='leads'"></rep-leads>
    <!-- <call-queue *ngIf="tab=='queue'"></call-queue> -->
    <call-history *ngIf="tab=='history'"></call-history>

  </div>
  <live-flash *ngIf="bShowLiveCalls" class="live_calls"></live-flash>
</div>
