import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { selectToolOptions } from 'src/app/canvas/canvas.selectors';

@Component({
  selector: 'kpay-set-up',
  templateUrl: './set-up.component.html',
  styleUrls: ['./set-up.component.scss']
})
export class SetUpComponent {
  bFormUploaded: boolean = false
  doing_t: string = "Start onboarding"
  doing_b: string = "Please fill out the form below with your basic contact information. Once submitted, you'll receive an email from PaymentStars to begin your onboarding process."
  constructor(
    public canvasStore: Store<CanvasState>,
  ) {
    this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      if (opt) {
        let bFormUploaded = (opt.name == "kps1") //from closing info
        if (bFormUploaded) {
          this.setFormDone()
        }
      }
    })

  }
  setFormDone() {
    setTimeout(() => {
      this.doing_t = "Processing request..."
      this.doing_b = "Check your inbox—both you, keyzii, and PaymentStars will receive a copy of this request form. Once your PaymentStars account is created and approved, this page will update."
    })
  }
  openPS() {
    window.open("https://www.paymentstars.com/")

  }
  openForm() {
    this.canvasStore.dispatch(new SelectToolOpts("pkay_info"))
  }
}
