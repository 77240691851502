import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { ContactComponent } from './contact/contact.component';
import { FormsModule } from '@angular/forms';

import { ControlsModule } from 'src/app/controls/controls.module';
import { CustomizationComponent } from './customization/customization.component';
import { UsersComponent } from './users/users.component';
import { LinksComponent } from './links/links.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FileUploadModule } from 'ng2-file-upload';
import { BillingComponent } from './billing/billing.component';
import { CanvasModule } from 'src/app/canvas/canvas.module';
import { SchedulingModule } from '../scheduling/scheduling.module';
import { AddOneUserComponent } from './users/add-one-user/add-one-user.component';
import { ApiComponent } from './api/api.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CreditCardComponent } from './billing/credit-card/credit-card.component';
import { NewCcComponent } from './billing/new-cc/new-cc.component';
import { CardsComponent } from './billing/cards/cards.component';
import { BillInfoComponent } from './billing/bill-info/bill-info.component';
import { DowngradeComponent } from './billing/downgrade/downgrade.component';
import { WarningComponent } from './billing/warning/warning.component';
import { PaymentsComponent } from './payments/payments.component';
import { CardsDelComponent } from './billing/cards-del/cards-del.component';
import { DelUserComponent } from './users/del-user/del-user.component';
import { KpayModule } from 'src/app/kpay/kpay.module';
import { KzLibModule } from 'src/app/kz-lib/kz-lib.module';


@NgModule({
  declarations: [
    ProfileComponent,
    ContactComponent,
    CustomizationComponent,
    UsersComponent,
    LinksComponent,
    BillingComponent,
    AddOneUserComponent,
    ApiComponent,
    CalendarComponent,
    CreditCardComponent,
    NewCcComponent,
    CardsComponent,
    CardsDelComponent,
    BillInfoComponent,
    DowngradeComponent,
    WarningComponent,
    PaymentsComponent,
    DelUserComponent,


  ],
  imports: [
    CommonModule,
    FormsModule,
    ControlsModule,
    ClipboardModule,
    FileUploadModule,
    CanvasModule,
    SchedulingModule,
    KpayModule,
    KzLibModule
    // RecaptchaV3Module
  ], exports: [
    ProfileComponent,
    CreditCardComponent,
    NewCcComponent,
    BillInfoComponent,
    DowngradeComponent,
    WarningComponent,
    AddOneUserComponent,
    DelUserComponent,

  ], providers: [

    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LfCFCwqAAAAAAERA77uwKqJAkcVtLQ7-Y5W_CwH" } //callvendi.com
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LeSESwqAAAAAPIJxkboPq9C2ccS1HzqMWXdOJks" } //keyzii.com
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LcMFywqAAAAAHbHV7SEh0G7bN5c8OKkYarssZJT" } //localhost

  ]
})
export class ProfileModule { }
