import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'k-select-icon',
  templateUrl: './select-icon.component.html',
  styleUrls: ['./select-icon.component.scss']
})
export class SelectIconComponent {


  bShowOptions: boolean = false;
  bShowRight: boolean = false;

  _options: any[] = []
  sel_opt: number = -1
  @Input() set options(options: any[]) {
    this._options = options
  }

  @Input() title: string = "title"

  @Output() selected: EventEmitter<number> = new EventEmitter();

  bMouseIn: boolean = false
  constructor() {
    console.log("select")
  }


  showOptions() {
    this.bShowOptions = !this.bShowOptions
  }
  leave() {
    this.bMouseIn = false
    setTimeout(() => {
      this.bShowOptions = this.bMouseIn
    }, 300)

  }
  enter() {
    this.bMouseIn = true
  }

  selOption(i: number) {

    this.sel_opt = i;
    this.selected.emit(this.sel_opt)

    // this.selected.emit(i)
  }
}
