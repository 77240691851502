import { Component } from '@angular/core';
import { NotifyError } from 'src/app/pages/pages.actions';
import { BillingComponent } from '../billing.component';

@Component({
  selector: 'cards-del',
  templateUrl: './cards-del.component.html',
  styleUrls: ['../billing.component.scss', './cards-del.component.scss']
})
export class CardsDelComponent extends BillingComponent {
  async delCard(i: number) {
    if (this.cards.length == 1) {
      this.pagesState.dispatch(new NotifyError("You need to leave at least one card"))
      return
    }
    try {
      let card = this.cards[i]
      await this.pay_service.delCC(card)
      this.pagesState.dispatch(new NotifyError("Deleted Credit Card"))

    } catch (e) {
      this.pagesState.dispatch(new NotifyError("Error deleting Credit Card"))
    }
  }
}
