<div class="cont">

  <!-- <div class="p_line"> </div>
  <img class="circle" [style.left.%]="progress" src="assets/images/yellow_circle.svg" alt="3"> -->
  <div class="out">
    <div class="title">
      Block Chain
    </div>
    <div class="sub-title">
      Using Cardano (c).
    </div>
    <div *ngIf="!chain" class="comment">
      <p>Digital certifications will be done in the Cardano test net. This is the test system and should only be used to see how digital cerfification works.</p>
      <p>Enable certifications in the Cardano main network in ... </p>
    </div>
    <div *ngIf="chain">
      <p> your blockchained <strong>blockchain.pdf</strong> document is in your Downloads folder</p>
      <p> Wait a few minutes for the blockchain to finish, and Click to see the transaction where the pdf hash was added to the cardano blockchain</p>
      <p><a target="_blank" [href]="chain.url">Cardano Blockchain Explorer</a></p>
      <p>Search for the PDF hash in the metadata</p>
      <p><strong> {{chain.hash}}</strong></p>
      <p>To independently verify the PDF you can use openssl to get the hash</p>
      <p> openssl dgst -sha256 blockchain.pdf</p>
      <p>the hash and the same hash is now inmutable in the cardando block chain ledger</p>
      <p>
    </div>

    <div *ngIf="blockchain=='once' && !ready_to_block_chain" class="error">
      The contract can not be blockchained until is singed by everbody
    </div>
    <div *ngIf="blockchain=='all' && !ready_to_block_chain" class="error">
      The contract can not be blockchained until it is fully signed
    </div>
  </div>
  <k-button *ngIf="bShowCancel" text="Cancel" class="cancel" background="#fff"  kzLib (click)="cancel()"></k-button>
  <k-button [text]="next_text" class="next" color="white" background="black"  kzLib (click)="done()"></k-button>
  <k-spinner class="abs_center" *ngIf="bShowSpinner"></k-spinner>

</div>
