import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { checkPhone } from 'src/app/utils';

@Component({
  selector: 'k-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class KInputComponent {
  @ViewChild('input') my_input!: ElementRef;
  _icon: string | undefined
  @Input() set icon(icon: string) {
    this._icon = icon
  }
  @Input() width = "100%"
  setIcon() {
    if (this._icon && this.my_input) {
      this.my_input.nativeElement.style["background-image"] = "url(" + this._icon + ")"
      setTimeout(() => this.display_placeholder = "     " + this._placeholder)
    } else {
      setTimeout(() => this.display_placeholder = this._placeholder)
    }
  }
  @Input() tabindex: number = 1
  ngAfterViewInit() {
    this.setIcon()
    let me = this
    function onFocus() {
      me.display_placeholder = ""
      me.my_input.nativeElement.style["background-image"] = ""
    }
    function offFocus() {
      me.setIcon()
    }

    if (this.my_input) {
      this.my_input.nativeElement.addEventListener('touchstart', onFocus, false);
      this.my_input.nativeElement.addEventListener('pointerdown', onFocus, false);
      this.my_input.nativeElement.addEventListener('mouseleave', offFocus, false);

      setTimeout(() => {
        this.my_input.nativeElement.focus()
        this.my_input.nativeElement.select()
        console.log("Set foucs")
      }, 100)
    }
  }


  display_placeholder: string = ""
  _placeholder: string = ""
  @Input() set placeholder(p: string) {
    this._placeholder = p;
    setTimeout(() => this.display_placeholder = this._placeholder)
  }
  @Input() type: string = ""
  _model: string = "";
  bInvalid: boolean = false;

  @Input() set invalid(value: boolean) {
    this.bInvalid = value;
  }
  @Output() invalidChange: EventEmitter<boolean> = new EventEmitter();



  @Input() set myModel(value: string) {
    this._model = value;
  }
  @Output() myModelChange: EventEmitter<string> = new EventEmitter();
  checkEmail(): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this._model)) {
      let parts = this._model.split('@')
      if (parts.length > 1) {
        let index = parts[1].indexOf('.')
        if (index > 2) {
          this.bInvalid = false;
          this.invalidChange.emit(false)
          return true
        }
      }
    }
    this.invalidChange.emit(true)
    this.bInvalid = true
    return false
  }

  checkPhone(): boolean {
    this._model = checkPhone(this._model)


    return true
  }
  @Input() autocomplete: string | undefined

  modelChanged($event: any) {
    if (this._model.length > 0) {
      this.my_input.nativeElement.style["background-image"] = ""
    }
  }
  keyDown($event: any) {
    // console.log("key down "+$event.which)
    if ($event.which == 9) {
      $event.preventDefault()
      this.lostFocus()
    }
  }
  keyUp($event: any) {
    // console.log("key up "+$event.which)
    if ($event.which == 9 || $event.which == 13) {
      this.lostFocus()
      console.log("Tab was pressed move to the next field")
    } else {
      if (this.type == "email") {
        if (this.checkEmail()) {
        }
      }
      if (this.type == "phone") {
        if (this.checkPhone()) {
        }
      }
    }
  }
  lostFocus() {
    this.myModelChange.emit(this._model)
  }
  // changed($event: any) { //if we do this in the ngModelChange, it will be sending the chat on every letter
  //   this.myModelChange.emit(this._model)
  // }
}
