import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'k-memo',
  templateUrl: './memo.component.html',
  styleUrls: ['./memo.component.scss']
})
export class MemoComponent implements OnInit {
  _model: string = ""
  @Input() set myModel(value: string) {
    this._model = value;
  }
  @Output() myModelChange: EventEmitter<string> = new EventEmitter();
  display_placeholder: string = ""
  _placeholder: string = ""
  @Input() set placeholder(p: string) {
    this._placeholder = p;
    setTimeout(() => this.display_placeholder = this._placeholder)
  }
  constructor() { }

  ngOnInit(): void {
  }
  ngModelChange() {
    this.myModelChange.emit(this._model)
  }
}
