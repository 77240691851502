<div class="out">
  <div class="header">
    <div class="h_p font_sub_title no_h_left selected"  kzLib (click)="select('calendar')">Calendar </div>
    <!-- <div class="h_p font_sub_title" [ngClass]="{selected:tab=='settings'}"  kzLib (click)="select('settings')">Settings</div> -->
  </div>

  <div class="body">
    <app-calendar class="calendar"> </app-calendar>

    <div class="appointment">
      <div class="font_title m20 title appt">Appointments</div>
      <div class="apt_container">
        <div class="appt m20" *ngFor="let slot of slots">
          <div class="font_sub_title m20"> {{slot.event.fdate}}</div>
          <div class="font_text m20"> {{slot.event.name}}</div>
          <div class="font_text m20"> {{slot.event.email}}</div>
          <div class="font_text m20"> {{slot.event.notes}}</div>
          <k-button background="transparent" class="link" icon="link.svg"  kzLib (click)="getLink(slot)"></k-button>
        </div>
      </div>
    </div>
  </div>

  <div class="top_line_container">
    <div class="top_line">
      <div class="filler"></div>
      <!-- <img *ngIf="bNeedsConnection" class="connect" src="assets/images/google_connect_cal.svg"  kzLib (click)="linkCal()"> -->
      <!-- <k-button *ngIf="tab=='calendar'" min_width="150" class="add" [text]="btn_tx"  kzLib (click)="createEvent()"></k-button> -->
      <div class="tzl"  kzLib (click)="bShowSettings=!bShowSettings">
        Settings
        <img class="expand" src="assets/images/exp10.svg" [ngClass]="{flip:bShowSettings}">
      </div>
      <img *ngIf="bNeedsConnection" class="connect" src="assets/images/google_connect_cal.svg"  kzLib (click)="linkCal()">

    </div>
  </div>
  <cal-settings *ngIf="bShowSettings" class="settings" (close)="bShowSettings=false"></cal-settings>

</div>
