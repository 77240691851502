
export var colors: string[] = ["#3B5FE0", "#E06D3B", "#E0D53B", "#3DE049", "#3BE0C4", "#3BDCE0", "#A13BE0", "#E03BCF", "#E03B8D", "#653BE0", "#E03B3D"]

export var asset_types: any[] = [
  { desc: "All", type: "all" }
  // , { desc: "Onboarding Light", type: "iframe_pro" }
  // , { desc: "Onboarding Pro", type: "on_board_pro" }
  // , { desc: "Onboarding Pro", type: "on_board_p2p" }
  // , { desc: "Onboarding Pro", type: "on_board_wnd" }
  , { desc: "Contracts", type: "contract" }
  , { desc: "PDF presentations", type: "pdf" }
  , { desc: "Virtual Tour", type: "matterport" }
  , { desc: "Image", type: "image" }
  , { desc: "Video", type: "video" }
  , { desc: "iFrame", type: "iframe" }
  // , { desc: "Folder", type: "folder" }
  // , { desc: "Screen Share", type: "share" }
];

export var menus: any[] = [
  { image: "live_call.svg", text: "Live Call", nav: "/call" },
  // { image: "profile.svg", text: "Profile", nav: "/profile" },
  { image: "assets.svg", text: "Assets", nav: "/assets" },
  // { image: "k-sign.svg", text: "keyzii", dark: "sign", nav: "/assets", disabled: "k-sign-gray.svg" }
  // { image: "scheduling.svg", text: "Calendar", nav: "/calendar" },
  // { image: "contacts.svg", text: "Contacts", nav: "/contacts", disabled: "contacts_gray.svg" },
  { image: "call_hub.svg", text: "Call Hub", nav: "/hub" },//, disabled: "call_management_gray.svg" },
  // { image: "reporting.svg", text: "Reporting", nav: "/reports" },
  { image: "k-pay.svg", text: "keyzii", dark: "pay", nav: "/kpay" },
  // { image: "pen.svg", text: "Training", nav: "/rec_training" },
]

export class Hash {
  map: any = {}
  _array: any[] = []
  constructor() {

  }
  get length() {
    return this._array.length
  }
  get array() {
    return this._array
  }
  toString() {
    return JSON.stringify(this._array)
  }
  has(num: any) {
    if (num in this.map) {
      return true;
    }
    return false
  }
  insert(num: any) {
    if (num in this.map) {
      return false;
    }
    else {
      /* some variation of the below approach is fine */
      const index = this._array.length;
      this._array.push(num);
      this.map[num] = index;
      return true;
    }
  }

  remove(num: any) {
    if (num in this.map) {
      if (this._array.length == 1) {
        this.map = {};
        this._array = [];
      }
      else {
        const thisIndex = this.map[num];
        const lastIndex = this._array.length - 1;
        if (thisIndex == lastIndex) {
          this._array.splice(-1, 1);
        }
        else {
          this._array[thisIndex] = this._array[lastIndex];
          this._array.splice(-1, 1);
          this.map[this._array[thisIndex]] = thisIndex;
        }
        delete this.map[num];
      }
      return true;
    }
    else {
      return false;
    }
  }
  getRandom() {
    if (this._array.length) {
      /* Splitting up these variables for readability */
      const randomNumber = Math.random() * this._array.length;
      const randomIndex = Math.floor(randomNumber);
      return this._array[randomIndex];
    }
    else {
      return false;
    }
  }
};
export function fomatTime(ms: number) {
  let sec_num = Math.floor(ms / 1000)
  var h = Math.floor(sec_num / 3600)
  var m = Math.floor((sec_num - (h * 3600)) / 60);
  let hs = h * 3600
  let min_s = m * 60
  let total = hs + min_s
  var s = sec_num - total;
  let hours = h.toString()
  let minutes = m.toString()
  let seconds = s.toString()
  if (h < 10) { hours = "0" + hours; }
  if (m < 10) { minutes = "0" + minutes; }
  if (s < 10) { seconds = "0" + seconds; }

  return hours + ':' + minutes + ':' + seconds;
}

export function validateEmail(mail: string) {
  let parts = mail.split('@')
  if (parts.length > 1) {
    let index = parts[1].indexOf('.')
    if (index > 1) {
      if (!/^\w+([\.-]?\w+)/.test(parts[0])) {
        return false;
      }
      if (!/\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(parts[1])) {
        return false;
      }
      return true;
      // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(mail)) {
      //   return (true);
      // }
    }
  }
  return (false)
}


//We need to save the parameters before we go to login, because they will be lost
let orig_parameters: string
export function initQueryParameters() {
  orig_parameters = window.location.href
}

export function getQueryParameter(key: string): string | undefined {
  //  console.log("getQueryParameter " + key + " = " + rv)
  return paramsFromURl(key, orig_parameters);
}

export function paramsFromURl(key: string, url: string): string | undefined {
  var results = new RegExp('[\?&]' + key + '=([^&#]*)').exec(url);
  if (results == null) {
    //    console.log("getQueryParameter " + key)
    return undefined;
  }
  let rv = decodeURIComponent(results[1]) || undefined;
  //  console.log("getQueryParameter " + key + " = " + rv)
  return rv;
}
export function fixSVGViewPortAndSize(xmlString: string, vb_x: number, vb_y: number, vb_w: number, vb_h: number, w: number, h: number) {
  var parser = new DOMParser();
  var xmlDoc = parser.parseFromString(xmlString, "text/xml"); //important to use "text/xml"
  let el = xmlDoc.getElementsByTagName("svg")[0]
  el.setAttribute("viewBox", "" + vb_x + " " + vb_y + " " + vb_w + " " + vb_h)
  el.setAttribute("width", "" + w)
  el.setAttribute("height", "" + h)
  var serializer = new XMLSerializer();
  var xmlString = serializer.serializeToString(xmlDoc);
  return xmlString
}
export function getPageContentSize(el: any, wd: number = 0): any {
  let parent = el.parentNode;
  if (parent) {
    if (parent.className) {
      if (parent.className.indexOf("page-content") >= 0 || wd > 10) {
        let dim = { width: parent.clientWidth, height: parent.clientHeight };
        console.log("getPageContentSize " + JSON.stringify(dim))
        return dim
      }
    }
    return getPageContentSize(parent, ++wd)
  }
}
export function base64ToFile(dataURL: string) {
  return new Promise<ArrayBuffer>(async (resolve, reject) => {
    const arr = dataURL.split(',');
    if (arr && arr.length > 0) {
      let line = arr[0]
      if (line) {
        return (fetch(dataURL)
          .then(function(result) {
            resolve(result.arrayBuffer());
          }));
      }
    }
    reject();
  })
}
export const b64toBlob = (src_img: string, contentType = '', sliceSize = 512) => {
  let parts = src_img.split(',')
  let b64Data = parts[parts.length - 1]
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function checkPhone(event: string): string {
  console.log("check phone <-- [" + event + "]")
  let int = ""
  if (event.startsWith('+')) {
    let parts = event.split("(")
    if (parts.length > 0) {
      parts = parts[0].split(" ")

      if (parts.length > 1 && parts[1].length > 0) {
        int = parts[0] + " "
        event = parts[1]
        for (let i = 2; i < parts.length; i++) {
          event += parts[i]
        }
      } else {
        int = event
        event = ""
      }
    }
  }
  console.log("check phone int [" + int + "]")
  console.log("check phone event [" + event + "]")

  let newVal = event.replace(/\D/g, '');

  if (newVal.length === 0) {
    newVal = '';
  } else if (newVal.length <= 3) {
    newVal = newVal.replace(/^(\d{0,3})/, '$1');
  } else if (newVal.length <= 6) {
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1 $2');
  } else if (newVal.length <= 9) {
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1 $2 $3');
  } else {
    newVal = newVal.substring(0, 10);
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1 $2 $3');
  }
  console.log("check phone newVal " + newVal)
  let rv = int + newVal
  console.log("check phone rv " + rv)
  return rv

}
export function getAssetFromURL(url: string) {
  let video_id = getYouTubeId(url)
  if (video_id) {
    return {
      asset_id: video_id,
      bAdmin: false,
      data: video_id,//"26i-T7O_X10",
      name: "Keyzii",
      type: "youtube"
    }
  }
  let matterport_id = getMaterportId(url)
  if (matterport_id) {
    return {
      type: "matterport",
      asset_id: matterport_id,
      url: matterport_id
    }
  }
  let kula_url = getKuulaURL(url)
  if (kula_url) {
    return {
      type: "kuula",
      asset_id: "kuula",
      url: kula_url//"https://kuula.co/share/NjV1w?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
      // url: "https://kuula.co/post/7DTNb/collection/7PNXK"
    }
  }
  if (isImage(url)) {
    return {
      type: "image",
      asset_id: "image",
      url: url//"https://kuula.co/share/NjV1w?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
      // url: "https://kuula.co/post/7DTNb/collection/7PNXK"
    }
  }
  if (url.startsWith("https://")) {
    return {
      type: "iframe",
      asset_id: "iframe",
      url: url//"https://kuula.co/share/NjV1w?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
      // url: "https://kuula.co/post/7DTNb/collection/7PNXK"
    }
  }

  return undefined
}
//https://my.keyzii.com/assets/images/keyzii.svg, https://my.keyzii.com/assets/images/2007.png
export function isImage(img_url: string) {
  if (img_url.startsWith("data:image")) {
    return true
  }
  let ext = getUrlExtension(img_url)
  if (ext) {
    if (ext == "svg" || ext == "png" || ext == "jpg" || ext == "jpeg") {
      return true
    }
  }
  return false
}

export function getUrlExtension(img_url: string) {
  let parts1 = img_url.split(/[#?]/)
  if (parts1.length > 0) {
    let parts2 = parts1[0].split('.')
    if (parts2) {
      let parts3 = parts2.pop()
      if (parts3) {
        return parts3.trim().toLowerCase();
      }
    }
  }
  return undefined
}

export function getImages(url: string) {
  let rv = []
  let parts = url.split(",")
  for (let i = 0; i < parts.length; i++) {
    let img_url = parts[i]
    if (isImage(img_url)) {
      rv.push(img_url)
    }
  }
  return rv;
}

//https://kuula.co/share/NjV1w?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1
export function getKuulaURL(url: string) {
  if (url.startsWith("https://kuula.co")) {
    return url
  }
  return undefined
}

//https://my.matterport.com/show?m=j4RZx7ZGM6T&title=0&qs=1&hr=0&brand=0&help=0&play=1&dh=0&fp=0
export function getMaterportId(url: string) {
  if (url.startsWith("https://my.matterport.com")) {
    let val = paramsFromURl("m", url)
    if (val) {
      return val
    }
    if (url.indexOf("http") >= 0) {
      let parts = url.split("/")
      if (parts.length >= 0) {
        return parts[parts.length - 1]
      }
      return undefined
    }
    return url
  }
  return undefined
}

//https://www.youtube.com/watch?v=26i-T7O_X10
export function getYouTubeId(link: string) {
  if (link.startsWith('https://youtu.be') || link.startsWith('https://www.youtube.com/')) {

    var video_id: string | undefined
    let parts1: string[] = link.split('v=')
    if (parts1.length > 1) {
      video_id = parts1[1]
      let parts2 = video_id.split('&')
      if (parts2) {
        let vid1 = parts2[0]
        if (vid1) {
          video_id = vid1
        }
      } //https://youtu.be/aXYmxYePCes?si=K_vJXwYzQfckPE6v
    } else if (link.indexOf('youtu.be') >= 0) { // https://youtu.be/me19SUmWu2s
      parts1 = link.split('youtu.be/')
      if (parts1.length > 1) {

        let params = parts1[1].split('?')
        video_id = params[0]
      }
      //  https://www.youtube.com/live/UEJdOaIMgPo?feature=shared
    } else if (link.indexOf('https://www.youtube.com/') >= 0) {
      let parts1: string[] = link.split('/')
      if (parts1.length > 0) {
        let last = parts1[parts1.length - 1]//UEJdOaIMgPo?feature=shared
        let params = last.split('?')
        video_id = params[0]
      }
    }
    if (video_id) {
      console.log("video id " + video_id)
      return video_id;
    }
  }
  return undefined
}

export function fitImgInTarget(img_w: number, img_h: number, target_w: number, target_h: number) {
  let dest_x = 0, dest_w = 0, dest_y = 0, dest_h = 0

  //we want the largets image that fit the target box
  //first we check the height


  let new_w = img_w
  let new_h = img_h
  if (img_h <= target_h && img_w <= target_w) { //The image is smaller just center it
    dest_h = img_h
    dest_w = img_w
    dest_x = (target_w - dest_w) / 2
    dest_y = (target_h - dest_h) / 2

  }

  if (img_h > target_h) {
    new_w = target_h / img_h * img_w
    new_h = target_h
    dest_h = new_h
    dest_w = new_w

  } else { //Adjust the height
    dest_h = target_h
    dest_w = img_w * target_h / img_h
    if (dest_w > target_w) {
      dest_w = target_w
      dest_h = img_h * target_w / img_w
    }
  }
  if (new_w > target_w) {
    new_h = target_w / img_w * img_h
    new_w = target_w
    dest_w = target_w
    dest_h = new_h

  } else {
    dest_w = target_w
    dest_h = img_h * target_w / img_w
    if (dest_h > target_h) {
      dest_h = target_h
      dest_w = img_w * target_h / img_h
    }
  }

  dest_y = (target_h - dest_h) / 2
  dest_x = (target_w - dest_w) / 2

  return {
    height: dest_h,
    width: dest_w,
    top: dest_y,
    left: dest_x
  }
}
export function loResImg(img_src: string, target_w: number, target_h: number, bCrop: boolean) {
  return new Promise<any>(async (resolve, reject) => {
    var img = new Image();
    img.onload = function() {
      console.log("img loaded")
      let img_w = img.naturalWidth
      let img_h = img.naturalHeight

      let canvas = document.createElement("canvas");
      canvas.height = target_h;
      canvas.width = target_w
      var ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";

        let src_x, src_w, src_y, src_h
        let dest_x = 0, dest_w = 0, dest_y = 0, dest_h = 0

        //we want the largets image that fit the target box
        //first we check the height
        if (bCrop) { //its a picture, t=it can be crooped
          dest_x = 0
          dest_y = 0
          dest_w = target_w
          dest_h = target_h
          if (img_w > img_h) {
            src_h = img_h;
            src_w = img_h;
            src_x = (img_w - img_h) / 2
            src_y = 0
          } else {
            src_h = img_w;
            src_w = img_w;
            src_y = (img_h - img_w) / 2
            src_x = 0
          }
        } else { //its a logo

          src_x = 0
          src_w = img_w
          src_y = 0
          src_h = img_h

          let new_w = img_w
          let new_h = img_h
          if (img_h < target_h && img_w < target_w) { //The image is smaller just center it
            dest_h = img_h
            dest_w = img_w
            dest_x = (target_w - dest_w) / 2
            dest_y = (target_h - dest_h) / 2

          }

          if (img_h > target_h) {
            new_w = target_h / img_h * img_w
            new_h = target_h
            dest_y = 0
            dest_h = new_h
            dest_w = new_w
            dest_x = (target_w - new_w) / 2
          }
          if (new_w > target_w) {
            new_h = target_w / img_w * img_h
            new_w = target_w
            dest_x = 0
            dest_w = target_w
            dest_h = new_h
            dest_y = (target_h - dest_h) / 2
          }

        }
        // Actual resizing dest_x, dest_y, dest_w,  dest_h
        ctx.drawImage(img,
          src_x, src_y, src_w, src_h,
          dest_x, dest_y, dest_w, dest_h // 0, 0, target_w, target_h
        );

        // Show resized image in preview element
        var dataurl = canvas.toDataURL("image/png");

        console.log("Reduce image from " + img_src.length + " " + dataurl.length)
        if (img_src.length < dataurl.length) {
          dataurl = img_src
        }
        resolve(dataurl)
      }
    }
    img.src = img_src;
    console.log(img_src)
  })
}
