import { SVG } from '@svgdotjs/svg.js';
import { AddToUndo, SelectId } from '../canvas.actions';
import { Draw } from './draw';

export class kEllipse extends Draw {
  ellipses: any = {}
  // anchors: any = {}


  //****************************************************************************
  // Parent events
  //****************************************************************************
  newObj(left: number, top: number, right: number, bottom: number) {
    left = this.parent_canvas.deScaleX(left)
    top = this.parent_canvas.deScaleY(top)

    right = this.parent_canvas.deScaleX(right)
    bottom = this.parent_canvas.deScaleY(bottom)

    console.log("new ecipse")
    let w = right - left
    let h = bottom - top
    let drawing_ellipse = this.draw_tool.ellipse(w, h)
    let ellipse_id = new Date().getTime().toString()
    this.ellipses[ellipse_id] = drawing_ellipse
    drawing_ellipse.node.dataset['id'] = ellipse_id
    drawing_ellipse.node.dataset['k_type'] = "ellipse"
    // this.setupEllipse(drawing_ellipse)
    drawing_ellipse.move(left, top).fill(this.fill_color)
    let svg = drawing_ellipse.svg()
    this.canvas_service.sendCanvasMsg("ellipse", "new", ellipse_id, svg)
    this.canvasStore.dispatch(new AddToUndo({
      undo: "new",
      k_type: "ellipse",
      id: ellipse_id
    }))
    let box = this.getBox(ellipse_id)
    return { id: ellipse_id, box: box, k_type: "ellipse" }
  }

  setMinSize(id: string, fromViewBox?: any) {

    let drawing_ellipse = this.ellipses[id]
    let attr = drawing_ellipse.attr()
    let points = this.getArrayFromStr(attr.points)
    let dx = 25
    let dy = 25
    if (fromViewBox) {
      dx = this.parent_canvas.scaleXToWidth(dx, fromViewBox.width)
      dy = this.parent_canvas.scaleYtoHeight(dy, fromViewBox.height)

    }

    if (attr.rx < 10) {
      let cx = attr.cx + dx
      let cy = attr.cy + dy

      drawing_ellipse.attr({ cx: cx, cy: cy, rx: dx, ry: dy })
    }

  }
  onMouseDown($event: any) { //Cal
    this.newObj($event.offsetX, $event.offsetY, $event.offsetX + 50, $event.offsetY + 50)
  }

  undo(msg: any) {
    console.log("ellipse")
    let old = this.ellipses[msg.id]
    if (old) {
      old.remove()
      delete this.ellipses[msg.id]
    }
    if (msg.data) {
      let new_obj = SVG(msg.data)
      let drawing_ellipse
      if (msg.canvas_width) {
        let vb = {
          width: msg.canvas_width,
          height: msg.canvas_height
        }
        drawing_ellipse = this.ellipseFromSVG(new_obj, vb)
      } else {
        drawing_ellipse = this.ellipseFromSVG(new_obj)
      }
      let ellipse_id = drawing_ellipse.node.dataset['id']
      this.ellipses[ellipse_id] = drawing_ellipse
    }
  }



  pasteSVG(svg: any, x: number, y: number) {
    let drawing_ellipse = this.ellipseFromSVG(svg)
    if (drawing_ellipse) {
      let id = drawing_ellipse.node.dataset['id']
      if (this.ellipses[id]) { //change the id
        id = new Date().getTime().toString()
        drawing_ellipse.node.dataset['id'] = id
      }
      this.ellipses[id] = drawing_ellipse
      if (x == 0 && y == 0) {
        let box: any = this.getBox(id)
        x = box.x1 + 30
        y = box.y1 + 30
      }
      let box: any = this.getBox(id)
      drawing_ellipse.move(box.x1 + 20, box.y1 + 20)
      box = this.getBox(id)
      return { id: id, box: box, k_type: "ellipse" }
    }
    return undefined
  }

  ellipseFromSVG(ellipse: any, fromViewBox?: any) {
    let attr = ellipse.attr()
    let drawing_ellipse
    // this.setupEllipse(drawing_ellipse)

    if (fromViewBox) {
      let points = this.getArrayFromStr(attr.points)
      let nattr = Object.assign({}, attr)
      nattr.cx = this.parent_canvas.scaleXToWidth(nattr.cx, fromViewBox.width)
      nattr.cy = this.parent_canvas.scaleYtoHeight(nattr.cy, fromViewBox.height)
      nattr.rx = this.parent_canvas.scaleXToWidth(nattr.rx, fromViewBox.width)
      nattr.ry = this.parent_canvas.scaleYtoHeight(nattr.ry, fromViewBox.height)

      drawing_ellipse = this.draw_tool.ellipse(nattr)
    } else {
      drawing_ellipse = this.draw_tool.ellipse(attr)
    }

    return drawing_ellipse
  }





  //****************************************************************************
  // Utilities
  //****************************************************************************

  // setupEllipse(drawing_ellipse: any) {
  //   drawing_ellipse.node.onmousemove = onEclipseEnter
  //   let me = this
  //   function onEclipseEnter(this: any, _$event: any) {
  //     let id = this.dataset['id']
  //     let box = me.getBox(id)
  //     if (box) {
  //       me.parent_canvas.onHover(id, box, "ellipse")
  //     }
  //   }
  // }
  copy(selected_id: string) {
    let obj = this.ellipses[selected_id]
    if (obj) {
      return obj.svg()
    }
    return undefined
  }

  erase(id: string) {
    let rv
    let obj = this.ellipses[id]
    if (obj) {
      rv = {
        undo: "erase",
        k_type: "ellipse",
        id: id,
        data: obj.svg()
      }
    }
    obj.remove()
    this.canvas_service.sendCanvasMsg("ellipse", "erase", id)
    return rv
  }
  moveObj(selected_id: string, x1: number, y1: number, x2: number, y2: number) {
    console.log("#el " + x1 + " " + y1 + " " + x2 + " " + y2)
    if (x1 > x2) {
      let t = x1
      x1 = x2
      x2 = t
    }
    if (y1 > y2) {
      let t = y1
      y1 = y2
      y2 = t
    }
    let w = x2 - x1
    let h = y2 - y1
    let left = x1;
    let top = y1;
    // console.log("#el1 " + left + " " + top + " " + w + " " + h)

    left = this.parent_canvas.deScaleX(left)
    w = this.parent_canvas.deScaleX(w)

    top = this.parent_canvas.deScaleY(top) //We might have to descale from the bottom
    h = this.parent_canvas.deScaleY(h)

    let cx = left + w / 2
    let cy = top + h / 2

    //dimensions I think are ok
    let rx = w / 2
    let ry = h / 2
    console.log("#el " + cx + " " + cy + " " + rx + " " + ry)
    let drawing_ellipse = this.ellipses[selected_id]



    drawing_ellipse.attr({ cx: cx, cy: cy, rx: rx, ry: ry })
    this.ellipses[selected_id] = drawing_ellipse
    let svg = drawing_ellipse.svg()
    this.canvas_service.sendCanvasMsg("ellipse", "move", selected_id, svg)

  }
  getBox(selected_id: string) { //subclassed for mullti select
    if (this.ellipses) {
      let drawing_ellipse = this.ellipses[selected_id]
      if (drawing_ellipse) {

        let bbox = drawing_ellipse.bbox()
        // this.setAnchorsPos(attr.cx, attr.cy, attr.rx, attr.ry)

        let rv =
        {
          x1: bbox.x,
          y1: bbox.y,
          x2: bbox.x2,
          y2: bbox.y2,
          back: true, //make back div
          new_point: false, //new_point for polygon
          svg: drawing_ellipse.svg() //for the undo
        }
        return rv
      }
    }
    return undefined
  }
  moveDelta(selected_id: string, delta_x: number, delta_y: number) {//subclassed for mullti select
    if (this.ellipses) {
      let drawing_ellipse = this.ellipses[selected_id]
      if (drawing_ellipse) {
        let attr = drawing_ellipse.attr()
        let cx = attr.cx + delta_x
        let cy = attr.cy + delta_y
        drawing_ellipse.attr({ cx: cx, cy: cy })
      }
    }
    // this.movePolyPoints(drawing_poly, delta_x, delta_y)
  }
  setColor(id: string, color: string) { //subclased
    if (this.ellipses) {
      let drawing_ellipse = this.ellipses[id]
      if (drawing_ellipse) {
        drawing_ellipse.fill(color)
      }
    }
  }
}
