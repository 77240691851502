import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from '../../chapala-mls-service';
import { GotMLSMsg, SetSearch } from '../../chapala-mls.actions';

import { ChapalaMLSState } from '../../chapala-mls.model';
import { gotMLSMsg } from '../../chapala-mls.selectors';


@Component({
  selector: 'selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent {
  subscriptions: any[] = []
  btncolor: string = "#152666"
  border: string = "1px solid gray"

  private _title: string = "";
  public title: string = "";
  @Input() search_by: string = ""
  public options: string[] = [];
  bShowOptions: boolean = false;
  debounce_show: number = 0

  // @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    console.log("selector")
    let me = this
    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.show) {
          if (msg.show.hasOwnProperty(me._title)) {
            this.debounce_show = new Date().getTime()

            me.bShowOptions = msg.show[me._title]
            // console.log("msg.show " + " " + me._title + " " + me.bShowOptions)
          }
          return;
        }
        if (msg.select) {
          if (msg.select.hasOwnProperty(me._title)) {
            setTimeout(() => {
              let index = msg.select[me._title]
              if (index == -1) {
                this.chapalaMLState.dispatch(new SetSearch(this.search_by, undefined))
                me.title = me._title;
              } else {
                me.title = me.options[index]
              }
              me.bShowOptions = false;
            }, 1)
          }
        }
        if (msg.show_all) {
          this.bShowOptions = false
        }
        if (msg.hide) {
          if (me._title == msg.hide) {
            // console.log("msg.hide")
            setTimeout(() => {
              me.bShowOptions = false
            }, 100)
          }
        }
      }
    }))
    // this.accountStore.select(btnColor).subscribe((color: string) => {
    //   this.btncolor = color;
    //   this.border = "1px solid " + color;
    // })
  }
  bShowRight: boolean = true;

  @ViewChild('outComponent') outComponent: ElementRef | undefined;
  @ViewChild('optComponent') optComponent: ElementRef | undefined;
  ngAfterViewInit() {
    if (this.outComponent) {
      let left = this.outComponent.nativeElement.offsetLeft
      this.bShowRight = (left > 250)
      // console.log(this.title + " offset_left " + left)
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) { //aspect ratio is 1280, 720
    if (this.outComponent) {
      let left = this.outComponent.nativeElement.offsetLeft
      let bShowRight = (left > 250)
      if (bShowRight != this.bShowRight) {
        setTimeout(() => {
          this.bShowRight = bShowRight
        }, 1)
      }
    }
    // console.log(this.title+" offset_left resize "+left)
  }

  @HostListener('window:click', ['$event'])
  onClick(event?: any) { //aspect ratio is 1280, 720
    // console.log("@cc checking " + this.title)
    let now = new Date().getTime()
    let dif = now - this.debounce_show
    if (dif > 1000) {
      if (this.outComponent && this.optComponent) {
        if (event.target != this.outComponent.nativeElement) {
          if (!this.outComponent.nativeElement.contains(event.target)) {
            // console.log("@cc clicked outside " + this.title)
            let msg: any = {
              hide: this._title
            }
            this.chapala_mls_service.sendMsg(msg)
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  selIndex(i: number) {
    // this.selected.emit({ index:i, val:this.options[i] })
  }
  setTitle(t: string) {
    this.title = t;
    this._title = t;
  }
  setOptions(values: any[], name: string) {
    this.options = []
    values.forEach(value => {
      this.options.push(value[name])
    });
  }
  selOption(i: number) {
    let msg: any = {
      select: {}
    }
    msg.select[this._title] = i
    this.chapala_mls_service.sendMsg(msg)
    // console.log("--> " + JSON.stringify(msg))
    // this.bShowOptions = false, we do this in the component when the mssage is received
  }
  all() {
    if (this.search_by) {

      let msg: any = {
        select: {}
      }
      msg.select[this._title] = -1
      this.chapala_mls_service.sendMsg(msg)
      // console.log("--> " + JSON.stringify(msg))
    }
  }
  showOptions() {
    // console.log("showOptions " + this._title + " was " + this.bShowOptions)
    let msg: any = {
      show: {}
    }
    if (this.bShowOptions) {
      msg.show[this._title] = false
    } else {
      msg.show[this._title] = true
    }
    this.chapala_mls_service.sendMsg(msg)
  }

}
