<div class="out" [ngClass]="{show:bShowChat}">

  <div class="body" #scroll_container>
    <div class="top_filler"></div>

    <div class="line" *ngFor="let msg of messages">
      <chat-line *ngIf="msg.chat_text" [mine]="msg.id != caller_id" (asset)="showAsset($event)" [text]="msg.chat_text"> </chat-line>
      <img *ngIf="msg.img" class="in_img" [ngClass]="{caller:msg.from=='caller'}" [src]="msg.img">
      <!-- <div *ngIf="msg.asset_id">{{msg.asset_id}}</div> -->
      <sel-asset *ngIf="msg.asset_id" class="asset" [asset]="assets[msg.asset_id]"></sel-asset>

    </div>
  </div>
  <div class="in_frame">
    <input kzInput class="fback" type="name" [placeholder]="text_prompt" [(ngModel)]="in_text" (keyup)="keyUp($event)">
    <k-button class="send" background="transparent" icon="send.svg"  kzLib (click)="send($event)"></k-button>
  </div>

  <div class="header_frame1">
    <div class="header">
      <img class="header_i" src="assets/images/chat.svg">
      <div class="font_sub_title header_t">
        Chat
      </div>
      <div class="caller_name">
        {{caller_name}}
      </div>
    </div>
  </div>
  <asset-preview [style.top.px]="preview_top" [small]="true" *ngIf="preview_asset" [asset]="preview_asset" (close)="closePreview()" class="preview"></asset-preview>

</div>
