import { Component } from '@angular/core';
import { CopyLinksComponent } from '../copy-links/copy-links.component';

@Component({
  selector: 'link-opts',
  templateUrl: './copy-link-opt.component.html',
  styleUrls: ['../copy-links/copy-links.component.scss', './copy-link-opt.component.scss']
})
export class CopyLinkOptComponent extends CopyLinksComponent {

}
