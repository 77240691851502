import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { KeyziiService } from 'src/app/wss/keyzii.service';
import { PagesState } from '../../pages.model';
import { gotReps, gotUsers, hostUrl, myRoll } from '../../pages.selectors';
// import { Clipboard } from '@angular/cdk/clipboard'
import { PagesService } from '../../pages.service';
import { Hash } from 'src/app/utils';
import { NotifyError, NotifyUser } from '../../pages.actions';
import { WSSState } from 'src/app/wss/wss.model';
import { customerId } from 'src/app/wss/wss.selectors';
import { PayService } from 'src/app/kpay/pay.service';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  // search_type: any
  // host_url: string | undefined
  // search: string = ""
  all_users: any[] = []
  users: any[] = []
  reps: any[] = []

  bAddingUser: boolean = false
  edit_user_top: number = 0; //!=0 shows
  edit_user_left: number = 0
  show_rolls_index: number = -1
  sel_roll_type: string | undefined
  edit_user: any | undefined

  edit_row: number = -1
  edit_what: string = ""
  edit_value: string = ""


  bReady: boolean = false

  subscriptions: any[] = []
  sel_option: number = 0

  roll: string = "admin"
  customer_id: string | undefined

  sel_user_id: string = ""
  api_user_id: string = ""
  user_pin: string = ""
  @Input() merchant_id: string = ""
  constructor(
    private pagesState: Store<PagesState>,
    public pay_service: PayService, //Make sure the service is ready

    private wssState: Store<WSSState>,
  ) {
    console.log("payments")
    // for (let i = 0; i < 30; i++) {
    //   let user = {
    //     name: "User-" + i,
    //     email: "email-" + i + "@company.com",
    //     role: "Agent",
    //     link: "https://keyzi.com"
    //   }
    //   this.users.push(user)
    // }
    this.subscriptions.push(this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      this.customer_id = customer_id;
    }))
    this.subscriptions.push(this.pagesState.select(myRoll).subscribe((my_roll: string) => {
      setTimeout(() => this.roll = my_roll);
    }))
    // this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
    //   this.host_url = url
    // }))
    this.subscriptions.push(this.pagesState.select(gotReps).subscribe((users: any[]) => {
      this.users = users
      this.setAllUsers()
    }))
    this.subscriptions.push(this.pagesState.select(gotUsers).subscribe((reps: any[]) => {
      this.reps = reps
      this.setAllUsers()
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }




  setAllUsers() {
    let all_users: any[] = []
    let me = this
    function addToArray(collection: any) {
      if (collection) {
        let keys = Object.keys(collection)
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i]
          let user = Object.assign({}, collection[key])
          if (!user._id) {
            user._id = key
            user.customer_id = me.customer_id
          }
          user.selected = false
          all_users.push(user)
        }
      }
    }
    addToArray(this.users)
    addToArray(this.reps)
    all_users.sort(function(a, b) {
      let a_name = a.name
      let b_name = b.name
      if (a_name && b_name) {
        return a_name.toLowerCase().localeCompare(b_name.toLowerCase());
      }
      if (a_name) {
        return -1
      }
      if (b_name) {
        return 1
      }
      return 0
    })
    setTimeout(() => this.all_users = all_users)
  }
  selUser(i: number) {
    let users: any[] = Object.assign([], this.users)
    let user: any = Object.assign({}, users[i])
    user.selected = !user.selected
    users[i] = user
    this.users = users


  }
  ngOnInit(): void {
  }


  setCreds($event: any, user: any) {
    this.bAddingUser = true
    this.edit_user_top = $event.y - 210;
    this.edit_user_left = $event.x - 640;
    this.sel_user_id = user._id
  }

  closeIt() {
    this.edit_user_top = 0
  }
  checkIt() {
    this.bReady = (this.merchant_id.length == 7 && this.api_user_id.length > 2 &&
      (this.user_pin.length == 64
        || this.user_pin.length == 14)
    )
  }
  async saveIt() {
    if (this.bReady) {
      try {
        await this.pay_service.saveAPICreds({
          api_user_id: this.api_user_id,
          user_pin: this.user_pin,
          kuser_id: this.sel_user_id,
          merchant_id: this.merchant_id
        })
        this.closeIt()
      } catch (e) {
        console.error(e)
      }
    }
  }
}
