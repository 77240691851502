<div class="out" (mouseleave)="leave()" (mouseenter)="enter()">

  <div class="line ">
    {{title}}

  </div>

  <k-button background="transparent" class="expand" icon="expand.svg" [ngClass]="{flip:bShowOptions}"  kzLib (click)=" showOptions()"></k-button>

  <div *ngIf="bShowOptions" class="opt_contianier ">
    <div #optComponent class="options" [ngClass]="{opts_r:bShowRight}">
      <div class="opt" *ngFor="let opt of _options;let i = index"  kzLib (click)="selOption(i)">
        <img [src]="opt.icon" class="icon">
        <span class="tx">{{opt.name}}</span>
        <!-- <div class="check" [ngClass]="{selected:i == sel_opt}"></div> -->
      </div>
    </div>

  </div>
</div>
