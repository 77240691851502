import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  S3Client,
  AbortMultipartUploadCommand,

  UploadPartCommand,
  ListPartsCommand,
  UploadPartOutput,
  UploadPartCommandOutput,
  UploadPartCommandInput,
  CompletedMultipartUpload,
  CreateMultipartUploadCommand,
  CreateMultipartUploadCommandInput,
  CompleteMultipartUploadCommand,


} from "@aws-sdk/client-s3";
import { Store } from '@ngrx/store';
import { WSSState } from '../wss/wss.model';
import { callId } from '../wss/wss.selectors';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  streams: any //

  screen_stream: any
  audio_stream: any
  audio_and_screen_stream: any;

  recordedChunks: any = [];
  bRecording: boolean = false;


  credentials: any;
  // multiPartUploader: any;
  client: S3Client | undefined
  call_id: string | undefined
  partNumber: number = 1
  UploadId: string | undefined
  public key: string | undefined

  rec_number: number = 0 //In case we need more than one recording per call


  constructor(
    private httpClient: HttpClient,
    public wssState: Store<WSSState>,

  ) {
    console.log("MP# s3 service")
    // this.createS3Client()
    //We need to reset rec_number when we start a call
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (call_id) {
        this.rec_number = 0;
      }
    })
  }


  //****************************************************************************
  // One Multipart
  //****************************************************************************
  //****************************************************************************
  // Multipart Public API
  //****************************************************************************
  async startMultipart(user_id: string, rec_mime: string, call_id?: string) {
    return new Promise<void>(async (resolve, reject) => {
      if (!this.client) {
        console.error("!this.client")
        return
      }
      try {
        //  this.credentials = await this.getMultipart() //Gets temporary security credentials
        let now = new Date().getTime() + 60 * 60 * 4 * 1000
        let signedUrlExpireSeconds = new Date(now)
        this.partNumber = 1
        this.rec_number++
        if (call_id) {
          this.key = user_id + "/" + call_id + "/" + this.rec_number + "." + rec_mime
        } else {
          this.key = user_id + "/" + this.rec_number + "." + rec_mime
        }
        let params: CreateMultipartUploadCommandInput = {
          Bucket: "k-recorder",
          Key: this.key,
          Expires: signedUrlExpireSeconds,
          ContentType: 'video/webm'
        }
        let mpCmd = new CreateMultipartUploadCommand(params)
        const mpRes = await this.client.send(mpCmd);
        // process data.


        this.UploadId = mpRes.UploadId
        console.log("MP# (2) ok UploadId " + this.UploadId)

        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }
  async uploadMultipart(blob: Blob) {
    return new Promise<void>(async (resolve, reject) => {
      if (!this.client) {
        console.error("!this.client 2")
        return
      }
      try {

        let uploadParams = {
          Body: blob,
          Bucket: this.credentials.Bucket,
          Key: this.key,
          PartNumber: this.partNumber,
          UploadId: this.UploadId,
          ContentLength: blob.size
        }
        let uploadCmd = new UploadPartCommand(uploadParams)
        const uploadRes: UploadPartCommandOutput = await this.client.send(uploadCmd);
        console.log("MP# uploaded multipart " + this.partNumber)
        this.partNumber++;
        resolve()
      } catch (error) {
        console.error("MP# error mp " + error)
        reject(error)
      }
    })
  }
  async completeMultiPart() {
    return new Promise<number>(async (resolve, reject) => {
      if (!this.client) {
        console.error("!this.client 3")
        return
      }
      try {
        let parts = await this.getParts()
        // if (!parts) {
        //   reject("#MP No parts")
        //   return;
        // }
        // console.log("uploaded part ok " + response.metadata.httpStatusCode)
        let mp: any = {}
        if (parts) {
          mp.Parts = parts
        } else {
          console.log("No Parts")
          mp.Parts = []
        }

        let completeCmdParams: any = {
          Bucket: this.credentials.Bucket,
          Key: this.key,
          UploadId: this.UploadId,
          MultipartUpload: mp
        }
        let completeCmd = new CompleteMultipartUploadCommand(completeCmdParams)
        const complteteRes = await this.client.send(completeCmd);
        // process data.
        console.log("MP# complete ok ")
        resolve(this.rec_number)
        return

      } catch (error) {
        console.error("MP# complete mp error " + error)
        reject(error)
      }
    })
  }
  async abortMultiPart() {
    return new Promise<void>(async (resolve, reject) => {
      if (!this.client) {
        console.error("!this.client 4")
        return
      }
      let abortCmdParams = {
        Bucket: this.credentials.Bucket,
        Key: this.key,
        UploadId: this.UploadId,
      }
      let abortCmd = new AbortMultipartUploadCommand(abortCmdParams)
      try {
        const abortRes = await this.client.send(abortCmd);
        // process data.
        console.log("MP# abort ok ")
        resolve()
        return
      } catch (error) {
        reject(error)
      }
    })
  }

  //****************************************************************************
  //Public utils
  //****************************************************************************

  async createS3Client() {
    return new Promise<any>(async (resolve, reject) => {
      if (this.client) {
        resolve(this.client)
        return;
      }
      try {
        this.credentials = await this.httpClient.get('/s3-tmp-creds/').toPromise();
        let credentials: any = {
          secretAccessKey: this.credentials.secretAccessKey,
          accessKeyId: this.credentials.accessKeyId,
        }
        this.client = new S3Client(
          {
            region: "us-west-1",
            credentials: credentials
          });
        resolve(this.client);
      } catch (e) {
        reject(e)
      }
    })
  }

  private async getMultipart() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let url = '/k-multi/' + this.call_id
        let rv: any = await this.httpClient.get(url).toPromise();
        resolve(rv);
      } catch (e) {
        reject(e)
      }
    })
  }
  async getParts() {
    return new Promise<any[]>(async (resolve, reject) => {
      if (!this.client) {
        console.error("!this.client 5")
        return
      }
      let parts: any[]
      let listParams = {
        Bucket: this.credentials.Bucket,
        Key: this.key,
        UploadId: this.UploadId,
      }
      let listCmd = new ListPartsCommand(listParams)

      try {
        const listRes: any = await this.client.send(listCmd);
        // process data.
        if (listRes.Parts) {
          console.log("MP# get parts ok " + JSON.stringify(listParams))
        }
        else {
          console.log("MP# get parts = NO PARTS " + JSON.stringify(listParams))
        }
        resolve(listRes.Parts)
      } catch (error) {
        console.error("MP# get parts error " + error)
        reject(error)
      }
    })
  }




  //****************************************************************************
  // One Blob
  //****************************************************************************

  async getRecURL() {
    return new Promise<any>(async (resolve, reject) => {

      try {
        let url = '/k-record/' + this.call_id
        let rv: any = await this.httpClient.get(url).toPromise();
        resolve(rv);
      } catch (e) {
        reject(e)
      }
    })
  }
  async uploadArray(blob: Blob) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rec_url = await this.getRecURL()
        let array = await blob.arrayBuffer()
        // let stream = blob.stream()
        // let file = new File([blob], "abc.webm")
        // try {
        let rv: any = await this.httpClient.put(rec_url, array).toPromise();
        resolve(rv)
      } catch (e) {
        reject(e)
      }
    })
  }
}
