import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotifyError, ShowCC } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { inPhone } from 'src/app/pages/pages.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { StartKPay, StartKPlan } from '../kpay.actions';
import { kPayState } from '../kpay.model';


@Component({
  selector: 'kpay-menu',
  templateUrl: './kpay-menu.component.html',
  styleUrls: ['./kpay-menu.component.scss']
})
export class KpayMenuComponent {
  using_phone: string | undefined
  subscriptions: any[] = []
  sel_option: number = 0
  options: any[] = [
    {
      text: "keyzii Plan"
    }, {
      text: "New Invoice"
    }]
  constructor(
    public kpayState: Store<kPayState>,
    public pagesState: Store<PagesState>,
  ) {
    this.subscriptions.push(this.pagesState.select(inPhone).subscribe((in_phone: string | undefined) => {
      this.using_phone = in_phone
    }))
  }
  selOption(i: number) {
    if (i == 0) {
      if (this.using_phone == "caller") {
        this.pagesState.dispatch(new NotifyError("keyzii plan is only available when the caller is using a desktop."))
        return
      }
      this.kpayState.dispatch(new StartKPlan())
    } else {
      this.kpayState.dispatch(new StartKPay())
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
}
