import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from 'src/app/chapala-mls/chapala-mls-service';
import { GotMLSMsg, SetSearch } from 'src/app/chapala-mls/chapala-mls.actions';
import { ChapalaMLSState } from 'src/app/chapala-mls/chapala-mls.model';
import { gotMLSMsg, searchBy } from 'src/app/chapala-mls/chapala-mls.selectors';
import { SelectorComponent } from '../selector.component';

@Component({
  selector: 'mls-more',
  templateUrl: './more.component.html',
  styleUrls: ['../selector.component.scss', './more.component.scss']
})
export class MoreComponent extends SelectorComponent {
  flors_lbl: string = "Floors"
  // @ViewChild('outComponent') outComponent: ElementRef | undefined;
  bShowMoreOptions: boolean = false;
  constructor(
    public chapalaMLState: Store<ChapalaMLSState>,
    public chapala_mls_service: ChapalaMLSService,
  ) {
    super(chapalaMLState, chapala_mls_service);
    console.log("More")
    this.setTitle("More")

    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      if (msg) {
        if (msg.more) {
          this.chapalaMLState.dispatch(new SetSearch(msg.more.key, msg.more.val))
          if (msg.more.key == "floors") {
            this.flors_lbl = msg.more.val + " Floors"
          }
        }

        if (msg.show) {
          if (msg.show.hasOwnProperty("More")) {
            setTimeout(() => {
              this.bShowMoreOptions = msg.show.More
            }, 1)
          } else {
            this.bShowMoreOptions = false
          }
        }
      }

    }))
  }



  setVal(key: string, val: string) {
    let msg: any = { more: { key: key, val: val } }
    this.chapala_mls_service.sendMsg(msg)
  }

}
