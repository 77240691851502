import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NewMsg } from '../chat/chat.actions';
import { PagesState } from '../pages/pages.model';
import { WRTCService } from '../wrtc/wrtc.service';
import { GotMessage } from '../wss/wss.actions';
import { WSSState } from '../wss/wss.model';
import { app, callId, lastMsg } from '../wss/wss.selectors';
import { WSSService } from '../wss/wss.service';

@Injectable({
  providedIn: 'root'
})
export class KFTPService {
  rootHandle: any
  call_id: string | undefined
  data_target: string = "rep"
  data_channel: any

  files_in_play: any = {}
  my_files: any = {} //the ones that I can send
  other_files: any
  constructor(
    private wss_service: WSSService,
    private wssState: Store<WSSState>,
    public pagesState: Store<PagesState>,
    private wrtc_service: WRTCService,

  ) {
    console.log("Intro")
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
      if (this.call_id) {
        console.log("#TS got call_id")
        this.openDataChannel()
      } else { //call ended clean up
        this.cleanUp()
      }
    })

    this.wssState.select(app).subscribe((app: string) => {
      if (app == 'rep') {
        this.data_target = "caller"
      }
    })

    this.wssState.select(lastMsg).subscribe((msg: any) => {
      if (msg) {
        if (msg.hasOwnProperty('k_ftp')) {
          this.gotMsgFromOrher(msg)
        }
      }
    })
  }


  //****************************************************************************
  // TESTED
  //****************************************************************************

  cleanUp() {
    delete this.data_channel
    delete this.my_files
    delete this.other_files
    delete this.sending_file
  }

  async selDir() {
    let rootHandle = await (window as any).showDirectoryPicker({ mode: "readwrite" });
    // this.syncDir(rootHandle)
  }
  async setHandleFilesAndSyncDir(rootHandle: any, files_in_play: any) {
    this.rootHandle = rootHandle
    this.files_in_play = files_in_play
    return this.syncDir()
  }

  async syncDir() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (this.rootHandle) {
          // let rep_files_in_play = await this.training_sevice.filesInPlay(this.rootHandle, "play.txt")
          console.log("rep_files_in_play " + JSON.stringify(this.files_in_play))

          this.my_files = await this.filesToCheck(this.rootHandle, this.files_in_play)
          console.log("rep_files_to_cechk " + JSON.stringify(this.my_files))
          if (this.my_files && this.other_files) {
            this.tryToSend()
          }
          await this.sendMsgToOther(this.my_files)
          resolve()
        } else {
          reject("no handle ready")
        }
      } catch (e) {
        reject(e)
      }
    })
  }

  async filesToCheck(rootHandle: any, send_only_this_files?: any) {
    return new Promise<void>(async (resolve, reject) => {

      if (!rootHandle) {
        reject("#kFTP checkAndSyncFiles no permison")
        return
      }

      let asyncIter = rootHandle.values()
      let my_files: any = {}
      let bCheckFilter = false
      if (send_only_this_files) {
        let keys = Object.keys(send_only_this_files)
        bCheckFilter = (keys.length > 0)
      }
      while (true) {
        const result = await asyncIter.next(); // Get the next result from the iterator

        if (result.done) {
          break; // Exit the loop when the iterator is done
        }

        // console.log(result.value); // Process the value

        let file = await result.value.getFile()
        if (!file.name.startsWith('.')) {
          if (bCheckFilter) { //If i have a filter
            if (send_only_this_files[file.name]) { //and the file is in the filter
              my_files[file.name] = Object.assign({}, send_only_this_files[file.name])
              // my_files[file.name].file = file
              my_files[file.name].date = file.lastModifiedDate
              my_files[file.name].size = file.size
              my_files[file.name].name = file.name
            }
          } else {
            my_files[file.name] = {
              // file: file,
              date: file.lastModifiedDate,
              size: file.size,
              name: file.name,
            } //if no filter add it
          }
        }
      }

      resolve(my_files)
    })
  }
  sendMsgToOther(files_to_cechk: any) {
    let msg: any = {
      k_ftp: "check_files",
      check_files: files_to_cechk
    }
    this.wss_service.sendMessageToOtherMembers(msg)
  }
  /*

  */

  async gotMsgFromOrher(msg: any) {
    console.log("<--  gotMsgFromOther" + JSON.stringify(msg))
    if (msg) {
      if (msg.k_ftp == "check_files") {
        this.other_files = msg.check_files
        if (this.my_files && this.other_files) {
          this.tryToSend()
        }
      }
    }
  }
  async  tryToSend() {
    return new Promise<void>(async (resolve, reject) => {
      if (this.my_files && this.other_files) {
        if (!this.data_channel) {
          try {
            this.data_channel = await this.wrtc_service.openDataChannel(this.data_target, this)
          } catch (e) {
            reject(e)
            return
          }
        }
        let files_to_send = this.filesToSend(this.my_files, this.other_files)
        try {
          await this.sendFiles(files_to_send)
          resolve()
        } catch (e) {
          reject(e)
        }
      } else {
        reject("not ready")
      }
    })
  }

  filesToSend(my_files: any, other_files: any) {

    let local_keys = Object.keys(my_files)
    let files_to_send: any[] = []

    for (let i = 0; i < local_keys.length; i++) {
      let key = local_keys[i]
      let local_file = my_files[key]
      let rem_file = other_files[key]

      if (!rem_file) { //if the other one does not have the file, send it
        files_to_send.push(local_file)
      } else {
        if (local_file.size && (!rem_file.size || rem_file.size != local_file.size)) {
          files_to_send.push(local_file)
        }
      }
    }
    return files_to_send

  }

  async sendFiles(files_to_send: any[]) {
    return new Promise<void>(async (resolve, reject) => {

      if (!this.rootHandle) {
        reject("#KFTP syncRemoteFiles not rootHandle")
        return
      }

      if (!this.call_id) {
        reject("#KFTP syncRemoteFiles not call_id")
        return
      }

      for (let i = 0; i < files_to_send.length; i++) {
        let file_to_send = files_to_send[i]
        try {
          await this.sendFileToRemote(file_to_send)
        } catch (e) {
          console.error("#KFTP " + file_to_send.name + " " + e)
        }
      }
      resolve()
    })
  }
  //****************************************************************************
  // End TESTED
  //****************************************************************************



  sendFileToRemote(target: any) {
    return new Promise<void>(async (resolve, reject) => {
      let name = target.name
      let fileHandle = await this.rootHandle.getFileHandle(name)
      const file = await fileHandle.getFile();
      try {
        await this.sendFile(file)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }
  sending_file: any
  async sendFile(file: File, asset?: any) {
    return new Promise<void>(async (resolve, reject) => {
      function sleep(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
      } if (this.sending_file) {
        reject("bussy")
        return
      }
      if (!this.data_channel) {
        this.data_channel = await this.wrtc_service.openDataChannel(this.data_target, this)
      }
      let me = this
      try {

        this.sending_file = Object.assign({}, asset)
        this.sending_file.name = file.name
        this.sending_file.size = file.size
        this.sending_file.date = file.lastModified


        let text = "kzfile" + JSON.stringify(this.sending_file)
        await this.wrtc_service.sendText(this.data_target, text)
        await sleep(100);
        //Now read the file in blocks and send each block

        let start_at = new Date().getTime()
        const CHUNK_SIZE = 1024 * 16; // 65 KB
        let offset = 0;
        const fileReader = new FileReader();

        fileReader.onload = async (event: any) => {
          const chunk = event.target.result;
          // console.log("Read chunk:", chunk);
          let bPendingToSend = true
          while (bPendingToSend) {
            try {  // Process the chunk (e.g., send over WebRTC, save to server, etc.)
              await me.wrtc_service.sendData(me.data_target, chunk)
              bPendingToSend = false;
            } catch (e) {
              console.log("sleep")
              await sleep(100);
            }
          }

          offset += CHUNK_SIZE;
          let perc = Math.floor(offset / file.size * 100)
          let secs = Math.floor((new Date().getTime() - start_at) / 1000)
          console.log("sent " + perc + "% " + secs + " secs " + offset + " of " + file.size)

          if (offset < file.size) {
            readNextChunk();
          } else {
            console.log("Finished reading file");
            let other_files = Object.assign({}, this.other_files)
            other_files[this.sending_file.name] = this.sending_file
            this.other_files = other_files
            delete this.sending_file
            resolve()
          }
        };

        fileReader.onerror = (error) => {
          reject("Error reading file:" + error);
          delete this.sending_file
          return
        };

        function readNextChunk() {
          const blob = file.slice(offset, offset + CHUNK_SIZE);
          fileReader.readAsArrayBuffer(blob);
        }

        readNextChunk();


        // const buffer = await file.arrayBuffer()

      } catch (e) {
        reject(e)
        delete this.sending_file
        return
      }

    })
  }
  async openDataChannel() {
    if (!this.data_channel) {
      this.data_channel = await this.wrtc_service.openDataChannel(this.data_target, this)
    }
  }

  receiving_file: any
  async receiveData(data: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {

        if (typeof data === "string" && data.startsWith("kzfile")) {
          if (this.receiving_file) {
            console.error("overwriting " + JSON.stringify(this.receiving_file))
          }
          try {
            let json = data.substring(6)
            let msg = JSON.parse(json)

            if (msg && msg.name && msg.size) {
              this.receiving_file = Object.assign({}, msg)
              let name = this.receiving_file.name
              if (this.rootHandle) {
                console.log("@KFTP received create writable ")
                let fileHandle = await this.rootHandle.getFileHandle(name, { create: true })
                this.receiving_file.writable = await fileHandle.createWritable()
              } else {
                this.receiving_file.parts = []
              }
              this.receiving_file.offset = 0
              resolve()
              return
            }
          } catch (e) {
            reject("error parsing json " + e)
            return
          }
        }

        if (this.receiving_file.writable) {
          await this.receiving_file.writable.write(data)
          this.receiving_file.offset += data.byteLength
          let perc = Math.floor(this.receiving_file.offset / this.receiving_file.size * 100)
          console.log("@KFTP received " + perc + "% " + this.receiving_file.offset + " of " + this.receiving_file.size)
          if (this.receiving_file.offset >= this.receiving_file.size) {
            console.log("@KFTP done, closing file")
            try {
              await this.receiving_file.writable.close()
            } catch (e) {
              console.error(e)
            }
            let my_files = Object.assign({}, this.my_files)
            my_files[this.receiving_file.name] = this.receiving_file
            this.my_files = my_files
            delete this.receiving_file
            resolve()
          }
        } else {
          //save the parts and show the asset when we are done
          this.receiving_file.parts.push(data)
          this.receiving_file.offset += data.byteLength
          if (this.receiving_file.offset >= this.receiving_file.size) {


            if (this.receiving_file.hasOwnProperty("chat_msg")) {
              const blob = new Blob(this.receiving_file.parts, { type: this.receiving_file.chat_msg.type });
              if (blob) {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = this.receiving_file.chat_msg.name;
                a.click();
                URL.revokeObjectURL(url);
              }
              let msg = {
                chat_msg: {
                  chat_text: "Downloaded " + this.receiving_file.chat_msg.name,
                  id: this.receiving_file.chat_msg.id,
                  time: new Date().getTime()
                }
              }
              this.wssState.dispatch(new GotMessage(msg))
            }
            delete this.receiving_file
            resolve()
          }
        }
      } catch (e) {
        reject("@KFTP error receiveData " + e)
        return
      }
    })
  }

}
