import { Component } from '@angular/core';
import { Container, SVG } from '@svgdotjs/svg.js';
import { AddToUndo, ClearSel, DidStep, GetInitials, GetSignature } from '../canvas.actions';
import { changeInitials, changeSignature, fieldTextFont, fieldTextSize, fieldTextWeight, selSignerKey, signerEmail, signerName, signers } from '../canvas.selectors';
import { Draw } from './draw';

export class kInput extends Draw {
  dims: any = { //Thw width of a page is about 834 viewBoxWidth
    Date: { w: 120, h: 25 },  //aspect ratio is 460 / 270
    Text: { w: 250, h: 25 },
    Name: { w: 200, h: 25 },
    Email: { w: 200, h: 25 },
    Sign: { w: 150, h: 25 },
    Initial: { w: 80, h: 25 }
  }
  initials: any
  signature: any
  signer_email: string | undefined
  signer_name: string | undefined
  signers: any = {}
  signer_title: string = ""
  signer: any
  role: string = ""

  input_fields: any = {}

  //Text
  span_removed_at: number = 0;
  moving_span: any
  span: any //editing
  bSelected: boolean = false

  drawing_rect: any | undefined  //used to create and move lines
  drawing_text: any | undefined
  rectangle_id: string = ""
  move: string = "no"
  poly_array: number[] = []
  text_field: any

  initClass() {
    this.canvasStore.select(fieldTextWeight).subscribe((text_weight: string) => {
      this.text_weight = text_weight
    })

    this.canvasStore.select(fieldTextSize).subscribe((text_size: string) => {
      this.text_size = text_size
    })

    this.canvasStore.select(fieldTextFont).subscribe((text_font: string) => {
      this.text_font = text_font
    })

    this.canvasStore.select(selSignerKey).subscribe((signer_key: string | undefined) => {
      if (signer_key) {
        this.signer_key = signer_key
        if (this.signers) {
          this.signer = this.signers[this.signer_key]
          // if (this.signer) {
          //   setPromForSel()
          // }
        }
        console.log("sign signer_key 1 " + this.signer_key + " signers " + JSON.stringify(this.signers))
      }
    })

    this.canvasStore.select(signers).subscribe((signers: any) => {
      this.signers = signers
      // if (this.signers) {
      //   this.setDates()
      // }
      if (this.signer_key) {
        this.signer = this.signers[this.signer_key]

        // if (this.signer) {
        //   setPromForSel()
        // }

      }
      console.log("sign signer_key 2 " + this.signer_key + " signers " + JSON.stringify(this.signers))

    })
    this.canvasStore.select(signerEmail).subscribe((email: string | undefined) => {
      if (email) {
        this.signer_email = email //by canvas service when it gets myEmail and by in-call-asset.component when it reads the email from the command line
      }
    })
    this.canvasStore.select(signerName).subscribe((name: string | undefined) => {
      if (name) {
        this.signer_name = name
      }
    })

    this.canvasStore.select(changeInitials).subscribe((data: any | undefined) => {
      // this.dump("before initial")
      if (data && data.svg && data.svg.length > 1) {
        this.initials = data
        let group_id = data.group_id
        let group = this.findGroupById(group_id)
        if (group) {
          let dataset = group.node.dataset
          if (dataset.k_input == "Initial") {
            this.signItOrInitialItAndSendMsg(group, data.svg, data.rect)
          }
        }
      }
      // this.dump("after sign")
    })
    this.canvasStore.select(changeSignature).subscribe((data: any | undefined) => {
      if (data && data.svg && data.svg.length > 1) {
        // this.dump("before sign")
        this.signature = data
        let group_id = data.group_id
        let group = this.findGroupById(group_id)
        if (group) {
          let dataset = group.node.dataset
          if (dataset.k_input == "Sign") {
            this.signItOrInitialItAndSendMsg(group, data.svg, data.rect)
          }
        }
      }
      // this.dump("after sign")
    })
  }

  new_at: number = 0
  onMouseDown($event: any) { //Cal
    this.new_at = new Date().getTime()
    let rv = this.newObj($event.offsetX, $event.offsetY)
    this.canvas_service.setSteps(this.draw_tool)
    return rv
  }
  pasteSVG(svg: any, x: number, y: number) {
    let input_field = this.inputFromSVG(svg)
    if (input_field) {
      let id = input_field.node.dataset['id']

      if (this.input_fields[id]) { //change the id
        id = new Date().getTime().toString()
        input_field.node.dataset['id'] = id
      }
      this.input_fields[id] = input_field
      this.moveDelta(id, 20, 20)
      let box: any = this.getBox(id)
      return { id: id, box: box, k_type: "rectangle" }
    }
    return undefined
  }
  undo(msg: any) {
    if (msg.data) {
      let vb
      if (msg.canvas_width) {
        vb = {
          width: msg.canvas_width,
          height: msg.canvas_height
        }
      }
      let new_obj = SVG(msg.data)
      let dataset = new_obj.node.dataset
      let id = msg.id

      if (msg.k_type == "input") {
        // if (new_obj.node.children[0].tagName == 'image') {
        if (dataset["done"] == "true") {
          let rv: any = this.signFromSVG(new_obj, vb)
          let old = this.input_fields[id]
          if (old) {
            old.remove() //undo
            delete this.input_fields[id]
          }
          this.input_fields[id] = rv.group
          this.canvas_service.setSteps(this.draw_tool)
          return
        }
      }
      let old = this.input_fields[msg.id]
      if (old) {
        old.remove() //undo
        delete this.input_fields[msg.id]
      }

      let group = this.inputFromSVG(new_obj, vb)

      if (group) {
        this.input_fields[id] = group
        this.canvas_service.setSteps(this.draw_tool)
      } else {
        console.error("no drawing_input")
      }
    }
  }
  inputFromSVG(group: any, fromViewBox?: any) {
    console.log("inputFromSVG")

    let k_input = group.node.dataset['k_input']

    // if (k_input == "Text") {
    //   this.textFromSVG(group, fromViewBox)
    //   return
    // }

    let children = this.getChildren(group)
    if (children) {
      let in_text = children.text
      let in_input = children.rect
      if (k_input == "Sign" || k_input == "Initial") {
        if (!children.rect) { //it might be a signature or initial
          let rv: any = this.signFromSVG(group, fromViewBox)
          return rv.group
        }
      }

      let drawing_input
      let drawing_text
      let bbox
      let attr = in_input.attr()
      if (fromViewBox) {
        let points = this.getArrayFromStr(attr.points)
        let np = this.parent_canvas.scalePointsToViewBox(points, fromViewBox)
        let nattr = Object.assign({}, attr)
        nattr.points = np
        drawing_input = this.draw_tool.polygon(nattr)
      } else {
        drawing_input = this.draw_tool.polygon(attr)
      }
      if (drawing_input) {
        let tx = in_text.text()

        bbox = drawing_input.bbox()
        // let fs = bbox.height / 4 * 3

        let fs = parseFloat(group.node.dataset['size'])
        let font_s = this.parent_canvas.scaleYtoHeight(fs, fromViewBox.height)
        let font_size = font_s + "px"
        let btm_offset = this.parent_canvas.scaleYtoHeight(3, fromViewBox.height) //this.parent_canvas.deScaleY(3)
        let text_y = bbox.y2 - font_s - btm_offset
        drawing_text = this.draw_tool.text(tx).font({
          anchor: 'start',
          leading: '0em',
          size: font_size
        }).fill("#fff").move(bbox.x + 5, text_y) //input from svg
        let new_group = this.draw_tool.group()
        let id = group.node.dataset['id']
        let k_input = group.node.dataset['k_input']
        new_group.node.dataset['id'] = id
        new_group.node.dataset['k_type'] = 'input'
        new_group.node.dataset['k_input'] = k_input
        new_group.node.dataset['k_target'] = group.node.dataset['k_target']
        new_group.node.dataset['size'] = font_size

        new_group.node.dataset['left'] = bbox.x
        new_group.node.dataset['top'] = bbox.y;
        new_group.node.dataset['done'] = group.node.dataset['done']


        new_group.add(drawing_input)
        new_group.add(drawing_text)
        this.input_fields[id] = new_group
        this.canvas_service.setSteps(this.draw_tool)
        return new_group
      }
    }
  }

  signFromSVG(in_group: any, fromViewBox?: any) {
    let id = in_group.node.dataset['id']
    let k_type = in_group.node.dataset['k_type']
    let k_target = in_group.node.dataset['k_target']
    let k_input = in_group.node.dataset['k_input']
    let k_done = in_group.node.dataset['done']
    let signer
    if (this.signers) {
      signer = this.signers[k_target]
    }
    function getTheImage(g: any) {
      if (in_group.type == "image") {
        return in_group
      }
      let children = in_group.node.children
      for (let i = 0; i < children.length; i++) {
        let el = children[i]
        if (el.tagName == 'image') {
          if (el.instance) {
            return el.instance
          }
          return el
        }
      }
    }
    let image = getTheImage(in_group)
    if (image) {
      // var bbox = in_group.bbox()
      let src
      let attr
      let i_x
      let i_y
      let i_w
      let i_h
      if (image.attr) {
        attr = image.attr()
        src = attr['xlink:href']
        i_x = attr.x
        i_y = attr.y
        i_w = attr.width
        i_h = attr.height
      } else {
        src = image['href'].baseVal
        i_x = image.x.baseVal.value
        i_y = image.y.baseVal.value
        i_w = image.width.baseVal.value
        i_h = image.height.baseVal.value
      }

      let nattr = Object.assign({}, attr)
      let x = this.parent_canvas.scaleXToWidth(i_x, fromViewBox.width)
      let y = this.parent_canvas.scaleYtoHeight(i_y, fromViewBox.height)
      nattr.x = x
      nattr.y = y
      var new_el = this.draw_tool.image(src)
      // new_el.attr(nattr)
      new_el.node.style.userSelect = "none"
      let r_w = this.parent_canvas.scaleXToWidth(i_w, fromViewBox.width)
      let r_h = this.parent_canvas.scaleXToWidth(i_h, fromViewBox.width)
      new_el.size(r_w, r_h)
      new_el.move(x, y)//signFromSVG
      let group = this.draw_tool.group()
      group.node.dataset['k_type'] = k_type
      group.node.dataset['k_target'] = k_target
      group.node.dataset['id'] = id
      group.node.dataset['k_input'] = k_input
      group.node.dataset['done'] = k_done
      group.add(new_el)
      return { group: group, image: image }

    }


    let elements = in_group.children()
    let drawing_rect: any
    let newsignature: any
    let color
    for (let i = 0; i < elements.length; i++) {
      let el = elements[i]
      if (el.type == "polygon") {
        let attr = el.attr()
        if (fromViewBox) {
          let points = this.getArrayFromStr(attr.points)
          let np = this.parent_canvas.scalePointsToViewBox(points, fromViewBox)
          let nattr = Object.assign({}, attr)
          nattr.points = np
          drawing_rect = this.draw_tool.polygon(nattr)
        } else {
          console.log("drawing_rect 1")
          drawing_rect = this.draw_tool.polygon(attr)
        }
      } else if (el.type == "text") {
        let attr = el.attr()
        color = attr.fill
        if (fromViewBox) {
          let bbox: any
          if (drawing_rect) {
            bbox = drawing_rect.bbox()
          } else {
            bbox = el.bbox()
          }
          let nattr = Object.assign({}, attr)

          let fs = parseFloat(in_group.node.dataset['size'])
          let font_s = this.parent_canvas.scaleYtoHeight(fs, fromViewBox.height)
          let font_size = font_s + "px"

          nattr['font-size'] = font_size
          nattr.x = this.parent_canvas.scaleXToWidth(nattr.x, fromViewBox.width)
          nattr.y = this.parent_canvas.scaleYtoHeight(nattr.y, fromViewBox.height)
          newsignature = this.draw_tool.text(nattr)
        } else {
          newsignature = this.draw_tool.text(attr)
        }

        newsignature.node.style.userSelect = "none"
        let text = el.text()
        if (attr["data-done"] != "true") {
          if (signer) {
            text = this.signerPrompt(signer)
            if (k_type == "sign") {
              text += " signature"
            } else {
              text += " initials"
            }
          }
        }
        newsignature.text(text)

        console.log("#FS 2 " + newsignature.attr('font-size') + " " + attr['font-size'] + " " + text + " sing_id " + el.node.dataset['id'])
      } else if (el.type == "polyline") {
        let attr = el.attr()
        if (fromViewBox) {
          let points = this.getArrayFromStr(attr.points)
          let np = this.parent_canvas.scalePointsToViewBox(points, fromViewBox)
          let nattr = Object.assign({}, attr)
          nattr.points = np
          newsignature = this.draw_tool.polyline(nattr)
        } else {
          newsignature = this.draw_tool.polyline(attr)
        }
      }

    }
    if (newsignature) {
      newsignature.node.dataset['k_type'] = k_type
      newsignature.node.dataset['k_input'] = k_input
      newsignature.node.dataset['id'] = id
      newsignature.node.dataset['k_target'] = k_target
    }
    if (drawing_rect) {
      drawing_rect.node.dataset['k_type'] = k_type
      drawing_rect.node.dataset['k_input'] = k_input
      drawing_rect.node.dataset['id'] = id
      drawing_rect.node.dataset['k_target'] = k_target
    }

    if (newsignature || drawing_rect) {
      let group = this.draw_tool.group()
      group.node.dataset['k_type'] = k_type
      group.node.dataset['k_input'] = k_input
      group.node.dataset['k_target'] = k_target
      group.node.dataset['id'] = id
      if (k_done == 'true')
        group.node.dataset['done'] = 'true'
      else
        group.node.dataset['done'] = 'false'
      group.add(drawing_rect)
      group.add(newsignature)

      // this.setupRectangle(group)
      let new_signer: any
      if (k_target == "caller" && color) {
        new_signer = {
          roll: k_target,
          color: color
        }
      }
      return { group: group, rect: drawing_rect, signature: newsignature }
    }
    return undefined
  }
  signerPrompt(signer: any) {

    let name = ""
    if (signer) {
      if (signer.email && this.call_id) {
        if (signer.email == this.signer_email) {
          name = "Your"
        }
      }
      if (!name) {
        let parts: string[] | undefined
        if (signer.name) {
          if (signer.name.length > 0) {
            parts = signer.name.split(' ')
          }
        }
        if (!parts) {
          if (signer.email) {
            if (signer.email.length > 1) {
              parts = signer.email.split('@')
            }
          }
        }
        if (!parts) {
          if (signer.title) {
            parts = signer.title.split(' ')
          }
        }
        if (parts) {
          if (parts.length > 0) {
            name = parts[0].substring(0, 1) + "."
          }
          if (parts.length > 1) {
            name += parts[1].substring(0, 1) + "."
          }
        }
      }
    }
    return name
  }
  copy(selected_id: string) {
    let group = this.findGroupById(selected_id)// input_fields[selected_id]
    if (group) {
      return group.svg()
    }
    return undefined
  }

  newObj(left: number, top: number) {
    console.log("new input ")
    if (!this.selected_input || !this.dims[this.selected_input]) {
      return
    }
    left = this.parent_canvas.deScaleX(left)
    top = this.parent_canvas.deScaleY(top)
    let btm_offset = this.parent_canvas.deScaleY(3)
    let w = this.dims[this.selected_input].w * this.parent_canvas.viewBoxWidth / 900
    let h = this.dims[this.selected_input].h * this.parent_canvas.viewBoxWidth / 900


    if (this.parent_canvas.divContainer) {
      console.log("CW " + this.parent_canvas.divContainer.nativeElement.clientWidth)
      // w = w * this.parent_canvas.divContainer.nativeElement.clientWidth / this.parent_canvas.viewBoxWidth
      // h = h * this.parent_canvas.divContainer.nativeElement.clientWidth / this.parent_canvas.viewBoxWidth
    }
    let fs = h / 4 * 3
    top -= h
    let right = left + w
    let bottom = top + h
    let text_y = bottom - fs - btm_offset
    let drawing_text = this.draw_tool.text(this.selected_input).font({
      anchor: 'start'
      , leading: '0em',
      size: fs
    }).fill("#fff").move(left + h / 4, text_y) //newObj
    // let bbox = drawing_text.bbox()


    let arr = [
      left, top,
      left, bottom,
      right, bottom,
      right, top] //right bottom 6,7
    let color = this.fill_color
    if (this.signers && this.signer_key) {
      let signer = this.signers[this.signer_key]
      if (signer) {
        color = signer.color
      }
    }
    let drawing_input = this.draw_tool.polygon(arr).fill(color).stroke({ width: 1 })

    let input_field_id = new Date().getTime().toString()
    drawing_input.node.dataset['id'] = input_field_id
    drawing_input.node.dataset['k_type'] = "input"
    drawing_input.node.dataset['k_input'] = this.selected_input
    drawing_input.node.dataset['k_target'] = this.signer_key


    let group = this.draw_tool.group()
    group.node.dataset['id'] = input_field_id
    group.node.dataset['k_type'] = 'input'
    group.node.dataset['k_input'] = this.selected_input
    group.node.dataset['k_target'] = this.signer_key
    group.node.dataset['size'] = fs.toString()

    group.node.dataset['left'] = left
    group.node.dataset['top'] = top



    group.node.dataset['text_size'] = this.text_size

    group.node.dataset['text_font'] = this.text_font
    group.node.dataset['text_color'] = this.text_color
    group.node.dataset['text_weight'] = this.text_weight

    group.add(drawing_input)
    group.add(drawing_text)
    this.input_fields[input_field_id] = group

    let svg = group.svg()
    this.canvas_service.sendCanvasMsg("input", "new", input_field_id, svg)
    // this.setupRectangle(drawing_input)
    this.canvasStore.dispatch(new AddToUndo({
      undo: "new",
      k_type: "input",
      k_input: this.selected_input,
      id: input_field_id
    }))

    let box: any = this.getBox(input_field_id)
    return { id: input_field_id, box: box, k_type: "input", k_input: this.selected_input }
  }
  getChildren(group: any): any {
    if (group) {
      let children = group.children()
      let drawing_text
      let drawing_input
      for (let i = 0; i < children.length; i++) {
        let el = children[i]
        if (el.type == "polyline" || el.type == "polygon") {
          drawing_input = el
        } else if (el.type == "text") {
          drawing_text = el
        }
      }
      return {
        rect: drawing_input,
        text: drawing_text
      }
    }
    return undefined
  }
  moveObj(selected_id: string, x1: number, y1: number, x2: number, y2: number) {
    if (this.input_fields) {
      let children = this.getChildren(this.input_fields[selected_id])
      if (children) {
        let drawing_text = children.text
        let drawing_input = children.rect

        if (drawing_input) {
          // let group = this.input_fields[selected_id]
          console.log("#el1 " + x1 + " " + x2)
          if (x1 > x2) {
            let t = x1
            x1 = x2
            x2 = t
          }
          if (y1 > y2) {
            let t = y1
            y1 = y2
            y2 = t
          }

          let w = x2 - x1
          let h = y2 - y1
          let left = x1;
          let top = y1;
          // console.log("#el1 " + left + " " + top + " " + w + " " + h)

          left = this.parent_canvas.deScaleX(left)
          w = this.parent_canvas.deScaleX(w)

          top = this.parent_canvas.deScaleY(top) //We might have to descale from the bottom
          h = this.parent_canvas.deScaleY(h)

          let points = [
            left, top, // left top
            left, top + h, // left bottom
            left + w, top + h, //right bottom
            left + w, top] //right top

          drawing_input.attr({ points: points })
          if (drawing_text) {
            drawing_text.move(left + 4, top)
          }
        } else { //check to see if it is an image
          let group = this.findGroupById(selected_id)
          if (group) {
            if (group.node.children.length > 0) {
              let image = group.node.children[0].instance
              if (image) {
                if (image.type == "image") {
                  let r_w = x2 - x1;
                  let r_h = y2 - y1;
                  image.size(r_w, r_h)
                  image.move(x1, y1)
                  console.log("resize image")

                  let svg = group.svg()
                  this.canvas_service.sendCanvasMsg("input", "move", selected_id, svg)

                }
              }
            }
          }
        }

      }

    }

  }
  getBox(selected_id: string): any | undefined { //subclassed for mullti select
    if (this.input_fields) {
      // let group = this.input_fields[selected_id]
      let group = this.findGroupById(selected_id)

      if (group) {
        let bbox = group.bbox()

        let children = this.getChildren(group)
        if (children && children.rect) {
          bbox = children.rect.bbox()
        }
        // let attr = input_field.attr()//this.drawing_ellipse.attr({ cx: cx, cy: cy, rx: rx, ry: ry })
        // let points = this.getArrayFromStr(attr.points)

        let rv =
        {
          x1: bbox.x,
          y1: bbox.y,
          x2: bbox.x2,
          y2: bbox.y2,
          back: true, //make back div
          new_point: false, //new_point for polygon
          svg: group.svg() //for the undo

        }


        // console.log("rect got box")
        return rv
      }
    }
    return undefined
  }

  moveDelta(selected_id: string, delta_x: number, delta_y: number) {//subclassed for mullti select

    if (this.input_fields) {
      let children = this.getChildren(this.input_fields[selected_id])
      if (children) {
        let drawing_text = children.text
        let drawing_input = children.rect
        if (drawing_input) {
          let bbox = drawing_input.bbox()
          let left = bbox.x + delta_x
          let top = bbox.y + delta_y
          let points = [
            left, top, // left top
            left, top + bbox.height, // left bottom
            left + bbox.width, top + bbox.height, //right bottom
            left + bbox.width, top] //right top
          drawing_input.attr({ points: points })
          if (drawing_text) {
            let tbbox = drawing_text.bbox()
            let left = tbbox.x + delta_x
            let top = tbbox.y + delta_y
            drawing_text.move(left, top) //moveDelta
            console.log("#in  moveDelta")
          }
          return
        }
      }
    } //check to see if it is an image
    let group = this.findGroupById(selected_id)
    if (group) {
      if (group.node.children.length > 0) {
        let image = group.node.children[0].instance
        if (image) {
          if (image.type == "image") {
            let bbox = group.bbox()
            let left = bbox.x + delta_x
            let top = bbox.y + delta_y
            image.move(left, top)
            console.log("move image")

            let svg = group.svg()
            this.canvas_service.sendCanvasMsg("input", "move", selected_id, svg)

          }
        }
      }
    }
  }

  //****************************************************************************
  // Select
  //****************************************************************************
  erase(id: string) {
    if (this.span) {//if we are editing, the delete is inside the span
      return
    }
    let rv
    let obj = this.input_fields[id]
    if (obj) {
      rv = {
        undo: "erase",
        k_type: "input",
        id: id,
        data: obj.svg()
      }
      obj.remove() //erase
      this.canvas_service.sendCanvasMsg("input", "erase", id)
      this.canvas_service.setSteps(this.draw_tool)
    }
    return rv
  }
  select(selected_id: string) {
    let now = new Date().getTime()
    let dif = now - this.new_at
    if (dif > 500) {
      let group = this.findGroupById(selected_id)
      if (group) {
        console.log("input")
        let dataset = group.node.dataset
        let k_target = dataset['k_target']
        let signer = this.signers[k_target]
        let target_email = signer.email
        if (!target_email) {
          return
        }
        if (this.parent_canvas.my_email && k_target && this.parent_canvas.my_email == target_email) {
          if (dataset.k_input == "Sign" || dataset.k_input == "Initial") {
            this.signOrInitial(group)
          } else {
            let now = new Date().getTime()
            let dif = now - this.span_removed_at
            if (dif > 300) {
              if (dataset.k_input != "Date") {
                this.onTextDown(selected_id)
              } else {
                this.newHTMLDate(group, dataset['id'])
              }
            }
          }
        }
      }
    }
  }
  //****************************************************************************
  // Sing and Initial
  //****************************************************************************

  signOrInitial(group: any) {
    console.log("signOrInitial")
    let dataset = group.node.dataset

    if (dataset['done'] == 'true') {
      return
    }
    let id = dataset['id']


    let k_input = dataset['k_input']
    if (k_input == "Sign") {
      if (!this.signature || !this.signature.svg || this.signature.svg.length < 1) {
        this.canvasStore.dispatch(new GetSignature(id))

        return
      }
      this.signItOrInitialItAndSendMsg(group, this.signature.svg, this.signature.rect)
    }

    if (k_input == 'Initial') {
      if (!this.initials! || !this.initials.svg || this.initials.svg.length < 1) {
        this.canvasStore.dispatch(new GetInitials(id))
        return
      }
      this.signItOrInitialItAndSendMsg(group, this.initials.svg, this.initials.rect)
    }
  }
  signItOrInitialItAndSendMsg(group: any, ssvg: string, rect: any) {
    let sing_id = group.node.dataset['id']
    let k_type = group.node.dataset['k_type']
    let k_input = group.node.dataset['k_input']
    this.canvasStore.dispatch(new AddToUndo({
      undo: "svg",
      k_type: k_type,
      k_input: k_input,
      id: sing_id,
    }))
    let svg = this.signItOrInitialIt(group, ssvg, rect)
    this.canvas_service.sendCanvasMsg("input", k_input, sing_id, svg)
    this.canvasStore.dispatch(new DidStep(sing_id))
  }
  signItOrInitialIt(group: any, ssvg: string, rect: any) {
    let target_box

    //First look at the existing box and remove the previous content
    let children = group.children()

    for (let i = 0; i < children.length; i++) {
      let el = children[i]
      if (el.type == 'polygon') {
        target_box = el.bbox()
      }
      if (el.type == 'text' || el.type == "polyline" || el.type == 'polygon') {
        el.remove() //sing or initial
      }
    }
    if (!target_box && group && group.node && group.node.dataset && group.node.dataset['target_box']) {
      target_box = JSON.parse(group.node.dataset['target_box'])
      if (!target_box) {
        console.error("!target_box ")
        return
      }
    }


    function getChildren(svg: any, rv: any[]): any[] {
      let children = svg.children()

      for (let i = 0; i < children.length; i++) {
        let el = children[i]
        if (el.type == "polyline") {
          rv.push(el)
        } else if (el.type == "text") {
          rv.push(el)
        } else if (el.type == "image") {
          rv.push(el)
        } else if (el.type == "svg" || el.type == "g") {
          rv = getChildren(el, rv)
        }
      }
      return rv
    }

    let svg: any = SVG(ssvg)
    let new_children = getChildren(svg, [])

    let x1 = target_box.x
    let y1 = target_box.y
    let x2 = x1 + target_box.width
    let y2 = y1 + target_box.height

    let new_width = (x2 - x1)
    let new_height = (y2 - y1)
    let l = 1000
    let t = 1000
    let b = 0
    let r = 0
    for (let i = 0; i < new_children.length; i++) {
      let el = new_children[i]
      if (el.type == "polyline") {
        var bbox = el.bbox();
        if (bbox.x < l) {
          l = bbox.x
        }
        if (bbox.y < t) {
          t = bbox.y
        }

        if (bbox.x2 > r) {
          r = bbox.x2
        }
        if (bbox.y2 > b) {
          b = bbox.y2
        }
      }
    }
    let scale = 1
    let scaled_height = new_height
    let scaled_width = new_width

    if (t < 1000) {
      let orig_height = b - t
      let orig_width = r - l
      let scale_x = new_width / orig_width
      let scale_y = new_height / orig_height

      if (scale_x < scale_y) {
        scale = scale_x
      } else {
        scale = scale_y
      }
      scaled_height = orig_height * scale
      scaled_width = orig_width * scale
      console.log("signature  w1 " + orig_width + " w2 " + scaled_width)
      console.log("signature  h1 " + orig_height + " h2 " + scaled_height)

    }

    var new_el
    for (let i = 0; i < new_children.length; i++) {
      let el = new_children[i]
      if (el.type == "image") {
        var bbox = el.bbox()

        let attr = el.attr()
        let src = attr['xlink:href']
        new_el = this.draw_tool.image(src)
        let xv1 = new_el.bbox()
        new_el.node.dataset['id'] = group.node.dataset['id']
        new_el.node.dataset['k_type'] = group.node.dataset['k_type']
        new_el.node.dataset['k_target'] = group.node.dataset['k_target']
        new_el.node.dataset['k_input'] = group.node.dataset['k_input']

        new_el.node.dataset['done'] = 'true'

        // let nattr = Object.assign({}, attr)
        var t_w = bbox.width;
        var t_h = bbox.height;

        let r_w = x2 - x1
        let r_h = t_h * r_w / t_w //y2 - y1
        new_el.size(r_w, r_h)
        // let xv2 = new_el.bbox()
        // let nw = t_w * r_h / t_h
        // let left = r_w - nw
        let x = target_box.x
        let y = target_box.y - r_h + target_box.height
        new_el.node.style.userSelect = "none"
        new_el.move(x, y)//signItOrInitialIt
        // let xv3 = new_el.bbox()
        // return new_el.svg()
        // console.log("Center it")

      } else if (el.type == "text") {
        var bbox = el.bbox()
        let attr = el.attr()
        let nattr = Object.assign({}, attr)
        let ts = parseFloat(attr['font-size'])
        let font_size = this.parent_canvas.scaleXToWidth(ts, 612) + "px"
        nattr['font-size'] = font_size
        // if (target_box) {
        //   nattr['font-size'] = target_box.height + "px"
        // }
        var t_w = bbox.width;
        var t_h = bbox.height;
        let r_w = x2 - x1
        let r_h = y2 - y1
        // let x = x1 + (r_w - t_w) / 2
        let cy = (r_h - t_h) / 2
        let y = y1 + cy + t_h - 5
        let text = el.text()
        new_el = this.draw_tool.text(nattr)
        new_el.node.style.userSelect = "none"
        new_el.text(text)
        // console.log("#FS 3 " + new_el.attr('font-size') + " " + text + " sing_id " + group1.node.dataset['id'])
        let x = target_box.x
        new_el.attr({ x: x, y: y })

        new_el.node.dataset['font'] = nattr['font-family']
        new_el.node.dataset['size'] = nattr['font-size']
        new_el.node.dataset['weigth'] = nattr['font-weigth']

        console.log("Center it")

      } else if (el.type == "polyline") {

        let dy = (new_height - scaled_height) / 2
        let dx = (new_width - scaled_width) / 2
        console.log("signature  dx " + dx + " dy " + dy)

        let new_points: number[] = []
        let spoints = el.attr('points')
        let points = this.getArrayFromStr(spoints)

        for (let i = 0; i < points.length; i++) {
          let p = points[i]
          if (i % 2 == 0) { //X
            let px = p - l
            px *= scale
            p = px + dx + x1
          } else {
            let py = p - t
            py *= scale
            p = py + dy + y1
          }
          new_points.push(p)
        }
        new_el = this.draw_tool.polyline(new_points).fill('transparent').stroke({ width: 1, color: 'black' })

      }
    }

    new_el.node.dataset['id'] = group.node.dataset['id']
    new_el.node.dataset['k_type'] = group.node.dataset['k_type']
    new_el.node.dataset['k_target'] = group.node.dataset['k_target']
    new_el.node.dataset['k_input'] = group.node.dataset['k_input']
    new_el.node.dataset['done'] = 'true'

    let group1 = this.draw_tool.group()
    group1.node.dataset['id'] = group.node.dataset['id']
    group1.node.dataset['k_type'] = group.node.dataset['k_type']
    group1.node.dataset['k_target'] = group.node.dataset['k_target']
    group1.node.dataset['k_input'] = group.node.dataset['k_input']
    group1.node.dataset['target_box'] = JSON.stringify(target_box)
    group1.node.dataset['done'] = 'true'
    group1.add(new_el)
    group.remove() //sing or initial

    if (new_el.type != "image") {
      // //Center evry thing
      let bx = this.parent_canvas.scaleBox(target_box)
      group1.node.dataset['done'] = 'true'
      let id = group1.node.dataset['id']
      this.moveObj(id, bx.x, bx.y, bx.x2, bx.y2)
    }
    //Dec 2, 23, this is what the canvas does aftr a new object is created
    // if (bx) {
    //   this.parent_canvas.removeMultiSelBoxes()
    //   this.parent_canvas.processMultiSel(id, bx, group1.node.dataset['k_type'])
    //   this.parent_canvas.makeGroupFromBoxes()
    //   // this.processSingleSel(newRv.id, newRv.box, newRv.k_type)
    // }

    return group1.svg()
  }
  //****************************************************************************
  // Text
  //****************************************************************************
  onTextDown(id: string) {

    if (!this.span && (!this.sel_tool_name || this.sel_tool_name == 'none')) {
      // let span = $event.srcElement.instance.node.parentElement
      console.log("#tx on pointer down " + id)

      let group = this.findGroupById(id)
      if (group) {
        if (group.node) {
          if (group.node.dataset) {
            if (group.node.dataset['text_size']) {
              this.text_size = group.node.dataset['text_size']
            }
            if (group.node.dataset['text_font']) {
              this.text_font = group.node.dataset['text_font']
            }
            if (group.node.dataset['text_color']) {
              this.text_color = group.node.dataset['text_color']
            }
            if (group.node.dataset['text_weight']) {
              this.text_weight = group.node.dataset['text_weight']
            }
          }
        }
        let children = this.getChildren(group)
        let attr
        let text = ""
        if (children) {
          let tx
          if (children.text) {
            tx = children.text.text()
            attr = children.text.attr()
          }
          if (!tx) {
            console.error("No tx ")
          }


          // let font_family = line.node.style.fontFamily
          // let font_weight = line.node.style.fontWeight
          // let font_size = line.node.style.fontSize


          let box1
          if (children.rect) {
            box1 = children.rect.bbox()
          } else {
            box1 = group.bbox()
          }

          let bbox = this.parent_canvas.scaleBox(box1)
          let fs = parseFloat(group.node.dataset['size'])
          let font_size = this.parent_canvas.scaleY(fs) + "px"

          this.newText(group, text, bbox.x + 1, bbox.y + 4, bbox.width, bbox.height + 2, this.text_font, font_size, this.text_weight, "#000")

          let svg = group.svg()
          this.canvasStore.dispatch(new AddToUndo({
            undo: "text",
            k_type: "text",
            id: id,
            data: svg
          }))

          // group.remove() //we will put the text back in the group
        }
      }
    }
  }
  newText(group: any, text: string, left: number, top: number, width: number, height: number, text_font: string, font_size: string, text_weight: string, text_color: string) { //Cal
    //This creates a text element in the div, no resizing needit until we fade
    if (!this.bSelected) {
      let children = this.getChildren(group)
      let back_color = children.rect.fill()
      let text_color = children.text.fill()
      let text = children.text.text()
      let dataset = group.node.dataset
      if (text == dataset.k_input) {
        text = ""
      }

      this.bSelected = true;

      console.log("#TXT new span for " + dataset.id)
      this.span = document.createElement('span')
      this.span.setAttribute('contenteditable', 'true')
      this.span.style["overflow-y"] = "hidden"

      this.span.style.position = "absolute"
      this.span.style.left = left + "px"

      // top -= 8; //To compoensate for the mouse icon, oct 17, 23
      let top_minus_border = top - 4 //last 2 is the border
      this.span.style.top = top_minus_border + "px"

      this.span.style.padding = "4px"
      this.span.style.outline = "1px solid #FFBA55"
      this.span.style.background = back_color

      this.span.style.minWidth = "50px"
      let mw = this.canvas.clientWidth - left - 20
      this.span.style.maxWidth = mw + "px"
      this.span.style.borderRadius = "4px"
      this.span.innerHTML = text
      this.canvasStore.dispatch(new ClearSel())

      this.span.onblur = onSpanBlur
      this.span.onpointerdown = onSpanDown
      this.span.onfocus = onSpanFocus


      this.span.dataset['id'] = dataset.id
      this.span.dataset['k_type'] = dataset.k_type

      this.span.dataset['k_input'] = dataset.k_input
      this.span.dataset['k_target'] = dataset['k_target']

      this.span.style.fontFamily = text_font
      this.span.style.fontSize = font_size
      this.span.style['font-size'] = font_size
      this.span.style.lineHeight = font_size
      this.span.style.fontWeight = text_weight
      this.span.style.color = text_color
      if (dataset.id != "new") {
        let w_minus_padding = width - 4 * 2 - 2 //last 2 is the border
        this.span.style.width = w_minus_padding + "px"

        let h_minus_padding = height - 4 * 2 - 2 //last 2 is the border
        this.span.style.minHeight = h_minus_padding + "px"
        this.span.style.height = "fit-content"

      }
      this.canvas.appendChild(this.span)
      let me = this



      function onSpanDown(this: any, $event: any) {
        $event.stopPropagation()
        if ($event.shiftKey) {
          // console.log("#TXT onSpanDown 2 " + new Date().getTime())

          me.onSpanBlur(this)
          let box = me.getBox(dataset.id)
          me.parent_canvas.processMultiSel(dataset.id, box, "text")
          return
        }
        me.mouse_down_at = 1
        me.canvas.onpointerup = onSpanUp
        setTimeout(() => {

          if (me.mouse_down_at) {
            if (me.sel_tool_name == "none") {
              me.x1 = parseFloat(this.style.left)
              me.y1 = parseFloat(this.style.top)

              me.startX = -1
              me.startY = -1
              me.pointer_id = $event.pointerId
              me.canvas.setPointerCapture(me.pointer_id)
              me.canvas.onpointermove = onMoveSpan

              me.moving_span = this
              me.moving_span.style.cursor = 'grab'
              console.log("#sp dw")
            }
          }
        }, 500)
      }
      function onMoveSpan($event: any) {
        $event.stopPropagation()
        if (me.moving_span) {

          if (me.startX < 0) {
            me.startX = $event.offsetX
            me.startY = $event.offsetY
            me.move_undo_params = {
              undo: "move",
              k_type: "text",
              id: me.moving_span.dataset['id'],
              data: { left: me.x1, top: me.y1 }
            }

            // console.log("#sp mv + undo move")
          }
          let delta_x = $event.offsetX - me.startX
          let delta_y = $event.offsetY - me.startY
          let x = me.x1 + delta_x
          let y = me.y1 + delta_y
          me.moving_span.style.left = x + "px"
          me.moving_span.style.top = y + "px"
        }
      }

      function onSpanUp(this: any, $event: any) {
        me.mouse_down_at = 0

        console.log("#sp up")
        if (me.moving_span) {
          let left = parseFloat(me.moving_span.style.left)
          let mw = me.canvas.clientWidth - left - 20
          me.moving_span.style.maxWidth = mw + "px"
          me.moving_span.style.cursor = "text"

          let id = me.moving_span.dataset['id']
          let top = parseFloat(me.moving_span.style.top)
          me.canvas_service.sendCanvasMsg("input", "move", id, { left: left, top: top })
        }
        me.moving_span = undefined
        me.canvas.onpointermove = undefined
        me.canvas.onpointerup = undefined
        if (me.pointer_id) {
          this.releasePointerCapture(me.pointer_id)
          me.pointer_id = undefined
        }
      }

      function onSpanFocus(this: any, $event: any) {
        // this.style.background="lightblue"
        me.span.style.outline = "1px solid #FFBA55"

      }
      function onSpanBlur(this: any, $event: any) {
        // console.log("#TXT onSpanBlur 1 " + new Date().getTime())

        me.onSpanBlur(this)
        return
      }

      console.log("#tt new rectangle " + this.span.dataset['id'] + " " + this.span.style.left + " " + this.span.style.top)
      setTimeout(() => {
        this.span.focus()
        this.parent_canvas.selectObj(dataset.id, "input")
      }, 100)
    }

  }
  onSpanBlur(obj: any) {

    this.bSelected = false

    let tx = obj.outerText
    let id = obj.dataset['id']

    if (id == "new") { //its a new one
      id = new Date().getTime()
    }
    let group
    if (tx.length > 0) {
      group = this.findGroupById(id)
      if (group) {
        let children = this.getChildren(group)
        if (children) {
          if (children.text) {
            {
              children.text.text(tx)
              group.node.dataset['done'] = true;
            }
          }
          // if (children.rect && this.span) {
          //   let box1 = children.rect.bbox()
          //   let bbox = this.parent_canvas.scaleBox(box1)
          //   let h = this.parent_canvas.scaleY(this.span.clientHeight)
          //   let bottom = bbox.y + h
          //   this.moveObj(id, bbox.x, bbox.y, bbox.x2, bottom)
          // }
        }

      }
    }

    if (this.span) {
      console.log("#TXT before removed span for " + id + this.span.clientWidth + " " + this.span.clientHeight)
      this.span.onblur = undefined
      this.span.onpointerdown = undefined
      this.span.onfocus = undefined

      this.canvas.removeChild(this.span)
      console.log("#TXT removed span for " + id)
      this.span_removed_at = new Date().getTime()
      delete this.span

    }
    if (group) {
      let k_input = group.node.dataset.k_input
      let tsvg = group.svg()
      this.canvas_service.sendCanvasMsg("input", k_input, id, tsvg)
      this.canvasStore.dispatch(new DidStep(id))
    }
  }
  //****************************************************************************
  //  Date
  //****************************************************************************
  setDates() {
    let keys = Object.keys(this.input_fields)
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      let group = this.findGroupById(key)// input_fields[key]
      let dataset = group.node.dataset
      if (dataset.k_input == "Date") {
        let k_target = dataset['k_target']
        let box_email = this.signers[k_target].email
        if (box_email) {
          this.newHTMLDate(group, key)
        }
      }

    }
  }
  newHTMLDate(group: any, id: string) {
    let me = this
    console.log("#date on pointer down ")
    if (group) {
      let children = this.getChildren(group)
      if (children) {
        if (!children.text || !children.rect) {
          return
        }
        if (!this.span) {
          let back_color = children.rect.fill()
          let color = children.text.fill()
          this.span = document.createElement('input')
          this.span.setAttribute('type', 'date')
          this.span.style.position = "absolute"
          this.span.style.placholder = "mm/dd/yyyy"
          this.span.style.background = back_color //"red"
          this.span.style.color = color //"red"


          let attr = children.text.attr()
          let ts = parseFloat(attr['font-size'])
          let font_size = this.parent_canvas.scaleXToWidth(ts, 612) + "px"
          this.span.style['font-size'] = font_size

          this.span.style["-webkit-text-fill-color"] = color

          let box1 = children.text.bbox()
          let bbox = this.parent_canvas.scaleBox(box1)
          this.span.style.left = bbox.x1 + "px"
          this.span.style.top = bbox.y1 + "px"
          this.span.style.width = ".1px"
          this.span.style.height = ".1px"

          this.span.dataset['id'] = id

          this.span.innerHTML = children.text.text()
          this.canvas.appendChild(this.span)
          let me = this
          this.span.onchange = function changeDate($event: any) {
            let id = $event.target.dataset['id']
            let date = $event.target.value
            console.log("changed date " + id + " " + date)
            let group1 = me.findGroupById(id)
            let children1 = me.getChildren(group1)
            children1.text.text(date)
            group1.node.dataset['done'] = true
            group.node.dataset['done'] = true
            me.canvas.removeChild(me.span)
            me.span = undefined
            me.canvasStore.dispatch(new DidStep(id))
            let k_input = group.node.dataset.k_input
            me.canvas_service.sendCanvasMsg("input", k_input, id, group.svg())

          }
        }
        setTimeout(() => me.span.showPicker())
      }
    }
  }
  //****************************************************************************
  processMsg(msg: any) {
    if (msg.action == "erase") {
      let obj = this.input_fields[msg.id]
      if (obj) {
        obj.remove()//erase
        this.canvas_service.setSteps(this.draw_tool)
        this.parent_canvas.removeMultiSelBoxes()
        this.parent_canvas.removeInfoAndAnchors()
        // this.canvasStore.dispatch(new RemovedStep(msg.id))
      }
    } else {
      this.undo(msg)
    }
    // if (msg.action == "size") {
    //   this.processFontSize(msg.id, msg.data)
    //   return
    // }


  }
  reDraw(group: any) {
    if (group) {

      let children = this.getChildren(group)
      if (children) {
        if (!children.text || !children.rect) {
          return
        }

        let ts = parseFloat(this.text_size)
        let text_size = ts * this.parent_canvas.viewBoxWidth / 900

        group.node.dataset['font'] = this.text_font
        group.node.dataset['size'] = this.text_size
        group.node.dataset['weigth'] = this.text_weight

        children.text.font({
          family: this.text_font
          , size: text_size
          , weight: this.text_weight
        })
      }
    }
  }
}
