import { Component, Input, OnInit } from '@angular/core';
import { fomatTime } from 'src/app/utils';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';
import { recStatus } from 'src/app/pages/pages.selectors';

import { WSSState } from 'src/app/wss/wss.model';
import { callId } from 'src/app/wss/wss.selectors';
import { StartRecording, StopRecording } from 'src/app/pages/pages.actions';


@Component({
  selector: 'recording',
  templateUrl: './recording.component.html',
  styleUrls: ['./recording.component.scss'],
  animations: [
    trigger('showHide', [
      state('hide', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('show => hide', [
        animate('3.0s')
      ]),
      transition('hide => show', [
        animate('3.0s')
      ]),
    ]),

  ]
})
export class RecordingComponent {
  rec_time_string: string = ""
  rec_status: string = ""
  uploading: number = 100 //%
  start_time: number = 0
  interval: any
  duration: string = ""
  isOn: boolean = true;
  on_count: number = 0

  call_id: string | undefined;
  subscriptions: any[] = []

  constructor(
    private pagesState: Store<PagesState>,
    public wssState: Store<WSSState>,
  ) {
    console.log("rec ctrl")
    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
    }))
    this.subscriptions.push(this.pagesState.select(recStatus).subscribe((rec_status: string) => {
      this.rec_status = rec_status;
      if (this.rec_status == "ready") {
        this.duration = ""

      } else if (this.rec_status == "starting") {
        this.duration = ""
      } else if (this.rec_status == "recording") {
        this.start_time = new Date().getTime()
        if (++this.on_count > 3) {
          this.isOn = !this.isOn
          this.on_count = 0
        }
        this.interval = setInterval(() => this.setDuration(), 1000)
        return
      }
      // else if (this.rec_status == "stopping") {
      //   this.duration = "Uploading ..."
      // }

      if (this.interval)
        clearInterval(this.interval)

    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    clearInterval(this.interval)
  }
  setDuration() {
    let now = new Date().getTime()
    this.duration = fomatTime(now - this.start_time)
  }
  togggleRec() {
    if (this.rec_status == "ready") {
      this.pagesState.dispatch(new StartRecording())
    } else if (this.rec_status == "recording") {
      this.pagesState.dispatch(new StopRecording())
    }

  }
}
