import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolsComponent } from './tools/tools.component';
import { ControlsModule } from '../controls/controls.module';
import { FormsModule } from '@angular/forms';
import { AssetsModule } from '../pages/assets/assets.module';
import { KzLibModule } from '../kz-lib/kz-lib.module';

@NgModule({
  declarations: [
    ToolsComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ControlsModule,
    AssetsModule,
    KzLibModule
  ], exports: [
    ToolsComponent
  ]
})
export class ToolsModule { }
