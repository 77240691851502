import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChapalaMLSService } from '../chapala-mls-service';
import { ChapalaMLSState } from '../chapala-mls.model';
import { gotCities, gotMLSMsg, gotProperties, pageNo, searchBy, searchUrl, selProperty } from '../chapala-mls.selectors';
import { CleanMLS, ClearSearch, SelProperty, SetSearch } from '../chapala-mls.actions';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';

@Component({
  selector: 'chapala-mls',
  templateUrl: './mls-search.component.html',
  styleUrls: ['./mls-search.component.scss']
})
export class MlsSearchComponent implements OnInit {
  theme: string = ""

  btns_width: number = 660
  header_height: number = 200
  search_width: number = 200
  search_top: number = 200
  search_left: number = 20
  subscriptions: any[] = []
  btncolor: string = "#152666"
  iconcolor: string = "#152666"
  map: google.maps.Map | undefined;
  // page_no: number = 1

  properties: any[] = []
  markers: any[] = []
  center: any = {
    lat: 20.29265,
    lng: -103.22500
  };//map.setCenter(marker.getPosition() as google.maps.LatLng);
  zoom: number = 4
  search_url: string = "";
  sel_property: any
  border: string = "1px solid gray"
  bShowSelLocation: boolean = true

  @Input() bShowClose: boolean = false;
  @ViewChild('topDiv') topDiv: ElementRef | undefined;

  show_opt: string = ""
  bShowMap: boolean = true


  constructor(
    private chapalaMLState: Store<ChapalaMLSState>,
    private chapala_mls_service: ChapalaMLSService,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("mls-search");

    this.chapalaMLState.dispatch(new SelProperty(undefined))
    try {
      this.chapala_mls_service.getStates()
    } catch (e) {
      console.error(e)
    }

    // this.subscriptions.push(this.chapalaMLState.select(showOpt).subscribe((opt: any) => {
    //   this.show_opt = opt.name
    // }))
    this.subscriptions.push(this.chapalaMLState.select(gotMLSMsg).subscribe((msg: any) => {
      //console.log("<-- " + JSON.stringify(msg))
      if (msg) {
        if (msg.show_all) {
          this.chapalaMLState.dispatch(new CleanMLS())
          this.chapalaMLState.dispatch(new SetSearch("state", undefined))
        }
      }
    }))
  }

  ngAfterViewInit() {
    this.initMap()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    this.markers.forEach((marker) => {
      marker.setMap(null);
    })
  }

  createMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    })
    // list of hardcoded positions markers
    let markers = []
    var bounds = new google.maps.LatLngBounds();


    if (this.map && this.properties) {
      //iterate latLng and add markers

      for (let i = 0; i < this.properties.length; i++) {
        let prop = this.properties[i]
        let position = {
          lat: parseFloat(prop.lat_add),
          lng: parseFloat(prop.long_add)
        }
        var marker: any = new google.maps.Marker({
          position: position,
          map: this.map,
          title: prop.ref
        });
        marker['sel_prop'] = i
        let me = this;
        marker.addListener("click", function($event: any) {
          // me.chapalaMLState.dispatch(new SelProperty(this.property))
          // let msg: any = {
          //   sel_prop: this.sel_prop
          // }
          // me.chapala_mls_service.sendMsg(msg)
        });
        markers.push(marker)
        bounds.extend(position) // your marker position, must be a LatLng instance
      }
      this.markers = markers
      this.map.fitBounds(bounds);
    }
  };
  ngOnInit(): void {
    this.subscriptions.push(this.chapalaMLState.select(searchUrl).subscribe((url: string) => {
      if (url) {
        this.search_url = url;
        this.chapala_mls_service.getProperties(this.search_url)
      }
    }))

    this.chapalaMLState.select(searchBy).subscribe((search_by: any) => {
      if (search_by) {

        if (search_by.city) {
          if (!this.map) {
            this.initMap()
          }

        }
      }
    })

    this.subscriptions.push(this.chapalaMLState.select(gotProperties).subscribe((properties: any) => {
      if (properties) {
        if (properties.properties) {
          this.properties = properties.properties
          this.createMarkers()
          this.bShowSelLocation = false;
        }
      }
    }))

    this.subscriptions.push(this.chapalaMLState.select(selProperty).subscribe((property: any) => {
      this.sel_property = property
    }))
  }



  initMap(): void {

    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: this.zoom,
        center: this.center,
      }
    );


    this.map.addListener("center_changed", () => {
    });
    this.map.addListener("zoom_changed", () => {

    });

  }

  close() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
  closeProp() {
    this.chapalaMLState.dispatch(new SelProperty(undefined))
  }
  showAll() {
    let msg: any = {
      show_all: true
    }
    this.chapala_mls_service.sendMsg(msg)
  }
  clearAll() {
    this.chapalaMLState.dispatch(new ClearSearch())
  }
  showMap(val: boolean) {
    console.log("show map " + val);
    if (!val) {
      this.showAll()
    }
  }
}
