<div *ngIf="opened" class="out open" [ngClass]="{selected:selected==index}" kzLib (click)="setSel()">
  <img [src]="src_img" class="src_img">
  <div class="text" [ngClass]="{gray:bDisabled}">{{text}}<span class="dark">{{dark_text}}</span></div>

  <img *ngIf="bDisabled" class="lock" src="assets/images/lock_gray.svg" alt="">
  <div *ngIf="debug=='lines'" class="h_lines" [style.top.px]="0"> </div>
  <div *ngIf="debug=='lines'" class="h_lines" [style.top.px]="12"> </div>

  <div *ngIf="debug=='lines'" class="h_lines v_lines_l" [style.top.px]="22"> </div>
  <div *ngIf="debug=='lines'" class="h_lines v_lines_l" [style.top.px]="42"> </div>

  <div *ngIf="debug=='lines'" class="h_lines" [style.top.px]="52"> </div>
  <div *ngIf="debug=='lines'" class="h_lines" [style.top.px]="64"> </div>
  <div *ngIf=" bDisabled" class="err">
    Under construction
  </div>

</div>

<div *ngIf="!opened" class="out" kzLib (click)="setSel()">
  <div class="sel" [ngClass]="{selected:selected==index}">
    <img [src]="src_img" class="src_img left_22">
    <div class="text"> {{text}}<span class="dark">{{dark_text}}</span> </div>
  </div>

  <div *ngIf="debug=='lines'" class="h_lines h_f" [style.top.px]="0"> </div>
  <div *ngIf="debug=='lines'" class="h_lines" [style.top.px]="12"> </div>

  <div *ngIf="debug=='lines'" class="h_lines v_lines_l" [style.top.px]="22"> </div>
  <div *ngIf="debug=='lines'" class="h_lines v_lines_l" [style.top.px]="42"> </div>

  <div *ngIf="debug=='lines'" class="h_lines" [style.top.px]="52"> </div>
  <div *ngIf="debug=='lines'" class="h_lines h_f" [style.top.px]="64"> </div>
</div>
