import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { NotifyError, NotifyUser, ShowCC } from 'src/app/pages/pages.actions';
import { myEmail } from 'src/app/pages/pages.selectors';
import { validateEmail } from 'src/app/utils';
import { BillingComponent } from '../billing.component';


@Component({
  selector: 'credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['../billing.component.scss', './credit-card.component.scss']
})
export class CreditCardComponent extends BillingComponent {
  bInvalidEmail: boolean = false;

  btn_code_ready: boolean = false

  plan_prompt: string = ""
  bCCNumOk: boolean = true;

  exp_propmt: string = "Free trial ends on"
  years: any[] = []
  amount: number = 0
  bShowCaptcha: boolean = false
  ngAfterViewInit() {

    console.log("CC")

    this.subscriptions.push(this.pagesState.select(myEmail).subscribe((email: string | undefined) => {
      if (email) {
        setTimeout(() => this.email = email)
      }
    }))
    this.setCost()
    // this.setCpatcha("add cc")
    // this.getInvoices()

  }



  changedEmail($event: any) {
    this.bInvalidEmail = !validateEmail(this.email)
    if (!this.bInvalidEmail) {
      this.changed()
    }
    console.log("email " + $event)
  }
  async processIt() {
    this.bShowCaptcha = true
  }
  async gotCapToken(cap_token: string) {
    this.bShowCaptcha = false
    try {
      if (this.in_code) {
        try {
          await this.pay_service.sendPromoCode(this.in_code, true)
          this.pagesState.dispatch(new NotifyUser("Code Reedemed"))

        } catch (e) {
          console.error(e)
          return
        }
      }
      if (this.bReady) {

        if (this.selected_plan) {
          if (this.selected_plan.num_users == this.num_users
            && this.selected_plan.bYearly == this.bYearly
            && this.cc_number.length == 0
          ) {
            this.updateBillingInfo()
            return
          }
        }
        let info: any = {
          bill_info: {
            email: this.email,
            cia_name: this.cia_name,
            cc_address: this.cc_address,
            cc_zip: this.cc_zip,
            cc_state: this.cc_state,
          }, plan: {
            num_users: this.num_users,
            bYearly: this.bYearly,
            amount: this.amount,
            text: "Your keyzii Plan",
          },
        }
        if (this.cards.length == 0) {
          if (this.billed_today > 0) {
            info.pay = {
              index: 0,
              amount: this.billed_today
            }
          }
          console.log(JSON.stringify(info))
          if (this.cc_number.length == 0 && this.billed_today == 0) {
            if (this.num_users != this.selected_plan.num_users) {
              this.pagesState.dispatch(new NotifyError("Please Enter a CC Number"))
            } else {
              this.closeIt()
            }
            return
          }
          try {
            this.cc_page = "process"
            if (cap_token) {
              let rv: any = await this.pay_service.setCC(cap_token, {
                cc_exp: this.cc_exp,
                cc_number: this.cc_number,
                amount: this.amount,
                first_name: this.first_name,
                last_name: this.last_name,
                cc_cvv: this.cc_cvv,
                cc_address: this.cc_address,
                cc_zip: this.cc_zip,
                cc_state: this.cc_state,
              }, info)
              this.cc_page = rv.page
              this.cc_error = rv.error
            }
          } catch (e: any) {
            this.cc_page = e.page
            this.cc_error = e.error
          }
        } else {
          this.cc_page = "process"
          if (this.billed_today > 0) {
            info.pay = {
              index: this.converge.select,
              amount: this.billed_today
            }
          }
          try {
            await this.pay_service.setInfo(info)
            // this.pagesState.dispatch(new NotifyUser("Payment processed"))
            this.cc_error = undefined
            this.cc_page = "done"
            if (this.billed_today) {
              this.done_prompt = "Payment Successful"
            } else {
              if (this.cc_number) {
                this.done_prompt = "Credit card successfully sent to processor"
              } else {
                this.done_prompt = "keyzii plan updated successfully!"
              }
            }
          } catch (e) {
            // me.pagesState.dispatch(new NotifyError("Save Error " + e))
            this.cc_error = "Save Error " + e
            this.cc_page = "done"

          }
        }
      }
    } catch (e: any) {
      this.pagesState.dispatch(new NotifyError(e))
    }
  }


  ccNchanged() {
    let cc_number = this.cc_number.replace(/(?![0-9])./gmi, '')
    if (cc_number != this.cc_number) {
      setTimeout(() => this.cc_number = cc_number)
    }
    this.changed()
  }
  changed() {
    this.bChanged = true
    this.setCost()
    this.cc_logo = this.getCCLogo(this.cc_number)
    this.bCCNumOk = this.validateCC()

    if (this.cards.length == 0) {
      if (this.cia_name.length > 0) {
        if (this.cia_name == "x") { //Test only
          this.setDevData()
        }


        this.bReady = this.changedCCData()
      } else {
        this.bReady = false
      }
    }
  }
  // setMinUsers() {
  //   let num_users = 0
  //   if (this.users) {
  //     let uk = Object.keys(this.users)
  //     num_users += uk.length
  //   }
  //   if (this.reps) {
  //     let rk = Object.keys(this.reps)
  //     num_users += rk.length
  //   }
  //
  //   if (parseInt(this.num_users) < num_users) {
  //     setTimeout(() => this.num_users = num_users.toString())
  //     this.num_assets = num_users * 100
  //   }
  //   this.setCost()
  // }
  contact() {
    window.open("https://www.keyzii.com/contact")
  }

  changedCode() {
    let bReady = false
    if (this.in_code.length == 19) {
      let parts = this.in_code.split('-')
      if (parts.length == 4) {
        bReady = true
      }
    }
    setTimeout(() => this.btn_code_ready = bReady)
  }
  async sendCode() {
    try {
      let rv: any = await this.pay_service.sendPromoCode(this.in_code, true)
      this.gotInvoices(rv.invoices)
      this.gotCode(rv.code)
      // /code/,  codesCtr.redeemCode, clientsCtrl.setPromoCode client.code
      //<-- usersCtr.returnAfterVerify rv.code pageModel code
      //allows the number of users to be plan plus code users
      this.pagesState.dispatch(new NotifyUser("Code Applied"))
    } catch (e) {
      console.error(e)
    }
  }
  delete() {
    this.pay_service.removeAccount()
  }
  selState(i: number) {
    this.bShowStates = false
    this.cc_state = this.us_states[i].name
  }
  doneError() {
    if (this.cc_error) {
      this.cc_page = 'billing'
    } else {
      this.pagesState.dispatch(new ShowCC(undefined))
    }
  }
  async  updateBillingInfo() {
    try {
      await this.pay_service.setInfo({
        bill_info: {
          email: this.email,
          cia_name: this.cia_name,
          cc_address: this.cc_address,
          cc_zip: this.cc_zip,
          cc_state: this.cc_state,
        }
      })
      this.pagesState.dispatch(new NotifyUser("Bill information updated"))
      this.pagesState.dispatch(new ShowCC(""))

    } catch (e) {
      this.pagesState.dispatch(new NotifyError("Error updating bill information"))
    }
  }
}
