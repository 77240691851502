<div class="out" [ngClass]="{out_s:small}">
  <asset *ngIf='asset && asset.type != "youtube"' [asset]="asset" class="asset" [ngClass]="{asset_s:small}" [preview]="true"></asset>
  <app-youtube class="asset" *ngIf='asset && asset.type == "youtube"' [pauseOnStart]="true" [asset]="asset" [ngClass]="{asset_s:small}">
  </app-youtube>

  <div class="btn clear"  kzLib (click)="clear()">
    <div class="abs_center">
      Clear
    </div>
  </div>
  <div class="btn share"  kzLib (click)="share()">
    <div class="abs_center">
      Share
    </div>
  </div>
  <!-- <div *ngIf="!call_id" class="btn share"  kzLib (click)="clear()">
    <div class="abs_center">
      Close
    </div>
  </div> -->
</div>
