
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { selectToolOptions } from 'src/app/canvas/canvas.selectors';

import { PagesState } from 'src/app/pages/pages.model';
import { gotQueue } from 'src/app/pages/pages.selectors';

@Component({
  selector: 'cal-icon',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarIconComponent {

  bShowCallsWnd: boolean = false
  subscriptions: any[] = []

  constructor(
    private pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("cal-icon")
    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      setTimeout(() => this.bShowCallsWnd = opt.name == 'appts-wnd')
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  openApptsWnd($event: any) {
    var viewportOffset = $event.srcElement.getBoundingClientRect();

    if (this.bShowCallsWnd) {
      this.canvasStore.dispatch(new SelectToolOpts(""))
    } else {
      this.canvasStore.dispatch(new SelectToolOpts("appts-wnd", { x: viewportOffset.left }))
    }
  }
}
