import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { WSSState } from 'src/app/wss/wss.model';
import { myId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { PagesState } from '../../pages.model';
import { callWaiting, gotQueue } from '../../pages.selectors';
import { fomatTime } from 'src/app/utils';

@Component({
  selector: 'queue-wnd',
  templateUrl: './queue-wnd.component.html',
  styleUrls: ['./queue-wnd.component.scss']
})

export class QueueWndComponent {
  calls: any[] = []
  waiting: string[] = []
  interval: any
  bShowHangUp: boolean = false;
  my_id: string = "";
  show_index: number = -1;
  bShowOpts: boolean = false;
  y_pos: number = 0;
  call_waiting: any | undefined
  // bShowedCallWaiting: boolean = false
  subscriptions: any[] = []

  constructor(
    public pagesState: Store<PagesState>,
    public wss_service: WSSService,
    public wssState: Store<WSSState>,
  ) {
    this.subscriptions.push(this.wssState.select(myId).subscribe((my_id: any) => {
      this.my_id = my_id
    }))
    this.subscriptions.push(this.pagesState.select(gotQueue).subscribe((queue: any[]) => {
      this.calls = [];
      queue.forEach((c: any) => {
        if (c.state == "calling") {
          this.calls.push(c)
        }
      })
    }))
    console.log("queue wnd")

    this.subscriptions.push(this.pagesState.select(callWaiting).subscribe((call_waiting: any) => {
      setTimeout(() => {
        this.call_waiting = call_waiting
      })
    }))
  }

  showOpts(i: number) {
    console.log("show opt " + i)
    this.show_index = i
    this.y_pos = 53 + 58 * i
    this.bShowOpts = i >= 0;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    clearInterval(this.interval)
  }
  /*
  setDuration() {
    let now = new Date().getTime()
    let calls: any[] = []//Object.assign([], this.calls)
    let waiting: string[] = []
    for (let i = 0; i < this.calls.length; i++) {
      let call: any = Object.assign({}, this.calls[i])
      let call_waiting: string = ""
      if (call.state == "calling") {
        if (call.start_calling_at) {
          call_waiting = fomatTime(now - call.start_calling_at)
        } else {
          call_waiting = "error"
        }
        waiting.push(call_waiting)
      }
      // else {
      //   waiting = "conected"
      // }
      if (call.rep_id) {
        if (call.rep_id == this.my_id) {
          this.bShowHangUp = true
        }
      }

    }
    this.waiting = waiting
  }*/
}
