import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotifyUser } from '../pages.actions';
import { PagesState } from '../pages.model';
//In the top menu bar
@Component({
  selector: 'k-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [

    trigger('showHide', [
      state('hide', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('show => hide', [
        animate('2.0s')
      ]),
      transition('hide => show', [
        animate('.50s')
      ]),
    ]),



  ],
})
export class NotificationComponent {
  _text: string = "Notify"
  icon: string = ""
  bShowUserNotification: boolean = false;
  bShow: boolean = false
  @Input() set notify(a: any | undefined) {
    if (a) {
      this._text = a.text;
      this.icon = "assets/images/" + a.icon + ".svg"
      this.bShowUserNotification = true;
      if (a.show) {
        this.bShow = true
        return
      }
      setTimeout(() => {
        this.bShowUserNotification = false;
        setTimeout(() => {
          if (!this.bShow) {
            this.pagesState.dispatch(new NotifyUser(undefined))
          }
        }, 2000)
      }, 3000)
    }
  }
  remove() {
    this.bShowUserNotification = false;
    this.bShow = false
    setTimeout(() => {
      this.pagesState.dispatch(new NotifyUser(undefined))
    }, 1000)
  }
  constructor(
    private pagesState: Store<PagesState>,

  ) {
    console.log("norification")
  }

}
