import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { lastMsg } from 'src/app/wss/wss.selectors';
import { showAPI } from '../../pages.selectors';
import { SelAssetComponent } from '../sel-asset/sel-asset.component';

@Component({
  selector: 'asset',
  templateUrl: './asset.component.html',
  styleUrls: ['../sel-asset/sel-asset.component.scss', './asset.component.scss']
})
export class AssetComponent extends SelAssetComponent {
  @ViewChild('out') outDiv: ElementRef | undefined

  bShowAPI: boolean = false
  @Input() preview: boolean = false
  last_p2p_size_msg: any
  p2p_left: number = 0
  p2p_top: number = 0

  p2p_width: number = 0
  p2p_height: number = 0
  initSubClass() {
    console.log("asset component")
    this.subscriptions.push(this.pagesState.select(showAPI).subscribe((show: boolean) => {
      this.bShowAPI = show

    }))
  }
  ngAfterViewInit() {
    this.subscriptions.push(this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg.p2p_size) {
        this.last_p2p_size_msg = msg
        this.onResize()
      }
    }))
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) { //aspect ratio is 1280, 720
    if (this._asset.type == "on_board_p2p" && this.last_p2p_size_msg) {
      if (this.last_p2p_size_msg) {
        this.p2p_width = this.last_p2p_size_msg.p2p_size.width
        this.p2p_height = this.last_p2p_size_msg.p2p_size.height
        if (this.outDiv) {
          let left =
            (this.outDiv.nativeElement.clientWidth - this.p2p_width) / 2
          let top = (this.outDiv.nativeElement.clientHeight - this.p2p_height) / 2
          if (left < 0) {
            this.outDiv.nativeElement.style.overflowX = 'scroll';
            left = 0
          } else {
            this.outDiv.nativeElement.style.overflowX = 'none';
          }
          if (top < 0) {
            this.outDiv.nativeElement.style.overflowY = 'scroll';
            top = 0
          } else {
            this.outDiv.nativeElement.style.overflowY = 'none';
          }
          this.outDiv.nativeElement.style.position = "absolute"
          this.p2p_left = left
          this.p2p_top = top
        }
      }

    }
  }
}
