import { Component } from '@angular/core';
import { CanvasService } from '../../canvas.service';
import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';
import { showAsset } from 'src/app/pages/pages.selectors';
import { contractExecuted, contractSigned, lastSignedContract, pdfSecOpts, progress, readyToBlockChain, selSignerKey } from '../../canvas.selectors';
import { DidStep, GoToStep, SelectToolOpts } from '../../canvas.actions';

@Component({
  selector: 'block-chain',
  templateUrl: './block-chain.component.html',
  styleUrls: ['./block-chain.component.scss']
})
export class BlockChainComponent {
  progress: number = 50
  next_text: string = "Block Chain"
  missing_steps: number = 3
  contract: any
  chain: any
  ready_to_block_chain: boolean = false
  blockchain: string = "";
  signer_key: string = ""
  bShowSpinner: boolean = false
  bShowCancel: boolean = true
  subscriptions: any[] = []
  constructor(
    public canvas_service: CanvasService,
    public canvasStore: Store<CanvasState>,
    private pagesState: Store<PagesState>,
  ) {
    console.log("block chain")
    this.subscriptions.push(this.canvasStore.select(pdfSecOpts).subscribe((sec_opts: any) => {
      if (sec_opts) {
        if (sec_opts.blockchain) {
          this.blockchain = sec_opts.blockchain
        }
      }
    }))
    this.subscriptions.push(this.canvasStore.select(readyToBlockChain).subscribe((ready: boolean) => {
      this.ready_to_block_chain = ready
    }))

    this.subscriptions.push(this.canvasStore.select(progress).subscribe((progress: any) => {
      this.progress = progress.progress
      this.missing_steps = progress.missing_steps
      if (this.progress == 100) {

      }
    }))

    this.subscriptions.push(this.canvasStore.select(selSignerKey).subscribe((signer_key: string | undefined) => {
      if (signer_key) {
        this.signer_key = signer_key
      }
    }))
    this.subscriptions.push(this.canvasStore.select(lastSignedContract).subscribe((contract: any) => {
      if (contract) {
        this.contract = contract

        let signer = this.contract.signers[this.signer_key]
        if (!signer) {
          signer = this.contract.signers[this.contract.signer_key]
        }
        if (signer) {
          if (signer.chain) {
            this.chain = signer.chain
            this.bShowSpinner = false;
            this.next_text = "Done"
            this.bShowCancel = false
          }
        }
      }
    }))

    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        this.next_text = "Block Chain"
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  cancel() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
    this.canvasStore.dispatch(new GoToStep({}))
  }
  dbounce: number = 0
  done() {
    let now = new Date().getTime()
    if (!this.bShowSpinner) {
      if (this.next_text != "Done") {
        // if (!this.ready_to_block_chain) {
        //   return
        // }
        console.log("blockChain " + new Date().toString())
        this.bShowSpinner = true;
        this.canvas_service.blockChain()
        this.dbounce = now
      } else {
        let dif = now - this.dbounce
        if (dif > 500) {
          this.canvasStore.dispatch(new DidStep("blockchain"))
        }
      }
    }
  }
}
