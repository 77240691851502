import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowAsset } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { WSSState } from 'src/app/wss/wss.model';
import { callId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';

@Component({
  selector: 'asset-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent {
  call_id: string | undefined
  constructor(
    private pagesState: Store<PagesState>,
    public wss_service: WSSService,
    private wssState: Store<WSSState>,
  ) {
    console.log("preview")
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
    })
  }
  @Input() small: boolean = false
  @Input() asset: any
  @Output() close: EventEmitter<void> = new EventEmitter();

  clear() {
    this.close.emit()
  }
  share() {
    if (this.asset.type != "iframe") {
      this.pagesState.dispatch(new ShowAsset(this.asset))
    }
    this.wss_service.sendMessageToOtherMembers({ show_asset: this.asset })
    this.close.emit()
  }
}
