<div class="out">
  <div class="line top"  kzLib (click)="addAsset.emit()">
    <img class=" img" src="assets/images/assets.svg" alt="">
    Assets
  </div>
  <div class="line"  kzLib (click)="addFolder.emit()">
    <img class="img" src="assets/images/folder.svg" alt="">
    Folder
  </div>
</div>
