import { Component, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { selectToolOptions } from 'src/app/canvas/canvas.selectors';

import { PagesState } from 'src/app/pages/pages.model';
import { gotQueue } from 'src/app/pages/pages.selectors';

@Component({
  selector: 'calls-waiting',
  templateUrl: './calls-waiting.component.html',
  styleUrls: ['./calls-waiting.component.scss']
})
export class CallsWaitingComponent {
  num_waiting: number = 0
  bShowCallsWnd: boolean = false
  subscriptions: any[] = []

  constructor(
    private el: ElementRef,
    private pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
  ) {

    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      if (opt.name == 'open-queue-wnd') {
        const rect = this.el.nativeElement.getBoundingClientRect();
        this.canvasStore.dispatch(new SelectToolOpts("queue-wnd", { x: rect.left }))

      } else {
        setTimeout(() => this.bShowCallsWnd = opt.name == 'queue-wnd')
      }
    }))

    this.subscriptions.push(this.pagesState.select(gotQueue).subscribe((queue: any[]) => {
      if (queue) {
        this.num_waiting = 0
        for (let i = 0; i < queue.length; i++) {
          let caller = queue[i]
          if (caller.state == "calling") {
            this.num_waiting++
          }
        }
        console.log("phone " + this.num_waiting)
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  openCallWnd($event: any) {
    var viewportOffset = $event.srcElement.getBoundingClientRect();

    if (this.bShowCallsWnd) {
      this.canvasStore.dispatch(new SelectToolOpts(""))
    } else {
      this.canvasStore.dispatch(new SelectToolOpts("queue-wnd", { x: viewportOffset.left }))
    }
  }
}
