<div class="out" #outComponent>
  <div  kzLib (click)="showOptions()" class="title">{{title}}
    <img class="exp" [ngClass]="{colapse:bShowOptions}" src="assets/images/expand.svg">
  </div>
  <div *ngIf="bShowOptions" #optComponent class="options" [ngClass]="{opts_r:bShowRight}" [style.border]="border">
    <div>
      <div>Beds</div>
      <mls-sel1to5 (changed)="setBeds($event)" class="top_box"></mls-sel1to5>
    </div>
    <div>
      <div>Baths</div>
      <mls-sel1to5 (changed)="setBaths($event)" class="top_box"></mls-sel1to5>
    </div>
  </div>
</div>
