import { Component } from '@angular/core';

@Component({
  selector: 'no-chrome',
  templateUrl: './no-chrome.component.html',
  styleUrls: ['./no-chrome.component.scss']
})
export class NoChromeComponent {
  openChrome() {

  }
  download() {
    window.open("https://www.google.com/chrome/")
  }
}
