import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';
import { playRec } from 'src/app/pages/pages.selectors';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'play-back',
  templateUrl: './play-back.component.html',
  styleUrls: ['./play-back.component.scss']
})
export class PlayBackComponent {
  rep_stream: MediaStream | undefined
  caller_stream: MediaStream | undefined
  play_rec: any
  constructor(
    private pagesState: Store<PagesState>,
    private httpClient: HttpClient,
  ) {
    console.log("play-back")


    this.pagesState.select(playRec).subscribe((play_rec: any) => {
      if (play_rec) {
        this.play_rec = play_rec
        this.setUpStreams()
      }

    })
  }
  setUpStreams() {



  }
}
