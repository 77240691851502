<div class="out">
  <div class="content">
    <div class="row">

      <div class="section fl">
        <div class="tx font_title">
          Company Call Link
        </div>
        <div class="btn" #company kzLib (click)="customerLink()"></div>

        <!-- <k-button class=" btn abs_center" icon="copy.svg" text="Company Link"  kzLib (click)="customerLink()">
        </k-button> -->
        <div class="info font_text">
          Embed this link on your website to direct callers to agents company-wide.
        </div>
      </div>
      <div class="section fl">
        <div class="font_title">
          Personal Call Link
        </div>
        <div class="btn" #personal kzLib (click)="personalLink()"></div>

        <!-- <k-button class=" btn abs_center" icon="copy.svg" text="Personal Link"  kzLib (click)="personalLink()">
        </k-button> -->
        <div class="info font_text">
          Embed this link on your website to direct callers to your personal video call page. </div>
      </div>
    </div>
    <div class="row">

      <div class="section">
        <div class="tx font_title">
          Company QR Code
        </div>
        <img class="qr" [src]="cia_qr_code">
        <div class="info font_text">
          Company Call link as a QR code.
          Right click to save or copy the image. </div>
      </div>
      <div class="section">
        <div class="tx font_title">
          Personal QRCode
        </div>
        <img class="qr" [src]="rep_qr_code">
        <div class="info font_text">
          Personal Call link as a QR code.
          Right click to save or copy the image.</div>
      </div>
    </div>
    <div class="row last_row">

      <div class="section">
        <div class="tx  font_title">
          <span>Company Dynamic Buttons</span>
        </div>
        <div class="btn" #embed kzLib (click)="embedCia()"></div>
        <div class="info font_text">
          <span>Add this dynamic button to your website to let your callers know when agents are ONLINE and OFFLINE.</span>
        </div>
      </div>

      <div class="section">
        <div class="tx  font_title">
          <span>Personal Dynamic Buttons</span>
        </div>
        <div class="btn per_btn" #embed_per kzLib (click)="embedPersonal()"></div>

        <div class="info font_text">
          <span> Add this dynamic button to your website to let your callers know when you are ONLINE and OFFLINE.</span>
        </div>
      </div>

    </div>
  </div>
</div>
