/*
(C)2020 Sergio A. Fernandez, Ph.D.



*/


export interface KVideoState {
  bHasVideoCamera: boolean,
  bAudioOn: boolean,
  bVideoOn: boolean,
  bShareOn: boolean,
  streams: any,
  remote_tracks: any, //{from:tracks:{video:v_id, audio:a_id, shared:shared_id}}

  check_video_seq: number,
  check_video: any | undefined,
  showing_remote_share: boolean,
  media_state: any,
  selected_vdev_id: string | undefined;
  selected_adev_id: string | undefined;
  selected_a_out_dev_id: string | undefined;

}

export const initialkVideoState: KVideoState = {
  bHasVideoCamera: false,
  bAudioOn: false,
  bVideoOn: false,
  bShareOn: false,
  streams: {},
  remote_tracks: {},

  check_video_seq: 0,
  check_video: undefined,
  showing_remote_share: false,
  media_state: {},
  selected_vdev_id: undefined,
  selected_adev_id: undefined,
  selected_a_out_dev_id: undefined,

};
