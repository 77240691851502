import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { ContactsService } from '../contacts.service';

@Component({
  selector: 'del-lead',
  templateUrl: './del-lead.component.html',
  styleUrls: ['./del-lead.component.scss']
})
export class DelLeadComponent {
  constructor(
    public canvasStore: Store<CanvasState>,
    private contacts_service: ContactsService,
  ) {
    console.log("del lead")
  }

  delContact() {
    this.contacts_service.deleteContact()
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
  cancel() {
    this.contacts_service.cancelDeleteContact()
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
}
