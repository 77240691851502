<div class="ccards">
  <div class="" *ngFor="let card of cards;let i = index">


    <div class="cc_box">
      <div class="check ccheck" [ngClass]="{selected:i==converge.select}"  kzLib (click)="selCard(i)"></div>
      <img class="cc ccc" [src]="getCCLogo(card.ssl_card_number)">

      <div class="cctx ccctx">
        {{card.ssl_card_short_description}} {{card.ssl_card_number}}
      </div>
      <div class="ex_cc cex_cc">
        {{formatExpDate(card.ssl_exp_date)}}
      </div>
    </div>
    <div class="g_li">

    </div>
  </div>
</div>
