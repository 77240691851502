import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CanvasService } from 'src/app/canvas/canvas.service';
import { WSSService } from 'src/app/wss/wss.service';
import { NotifyUser, ShowAsset } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { gotContracts, hostUrl } from '../../pages.selectors';
import { PagesService } from '../../pages.service';
import { Clipboard } from '@angular/cdk/clipboard'
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';

@Component({
  selector: 'contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent {
  // bShowDelUserWarning: boolean = false
  search: string = ""
  page: number = 1
  contracts: any[] = []
  num_contracts: number = 0;
  sel_ids: any = {}

  details_index: number = -1
  expand_index: number = -1
  options_y: number = 53;
  options_x: number = 400;
  options: any[] = []
  sel_contract: any

  chain_y: number = 53;
  chain_details_index: number = -1
  chain_deltails_opt: any


  // contracts_keys: string[] = []
  host_url: string | undefined
  num_rows: number = 10;
  num_pages: number = 1;
  total: number = 0;
  @ViewChild('outComponent') outComponent: ElementRef | undefined
  @ViewChild('content') content: ElementRef | undefined
  subscriptions: any[] = []

  constructor(
    private pagesState: Store<PagesState>,
    private page_service: PagesService,
    private canvas_service: CanvasService,
    public wss_service: WSSService,
    private clipboard: Clipboard,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("contracts")
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
    this.subscriptions.push(this.pagesState.select(gotContracts).subscribe((contracts: any) => {
      if (contracts) {
        this.total = contracts.num_contracts
        this.contracts = Object.assign([], contracts.contracts)
        if (this.contracts) {

          this.contracts.sort((a: any, b: any) => {
            let d_a = new Date(a.created_at).getTime()
            let d_b = new Date(b.created_at).getTime()
            return d_b - d_a
          })
          for (let i = 0; i < this.contracts.length; i++) {
            let contract = Object.assign({}, this.contracts[i])
            contract.perc = this.getPercent(contract)
            this.contracts[i] = contract
          }
          this.num_contracts = this.contracts.length
        }


        this.num_pages = Math.ceil(contracts.num_contracts / this.num_rows)
      }
      // if (this.contracts) {
      //   this.contracts_keys = Object.keys(this.contracts).reverse();
      // }
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.setSize()
    this.page_service.getContracts(1, this.num_rows, this.search);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) { //aspect ratio is 1280, 720
    setTimeout(() => {
      this.setSize()
    }, 100)
  }
  setSize() {

    if (this.content) {
      let h = this.content.nativeElement.clientHeight
      this.num_rows = Math.floor(h / 60);
    }
  }


  isSelected(contract: any) {
    return this.sel_ids[contract._id]
  }
  select(i: number, contract: any) {
    if (!this.sel_ids[contract._id]) {
      this.sel_ids[contract._id] = true
    } else {
      delete this.sel_ids[contract._id]
    }
  }
  del_contract_id: string | undefined
  del_contract_index: number = -1
  async delete(i: number, contract: any) {
    this.canvasStore.dispatch(new SelectToolOpts("del_cont", { id: contract._id, page: this.page, page_size: this.num_rows, search: this.search }))

    // this.bShowDelUserWarning = true;
    this.del_contract_index = i
    // let keys = Object.keys(this.sel_ids)
    //
    // for (let i = 0; i < keys.length; i++) {
    this.del_contract_id = contract._id //keys[i]
  }
  // async deletContract() {
  //   try {
  //     if (this.del_contract_id) {
  //       await this.canvas_service.deleteContract(this.del_contract_id)
  //       let contracts = Object.assign([], this.contracts)
  //       contracts.splice(this.del_contract_index, 1)
  //       this.del_contract_index = -1
  //       this.del_contract_id = undefined
  //       this.bShowDelUserWarning = false;
  //       this.contracts = contracts
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  //   // }
  //
  //   // setTimeout(() => this.contracts_keys = Object.keys(this.contracts.signers).reverse());
  //   this.sel_ids = []
  // }
  show(contract: any) {
    this.pagesState.dispatch(new ShowAsset(contract))
    this.wss_service.sendMessageToOtherMembers({ show_asset: contract })
  }
  getPercent(contract: any) {
    let keys = Object.keys(contract.signers)
    let num_signatures = 0
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      let signer = contract.signers[key]
      if (signer.signed_at) {
        num_signatures++
      }
    }
    let perc = Math.floor(num_signatures / keys.length * 100)
    return perc
  }
  getStatus(contract: any) {
    return this.getPercent(contract) + "% completed"
  }
  openSignersFor(index: number, contract: any, $event: any) {
    $event.stopPropagation()
    this.sel_contract = contract
    if (this.details_index == index) {
      this.details_index = -1
    } else {
      setTimeout(() => {
        this.options_x = $event.clientX - 410
        this.options_y = $event.clientY - 115
        console.log("Y " + $event.clientY + " " + $event.screenY)
        // this.options_y = 180 + index * 56
        this.chain_y = this.options_y + 140;
        this.options = []
        let keys = Object.keys(contract.signers)
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i]
          this.options.push(contract.signers[key])
        }
        if (this.options.length > 0) {
          this.details_index = index;
        } else {
          this.details_index = -1
        }
      })
    }
  }
  openChain(details_index: number, opt: any, $event: any) {
    $event.stopPropagation()
    this.chain_deltails_opt = opt
    this.chain_details_index = details_index

  }
  async   download() {
    try {
      let key = this.chain_deltails_opt.chain.key
      let url = await this.page_service.getContractURL(key)
      window.location.assign(url.url);
    } catch (e) {
      console.error(e)
    }
  }
  closeDetails() {
    this.details_index = -1
    this.chain_details_index = -1
  }
  async downloadContract(contract: any) {
    try {
      let key = contract.key
      let url = await this.page_service.getContractURL(key)
      window.location.assign(url.url);
    } catch (e) {
      console.error(e)
    }
  }
  copyLink($event: any, contract_index: number, option_index: number) {
    $event.stopPropagation()

    // let key = this.contracts_keys[contract_index]
    if (this.contracts) {
      let contract = this.contracts[contract_index]//[key]
      let signer_keys = Object.keys(contract.signers)
      let singer_key = signer_keys[option_index]
      let opt = contract.signers[singer_key]
      if (opt) {

        //localhost"4206/?rep_id=631ccb8a103e78d5521074e0&contract_id=652febbcff09b418c24796ca&email=sergio@asci.us
        let link = "https://" + this.host_url + "?rep_id=" + contract.created_by + "&contract_id=" + contract._id + "&email=" + opt.email
        console.log("link " + link)
        this.clipboard.copy(link);
        this.pagesState.dispatch(new NotifyUser("Link copied"))
      }
    }
  }
  selPage(page: number) {
    this.page = page
    this.page_service.getContracts(page, this.num_rows, this.search);
  }
  changedSearch() { //filter by name
    console.log("filter by name")
    this.page_service.getContracts(this.page, this.num_rows, this.search);
  }
  dontCloseDetails($event: any) {
    $event.stopPropagation()
  }
  expandDetails(i: number) {
    if (this.expand_index == i) {
      this.expand_index = -1;
    } else {
      this.expand_index = i
    }

  }
}
