import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  @Output() onFileDropped: EventEmitter<any> = new EventEmitter();
  @Output() onFileOver: EventEmitter<any> = new EventEmitter();
  @Output() onFileLeave: EventEmitter<any> = new EventEmitter();

  constructor() { }
  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault()
    evt.stopPropagation()

    this.onFileOver.emit()
    // console.log("drag over")
  }
  @HostListener('dragleave', ['$event']) onDragLeave(evt: any) {
    evt.preventDefault()
    evt.stopPropagation()
    this.onFileLeave.emit()
    // console.log("drag leave")
  }
  @HostListener('drop', ['$event']) onDrop(evt: any) {
    evt.preventDefault()
    evt.stopPropagation()

    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      // console.log("Droped " + files.length + " files")
      this.onFileDropped.emit(files)
    }
    // console.log("drop  ")

    //It is one, create an asset
    //it multiple ones, create a folder and put all the content there
  }
}
