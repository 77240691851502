import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';
import { debug } from 'src/app/pages/pages.selectors';
import { callId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent {
  src_img: string | undefined
  bDisabled: boolean = false

  @Input() set image(image: string) {
    this.src_img = "assets/images/" + image
  }
  @Input() set disabled(image: string) {
    if (image) {
      this.src_img = "assets/images/" + image
      this.bDisabled = true;
    }
  }
  dark_text: string = ""
  @Input() set dark(s: string) {
    if (s) {
      this.dark_text = s
    }
  }
  @Input() text: string = ""
  @Input() index: number = 0;

  @Input() opened: boolean = false;
  @Input() selected!: number;
  @Output() selectedChange = new EventEmitter<number>();
  debug: string = "lines"
  subscriptions: any[] = []
  call_id: string | undefined
  constructor(
    private pagesState: Store<PagesState>,
    private wssState: Store<WSSService>,

  ) {
    console.log("option")
    this.subscriptions.push(this.pagesState.select(debug).subscribe((debug: string) => {
      this.debug = debug
    }))
    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  setSel() {
    if (!this.bDisabled) {
      if (this.index == 0 || !this.call_id)
        this.selected = this.index;
      this.selectedChange.emit(this.selected)
    }
  }

}
