<div class="outx">

  <div class="li1">Recording link sent to email</div>
  <div class="btn_link b1"  kzLib (click)="copyLink()">
    <div class="">
      <img src="assets/images/link3.svg">
      Copy Link
    </div>

  </div>

  <div class="btn_link b2"  kzLib (click)="downLoadWebm()">
    Download webm
  </div>

  <div class="btn_link b3"  kzLib (click)="downLoadMp4()">
    Download mp4
  </div>
  <img class="cancel" src="assets/images/close.svg" alt="close"  kzLib (click)="cancel()">

</div>
