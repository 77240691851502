import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { SVG } from '@svgdotjs/svg.js';


@Component({
  selector: 'sign-pad1',
  templateUrl: './sign-pad1.component.html',
  styleUrls: ['./sign-pad1.component.scss']
})
export class SignPad1Component {

  @Output() svgChange: EventEmitter<any> = new EventEmitter();

  @Input() enabled: boolean = true
  @Input() set clear(s: number) {
    if (this.signaturePad) {
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }
  }
  @Input() set svg(svg: string) {
    if (this.signaturePad && !this._svg) {
      this.setInitialSVG(svg)
    }
    this._svg = svg    //Only Once
  }
  _svg: string | undefined
  @ViewChild('signature')
  public signaturePad: SignaturePadComponent | undefined

  public signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 1,
    canvasWidth: 460,
    canvasHeight: 270
  };

  constructor() {
    console.log("sign pad1")
    let w = window.innerWidth
    if (w < 600) {
      this.signaturePadOptions.canvasWidth = w - 20
      this.signaturePadOptions.canvasHeight = 220
    }
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    if (this.signaturePad) {
      this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
      if (this._svg) {
        this.setInitialSVG(this._svg);
      }
    }

  }
  setInitialSVG(ssvg: string) {
    let svg = SVG(ssvg)
    if (svg.children().length > 0) {
      let el = svg.children()[0]
      if (el.type == "image") {
        if (this.signaturePad) {
          let attr = el.attr()
          let src = attr['xlink:href']
          this.signaturePad.fromDataURL(src);
        }
      }
    }
  }
  drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    console.log('Completed drawing', event);
    let me = this
    if (this.signaturePad) {

      let canvas = this.signaturePad.getCanvas()
      let ctx = canvas.getContext('2d');
      if (ctx) {
        let x_arr: number[] = []
        let y_arr: number[] = []
        let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let x, y, index;
        let w = canvas.width;
        let h = canvas.height;

        for (y = 0; y < h; y++) {
          for (x = 0; x < w; x++) {
            index = (y * w + x) * 4;
            if (imageData.data[index + 3] > 0) {
              x_arr.push(x);
              y_arr.push(y);
            }
          }
        }
        x_arr.sort(function(a, b) { return a - b });
        y_arr.sort(function(a, b) { return a - b });
        var n = x_arr.length - 1;

        w = 1 + x_arr[n] - x_arr[0];
        h = 1 + y_arr[n] - y_arr[0];
        var cut = ctx.getImageData(x_arr[0], y_arr[0], w, h);
        var newCanvas = document.createElement('canvas');
        var new_context = newCanvas.getContext('2d');
        if (new_context) {
          newCanvas.width = w;
          newCanvas.height = h;
          new_context.putImageData(cut, 0, 0);

          var png = newCanvas.toDataURL();

          // let png = newCanvas.toDataURL()


          console.log(png);
          let draw_tool = SVG()
          let me = this
          var image = draw_tool.image(png, function(event) {
            let ssvg: string = draw_tool.svg() //"https://cdn.img42.com/4b6f5e63ac50c95fe147052d8a4db676.jpeg"//
            let rv: any = {
              svg: ssvg,
              width: me.signaturePadOptions.canvasWidth,
              height: me.signaturePadOptions.canvasHeight,
              top: 0,
              left: 0,
            }
            me.svgChange.emit(rv)
          })
        }
      }
    }

  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('Start drawing', event);
  }
}
