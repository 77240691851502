<div class="out">
  <div class="round"  kzLib (click)="openCallWnd($event)" [ngClass]="{opened:bShowCallsWnd}">
    <img class="abs_center" src="assets/images/call_management.svg" alt="">
  </div>
  <div *ngIf="num_waiting" class="num_c">
    <div class="abs_center">
      {{num_waiting}}
    </div>
  </div>
</div>
