/*
iframe-canvas is used to draw over the share screen in the other side, teh caller
opens an iframe, shares its window and the rep will crop the iframe so it shows in
the rep right side

If the iframe on the right side is using kzLib, it will also be possible to
click on the buttons on the right side

we can ignore all mouse message from keyzii lib since they are used by video-pen
when both sides (Caller and Rep) are loading the same url with kzlib in iframes

*/
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatState } from 'src/app/chat/chat.model';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { checkVideo, mediaState, streams } from 'src/app/k-video/k-video.selectors';
import { PagesState } from 'src/app/pages/pages.model';
import { ToolService } from 'src/app/tools/tool.service';
import { WSSState } from 'src/app/wss/wss.model';
import { WSSService } from 'src/app/wss/wss.service';

import { lastkzApiMsg, lastMsg, lastOnBoard } from 'src/app/wss/wss.selectors'
import { CanvasService } from '../canvas.service';
import { MetaPageService } from '../meta-page.service';
import { CanvasComponent } from '../canvas/canvas.component';
import { kPencil } from '../draw/pencil';
import { SVG, extend as SVGextend, Element as SVGElement, G } from '@svgdotjs/svg.js'
import { myName } from 'src/app/pages/pages.selectors';
import { SetShare } from 'src/app/k-video/k-video.actions';
import { KVideoService } from 'src/app/k-video/k-video.service';


@Component({
  selector: 'iframe-canvas',
  templateUrl: './iframe-canvas.component.html',
  styleUrls: ['./iframe-canvas.component.scss']
})

export class IframeCanvasComponent extends CanvasComponent {

  @ViewChild('outComponent') outComponent: ElementRef | undefined
  @ViewChild('divContainer') container: ElementRef | undefined
  @ViewChild('videoElement') video: ElementRef | undefined;


  @ViewChild('iframeElement') iframeElement: ElementRef | undefined;

  container_height: number = 500; //This is where we show the incoming vide
  container_width: number = 500;
  container_top: number = 0;
  container_left: number = 0;

  canvas_height: number = 500; //This is the canvas where we draw
  canvas_width: number = 500;
  canvas_top: number = 0;
  canvas_left: number = 0;

  viewport_height: number = 500; //We need to tell the other size the dimensions of the iframe is
  viewport_width: number = 500;
  viewport_top: number = 0;
  viewport_left: number = 0;

  bSmall: boolean = false
  streams: any
  share_stream: MediaStream | undefined
  bCanvasReady: boolean = false
  local_draw_pencil!: kPencil
  remote_draw_pencil!: kPencil
  last_on_board: any
  bShowWall: boolean = false

  wall_l: number = 0
  wall_t: number = 0
  wall_w: number = 0
  wall_h: number = 0

  twall_l: number = 0
  twall_t: number = 0
  twall_w: number = 0
  twall_h: number = 0


  // subscriptions: any[] = []
  // my_canvas: any
  // draw_tool: any
  // viewBoxWidth: number = 0
  // viewBoxHeight: number = 0

  bRemoteIsSharing: boolean = true
  bSendToRemote: boolean = true
  bDrawLocal: boolean = false
  sanitazed_url: any
  @Input() set src(s: any) {
    this.sanitazed_url = s
    this.kVideoState.dispatch(new SetShare(true))
    this.k_video_service.shareScreen(true)
  }

  constructor(
    public canvasStore: Store<CanvasState>,
    public wssState: Store<WSSState>,
    public wss_service: WSSService,
    public canvas_service: CanvasService,
    public pagesState: Store<PagesState>,
    public chatState: Store<ChatState>,
    public tools_service: ToolService,
    public meta_server: MetaPageService,
    public kVideoState: Store<KVideoState>,
    public k_video_service: KVideoService, //Make sure the service is ready

  ) {
    super(canvasStore, wssState, wss_service, canvas_service, pagesState, chatState, tools_service, meta_server);
    console.log("video-canvas")
  }
  initCanvas() {
    this.setMouseLbl()
  }
  keyDonw = ($event: any) => {
    // keyDonw($event: any) {

    let msg = {
      key: $event.key,
      keyCode: $event.keyCode,
      returnValue: $event.returnValue,
      shiftKey: $event.shiftKey,
    }
    this.canvas_service.sendCanvasMsg("key", "down", this.mouse_lbl, msg)

  }
  // keyPress($event: any) {
  //   let msg = {
  //     key: $event.key,
  //     keyCode: $event.keyCode,
  //     returnValue: $event.returnValue,
  //     shiftKey: $event.shiftKey,
  //   }
  //   this.canvas_service.sendCanvasMsg("key", "down", this.mouse_lbl, msg)
  // }
  ngAfterViewInit() {
    let me = this
    window.addEventListener('keydown', me.keyDonw)
    // window.addEventListener('keypress', this.keyPress)
    window.addEventListener('resize', me.onResize)

    // this.subscriptions.push(this.wssState.select(lastkzApiMsg).subscribe((msg: any | undefined) => {
    //   if (msg) {
    //     let remote_w = msg.windowWidth
    //     let remote_h = msg.windowHeight - 20
    //     this.setSharedWndSize(remote_w, remote_h)
    //   }
    // }))

    // this.subscriptions.push(this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
    //   if (this.outComponent && msg && msg.fromKzApi && msg.windowWidth) {
    //     console.log(JSON.stringify(msg))
    //     let remote_w = msg.windowWidth
    //     let remote_h = msg.windowHeight - 20
    //     this.setSharedWndSize(remote_w, remote_h)
    //   }
    // }))
    // this.subscriptions.push(this.kVideoState.select(mediaState).subscribe((media_state: any) => {
    //   //Calculate the are from width and height
    // }))

    this.subscriptions.push(this.kVideoState.select(checkVideo).subscribe((check: any | undefined) => {
      if (check) {
        if (check.role == "local_share" || check.role == "remote_share" && this.share_stream) {
          delete this.share_stream
        }
      }
    }))
    this.subscriptions.push(this.kVideoState.select(streams).subscribe(async (streams: any) => {
      // console.log("k-video  stream for " + JSON.stringify(Object.keys(streams)))
      if (streams["remote_share"]) {
        if (this.container_left == 0) {
          try {
            await this.setOnBoardDims()
          } catch (e) {
            console.error("streams fail onBoardDims ")
          }
        }
        console.log("--> shared done")
        this.canvas_service.sendCanvasMsg("shared", "done", me.mouse_lbl, {

        })


      }

      let share_stream
      if (streams["local_share"]) {
        setTimeout(() => this.bRemoteIsSharing = false)
        share_stream = streams["local_share"]
        if (!this.bCanvasReady && this.container && this.iframeElement) {
          setTimeout(() => {//Let ir resize
            if (this.container && this.iframeElement) {
              const rect = this.iframeElement.nativeElement.getBoundingClientRect();
              this.addCanvasToDiv()//true, this.container.nativeElement, undefined, 1000, 750)
              this.bCanvasReady = true
              this.sizeChanged()
            }
          }, 10)
        }
      } else if (streams["remote_share"]) {
        if (!this.share_stream) {
          let vts = streams["remote_share"].getVideoTracks()
          if (vts.length > 0) {

            this.share_stream = share_stream //This will remove the share element
            let interval = setInterval(() => {
              this.bRemoteIsSharing = true
              if (this.video) {
                let newStream = new MediaStream()
                let videoTrack = vts[0]
                newStream.addTrack(videoTrack)
                this.video.nativeElement.srcObject = newStream
                this.video.nativeElement.volume = 0
                // Get the settings of the video track (which contains the dimensions)

                const settings = videoTrack.getSettings();
                if (settings.width) {
                  clearInterval(interval)
                  // this.setSharedWndSize()
                }
              }
            }, 100)
          }
        }
      }
    }))

    /*
    this.subscriptions.push(this.kVideoState.select(streams).subscribe((streams: any) => {
      // console.log("k-video  stream for " + JSON.stringify(Object.keys(streams)))
      let bRemoteIsSharing: boolean = true
      if (streams) {
        let share_stream
        if (streams["local_share"]) {
          share_stream = streams["local_share"]
          bRemoteIsSharing = false
        } else if (streams["remote_share"]) {
          share_stream = streams["remote_share"]
          bRemoteIsSharing = true
        }

        if (!this.share_stream && share_stream) {
          let vts = share_stream.getVideoTracks()
          if (vts.length > 0) {

            this.share_stream = share_stream //This will remove the share element
            let interval = setInterval(() => {
              this.bRemoteIsSharing = bRemoteIsSharing
              if (this.video) {
                let newStream = new MediaStream()
                newStream.addTrack(vts[0])
                this.video.nativeElement.srcObject = newStream
                this.video.nativeElement.volume = 0
                clearInterval(interval)
              }
            }, 100)
          }
        }
      }
    }))*/
    let interval: any
    let last_on_board_at: number = 0
    this.subscriptions.push(this.wssState.select(lastOnBoard).subscribe((msg: any) => {
      if (msg) {
        this.last_on_board = msg
        console.log("last_on_board " + JSON.stringify(this.last_on_board))
        last_on_board_at = new Date().getTime()
        if (!interval) {
          interval = setInterval(async () => {
            let now = new Date().getTime()
            let dif = now - last_on_board_at
            if (dif > 500) {
              console.log("debounce last_on_board " + JSON.stringify(this.last_on_board))
              try {
                await this.setOnBoardDims()
                if (!this.bShowWall) {
                  this.bShowWall = true
                }
                clearInterval(interval)
                interval = undefined
              } catch (e) {
                console.error("lastOnBoard fail onBoardDims ")
              }
            }
          }, 100)
        }
        // this.showGrid()
      }
    }))
  }
  ngOnDestroy(): void {
    super.ngOnDestroy()
    window.removeEventListener('keydown', this.keyDonw)
    // window.removeEventListener('keypress', this.keyPress)
    window.removeEventListener('resize', this.onResize)

  }
  // @HostListener('window:resize', ['$event'])
  async onResize(_event?: any) {
    if (this.last_on_board) {
      if (this.bRemoteIsSharing) {
        try { await this.setOnBoardDims() } catch (e) { console.error("resize fail onBoardDims ") }
      }
      // else {
      //   this.sizeChanged()
      // }
    }
  }
  sizeChanged() {
    if (this.iframeElement && this.bCanvasReady) {
      const rect = this.iframeElement.nativeElement.getBoundingClientRect();

      let msg = {
        on_board: rect,
        w_width: window.innerWidth,
        w_height: window.innerHeight
      }
      this.wss_service.sendMessageToOtherMembers(msg)
      this.setRemoteAspectRatio()
    }
  }
  setOnBoardDims() {
    return new Promise<void>(async (resolve, reject) => {
      if (this.last_on_board) {
        let msg = this.last_on_board
        console.log(JSON.stringify(msg))
        if (this.outComponent && this.video) {
          // setTimeout(() => {
          if (this.outComponent) {
            // this.aspect_ratio = msg.on_board.height / msg.on_board.width
            let bScaleWidh = true
            //This is s_out the view port
            let o_w = this.outComponent.nativeElement.clientWidth;
            let o_h = this.outComponent.nativeElement.clientHeight;

            //Try to map the area we care about, the share, to the view port
            let in_h = o_h
            let in_w = o_h * msg.on_board.width / msg.on_board.height

            if (in_w > o_w) { //now do it with the width
              in_w = o_w
              in_h = o_w / msg.on_board.width * msg.on_board.height
              bScaleWidh = false
            }
            //Get the scale, sould allway be the same
            let scale_w = in_w / msg.on_board.width
            let scale_h = in_h / msg.on_board.height

            //This is the window  scale it
            this.container_height = msg.w_height * scale_h
            this.container_width = msg.w_width * scale_w

            //move the container so that the box in in the out_s space
            if (bScaleWidh) {
              //This is the window  scale it
              this.container_height = msg.w_height * scale_h
              this.container_width = msg.w_width * scale_w

              this.container_left = (o_w - this.container_width - msg.on_board.x * scale_w) / 2
              // this.container_top = (o_h - this.container_height + msg.on_board.y * scale_h)
              let bottom = (msg.on_board.bottom) * scale_h
              this.container_top = o_h - bottom


              //now cover the part of the pictures
              this.wall_l = 0
              this.wall_t = 0;
              this.wall_h = o_h
              this.wall_w = (o_w - (this.container_left + this.container_width))

              this.twall_l = 0; //No right wall needed
              this.twall_t = 0;
              this.twall_h = 0;
              this.twall_w = 0;


              //now map the canvas to the visible part
              this.canvas_left = this.wall_w //o_w - this.canvas_width - this.wall_w
              this.canvas_top = 0
              // this.canvas_height = o_h

              this.canvas_width = 2000 * scale_w//(msg.on_board.width * scale_w)
              this.canvas_height = 1500 * scale_h

              this.viewport_top = this.canvas_top
              this.viewport_left = this.canvas_left
              this.viewport_width = msg.on_board.width * scale_w
              this.viewport_height = msg.on_board.height * scale_h

            } else {

              this.container_left = msg.on_board.left * scale_w * -1
              this.wall_l = 0
              this.wall_w = o_w

              this.wall_h = (o_h - msg.on_board.height * scale_h) / 2
              this.wall_t = o_h - this.wall_h
              this.twall_l = 0
              this.twall_w = o_w

              this.twall_t = 0;
              this.twall_h = this.wall_h;
              /*    CONTAINER
                header
                       -----------
                       |         |
                  pics |         |
                       -----------
                          cmds
              */


              this.container_top = o_h - msg.on_board.bottom * scale_h - this.wall_h


              this.canvas_left = 0
              this.canvas_top = this.twall_h

              this.canvas_width = 2000 * scale_w//(msg.on_board.width * scale_w)
              this.canvas_height = 1500 * scale_h// o_h - this.wall_h - this.twall_h


              this.viewport_top = this.canvas_top
              this.viewport_left = this.canvas_left
              this.viewport_width = msg.on_board.width * scale_w
              this.viewport_height = msg.on_board.height * scale_h


            }

            console.log("canvas l " + this.canvas_left + " t " + this.canvas_top + " w " + this.canvas_width + " h " + this.canvas_height)
            if (!this.bCanvasReady && this.container) {
              console.log("canvas created")
              this.bCanvasReady = true;
              this.addCanvasToDiv()//true, this.container.nativeElement, undefined, this.canvas_width, this.canvas_height)

            }


            // console.log("l " + this.container_left + " t " + this.container_top + " w " + this.container_width + " h " + this.container_height + " o_w " + o_w + " o_h " + o_h + " f " + free_space)
            resolve()
            return
          }
        }
      }
      reject()
    })
  }




  //****************************************************************************
  //From canvas.component.ts
  //****************************************************************************
  addCanvasToDiv() {
    if (!this.container) {
      return
    }

    const canvas = document.createElement("div");

    this.my_canvas = canvas
    canvas.style.position = "absolute"
    canvas.style.overflow = "hidden"
    // canvas.style.background = "#ff000030"
    canvas.style.left = "0px"



    this.canvas_height

    canvas.style.width = this.canvas_width + "px"
    canvas.style.height = this.canvas_height + "px"



    // canvas.style.background = "#ffaa0020"
    this.container.nativeElement.appendChild(canvas);
    this.draw_tool = SVG().addTo(canvas)
    let childern = canvas.children
    if (childern) {
      let svg_node = childern[0]
      if (svg_node) { // scale is true&& bScale) {
        this.viewBoxWidth = canvas.clientWidth
        this.viewBoxHeight = canvas.clientHeight
        this.canvas_service.setSVGDim(this.viewBoxWidth, this.viewBoxHeight)

        svg_node.setAttribute("viewBox", "0 0 " + this.viewBoxWidth + " " + this.viewBoxHeight);
      }
    }
    this.draw_tool.node.dataset['canvas'] = "main"
    this.local_draw_pencil = new kPencil(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)
    this.remote_draw_pencil = new kPencil(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)

    let el: HTMLElement = canvas


    let svg = this.draw_tool.node
    if (svg) {        //This is to keep the aspect ratio in an image
      svg.style.width = "100%"
      svg.style.height = "100%"
    }

    if (el.parentNode) {
      el.addEventListener('pointerdown', onMouseDown, false);
      el.addEventListener('touchstart', onMouseDown, false);
      el.addEventListener('pointermove', onMouseMove, false);
      el.addEventListener('touchmove', onMouseMove, false);
      el.addEventListener('pointerup', onMouseUp, false);
      el.addEventListener('touchend', onMouseUp, false);
      el.addEventListener('click', onClicked);
    }

    // this.showGrid()
    let me = this
    return this.draw_tool
    //**************************************************************************
    // Mouse Down
    //**************************************************************************
    function onClicked(this: any, $event: any) {
      if (me.bSendToRemote) {
        me.canvas_service.sendCanvasMsg("mouse", "click", me.mouse_lbl, {
          x: $event.offsetX,
          y: $event.offsetY,
          client_width: me.viewport_width,
          client_height: me.viewport_height,
        })
      }
      // else {
      //     console.log("local mouse down")
      //   me.local_draw_pencil.onMouseDown($event, $event.offsetX, $event.offsetY)
      // }
    }
    //**************************************************************************
    // Mouse Down
    //**************************************************************************
    function onMouseDown(this: any, $event: any) {
      if (me.bRemoteIsSharing) {
        me.canvas_service.sendCanvasMsg("mouse", "down", me.mouse_lbl, {
          x: $event.offsetX,
          y: $event.offsetY,
          client_width: me.viewport_width,
          client_height: me.viewport_height,
        })
      }
      if (me.bDrawLocal) {
        console.log("local mouse down")
        me.local_draw_pencil.onMouseDown($event, $event.offsetX, $event.offsetY)
      }
    }

    //**************************************************************************
    // Mouse Move
    //**************************************************************************
    function onMouseMove(this: any, $event: any) {
      // $event.currentTarget.style.cursor = "none"
      if (me.bDrawLocal) {
        console.log("local mouse move")
        me.local_draw_pencil.onMouseMove($event)
      }
      // let x =
      // if (me.last_on_board) {
      //   x = $event.offsetX * this.clientWidth / me.container_width
      // }
      me.canvas_service.sendCanvasMsg("mouse", "move", me.mouse_lbl, {
        x: $event.offsetX,
        y: $event.offsetY,
        client_width: me.viewport_width,
        client_height: me.viewport_height,
      })

    }
    //**************************************************************************
    // Mouse Up
    //**************************************************************************
    function onMouseUp(this: any, $event: any) {
      if (me.bRemoteIsSharing) {
        me.canvas_service.sendCanvasMsg("mouse", "up", this.mouse_lbl, {
          x: $event.offsetX,
          y: $event.offsetY,
          client_width: me.viewport_width,
          client_height: me.viewport_height,
        })
      }
      if (me.bDrawLocal) {
        console.log("local mouse up")
        me.local_draw_pencil.onMouseUp($event)
      }
    }
  }
  /*
   bRemoteIsDrawing: boolean = false
   processIncomingMouseMsg(msg: any) {
     if (this.iframeElement) {
       let x = msg.data.x * this.iframeElement.nativeElement.clientWidth / msg.data.client_width
       let y = msg.data.y * this.iframeElement.nativeElement.clientHeight / msg.data.client_height

       if (msg.action == "down") {
         this.bRemoteIsDrawing = true
         this.remote_draw_pencil.onMouseDown({ pointerId: "remote" }, x, y)
         console.log("draw remote down " + x + ", " + y)
       }
       if (msg.action == "move") {
         if (this.bRemoteIsDrawing) {
           this.remote_draw_pencil.onMouseMove({ offsetX: x, offsetY: y })
           console.log("draw remote move " + x + ", " + y)

         }
         if (msg.canvas == "mouse" && !msg.caller_id) { //we dont need to show our own email or name
           if (!this.other_mouses[msg.id]) {
             this.makeMouseBox(msg)
           }
           this.other_mouses[msg.id].style.left = (x - 3) + "px"
           this.other_mouses[msg.id].style.top = (y - 10) + "px"
         }
       }

       if (msg.action == "up") {
         this.bRemoteIsDrawing = false
         this.remote_draw_pencil.onMouseUp(msg.data)
         console.log("draw remote up " + x + ", " + y)
         setTimeout(() => {
           this.undo()
         }, 5000)

       }
     }
   }
  setSharedWndSize(remote_w?: number, remote_h?: number) {
    setTimeout(() => {
      let video_w
      let video_h
      if (this.video && this.video.nativeElement.srcObject) {
        let vts = this.video.nativeElement.srcObject.getVideoTracks()
        if (vts.length > 0) {
          let videoTrack = vts[0]
          const settings = videoTrack.getSettings();
          if (settings.width) {
            video_w = settings.width;
            video_h = settings.height;
            // console.log("video_w " + video_w + " video_h " + video_h)
            // console.log("remote_w " + remote_w + " remote_h " + remote_h)
          }
        }
      }


      if (this.outComponent) {
        let o_w = this.outComponent.nativeElement.clientWidth;
        let o_h = this.outComponent.nativeElement.clientHeight;
        let in_t = 0


        let in_h = o_h
        let in_w = o_h * video_w / video_h
        let in_l = (o_w - in_w) / 2;
        if (in_w > o_w) { //now do it with the width
          in_w = o_w
          let ar = video_h / video_w
          in_h = o_w * ar
          // console.log(in_h + " = " + o_w + "  * " + w_h + " / " + w_w)

          in_l = 0;
          in_t = (o_h - in_h) / 2

        }
        this.container_height = in_h
        this.container_width = in_w
        this.container_left = in_l
        this.container_top = in_t

        this.canvas_width = in_w //- 32
        this.canvas_height = in_h //+ 16
        this.canvas_left = in_l
        this.canvas_top = in_t
        this.addCanvasToDiv()
      }
    })
  }
*/}
