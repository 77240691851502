import { Component, Input } from '@angular/core';

@Component({
  selector: 'progres-h',
  templateUrl: './progres-h.component.html',
  styleUrls: ['./progres-h.component.scss']
})
export class ProgresHComponent {

  @Input() progress: number = 50
}
