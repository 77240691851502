/*
(C)2023  Venditio Sergio A. Fernandez, Ph.D.

*/

export interface ContactsState {
  contacts: any

}

export const initialContactsState: ContactsState = {
  contacts: {},
};
